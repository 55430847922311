import React from "react";

import { alpha, styled, useTheme } from "@mui/material";
import Box, { BoxProps } from "@mui/material/Box";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";

export const StyledPanel = styled(Box)<Props>(({ borderRadius, shadow, backdrop, background, border }) => {
  const { colors } = useMUIThemeModeContext();
  const { palette: { mode } } = useTheme();

  let backgroundColor = colors.white;
  if (backdrop) backgroundColor = alpha(colors.white, 0.6);
  if (background) backgroundColor = background;

  let boxShadow
    = "0px 2.6px 3.6px -22px rgba(0, 0, 0, 0.038), 0px 7.1px 10px -22px rgba(0, 0, 0, 0.055), 0px 17.2px 24.1px -22px rgba(0, 0, 0, 0.072), 0px 57px 80px -22px rgba(0, 0, 0, 0.11)";
  if (shadow === false || mode === "dark") boxShadow = "none";

  return {
    position: "relative",
    background: backgroundColor,
    boxShadow: boxShadow,
    borderRadius: `${borderRadius ?? 10}px`,
    border: border ? border : undefined,
  };
});

export interface Props extends BoxProps {
  shadow?: boolean;
  backdrop?: boolean;
  borderRadius?: number;
  border?: string;
  background?: string;
}

const Panel: React.FC<Props> = (props) => {
  return <StyledPanel {...props}>{props.children}</StyledPanel>;
};

export default Panel;
