import React from "react";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { InputAdornment, Stack, useMediaQuery, useTheme } from "@mui/material";
import PriceField from "components/elements/PriceField";
import { IGridStrategyLimits } from "types/types";

import { CustomIconButton } from "../styles";

interface IProps {
  precision?: number;
  formik: any;
  disabled?: boolean;
  counterCurrency: string;
  gridStrategyLimits: IGridStrategyLimits
}

const ORDER_VALUE_CONST = {
  CZK: 1000,
  EUR: 50,
};

const OrderValueInput = ({ precision, formik, disabled, counterCurrency, gridStrategyLimits }: IProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleChange = (newValue: number | Array<number>) => {
    formik.setFieldValue("gridOrderValue", newValue);
  };

  const handleSub = () => {
    const min = gridStrategyLimits?.min ?? 0;
    const value = formik.values.gridOrderValue - ORDER_VALUE_CONST[counterCurrency as keyof typeof ORDER_VALUE_CONST];

    handleChange(value < min ? min : value);
  };

  const handleAdd = () =>
    handleChange(
      Number(formik.values.gridOrderValue) + ORDER_VALUE_CONST[counterCurrency as keyof typeof ORDER_VALUE_CONST]
    );

  return (
    <Stack spacing={isMobile ? 1 : 2} direction={"row"} justifyContent="center" alignItems="flex-start" p={3}>
      <CustomIconButton
        sx={{ marginTop: isMobile ? "3px" : 0.5 }}
        onClick={handleSub}
        size={isMobile ? "small" : "large"}
        disabled={disabled}>
        <RemoveIcon />
      </CustomIconButton>
      <PriceField
        sx={{
          [`& fieldset`]: {
            borderRadius: "39px",
          },
          [`& .MuiInputBase-root`]: {
            height: isMobile ? "40px" : undefined,
          },
        }}
        fullWidth={true}
        autoComplete="off"
        onlyPositive
        allowZero
        precision={precision}
        id={"gridOrderValue"}
        name={"gridOrderValue"}
        value={formik.values.gridOrderValue ?? ""}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        disabled={disabled}
        error={formik.touched.gridOrderValue && Boolean(formik.errors.gridOrderValue)}
        helperText={formik.touched.gridOrderValue && formik.errors.gridOrderValue}
        InputProps={{
          endAdornment: <InputAdornment position="start">{counterCurrency}</InputAdornment>,
        }}
      />
      <CustomIconButton
        sx={{ marginTop: isMobile ? "3px !important" : "4px !important" }}
        onClick={handleAdd}
        size={isMobile ? "small" : "large"}
        disabled={disabled}>
        <AddIcon />
      </CustomIconButton>
    </Stack>
  );
};

export default OrderValueInput;
