import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import {
  Typography,
  Box,
  useTheme,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import Card from "components/elements/Card";
import PageTitle from "components/elements/PageTitle";
import { useAPIExchanges } from "hooks/useAPI";
import useCurrencyPairs from "hooks/useCurrencyPairs";
import { useTranslation } from "react-i18next";
import { SubscriptionType } from "types/enums";
import { isAtTierOrHigher } from "utils";

const CurrencyPairs = () => {
  const { data: currencyPairs } = useCurrencyPairs();
  const { data: dataExchanges } = useAPIExchanges();
  const { t } = useTranslation();
  const theme = useTheme();

  const getTableIcon = (userTier: SubscriptionType, tier: SubscriptionType) => {
    if (isAtTierOrHigher(userTier, tier)) {
      return <CheckCircleIcon color="success" aria-label={t("yes")} sx={{ mt: "3px" }} />;
    }
    return <RemoveCircleIcon color="disabled" aria-label={t("no")} sx={{ mt: "3px" }} />;
  };

  return (
    <>
      <Grid container justifyContent="center">
        <Grid item xs={12} lg={10}>
          <Card header={t("currencyPairsPage.title")}>
            <PageTitle i18nKey="currencyPairsPage.meta_title" />

            {dataExchanges && (
              <Stack spacing={4} sx={{ pt: 2 }}>
                {currencyPairs
                  .filter((exchange) => {
                    const dataEx = dataExchanges.exchanges.find((ex) => ex.exchangeEnum === exchange.id);
                    return dataEx?.enabled;
                  })
                  .map((exchange) => (
                    <Box key={`${exchange.label}-${exchange.exchangeEnum}`}>
                      <Typography variant="h6" component="h2" sx={{ mb: 1 }} color="secondary">
                        {exchange.label}
                      </Typography>
                      <Stack>
                        <TableContainer component={Paper} sx={{ boxShadow: 0 }}>
                          <Table
                            size="small"
                            sx={{ "& *": { paddingLeft: "0 !important", paddingRight: "0 !important" } }}>
                            <TableHead>
                              <TableRow>
                                <TableCell>{t("currencyPair")}</TableCell>
                                <TableCell align="center" sx={{ color: theme.palette.grey[600], width: "20%" }}>
                                  {SubscriptionType.FREE}
                                </TableCell>
                                <TableCell align="center" sx={{ color: theme.palette.tertiary.main, width: "20%" }}>
                                  {SubscriptionType.PLUS}
                                </TableCell>
                                <TableCell align="center" sx={{ color: theme.palette.primary.main, width: "20%" }}>
                                  {SubscriptionType.PREMIUM}
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {exchange.data
                                .filter((item: any) => item.enabled)
                                .map((row: any) => (
                                  <TableRow key={row.key} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                      {row.key}
                                    </TableCell>
                                    <TableCell align="center">
                                      {getTableIcon(SubscriptionType["FREE"], row.subscription_type)}
                                    </TableCell>
                                    <TableCell align="center">
                                      {getTableIcon(SubscriptionType["PLUS"], row.subscription_type)}
                                    </TableCell>
                                    <TableCell align="center">
                                      {getTableIcon(SubscriptionType["PREMIUM"], row.subscription_type)}
                                    </TableCell>
                                  </TableRow>
                                ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Stack>
                    </Box>
                  ))}
              </Stack>
            )}
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default CurrencyPairs;
