import { SvgIcon } from "@mui/material";

export const Home = (props: any) => {
  return (
    <SvgIcon {...props}>
      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.6224 1.33636C12.2801 1.24368 11.9194 1.24368 11.5771 1.33636C11.1797 1.44395 10.8451 1.7066 10.5781 1.91623L10.5035 1.97465L3.64349 7.31012C3.26686 7.6024 2.93505 7.85991 2.68779 8.19421C2.4708 8.48759 2.30915 8.8181 2.21079 9.1695C2.09871 9.56992 2.09916 9.98993 2.09968 10.4667L2.09975 17.8385C2.09973 18.3657 2.09971 18.8204 2.13032 19.195C2.16262 19.5904 2.23394 19.9836 2.42673 20.362C2.71435 20.9264 3.17329 21.3854 3.73778 21.673C4.11616 21.8658 4.50938 21.9371 4.90472 21.9694C5.27929 22 5.73404 22 6.26118 22H17.9383C18.4655 22 18.9202 22 19.2948 21.9694C19.6901 21.9371 20.0833 21.8658 20.4617 21.673C21.0262 21.3854 21.4851 20.9264 21.7728 20.362C21.9656 19.9836 22.0369 19.5904 22.0692 19.195C22.0998 18.8204 22.0998 18.3657 22.0997 17.8386L22.0998 10.4667C22.1003 9.98993 22.1008 9.56992 21.9887 9.1695C21.8903 8.8181 21.7287 8.48759 21.5117 8.19421C21.2644 7.8599 20.9326 7.6024 20.556 7.31011L13.696 1.97465L13.6214 1.91623C13.3544 1.7066 13.0198 1.44395 12.6224 1.33636ZM8.09976 16C7.54748 16 7.09976 16.4477 7.09976 17C7.09976 17.5523 7.54748 18 8.09976 18H16.0998C16.652 18 17.0998 17.5523 17.0998 17C17.0998 16.4477 16.652 16 16.0998 16H8.09976Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};
