import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import { Box, Stack, Typography, alpha, useMediaQuery, useTheme } from "@mui/material";
import { CoinsStacked } from "components/icons";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";

interface IProps {
  value: number;
  isMobile: boolean;
}

const PercentDifferenceBox = ({ value, isMobile }: IProps) => {
  const theme = useTheme();
  const { colors } = useMUIThemeModeContext();
  const getColor = () => (value < 0 ? colors.error : colors.success);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(320));

  const canRenderBox = () => value === 0 && isMobile;

  return (
    <Stack spacing={1} direction={"row"} alignItems={"center"}>
      {!isSmallScreen && isMobile && <CoinsStacked color="inherit" sx={{ fontSize: 20 }} />}
      {!canRenderBox() && (
        <Box
          sx={{ visibility: value === 0 ? "hidden" : "unset" }}
          p={{ xs: 0.4, sm: 0.8 }}
          bgcolor={alpha(getColor(), 0.15)}
          color={getColor()}
          borderRadius={"10px"}>
          <Stack spacing={0.5} direction={"row"} alignItems={"center"}>
            {value < 0 ? (
              <TrendingDownIcon sx={{ fontSize: isMobile ? "16px" : undefined }} fontSize={"small"} />
            ) : (
              <TrendingUpIcon sx={{ fontSize: isMobile ? "16px" : undefined }} fontSize={"small"} />
            )}
            <Typography fontSize={"0.75rem"} fontWeight={600}>{`${value < 0 ? "-" : "+"} ${Math.floor(
              Math.abs(value)
            )} %`}</Typography>
          </Stack>
        </Box>
      )}
    </Stack>
  );
};

export default PercentDifferenceBox;
