import React from "react";

import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import { Button, Typography, Box, CardHeader, Avatar, Grid, Link as MUILink, Stack } from "@mui/material";
import Panel from "components/elements/Panel";
import useImage from "hooks/useImage";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ICourse } from "types/types";
import { whyBitcoinRoute } from "utils";

import CourseLength from "../CourseLength";

const CourseTile = ({ image, title, description, chapters, lengthInMinutes, author }: ICourse) => {
  const { t } = useTranslation<string>();

  const { image: img } = useImage(image, "courses");
  const { image: authorImg } = useImage(author.image, "courses");

  return (
    <Panel display={"flex"} flexDirection={"column"} height={"100%"} sx={{ overflow: "hidden" }}>
      {image && (
        <Link to={whyBitcoinRoute(chapters[0].url)}>
          <img src={img} style={{ width: "100%" }} />
        </Link>
      )}
      <Box p={2} display={"flex"} flexDirection={"column"} flexGrow={1}>
        <Stack direction={{ xs: "column", sm: "row" }} justifyContent="space-between">
          <Link to={whyBitcoinRoute(chapters[0].url)}  style={{ textDecoration: "none" }} >
            <Typography variant="body1" component="h2" sx={{ color: "tertiary.main" }} fontWeight={600}>
              <big>{title}</big>
            </Typography>
          </Link>
          <CourseLength chapters={chapters} lengthInMinutes={lengthInMinutes} />
        </Stack>
        <Typography pt={2} variant="body2">
          {description}
        </Typography>
        <Grid container spacing={2} pt={2} alignItems="flex-end" flexGrow={1}>
          <Grid item xs={12} sm={8}>
            <MUILink underline="none" href={author.url} target="_blank">
              <CardHeader
                avatar={<Avatar src={authorImg} />}
                title={
                  <Typography variant="body2" color="secondary" sx={{ fontWeight: 600 }}>
                    {author.name}
                  </Typography>
                }
                subheader={
                  <Typography variant="body1" color="gray">
                    {author.label}
                  </Typography>
                }
                sx={{ p: 0 }}
              />
            </MUILink>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button
              fullWidth
              color="secondary"
              sx={{ backgroundColor: "tertiary.main", color: "white" }}
              component={Link}
              to={whyBitcoinRoute(chapters[0].url)}
              endIcon={<PlayCircleOutlineIcon />}
              variant="contained">
              {t("whyBitcoin.coursePlay")}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Panel>
  );
};

export default CourseTile;
