import { SvgIcon } from "@mui/material";

export const MessageQuestionSquare = (props: any) => {
  return (
    <SvgIcon {...props}>
      <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.69922 6.00224C8.87542 5.50136 9.22319 5.07901 9.68095 4.80998C10.1387 4.54095 10.6769 4.4426 11.2002 4.53237C11.7236 4.62213 12.1982 4.89421 12.5402 5.30041C12.8821 5.70661 13.0693 6.22072 13.0685 6.75168C13.0685 8.25056 10.8201 9 10.8201 9M10.8491 12H10.8591M5.69922 16V18.3355C5.69922 18.8684 5.69922 19.1348 5.80845 19.2716C5.90344 19.3906 6.04749 19.4599 6.19976 19.4597C6.37485 19.4595 6.58289 19.2931 6.99898 18.9602L9.38443 17.0518C9.87174 16.662 10.1154 16.4671 10.3867 16.3285C10.6274 16.2055 10.8836 16.1156 11.1484 16.0613C11.4469 16 11.7589 16 12.383 16H14.8992C16.5794 16 17.4195 16 18.0612 15.673C18.6257 15.3854 19.0846 14.9265 19.3722 14.362C19.6992 13.7202 19.6992 12.8802 19.6992 11.2V5.8C19.6992 4.11984 19.6992 3.27976 19.3722 2.63803C19.0846 2.07354 18.6257 1.6146 18.0612 1.32698C17.4195 1 16.5794 1 14.8992 1H6.49922C4.81906 1 3.97898 1 3.33725 1.32698C2.77276 1.6146 2.31382 2.07354 2.0262 2.63803C1.69922 3.27976 1.69922 4.11984 1.69922 5.8V12C1.69922 12.93 1.69922 13.395 1.80144 13.7765C2.07884 14.8117 2.88749 15.6204 3.92276 15.8978C4.30426 16 4.76925 16 5.69922 16Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};
