import { ReactComponent as AnycoinLogo } from "assets/exchanges/anycoin-logo-original.svg";
import { ReactComponent as BinanceLogo } from "assets/exchanges/binance-logo-original.svg";
import { ReactComponent as BitstampLogo } from "assets/exchanges/bitstamp-logo-original.svg";
import { ReactComponent as CoinbaseproLogo } from "assets/exchanges/coinbasepro-logo-original.svg";
import { ReactComponent as CoinmateLogo } from "assets/exchanges/coinmate-logo-original.svg";
import { ExchangeType } from "types/enums";

interface Props extends React.SVGProps<SVGSVGElement> {
  type: ExchangeType;
}

const ExchangeIcon = ({ type, ...props }: Props) => {
  const getIcon = () => {
    switch (type) {
      case ExchangeType.ANYCOIN:
        return <AnycoinLogo height={"24px"} width={"96px"} {...props} />;
      case ExchangeType.BINANCE:
        return <BinanceLogo height={"24px"} width={"90px"} {...props} />;
      case ExchangeType.BITSTAMP:
        return <BitstampLogo height={"24px"} width={"70px"} {...props} />;
      case ExchangeType.COINBASEPRO:
        return <CoinbaseproLogo height={"24px"} width={"96px"} {...props} />;
      case ExchangeType.COINMATE:
        return <CoinmateLogo height={"24px"} width={"96px"} {...props} />;
    }
  };

  return getIcon();
};

export default ExchangeIcon;
