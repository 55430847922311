import { forwardRef, useEffect, useImperativeHandle, useRef } from "react";

import { useTheme } from "@mui/material";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import {
  IChartApi,
  IPriceLine,
  ISeriesApi,
  LineData,
  LineWidth,
  MouseEventParams,
  WhitespaceData,
  createChart,
} from "lightweight-charts-latest";
import { useTranslation } from "react-i18next";
import { DcaDynamicStrategyType } from "types/enums";
import { IPoint } from "types/types";
import { splitCurrencyPair } from "utils";
import { getNumberWithComma, renderDate, renderNumber } from "utils/formatter";

interface IProps {
  lines?: { value: number; label: string }[];
  points: IPoint[];
  pricePrecision?: number;
  currencyPair?: string;
  height?: string;
  onPriceScaleWidthChange?: (state: boolean) => void;
  type: DcaDynamicStrategyType;
}

const createTooltip = (borderColor: string) => {
  const toolTip = document.createElement("div");

  toolTip.style.position = "absolute";
  toolTip.style.display = "none";
  toolTip.style.padding = "8px";
  toolTip.style.boxSizing = "border-box";
  toolTip.style.fontSize = "12px";
  toolTip.style.textAlign = "left";
  toolTip.style.zIndex = "1000";
  toolTip.style.top = "12px";
  toolTip.style.left = "12px";
  toolTip.style.pointerEvents = "none";
  toolTip.style.border = "1px solid";
  toolTip.style.borderRadius = "10px";
  toolTip.style.fontFamily = "Popins";
  toolTip.style.background = "white";
  toolTip.style.color = "black";
  toolTip.style.borderColor = borderColor;

  return toolTip;
};

const DEFAULT_RIGHT_PRICE_SCALE_WIDTH = 75;

const DynamicStrategyChart = forwardRef(
  (
    {
      lines,
      points,
      currencyPair = "BTC/USD",
      pricePrecision = 0,
      height = "100%",
      onPriceScaleWidthChange,
      type,
    }: IProps,
    ref
  ) => {
    const { t } = useTranslation();
    const { palette } = useTheme();
    const { colors } = useMUIThemeModeContext();

    const { baseCurrency, counterCurrency } = splitCurrencyPair(currencyPair);
    const chartContainerRef = useRef<any>();
    const chart = useRef<IChartApi>();
    const lineSeries = useRef<ISeriesApi<"Line">[]>([]);
    const cLines = useRef<(IPriceLine | undefined)[]>();
    const toolTip = useRef<HTMLDivElement>();

    const handleResize = () => {
      chart.current?.priceScale("right").applyOptions({ autoScale: true });
      chart.current?.timeScale().fitContent();

      setTimeout(() => chart.current?.applyOptions({ width: chartContainerRef.current.clientWidth }), 10);
    };

    useImperativeHandle(ref, () => ({
      refresh: handleResize,
    }));

    const handleCrosshairMove = (param: MouseEventParams, toolTip: HTMLElement) => {
      if (
        param.point === undefined
        || !param.time
        || param.point.x < 0
        || param.point.x > chartContainerRef.current.clientWidth
        || param.point.y < 0
        || param.point.y > chartContainerRef.current.clientHeight
      ) {
        toolTip.style.display = "none";
      } else {
        const data = points.find((item) => item.d === param.time);

        if (data) {
          toolTip.style.display = "block";
          toolTip.innerHTML = `
            <div style="text-wrap: nowrap;">
              <div style="
                height: 10px;
                width: 10px;
                background-color: ${palette.mode === "light" ? colors.black : colors.gray700};
                border-radius: 50%;
                margin-right: 4px;
                display: inline-block;">
              </div>
              ${baseCurrency}: <strong>${renderNumber(data.p, pricePrecision, true)}\u00a0${counterCurrency}</strong>
            </div>
            <div>
              <div style="
                height: 10px;
                width: 10px;
                background-color: #219EBC;
                border-radius: 50%;
                margin-right: 4px;
                display: inline-block;">
              </div>
              ${t(`dcaBots.create.dynamicStrategyLabel.typesShort.${type}`)}: <strong>${getNumberWithComma(data.i)}\u00a0%</strong>
            </div>
            <div>
              ${t("dcaBots.dynamicStrategy.tooltipMultiplier")}: <strong>x${getNumberWithComma(data.m)}</strong>
            </div>
            <div>
              ${t("date")}: <strong>${renderDate(data.d)}</strong>
            </div>
          `;
        }

        const y = param.point.y;
        const x = param.point.x;
        const toolTipMargin = 10;

        let left = x + toolTipMargin;
        if (left > chartContainerRef.current.clientWidth - toolTip.clientWidth)
          left = x - toolTip.clientWidth - toolTipMargin;

        let top = y + toolTipMargin;
        if (top > chartContainerRef.current.clientHeight - toolTip.clientHeight)
          top = y - toolTip.clientHeight - toolTipMargin;

        toolTip.style.left = left + "px";
        toolTip.style.top = top + "px";
      }
    };

    const getLineSeries = (type: "price" | "percent", position: "right" | "left", color: string) => {
      return {
        color: color,
        lastValueVisible: false,
        priceLineVisible: false,
        priceScaleId: position,
        lineWidth: 1 as LineWidth,
        priceFormat: {
          type,
          precision: pricePrecision,
          minMove: pricePrecision > 0 ? undefined : 1,
        },
      };
    };

    const handleSizeChange = () => {
      const currentRightPriceScaleWidth = chart.current?.priceScale("right").width() || DEFAULT_RIGHT_PRICE_SCALE_WIDTH;
      onPriceScaleWidthChange?.(currentRightPriceScaleWidth > DEFAULT_RIGHT_PRICE_SCALE_WIDTH);
    };

    useEffect(() => {
      chart.current = createChart(chartContainerRef.current, {
        width: chartContainerRef.current.clientWidth,
        layout: {
          background: { color: "transparent" },
        },
        crosshair: {
          horzLine: {
            visible: false,
            labelVisible: false,
          },
          vertLine: {
            visible: false,
            labelVisible: false,
          },
        },
        rightPriceScale: {
          borderColor: colors.borderColor,
          mode: 1,
          visible: true,
        },
        timeScale: {
          borderColor: colors.borderColor,
        },
        localization: {
          priceFormatter: (price: number) => `$${renderNumber(price, pricePrecision)}`,
        },
      });

      lineSeries.current[0] = chart.current.addLineSeries(getLineSeries("percent", "left", palette.tertiary.main));
      lineSeries.current[1] = chart.current.addLineSeries(getLineSeries("price", "right", colors.black));

      chart.current.timeScale().fitContent();
      onPriceScaleWidthChange && chart.current.timeScale().subscribeSizeChange(handleSizeChange);

      // add a small margin on the right of the last candle
      chart.current.timeScale().applyOptions({ rightOffset: 10 });

      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);

        chart.current?.remove();
      };
    }, [pricePrecision]);

    useEffect(() => {
      if (lineSeries.current && points.length > 0 && Array.isArray(points)) {
        const strategy: (LineData | WhitespaceData)[] = [];
        const price: (LineData | WhitespaceData)[] = [];

        points.forEach((item) => {
          strategy.push({ time: item.d, value: item.i });
          price.push({ time: item.d, value: item.p ?? 0 });
        });

        lineSeries.current[0]?.setData(strategy);
        lineSeries.current[1]?.setData(price);

        chart.current?.timeScale().fitContent();

        if (!toolTip.current) {
          toolTip.current = createTooltip(palette.tertiary.main);
          chartContainerRef.current?.appendChild(toolTip.current);
          chart.current?.subscribeCrosshairMove((param) => toolTip.current && handleCrosshairMove(param, toolTip.current));
        }
      }
    }, [points]);

    useEffect(() => {
      if (lines && lines.length > 0) {
        //REMOVE OLD
        cLines.current?.forEach((line) => line && lineSeries.current[0]?.removePriceLine(line));

        //CREATE NEW
        cLines.current = lines.map((line) =>
          lineSeries.current[0]?.createPriceLine({
            price: line.value,
            title: line.label,
            color: palette.tertiary.main,
            lineWidth: 2,
            lineStyle: 0,
            axisLabelVisible: true,
            lineVisible: true,
          })
        );
      }
    }, [lines]);

    useEffect(() => {
      chart.current?.applyOptions({
        layout: {
          textColor: colors.black,
        },
        grid: {
          vertLines: { color: palette.mode === "light" ? "#D6DCDE" : "#444" },
          horzLines: { color: palette.mode === "light" ? "#D6DCDE" : "#444" },
        },
      });

      lineSeries.current[1]?.applyOptions({ color: palette.mode === "light" ? colors.black : colors.gray700 });
    }, [palette.mode]);

    useEffect(() => {
      chart.current?.subscribeCrosshairMove((param) => toolTip.current && handleCrosshairMove(param, toolTip.current));
    }, [palette.mode, points]);

    return <div style={{ height, position: "relative" }} ref={chartContainerRef} />;
  }
);

export default DynamicStrategyChart;
