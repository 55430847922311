const useDateTime = () => {
  const getLastDayOfMonth = () => {
    const date = new Date();
    const y = date.getFullYear();
    const m = date.getMonth();

    return new Date(y, m + 1, 0).getDate();
  };

  const getFirstDayOfMonth = () => {
    const date = new Date();
    const y = date.getFullYear();
    const m = date.getMonth();
    return new Date(y, m, 1).getDate();
  };

  const getTomorrow = () => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    return tomorrow.getDate();
  };

  const getTomorrowDay = () => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    const tomorrowDay = tomorrow.getDay();
    return tomorrowDay === 0 ? 7 : tomorrowDay;
  };

  return {
    getLastDayOfMonth,
    getFirstDayOfMonth,
    getTomorrow,
    getTomorrowDay,
  };
};

export default useDateTime;
