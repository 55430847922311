import { useState } from "react";

import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import { Button, Grid, Stack, Typography, Box, GlobalStyles, useTheme, darken } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import imageBackgroundHomepage from "assets/images/background-homepage.jpg";
import AspectRatioWrapper from "components/elements/AspectRatioWrapper";
import Panel from "components/elements/Panel";
import TrezorAffiliateCard from "components/modules/TrezorAffiliateCard";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { Trans, useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { IChapter } from "types/types";
import { getComponentByMode, whyBitcoinRoute } from "utils";
import { toHoursAndMinutes } from "utils/formatter";

import coursesConfig from "./config/courses";
import CourseLength from "./CourseLength";
import CourseTile from "./CourseTile";
import { UnderlineTypography, FirstCoursePanel, YoutubeShadow } from "./styles";

const WhyBitcoin = () => {
  const theme = useTheme();
  const { colors } = useMUIThemeModeContext();
  const { t } = useTranslation<string>();
  const [play, setPlay] = useState(false);
  const firstCourse = coursesConfig.courses[0];
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const globalStyle = {
    ".main-content": {
      background: `url(${imageBackgroundHomepage})`,
      backgroundSize: "contain",
    },
  };
  // ?rel=0 makes it to not show suggested videos after video ends
  const teaserVideoUrl = `https://www.youtube-nocookie.com/embed/tVmymAj1cRA?rel=0`;
  const url = play
  ? teaserVideoUrl + `&autoplay=1`
  : teaserVideoUrl;

  const renderCourses = () => {
    return coursesConfig.courses.slice(1).map((course, index) => (
      <Grid key={course.title + index} item xs={12} lg={6}>
        <CourseTile {...course} />
      </Grid>
    ));
  };

  const chapterItem = (chapter: IChapter, key: number) => {
    return (
      <Box
        key={`${chapter.title}-${key}`}
        sx={{
          borderRadius: "10px",
          backgroundColor: key === 0 ? "tertiary.main" : colors.legacyGray100,
          color: key === 0 ? "white" : "tertiary.main",
        }}
        p={1}>
        <Link to={whyBitcoinRoute(chapter.url)} style={{ textDecoration: "none", color: "inherit" }}>
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Typography
              variant={isMobile ? "body2" : "body1"}
              display="flex"
              alignItems="center">
              {chapter.title}
              <strong style={{ paddingLeft: "4px" }}>{key === 0 ? "|\u00A0" + t("whyBitcoin.coursePlay") : ""}</strong>
              {key === 0 && (
                <PlayCircleOutlineIcon
                  sx={{ paddingLeft: "4px", marginRight: "4px" }}
                  className="animate__animated animate__slow animate__heartBeat animate__infinite"
                />
              )}
            </Typography>
            <Typography
              display="flex"
              alignItems="center"
              variant={isMobile ? "body2" : "body1"}
              sx={{ color: key === 0 ? "#fff" : darken(colors.black, 0.5) }}>
              <AccessTimeIcon sx={{ marginRight: "4px" }} fontSize="small" />
              {toHoursAndMinutes(chapter.lengthInMinutes)}
            </Typography>
          </Stack>
        </Link>
      </Box>
    );
  };

  const handlePlayButton = () => {
    setPlay(true);
    isMobile && navigate("/proc-bitcoin#video");
  };

  return (
    <>
      {getComponentByMode(theme, <GlobalStyles styles={globalStyle} />, null)}
      <Grid container spacing={2} pt={2}>
        <Grid item xs={12} lg={6}>
          <Stack spacing={2} display={"flex"} alignItems="flex-start">
            <Stack direction={"row"} spacing={1}>
              <Typography variant="h3" fontWeight={600}>
                {t("whyBitcoin.title1")}
              </Typography>
              <UnderlineTypography variant="h3" color={theme.palette.primary.main} fontWeight={600}>
                {t("whyBitcoin.title2")}
              </UnderlineTypography>
            </Stack>
            <Typography pt={1} variant="body2">
              <Trans i18nKey="whyBitcoin.description" />
            </Typography>
            <Stack
              sx={{ pt: 2 }}
              direction={{ xs: "column", sm: "row" }}
              display="flex"
              width={"100%"}
              alignItems={"center"}
              spacing={2}>
              <Button
                variant="contained"
                onClick={handlePlayButton}
                endIcon={
                  <PlayCircleOutlineIcon className="animate__animated animate__slow animate__heartBeat animate__infinite" />
                }>
                {t("whyBitcoin.firstCourseButton")}
              </Button>
              <Button href="#kurzy" color="secondary" endIcon={<ExpandMoreIcon />}>
                {t("whyBitcoin.otherCoursesButton")}
              </Button>
            </Stack>
          </Stack>
        </Grid>
        <Grid id={"video"} item xs={12} lg={6}>
          <YoutubeShadow sx={{ borderTopLeftRadius: 12, borderTopRightRadius: 12, overflow: "hidden" }}>
            <AspectRatioWrapper aspectRatio={"16 / 9"}>
                <iframe
                  width="100%"
                  src={url}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                  style={{ display: "block", aspectRatio: "16 / 9" }}></iframe>
              </AspectRatioWrapper>
            </YoutubeShadow>
          <FirstCoursePanel borderRadius={0} p={2}>
            <Stack spacing={2}>
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Link to={whyBitcoinRoute(firstCourse.chapters[0]?.url)} style={{ textDecoration: "none" }}>
                <Typography variant="body1" component="h1" fontWeight={600} sx={{ color: "tertiary.main" }}>
                  <big>{firstCourse.title}</big>
                </Typography>
                </Link>
                <CourseLength chapters={firstCourse.chapters} lengthInMinutes={firstCourse.lengthInMinutes} />
              </Stack>
              <Typography variant="body2">{firstCourse.description}</Typography>
            </Stack>
            <Stack pt={2} spacing={1}>
              {firstCourse?.chapters?.map((chapter, key) => chapterItem(chapter, key))}
            </Stack>
          </FirstCoursePanel>
        </Grid>
      </Grid>
      <Stack>
        <Panel sx={{ p: 2, mt: 10 }}>
          <Typography variant="h6" component="h1" fontWeight={600} sx={{ color: "tertiary.main" }}>
            {t("whyBitcoin.coursesList")}
          </Typography>
        </Panel>
        <Grid container spacing={3} pt={5} id="kurzy">
          {renderCourses()}
        </Grid>
        <Box pt={10}><TrezorAffiliateCard source="whybitcoin" /></Box>
      </Stack>
    </>
  );
};

export default WhyBitcoin;
