import React, { useRef, useState } from "react";

import { Slider as MUISlider } from "@mui/material";
import { iOS } from "utils";

import IProps from "./types";

const Slider: React.FC<IProps> = ({ defaultValue, onChangeCommitted, minValue, onChange, ...props }) => {
  const [value, setValue] = useState<number | number[] | undefined>(defaultValue);
  const timeoutRef = useRef<any>();
  const isIOS = iOS();

  const handleChangeCommitted = (event: Event, newValue: number | number[]) => {
    if (!defaultValue || !onChangeCommitted) return;
    // Slider component workaround for Safari https://github.com/mui/material-ui/issues/31869
    if (event.type === "mousedown" && isIOS) return;

    let value = newValue;
    if (minValue && typeof newValue === "number") {
      value = newValue < minValue ? minValue : newValue;
    }
    setValue(value as number);

    clearTimeout(timeoutRef.current);
    const newTimer = setTimeout(() => onChangeCommitted(event, value), 500);
    timeoutRef.current = newTimer;
  };

  const handleChange = (event: Event, newValue: number | number[], activeThumb: number) => {
    // Slider component workaround for Safari https://github.com/mui/material-ui/issues/31869
    if (event.type === "mousedown" && isIOS) return;

    onChange?.(event, newValue, activeThumb);
  };

  if (isIOS || minValue) {
    return <MUISlider value={value} onChange={onChange ? handleChange : handleChangeCommitted} {...props} />;
  }

  return <MUISlider defaultValue={defaultValue} onChangeCommitted={onChangeCommitted} onChange={onChange} {...props} />;
};

export default Slider;
