import { useEffect, useRef, useState } from "react";

import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Skeleton,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import { updateBotLabel, updateDcaBotLabel } from "API/calls";
import Card2 from "components/elements/Card2";
import CurrencyIcon from "components/elements/CurrencyIcon";
import CustomTextField from "components/elements/CustomTextField";
import MaskedModeWrapper from "components/elements/MaskedModeWrapper";
import { Check, Clear, Edit } from "components/icons";
import ProfitBox from "components/modules/ProfitBox";
import SatsView from "components/modules/SatsView";
import { useDefaultLayoutContext } from "context/DefaultLayoutContext";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { useUserDataContext } from "context/UserDataContext";
import { useFormik } from "formik";
import useExchangesMetadata from "hooks/useExchangesMetadata";
import useKeyPress from "hooks/useKeyPress";
import { useSnackbar } from "notistack";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { BotStrategyType, DcaBotMode, SubscriptionType } from "types/enums";
import { IDcaBotDetail, IDcaStrategy, IGridBotDetail, IGridStrategy } from "types/types";
import { percentageChange, splitCurrencyPair, splitTranslatedBotFrequency } from "utils";
import { renderNumber } from "utils/formatter";
import * as yup from "yup";

import CircularProgressWithLabel from "./CircularProgressWithLabel";
import LinearDifference from "./LinearDifference";
import MidPriceItem from "./MidPriceItem";
import PercentDifferenceBox from "./PercentDifferenceBox";
import ValueItem from "./ValueItem";

export const SectionBox = styled(Box)(() => {
  const { colors } = useMUIThemeModeContext();

  return { padding: "16px", backgroundColor: colors.gray50, borderRadius: "10px" };
});

interface IProps {
  data: IGridBotDetail | IDcaBotDetail;
  strategyData?: IGridStrategy | IDcaStrategy;
  isGrid?: boolean;
  refreshData: () => void;
}

const Item = ({ data, isGrid, strategyData, refreshData }: IProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { colors } = useMUIThemeModeContext();
  const { enqueueSnackbar } = useSnackbar();
  const keyPressEnter = useKeyPress("Enter");
  const keyPressedEsc = useKeyPress("Escape");
  const { subscriptionType } = useUserDataContext();
  const { refreshMenu } = useDefaultLayoutContext();
  const inputRef = useRef<any>();
  const [editMode, setEditMode] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { baseCurrency, counterCurrency } = splitCurrencyPair(data.currencyPair);
  const { counterCurrDisplayedScale, baseCurrDisplayedScale } = useExchangesMetadata(
    data.exchangeConnection.exchangeEnum,
    data.currencyPair
  );

  const isFree = subscriptionType === SubscriptionType.FREE;
  const isPremium = subscriptionType === SubscriptionType.PREMIUM;

  useEffect(() => {
    if (typeof editMode === "boolean" && editMode === true) {
      inputRef.current.focus();
    }
  }, [editMode]);

  useEffect(() => {
    if (editMode && keyPressedEsc) {
      handleClear();
    }
  }, [keyPressedEsc]);

  useEffect(() => {
    if (editMode && keyPressEnter) {
      formik.handleSubmit();
    }
  }, [keyPressEnter]);

  const formik = useFormik({
    initialValues: { label: data.label },
    validationSchema: yup.object({
      label: yup.string().max(50, t("fieldMaxLength", { max: 50 })),
    }),
    onSubmit: (values) => {
      handleUpdate(values);
    },
  });

  const handleUpdate = async (body: object) => {
    setIsLoading(true);
    const langKey = isGrid ? "gridBot.create" : "dcaBots.create";
    const api = isGrid ? updateBotLabel : updateDcaBotLabel;

    try {
      await api(data.id, body);
      enqueueSnackbar(t(`${langKey}.editSuccessMessage`), { variant: "success" });
      refreshData();
      refreshMenu?.();
    } catch (error) {
      enqueueSnackbar(t(`${langKey}.editErrorMessage`), { variant: "error" });
    }

    setEditMode(false);
    setIsLoading(false);
  };

  const handleClear = (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event?.stopPropagation();
    formik.setFieldValue("label", data.label);
    setEditMode(false);
  };

  const getTitle = () => {
    if (editMode) {
      return (
        <CustomTextField
          fullWidth
          autoComplete="off"
          id="label"
          name="label"
          variant="standard"
          inputRef={inputRef}
          label={t("bots.tile.label")}
          value={formik.values.label}
          onChange={formik.handleChange}
          disabled={isLoading}
          error={formik.touched.label && Boolean(formik.errors.label)}
          helperText={formik.touched.label && formik.errors.label}
        />
      );
    }

    if (data.label) {
      return (
        <Typography fontSize={"1rem"} fontWeight={600}>
          {data.label}
        </Typography>
      );
    }

    return (
      <Typography color={colors.gray400} fontSize={"1rem"} fontWeight={500}>
        {t("withoutLabel")}
      </Typography>
    );
  };

  const header = () => (
    <Stack direction={"row"} spacing={1.5}>
      <Stack direction={"row"} alignItems="center">
        <CurrencyIcon
          style={{
            outline: `2px solid ${colors.white}`,
            borderRadius: "50%",
            width: "24px",
            height: "24px",
            zIndex: 1,
          }}
          name={baseCurrency.toLocaleLowerCase()}
        />
        <CurrencyIcon
          style={{
            marginLeft: "-2px",
            width: "24px",
            height: "24px",
          }}
          name={counterCurrency.toLocaleLowerCase()}
        />
      </Stack>
      {getTitle()}
    </Stack>
  );

  const getDetailLink = () => (isGrid ? "/grid-strategie" : "/strategie");

  const getBotTotalAmount = (detailData: IDcaBotDetail) => {
    return detailData?.strategy?.type === BotStrategyType.MARKET
      ? detailData?.strategy?.marketOrderValue
      : detailData?.strategy?.limitOrders?.reduce((accumulator, currentValue) => {
          return accumulator + currentValue.value;
        }, 0);
  };

  const dynamicStrategySection = () => {
    const detailData = data as IDcaBotDetail;

    if (!detailData?.strategy?.dynamicStrategy) return;

    const sData = strategyData as IDcaStrategy | undefined;
    const multiplier = sData?.currentDynamicStrategyMultiplier ?? 0;
    const amount = getBotTotalAmount(detailData);

    return (
      <>
        <Typography fontSize={"0.875rem"} pt={0.5} color={colors.gray600} fontWeight={400}>
          <Trans
            i18nKey={"bots.tile.dynamicStrategy.label"}
            values={{ type: t(`dcaBots.create.dynamicStrategyLabel.types.${detailData.strategy.dynamicStrategy.type}`) }}
          />
        </Typography>
        {multiplier && amount ? (
          <Box display={"flex"} flexDirection={"row"} flexWrap={"wrap"} columnGap={0.5}>
            <Typography fontSize={"0.875rem"} color={colors.gray600} fontWeight={400}>
              {t("bots.tile.dynamicStrategy.currentMultiplier")}
            </Typography>
            <Typography color={colors.gray600} fontSize={"0.875rem"} fontWeight={700}>
              {`x${renderNumber(multiplier, 2)}`}
            </Typography>
            <Typography fontSize={"0.875rem"} color={colors.gray600} fontWeight={400}>
              {"="}
            </Typography>
            <MaskedModeWrapper color={colors.gray600} fontSize={"0.875rem"} fontWeight={700}>
              <Typography fontSize={"0.875rem"} color={colors.gray600} fontWeight={700}>
                {`${renderNumber(multiplier * amount, counterCurrDisplayedScale, true)}\u00a0${counterCurrency}`}
              </Typography>
            </MaskedModeWrapper>
          </Box>
        ) : (
          <Stack direction={"row"} spacing={1} alignItems={"center"}>
            <Typography fontSize={"0.875rem"} color={colors.gray600} fontWeight={400}>
              {t("bots.tile.dynamicStrategy.currentMultiplier")}
            </Typography>
            <Skeleton variant="text" width={"100px"} sx={{ fontSize: "0.875rem" }} />
          </Stack>
        )}
      </>
    );
  };

  const description = () => {
    if (isGrid) {
      const detailData = data as IGridBotDetail;

      return (
        <Stack>
          <Typography fontSize={"0.875rem"} color={colors.gray600} fontWeight={400}>
            <Trans
              i18nKey="bots.tile.gridDescription.1"
              values={{
                currencyPair: detailData.currencyPair,
                exchangeTitle: detailData.exchangeConnection.exchangeTitle,
              }}
            />
          </Typography>
          <Box display={"flex"} flexDirection={"row"} flexWrap={"wrap"} columnGap={0.5}>
            <Typography fontSize={"0.875rem"} color={colors.gray600} fontWeight={400}>
              <Trans i18nKey="bots.tile.gridDescription.2" />
            </Typography>
            <MaskedModeWrapper color={colors.gray600} fontSize={"0.875rem"} fontWeight={700}>
              <Typography fontSize={"0.875rem"} color={colors.gray600} fontWeight={700}>
                {`${renderNumber(detailData.gridOrderValue)}\u00a0${counterCurrency}`}
              </Typography>
            </MaskedModeWrapper>
            <Typography fontSize={"0.875rem"} color={colors.gray600} fontWeight={400}>
              <Trans
                i18nKey="bots.tile.gridDescription.3"
                values={{
                  every: detailData.gridPercentStep % 1 !== 0 ? t("each2") : t("each"),
                  percentStep: detailData.gridPercentStep ? `${detailData.gridPercentStep}`.replace(".", ",") : detailData.gridPercentStep,
                }}
              />
            </Typography>
          </Box>
        </Stack>
      );
    }

    const detailData = data as IDcaBotDetail;
    const amount = getBotTotalAmount(detailData);
    const translatedFrequency = splitTranslatedBotFrequency(detailData.frequency, detailData.frequencyParameter);

    return (
      <Stack>
        <Typography fontSize={"0.875rem"} color={colors.gray600} fontWeight={400}>
          <Trans
            i18nKey={`bots.tile.dcaDescription.1.${detailData.mode}`}
            values={{
              currencyPair: detailData.currencyPair,
              exchangeTitle: detailData.exchangeConnection.exchangeTitle,
            }}
          />
        </Typography>
        {detailData.mode !== DcaBotMode.MANUAL && (
          <Box display={"flex"} flexDirection={"row"} flexWrap={"wrap"} columnGap={0.5}>
            <Typography fontSize={"0.875rem"} color={colors.gray600} fontWeight={400}>
              <Trans i18nKey="bots.tile.dcaDescription.2" values={{ stategy: detailData.strategy.type }} />
            </Typography>
            <MaskedModeWrapper color={colors.gray600} fontSize={"0.875rem"} fontWeight={700}>
              <Typography fontSize={"0.875rem"} color={colors.gray600} fontWeight={700}>
                {`${renderNumber(amount ?? 0)}\u00a0${counterCurrency}`}
              </Typography>
            </MaskedModeWrapper>
            <Typography fontSize={"0.875rem"} color={colors.gray600} fontWeight={400}>
              <Trans i18nKey="bots.tile.dcaDescription.3" values={{ ...translatedFrequency }} />
            </Typography>
          </Box>
        )}
      </Stack>
    );
  };

  const dcaSection = () => {
    const detailData = data as IDcaBotDetail;
    const sData = strategyData as IDcaStrategy | undefined;
    const canRenderSavingTarget = isFree ? true : !!detailData.savingsTarget;

    return (
      <Stack pt={2} spacing={"2px"}>
        {!isFree && (
          <>
            <SectionBox>
              <Stack justifyContent={"center"}>
                <Typography fontSize={"0.875rem"} fontWeight={500} color={colors.gray950}>
                  {t("dcaBots.youHaveSaved")}
                </Typography>
                {sData ? (
                  <MaskedModeWrapper color={colors.gray950} fontSize={"1.125rem"} fontWeight={500}>
                    <SatsView
                      color={colors.gray950}
                      fontSize={"1.125rem"}
                      fontWeight={500}
                      value={sData?.totalAmount ?? 0}
                      currency={baseCurrency}
                      scale={baseCurrDisplayedScale}
                      withZeros={!!sData?.totalAmount}
                    />
                  </MaskedModeWrapper>
                ) : (
                  <Skeleton variant="text" width={150} height={40} />
                )}
              </Stack>
            </SectionBox>
            {(sData?.totalAmount ?? 0) < 0 ? (
              <Alert severity="warning">
                <AlertTitle>
                  <Trans i18nKey="dcaBots.negativeTotalAmount.title" />
                </AlertTitle>
                <Trans i18nKey={"dcaBots.negativeTotalAmount.shortDescription"} />
              </Alert>
            ) : (
              <SectionBox>
                <Stack spacing={1}>
                  <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                    <ValueItem
                      label={t("dcaBots.currentValue")}
                      value={sData?.currentValue}
                      sx={{ flexDirection: "column-reverse" }}
                      valueFontSize="1.125rem"
                      isLoading={!sData}
                      skeletonHeight={40}
                      withZeros={!!sData?.currentValue}
                      scale={counterCurrDisplayedScale}
                      counterCurrency={counterCurrency}
                      baseCurrency={baseCurrency}
                      withoutUsd
                    />
                    {sData ? (
                      <PercentDifferenceBox value={sData?.currentValuePercentDifference ?? 0} />
                    ) : (
                      <Skeleton variant="text" width={50} height={60} />
                    )}
                  </Stack>
                  <LinearDifference currentValue={sData?.currentValue} value={sData?.totalInvested} />
                  <Stack direction={"row"} justifyContent={"space-between"}>
                    <ValueItem
                      label={t("invested")}
                      value={sData?.totalInvested}
                      sx={{ flexDirection: "column-reverse" }}
                      isLoading={!sData}
                      withZeros={!!sData?.totalInvested}
                      scale={counterCurrDisplayedScale}
                      counterCurrency={counterCurrency}
                      baseCurrency={baseCurrency}
                      withoutUsd
                    />
                    <ValueItem
                      label={t("bots.tile.valueDifference")}
                      value={Math.abs(sData?.currentValueDifference ?? 0)}
                      sx={{ flexDirection: "column-reverse", alignItems: "end" }}
                      isLoading={!sData}
                      scale={counterCurrDisplayedScale}
                      counterCurrency={counterCurrency}
                      baseCurrency={baseCurrency}
                      withZeros={!!sData?.currentValueDifference}
                      prefix={
                        sData?.currentValueDifference ? (sData?.currentValueDifference > 0 ? "+ " : "- ") : undefined
                      }
                      withoutUsd
                    />
                  </Stack>
                </Stack>
              </SectionBox>
            )}
          </>
        )}
        {subscriptionType && !isPremium && detailData.hasManualOrders && (
          <Alert severity="warning">
            <AlertTitle>
              <Trans i18nKey="dcaBots.hasManualOrders.title" />
            </AlertTitle>
            <Trans i18nKey="dcaBots.hasManualOrders.description" />
          </Alert>
        )}
        {canRenderSavingTarget && (
          <SectionBox>
            <Stack direction={"row"} justifyContent={"space-between"}>
              <ValueItem
                label={t("dcaBots.tile.savingsTarget")}
                value={detailData.savingsTarget}
                scale={counterCurrDisplayedScale}
                counterCurrency={counterCurrency}
                baseCurrency={baseCurrency}
                fallbackValue={`${t("dcaBots.notSet")}`}
                withoutSats
                withoutUsd
              />
              {!isFree
                && (sData ? (
                  <CircularProgressWithLabel value={sData?.savedPercent ?? 0} />
                ) : (
                  <Skeleton variant="circular" width={40} height={40} />
                ))}
            </Stack>
          </SectionBox>
        )}
      </Stack>
    );
  };

  const gridSection = () => {
    const detailData = data as IGridBotDetail;
    const sData = strategyData as IGridStrategy | undefined;

    const lowerPercentage = sData ? Math.round(percentageChange(sData.midPrice, detailData.gridLowerPrice)) : 0;
    const upperPercentage = sData ? Math.round(percentageChange(sData.midPrice, detailData.gridUpperPrice)) : 0;

    return (
      <Stack pt={2} spacing={"2px"}>
        {!isFree && (
          <SectionBox>
            <Stack justifyContent={"center"}>
              <Typography fontSize={"0.875rem"} fontWeight={500} color={colors.gray950}>
                {t("gridBot.detail.profit")}
              </Typography>
              {sData ? (
                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                  <MaskedModeWrapper color={colors.black} fontSize={"1.125rem"} fontWeight={500}>
                    <SatsView
                      color={colors.black}
                      fontSize={"1.125rem"}
                      fontWeight={500}
                      value={sData.totalBaseCurrencyProfit ?? 0}
                      currency={baseCurrency}
                      scale={baseCurrDisplayedScale}
                      withZeros={!!sData.totalBaseCurrencyProfit}
                    />
                  </MaskedModeWrapper>
                  {sData.totalBaseCurrencyProfitCurrentValue ? (
                    <ProfitBox
                      value={sData.totalBaseCurrencyProfitCurrentValue}
                      currency={counterCurrency}
                      scale={counterCurrDisplayedScale}
                    />
                  ) : undefined}
                </Stack>
              ) : (
                <>
                  <Skeleton variant="text" width={150} height={50} />
                  <Skeleton variant="text" width={60} height={30} />
                </>
              )}
            </Stack>
          </SectionBox>
        )}
        <SectionBox>
          <Stack direction={"row"} justifyContent={"space-between"}>
            <ValueItem
              label={t("gridBot.detail.gridLowerPrice")}
              value={detailData.gridLowerPrice}
              scale={counterCurrDisplayedScale}
              withZeros
              counterCurrency={counterCurrency}
              baseCurrency={baseCurrency}
              withoutMask
            />
            <ValueItem
              label={t("gridBot.detail.gridUpperPrice")}
              value={detailData.gridUpperPrice}
              scale={counterCurrDisplayedScale}
              withZeros
              counterCurrency={counterCurrency}
              baseCurrency={baseCurrency}
              sx={{ alignItems: "end" }}
              withoutMask
            />
          </Stack>
          {!isFree && sData && (
            <Stack spacing={1}>
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography
                  fontSize={"0.75rem"}
                  color={lowerPercentage > 0 ? colors.error : colors.gray500}
                  fontWeight={lowerPercentage > 0 ? 600 : 400}>
                  {`${lowerPercentage > 0 ? "+" : ""}${lowerPercentage} %`}
                </Typography>
                <Typography
                  fontSize={"0.75rem"}
                  color={upperPercentage < 0 ? colors.error : colors.gray500}
                  fontWeight={upperPercentage < 0 ? 600 : 400}>
                  {`${upperPercentage > 0 ? "+" : ""}${upperPercentage} %`}
                </Typography>
              </Stack>
              <MidPriceItem
                midPrice={sData.midPrice}
                data={detailData}
                priceScale={counterCurrDisplayedScale}
                counterCurrency={counterCurrency}
                baseCurrency={baseCurrency}
              />
            </Stack>
          )}
        </SectionBox>
      </Stack>
    );
  };

  const navigateToDetail = (event: React.MouseEvent<HTMLButtonElement | HTMLDivElement, MouseEvent>) => {
    if (editMode) return;

    event.stopPropagation();
    navigate(`${getDetailLink()}/${data.id}`);
  }

  const handleEdit = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    setEditMode(true);
  }

  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    formik.handleSubmit();
  }

  return (
    <Grid item xs={12} md={6}>
      <Card2
        height={"100%"}
        title={header()}
        onClick={navigateToDetail}
        sx={{ cursor: "pointer" }}
        action={
          <Stack direction={"row"} spacing={1}>
            {editMode ? (
              <>
                <IconButton size="small" disabled={isLoading} onClick={handleSubmit}>
                  {isLoading ? <CircularProgress size={24} /> : <Check sx={{ fontSize: 20, color: colors.gray700 }} />}
                </IconButton>
                <IconButton size="small" disabled={isLoading} onClick={handleClear}>
                  <Clear sx={{ fontSize: 20, color: colors.gray700 }} />
                </IconButton>
              </>
            ) : (
              <IconButton size="small" onClick={handleEdit}>
                <Edit sx={{ fontSize: 20, color: colors.gray700 }} />
              </IconButton>
            )}
          </Stack>
        }
        childrenBoxSx={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
        }}
        headerStackSx={{
          mb: 2,
        }}>
        {description()}
        {dynamicStrategySection()}
        {isGrid ? gridSection() : dcaSection()}
        <Box pt={3} flexGrow={1} alignItems={"flex-end"} justifyContent={"center"} display="flex">
          <Button
            fullWidth
            sx={{ border: `1px solid ${colors.gray300}` }}
            onClick={navigateToDetail}
            color="inherit"
            variant="outlined">
            {t("bots.tile.showDetail")}
          </Button>
        </Box>
      </Card2>
    </Grid>
  );
};

export default Item;
