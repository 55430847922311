import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

export const CustomBox = styled(Box)({
  display: "flex",
  width: "100%",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
});
