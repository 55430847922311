const DAYS_IN_YEAR = 365;

import { PriceHistory } from "types/types";

import {
  GetFrequencyCountByValueOptions,
  GetInvestedCryptoOptions,
  GetInvestedOptions,
  GetHistoryWithPricesOptions,
} from "./types";

export const getHistoryDays = (duration: number) => {
  return duration * DAYS_IN_YEAR;
};

export const getStepCount = (duration: number, frequency: number) => {
  return Math.round(getHistoryDays(duration) / frequency);
};

export const getFrequencyCountByValue = ({ value, frequencies }: GetFrequencyCountByValueOptions) =>
  frequencies.find((item) => item.value === value)?.count;

export const getInvested = ({ duration, frequency, amount }: GetInvestedOptions) => {
  return getStepCount(duration, frequency) * amount;
};

export const getHistoryWithPrices = ({ duration, frequency, priceHistory = [] }: GetHistoryWithPricesOptions) => {
  const historyDays = getHistoryDays(duration);
  const stepsCount = getStepCount(duration, frequency);
  const currentHistory = priceHistory.slice(0, historyDays);
  let counter = 0;
  return currentHistory.filter((_item, index) => {
    if (index % frequency === 0 && counter < stepsCount) {
      counter += 1;
      return true;
    }
  });
};

export const getInvestedCrypto = ({ amount, priceHistory = [] }: GetInvestedCryptoOptions) => {
  return priceHistory.reduce((total, item) => total + amount / item.p, 0);
};

export const getFiatValue = (amount: number, priceHistory?: PriceHistory) => {
  if (!priceHistory) {
    return 0;
  }
  return amount * priceHistory[0]?.p;
};
