import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { Box, Stack, Typography } from "@mui/material";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { useNavigate } from "react-router-dom";
import { IChapter } from "types/types";
import { whyBitcoinRoute } from "utils";
import { toHoursAndMinutes } from "utils/formatter";

interface IProps {
  chapter: IChapter;
  isPreviousChapter: boolean;
  isActiveChapter: boolean;
}

const ChapterItem = ({ isPreviousChapter, isActiveChapter, chapter }: IProps) => {
  const { colors } = useMUIThemeModeContext();
  const navigate = useNavigate();

  return (
    <Box
      id={isActiveChapter ? "active-chapter" : ""}
      onClick={() => navigate(whyBitcoinRoute(chapter.url))}
      sx={{
        borderRadius: "10px",
        backgroundColor: isActiveChapter ? "primary.main" : isPreviousChapter ? colors.success : colors.legacyGray100,
        color: isActiveChapter || isPreviousChapter ? "#fff" : "tertiary.main",
        cursor: "pointer",
      }}
      p={1}>
      <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
        <Stack alignItems={"start"}>
          <Typography>{chapter.title}</Typography>
          <Typography
            display="flex"
            justifyContent="center"
            variant="body2"
            component="h1"
            sx={{ color: isActiveChapter || isPreviousChapter ? "#fff" : "#9E9E9E" }}>
            <AccessTimeIcon sx={{marginRight: "4px"}} fontSize="small" />
            {toHoursAndMinutes(chapter.lengthInMinutes)}
          </Typography>
        </Stack>
        {isPreviousChapter && <CheckCircleOutlineIcon fontSize="medium" />}
        {isActiveChapter && <PlayArrowIcon fontSize="medium" />}
      </Stack>
    </Box>
  );
};

export default ChapterItem;
