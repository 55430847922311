import { IconButton, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const CustomIconButton = styled(IconButton)({
  backgroundColor: "rgba(0, 0, 0, 0.54)", 
  color: "white",
  marginLeft: 5,
  padding: 2,

  '& svg': {
    fontSize: 12
  }
});

export const TypographyWithTooltip = styled(Typography)({
  display: "flex",
  alignItems: "center",
});
