import { SvgIcon } from "@mui/material";

export const ShieldPlus = (props: any) => {
  return (
    <SvgIcon {...props}>
      <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="shield-plus">
          <path
            id="Icon"
            d="M9.99967 12.0831V7.08314M7.49967 9.58314H12.4997M16.6663 9.99981C16.6663 14.0902 12.2047 17.0651 10.5813 18.0122C10.3968 18.1198 10.3046 18.1737 10.1744 18.2016C10.0734 18.2232 9.92597 18.2232 9.82494 18.2016C9.69475 18.1737 9.60251 18.1198 9.41802 18.0122C7.79464 17.0651 3.33301 14.0902 3.33301 9.99981V6.01448C3.33301 5.34822 3.33301 5.01509 3.44197 4.72873C3.53824 4.47576 3.69466 4.25004 3.89772 4.07109C4.12759 3.86851 4.43951 3.75154 5.06334 3.5176L9.53151 1.84204C9.70476 1.77707 9.79138 1.74459 9.88049 1.73171C9.95954 1.72029 10.0398 1.72029 10.1189 1.73171C10.208 1.74459 10.2946 1.77707 10.4678 1.84204L14.936 3.5176C15.5598 3.75154 15.8718 3.86851 16.1016 4.07109C16.3047 4.25004 16.4611 4.47576 16.5574 4.72873C16.6663 5.01509 16.6663 5.34822 16.6663 6.01448V9.99981Z"
            stroke="currentColor"
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    </SvgIcon>
  );
};
