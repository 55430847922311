import { SubscriptionType, ExchangeType } from "types/enums";

import { useAPIExchanges, useAPIExchangesMetadata } from "./useAPI";

const sortFn = (a: any, b: any) => {
  const nameA = a.key.toUpperCase();
  const nameB = b.key.toUpperCase();
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
};

function useCurrencyPairs() {
  const { data: dataMetadata } = useAPIExchangesMetadata();
  const { data: dataExchanges } = useAPIExchanges();

  const getCurrencyPairs = (tier?: SubscriptionType) => {
    const data: any[] = [];
    if (dataMetadata && dataExchanges) {
      dataExchanges.exchanges.forEach((exchange) => {
        const dataTemp = [];
        for (const [key, currencyPair] of Object.entries(dataMetadata[exchange.exchangeEnum].currency_pairs)) {
          dataTemp.push({
            key,
            ...currencyPair,
          });
        }
        const currencyPairsSorted = dataTemp.sort(sortFn);
        const currencyPairsBtc = currencyPairsSorted.filter((item) => item.key.startsWith("BTC"));
        const currencyPairsWithoutBtc = currencyPairsSorted.filter((item) => !item.key.startsWith("BTC"));
        const currencyPairsResult = [...currencyPairsBtc, ...currencyPairsWithoutBtc];
        const newExchangeObject = {
          id: exchange.exchangeEnum,
          label: exchange.title,
          data: currencyPairsResult,
        };
        data.push(newExchangeObject);
      });
    }
    return data;
  };

  const data = getCurrencyPairs();

  return {
    data,
    getCurrencyPairs,
  };
}

export default useCurrencyPairs;
