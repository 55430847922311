import { subscriptionCost } from "constants/subscriptions";

import React from "react";

import { KeyboardArrowDown } from "@mui/icons-material";
import { Typography, Box, Button, Grid, Divider } from "@mui/material";
import Panel from "components/elements/Panel";
import { Award03, Credit, ShieldPlus } from "components/icons";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { SubscriptionType } from "types/enums";
import { renderCredits } from "utils/formatter";

interface Props {
  badges?: number
  badgesTotal?: number
  credits?: number
  creditsUnclaimed?: number
  subscription?: SubscriptionType
}

const Overview: React.FC<Props> = ({ credits, creditsUnclaimed, badges, badgesTotal, subscription }) => {
  const { t } = useTranslation();

  return (
    <Panel shadow={false} sx={{ width: '100%', p: 1, mb: 4 }}>
      <Grid container spacing={1} alignItems={'stretch'}>
        <Grid item xs={12} md={12} lg={4}>
          <Box sx={{
            backgroundColor: 'primary.background',
            borderRadius: '10px',
            pt: 1,
            px: 2,
            pb: 1,
            width: '100%',
            height: '100%',
            color: 'primary.main',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            justifyContent: 'center',
          }}>
            <Typography variant="body1" component="h4" sx={{ fontWeight: 600, display: 'flex', alignItems: 'center', mb: 1 }}>
              {t("accountState")}
            </Typography>
            <Typography variant="h6" component="div" sx={{ display: 'flex', alignItems: 'center' }}>
              <Credit sx={{ mr: 1, fontSize: { xs: 24, md: 36 } }} />
              <Box sx={{ mt: 1, fontSize: { xs: 16, sm: 18, md: 20 } }}>
                {renderCredits(credits)}
              </Box>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Box sx={{
            backgroundColor: 'tertiary.background',
            borderRadius: '10px',
            pt: 1,
            px: 2,
            pb: 1,
            width: '100%',
            height: '100%',
            color: 'tertiary.main',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            justifyContent: 'center',
          }}>
            <Grid container alignItems={'stretch'}>
              <Grid item xs={6}>
                <Typography variant="body1" component="h4" sx={{ fontWeight: 600, display: 'flex', alignItems: 'center', mb: 1 }}>
                  {t("subscription")}
                </Typography>
                <Typography variant="h6" component="div" sx={{ display: 'flex', alignItems: 'center' }}>
                  {subscription && <ShieldPlus sx={{ mr: 1, fontSize: { xs: 24, md: 36 } }} />}
                  <Box sx={{ mt: 1, fontSize: { xs: 16, sm: 18, md: 20 } }}>
                    {subscription}
                  </Box>
                </Typography>
              </Grid>
              <Grid item xs={6} sx={{ pl: 1, textAlign: 'center', alignSelf: 'center' }}>
                  {subscription && subscription !== 'FREE' && (
                    <>
                      <Box>
                        {renderCredits(subscriptionCost[subscription])}
                      </Box>
                      <Divider sx={{ width: '80%', margin: '0 auto' }} />
                      <Box>
                        {t("datetime.month")}
                      </Box>
                    </>
                  )}
                  {subscription && subscription === 'FREE' && t("accountFree")}
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Box sx={{
            backgroundColor: 'tertiary.background',
            borderRadius: '10px',
            pt: 1,
            px: 2,
            pb: 1,
            width: '100%',
            height: '100%',
            color: 'tertiary.main',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            justifyContent: 'center',
          }}>
            <Typography variant="body1" component="h4" sx={{ fontWeight: 600, display: 'flex', alignItems: 'center', mb: 1 }}>
              {t("badges")}
            </Typography>
            <Typography variant="h6" component="div" sx={{ display: 'flex', alignItems: 'center' }}>
              <Award03 sx={{ mr: 1, fontSize: { xs: 24, md: 36 } }} />
              <Box sx={{ mt: 1, fontSize: { xs: 16, sm: 18, md: 20 } }}>
                {t("youHaveCollected")} {badges} {t("count.of")} {badgesTotal} {t('badge.collected', { count: badgesTotal }).toLowerCase()}
              </Box>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={4} sx={{ textAlign: 'center', alignSelf: 'center' }}>
          <Button variant="contained" component={Link} to="/stosaky#nakoupit" endIcon={<KeyboardArrowDown />} sx={{ textTransform: 'none', width: '100%' }}>
            {t("fillUp")} {t("credits")}
          </Button>
        </Grid>
        <Grid item xs={12} md={6} lg={4} textAlign={{ xs: 'center', lg: 'right' }} alignSelf="center">
          <Button variant="contained" color="success" component={Link} to="/predplatne" sx={{ textTransform: 'none', width: '100%' }}>
            {t("compare")}&nbsp;
            <span style={{ textTransform: 'lowercase' }}>{t("subscription")}</span>
          </Button>
        </Grid>
        {creditsUnclaimed !== 0 && (
          <Grid item xs={12} md={12} lg={4} textAlign={{ xs: 'center', lg: 'right' }} alignSelf="center">
            <Button variant="text" color="secondary" component={'a'} href="#odznaky" endIcon={<KeyboardArrowDown />} sx={{ textTransform: 'none', width: '100%' }}>
              {renderCredits(creditsUnclaimed)} {t("toClaim")}
            </Button>
          </Grid>
        )}
      </Grid>
    </Panel>
  );
};

export default Overview;
