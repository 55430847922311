import React from "react";

import MUITextField from "@mui/material/TextField";

import IProps from "./types";

const CodeField = ({ onChange, maxLength, customRegex, ...props }: IProps) => {
  const handleChange = (e: React.ChangeEvent<any>) => {
    const { value } = e.target;
    let code = value.replace(customRegex ?? /[^0-9]/g, "");

    if (maxLength && code.length > maxLength) {
      code = code.slice(0, maxLength);
    }

    e.target.value = code;
    return onChange && onChange(e);
  };

  return <MUITextField {...props} onChange={handleChange} />;
};

export default CodeField;
