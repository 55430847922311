import { AFFILIATE_BY_TTL } from "constants/localStorageExpiryDate";
import validations from "constants/validations";

import { useState, useEffect } from "react";

import { Alert, AlertTitle, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Stack from "@mui/material/Stack";
import useTheme from "@mui/material/styles/useTheme";
import TextField from "@mui/material/TextField";
import { register } from "API/calls";
import PageTitle from "components/elements/PageTitle";
import Panel from "components/elements/Panel";
import PasswordField from "components/elements/PasswordField";
import LanguageSelect from "components/modules/LanguageSelect";
import { useFormik } from "formik";
import useKeyPress from "hooks/useKeyPress";
import { getWithExpiry } from "hooks/useTTLLocalStorage";
import { useIsSmallScreen } from "hooks/useWindowSize";
import { useSnackbar } from "notistack";
import { useTranslation, Trans } from "react-i18next";
import { Link } from "react-router-dom";
import { IRegister } from "types/types";
import * as yup from "yup";

import { FormDiv } from "./styles";

const RegisterPage = () => {
  const theme = useTheme();
  const isSmallScreen = useIsSmallScreen();
  const { t } = useTranslation<string>();
  const keyPressed = useKeyPress("Enter");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isRegistered, setIsRegistered] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      language: "CS",
      agreement: false,
    },
    validationSchema: yup.object().shape({
      email: yup.string().required(t("fieldRequired")).matches(validations.email, t("fieldErrors.email")),
      password: yup.string().required(t("fieldRequired")).matches(validations.password, t("fieldErrors.password")),
      language: yup.string().required(t("fieldRequired")),
      agreement: yup.bool().oneOf([true], t("registrationForm.termsAgreementRequired")),
    }),
    onSubmit: (values) => {
      const affBy = getWithExpiry("affBy", AFFILIATE_BY_TTL);
      handleRegister({
        email: values.email,
        password: values.password,
        language: values.language,
        ...(affBy && { affBy }),
      });
    },
  });
  useEffect(() => {
    if (!isRegistered && keyPressed) {
      formik.handleSubmit();
    }
  }, [keyPressed]);

  const handleRegister = async (payload: IRegister) => {
    setIsLoading(true);
    try {
      await register(payload);
      enqueueSnackbar(t("registrationForm.successMessage"), { variant: "success" });
      setIsRegistered(true);
    } catch (error: any) {
      if (error?.response?.status === 409) {
        enqueueSnackbar(t("registrationForm.userAlreadyExists"), { variant: "error" });
      } else if (error?.response?.status === 403) {
        enqueueSnackbar("Registrace je momentálně umožněna jen pro pozvané testery.", { variant: "warning" });
      } else {
        enqueueSnackbar(t("registrationForm.errorMessage"), { variant: "error" });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const changeWithValidate = (e: any) => {
    formik.setFieldTouched(e.target.id);
    formik.handleChange(e);
  };

  return (
    <FormDiv>
      <PageTitle i18nKey="registrationForm.meta_title" />
      <Panel sx={{ p: 4, width: isSmallScreen ? "100%" : "460px" }}>
        <Stack spacing={3}>
          <Typography variant="h4" component="h1" fontWeight={600} sx={{ color: "tertiary.main", textAlign: "center", pb: 2 }}>
            {t("registrationForm.create")}
          </Typography>
          <TextField
            fullWidth={true}
            id={"email"}
            name={"email"}
            type={"email"}
            inputProps={{
              autoCapitalize: "none",
            }}
            label={t("registrationForm.email")}
            value={formik.values.email ?? ""}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            disabled={isRegistered || isLoading}
          />
          <PasswordField
            fullWidth={true}
            id={"password"}
            name={"password"}
            label={t("registrationForm.password")}
            value={formik.values.password ?? ""}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            onChange={changeWithValidate}
            disabled={isRegistered || isLoading}
          />
          <LanguageSelect
            value={formik.values.language ?? ""}
            error={formik.errors.language}
            onChange={(e) => formik.setFieldValue("language", e.target.value)}
            disabled={isRegistered || isLoading}
          />
          <FormControl disabled={isRegistered || isLoading} error={Boolean(formik.errors.agreement)} variant="standard">
            <FormControlLabel
              control={<Checkbox />}
              id={"agreement"}
              name={"agreement"}
              label={
                <>
                  {`${t("registrationForm.agreement")} `}
                  <Link to="/podminky" target="_blank" style={{ color: theme.palette.primary.main }}>
                    {t("registrationForm.terms")}
                  </Link>
                </>
              }
              checked={formik.values.agreement}
              onChange={formik.handleChange}
            />
            {<FormHelperText>{formik.touched.agreement && formik.errors.agreement}</FormHelperText>}
          </FormControl>
          <Button
            disabled={isRegistered || isLoading}
            fullWidth
            variant="contained"
            onClick={() => formik.handleSubmit()}
            autoFocus>
            {t("registrationForm.registerButton")}
          </Button>
          {isRegistered && (
            <>
              <Alert severity="success" sx={{ margin: "8px 16px" }}>
                <AlertTitle>{t("registrationForm.thanksForRegistering")}</AlertTitle>
                <Trans i18nKey="registrationForm.verifyYourEmailAddress" />
              </Alert>
              <Alert severity="warning" sx={{ margin: "8px 16px" }}>
                <AlertTitle>{t("registrationForm.emailVerificationWarning.title")}</AlertTitle>
                <Trans i18nKey="registrationForm.emailVerificationWarning.subtitle1" />
                <br />
                <Trans
                  i18nKey="registrationForm.emailVerificationWarning.subtitle2"
                  components={{
                    a: <Link style={{ color: theme.palette.primary.main }} to="/zapomenute-heslo" />,
                  }}
                />
              </Alert>
            </>
          )}
        </Stack>
      </Panel>
    </FormDiv>
  );
};

export default RegisterPage;
