import { useState, useEffect } from "react";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import {
  Box,
  Stack,
  Typography,
  Link as MUILink,
  CircularProgress,
  CardMedia,
  Button,
  useMediaQuery,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Alert,
  TextField,
  useTheme,
} from "@mui/material";
import { enableMFA, confirmMFA, logout } from "API/calls";
import BackdropLoading from "components/elements/BackdropLoading";
import CodeField from "components/elements/CodeField";
import ResponsiveLabel from "components/elements/ResponsiveLabel";
import { AppleOs, Android, CircleCheck } from "components/icons";
import { useUserDataContext } from "context/UserDataContext";
import { useFormik } from "formik";
import useKeyPress from "hooks/useKeyPress";
import useStep from "hooks/useStep";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import MobileStepper from "../MobileStepper";
import Stepper from "../Stepper";
import { DownloadBox } from "./styles";

interface IEnableMFA {
  secret: string;
  qrImage: string;
}

const Enable = () => {
  const theme = useTheme();
  const { t } = useTranslation<string>();
  const keyPressEnter = useKeyPress("Enter");
  const { refreshData } = useUserDataContext();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [backupCode, setBackupCode] = useState<string>("");
  const [backupCodeError, setBackupCodeError] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const [enableMFAdata, setEnableMFAdata] = useState<IEnableMFA>();
  const [enableMFALoading, setEnableMFALoading] = useState<boolean>(false);
  const [isOpenBackdropLoading, setIsOpenBackdropLoading] = useState<boolean>(false);
  const formik = useFormik({
    initialValues: { otp: "" },
    validationSchema: yup.object({
      otp: yup
        .string()
        .required(t("fieldRequired"))
        .max(6, t("fieldMaxLength", { max: 6 })),
    }),
    onSubmit: (values) => {
      const { otp } = values;
      otp && handleConfirmMFA(`${otp}`);
    },
  });
  const { steps, activeStep, isFirstStep, isLastStep, nextStep, backStep } = useStep([
    t("twoFactorAuth.downloadApp.title"),
    t("twoFactorAuth.scanQRcode.title"),
    t("twoFactorAuth.backupCode.title"),
    t("twoFactorAuth.enableGoogleAuth.title"),
    t("twoFactorAuth.complete.title"),
  ]);
  const recoveryFormik = useFormik({
    initialValues: { recovery: false },
    validationSchema: yup.object({
      recovery: yup.bool().oneOf([true], t("twoFactorAuth.scanQRcode.recoveryCheckboxError")),
    }),
    onSubmit: () => nextStep(),
  });

  useEffect(() => {
    if (activeStep === 0) {
      recoveryFormik.resetForm();
    }
    if (activeStep === 1) {
      !enableMFAdata && handleEnableMFA();
      recoveryFormik.resetForm();
    }
    if (activeStep === 2) {
      formik.resetForm();
    }
  }, [activeStep]);

  useEffect(() => {
    if (keyPressEnter) isLastStep ? handleDone() : handleNext();
  }, [keyPressEnter]);

  useEffect(() => {
    if (backupCodeError) setBackupCodeError(false);
  }, [backupCode]);

  const handleEnableMFA = async () => {
    try {
      setEnableMFALoading(true);
      setEnableMFAdata(undefined);
      const { data } = await enableMFA();
      setEnableMFAdata(data);
    } catch (error) {
      enqueueSnackbar(t("commonError"), { variant: "error" });
    } finally {
      setEnableMFALoading(false);
    }
  };

  const handleConfirmMFA = async (otp: string) => {
    setIsOpenBackdropLoading(true);

    try {
      await confirmMFA(otp);
      nextStep();
      
      await logout();
      refreshData?.();
    } catch (err: any) {
      enqueueSnackbar(err?.response?.data?.message ?? t("commonError"), { variant: "error" });
    } finally {
      setIsOpenBackdropLoading(false);
    }
  };

  const getSubtitle = () => {
    switch (activeStep) {
      case 0:
        return t("twoFactorAuth.downloadApp.subtitle");
      case 1:
        return t("twoFactorAuth.scanQRcode.subtitle");
      case 2:
        return t("twoFactorAuth.backupCode.subtitle");
      case 3:
        return t("twoFactorAuth.enableGoogleAuth.subtitle");
      case 4:
        return t("twoFactorAuth.complete.subtitle");
      default:
        return "";
    }
  };

  const getSection = () => {
    switch (activeStep) {
      case 0:
        return downloadAppSection();
      case 1:
        return scanQRcodeSection();
      case 2:
        return verificationBackupCodeSection();
      case 3:
        return enableGoogleAuthSection();
      case 4:
        return completeSection();
      default:
        return "";
    }
  };

  const downloadAppSection = () => {
    return (
      <Stack sx={{ alignItems: "center" }}>
        <Typography sx={{ textAlign: "center" }} variant="caption">
          {t("twoFactorAuth.downloadApp.subtitle2")}
        </Typography>
        <Stack spacing={2} direction={isMobile ? "column" : "row"} sx={{ pt: 3, pb: 6 }}>
          <DownloadBox sx={{ px: 3, py: 3 }}>
            <Stack direction="column" justifyContent="center" alignItems="center">
              <AppleOs sx={{ mr: 1, fontSize: 80, mt: 2 }} />
              <Box sx={{ pt: 3 }}>
                <Typography variant="h6" fontWeight={400}>
                  {t("twoFactorAuth.downloadApp.googleAuth")}
                </Typography>
              </Box>
              <Box sx={{ pb: 2 }}>
                <Typography>{t("twoFactorAuth.downloadApp.appStore")}</Typography>
              </Box>
              <Button
                color="success"
                variant="contained"
                component={MUILink}
                target={"_blank"}
                href="https://apps.apple.com/cz/app/google-authenticator/id388497605?l=cs"
                endIcon={<ArrowForwardIosIcon />}>
                {t("twoFactorAuth.downloadApp.button")}
              </Button>
            </Stack>
          </DownloadBox>
          <DownloadBox sx={{ px: 3, py: 3 }}>
            <Stack direction="column" justifyContent="center" alignItems="center">
              <Android sx={{ mr: 1, fontSize: 80, mt: 2 }} />
              <Box sx={{ pt: 3 }}>
                <Typography variant="h6" fontWeight={400}>
                  {t("twoFactorAuth.downloadApp.googleAuth")}
                </Typography>
              </Box>
              <Box sx={{ pb: 2 }}>
                <Typography>{t("twoFactorAuth.downloadApp.googlePlay")}</Typography>
              </Box>
              <Button
                color="success"
                variant="contained"
                component={MUILink}
                target={"_blank"}
                href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                endIcon={<ArrowForwardIosIcon />}>
                {t("twoFactorAuth.downloadApp.button")}
              </Button>
            </Stack>
          </DownloadBox>
        </Stack>
      </Stack>
    );
  };

  const scanQRcodeSection = () => {
    return (
      <Stack sx={{ alignItems: "center", pb: 7 }}>
        {enableMFALoading && <CircularProgress />}
        {enableMFAdata && (
          <>
            <CardMedia sx={{ width: "250px" }} component="img" src={enableMFAdata.qrImage} />
            <Typography sx={{ textAlign: "center", mb: 1 }} variant="caption">
              {t("twoFactorAuth.scanQRcode.tooltip")}
            </Typography>
            <Alert icon={<BorderColorIcon fontSize="inherit" />} sx={{ mb: 2 }} variant="standard" severity="warning">
              <Typography variant="body1" fontSize={isMobile ? "12px !important" : undefined} fontWeight={600}>
                {enableMFAdata.secret}
              </Typography>
            </Alert>
            <Typography sx={{ textAlign: "center", mb: 3 }} fontWeight={600} variant="body2" color="primary">
              {t("twoFactorAuth.scanQRcode.recovery1")}
            </Typography>
            <Typography sx={{ textAlign: "center", mb: 1, textDecoration: 'underline' }} fontWeight={600} variant="body2" color="primary">
              {t("twoFactorAuth.scanQRcode.recovery2")}
            </Typography>
            <FormControl error={Boolean(recoveryFormik.errors.recovery)} variant="standard">
              <FormControlLabel
                control={<Checkbox />}
                id={"recovery"}
                name={"recovery"}
                label={ResponsiveLabel(t("twoFactorAuth.scanQRcode.recoveryCheckbox"))}
                checked={recoveryFormik.values.recovery}
                onChange={recoveryFormik.handleChange}
              />
              {<FormHelperText>{recoveryFormik.errors.recovery}</FormHelperText>}
            </FormControl>
          </>
        )}
      </Stack>
    );
  };

  const verificationBackupCodeSection = () => {
    return (
      <Stack
        sx={{
          alignItems: "center",
          pt: 1,
          pb: 3,
          minWidth: isMobile ? undefined : "25rem",
          maxWidth: isMobile ? undefined : "35rem",
        }}>
        <Alert icon={<BorderColorIcon fontSize="inherit" />} sx={{ mb: 3 }} variant="standard" severity="warning">
          <Typography fontSize={isMobile ? "12px !important" : undefined} fontWeight={600}>
            {t("twoFactorAuth.backupCode.description")}
          </Typography>
        </Alert>
        <TextField
          autoComplete="off"
          fullWidth
          label={t("twoFactorAuth.backupCode.label")}
          value={backupCode}
          onChange={(e: any) => setBackupCode(e.target.value)}
          error={backupCodeError}
          helperText={backupCodeError && t("twoFactorAuth.backupCode.helperText")}
        />
      </Stack>
    );
  };

  const enableGoogleAuthSection = () => {
    return (
      <Stack sx={{ alignItems: "center", pt: 5, pb: 7 }}>
        <CodeField
          maxLength={6}
          fullWidth
          id="otp"
          name="otp"
          label={t("twoFactorAuth.enableGoogleAuth.label")}
          value={formik.values.otp}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.otp)}
          helperText={formik.errors.otp ?? t("twoFactorAuth.enableGoogleAuth.helperText")}
        />
      </Stack>
    );
  };

  const completeSection = () => {
    return (
      <Stack sx={{ alignItems: "center", pt: 4, pb: 7 }}>
        <CircleCheck sx={{ fontSize: 80, color: theme.palette.success.main, mr: 1 }} />
      </Stack>
    );
  };

  const checkBackupCode = () => {
    if (backupCode !== enableMFAdata?.secret) {
      setBackupCodeError(true);
    } else nextStep();
  };

  const handleDone = () => window.location.replace("/login");

  const handleNext = () => {
    switch (activeStep) {
      case 1:
        recoveryFormik.handleSubmit();
        break;
      case 2:
        checkBackupCode();
        break;
      case 3:
        formik.handleSubmit();
        break;
      default:
        nextStep();
    }
  };

  return (
    <>
      {!isMobile && (
        <Stepper
          activeStep={activeStep}
          isFirstStep={isFirstStep}
          isLastStep={isLastStep}
          steps={steps}
          title={getSubtitle()}
          onNext={handleNext}
          onBack={backStep}
          onDone={handleDone}>
          {getSection()}
        </Stepper>
      )}
      {isMobile && (
        <MobileStepper
          activeStep={activeStep}
          isFirstStep={isFirstStep}
          isLastStep={isLastStep}
          steps={steps}
          title={getSubtitle()}
          onNext={handleNext}
          onBack={backStep}
          onDone={handleDone}>
          {getSection()}
        </MobileStepper>
      )}
      <BackdropLoading open={isOpenBackdropLoading} />
    </>
  );
};

export default Enable;
