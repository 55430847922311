import { useTheme } from "@mui/material";
import Grid from "@mui/material/Grid";
import useMediaQuery from "@mui/material/useMediaQuery";
import Card from "components/elements/Card";
import useExchangesMetadata from "hooks/useExchangesMetadata";
import { ErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import { IBaseCurrencyProfitChart } from "types/types";

import Chart from "./chart";
import ErrorFallback from "./ErrorFallback";

interface Props {
  chartData: IBaseCurrencyProfitChart;
  baseCurrency: string;
  currencyPair: string;
  exchangeEnum?: string;
}

const ChartProfit = ({ chartData, baseCurrency, currencyPair, exchangeEnum }: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { baseCurrDisplayedScale } = useExchangesMetadata(exchangeEnum ?? "", currencyPair);

  return (
    <Card header={t("bots.profitChart.title")} sx={{ mb: 0 }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12}>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <Chart
              chartData={chartData}
              isMobile={isMobile}
              baseCurrency={baseCurrency}
              baseCurrDisplayedScale={baseCurrDisplayedScale}
            />
          </ErrorBoundary>
        </Grid>
      </Grid>
    </Card>
  );
};

export default ChartProfit;
