import * as React from "react";

import SidebarMenu from "./SidebarMenu";
import { userSidebarItems } from "./userSidebarItems";

interface Props {
  drawerOpen: boolean;
  drawerWidth: number;
  userLoggedIn: boolean;
  onItemClick: () => void;
  botsList: {
    enabledList: React.ReactNode[];
    disabledList: React.ReactNode[];
  };
}

const UserSidebarMenu: React.FC<Props> = ({ drawerOpen, drawerWidth, onItemClick, userLoggedIn, botsList }) => {
  return (
    <SidebarMenu
      userLoggedIn={userLoggedIn}
      sidebarItems={userSidebarItems(userLoggedIn)}
      drawerOpen={drawerOpen}
      drawerWidth={drawerWidth}
      onItemClick={onItemClick}
      botsList={botsList}
    />
  );
};

export default UserSidebarMenu;
