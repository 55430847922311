import { SvgIcon } from '@mui/material';

export const AppleOs = (props: any) => {
  return (
    <SvgIcon {...props}>
      <svg width="24" height="24" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M57.8667 46.6664C57.8956 44.1607 58.5589 41.7031 59.7947 39.5231C61.0304 37.3431 62.7983 35.5117 64.9333 34.1998C63.5741 32.2678 61.787 30.6759 59.7112 29.5484C57.6354 28.4209 55.3272 27.7882 52.9667 27.6998C47.8667 27.1998 42.9667 30.6998 40.4333 30.6998C37.9 30.6998 33.7667 27.7664 29.6333 27.8331C26.8553 27.9069 24.1441 28.7016 21.7657 30.1391C19.3873 31.5767 17.4234 33.6077 16.0667 36.0331C10.3 46.0331 14.6 60.8998 20.2333 69.0331C22.9667 72.9998 26.2667 77.4664 30.5667 77.2998C34.8667 77.1331 36.3 74.6331 41.3 74.6331C46.3 74.6331 47.7333 77.2998 52.1 77.2331C56.4667 77.1664 59.4 73.1664 62.1 69.1998C64.0533 66.3667 65.5911 63.2686 66.6667 59.9998C64.0527 58.8869 61.8242 57.0291 60.2593 54.658C58.6944 52.2868 57.8622 49.5074 57.8667 46.6664V46.6664Z" stroke="currentColor" strokeWidth="1.6125" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M51.0333 14.5331C53.8474 11.2072 55.2473 6.91171 54.9333 2.56641C50.6635 2.96547 46.7118 4.99505 43.9 8.23307C42.487 9.82807 41.4127 11.6935 40.7423 13.7161C40.0719 15.7388 39.8194 17.8765 40 19.9997C42.135 19.9948 44.2403 19.4992 46.1533 18.5513C48.0664 17.6035 49.736 16.2287 51.0333 14.5331Z" stroke="currentColor" strokeWidth="1.6125" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    </SvgIcon>
  );
}
