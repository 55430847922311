import { useState } from "react";

import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Box,
  useMediaQuery,
  Link,
  Button,
  Collapse,
} from "@mui/material";
import useTheme from "@mui/material/styles/useTheme";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { useTranslation } from "react-i18next";

interface TableContent {
  name: string;
  limit: string;
  market: string;
  withdrawal: string;
  link: string;
}

const exchanges: TableContent[] = [
  {
    name: "Coinmate",
    limit: "až 0,4 %",
    market: "~1,5 %*",
    withdrawal: "dynamický",
    link: "https://coinmate.io/cs/fees",
  },
  {
    name: "Anycoin",
    limit: "~1,6 %",
    market: "~1,6 %",
    withdrawal: "0,00001 BTC",
    link: "https://www.anycoin.cz/fees",
  },
  {
    name: "Binance",
    limit: "až 0,1 %",
    market: "až 0,1 %",
    withdrawal: "0,0005 BTC",
    link: "https://www.binance.com/en/fee/schedule",
  },
  {
    name: "Bitstamp",
    limit: "až 0,5 %",
    market: "až 0,5 %",
    withdrawal: "0,0005 BTC",
    link: "https://www.bitstamp.net/fee-schedule/",
  },
];

const ExchangeComparison = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [comparisonOpen, setComparisonOpen] = useState(false);
  const { colors } = useMUIThemeModeContext();

  const toggleComparison = () => {
    setComparisonOpen(!comparisonOpen);
  };

  {
    comparisonOpen && (
      <Box sx={{ mt: 3 }}>
        <ExchangeComparison />
      </Box>
    );
  }

  return (
    <>
      <Button
        onClick={toggleComparison}
        variant="text"
        endIcon={comparisonOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}>
        {t("exchangeComparison.cta")}
      </Button>
      <Collapse in={comparisonOpen}>
        <Box p={2}>
          {isMobile && (
            <>
              {exchanges.map((exchange, index) => (
                <Box
                  sx={{
                    mb: 1,
                    p: 1,
                    background: colors["lightGray"],
                    borderRadius: "10px",
                    fontSize: "0.8em",
                  }}
                  key={index}>
                  <TableContainer>
                    <Table>
                      <TableBody
                        sx={{
                          "tr td": {
                            fontWeight: 400,
                            color: theme.palette.grey[700],
                            border: 0,
                            padding: "0.3em",
                          },
                          "& > tr td:first-of-type": {
                            fontWeight: 600,
                            color: theme.palette.grey[700],
                          },
                        }}>
                        <TableRow>
                          <TableCell>{t("exchangeComparison.name")}</TableCell>
                          <TableCell align="right">
                            <strong>{exchange.name}</strong>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>{t("exchangeComparison.limit")}</TableCell>
                          <TableCell align="right">{exchange.limit}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>{t("exchangeComparison.market")}</TableCell>
                          <TableCell align="right">{exchange.market}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>{t("exchangeComparison.withdrawal")}</TableCell>
                          <TableCell align="right">{exchange.withdrawal}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="right" colSpan={2}>
                            <Link href={exchange.link} target="_blank">
                              {t("exchangeComparison.link")}
                            </Link>
                          </TableCell>
                        </TableRow>
                        {exchange.name === "Coinmate" && (
                          <TableRow
                            sx={{
                              background: colors["lightGray"],
                            }}>
                            <TableCell
                              sx={{
                                padding: "1.2em 1em 0.6em !important",
                                fontSize: "0.9em !important",
                                fontWeight: "400 !important",
                              }}
                              colSpan={2}>
                              <Box sx={{ mb: 1 }}>* {t("exchangeComparison.coinmateNote1")}</Box>
                              <Box>* {t("exchangeComparison.coinmateNote2")}</Box>
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              ))}
            </>
          )}
          {!isMobile && (
            <Box sx={{ borderRadius: "10px", overflow: "hidden" }}>
              <TableContainer>
                <Table>
                  <TableBody
                    sx={{
                      fontSize: "0.8em",
                      p: 1,
                      "tr td": {
                        fontSize: "1em",
                        fontWeight: 400,
                        color: theme.palette.grey[700],
                        border: 0,
                      },
                      "& > tr td:first-of-type": {
                        fontWeight: 600,
                        color: theme.palette.grey[700],
                      },
                    }}>
                    <TableRow sx={{ background: colors["lightGray"] }}>
                      <TableCell>{t("exchangeComparison.name")}</TableCell>
                      {exchanges.map((exchange, index) => (
                        <TableCell key={index}>
                          <strong>{exchange.name}</strong>
                        </TableCell>
                      ))}
                    </TableRow>
                    <TableRow sx={{ background: colors["lightGray"] }}>
                      <TableCell>{t("exchangeComparison.limit")}</TableCell>
                      {exchanges.map((exchange, index) => (
                        <TableCell key={index}>{exchange.limit}</TableCell>
                      ))}
                    </TableRow>
                    <TableRow sx={{ background: colors["lightGray"] }}>
                      <TableCell>{t("exchangeComparison.market")}</TableCell>
                      {exchanges.map((exchange, index) => (
                        <TableCell key={index}>{exchange.market}</TableCell>
                      ))}
                    </TableRow>
                    <TableRow sx={{ background: colors["lightGray"] }}>
                      <TableCell>{t("exchangeComparison.withdrawal")}</TableCell>
                      {exchanges.map((exchange, index) => (
                        <TableCell key={index}>{exchange.withdrawal}</TableCell>
                      ))}
                    </TableRow>
                    <TableRow sx={{ background: colors["lightGray"] }}>
                      <TableCell></TableCell>
                      {exchanges.map((exchange, index) => (
                        <TableCell key={index}>
                          <Link href={exchange.link} target="_blank">
                            {t("exchangeComparison.link")}
                          </Link>
                        </TableCell>
                      ))}
                    </TableRow>
                    <TableRow
                      sx={{
                        background: colors["lightGray"],
                        borderTop: `1px solid ${theme.palette.grey[300]}`,
                      }}>
                      <TableCell
                        sx={{ fontSize: "0.9em !important", fontWeight: "400 !important" }}
                        colSpan={exchanges.length + 1}>
                        <Box sx={{ mb: 1 }}>* {t("exchangeComparison.coinmateNote1")}</Box>
                        <Box>* {t("exchangeComparison.coinmateNote2")}</Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}
        </Box>
      </Collapse>
    </>
  );
};

export default ExchangeComparison;
