const PersonalDataProcesing = () => {
  return (
    <>
      <ol>
        <li>
          <p>Úvodní informace</p>
        </li>
        <ol type="a">
          <li>
            <p>
              Obchodní společnost Stosuj s.r.o., IČO: 143 99 831, se sídlem: Kaprova 42/14, Staré Město, 110 00 Praha,
              zapsaná v obchodním rejstříku vedeném u Městského soudu v Praze, oddíl C, vložka 364569 („Společnost"),
              tímto poskytuje informace o způsobu a rozsahu zpracování osobních údajů svých klientů (dále jen „Klient"),
              včetně práv Klientů souvisejících se zpracováním osobních údajů (dále jen „Zásady"), a to v souladu se
              zákonem č. 110/2019 Sb., o zpracování osobních údajů a Nařízením (EU) 2016/679.
            </p>
          </li>
        </ol>
        <li>
          <p>Správce osobních údajů a způsob zpracování údajů</p>
        </li>
        <ol type="a">
          <li>
            <p>
              Společnost, jakožto správce osobních údajů, shromažďuje, uchovává a využívá osobní údaje ve spojení se
              svojí podnikatelskou činností a za účelem jejich řádné správy byly vytvořeny tyto Zásady.
            </p>
          </li>
          <li>
            <p>
              Zpracování a uchovávání osobních údajů probíhá v sídle Společnosti a je prováděno výlučně zaměstnanci
              Společnosti, nestanoví-li tyto Zásady jinak. Zpracování osobních údajů probíhá manuálně i automatizovaně v
              elektronických informačních systémech, a to v elektronické nebo listinné podobě, vždy při vysokém
              technickém, organizačním a personálním zabezpečení ve smyslu požadavků příslušné legislativy.
            </p>
          </li>
          <li>
            <p>
              Pakliže Klient využije služby Společnosti, kontaktní údaje Klienta může Společnost využít k tomu, aby
              Klientovi zasílala obchodní sdělení, tj. aby jej kontaktovala o službách, které poskytuje a které by
              Klienta mohly zajímat. Z odebírání obchodních sdělení se Klient může kdykoliv odhlásit odkazem v emailech
              nebo zasláním zprávy na info@stosuj.cz
            </p>
          </li>
        </ol>
        <li>
          <p>Účel zpracování osobních údajů</p>
        </li>
        <ol type="a">
          <li>
            <p>
              Společnost působí při zpracování osobních údajů jako správce a určuje tak, za jakým účelem a jakými
              prostředky bude docházet ke zpracování osobních údajů. Osobní údaje jsou zpracovávány buď: (i) bez
              souhlasu Klienta na základě plnění smlouvy, oprávněného zájmu Společnosti nebo z důvodu plnění právní
              povinnosti, nebo (ii) na základě souhlasu Klienta. Společnost je oprávněna zpracovávat osobní údaje
              zejména pro následující účely
            </p>
          </li>
          <ol type="i">
            <li>
              <p>Zpracování bez souhlasu Klienta</p>
            </li>
            <ol>
              <li>
                <p>
                  Plnění smluvního vztahu - jednání o uzavření, změně nebo plnění smlouvy se stávajícím či potenciálním
                  Klientem, přičemž služby spočívají zejména v umožnění nákupu a prodeje kryptoměn za zákonnou měnu
                  prostřednictvím třetích stran, zejména anycoin.cz.
                </p>
              </li>
              <li>
                <p>
                  Plnění právních povinností - povinnosti ve smyslu účetní a daňové legislativy, archivační povinnost.
                  Plněním právních povinností se rozumí také poskytování údajů a informací orgánům činným v trestním
                  řízení, případně dalším orgánům veřejné moci v souladu s příslušnými právními předpisy. Plnění
                  právních povinností se dále rozumí regulatorní povinnosti Společnosti zejména dle následujících
                  předpisů:
                </p>
              </li>
              <ol type="a">
                <li>
                  <p>zákon č. 634/1992 Sb., o ochraně spotřebitele (tento zákon upravuje práva spotřebitele)</p>
                </li>
                <li>
                  <p>zákon č. 136/2011 Sb., o oběhu bankovek a mincí.</p>
                </li>
              </ol>
              <li>
                <p>
                  Oprávněný zájem Společnosti - ochrana práv a právem chráněných zájmů Společnosti, například posuzování
                  důvěryhodnosti Klienta a související získávání informací z veřejných zdrojů i soukromých zdrojů.
                </p>
              </li>
            </ol>
            <li>
              <p>Zpracování se souhlasem Klienta</p>
            </li>
            <ol>
              <li>
                <p>
                  Zasílání a sdělování obchodních sdělení a marketingová činnost - nabízení služeb Společnosti a její
                  propagace vůči stávajícím i potenciálním Klientům, předávání údajů třetím stranám pro zobrazení
                  nabídek na dalších webech.
                </p>
              </li>
              <li>
                <p>
                  Soubory cookies - cookies jsou textové soubory obsahující krátké údaje, které mohou být při návštěvě
                  webové stránky uloženy do zařízení Klienta. Na webových stránkách Společnosti jsou soubory cookies
                  používány k následujícím účelům: (i) funkční - vyžadovány pro základní fungování stránek. (ii) zvýšení
                  komfortu - slouží pro zlepšování funkcionalit webu, (iii) sociální média a personalizace zobrazování
                  reklam - možnost propojení se sociálními sítěmi a přizpůsobování reklamy uživatelské historii Klienta.
                </p>
              </li>
            </ol>
          </ol>
        </ol>
        <li>
          <p>Rozsah zpracovaných údajů</p>
        </li>
        <ol type="a">
          <li>
            <p>
              Společnost je oprávněna zpracovávat následující osobní údaje, které získává od Klientů v souvislosti s
              jednáním o uzavření smlouvy a v souvislosti s plněním smlouvy, dle níže uvedených účelů zpracování.
            </p>
            <table>
              <tbody>
                <tr>
                  <td>
                    <p>Osobní údaje Klienta</p>
                  </td>
                  <td>
                    <p>Účely zpracování</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>E-mail</p>
                  </td>
                  <td>
                    <p>Plnění smluvního vztahu, Plnění právních povinností.</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Údaje o využívání služeb Společnosti, transakční údaje a údaje ze vzájemné komunikace</p>
                  </td>
                  <td>
                    <p>
                      Plnění smluvního vztahu, Plnění právních povinností, Oprávněný zájem Společnosti, Zasílání
                      obchodních sdělení (pokud Klient udělil souhlas).
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Údaje o chování Klientů na webu</p>
                  </td>
                  <td>
                    <p>
                      Plnění smluvního vztahu, Plnění právních povinností, Oprávněný zájem Společnosti, Zasílání a
                      sdělování obchodních sdělení (pokud Klient udělil souhlas), Soubory cookies.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Údaje o smlouvě a jejím plnění.</p>
                  </td>
                  <td>
                    <p>Plnění smluvního vztahu, Plnění právních</p>
                    <p>povinností, Oprávněný zájem Společnosti.</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </li>
        </ol>
        <li>
          <p>Přístup k osobním údajům</p>
        </li>
        <ol type="a">
          <li>
            <p>
              Osobní údaje jsou zpřístupněny pouze oprávněným zaměstnancům Společnosti, či jednotlivým zpracovatelům
              osobních údajů, a to pouze v míře nezbytně nutné pro naplnění jednotlivých účelů zpracování, případně na
              základě souhlasu Klientů se zpracováním osobních údajů.
            </p>
          </li>
        </ol>
        <li>
          <p>Zpracovatelé osobních údajů</p>
        </li>
        <ol type="a">
          <li>
            <p>
              Společnost dále jako správce pověřuje zpracováním osobních údajů další subjekty, jako tzv. zpracovatele.
              Zpracovatelem osobních údajů je subjekt, který pro Společnost zpracovává osobní údaje, pro Společností
              určený účely, přičemž ochranu osobních údajů Společnost zajišťuje smluvně tak, aby byla garantováno
              technické i organizační zabezpečení údajů Klientů a nemohlo tak dojít k jejich zneužití. Všichni
              zpracovatelé jsou vázání smluvní mlčenlivostí a nesmějí údaje Klientů používat k jiným účelům, než ke
              kterým jim je Společnost zpřístupňujeme.
            </p>
          </li>
          <li>
            <p>
              Se souhlasem Klienta Společnost údaje předává také reklamním a sociálním sítím pro zobrazení reklamy na
              dalších webech. V zákonem stanovených případech jsme povinni některé Vaše osobní údaje předat na základě
              platných právních předpisů např. Policii ČR, České národní bance, Finančnímu analytickému úřadu, popř.
              jiným orgánům činným v trestním řízení a dalším orgánům veřejné správy.
            </p>
          </li>
          <li>
            <p>
              Zpracovateli osobních údajů jsou: (i) externí účetní společnost, (ii) externí advokátní kancelář, (iii)
              zpracovatelé, kteří Společnosti poskytují serverové, webové, cloudové nebo IT služby, či kteří jsou
              obchodními partnery Společnosti.
            </p>
          </li>
        </ol>
        <li>
          <p>
            Klient, jakožto subjekt údajů, má v souvislosti se zpracováním osobních údajů práva, která vyplývají z
            právních předpisů a která může kdykoliv uplatnit. Jedná se o následující práva:
          </p>
        </li>
        <ol type="a">
          <li>
            <p>
              Právo na informace a přístup k osobním údajům: chce-li Klient vědět, zda Společnost zpracovává osobní
              údaje, má právo získat informace o tom, jestli jsou jeho osobní údaje zpracovávány, a pokud tomu tak je,
              má právo k těmto osobním údajům získat přístup.
            </p>
          </li>
          <li>
            <p>
              Právo na opravu: v případě, že Klient dojde k závěru, že Společnost zpracovává nepřesné či neúplné osobní
              údaje, má právo požadovat jejich opravu a doplnění. Společnost provede opravu či doplnění údajů bez
              zbytečného odkladu.
            </p>
          </li>
          <li>
            <p>
              Právo na výmaz: v případě, že Klient požádá o výmaz, Společnost vymaže jeho osobní údaje pokud (i) již
              nejsou potřebné pro účely, pro které byly shromážděny nebo jinak zpracovány, (ii) zpracování je
              protiprávní, (iii) Klient vznese námitky proti zpracování a neexistují žádné převažující oprávněné důvody
              pro zpracování jeho osobních údajů.
            </p>
          </li>
          <li>
            <p>
              Právo na omezení zpracování osobních údajů: v případě, že Klient požádá o omezení zpracování, Společnost
              osobní údaje znepřístupní, dočasně odstraní či uchová anebo provede jiné úkony zpracování, které budou
              potřebné pro řádný výkon uplatněného práva.
            </p>
          </li>
          <li>
            <p>
              Právo na přenositelnost údajů: v případě, že o to Klient požádá, osobní údaje, které se ho týkají a které
              předal Společnosti, mu budou předány ve strukturovaném, běžně používaném a strojově čitelném formátu.
            </p>
          </li>
          <li>
            <p>Právo vznést námitku: právo vznést námitku proti zpracování osobních údajů.</p>
          </li>
          <li>
            <p>
              Právo obrátit se na dozorový úřad: Klient má právo kontaktovat Úřad pro ochranu osobních údajů, adresa:
              Pplk. Sochora 27, 170 00, Praha 7, a podat stížnost týkající se ochrany údajů a soukromí a související s
              činností Společnosti.
            </p>
          </li>
          <li>
            <p>
              Právo odvolat souhlas: v případě, že Klient poskytl souhlas se zpracováním osobním údajů pro účely, které
              vyžadují souhlas, má právo kdykoli tento souhlas odvolat.
            </p>
          </li>
        </ol>
        <li>
          <p>Doba zpracování údajů</p>
        </li>
        <ol type="a">
          <li>
            <p>
              Společnost zpracovává osobní údaje za účelem plnění právních povinností po celou dobu trvání smlouvy a po
              dobu 10 let po jejím ukončení smlouvy. Obchodní sdělení jsou zasílána Klientům do doby zrušení jejich
              odběru nebo odvolání jejich souhlasu. Další zpracování osobních údajů nad rámec uvedených lhůt provádí
              Společnost pouze v případě, že je to nezbytné pro účely oprávněných zájmů Společnosti nebo pro splnění
              povinností vyplývajících z právních předpisů, a to po dobu nezbytně nutnou.
            </p>
          </li>
          <li>
            <p>
              Osobní údaje, které Společnost zpracovává na základě souhlasu Klienta bude zpracovávat do doby, než Klient
              souhlas odvolá. Pro odvolání souhlasu je nutné zaslat e-mail s příslušnou žádostí na info@stosuj.cz
            </p>
          </li>
        </ol>
        <li>
          <p>Zabezpečení údajů</p>
        </li>
        <ol type="a">
          <li>
            <p>
              Společnost klade při zpracování osobních údajů důraz na technické i organizační zabezpečení zpracovávaných
              údajů a za tímto účelem přijalo odpovídající opatření a vytvořila systém interních pravidel. Veškeré
              osobní údaje v elektronické formě jsou uloženy v databázích a informačních systémech, k nimž mají přístup
              pouze osoby, které potřebují s osobními údaji bezprostředně nakládat pro účely uvedené v těchto Zásadách,
              a to pouze v nezbytném rozsahu.
            </p>
          </li>
        </ol>
        <li>
          <p>Závěrečné informace</p>
        </li>
        <ol type="a">
          <li>
            <p>
              Tyto Zásady jsou účinné od 1. 3. 2022. Jakékoliv dotazy a požadavky ve vztahu ke zpracování osobních údajů
              s námi můžete konzultovat prostřednictvím e-mailové adresy: info@stosuj.cz
            </p>
          </li>
        </ol>
      </ol>
    </>
  );
};

export default PersonalDataProcesing;
