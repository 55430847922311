import validations from "constants/validations";

import { useState } from "react";

import { CircularProgress, Box, Stack, InputAdornment, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import { editWithdrawalConnection } from "API/calls";
import Modal from "components/elements/Modal";
import PriceField from "components/elements/PriceField";
import ResponsiveLabel from "components/elements/ResponsiveLabel";
import { useFormik } from "formik";
import { useAPIExchangeWithdrawalFee, useAPIExchanges, useAPIWithdrawalConnectionsList } from "hooks/useAPI";
import { useSnackbar } from "notistack";
import { Trans, useTranslation } from "react-i18next";
import { getNumberWithComma } from "utils/formatter";
import * as yup from "yup";

import { FEE } from "../../CreateModal";
import { InputWrapper } from "./styles";
import IProps from "./types";

const EditModal = ({ isOpen, handleClose, id, label, btcAddress, btcThreshold, maxFee, exchangeEnum }: IProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { mutate } = useAPIWithdrawalConnectionsList();
  const { data } = useAPIExchanges();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { data: withdrawalFeeData } = useAPIExchangeWithdrawalFee(exchangeEnum, "BTC", false);

  const btcThresholdValidation = (value?: string, context?: yup.TestContext<any>) => {
    if (value && context) {
      const min = 0.001;
      const max = 20999999.9769;
      const val = Number(value.replace(",", "."));

      if (val < min) {
        return context.createError({
          message: t("withdrawalConnections.modal.minBtcValue", { value: `${min}`.replace(".", ",") }),
        });
      }
      if (val > max) {
        return context.createError({
          message: t("withdrawalConnections.modal.maxBtcValue", { value: `${max}`.replace(".", ",") }),
        });
      }
    }

    return true;
  };

  const maxFeeValidation = (value?: string, context?: yup.TestContext<any>) => {
    if (value && context) {
      const min = FEE.min;
      const max = FEE.max;
      const val = Number(value.replace(",", "."));

      if (val < min) {
        return context.createError({
          message: t("withdrawalConnections.modal.minFeeValue", { value: min }),
        });
      }
      if (val > max) {
        return context.createError({
          message: t("withdrawalConnections.modal.maxFeeValue", { value: max }),
        });
      }
    }

    return true;
  };

  const initValues = {
    label: label ?? "",
    btcAddress: btcAddress,
    btcThreshold: `${btcThreshold}`.replace(".", ","),
    maxFee: `${maxFee}`.replace(".", ","),
  };
  const defaultSchema = {
    label: yup.string().max(50, t("fieldMaxLength", { max: 50 })),
    btcAddress: yup.string().required(t("fieldRequired")).matches(validations.btcAddress, t("fieldErrors.btcAddress")),
    btcThreshold: yup
      .string()
      .required(t("fieldRequired"))
      .test("btcThresholdValue", "btcThresholdValueError", btcThresholdValidation),
    maxFee: yup.string().required(t("fieldRequired")).test("maxFeeValue", "maxFeeValueError", maxFeeValidation),
  };

  const formik = useFormik({
    initialValues: initValues,
    validationSchema: yup.object(defaultSchema),
    onSubmit: async (values) => {
      const { btcThreshold: editedBtcThreshold, maxFee: editedMaxFee, ...parameters } = values;

      try {
        setIsLoading(true);
        await editWithdrawalConnection(id, {
          btcThreshold: Number(`${editedBtcThreshold}`.replace(",", ".")),
          maxFee: Number(`${editedMaxFee}`.replace(",", ".")),
          ...parameters,
        });
        await mutate();

        enqueueSnackbar(t("withdrawalConnections.editModal.successMessage"), { variant: "success" });
        handleCloseModal();
      } catch (err: any) {
        enqueueSnackbar(t("withdrawalConnections.editModal.errorMessage"), { variant: "error" });
      } finally {
        setIsLoading(false);
      }
    },
  });

  const handleCloseModal = () => {
    setIsLoading(false);
    handleClose();
  };

  const getExchangeLabel = () => {
    return data?.exchanges.find((exchange) => exchange.exchangeEnum === exchangeEnum)?.title ?? exchangeEnum;
  };

  return (
    <Modal
      open={isOpen}
      confirmTitle={t("update")}
      onConfirm={formik.handleSubmit}
      onCancel={handleClose}
      title={t("withdrawalConnections.editModal.label")}
      withCloseButton>
      {isLoading && (
        <Box sx={{ pt: 8, pb: 12, alignSelf: "center", textAlign: "center" }}>
          <Stack spacing={4} sx={{ display: "flex", alignItems: "center" }}>
            <CircularProgress size={64} />
          </Stack>
        </Box>
      )}
      {!isLoading && (
        <>
          <InputWrapper>
            <TextField
              fullWidth
              label={ResponsiveLabel(t("withdrawalConnections.modal.exchange"))}
              value={getExchangeLabel()}
              disabled
            />
          </InputWrapper>
          <InputWrapper>
            <TextField
              fullWidth
              autoComplete="off"
              id="label"
              name="label"
              label={ResponsiveLabel(t("withdrawalConnections.modal.label"))}
              value={formik.values.label}
              onChange={formik.handleChange}
              error={Boolean(formik.errors.label)}
              helperText={formik.errors.label}
            />
          </InputWrapper>
          <InputWrapper>
            <TextField
              fullWidth
              autoComplete="off"
              id="btcAddress"
              name="btcAddress"
              label={ResponsiveLabel(t("withdrawalConnections.modal.btcAddress"))}
              value={formik.values.btcAddress}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.btcAddress && Boolean(formik.errors.btcAddress)}
              helperText={formik.touched.btcAddress && formik.errors.btcAddress}
            />
          </InputWrapper>
          <InputWrapper>
            <PriceField
              autoComplete="off"
              allowZero
              precision={8}
              fullWidth={true}
              id="btcThreshold"
              name="btcThreshold"
              label={ResponsiveLabel(t("withdrawalConnections.modal.btcThreshold"))}
              value={formik.values.btcThreshold}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={Boolean(formik.errors.btcThreshold)}
              helperText={formik.errors.btcThreshold}
              InputProps={{
                endAdornment: <InputAdornment position="start">{"BTC"}</InputAdornment>,
              }}
            />
          </InputWrapper>
          <Typography variant="body2" sx={{ pt: 1, pb: 1 }}>
            <Trans i18nKey="withdrawalConnections.modal.btcThresholdDescription" />
          </Typography>
            <Typography variant="body2" sx={{ pt: 1 }}>
              <Trans
                i18nKey="withdrawalConnections.modal.currentFee"
                values={{ value: getNumberWithComma(withdrawalFeeData?.fee ?? "-") }}
              />
            </Typography>
          <InputWrapper>
            <PriceField
              autoComplete="off"
              allowZero
              precision={8}
              fullWidth={true}
              id="maxFee"
              name="maxFee"
              label={ResponsiveLabel(t("withdrawalConnections.modal.maxFee"))}
              value={formik.values.maxFee}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={Boolean(formik.errors.maxFee)}
              helperText={formik.errors.maxFee}
              InputProps={{
                endAdornment: <InputAdornment position="start">{"BTC"}</InputAdornment>,
              }}
            />
          </InputWrapper>
          <Typography variant="body2" sx={{ pt: 1, pb: 1 }}>
            <Trans i18nKey="withdrawalConnections.modal.maxFeeDescription" />
          </Typography>
        </>
      )}
    </Modal>
  );
};

export default EditModal;
