import { TREZOR_AFFILIATE } from "constants/links";
import { TREZOR_AFFILIATE_TTL } from "constants/localStorageExpiryDate";

import { useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import { Link } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import trezorAffiliate from "assets/images/trezor-affiliate.png";
import Panel from "components/elements/Panel";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { setWithExpiry, getWithExpiry } from "hooks/useTTLLocalStorage";
import { Trans } from "react-i18next";

const trezorAffiliateLink = `${TREZOR_AFFILIATE}&source=dashboard`;
const disableTrezorAffiliateKey = "disableTrezorAffiliateV7";

const TrezorAffiliateTile = () => {
  const [close, setClose] = useState(false);
  const disabledTrezorAffiliate = getWithExpiry(disableTrezorAffiliateKey, TREZOR_AFFILIATE_TTL);
  const { colors } = useMUIThemeModeContext();

  const handleClose = () => {
    setWithExpiry(disableTrezorAffiliateKey, "true");
    setClose(true);
  };

  if (disabledTrezorAffiliate || close) return null;

  return (
    <Grid item md={6} xs={12}>
      <Panel
        sx={{ p: 3 }}
        height={"100%"}
        id="trezorAffiliate"
        border={`1px solid ${colors.gray200}`}
        background={colors.gray50}
        shadow={false}>
        <Stack flexDirection={"row"} justifyContent={"space-between"}>
          <Typography variant="h5" component="h1" fontWeight={600} sx={{ pb: 1, color: "tertiary.main" }}>
            <Trans i18nKey="dcaBots.trezorAffiliate.title" />
          </Typography>
          <IconButton onClick={handleClose} color="primary" component="span" edge="end" aria-label="close">
            <CloseIcon />
          </IconButton>
        </Stack>
        <Typography variant="subtitle2" fontWeight={400} sx={{ pb: 0 }}>
          <Trans
            i18nKey="dcaBots.trezorAffiliate.subtitle"
            components={{
              a: <Link href={trezorAffiliateLink} />
            }}
          />
        </Typography>
        <Stack pt={2} direction={"column"} alignItems="center">
          <Box>
            <a href={trezorAffiliateLink} target="_blank">
              <img src={trezorAffiliate} alt={trezorAffiliateLink} style={{ maxWidth: "100%", maxHeight: "260px" }} />
            </a>
          </Box>
        </Stack>
      </Panel>
    </Grid>
  );
};

export default TrezorAffiliateTile;
