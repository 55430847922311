import { COINMATE_FEES, GRID_STRATEGY_HOW_TO } from "constants/links";

import { Link, Alert, useTheme } from "@mui/material";
import { COINMATE_FEE, COINMATE_FEE_VOLUME } from "components/pages/BacktestGridBot";
import { Trans } from "react-i18next";

export const hasCoinmateTradingFeesAlert = (gridPercentStep: number, isCoinmate: boolean, makerFee?: number) => {
  if (!makerFee || !isCoinmate) return false;

  const isGridPercentStep1 = gridPercentStep === 1;
  const isGridPercentStep05 = gridPercentStep === 0.5;

  return (
    (makerFee === COINMATE_FEE[0] && isGridPercentStep1)
    || ((makerFee === COINMATE_FEE[0] || makerFee === COINMATE_FEE[1]) && isGridPercentStep05)
  );
};

interface IProps {
  isCoinmate: boolean;
  gridPercentStep: number;
  makerFee?: number;
}

const CoinmateTradingFeesAlert = ({ makerFee, isCoinmate, gridPercentStep }: IProps) => {
  const theme = useTheme();

  if (!makerFee || !isCoinmate) return null;

  if (makerFee === COINMATE_FEE[0] && gridPercentStep === 1) {
    return (
      <Alert severity="warning">
        <Trans
          i18nKey="gridBot.create.coinmateTradingFees.warning"
          values={{
            defaultFee: `${COINMATE_FEE[0]}`.replace(".", ","),
            makerFee: `${makerFee}`.replace(".", ","),
            percentStep: `${gridPercentStep}`.replace(".", ","),
            amount: COINMATE_FEE_VOLUME[1],
            newFee: `${COINMATE_FEE[1]}`.replace(".", ","),
          }}
          components={{
            a1: <Link color={theme.palette.warning.main} href={COINMATE_FEES} target="_blank" />,
            a2: <Link color={theme.palette.warning.main} href={GRID_STRATEGY_HOW_TO} target="_blank" />,
          }}
        />
      </Alert>
    );
  }

  if ((makerFee === COINMATE_FEE[0] || makerFee === COINMATE_FEE[1]) && gridPercentStep === 0.5) {
    return (
      <Alert severity="warning">
        <Trans
          i18nKey="gridBot.create.coinmateTradingFees.warning"
          values={{
            defaultFee: `${COINMATE_FEE[0]}`.replace(".", ","),
            makerFee: `${makerFee}`.replace(".", ","),
            percentStep: `${gridPercentStep}`.replace(".", ","),
            amount: COINMATE_FEE_VOLUME[2],
            newFee: `${COINMATE_FEE[2]}`.replace(".", ","),
          }}
          components={{
            a1: <Link color={theme.palette.warning.main} href={COINMATE_FEES} target="_blank" />,
            a2: <Link color={theme.palette.warning.main} href={GRID_STRATEGY_HOW_TO} target="_blank" />,
          }}
        />
      </Alert>
    );
  }

  return (
    <Alert severity="info">
      <Trans
        i18nKey="gridBot.create.coinmateTradingFees.info"
        values={{ makerFee: `${makerFee}`.replace(".", ",") }}
        components={{
          a1: <Link color={theme.palette.info.main} href={COINMATE_FEES} target="_blank" />,
          a2: <Link color={theme.palette.info.main} href={GRID_STRATEGY_HOW_TO} target="_blank" />,
        }}
      />
    </Alert>
  );
};

export default CoinmateTradingFeesAlert;
