import React from "react";

import { Helmet, HelmetProvider } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import Router from "router/router";
import { isProduction } from "utils";

import "./i18n/i18n";

const App: React.FC = () => {
  const { t } = useTranslation<string>();

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>{t("meta_title")}</title>
          <meta name="description" content={t("meta_description")} />
          <meta property="twitter:title" content={t("meta_title")} />
          <meta property="twitter:description" content={t("meta_description")} />
          <meta property="og:title" content={t("meta_title")} />
          <meta property="og:description" content={t("meta_description")} />
          {isProduction() && (
            <>
              <script async src={"https://www.googletagmanager.com/gtag/js?id=G-LJF3M7248Y"} />
              <script type="text/javascript">
                {`
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());
                  gtag('config', 'G-LJF3M7248Y');
                `}
              </script>
            </>
          )}
        </Helmet>
        <Router />
      </HelmetProvider>
    </>
  );
};

export default App;
