import { useState, useEffect } from "react";

import { Stack, useTheme } from "@mui/material";
import { disableMFA } from "API/calls";
import BackdropLoading from "components/elements/BackdropLoading";
import CodeField from "components/elements/CodeField";
import { CircleCheck } from "components/icons";
import { useFormik } from "formik";
import useKeyPress from "hooks/useKeyPress";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";

import { CustomButton, Content, Title } from "./styles";

const Disable = () => {
  const theme = useTheme();
  const { t } = useTranslation<string>();
  const keyPressEnter = useKeyPress("Enter");
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isOpenBackdropLoading, setIsOpenBackdropLoading] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const formik = useFormik({
    initialValues: { otp: "" },
    validationSchema: yup.object({
      otp: yup
        .string()
        .required(t("fieldRequired"))
        .max(6, t("fieldMaxLength", { max: 6 })),
    }),
    onSubmit: (values) => {
      const { otp } = values;
      otp && handleConfirmMFA(`${otp}`);
    },
  });

  useEffect(() => {
    if (keyPressEnter) isDisabled ? handleDone() : handleNext();
  }, [keyPressEnter]);

  const handleConfirmMFA = async (otp: string) => {
    setIsOpenBackdropLoading(true);

    try {
      await disableMFA(otp);
      setIsDisabled(true);
    } catch (err: any) {
      enqueueSnackbar(err?.response?.data?.message ?? t("commonError"), { variant: "error" });
    } finally {
      setIsOpenBackdropLoading(false);
    }
  };

  const disableGoogleAuthSection = () => {
    return (
      <Stack sx={{ alignItems: "center", pt: 5, pb: 7 }}>
        <CodeField
          maxLength={6}
          fullWidth
          id="otp"
          name="otp"
          label={t("twoFactorAuth.enableGoogleAuth.label")}
          value={formik.values.otp}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.otp)}
          helperText={formik.errors.otp ?? t("twoFactorAuth.enableGoogleAuth.helperText")}
        />
      </Stack>
    );
  };

  const completeSection = () => {
    return (
      <Stack sx={{ alignItems: "center", pt: 4, pb: 7 }}>
        <CircleCheck sx={{ fontSize: 80, color: theme.palette.success.main, mr: 1 }} />
      </Stack>
    );
  };

  const handleDone = () => navigate("/nastaveni");
  const handleNext = () => formik.handleSubmit();

  return (
    <>
      <Content>
        <Title variant="h6">
          {isDisabled ? t("twoFactorAuth.completeDisable.subtitle") : t("twoFactorAuth.disable.title")}
        </Title>
        {isDisabled ? completeSection() : disableGoogleAuthSection()}
        <Stack direction="row" spacing={2}>
          {isDisabled && (
            <CustomButton color="primary" variant="contained" onClick={handleDone}>
              {t("twoFactorAuth.done")}
            </CustomButton>
          )}
          {!isDisabled && (
            <>
              <CustomButton color="inherit" variant="contained" to="/nastaveni" component={Link}>
                {t("twoFactorAuth.previousStep")}
              </CustomButton>
              <CustomButton color="primary" variant="contained" onClick={handleNext}>
                {t("confirm")}
              </CustomButton>
            </>
          )}
        </Stack>
      </Content>
      <BackdropLoading open={isOpenBackdropLoading} />
    </>
  );
};

export default Disable;
