import { Box, Button, Alert, Stack } from "@mui/material";
import { useUserDataContext } from "context/UserDataContext";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const SubscriptionInfo = () => {
  const { subscriptionPausedError, subscriptionPausedWarning, creditsRechargeType } = useUserDataContext();
  const { t } = useTranslation();

  const hasError = () => subscriptionPausedError || subscriptionPausedWarning;

  return (
    <Box pb={hasError() ? 7 : 0}>
      {subscriptionPausedError && (
        <Alert severity="error">
          <Stack spacing={2}>
            <Box>
              {t("dashboard.subscriptionError")} {t("dashboard.subscriptionFree")}
            </Box>
            <Box>
              <Stack spacing={1} direction={{xs: "column", sm: "row"}}>
                <Button
                  component={Link}
                  to="/stosaky#nakoupit"
                  variant="contained"
                  size="small"
                  color="error">
                  {t("dashboard.subscriptionCtaBuyCredits")}
                </Button>
                <Button
                  component={Link}
                  to="/predplatne"
                  variant="outlined"
                  size="small"
                  color="error">
                  {t("dashboard.subscriptionCtaFree")}
                </Button>
              </Stack>
            </Box>
          </Stack>
        </Alert>
      )}
      {subscriptionPausedWarning && (
        <Alert severity="warning" sx={{ mt: subscriptionPausedError ? 1 : 0 }}>
          <Stack spacing={2}>
            <Box>
              {t("dashboard.subscriptionWarning")} {t("dashboard.subscriptionFree")}
            </Box>
            <Box>
              <Stack spacing={1} direction={{xs: "column", sm: "row"}}>
                <Button
                  component={Link}
                  to="/stosaky#nakoupit"
                  variant="contained"
                  size="small">
                  {t("dashboard.subscriptionCtaBuyCredits")}
                </Button>
                <Button
                  component={Link}
                  to="/predplatne"
                  variant="outlined"
                  size="small">
                  {t("dashboard.subscriptionCtaFree")}
                </Button>
              </Stack>
            </Box>
          </Stack>
        </Alert>
      )}
    </Box>
  );
};

export default SubscriptionInfo;
