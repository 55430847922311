import { SvgIcon } from "@mui/material";

export const AccountingCoinsStack = (props: any) => {
  return (
    <SvgIcon {...props}>
      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_439_1543)">
        <path d="M7.19232 6.75C10.506 6.75 13.1923 5.40685 13.1923 3.75C13.1923 2.09315 10.506 0.75 7.19232 0.75C3.87861 0.75 1.19232 2.09315 1.19232 3.75C1.19232 5.40685 3.87861 6.75 7.19232 6.75Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M13.1923 7.75V3.75" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M1.19232 3.75V18.75C1.19232 20.41 3.88232 21.75 7.19232 21.75C7.75785 21.752 8.32275 21.7119 8.88232 21.63" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M1.19232 15C1.19232 16.66 3.88232 18 7.19232 18C7.77137 17.9985 8.34955 17.955 8.92232 17.87" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M1.19232 11.25C1.19232 12.91 3.88232 14.25 7.19232 14.25C7.75785 14.252 8.32275 14.2119 8.88232 14.13" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M1.19232 7.5C1.19232 9.16 3.88232 10.5 7.19232 10.5C7.77137 10.4985 8.34955 10.455 8.92232 10.37" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M17.6923 16C21.006 16 23.6923 14.6569 23.6923 13C23.6923 11.3431 21.006 10 17.6923 10C14.3786 10 11.6923 11.3431 11.6923 13C11.6923 14.6569 14.3786 16 17.6923 16Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M11.6923 13V20.25C11.6923 21.91 14.3823 23.25 17.6923 23.25C21.0023 23.25 23.6923 21.91 23.6923 20.25V13" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M23.6923 16.5C23.6923 18.16 21.0023 19.5 17.6923 19.5C14.3823 19.5 11.6923 18.16 11.6923 16.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <defs>
        <clipPath id="clip0_439_1543">
        <rect width="24" height="24" fill="white" transform="translate(0.442322)"/>
        </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};
