import { useEffect, useState, useRef, Children, isValidElement, cloneElement } from "react";

interface IProps {
  children: JSX.Element;
  aspectRatio: string;
  withAspectRatio?: boolean;
}

const splitAspectRatio = (aspectRatio: string) => {
  const [width, height] = aspectRatio.split("/").map(Number);

  return { width, height };
}

const AspectRatioWrapper = ({ children, aspectRatio, withAspectRatio = true }: IProps) => {
  const [height, setHeight] = useState(0);
  const ref = useRef<any>(null);
  const isSupported = CSS.supports("aspect-ratio", aspectRatio);
  
  useEffect(() => {
    //hack for safari to version 15
    const handleResize = () => {
      const { width, height } = splitAspectRatio(aspectRatio);
      setHeight((ref.current.clientWidth * height) / width);
    }

    if(ref.current) {
      handleResize();
      window.addEventListener("resize", handleResize);
    }
    
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [ref]);

  
  if(!isSupported && withAspectRatio) {
    const childrenWithProps = Children.map(children, (child) => {
      if (isValidElement(child)) {
        return cloneElement<any>(child, { height: `${height}px`, ref });
      }
      return child;
    });

    return <>{childrenWithProps}</>;
  }

  return <>{children}</>;
}

export default AspectRatioWrapper;
