import i18n from "i18n/i18n";
import { ConfirmOptions } from "material-ui-confirm";

const confirmOptions: ConfirmOptions = {
  confirmationButtonProps: {
    variant: "contained",
    disableElevation: true,
  },
  cancellationButtonProps: {
    color: "inherit",
  },
  confirmationText: i18n.t("continue"),
  cancellationText: i18n.t("cancel"),
};

export default confirmOptions;
