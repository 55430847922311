import * as React from "react";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Divider, IconButton, Stack, Link, Box, Grid, Typography, useTheme, styled } from "@mui/material";
import Panel from "components/elements/Panel";
import Tooltip from "components/elements/Tooltip";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { useUserDataContext } from "context/UserDataContext";
import { useAPIUserDataAffiliate } from "hooks/useAPI";
import { useSnackbar } from "notistack";
import { Trans, useTranslation } from "react-i18next";
import { useCopyToClipboard } from "react-use";

const StyledAffiliateLink = styled(Box)(() => {
  const { colors } = useMUIThemeModeContext();

  return {
    fontFamily: "monospace",
    wordBreak: "break-all",
    padding: "10px 15px",
    display: "inline-block",
    background: colors.legacyGray100,
    borderRadius: "10px",

    "@media only screen and (max-width: 600px)": {
      fontSize: "0.8rem"
    }
  };
});

const BadgesTable: React.FC = ({ }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { aff } = useUserDataContext();
  const [, copyToClipboard] = useCopyToClipboard();
  const { data } = useAPIUserDataAffiliate();

  const affiliateLink = `stosuj.cz/?aff=${aff}`;
  const affiliateProcBitcoinLink = `ProcBitcoin.cz/?aff=${aff}`;

  const onCopyButtonClick = (affLink: string) => () => {
    copyToClipboard(affLink);
    enqueueSnackbar(t("affiliate.copiedToClipboard"), { variant: "success" });
  };

  const affItem = (affLink: string) => (
    <StyledAffiliateLink>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Box>{affLink}</Box>
        <Tooltip arrow placement="top" title={t("copyToClipboard") ?? ""}>
          <IconButton size="small" color="info" onClick={onCopyButtonClick("https://" + affLink)}>
            <ContentCopyIcon />
          </IconButton>
        </Tooltip>
      </Stack>
    </StyledAffiliateLink>
  );

  return (
    <Panel sx={{ mb: 4, p: 3 }}>
      <Typography variant="h5" component="h1" fontWeight={600} sx={{ pb: 1, color: "tertiary.main" }}>
        {t("affiliate.text1")}
      </Typography>
      <Typography variant="subtitle2" fontWeight={400} sx={{ pb: 3 }}>
        <Trans i18nKey="affiliate.text2" />
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ width: "100%" }}>
          <Typography variant="subtitle2" fontWeight={400} sx={{ display: "block", pb: 1 }}>
            {t("affiliate.yourLink")}:
          </Typography>
          {affItem(affiliateLink)}
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2" sx={{ width: { xs: "100", sm: "90%", md: "80%" }, pb: 1 }}>
            <Trans
              i18nKey="affiliate.procBitcoinText"
              components={{
                a: (
                  <Link
                    color={theme.palette.primary.main}
                    href="https://procbitcoin.cz/"
                    target="_blank"
                    sx={{ fontWeight: "bold" }}
                  />
                ),
              }}
            />
          </Typography>
          {affItem(affiliateProcBitcoinLink)}
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} display={"flex"} alignItems={"center"}>
          <Stack direction={"column"}>
            {data
              && Object.keys(data).map((key, index) => (
                <Typography variant="subtitle1" key={`${key}-${index}`}>
                  <Trans i18nKey={`affiliate.${key}`} values={{ count: data[key as keyof typeof data] }} />
                </Typography>
              ))}
          </Stack>
        </Grid>
      </Grid>
    </Panel>
  );
};

export default BadgesTable;
