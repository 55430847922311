import { useState, useEffect } from "react";

import { Box, Grid, Stack, useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import { emailVerification, resendVerificationEmail } from "API/calls";
import PageTitle from "components/elements/PageTitle";
import Panel from "components/elements/Panel";
import { CircleCheck, AlertCircle, InformationCircle } from "components/icons";
import useQuery from "hooks/useQuery";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const EmailVerification = () => {
  const theme = useTheme();
  const { t } = useTranslation<string>();
  const [errorCode, setErrorCode] = useState<number>();
  const { enqueueSnackbar } = useSnackbar();
  const query = useQuery();

  useEffect(() => {
    const token = query.get("token");

    async function fetchMyAPI(userToken: string) {
      try {
        await emailVerification(userToken);
        enqueueSnackbar(t("emailVerification.successMessage"), { variant: "success" });
        setErrorCode(200);
      } catch (error: any) {
        const statusCode = error?.response?.status;
        if (statusCode === 400) {
          enqueueSnackbar(t("emailVerification.error400"), { variant: "error" });
        } else if (statusCode === 428) {
          enqueueSnackbar(t("emailVerification.error428"), { variant: "warning" });
        } else if (statusCode === 403) {
          enqueueSnackbar(t("emailVerification.error403"), { variant: "error" });
        }

        setErrorCode(statusCode);
      }
    }

    if (token) fetchMyAPI(token);
  }, []);

  const handleResendVerificationEmail = async () => {
    const token = query.get("token");

    if (token) {
      try {
        await resendVerificationEmail(token);
        enqueueSnackbar(t("emailVerification.successResendVerificationEmail"), { variant: "success" });
      } catch (error: any) {
        enqueueSnackbar(t("commonError"), { variant: "error" });
      }
    }
  };

  const getBody = () => {
    switch (errorCode) {
      case 200:
        return (
          <Stack sx={{ alignItems: "center", py: 4 }} spacing={4}>
            <CircleCheck sx={{ fontSize: 80, color: theme.palette.success.main, mb: 2 }} />
            <Box>{`${t("emailVerification.successMessage")} ${t("emailVerification.youCanLogIn")}`}</Box>
            <Button component={Link} to="/login" color="primary" variant="contained">
              {t("login")}
            </Button>
          </Stack>
        );
      case 428:
        return (
          <Stack sx={{ alignItems: "center", py: 4 }} spacing={4}>
            <InformationCircle sx={{ fontSize: 80, color: theme.palette.info.main, mb: 2 }} />
            <Box>{`${t("emailVerification.error428")} ${t("emailVerification.youCanLogIn")}`}</Box>
            <Button component={Link} to="/login" color="primary" variant="contained">
              {t("login")}
            </Button>
          </Stack>
        );
      case 403:
        return (
          <Stack sx={{ alignItems: "center", py: 4 }} spacing={4}>
            <AlertCircle sx={{ fontSize: 80, color: theme.palette.error.main, mb: 2 }} />
            <Box>{`${t("emailVerification.error403")}`}</Box>
            <Button onClick={handleResendVerificationEmail} color="primary" variant="contained">
              {t("emailVerification.resendVerificationEmail")}
            </Button>
          </Stack>
        );
      case 400:
      default:
        return (
          <Stack sx={{ alignItems: "center", py: 4 }} spacing={4}>
            <AlertCircle sx={{ fontSize: 80, color: theme.palette.error.main, mb: 2 }} />
            <Box>{`${t("emailVerification.error400")}`}</Box>
            <Button component={Link} to="/" color="primary" variant="contained">
              {t("goToHome")}
            </Button>
          </Stack>
        );
    }
  };

  return (
    <Grid container justifyContent="center" spacing={8}>
      <Grid item sx={{ width: "100%", maxWidth: "md" }}>
        <Panel sx={{ p: 4 }}>
          <PageTitle i18nKey="emailVerification.meta_title" />
          {getBody()}
        </Panel>
      </Grid>
    </Grid>
  );
};

export default EmailVerification;
