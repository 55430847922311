import { styled } from "@mui/material/styles";

export const MainWrapper = styled("div")({
  width: "100%",
 
  "@media (min-width:1500px)": {
    marginTop: "-30px",
  },

  "& .apexcharts-datalabel-value": {
    fontSize: "1.7rem",
    "@media (max-width:600px)": {
      fontSize: "1.5rem",
    },
    "@media (max-width:350px)": {
      fontSize: "1.2rem",
    },

  },
  "& .apexcharts-datalabel-label": {
    fontSize: "1.5rem",
    "@media (max-width:600px)": {
      fontSize: "1.2rem",
    },
    "@media (max-width:350px)": {
      fontSize: "1rem",
    },
  },
});
