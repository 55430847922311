const TermsAndConditions = () => {
  return (
    <>
      <ol>
        <li>
          <p>Úvod a základní termíny</p>
        </li>
        <ol type="a">
          <li>
            <p>
              "Poskytovatel": Obchodní společnost Stosuj s.r.o., IČO: 143 99 831, se sídlem: Kaprova 42/14, Staré Město,
              110 00 Praha, zapsaná v obchodním rejstříku vedeném u Městského soudu v Praze, oddíl C, vložka 364569
            </p>
          </li>
          <li>
            <p>"Klient": Uživatel, který využívá aplikaci na webové stránce stosuj.cz.</p>
          </li>
          <li>
            <p>"Kryptoměna": Virtuální aktivum, jak je definováno Zákonem</p>
          </li>
          <li>
            <p>
              "DCA" (Dollar-Cost Averaging): Je investiční strategie, ve které investor rozdělí celkové množství částky,
              kterou chce za dané období investovat na menší periodické nákupy daného aktiva, čímž snižuje dopad
              volatility na celkový nákup.
            </p>
          </li>
          <li>
            <p>"Aplikace": Webová aplikace provozovaná na webové stránce stosuj.cz</p>
          </li>
          <li>
            <p>
              "API Klíč": Sada unikátních řetězců umožňující bezpečnou komunikaci mezi aplikacemi. Například komunikaci
              aplikace s burzou nebo směnárnou za účelem nákupu kryptoměn a zjištění zůstatků na účtu.
            </p>
          </li>
          <li>
            <p>
              "Limitní objednávka": Limitní objednávkou určí klient cenu, za kterou je ochoten nakoupit nebo prodat
              Kryptoměnu.
            </p>
          </li>
          <li>
            <p>
              "Market objednávka": Je objednávka na nákup nebo prodej Kryptoměny za aktuální cenu na dané burze nebo
              směnárně.
            </p>
          </li>
        </ol>
        <li>
          <p>Obchodní, dodací a platební podmínky</p>
        </li>
        <ol type="a">
          <li>
            <p>Poskytovatel je provozovatel webové stránky stosuj.cz</p>
          </li>
          <li>
            <p>
              Webová stránka stosuj.cz slouží Klientům k automatizaci nákupů Kryptoměn pomocí strategie DCA
              prosřednictvím burz a směnáren. Tyto obchodní podmínky (dále jen "Obchodní podmínky") představují rámcovou
              smlouvu, která bude uzavřena mezi Klientem a Poskytovatelem a která upravuje práva a povinnosti stran při
              poskytování služeb Poskytovatelem Klientovi (dále jen "Rámcová smlouva"). K uzavření Rámcové smlouvy dojde
              v okamžiku, kdy Klient odsouhlasí znění těchto Obchodních podmínek v rámci registračního procesu v
              Aplikaci.
            </p>
          </li>
          <li>
            <p>Proces nákupu Kryptoměny pomocí aplikace:</p>
          </li>
          <ol type="i">
            <li>
              <p>Klient si propojí burzu nebo směnárnu s Aplikací</p>
            </li>
            <ol>
              <li>
                <p>Zaregistruje se na směnárně anycoin.cz prostřednictvím Aplikace.</p>
              </li>
              <li>
                <p>
                  Propojí si svůj účet na burze nebo směnárně s Aplikací tím, že si na dané burze nebo směnárně
                  vygeneruje API Klíč a ten vloží do Aplikace.
                </p>
              </li>
            </ol>
            <li>
              <p>Klient si na propojenou burzu nebo směnárnu vloží peněžní prostředky za účelem nákupu Kryptoměn.</p>
            </li>
            <li>
              <p>
                Klient si v Aplikaci nastaví strategii nákupu na konkrétní propojené burze nebo směnárně, která obsahuje
                nastavení
              </p>
            </li>
            <ol>
              <li>
                <p>Frekvence pravidelného nákupu.</p>
              </li>
              <li>
                <p>Hodnoty objednávky (za kolik nakoupit).</p>
              </li>
              <li>
                <p>Zda se jedná o Market objednávku, či Limitní objednávku.</p>
              </li>
              <li>
                <p>
                  Zda se má Limitní objednávka na konci aktuálního období nakoupit Market objednávkou za aktuální cenu,
                  pokud ke stanovenému cenovému poklesu nedojde.
                </p>
              </li>
            </ol>
            <li>
              <p>Klient může kdykoliv nakoupit přes Aplikaci Kryptoměnu jednorázovým okamžitým nákupem.</p>
            </li>
            <li>
              <p>
                Vzhledem k dynamické povaze trhu může dojít ke zvýšení ceny Kryptoměn na burzách či směnárnách v době
                mezi obdržení požadavku na nákup a samotnému nákupu. Poskytovatel tedy není schopen garantovat realizaci
                za cenu zobrazenou v momentu vytváření objednávky.
              </p>
            </li>
            <li>
              <p>
                Nakoupené Kryptoměny zůstanou na dané burze nebo směnárně, kde byl realizovaný obchod pomocí propojení
                Aplikace. Aplikace a Poskytovatel nemůže s těmito prostředky nakládat.
              </p>
            </li>
          </ol>
        </ol>
        <li>
          <p>Omezení odpovědnosti za újmu</p>
        </li>
        <ol type="a">
          <li>
            <p>
              Vzhledem k neustálým změnám tržní hodnoty Kryptoměn tímto Klient bere na vědomí a je srozuměn s tím, že
              jím nakoupené Kryptoměny mohou ztratit hodnotu v důsledku vývoje a změn trhu (zejména nabídky a poptávky).
              Poskytovatel odpovídá pouze za provedení nákupní objednávky dle Poskytovatelem akceptovaného pokynu
              Klienta a nenese odpovědnost za změnu hodnoty nakoupené Kryptoměny po provedení nákupu.
            </p>
          </li>
          <li>
            <p>Poskytovatel neodpovídá za žádných okolností za újmu vzniklou na straně Klienta a související s:</p>
          </li>
          <ol>
            <li>
              <p>pohybem kurzu Kryptoměn</p>
            </li>
            <li>
              <p>
                selháním přenosu dat či jakéhokoliv jiného komunikačního kanálu mezi Klientem a Poskytovatelem nebo
                jakoukoli jinou třetí stranou
              </p>
            </li>
            <li>
              <p>nedostupností aplikace Poskytovatele</p>
            </li>
            <li>
              <p>selhání jakékoli třetí strany ve vztahu k vypořádání pokynu k nákupu Kryptoměn.</p>
            </li>
            <li>
              <p>prodlení Klienta s plněním jeho povinností dle těchto Podmínek.</p>
            </li>
          </ol>
        </ol>
        <li>
          <p>Zpracování osobních údajů</p>
        </li>
        <ol type="a">
          <li>
            <p>
              Poskytovatel zpracovává a uchovává osobní údaje Klienta v souladu s Nařízením Evropského parlamentu a Rady
              (EU) 2016/679, o ochraně fyzických osob v souvislosti se zpracováním osobních údajů a o volném pohybu
              těchto údajů (GDPR) a zákonem 110/2019 Sb., o zpracování osobních údajů a související právními předpisy.
            </p>
          </li>
          <li>
            <p>
              Zásady zpracování osobních údajů a informace o zpracování osobních údajů jsou zveřejněny na internetových
              stránkách Poskytovatele v sekci Zásady zpracování osobních údajů a Klient se s těmito seznámil a vyslovuje
              s nimi svůj souhlas.
            </p>
          </li>
        </ol>
        <li>
          <p>Upozornění</p>
        </li>
        <ol type="a">
          <li>
            <p>
              Poskytovatel upozorňuje, že obsah jeho webové stránky, na které nabízí služby, může obsahovat neaktuální
              informace, chyby nebo nepřesnosti, a vyhrazuje si právo provést opravu, úpravu nebo aktualizaci obsahu
              webové stránky, považuje-li to za nutné nebo vhodné.
            </p>
          </li>
          <li>
            <p>
              Jakékoliv informace dostupné na webových stránkách Poskytovatele nepředstavují investiční, daňové, právní,
              příp. jiné poradenství, ani návrh na uzavření smlouvy o poskytování finanční, investiční nebo jiné služby
              a ani jakýkoliv jiný návrh Poskytovatele vůči potencionálnímu Klientovi.
            </p>
          </li>
          <li>
            <p>
              Poskytovatel neposkytuje Klientům žádná doporučení ohledně nákupu a prodeje Kryptoměn, investiční průzkumy
              nebo finanční analýzy a podobné služby. Veškeré informace uvedené na webových stránkách Poskytovatele
              nemají doporučující charakter a jejich cílem je pouze zdarma zpřístupnit danou informaci potencionálnímu
              Klientovi.
            </p>
          </li>
          <li>
            <p>
              Poskytovatel není povinen zajistit nepřetržitou přístupnost webových stránek a nezaručuje žádnou minimální
              dobu odezvy webových stránek na požadavky Klienta. Provoz a funkčnost webových stránek může být ovlivněn
              faktory ležícími mimo sféru vlivu Poskytovatele a Poskytovatel nepřebírá odpovědnost za jejich následky.
            </p>
          </li>
        </ol>
        <li>
          <p>Změny Obchodních podmínek</p>
        </li>
        <ol type="a">
          <li>
            <p>
              Poskytovatel je oprávněn obchodní podmínky jednostranně měnit, přičemž v souladu s Nařízením Evropského parlamentu
              a Rady (EU) č. 2019/1150 tyto změny, resp. nové znění obchodních podmínek zveřejní na svých internetových stránkách
              a stávající dotčené uživatelé o něm adresně v rámci Systému či jiným způsobem informuje. Dotčení uživatelé
              svým dalším užitím Aplikace Štosuj.cz po provedení změny obchodních podmínek stvrzují přijetí takové změny.
            </p>
          </li>
        </ol>
        <li>
          <p>Rozhodné právo a příslušné soudy</p>
        </li>
        <ol type="a">
          <li>
            <p>
              Jakékoliv právní vztahy vzniklé na základě nebo v důsledku využívání služeb Poskytovatele se budou řídit
              právem České republiky.
            </p>
          </li>
          <li>
            <p>
              Jakékoliv spory vzniklé na základě nebo v důsledku využívání služeb Poskytovatele budou řešeny příslušnými
              soudy České republiky.
            </p>
          </li>
          <li>
            <p>
              Klient bere na vědomí, že orgánem dozoru nad dodržováním povinností Poskytovatele stanovených na ochranu
              spotřebitele je Česká obchodní inspekce.
            </p>
          </li>
        </ol>
        <li>
          <p>Závěrečné informace</p>
        </li>
        <ol type="a">
          <li>
            <p>
              Tyto Zásady jsou účinné od 1. 3. 2022. Jakékoliv dotazy a požadavky ve vztahu ke zpracování osobních údajů
              s námi můžete konzultovat prostřednictvím e-mailové adresy: info@stosuj.cz
            </p>
          </li>
        </ol>
      </ol>
    </>
  );
};

export default TermsAndConditions;
