import React from "react";

import { Box, BoxProps, Container, Typography, useTheme } from "@mui/material";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";

interface IProps extends Omit<BoxProps, "title"> {
  name?: string;
  title?: string | JSX.Element;
  subtitle?: string | JSX.Element;
  children?: React.ReactNode;
  size?: "small" | "medium";
  titleColor?: string;
  middle?: JSX.Element;
}

const PY_OPTIONS = {
  small: { xs: 2, md: 6 },
  medium: { xs: 4, md: 8 },
};

const HomeBox = ({ title, name, subtitle, children, size = "medium", titleColor, bgcolor, middle, ...props }: IProps) => {
  const theme = useTheme();
  const { colors } = useMUIThemeModeContext();

  return (
    <Box bgcolor={bgcolor}>
      <Container>
        <Box py={PY_OPTIONS[size]} textAlign={"center"} {...props}>
          {name && (
            <Typography color={theme.palette.tertiary.main} fontWeight={600} fontSize={{ xs: "0.9rem", sm: "1rem" }}>
              {name}
            </Typography>
          )}
          {title && (
            <Typography
              fontWeight={600}
              fontSize={{ xs: "1.25rem", sm: "2rem", lg: "2.5rem" }}
              color={titleColor ?? colors["blueBlack"]}>
              {title}
            </Typography>
          )}
          {middle && middle}
          {subtitle && (
            <Typography
              pt={{ xs: 1, sm: 4 }}
              fontSize={{ xs: "0.8rem", sm: "1.125rem" }}
              fontWeight={300}
              color={colors["blueGray"]}>
              {subtitle}
            </Typography>
          )}
          {children}
        </Box>
      </Container>
    </Box>
  );
};

export default HomeBox;
