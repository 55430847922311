import { Box, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";

import { ExpandMoreProps } from "./types";

export const ItemBox = styled(Box)(({ theme }) => ({
  backgroundColor: "rgba(158, 158, 158, .1)",
  borderRadius: "10px",
  padding: theme.breakpoints.down("sm") ?  theme.spacing(1) : theme.spacing(2),
  width: "100%",
  height: "100%",
  color: theme.palette.secondary.main,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));