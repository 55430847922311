import { useEffect, useState } from "react";

import { Box, Button, CircularProgress, FormControlLabel, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import { getUserEmailPreferences, putUserEmailPreferences } from "API/calls";
import CenterWrapper from "components/elements/CenterWrapper";
import Panel from "components/elements/Panel";
import Switch from "components/elements/Switch";
import { useAPIUserEmailPreferences } from "hooks/useAPI";
import { useIsLogin } from "hooks/useUser";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { StringParam, useQueryParams } from "use-query-params";

const EmailPreferences = () => {
  const [query, setQuery] = useQueryParams({ id: StringParam });
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [isUnsubscribeAll, setIsUnsubscribeAll] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [preferencesId, setPreferencesId] = useState<string>(query.id || "");
  const { data, error, mutate } = useAPIUserEmailPreferences(preferencesId);
  const isLoggedIn = useIsLogin();

  const loadPreferenceId = async () => {
    try {
      const { data } = await getUserEmailPreferences();
      if (data?.id) setPreferencesId(data.id);
      else navigate("/");
    } catch (_error: any) {
      navigate("/");
    }
  };

  useEffect(() => {
    if (isLoggedIn !== undefined) {
      if (!preferencesId.length) {
        if (isLoggedIn) loadPreferenceId();
        else navigate("/");
      }
    }

    // to remove id query parameter from URL before it gets collected to Google Analytics
    setQuery({ id: undefined });
  }, [isLoggedIn]);

  useEffect(() => {
    if (error) navigate("/");
  }, [error]);

  const handleChangeCheckbox = async (event: React.ChangeEvent<HTMLInputElement>, key: string) => {
    if (!preferencesId.length || !data) return;

    setLoading(true);
    try {
      await putUserEmailPreferences(preferencesId, { ...data, [key]: event.target.checked } as any);
      await mutate();
      enqueueSnackbar(t("emailPreferences.saved"), { variant: "success" });
      setLoading(false);
    } catch (err: any) {
      enqueueSnackbar(t("emailPreferences.error"), { variant: "error" });
      setLoading(false);
    }
  };

  const handleUnsubscribeAll = async () => {
    if (!preferencesId.length) return;

    setLoading(true);
    setIsUnsubscribeAll(true);
    try {
      await putUserEmailPreferences(preferencesId, {
        notifications: false,
        tutorials: false,
        news: false,
        questionnaires: false,
        events: false,
      } as any);
      await mutate();
      enqueueSnackbar(t("emailPreferences.saved"), { variant: "success" });
      setLoading(false);
    } catch (err: any) {
      enqueueSnackbar(t("emailPreferences.error"), { variant: "error" });
      setLoading(false);
    }
  };

  const preferenceItem = (key: string) => {
    const value = key === "necessary" ? true : data?.[key as keyof typeof data];
    const disabled = key === "necessary";

    return (
      <FormControlLabel
        sx={{ display: "flex", alignItems: "flex-start", ml: 0 }}
        control={
          <Switch
            sx={{ mr: 2, mt: 0.5 }}
            checked={value}
            disabled={loading || disabled}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChangeCheckbox(event, key)}
          />
        }
        label={
          <Stack>
            <Typography color="tertiary.main" variant="subtitle1">
              {t(`emailPreferences.${key}.title`)}
            </Typography>
            <Typography variant="subtitle2" fontWeight={400} color="gray">
              {t(`emailPreferences.${key}.description`)}
            </Typography>
          </Stack>
        }
      />
    );
  };

  if (!data) {
    return (
      <CenterWrapper>
        <CircularProgress color="primary" />
      </CenterWrapper>
    );
  }

  return (
    <Box justifyContent="center" display={"flex"}>
      <Panel sx={{ p: 3, mb: 10, width: "100%", maxWidth: "sm" }}>
        <Typography variant="h5" component="h1" fontWeight={600} sx={{ pb: 3, color: "tertiary.main" }}>
          {t("emailPreferences.title")}
        </Typography>
        <Stack direction={"column"} spacing={2}>
          <Box display={"flex"} pb={2}>
            <Button sx={{ pl: 0 }} onClick={handleUnsubscribeAll} disabled={isUnsubscribeAll} color="error">
              {t("emailPreferences.all")}
            </Button>
          </Box>
          {preferenceItem("necessary")}
          {preferenceItem("notifications")}
          {preferenceItem("tutorials")}
          {preferenceItem("news")}
          {preferenceItem("questionnaires")}
          {preferenceItem("events")}
        </Stack>
      </Panel>
    </Box>
  );
};
export default EmailPreferences;
