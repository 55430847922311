import { useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Box, Typography, alpha, styled, useMediaQuery } from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import HomeBox from "components/modules/HomeBox";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { useTranslation } from "react-i18next";

const FaqItems = [
  {
    title: <strong>Je potřeba KYC - ověření totožnosti?</strong>,
    body: (
      <>
        <p>Pro používání aplikace Štosuj.cz od tebe budeme potřebovat pouze platný e‑mail.</p>
        <p>
          Nicméně, většina burz/směnáren, kde si musíš vytvořit vlastní účet, KYC (ověření totožnosti občanským
          průkazem) vyžaduje.
        </p>
        <p>
          Bohužel ještě nevíme o žádné možnosti, jak zautomatizovat pravidelný nákup převodem peněz z bankovního účtu. A
          vzhledem ke všem regulacím je tato možnost do budoucna i téměř vyloučena.
        </p>
        <p>Pokud se tedy KYC nutně chceš vyhnout, stále budeš muset podnikat manuální opatření jak krypto nakoupit.</p>
      </>
    ),
  },
  {
    title: <strong>Jde nakupovat a prodávat i jiné kryptoměny než jen bitcoin?</strong>,
    body: (
      <>
        <p>Ano, jde.</p>
        <p>
          Postupně přidáváme další kryptoměny a chtěli bychom ve výsledku podporovat všechny měnové páry na
          integrovaných burzách/směnárnách.
        </p>
      </>
    ),
  },
  {
    title: <strong>Jaké burzy a směnárny podporujete?</strong>,
    body: (
      <>
        <p>Podporujeme českou burzu Coinmate, českou směnárnu Anycoin a zahraniční burzy Binance a Bitstamp.</p>
        <p>Postupně budeme přidávat i další burzy, např. Kraken a Coinbase Advance Trade.</p>
      </>
    ),
  },
  {
    title: <strong>Může mě Štosuj.cz připravit o peníze nebo krypto?</strong>,
    body: (
      <>
        <p>
          Svoje peníze posíláš přímo na svůj účet na burze/směnárně (ne do Štosuj.cz) a nakoupené kryptoměny si z burzy
          můžeš vybrat do své peněženky pouze ty.
        </p>
        <p>
          Aplikaci Štosuj.cz sám vytvoříš takzvaný API klíč, kterým nám umožníš z tvého burzovního účtu pouze nakupovat
          krypto a zobrazit zůstatky.
        </p>
        <p>My nebudeme mít oprávnění odesílat tvé peníze ani krypto nikam ven z tvé burzy.</p>
        <p>Tento API klíč je pod tvou správou na burze a můžeš ho kdykoliv deaktivovat.</p>
      </>
    ),
  },
  {
    title: <strong>Jak byste co nejstručněji shrnuli jak k investování do Bitcoinu přistupovat?</strong>,
    body: (
      <>
        <p>
          DYOR - "Do your own research", neboli udělej si vlastní průzkům. Investuj prvně svůj čas, abys Bitcoin či jiné
          kryptoměny nastudoval. Musíš rozumět tomu, do čeho investuješ. Udělej své rozhodnutí.
        </p>
        <p>Investuj jen peníze, o které si můžeš dovolit přijít.</p>
        <p>Přemýšlej o investování v dlouhodobém horizontu, třeba 5+ let.</p>
        <p>Urči si pevnou nákupní strategii a drž se jí. S tímto ti pomůže Štosuj.cz 🙂</p>
        <p>Při propadech trhu nepanikař, slevu můžeš využít k nákupu a snížení průměrné nákupní ceny.</p>
        <p>
          Nakoupené kryptoměny nedrž dlouhodobě na burze, ale vybírej si je do vlastní peněženky (ideálně hardwarové,
          třeba Trezor).
        </p>
        <p>
          Dobře si promysli, jestli a jak budeš chtít kryptoměny odprodávat (vybírat zisk zpět do státní měny). S tímto
          ti na Štosuj.cz také pomůžeme 😉
        </p>
      </>
    ),
  },
  {
    title: <strong>Je služba Štosuj.cz zpoplatněna?</strong>,
    body: (
      <>
        <p>
          Nastavit si na Štosuj.cz pravidelný nákup Bitcoinu (BTC) za státní peníze (CZK, EUR, USD) či stablecoiny
          (USDT, USDC a další) jde naprosto zdarma.
        </p>
        <p>
          Omezen tak jsi pouze na BTC a počtem samostatných pravidelných nákupů (zdarma jen 2) a dostupnou frekvencí
          (zdarma jen měsíční a týdenní frekvence).
        </p>
        <p>
          Pro odemčení kratších frekvencí nákupů, dalších měnových párů a dalších funkcí se podívej na naše předplatné.
        </p>
      </>
    ),
  },
  {
    title: <strong>Je výhodnější nakupovat za aktuální cenu nebo využít strategii limitních objednávek?</strong>,
    body: (
      <>
        <p>Dlouhodobě je výhodnější využít limitní objednávky, ale se správným nastavením.</p>
        <p>Je potřeba vzít do úvahy frekvenci nákupů a aktuální situaci na trhu.</p>
        <p>
          Správné nastavení se pokusíme zjednodušit a uživateli ukázat, jak podle historických dat je pravděpodobné, že
          jejich limitní objednávka bude naplněna (tzv. backtest).
        </p>
      </>
    ),
  },
];

const Accordion = styled(MuiAccordion)(() => {
  const { colors } = useMUIThemeModeContext();

  return {
    maxWidth: "816px",
    borderTop: `1px solid ${alpha(colors["deepMidnightBlue"], 0.1)}`,
    borderBottom: `1px solid ${alpha(colors["deepMidnightBlue"], 0.1)}`,
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&::before": {
      display: "none",
    },
  };
});

const AccordionSummary = styled(MuiAccordionSummary)(({ theme }) => {
  const { colors } = useMUIThemeModeContext();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return {
    padding: 0,
    "& .MuiAccordionSummary-content": {
      marginTop: "24px",
      marginBottom: "24px",
    },
    "& .MuiAccordionSummary-content .MuiTypography-body1": {
      color: colors["deepMidnightBlue"],
      fontSize: isMobile ? "0.875rem" : "1rem",
    },
  };
});

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => {
  const { colors } = useMUIThemeModeContext();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return {
    color: colors["blueGray"],
    fontSize: isMobile ? "0.875rem" : "1rem",
    padding: 0,
  };
});

const Faq = () => {
  const { t } = useTranslation();
  const { colors } = useMUIThemeModeContext();

  const [faqOpen, setFaqOpen] = useState<string | false>("faq-0");

  const onFaqChange = (id: string) => (_event: React.SyntheticEvent, isOpen: boolean) => {
    setFaqOpen(isOpen ? id : false);
  };

  return (
    <HomeBox title={t("faq")}>
      <Box mt={{ xs: 2, md: 6 }} display={"flex"} flexDirection={"column"} alignItems={"center"} textAlign={"start"}>
        {FaqItems.map((item, key) => {
          const itemKey = `faq-${key}`;
          return (
            <Accordion
              key={itemKey}
              expanded={itemKey === faqOpen}
              onChange={onFaqChange(itemKey)}
              disableGutters
              elevation={0}
              sx={{ backgroundColor: "white" }}
              square>
              <AccordionSummary
                expandIcon={
                  itemKey === faqOpen ? (
                    <RemoveIcon fontSize="small" sx={{ color: colors["deepMidnightBlue"] }} />
                  ) : (
                    <AddIcon fontSize="small" sx={{ color: colors["deepMidnightBlue"] }} />
                  )
                }>
                <Typography color="gray">{item.title}</Typography>
              </AccordionSummary>
              <AccordionDetails>{item.body}</AccordionDetails>
            </Accordion>
          );
        })}
      </Box>
    </HomeBox>
  );
};

export default Faq;
