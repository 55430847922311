import React from "react";

import { Button, Stack, Typography, useTheme } from "@mui/material";
import Card2 from "components/elements/Card2";
import SectionCard from "components/elements/SectionCard";
import { Wallet } from "components/icons";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

interface IProps {
  header: string;
  onAdd: () => void;
}

const ListWrapper: React.FC<IProps> = ({ header, onAdd, children }) => {
  const { colors } = useMUIThemeModeContext();
  const { t } = useTranslation();
  const theme = useTheme();

  const action = (
    <Button variant="contained" size="small" onClick={onAdd}>
      {children ? t("withdrawalConnections.smallAdd") : t("withdrawalConnections.add")}
    </Button>
  );

  return (
    <SectionCard title={header} actions={children ? action : undefined}>
      {children ? (
        children
      ) : (
        <Card2 width={{ sm: "100%", lg: "75%" }}>
          <Stack alignItems="center" textAlign="center">
            <Wallet color="inherit" sx={{ fontSize: 24 }} />
            <Typography fontSize={"1rem"} fontWeight={600} pt={3}>
              {t("withdrawalConnections.description1")}
            </Typography>
            <Typography color={colors.gray600} fontSize={"0.875rem"} pt={2}>
              <Trans i18nKey={`withdrawalConnections.description2`} />
            </Typography>
            <Typography color={colors.gray600} fontSize={"0.875rem"}>
              <Trans i18nKey={`withdrawalConnections.description3`} />
            </Typography>
            <Typography color={colors.gray600} fontSize={"0.875rem"} py={2}>
              <Trans i18nKey={`withdrawalConnections.description4`} />
            </Typography>
            {action}
            <Typography color={colors.gray600} fontSize={"0.875rem"} pt={4}>
              <Trans i18nKey={`withdrawalConnections.description5`} />
            </Typography>
            <Typography color={colors.gray600} fontSize={"0.875rem"}>
              <Trans
                i18nKey={`withdrawalConnections.description6`}
                components={{
                  a: <Link style={{ color: theme.palette.primary.main }} to="/predplatne" />,
                }}
              />
            </Typography>
          </Stack>
        </Card2>
      )}
    </SectionCard>
  );
};

export default ListWrapper;
