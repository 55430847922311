import { Box } from "@mui/material";

interface Props {
  index: number;
  value: number;
}

const TabPanel: React.FC<Props> = ({ children, value, index, ...other }) => {
  return (
    <div role="tabpanel" hidden={value !== index} id={`TabPanel-${index}`} aria-labelledby={`Tab-${index}`} {...other}>
      {value === index && <Box sx={{ py: 3 }}>{children}</Box>}
    </div>
  );
};

export default TabPanel;
