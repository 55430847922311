import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import PageTitle from "components/elements/PageTitle";
import Panel from "components/elements/Panel";
import Tabs from "components/elements/Tabs";
import { useTranslation } from "react-i18next";
import styled from "styled-components"

import PersonalDataProcesing from "./PersonalDataProcessing";
import RecurringPayments from "./RecurringPayments";
import TermsAndConditions from "./TermsAndConditions";

const StyledTabs = styled(Tabs)`
  p {
    font-size: 0.8em;
  }
`;

const TermsPage = () => {
  const { t } = useTranslation();

  const termsTabs = [
    {
      label: "Obchodní podmínky",
      component: <TermsAndConditions />,
    },
    {
      label: "Zpracování osobních údajů",
      component: <PersonalDataProcesing />,
    },
    {
      label: "Opakované platby",
      component: <RecurringPayments />,
    },
  ];

  return (
    <Grid container justifyContent="center" spacing={8}>
      <Grid item sx={{ width: "100%" }}>
        <Panel sx={{ p: 4, mb: 10 }}>
          <Typography variant="h5" component="h1" fontWeight={600} sx={{ pb: 3, color: "tertiary.main" }}>
            {t("terms")}
          </Typography>
          <PageTitle i18nKey="termsPage.meta_title" />
          <StyledTabs tabs={termsTabs} />
        </Panel>
      </Grid>
    </Grid>
  );
};

export default TermsPage;
