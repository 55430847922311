import { SvgIcon } from "@mui/material";

export const Checklist = (props: any) => {
  return (
    <SvgIcon {...props}>
      <svg width="24" height="24" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.9549 1.7334H16.0446C18.1861 1.73339 19.854 1.73339 21.1894 1.849C22.546 1.96646 23.6465 2.20852 24.6282 2.74459C26.0191 3.50408 27.1624 4.64737 27.9219 6.03827C28.458 7.02001 28.7 8.12049 28.8175 9.47709C28.9331 10.8125 28.9331 12.4804 28.9331 14.622V17.3781C28.9331 19.5197 28.9331 21.1876 28.8175 22.523C28.7 23.8796 28.458 24.9801 27.9219 25.9619C27.1624 27.3528 26.0191 28.4961 24.6282 29.2555C23.6465 29.7916 22.546 30.0337 21.1894 30.1511C19.854 30.2667 18.186 30.2667 16.0445 30.2667H15.955C13.8134 30.2667 12.1455 30.2667 10.8101 30.1511C9.4535 30.0337 8.35301 29.7916 7.37128 29.2555C5.98037 28.4961 4.83709 27.3528 4.07759 25.9619C3.54153 24.9801 3.29947 23.8796 3.18201 22.523C3.0664 21.1877 3.0664 19.5198 3.06641 17.3782V14.6219C3.0664 12.4804 3.0664 10.8125 3.18201 9.47709C3.29947 8.12049 3.54153 7.02001 4.07759 6.03827C4.83709 4.64737 5.98037 3.50408 7.37128 2.74459C8.35301 2.20852 9.4535 1.96646 10.8101 1.849C12.1455 1.73339 13.8134 1.73339 15.9549 1.7334ZM10.9711 3.70871C9.73755 3.81552 8.92692 4.02197 8.26588 4.38292C7.18905 4.97091 6.30392 5.85604 5.71593 6.93287C5.35497 7.59391 5.14852 8.40454 5.04172 9.6381C4.93389 10.8836 4.93307 12.4709 4.93307 14.6667V17.3334C4.93307 19.5292 4.93389 21.1165 5.04172 22.362C5.14852 23.5956 5.35497 24.4062 5.71593 25.0673C6.30392 26.1441 7.18905 27.0292 8.26588 27.6172C8.92692 27.9782 9.73755 28.1846 10.9711 28.2914C12.2166 28.3993 13.8039 28.4001 15.9997 28.4001C18.1956 28.4001 19.7829 28.3993 21.0284 28.2914C22.2619 28.1846 23.0726 27.9782 23.7336 27.6172C24.8104 27.0292 25.6956 26.1441 26.2836 25.0673C26.6445 24.4062 26.851 23.5956 26.9578 22.362C27.0656 21.1165 27.0664 19.5292 27.0664 17.3334V14.6667C27.0664 12.4709 27.0656 10.8836 26.9578 9.6381C26.851 8.40454 26.6445 7.59391 26.2836 6.93287C25.6956 5.85604 24.8104 4.97091 23.7336 4.38292C23.0726 4.02197 22.2619 3.81552 21.0284 3.70871C19.7829 3.60088 18.1956 3.60006 15.9997 3.60006C13.8039 3.60006 12.2166 3.60088 10.9711 3.70871Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.9704 9.986C14.3463 10.3388 14.365 10.9294 14.0123 11.3053L13.3317 10.6665C14.0123 11.3053 14.0123 11.3052 14.0123 11.3053L13.9029 11.4214C13.8336 11.4949 13.7345 11.5998 13.6152 11.7256C13.3767 11.977 13.0562 12.3129 12.73 12.6495C12.4052 12.9846 12.069 13.3262 11.8008 13.5864C11.6682 13.715 11.5421 13.8336 11.438 13.9239C11.388 13.9673 11.3271 14.018 11.2646 14.0627C11.2347 14.084 11.1851 14.118 11.1234 14.1506C11.1226 14.1511 11.1216 14.1516 11.1203 14.1523C11.0882 14.1696 10.9083 14.2665 10.665 14.2665C10.4067 14.2665 10.2158 14.1598 10.1728 14.1358C10.1716 14.1351 10.1706 14.1345 10.1696 14.134C10.0962 14.0932 10.0349 14.0488 9.99363 14.0172C9.90843 13.9521 9.82236 13.8747 9.74756 13.8042C9.59373 13.6593 9.41665 13.476 9.25637 13.3053C9.09358 13.1318 8.9373 12.9597 8.82241 12.8316C8.76477 12.7674 8.71708 12.7138 8.68364 12.6761L8.64462 12.6319L8.63016 12.6155C8.29027 12.2279 8.32875 11.6381 8.71627 11.2982C9.10379 10.9583 9.69346 10.9969 10.0334 11.3844C10.0333 11.3844 10.0334 11.3844 10.0334 11.3844L10.0451 11.3978L10.0809 11.4383C10.1121 11.4735 10.157 11.524 10.2116 11.5848C10.3211 11.7068 10.4674 11.8679 10.6175 12.0278C10.6346 12.0461 10.6517 12.0642 10.6686 12.0821C10.8849 11.8677 11.1369 11.611 11.3896 11.3503C11.7096 11.0202 12.0251 10.6894 12.261 10.4408C12.3788 10.3166 12.4766 10.2132 12.5448 10.1408L12.6508 10.0282C12.6508 10.0282 12.6512 10.0278 12.9617 10.3193L12.6508 10.0282C13.0035 9.65237 13.5946 9.63324 13.9704 9.986Z"
          fill="#FF9900"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.9704 19.319C14.3463 19.6718 14.365 20.2624 14.0123 20.6383L13.3317 19.9996C14.0123 20.6383 14.0123 20.6382 14.0123 20.6383L13.9029 20.7544C13.8336 20.828 13.7345 20.9328 13.6152 21.0586C13.3767 21.31 13.0562 21.6459 12.73 21.9825C12.4052 22.3176 12.069 22.6592 11.8008 22.9194C11.6682 23.048 11.5421 23.1666 11.438 23.2569C11.388 23.3003 11.3271 23.351 11.2646 23.3957C11.2347 23.417 11.1851 23.4511 11.1234 23.4837C11.1226 23.4841 11.1216 23.4846 11.1203 23.4853C11.0882 23.5026 10.9083 23.5996 10.665 23.5996C10.4067 23.5996 10.2158 23.4928 10.1728 23.4688C10.1716 23.4682 10.1706 23.4676 10.1696 23.467C10.0962 23.4262 10.0349 23.3818 9.99363 23.3502C9.90843 23.2851 9.82236 23.2077 9.74756 23.1372C9.59373 22.9923 9.41665 22.8091 9.25637 22.6383C9.09358 22.4648 8.9373 22.2927 8.82241 22.1647C8.76477 22.1004 8.71708 22.0468 8.68364 22.0091L8.64462 21.9649L8.63016 21.9485C8.29027 21.561 8.32875 20.9711 8.71627 20.6312C9.10379 20.2913 9.69346 20.3299 10.0334 20.7174C10.0333 20.7174 10.0334 20.7174 10.0334 20.7174L10.0451 20.7308L10.0809 20.7713C10.1121 20.8065 10.157 20.857 10.2116 20.9178C10.3211 21.0398 10.4674 21.2009 10.6175 21.3608C10.6346 21.3791 10.6517 21.3972 10.6686 21.4151C10.8849 21.2007 11.1369 20.944 11.3896 20.6833C11.7096 20.3532 12.0251 20.0224 12.261 19.7738C12.3788 19.6496 12.4766 19.5462 12.5448 19.4738L12.6508 19.3612C12.6508 19.3612 12.6512 19.3608 12.9617 19.6523L12.6508 19.3612C13.0035 18.9854 13.5946 18.9663 13.9704 19.319Z"
          fill="#FF9900"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.6016 22.0003C23.6016 22.5157 23.1837 22.9336 22.6682 22.9336L17.3349 22.9336C16.8194 22.9336 16.4016 22.5157 16.4016 22.0003C16.4016 21.4848 16.8194 21.0669 17.3349 21.0669L22.6682 21.0669C23.1837 21.0669 23.6016 21.4848 23.6016 22.0003Z"
          fill="#FF9900"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.6016 12.6663C23.6016 13.1817 23.1837 13.5996 22.6682 13.5996L17.3349 13.5996C16.8194 13.5996 16.4016 13.1817 16.4016 12.6663C16.4016 12.1508 16.8194 11.7329 17.3349 11.7329L22.6682 11.7329C23.1837 11.7329 23.6016 12.1508 23.6016 12.6663Z"
          fill="#FF9900"
        />
      </svg>
    </SvgIcon>
  );
};
