import React from "react";

import { CssBaseline } from "@mui/material";
import Theme from "config/Theme";
import { UserDataProvider } from "context/UserDataContext";
import { SnackbarProvider } from "notistack";
import ReactDOM from "react-dom";
import "animate.css";
import "react-image-gallery/styles/css/image-gallery.css";

import App from "./App";

// There's not point of having Sentry running when we haven't launched yet
// import * as Sentry from "@sentry/react";
// import { Integrations } from "@sentry/tracing";
// Sentry.init({
//   dsn: "https://568db12546b54eaab024668433e8f253@o1033628.ingest.sentry.io/6000354",
//   integrations: [new Integrations.BrowserTracing()],
//   release: "stosuj-dashboard@" + process.env.npm_package_version,
//   tracesSampleRate: 1.0,
//   environment: "production",
// });

ReactDOM.render(
  <React.StrictMode>
    <UserDataProvider>
      <SnackbarProvider maxSnack={3}>
        <Theme>
          <CssBaseline />
          <App />
        </Theme>
      </SnackbarProvider>
    </UserDataProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
