import { CircleLoader, Check } from "./styles";

interface IProps {
  completed: boolean;
}

const CheckMarkLoader = ({ completed }: IProps) => {
  return <CircleLoader completed={completed}>{completed && <Check />}</CircleLoader>;
};

export default CheckMarkLoader;
