import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { getColorByMode } from "utils";

const getBorderThickness = (border: string) => {
  const borderValue = parseFloat(border);

  if (isNaN(borderValue) || borderValue === 1) return 0;
  return borderValue / 16; // 1em = 16px;
};

const LOADER_SIZE = "7em";
const BORDER_THICKNESS = "2px";
const CHECK_HEIGHT = `${parseFloat(LOADER_SIZE) / 2}em`;
const CHECK_WIDTH = `${parseFloat(CHECK_HEIGHT) / 2}em`;
const CHECK_TOP = `${parseFloat(LOADER_SIZE) / 2 - getBorderThickness(BORDER_THICKNESS)}em`;
const CHECK_LEFT = `${parseFloat(LOADER_SIZE) / 6 + parseFloat(LOADER_SIZE) / 12 - getBorderThickness(BORDER_THICKNESS)}em`;
const CHECK_THICKNESS = "5px";

export const CircleLoader = styled(Box)<{ completed?: boolean }>(({ theme, completed }) => {
  const { colors } = useMUIThemeModeContext();

  return {
    border: `${BORDER_THICKNESS} solid ${getColorByMode(theme, colors.gray200, colors.gray400)}`,
    borderLeftColor: completed ? theme.palette.success.light : theme.palette.primary.main,
    animation: "spin 1.2s infinite linear",
    position: "relative",
    display: "inline-block",
    verticalAlign: "top",
    borderRadius: "50%",
    width: LOADER_SIZE,
    height: LOADER_SIZE,
    ...(completed && {
      WebkitAnimation: "none",
      animation: "none",
      borderColor: theme.palette.success.light,
      transition: "border 500ms ease-out",
    }),

    "@keyframes spin": {
      "0%": {
        transform: "rotate(0deg)",
      },
      "100%": {
        transform: "rotate(360deg)",
      },
    },
  };
});

export const Check = styled(Box)(({ theme }) => ({
  "&:after": {
    opacity: 1,
    height: CHECK_HEIGHT,
    width: CHECK_WIDTH,
    transformOrigin: "left top",
    borderRight: `${CHECK_THICKNESS} solid ${theme.palette.success.light}`,
    borderTop: `${CHECK_THICKNESS} solid ${theme.palette.success.light}`,
    content: "''",
    left: CHECK_LEFT,
    top: CHECK_TOP,
    position: "absolute",
    animationDuration: "800ms",
    animationTimingFunction: "ease",
    animationName: "check",
    transform: "scaleX(-1) rotate(135deg)",
  },

  "@keyframes check": {
    "0%": {
      height: 0,
      width: 0,
      opacity: 1,
    },
    "20%": {
      height: 0,
      width: CHECK_WIDTH,
      opacity: 1,
    },
    "40%": {
      height: CHECK_HEIGHT,
      width: CHECK_WIDTH,
      opacity: 1,
    },
    "100%": {
      height: CHECK_HEIGHT,
      width: CHECK_WIDTH,
      opacity: 1,
    },
  },
}));
