import React from "react";

import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import MUISelect from "@mui/material/Select";

import IProps from "./types";

const Select: React.FC<IProps> = ({ label, error, children, size, ...selectProps }: IProps) => {
  return (
    <FormControl fullWidth error={!!error} size={size}>
      <InputLabel>{label}</InputLabel>
      <MUISelect label={label} error={!!error} {...selectProps} size={size}>
        {children}
      </MUISelect>
      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  );
};

export default Select;
