import { EXIT_STRATEGY } from "constants/links";

import { Box } from "@mui/material";
import DcaHome from "assets/images/dca-home.png";
import DynamicDcaHome from "assets/images/dynamic-dca-home.png";
import ExitHome from "assets/images/exit-home.png";
import GridHome from "assets/images/grid-home.png";
import HomeBox from "components/modules/HomeBox";
import HomeStrategyTile from "components/modules/HomeStrategyTile";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { Trans, useTranslation } from "react-i18next";

import DcaCalculator from "../DcaCalculator";

const SelectStrategy = () => {
  const { t } = useTranslation();
  const { colors } = useMUIThemeModeContext();

  return (
    <>
      <HomeBox bgcolor={colors["babyBlue"]} id={"vyber-strategie"} title={t("homepage.selectStrategy.title")} subtitle={t("homepage.selectStrategy.subtitle")}>
        <Box pt={{ xs: 5, md: 9 }} id={"dca-strategie"}>
          <HomeStrategyTile
            title={t("homepage.selectStrategy.dca.title")}
            name={t("homepage.selectStrategy.dca.name")}
            subtitle={<Trans i18nKey="homepage.selectStrategy.dca.subtitle" />}
            info={{
              button: t("homepage.selectStrategy.dca.info.button"),
              link: "/proc-bitcoin/stosuj-cz-navod-krok-za-krokem",
              title: t("homepage.selectStrategy.dca.info.title"),
              description: <Trans i18nKey={"homepage.selectStrategy.dca.info.description"} />,
            }}
            image={DcaHome}
          />
        </Box>
      </HomeBox>
      <DcaCalculator />
      <HomeBox bgcolor={colors["babyBlue"]} id={"dynamicka-dca-strategie"}>
        <HomeStrategyTile
          title={t("homepage.selectStrategy.dynamicDca.title")}
          name={t("homepage.selectStrategy.dynamicDca.name")}
          subtitle={<Trans i18nKey="homepage.selectStrategy.dynamicDca.subtitle" />}
          info={{
            button: t("homepage.selectStrategy.dynamicDca.info.button"),
            link: "/proc-bitcoin/dynamicka-dca-strategie",
            title: t("homepage.selectStrategy.dynamicDca.info.title"),
            description: <Trans i18nKey={"homepage.selectStrategy.dynamicDca.info.description"} />,
          }}
          backTest={{
            button: t("homepage.selectStrategy.dynamicDca.backTest.button"),
            link: "/backtest-dca-strategie",
            title: t("homepage.selectStrategy.dynamicDca.backTest.title"),
            description: <Trans i18nKey={"homepage.selectStrategy.dynamicDca.backTest.description"} />,
          }}
          image={DynamicDcaHome}
        />
      </HomeBox>
      <HomeBox id={"grid-strategie"}>
        <HomeStrategyTile
          title={t("homepage.selectStrategy.grid.title")}
          name={t("homepage.selectStrategy.grid.name")}
          subtitle={<Trans i18nKey="homepage.selectStrategy.grid.subtitle" />}
          info={{
            button: t("homepage.selectStrategy.grid.info.button"),
            link: "/proc-bitcoin/predstaveni-grid-strategie",
            title: t("homepage.selectStrategy.grid.info.title"),
            description: <Trans i18nKey={"homepage.selectStrategy.grid.info.description"} />,
          }}
          backTest={{
            button: t("homepage.selectStrategy.grid.backTest.button"),
            link: "/backtest-grid-strategie",
            title: t("homepage.selectStrategy.grid.backTest.title"),
            description: <Trans i18nKey={"homepage.selectStrategy.grid.backTest.description"} />,
          }}
          image={GridHome}
          reverse
        />
      </HomeBox>
      <HomeBox id={"exit-strategie"} bgcolor={colors["babyBlue"]}>
        <HomeStrategyTile
          title={t("homepage.selectStrategy.exit.title")}
          name={t("homepage.selectStrategy.exit.name")}
          subtitle={<Trans i18nKey="homepage.selectStrategy.exit.subtitle" />}
          info={{
            button: t("homepage.selectStrategy.exit.info.button"),
            link: "/proc-bitcoin/exit-strategie",
            title: t("homepage.selectStrategy.exit.info.title"),
            description: <Trans i18nKey={"homepage.selectStrategy.exit.info.description"} />,
          }}
          backTest={{
            button: t("homepage.selectStrategy.exit.backTest.button"),
            link: EXIT_STRATEGY,
            target: "_blank",
          }}
          image={ExitHome}
        />
      </HomeBox>
    </>
  );
};

export default SelectStrategy;
