export const REFERRAL_ANYCOIN = "https://www.anycoin.cz/?ref=stosuj";
export const REFERRAL_COINMATE = "https://coinmate.io/cs/sign-up?clickid=4628110125";
export const COINMATE_FEES = "https://coinmate.io/cs/fees";
export const TREZOR_AFFILIATE = "https://affil.trezor.io/aff_c?offer_id=133&aff_id=10904";
export const DISCORD = "https://discord.gg/dXNKsCnTX4";
export const GRID_STRATEGY_INTRODUCTION = "https://stosuj.cz/proc-bitcoin/predstaveni-grid-strategie";
export const GRID_STRATEGY_BACKTEST = "https://stosuj.cz/backtest-grid-strategie";
export const GRID_STRATEGY_HOW_TO = "https://stosuj.cz/proc-bitcoin/detailni-vysvetleni-pouziti-a-fungovani-grid-strategie";
export const EXIT_STRATEGY = "https://docs.google.com/spreadsheets/d/1cpXHZMPTHXNt0Dw5xk1Wigy1WMc2KVNdjunSGBoELWI/edit";
export const EMAIL = "info@stosuj.cz";
export const TWITTER = "https://x.com/StosujCZ";
export const INSTAGRAM = "https://www.instagram.com/stosuj.cz";
export const FACEBOOK = "https://www.facebook.com/stosuj.cz";
export const YOUTUBE = "https://www.youtube.com/@StosujCZ";
export const LINKEDIN = "https://www.linkedin.com/company/stosujcz";
export const AFFILIATE = "https://docs.google.com/document/d/1W5Mtp50tPoO1qyBjObanXvFku9NlUScJdtXOmFiAulE/edit";
