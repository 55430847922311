import React, { useEffect, useRef, useState } from "react";

import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { deleteBot, updateBotEnabled } from "API/calls";
import BackdropLoading from "components/elements/BackdropLoading";
import { useDefaultLayoutContext } from "context/DefaultLayoutContext";
import { useAPIBotsBalances, useAPIBotsOrdersList, useAPIGridBotDetail } from "hooks/useAPI";
import useConfirm from "hooks/useConfirm";
import { useSnackbar } from "notistack";
import { ErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { LayoutPosition } from "types/enums";
import { splitCurrencyPair } from "utils";

import BotDetailSkeleton from "../BotDetailSkeleton";
import Balance from "./Balance";
import Chart from "./Chart";
import ErrorFallback from "./Chart/ErrorFallback";
import ChartProfit from "./ChartProfit";
import ControlPanel from "./ControlPanel";
import Detail from "./Detail";
import Levels from "./Levels";
import OrdersList from "./OrdersList";
import { MediaQueryGrid, MediaQueryGridReverse } from "./styles";
import IProps from "./types";

const getOrderListPosition = () => {
  const position = localStorage.getItem("gridOrderListPosition") as LayoutPosition;
  return position ? position : LayoutPosition.BOTTOM;
};

const GridBotDetail: React.FC<IProps> = ({ id }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { confirm } = useConfirm();
  const navigate = useNavigate();
  const [isOpenBackdropLoading, setIsOpenBackdropLoading] = useState<boolean>(false);
  const [syncInterval, setSyncInterval] = useState<number>(15000);
  const { enqueueSnackbar } = useSnackbar();
  const isOneColumn = useMediaQuery(theme.breakpoints.down("lg"));
  const chartRef = useRef<{ refreshOHLC: () => void }>();
  const { refreshMenu } = useDefaultLayoutContext();
  const [position, setPosition] = useState<LayoutPosition>(getOrderListPosition());

  const {
    data: gridBotsDetailData,
    mutate: gridBotsDetailRefresh,
    clearCache: gridBotsDetailClear,
    isValidating: gridBotsDetailIsValidating,
  } = useAPIGridBotDetail(id, true, syncInterval);
  const {
    data: botsOrdersListData,
    mutate: botsOrdersListRefresh,
    clearCache: botsOrdersListClear,
    isValidating: botsOrdersListIsValidating,
  } = useAPIBotsOrdersList(id, syncInterval);
  const {
    data: botsBalances,
    isValidating: botsBalancesIsValidating,
    error,
    mutate: botsBalancesRefresh,
    clearCache: botsBalancesClear,
  } = useAPIBotsBalances(id, gridBotsDetailData?.exchangeConnection.functional);

  const baseCurrencyProfitChart = botsOrdersListData?.baseCurrencyProfitChart;

  useEffect(() => {
    return () => {
      gridBotsDetailClear();
      botsOrdersListClear();
      botsBalancesClear();
    };
  }, []);

  useEffect(() => {
    if(!gridBotsDetailIsValidating && gridBotsDetailData === null) gridBotsDetailRefresh();
    if(!botsOrdersListIsValidating && botsOrdersListData === null) botsOrdersListRefresh();
    if(!botsBalancesIsValidating && botsBalances === null && !error) botsBalancesRefresh();
  }, [botsBalancesIsValidating, botsOrdersListIsValidating, gridBotsDetailIsValidating]);

  useEffect(() => {
    if (gridBotsDetailData?.details) {
      const newInterval = gridBotsDetailData?.details.synced ? 15000 : 5000;
      if (
        gridBotsDetailData?.details.synced === false
        || (gridBotsDetailData?.details.synced === true && syncInterval === 5000)
      ) {
        botsBalancesRefresh();
      }
      if (newInterval !== syncInterval) setSyncInterval(newInterval);
    }
  }, [gridBotsDetailData?.details]);

  if (!gridBotsDetailData) return <BotDetailSkeleton />

  const { baseCurrency, counterCurrency } = splitCurrencyPair(gridBotsDetailData.currencyPair);

  const handleEnabled = async () => {
    try {
      await confirm({
        title: gridBotsDetailData.enabled ? t("gridBot.confirmDisable.title") : t("gridBot.confirmEnable.title"),
        description: gridBotsDetailData.enabled
          ? t("gridBot.confirmDisable.description")
          : t("gridBot.confirmEnable.description"),
      });

      setIsOpenBackdropLoading(true);
      try {
        await updateBotEnabled(id, { enabled: !gridBotsDetailData.enabled });
        enqueueSnackbar(
          !gridBotsDetailData.enabled ? t("gridBot.successMessageEnable") : t("gridBot.successMessageDisable"),
          { variant: "success" }
        );
        gridBotsDetailRefresh();
        botsOrdersListRefresh();
        botsBalancesRefresh();
        refreshMenu?.();
        chartRef?.current?.refreshOHLC();
      } catch (error: any) {
        if (error?.response?.status === 409) {
          enqueueSnackbar(t("gridBot.create.errorMessage409"), { variant: "error" });
        } else {
          enqueueSnackbar(!gridBotsDetailData.enabled ? t("gridBot.errorMessageEnable") : t("gridBot.errorMessageDisable"), { variant: "error" });
        }
      }
      setIsOpenBackdropLoading(false);
    } catch (err: any) {
      // silent
    }
  };

  const handleDelete = async () => {
    try {
      await confirm({
        title: t("gridBot.confirmDelete.title"),
        description: t("gridBot.confirmDelete.description"),
        dialogProps: { maxWidth: "xs" },
      });

      setIsOpenBackdropLoading(true);
      try {
        await deleteBot(id);
        enqueueSnackbar(t("gridBot.deleteSuccessMessage"), { variant: "success" });
        refreshMenu?.();
        navigate(`/dashboard`);
      } catch (error: any) {
        enqueueSnackbar(t("gridBot.deleteErrorMessage"), { variant: "error" });
      }
      setIsOpenBackdropLoading(false);
    } catch (err: any) {
      // silent
    }
  };

  const togglePosition = () => {
    const newPosition = position === LayoutPosition.BOTTOM ? LayoutPosition.TOP : LayoutPosition.BOTTOM;
    setPosition(newPosition);
    localStorage.setItem("gridOrderListPosition", newPosition);
  }

  const getOrdersList = () => (
    <OrdersList
      data={botsOrdersListData}
      baseCurrency={baseCurrency}
      counterCurrency={counterCurrency}
      exchangeEnum={gridBotsDetailData.exchangeConnection.exchangeEnum}
      enabled={gridBotsDetailData.enabled}
      position={position}
      onPositionToggle={togglePosition}
    />
  );

  return (
    <Grid container spacing={4}>
      <BackdropLoading open={isOpenBackdropLoading} />
      <Grid item lg={7} xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Detail
              id={id}
              ordersList={botsOrdersListData}
              data={gridBotsDetailData}
              onEnabled={handleEnabled}
              onDelete={handleDelete}>
              <ErrorBoundary FallbackComponent={ErrorFallback}>
                <Chart
                  ref={chartRef}
                  data={gridBotsDetailData.details}
                  exchangeEnum={gridBotsDetailData.exchangeConnection.exchangeEnum}
                  isBrokerage={gridBotsDetailData.exchangeConnection.isBrokerage}
                  exchangeTitle={gridBotsDetailData.exchangeConnection.exchangeTitle}
                  currencyPair={gridBotsDetailData.currencyPair}
                />
              </ErrorBoundary>
            </Detail>
          </Grid>
          <MediaQueryGrid item xs={12} disabled={position === LayoutPosition.TOP}>
            {getOrdersList()}
          </MediaQueryGrid>
        </Grid>
      </Grid>
      <Grid item lg={5} xs={12}>
        <Grid container spacing={4}>
          {!isOneColumn && (
            <Grid item xs={12}>
              <ControlPanel
                id={id}
                enabled={gridBotsDetailData.enabled}
                functional={gridBotsDetailData.exchangeConnection.functional}
                onEnabled={handleEnabled}
                onDelete={handleDelete}
              />
            </Grid>
          )}
          {baseCurrencyProfitChart && (
            <Grid item xs={12}>
              <ChartProfit
                chartData={baseCurrencyProfitChart}
                baseCurrency={baseCurrency}
                currencyPair={gridBotsDetailData.currencyPair}
                exchangeEnum={gridBotsDetailData.exchangeConnection.exchangeEnum}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Balance
              data={botsBalances}
              isValidating={botsBalancesIsValidating}
              error={error}
              exchangeConnection={gridBotsDetailData.exchangeConnection}
              baseCurrency={baseCurrency}
              counterCurrency={counterCurrency}
              functional={gridBotsDetailData.exchangeConnection.functional}
              bidPrice={gridBotsDetailData.details?.bidPrice}
            />
          </Grid>
          <Grid item xs={12}>
            <Levels
              data={gridBotsDetailData.details}
              exchangeEnum={gridBotsDetailData.exchangeConnection.exchangeEnum}
              currencyPair={gridBotsDetailData.currencyPair}
            />
          </Grid>
          <MediaQueryGridReverse item xs={12} disabled={position === LayoutPosition.BOTTOM}>
            {getOrdersList()}
          </MediaQueryGridReverse>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default GridBotDetail;
