import { Grid, CircularProgress, Box, Alert, AlertTitle } from "@mui/material";
import BalanceItem from "components/elements/BalanceItem";
import Card from "components/elements/Card";
import useExchangesMetadata from "hooks/useExchangesMetadata";
import { Trans, useTranslation } from "react-i18next";
import { IBalance } from "types/types";

import IProps from "./types";

const Balance = ({
  baseCurrency,
  counterCurrency,
  exchangeConnection,
  isValidating,
  error,
  data,
  functional,
  bidPrice,
}: IProps) => {
  const { t } = useTranslation();
  const { exchangeEnum, isBrokerage, exchangeTitle, label } = exchangeConnection ?? {};
  const { counterCurrDisplayedScale, baseCurrDisplayedScale } = useExchangesMetadata(
    exchangeEnum ?? "",
    `${baseCurrency}/${counterCurrency}`
  );

  const getCounterCurrencyAmount = (value: number) => {
    if (!bidPrice) return;

    const amount = value * bidPrice;
    return amount ? { value: amount, currency: counterCurrency, scale: counterCurrDisplayedScale } : undefined;
  }

  const balanceItem = (
    balance: IBalance,
    label: string,
    decimalPlaces: number,
    withZeros = false,
    isFirstChild = false
  ) => {
    const { available, frozen, currency } = balance;

    return (
      <>
        <BalanceItem
          label={label}
          value={available}
          decimalPlaces={decimalPlaces}
          withZeros={withZeros}
          currency={currency}
          isFirstChild={isFirstChild}
          amount={isFirstChild ? getCounterCurrencyAmount(available) : undefined}
        />
        <BalanceItem
          label={t("gridBot.balances.frozen")}
          value={frozen}
          decimalPlaces={decimalPlaces}
          withZeros={withZeros}
          currency={currency}
          tooltip={t("gridBot.balances.frozenDesc")}
          amount={isFirstChild ? getCounterCurrencyAmount(frozen) : undefined}
        />
      </>
    );
  };

  return (
    <Card
      sx={{ mb: 0 }}
      header={
        isBrokerage
          ? t("dcaBots.brokerage", { exchange: exchangeTitle ?? "" })
          : t("dcaBots.exchange", { exchange: exchangeTitle ?? "" })
      }
      subheader={label ?? ""}>
      <Grid container>
        <Grid item xs={12} sx={{ display: "flex", flexDirection: "column" }}>
          {data?.baseCurrencyBalance
            && balanceItem(
              data?.baseCurrencyBalance,
              t("gridBot.balances.available", { currency: data?.baseCurrencyBalance?.currency }),
              baseCurrDisplayedScale,
              true,
              true
            )}
          {data?.counterCurrencyBalance
            && balanceItem(
              data?.counterCurrencyBalance,
              t("gridBot.balances.available", { currency: data?.counterCurrencyBalance?.currency }),
              counterCurrDisplayedScale,
              true
            )}
          {isValidating && !data && (
            <Box p={1} justifyContent="center" width="100%" display="flex">
              <CircularProgress color="primary" />
            </Box>
          )}
          {error
            && (error?.response?.status === 502 ? (
              <Alert severity="warning">
                <AlertTitle>
                  <Trans i18nKey="dcaBots.balances.warning.title" />
                </AlertTitle>
                <Trans i18nKey="dcaBots.balances.warning.description" />
              </Alert>
            ) : (
              <Alert severity="error">
                <AlertTitle>
                  <Trans
                    i18nKey={
                      isBrokerage ? "dcaBots.balances.errorBrokerageTitle" : "dcaBots.balances.errorExchangeTitle"
                    }
                  />
                </AlertTitle>
                <Trans i18nKey="gridBot.balances.errorConnectionText" />
              </Alert>
            ))}
          {!functional && (
            <Alert severity="error">
              <AlertTitle>
                <Trans
                  i18nKey={
                    isBrokerage ? "exchangeConnections.functionalError.brokerage" : "exchangeConnections.functionalError.exchange"
                  }
                />
              </AlertTitle>
              <Trans i18nKey="exchangeConnections.functionalError.description" />
            </Alert>
          )}
        </Grid>
      </Grid>
    </Card>
  );
};

export default Balance;
