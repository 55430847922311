import React, { useState, useEffect } from "react";

import { Typography, Stack, Button, Box } from "@mui/material";
import { runDcaBot } from "API/calls";
import Card from "components/elements/Card";
import MaskedModeWrapper from "components/elements/MaskedModeWrapper";
import DynamicChart from "components/modules/DynamicChart";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { useAPIDcaBotsDynamicStrategyChart } from "hooks/useAPI";
import useConfirm from "hooks/useConfirm";
import useExchangesMetadata from "hooks/useExchangesMetadata";
import { useTranslation, Trans } from "react-i18next";
import { BotStrategyType, DcaBotMode, DcaDynamicStrategyType } from "types/enums";
import { BooleanParam, useQueryParams } from "use-query-params";
import { splitCurrencyPair, translateBotFrequency } from "utils";
import { renderDateTimeWithComma, renderNumber } from "utils/formatter";

import { ItemBox, CustomCachedIcon } from "./styles";
import IProps from "./types";

const Settings: React.FC<IProps> = ({
  id,
  strategy,
  currencyPair,
  frequency,
  frequencyParameter,
  createdAt,
  updatedAt,
  exchangeEnum,
  enabled,
  refreshData,
  isExchangeEnabled,
  currentDynamicStrategyMultiplier,
  mode,
}) => {
  const { t } = useTranslation();
  const { colors } = useMUIThemeModeContext();
  const { counterCurrDisplayedScale } = useExchangesMetadata(exchangeEnum ?? "", currencyPair);
  const { counterCurrency } = splitCurrencyPair(currencyPair);
  const { confirm } = useConfirm();
  const [isLoadingRerunn, setIsLoadingRerunn] = useState<boolean>(false);
  const [query, setQuery] = useQueryParams({
    initRun: BooleanParam,
  });

  const isDynamicStrategy = !!strategy.dynamicStrategy;
  const isManualMode = mode === DcaBotMode.MANUAL;
  const { data, isValidating } = useAPIDcaBotsDynamicStrategyChart(id, isDynamicStrategy);

  useEffect(() => {
    if (query?.initRun === true) {
      (async () => {
        try {
          setIsLoadingRerunn(true);
          setQuery({ initRun: undefined });
          await runDcaBot(id);
          refreshData();
        } catch {
        } finally {
          setIsLoadingRerunn(false);
        }
      })();
    }
  }, [query, setQuery, refreshData, runDcaBot]);

  const onManualRunClick = async () => {
    try {
      setIsLoadingRerunn(true);
      await confirm({
        allowClose: false,
        title: t("dcaBots.manualRun.title"),
        description: <Trans i18nKey="dcaBots.manualRun.description" />,
        confirmationText: t("dcaBots.manualRun.confirm"),
      });
      await runDcaBot(id);
      refreshData();
    } catch {
    } finally {
      setIsLoadingRerunn(false);
    }
  };

  const getBottomSection = () => {
    if (strategy?.type === BotStrategyType.LIMIT) {
      return (
        <Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
          <Button
            sx={{ backgroundColor: colors.white, "&:disabled": { color: "text.disabled" } }}
            disabled={isLoadingRerunn || !enabled || !isExchangeEnabled}
            onClick={onManualRunClick}
            startIcon={<CustomCachedIcon disabled={isLoadingRerunn} />}
            variant="outlined"
            color="info"
            size="small">
            {t("dcaBots.manualRun.cta")}
          </Button>
        </Stack>
      );
    }
  };

  const getDynamicStrategyRange = (value: number) => {
    if (!strategy?.dynamicStrategy) return;

    const minMultiplier = strategy.dynamicStrategy.minMultiplier ?? 0;
    const maxMultiplier = strategy.dynamicStrategy.maxMultiplier ?? 0;

    return (
      <Box display={"flex"} flexDirection={"row"} columnGap={0.5} flexWrap={"wrap"}>
        <Typography variant="subtitle2" fontWeight={400}>
          {t("dcaBots.dynamicStrategy.range.1")}
        </Typography>
        <MaskedModeWrapper variant="subtitle2" color="inherit" fontWeight={600}>
          <Typography variant="subtitle2" fontWeight={600}>
            {`${renderNumber(value * minMultiplier, counterCurrDisplayedScale)}\u00a0${counterCurrency}`}
          </Typography>
        </MaskedModeWrapper>
        <Typography variant="subtitle2" fontWeight={400}>
          {t("dcaBots.dynamicStrategy.range.2")}
        </Typography>
        <MaskedModeWrapper variant="subtitle2" color="inherit" fontWeight={600}>
          <Typography variant="subtitle2" fontWeight={600}>
            {`${renderNumber(value * maxMultiplier, 2)}\u00a0${counterCurrency}`}
          </Typography>
        </MaskedModeWrapper>
      </Box>
    );
  };

  const getBotStrategy = () => {
    if (strategy?.type === BotStrategyType.MARKET) {
      return (
        <ItemBox sx={{ mt: 2 }}>
          <Stack width={"100%"} direction={"column"} spacing={1}>
            <Stack width={"100%"} direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="body2" fontWeight={400}>
                {t(`dcaBots.purchaseNow.market.label.${mode}`)}
              </Typography>
              {!isDynamicStrategy && (
                <Box display={"flex"} flexDirection={"row"} columnGap={0.5}>
                  <Typography variant="body2" fontWeight={600}>
                    {t("dcaBots.purchaseNow.market.for")}
                  </Typography>
                  <MaskedModeWrapper variant="body2" color="inherit" fontWeight={600}>
                    <Typography variant="body2" fontWeight={600}>
                      {`${renderNumber(
                        strategy.marketOrderValue ?? 0,
                        counterCurrDisplayedScale
                      )}\u00a0${counterCurrency}`}
                    </Typography>
                  </MaskedModeWrapper>
                </Box>
              )}
            </Stack>
            {isDynamicStrategy && (
              <Stack>
                {getDynamicStrategyRange(strategy.marketOrderValue ?? 0)}
                <Stack>
                  <Box display={"flex"} flexDirection={"row"} columnGap={0.5} flexWrap={"wrap"}>
                    <Typography variant="subtitle2" fontWeight={400}>
                      {t("dcaBots.dynamicStrategy.orderInputValue")}
                    </Typography>
                    <MaskedModeWrapper variant="subtitle2" color="inherit" fontWeight={600}>
                      <Typography variant="subtitle2" fontWeight={600}>
                        {`${renderNumber(
                          strategy.marketOrderValue ?? 0,
                          counterCurrDisplayedScale
                        )}\u00a0${counterCurrency}`}
                      </Typography>
                    </MaskedModeWrapper>
                  </Box>
                  <Box display={"flex"} flexDirection={"row"} columnGap={0.5} flexWrap={"wrap"}>
                    <Typography variant="subtitle2" fontWeight={400}>
                      <Trans
                        i18nKey={"dcaBots.dynamicStrategy.orderCurrentDynamicValue"}
                        values={{
                          multiplier: renderNumber(currentDynamicStrategyMultiplier ?? 1, 2),
                        }}
                      />
                    </Typography>
                    <MaskedModeWrapper variant="subtitle2" color="inherit" fontWeight={600}>
                      <Typography variant="subtitle2" fontWeight={600}>
                        {`${renderNumber(
                          (strategy.marketOrderValue ?? 1) * (currentDynamicStrategyMultiplier ?? 1),
                          counterCurrDisplayedScale
                        )}\u00a0${counterCurrency}`}
                      </Typography>
                    </MaskedModeWrapper>
                  </Box>
                </Stack>
              </Stack>
            )}
          </Stack>
        </ItemBox>
      );
    }

    return (
      <Stack mt={1} spacing={1}>
        {strategy?.limitOrders
          ? strategy.limitOrders.map((limitOrder, index: number) => (
              <ItemBox key={`${index}-order`} sx={{ mt: 2 }}>
                <Stack width={"100%"} direction={"column"} spacing={1}>
                  <Stack width={"100%"} direction="row" alignItems="center" justifyContent="space-between">
                    <Stack>
                      <Typography variant="body2" fontWeight={400}>
                        <Trans
                          i18nKey={`dcaBots.purchaseNow.limit.label.${mode}`}
                          values={{ percentChange: limitOrder.percentChange }}
                        />
                      </Typography>
                      {limitOrder.executeAsMarketWhenNotFilled && (
                        <Typography
                          fontStyle={"italic"}
                          fontSize={"0.75rem"}
                          variant="body2"
                          fontWeight={400}>
                          {t("dcaBots.purchaseNow.limit.executeAsMarketWhenNotFilled")}
                        </Typography>
                      )}
                    </Stack>
                    {!isDynamicStrategy && (
                      <Box display={"flex"} flexDirection={"row"} columnGap={0.5}>
                        <Typography variant="body2" fontWeight={600}>
                          {t("dcaBots.purchaseNow.limit.for")}
                        </Typography>
                        <MaskedModeWrapper variant="body2" color="inherit" fontWeight={600}>
                          <Typography variant="body2" fontWeight={600}>
                            {`${renderNumber(limitOrder.value ?? 0, counterCurrDisplayedScale)}\u00a0${counterCurrency}`}
                          </Typography>
                        </MaskedModeWrapper>
                      </Box>
                    )}
                  </Stack>
                  {isDynamicStrategy && (
                    <Stack>
                      {getDynamicStrategyRange(limitOrder.value ?? 0)}
                      <Stack>
                        <Box display={"flex"} flexDirection={"row"} columnGap={0.5} flexWrap={"wrap"}>
                          <Typography variant="subtitle2" fontWeight={400}>
                            {t("dcaBots.dynamicStrategy.orderInputValue")}
                          </Typography>
                          <MaskedModeWrapper variant="subtitle2" color="inherit" fontWeight={600}>
                            <Typography variant="subtitle2" fontWeight={600}>
                              {`${renderNumber(limitOrder.value ?? 0, counterCurrDisplayedScale)}\u00a0${counterCurrency}`}
                            </Typography>
                          </MaskedModeWrapper>
                        </Box>
                        <Box display={"flex"} flexDirection={"row"} columnGap={0.5} flexWrap={"wrap"}>
                          <Typography variant="subtitle2" fontWeight={400}>
                            <Trans
                              i18nKey={"dcaBots.dynamicStrategy.orderCurrentDynamicValue"}
                              values={{
                                multiplier: renderNumber(currentDynamicStrategyMultiplier ?? 1, 2),
                              }}
                            />
                          </Typography>
                          <MaskedModeWrapper variant="subtitle2" color="inherit" fontWeight={600}>
                            <Typography variant="subtitle2" fontWeight={600}>
                              {`${renderNumber(
                                (limitOrder.value ?? 1) * (currentDynamicStrategyMultiplier ?? 1),
                                counterCurrDisplayedScale
                              )}\u00a0${counterCurrency}`}
                            </Typography>
                          </MaskedModeWrapper>
                        </Box>
                      </Stack>
                    </Stack>
                  )}
                </Stack>
              </ItemBox>
            ))
          : null}
      </Stack>
    );
  };

  const getDynamicSection = () => {
    return (
      <DynamicChart
        minMultiplier={strategy.dynamicStrategy?.minMultiplier ?? 0}
        maxMultiplier={strategy.dynamicStrategy?.maxMultiplier ?? 0}
        top={strategy.dynamicStrategy?.top ?? 0}
        bottom={strategy.dynamicStrategy?.bottom ?? 0}
        center={strategy.dynamicStrategy?.center ?? 0}
        dynamicStrategyChart={data?.points ?? []}
        isCollaped={true}
        loading={isValidating && !data}
        labelFontWeight={600}
        lineLabelFormatter={(value, _levelLabel, limit) => `${value} | ${limit}%`}
        mode={mode}
        type={strategy.dynamicStrategy?.type ?? DcaDynamicStrategyType.EMPTY}
      />
    );
  };

  return (
    <Card
      sx={{ mb: 0 }}
      header={t("dcaBots.settings")}
      actions={
        <Stack alignItems="flex-end">
          <Stack direction="row" spacing={1}>
            <Typography color={colors.legacyGray700} variant="body2">
              {t("dcaBots.botCreated")}
            </Typography>
            <Typography color={colors.legacyGray700} variant="body2" fontWeight={600}>
              {renderDateTimeWithComma(createdAt)}
            </Typography>
          </Stack>
          {createdAt !== updatedAt && (
            <Stack direction="row" spacing={1}>
              <Typography color={colors.legacyGray700} variant="body2">
                {t("dcaBots.botLastUpdated")}
              </Typography>
              <Typography color={colors.legacyGray700} variant="body2" fontWeight={600}>
                {renderDateTimeWithComma(updatedAt)}
              </Typography>
            </Stack>
          )}
        </Stack>
      }
      bottomSection={getBottomSection()}>
      <Stack spacing={1}>
        <Stack direction="row" justifyContent="space-between">
          <Typography color={"secondary"} variant="body2" pr={1}>
            {t("dcaBots.mode.label")}
          </Typography>
          <Typography color={"secondary"} variant="body2" fontWeight={600}>
            {t(`dcaBots.mode.${mode}`)}
          </Typography>
        </Stack>
        {!isManualMode && (
          <>
            <Stack direction="row" justifyContent="space-between">
              <Typography color={"secondary"} variant="body2" pr={1}>
                {t("dcaBots.frequency.label")}
              </Typography>
              <Typography sx={{ textAlign: "end" }} color={"secondary"} variant="body2" fontWeight={600}>
                {translateBotFrequency(frequency, frequencyParameter)}
              </Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Typography color={"secondary"} variant="body2" pr={1}>
                {t("dcaBots.strategyLabel")}
              </Typography>
              <Typography color={"secondary"} variant="body2" fontWeight={600}>
                {strategy?.type}
              </Typography>
            </Stack>
          </>
        )}
        {strategy.dynamicStrategy && (
          <>
            <Stack direction="row" justifyContent="space-between" pt={2}>
              <Typography color={"secondary"} variant="body2" pr={1}>
                {t("dcaBots.dynamicStrategy.label")}
              </Typography>
              <Typography color={"secondary"} variant="body2" fontWeight={600} textAlign={"end"}>
                {t(`dcaBots.create.dynamicStrategyLabel.types.${strategy.dynamicStrategy.type}`)}
              </Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Typography color={"secondary"} variant="body2" pr={1}>
                {t("dcaBots.dynamicStrategy.minMaxMultiplierLabel")}
              </Typography>
              <Typography color={"secondary"} variant="body2" textAlign={"end"}>
                <Trans
                  i18nKey={"dcaBots.dynamicStrategy.minMaxMultiplierValue"}
                  values={{
                    min: renderNumber(strategy.dynamicStrategy.minMultiplier ?? 0, 1),
                    max: renderNumber(strategy.dynamicStrategy.maxMultiplier ?? 0, 1),
                  }}
                />
              </Typography>
            </Stack>
          </>
        )}
      </Stack>
      {!isManualMode && getBotStrategy()}
      {isDynamicStrategy && getDynamicSection()}
    </Card>
  );
};

export default Settings;
