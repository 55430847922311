import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Modal from "components/elements/Modal";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

interface IProps {
  open: boolean;
  onClose: () => void;
}

const ModalNotEnoughCredits = ({ open, onClose }: IProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      open={open}
      onCancel={onClose}
      withCloseButton
      title={t("subscriptionPage.modalNotEnoughCredits.title")}
      customConfirmButton={
        <Button to="/stosaky#nakoupit" component={Link} variant="contained" sx={{ ml: 1 }}>
          {t("subscriptionPage.modalNotEnoughCredits.buy")}
        </Button>
      }>
      <Alert severity="warning" sx={{ mb: 2 }}>
        {t("subscriptionPage.modalNotEnoughCredits.subtitle")}
      </Alert>
    </Modal>
  );
};

export default ModalNotEnoughCredits;
