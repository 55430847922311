import { Box, IconButton } from "@mui/material";
import { Theme, alpha, styled } from "@mui/material/styles";
import Slider from "components/elements/Slider";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { getColorByMode } from "utils";

export const ItemBox = styled(Box)(({ theme, color, compact }: { theme: Theme; color: string; compact?: boolean }) => ({
  backgroundColor: alpha(color, 0.1),
  borderRadius: "10px",
  padding: theme.spacing(1),
  width: compact ? "fit-content" : "100%",
  height: "100%",
  color: color,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const CustomIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.primary.main,
  
  "&.Mui-disabled": {
    color: theme.palette.common.white,
    backgroundColor: "#C4C4C4",
  },
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.5),
  }
}));

export const CustomSlider = styled(Slider)<{ increase?: number; basic?: number }>(({ theme, increase, basic }) => {
  const { colors } = useMUIThemeModeContext();

  return {
    color: theme.palette.primary.main,
    height: 8,
    "& .MuiSlider-rail": {
      backgroundColor: increase ? getColorByMode(theme, colors.gray200, colors.gray400) : undefined,
      opacity: increase ? 1 : undefined,
    },
    "& .MuiSlider-track": {
      border: "none",
      backgroundColor: increase ? undefined : getColorByMode(theme, colors.gray200, colors.gray400),
    },
    "& .MuiSlider-mark": {
      display: "none",
    },
    "& .MuiSlider-thumb": {
      height: 24,
      width: 24,
      backgroundColor: colors.white,
      border: "4px solid currentColor",
      "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
        boxShadow: "inherit",
      },
      "&:before": {
        display: "none",
      },
    },
    "& .MuiSlider-valueLabel": {
      backgroundColor: "#101828",
      borderRadius: "10px",
      padding: "8px 12px",
      fontWeight: 600,
      fontSize: "12px",
    },
    "& .MuiSlider-markLabel": {
      fontWeight: 500,
      fontWize: "12px",
      lineHeight: "16px0",
      letterSpacing: "0.005em",
      color: colors.black,
      marginLeft: basic ? "0px" : increase ? "-24px" : "20px",
    },
    '[data-index="0"]:not(.MuiSlider-thumb)': {
      marginLeft: basic ? "10px" : undefined,
    },
    '[data-index="1"]:not(.MuiSlider-thumb)': {
      marginLeft: basic ? "-10px" : undefined,
    },
  };
});
