import { Skeleton, Stack, SxProps, Theme, Typography, useMediaQuery, useTheme } from "@mui/material";
import MaskedModeWrapper from "components/elements/MaskedModeWrapper";
import UsdWrapper from "components/elements/UsdWrapper";
import SatsView, { ISatsViewProps } from "components/modules/SatsView";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";

interface ValueItemProps extends Omit<ISatsViewProps, "color" | "currency"> {
  label: string;
  sx?: SxProps<Theme>;
  valueSx?: SxProps<Theme>;
  isLoading?: boolean;
  skeletonHeight?: number;
  withoutMask?: boolean;
  withoutUsd?: boolean;
  valueFontSize?: string;
  counterCurrency: string;
  baseCurrency: string;
}

const ValueItem = ({
  label,
  sx,
  isLoading,
  skeletonHeight = 30,
  valueSx,
  value,
  withZeros = false,
  scale,
  counterCurrency,
  baseCurrency,
  fallbackValue,
  prefix,
  withoutSats,
  withoutMask,
  valueFontSize,
  withoutUsd,
}: ValueItemProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(341));
  const { colors } = useMUIThemeModeContext();
  const valFontSize = valueFontSize ? valueFontSize : isMobile ? "0.65rem" : "0.875rem";

  return (
    <Stack sx={{ ...sx }} justifyContent={"center"}>
      <Typography fontSize={"0.75rem"} color={colors.gray500}>
        {label}
      </Typography>
      {isLoading ? (
        <Skeleton variant="text" width={50} height={skeletonHeight} />
      ) : (
        <MaskedModeWrapper
          color={colors.gray950}
          fontSize={valFontSize}
          fontWeight={500}
          withoutMask={withoutMask}>
            <UsdWrapper value={value} counterCurrency={counterCurrency} baseCurrency={baseCurrency} disabled={withoutUsd}>
              <SatsView
                color={colors.gray950}
                fontSize={valFontSize}
                fontWeight={500}
                value={value}
                currency={counterCurrency}
                scale={scale}
                withZeros={withZeros}
                sx={{ ...valueSx }}
                fallbackValue={fallbackValue}
                prefix={prefix}
                withoutSats={withoutSats}
              />
          </UsdWrapper>
        </MaskedModeWrapper>
      )}
    </Stack>
  );
};

export default ValueItem;
