import { SvgIcon } from "@mui/material";

export const LikeShine = (props: any) => {
  return (
    <SvgIcon {...props}>
      <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#a)" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round">
          <path d="M19.5 6.75v-3M18 5.25h3M6.75 3.75v-3M5.25 2.25h3M2.25 9V6M.75 7.5h3M.75 12h2.14a8 8 0 0 0 8-7.86c0-1.59 3.21-1.8 3.21 1.22a28.174 28.174 0 0 1-1.09 5.89h8.2a2 2 0 0 1 1.94 2.49l-2 8a2 2 0 0 1-1.94 1.52h-7.75C7.18 23.25 9.32 21 .75 21" />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0h24v24H0z" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};
