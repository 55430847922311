import { subscriptionMinGridPercentStep } from "constants/subscriptions";

import React, { useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Stack, useMediaQuery, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import { SubscriptionType } from "types/enums";
import { renderNumber } from "utils/formatter";

import { CustomIconButton, CustomSlider } from "../styles";

interface IProps {
  id: string;
  value: number;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  min?: number;
  max?: number;
  step?: number;
  disabled?: boolean;
  subscriptionType?: SubscriptionType;
  defaultMinValue?: number;
}

const PercentStepInput = ({
  id,
  value,
  setFieldValue,
  min = 1,
  max = 5,
  step = 0.5,
  disabled,
  subscriptionType,
  defaultMinValue,
}: IProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const minValue = defaultMinValue ?? subscriptionMinGridPercentStep[subscriptionType as keyof typeof subscriptionMinGridPercentStep];
  const [uniqueId, setUniqueId] = useState<number>(1);

  const handleSliderChange = (_event: React.SyntheticEvent | Event | null, newValue: number | Array<number>) => {
    setFieldValue(id, newValue);
  };

  const disableSub = () => {
    if (!subscriptionType) return true;

    return value <= minValue;
  };

  const handleSub = () => {
    handleSliderChange(null, value - step);
    setUniqueId(uniqueId + 1);
  };
  const handleAdd = () => {
    handleSliderChange(null, value + step);
    setUniqueId(uniqueId + 1);
  };

  return (
    <Stack
      spacing={2}
      direction={"row"}
      justifyContent="center"
      alignItems={isMobile ? "center" : "flex-start"}
      pt={4.5}
      px={3}
      pb={1}>
      <CustomIconButton
        sx={{ marginBottom: isMobile ? 2.5 : 0 }}
        onClick={handleSub}
        size={isMobile ? "small" : "large"}
        disabled={disableSub() || disabled}>
        <RemoveIcon />
      </CustomIconButton>
      <Box pt={1} width={"100%"}>
        <CustomSlider
          key={`percentStepInputSlider-${uniqueId}`}
          increase={1}
          basic={1}
          onChangeCommitted={handleSliderChange}
          defaultValue={value}
          minValue={minValue}
          min={min}
          max={max}
          step={step}
          disabled={disabled}
          valueLabelDisplay={"on"}
          valueLabelFormat={(n: number) => `${renderNumber(n, 1)} %`}
          marks={[
            {
              value: min,
              label: `${renderNumber(min, 1)} %`,
            },
            {
              value: max,
              label: `${renderNumber(max, 1)} %`,
            },
          ]}
        />
      </Box>
      <CustomIconButton
        sx={{ marginBottom: isMobile ? "20px !important" : 0 }}
        onClick={handleAdd}
        size={isMobile ? "small" : "large"}
        disabled={value >= max || disabled}>
        <AddIcon />
      </CustomIconButton>
    </Stack>
  );
};

export default PercentStepInput;
