import { useState, useRef, useEffect } from "react";

import AddchartIcon from "@mui/icons-material/Addchart";
import CreateIcon from "@mui/icons-material/Create";
import { Stack, Grid, CircularProgress, Button, Alert, AlertTitle } from "@mui/material";
import BalanceItem from "components/elements/BalanceItem";
import Card from "components/elements/Card";
import { useAPIDcaBotsBalances } from "hooks/useAPI";
import useExchangesMetadata from "hooks/useExchangesMetadata";
import { Trans, useTranslation } from "react-i18next";
import { DcaBotMode, DcaBotRunState, ExchangeType, OrderTriggering, OrderType } from "types/enums";
import { IBalance } from "types/types";

import ManualOrderModal from "./ManualOrderModal";
import { CenterDiv } from "./styles";
import TradeModal from "./TradeModal";
import IProps from "./types";

const Balance = ({
  id,
  exchangeTitle,
  exchangeLabel,
  exchangeEnum,
  baseCurrency,
  counterCurrency,
  refreshData,
  isBrokerage,
  totalInvested,
  botRunsList,
  isExchangeEnabled,
  functional,
  bidPrice,
  mode,
}: IProps) => {
  const { t } = useTranslation();
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [isManualOrderModal, setIsManualOrderModal] = useState<boolean>(false);
  const { data, error, isValidating, mutate } = useAPIDcaBotsBalances(id, isExchangeEnabled && functional);
  const anycoinInterval = useRef<ReturnType<typeof setInterval> | null>(null);
  const { counterCurrDisplayedScale, baseCurrDisplayedScale } = useExchangesMetadata(
    exchangeEnum ?? "",
    `${baseCurrency}/${counterCurrency}`
  );

  useEffect(() => {
    if (botRunsList && anycoinInterval.current) {
      const lastOrder = botRunsList.dcaBotRuns[0]?.orders[0];
      if (lastOrder?.status === "FILLED" && lastOrder?.triggering === "INSTANT") {
        handleClose();
        mutateData();
      } else startAnycoinInterval();
    }
  }, [botRunsList]);

  const mutateData = () => {
    mutate();
    refreshData();
  };

  const handleInstantTrade = () => {
    mutateData();

    if (exchangeEnum === ExchangeType.ANYCOIN && !anycoinInterval.current) {
      startAnycoinInterval();
    }
  };

  const handleClose = () => {
    setIsOpenModal(false);
    clearAnycoinInterval();
  };

  const startAnycoinInterval = () => {
    clearAnycoinInterval();
    anycoinInterval.current = setTimeout(mutateData, 2500);
  };

  const clearAnycoinInterval = () => {
    if (anycoinInterval.current) {
      clearTimeout(anycoinInterval.current);
      anycoinInterval.current = null;
    }
  };

  const getCounterCurrencyAmount = (value: number) => {
    if (!bidPrice) return;

    const amount = value * bidPrice;
    return amount ? { value: amount, currency: counterCurrency, scale: counterCurrDisplayedScale } : undefined;
  };

  const balanceItem = (
    balance: IBalance,
    label: string,
    decimalPlaces: number,
    withZeros = false,
    isFirstChild = false
  ) => {
    const { available, frozen, currency } = balance;

    return (
      <>
        <BalanceItem
          label={label}
          value={available}
          decimalPlaces={decimalPlaces}
          withZeros={withZeros}
          currency={currency}
          isFirstChild={isFirstChild}
          amount={isFirstChild ? getCounterCurrencyAmount(available) : undefined}
        />
        {frozen !== 0 && (
          <BalanceItem
            label={t("dcaBots.balances.frozen")}
            value={frozen}
            decimalPlaces={decimalPlaces}
            withZeros={withZeros}
            currency={currency}
            tooltip={t("dcaBots.balances.frozenDesc")}
            amount={isFirstChild ? getCounterCurrencyAmount(frozen) : undefined}
          />
        )}
      </>
    );
  };

  const getSumsOfOpenInstantLimit = () => {
    let result = 0;

    botRunsList?.dcaBotRuns?.forEach((run) => {
      const { state, triggering, type } = run;

      if (state === DcaBotRunState.OPEN && triggering === OrderTriggering.INSTANT && type === OrderType.LIMIT) {
        result += 1;
      }
    });

    return result;
  };

  return (
    <Card
      sx={{ mb: 0 }}
      header={
        isBrokerage
          ? t("dcaBots.brokerage", { exchange: exchangeTitle ?? "" })
          : t("dcaBots.exchange", { exchange: exchangeTitle ?? "" })
      }
      subheader={exchangeLabel ?? ""}
      bottomSection={
        <Stack direction="column" justifyContent="center" alignItems="center" spacing={1}>
          <Button
            disabled={!data || !isExchangeEnabled || !functional}
            onClick={() => setIsOpenModal(true)}
            startIcon={<AddchartIcon />}
            variant="contained">
            {t("dcaBots.balances.buySellNow", { baseCurrency })}{"..."}
          </Button>
          <Button
            onClick={() => setIsManualOrderModal(true)}
            startIcon={<CreateIcon />}
            sx={{ backgroundColor: "tertiary.main" }}
            color="info"
            variant="contained">
            {t("dcaBots.balances.addManualTransaction")}{"..."}
          </Button>
          {totalInvested === 0 && (
            mode === DcaBotMode.MANUAL ? (
              <Alert severity="info">
                <Trans i18nKey="dcaBots.balances.manualModeInfoBox1" />
                <br/><br/>
                <Trans i18nKey="dcaBots.balances.manualModeInfoBox2" />
              </Alert>
            ) : (
              <Alert severity="info">
                {t("dcaBots.balances.infoBox1")}
                <br/><br/>
                {t("dcaBots.balances.infoBox2")}
              </Alert>
            )
          )}
        </Stack>
      }>
      <Grid container>
        <Grid item xs={12} sx={{ display: "flex", flexDirection: "column" }}>
          {data?.baseCurrencyBalance
            && balanceItem(
              data?.baseCurrencyBalance,
              isBrokerage
                ? t("dcaBots.balances.storedOnBrokerage", { exchange: exchangeTitle ?? "" })
                : t("dcaBots.balances.storedOnExchange", { exchange: exchangeTitle ?? "" }),
              baseCurrDisplayedScale,
              true,
              true
            )}
          {data?.counterCurrencyBalance
            && balanceItem(data?.counterCurrencyBalance, t("dcaBots.balances.available"), counterCurrDisplayedScale, true)}
          {isValidating && !data && (
            <CenterDiv>
              <CircularProgress color="primary" />
            </CenterDiv>
          )}
          {error
            && (error?.response?.status === 502 ? (
              <Alert severity="warning">
                <AlertTitle>
                  <Trans i18nKey="dcaBots.balances.warning.title" />
                </AlertTitle>
                <Trans i18nKey="dcaBots.balances.warning.description" />
              </Alert>
            ) : (
              <Alert severity="error">
                <AlertTitle>
                  <Trans
                    i18nKey={
                      isBrokerage ? "dcaBots.balances.errorBrokerageTitle" : "dcaBots.balances.errorExchangeTitle"
                    }
                  />
                </AlertTitle>
                <Trans i18nKey="dcaBots.balances.errorConnectionText" />
              </Alert>
            ))}
          {!functional && (
            <Alert severity="error">
              <AlertTitle>
                <Trans
                  i18nKey={
                    isBrokerage ? "exchangeConnections.functionalError.brokerage" : "exchangeConnections.functionalError.exchange"
                  }
                />
              </AlertTitle>
              <Trans i18nKey="exchangeConnections.functionalError.description" />
            </Alert>
          )}
        </Grid>
      </Grid>
      {isOpenModal && data && (
        <TradeModal
          isOpen={isOpenModal}
          id={id}
          handleClose={handleClose}
          handleInstantTrade={handleInstantTrade}
          baseCurrency={baseCurrency}
          counterCurrency={counterCurrency}
          exchangeEnum={exchangeEnum}
          balanceBase={data?.baseCurrencyBalance}
          balanceCounter={data?.counterCurrencyBalance}
          sumsOfOpenInstantLimit={getSumsOfOpenInstantLimit()}
        />
      )}
      {isManualOrderModal && exchangeEnum && (
        <ManualOrderModal
          id={id}
          handleClose={() => setIsManualOrderModal(false)}
          baseCurrency={baseCurrency}
          counterCurrency={counterCurrency}
          exchangeEnum={exchangeEnum}
          refreshData={mutateData}
        />
      )}
    </Card>
  );
};

export default Balance;
