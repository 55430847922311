import CloseIcon from "@mui/icons-material/Close";
import { Stack, IconButton, Typography, DialogContent, DialogTitle } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import UsdWrapper, { TypographyItem } from "components/elements/UsdWrapper";
import { LevelsContent } from "components/modules/GridBotDetail/Levels";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import useExchangesMetadata from "hooks/useExchangesMetadata";
import { useTranslation } from "react-i18next";
import { IPriceLevel, ITicker } from "types/types";
import { splitCurrencyPair } from "utils";

interface IProps {
  handleClose: () => void;
  data: IPriceLevel[];
  ticker: ITicker;
  exchangeEnum: string;
  currencyPair: string;
}

export default function LevelsModal({ handleClose, exchangeEnum, currencyPair, data, ticker }: IProps) {
  const { t } = useTranslation<string>();
  const { colors } = useMUIThemeModeContext();
  const { counterCurrDisplayedScale } = useExchangesMetadata(exchangeEnum ?? "", currencyPair);
  const { counterCurrency, baseCurrency } = splitCurrencyPair(currencyPair);

  return (
    <Dialog
      open
      fullWidth={true}
      maxWidth={"xs"}
      PaperProps={{ sx: { width: "100%", m: 1, overflow: "auto" } }}
      onClose={handleClose}>
      <DialogTitle sx={{ borderBottom: data?.length ? `1px solid ${colors.gray200}` : "none" }}>
        <Stack width={"100%"} direction={"column"}>
          <Stack width={"100%"} direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
            <Typography variant="h5" component="h1" fontWeight={600} sx={{ color: "tertiary.main" }}>
              {t("gridBot.create.about.modalTitle")}
            </Typography>
            <IconButton edge="end" color="inherit" onClick={handleClose} size="small">
              <CloseIcon />
            </IconButton>
          </Stack>
          <Stack direction="row" justifyContent="space-between" pt={2}>
            <Typography color={"secondary"} variant="body2" pr={1}>
              {t("dcaBots.askPrice")}
            </Typography>
            <UsdWrapper value={ticker.askPrice} counterCurrency={counterCurrency} baseCurrency={baseCurrency}>
              <TypographyItem
                sx={{ textAlign: "end" }}
                fontWeight={600}
                color={"secondary"}
                variant="body2"
                value={ticker.askPrice}
                scale={counterCurrDisplayedScale}
                currency={counterCurrency}
                fontFamily={"monospace"}
              />
            </UsdWrapper>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography color={"secondary"} variant="body2" pr={1}>
              {t("dcaBots.bidPrice")}
            </Typography>
            <UsdWrapper value={ticker.bidPrice} counterCurrency={counterCurrency} baseCurrency={baseCurrency}>
              <TypographyItem
                sx={{ textAlign: "end" }}
                fontWeight={600}
                color={"secondary"}
                variant="body2"
                value={ticker.bidPrice}
                scale={counterCurrDisplayedScale}
                currency={counterCurrency}
                fontFamily={"monospace"}
              />
            </UsdWrapper>
          </Stack>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ padding: 0 }}>
        <LevelsContent
          priceLevels={data}
          baseCurrency={baseCurrency}
          counterCurrency={counterCurrency}
          counterCurrDisplayedScale={counterCurrDisplayedScale}
        />
      </DialogContent>
    </Dialog>
  );
}
