import { Box, Button, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StepperBox = styled(Box)({
  backgroundColor: "rgba(224, 224, 224, 0.25)",
  borderRadius: "10px",
  padding: "38px 0px 28px 0px",
});

export const StepperButton = styled(Button)({
  width: "147px",
}) as typeof Button;

export const StepperContent = styled(Box)({
  padding: "36px 0px 12px 0px",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
});

export const StepperTitle = styled(Typography)({
  color: "#023047",
  fontWeight: 600,
});

export const StepperChildren = styled(Box)({
});
