import { VIP_USERS } from "constants/affiliate";

import { useEffect, useState } from "react";

import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
  Stack,
  TextField,
  Box,
  Grid,
  Button,
  Typography,
  useTheme,
  darken,
  useMediaQuery,
  Link,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import { previewGridBot, updateBotEnabled, updateGridBot } from "API/calls";
import Big from "big.js";
import BackdropLoading from "components/elements/BackdropLoading";
import CandleChart, { convertOHLCData } from "components/elements/CandleChart";
import CenterWrapper from "components/elements/CenterWrapper";
import PageTitle from "components/elements/PageTitle";
import ResponsiveLabel from "components/elements/ResponsiveLabel";
import Tile from "components/elements/Tile";
import { AccountingCoinsStack } from "components/icons";
import { useDefaultLayoutContext } from "context/DefaultLayoutContext";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { useUserDataContext } from "context/UserDataContext";
import { useFormik } from "formik";
import { useAPIExchangeConnectionTradingFees, useAPIGridBotDetail, useAPIOHLC } from "hooks/useAPI";
import useConfirm from "hooks/useConfirm";
import useExchangeRate from "hooks/useExchangeRate";
import useExchangesMetadata from "hooks/useExchangesMetadata";
import { LineWidth } from "lightweight-charts";
import { useSnackbar } from "notistack";
import { Trans, useTranslation } from "react-i18next";
import { useParams, Link as RouterLink, useNavigate } from "react-router-dom";
import { ExchangeType, OHLCInterval, Side, SubscriptionType } from "types/enums";
import { IPreviewGridBot, IPriceLevel } from "types/types";
import { getColorByMode, splitCurrencyPair } from "utils";
import * as yup from "yup";

import BalanceView from "../BalanceView";
import CoinmateTradingFeesAlert, { hasCoinmateTradingFeesAlert } from "../CoinmateTradingFeesAlert";
import LevelsModal from "../LevelsModal";
import OrderValueInput from "../OrderValueInput";
import PercentStepInput from "../PercentStepInput";
import PriceRangeInput from "../PriceRangeInput";
import UpdateModal from "./UpdateModal";

const MAX_GRID_LIMIT_PERCENTAGE_UP = 150;
const MAX_GRID_LIMIT_PERCENTAGE_DOWN = -50;

const initValues = {
  label: "",
  gridUpperPrice: 0,
  gridLowerPrice: 0,
  gridPercentStep: 0,
  gridOrderValue: 0,
};

const UpdateGridBot = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation<string>();
  const { confirm } = useConfirm();
  const theme = useTheme();
  const isLg = useMediaQuery(theme.breakpoints.up("lg"));
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpenBackdropLoading, setIsOpenBackdropLoading] = useState<boolean>(false);
  const [showLevelsModal, setShowLevelsModal] = useState<boolean>(false);
  const [previewGridBotData, setPreviewGridBotData] = useState<IPreviewGridBot>();
  const [isInit, setIsInit] = useState<boolean>(false);
  const [isOpenUpdateModal, setIsOpenUpdateModal] = useState<boolean>(false);

  const { refreshMenu } = useDefaultLayoutContext();
  const { colors } = useMUIThemeModeContext();
  const { subscriptionType, showInUSD, aff } = useUserDataContext();
  const { getUSDOriginalPrice } = useExchangeRate();

  const { data: gridBotDetailData, isValidating, error, mutate } = useAPIGridBotDetail(id, false);
  const isActive = gridBotDetailData?.enabled;

  const { baseCurrency } = splitCurrencyPair(gridBotDetailData?.currencyPair ?? "");
  const { currencyPairPriceScale, counterCurrDisplayedScale, gridStrategyLimits, baseCurrDisplayedScale }
    = useExchangesMetadata(
      gridBotDetailData?.exchangeConnection.exchangeEnum ?? "",
      gridBotDetailData?.currencyPair ?? ""
    );

  const validationGridPrice = (value?: string, context?: yup.TestContext<any>) => {
    if (value && context && OHLCData && lastClosePrice) {
      const val = Number(value.replace(",", "."));
      const closePrice = new Big(lastClosePrice).round(currencyPairPriceScale, Big.roundDown).toNumber();

      if (context.path === "gridLowerPrice") {
        if (val >= closePrice) {
          return context.createError({
            message: t("gridBot.create.maxLowerPriceFallback", { value: closePrice, currency: counterCurrency }),
          });
        }
      } else {
        if (val <= closePrice) {
          return context.createError({
            message: t("gridBot.create.minUpperPriceFallback", { value: closePrice, currency: counterCurrency }),
          });
        }
      }
    }

    return true;
  };

  const validationGridOrderValue = (value?: string, context?: yup.TestContext<any>) => {
    if (value && context) {
      const val = Number(value.replace(",", "."));
      const min = gridStrategyLimits?.min
        ? new Big(gridStrategyLimits?.min).round(counterCurrDisplayedScale, Big.roundDown).toNumber()
        : undefined;
      const max = gridStrategyLimits?.max
        ? new Big(gridStrategyLimits?.max).round(counterCurrDisplayedScale, Big.roundDown).toNumber()
        : undefined;

      if (max && val > max) {
        return context.createError({
          message: t("gridBot.create.maxGridOrderValueFallback", { value: max, currency: counterCurrency }),
        });
      }

      if (min && val < min) {
        return context.createError({
          message: t("gridBot.create.minGridOrderValueFallback", { value: min, currency: counterCurrency }),
        });
      }
    }

    return true;
  };

  const defaultSchema = {
    label: yup.string().max(50, t("fieldMaxLength", { max: 50 })),
    gridUpperPrice: yup
      .string()
      .required(t("fieldRequired"))
      .test("minGridUpperPrice", "gridUpperPriceError", validationGridPrice),
    gridLowerPrice: yup
      .string()
      .required(t("fieldRequired"))
      .test("maxGridLowerPrice", "gridLowerPriceError", validationGridPrice),
    gridPercentStep: yup.string().required(t("fieldRequired")),
    gridOrderValue: yup
      .string()
      .required(t("fieldRequired"))
      .test("gridOrderValue", "gridOrderValueError", validationGridOrderValue),
  };
  const formik = useFormik({
    initialValues: initValues,
    validationSchema: yup.object(defaultSchema),
    onSubmit: () => handleUpdate(),
  });

  const { counterCurrency } = splitCurrencyPair(gridBotDetailData?.currencyPair ?? "");
  const { data: OHLCData } = useAPIOHLC(
    gridBotDetailData?.currencyPair ?? "",
    OHLCInterval.ONE_DAY,
    gridBotDetailData?.exchangeConnection.exchangeEnum,
    5000
  );
  const lastClosePrice = OHLCData && OHLCData.ohlc[OHLCData.ohlc.length - 1].c;

  const isCoinmate = gridBotDetailData?.exchangeConnection.exchangeEnum === ExchangeType.COINMATE;
  const { data: tradingFees } = useAPIExchangeConnectionTradingFees(
    gridBotDetailData?.exchangeConnection.id ?? "",
    (gridBotDetailData?.currencyPair ?? "").replace("/", "-"),
    isCoinmate
  );

  useEffect(() => {
    if (!isValidating && gridBotDetailData === null) mutate();
  }, [isValidating]);

  useEffect(() => {
    if (gridBotDetailData && id) {
      formik.setFieldValue("label", gridBotDetailData.label);
      formik.setFieldValue("gridUpperPrice", gridBotDetailData.gridUpperPrice);
      formik.setFieldValue("gridLowerPrice", gridBotDetailData.gridLowerPrice);
      formik.setFieldValue("gridPercentStep", gridBotDetailData.gridPercentStep);
      formik.setFieldValue("gridOrderValue", gridBotDetailData.gridOrderValue);
      setIsInit(true);
    }
  }, [gridBotDetailData]);

  useEffect(() => {
    setIsLoading(isValidating);
  }, [isValidating]);

  useEffect(() => {
    if (error && id) navigate(`/dashboard`);
  }, [error]);

  useEffect(() => {
    if (
      formik.values.gridOrderValue
      && gridBotDetailData?.currencyPair
      && gridBotDetailData?.exchangeConnection.id
      && !isActive
      && isInit
    ) {
      (async () => {
        const min = gridStrategyLimits?.min
          ? new Big(gridStrategyLimits.min).round(counterCurrDisplayedScale, Big.roundDown).toNumber()
          : undefined;
        const max = gridStrategyLimits?.max
          ? new Big(gridStrategyLimits.max).round(counterCurrDisplayedScale, Big.roundDown).toNumber()
          : undefined;
        if ((min && formik.values.gridOrderValue < min) || (max && formik.values.gridOrderValue > max)) return;

        try {
          const response = await previewGridBot({
            ...formik.values,
            currencyPair: gridBotDetailData.currencyPair,
            exchangeConnectionId: gridBotDetailData.exchangeConnection.id,
          });
          setPreviewGridBotData(response.data);
        } catch (e: any) {}
      })();
    }
  }, [formik.values.gridOrderValue, formik.values.gridPercentStep, formik.values.gridUpperPrice, formik.values.gridLowerPrice, lastClosePrice, isActive, isInit]);

  if (!isInit) {
    return (
      <CenterWrapper>
        <CircularProgress color="primary" />
      </CenterWrapper>
    );
  }

  const handleUpdate = async () => {
    if (isActive) handleSaveBasic();
    else setIsOpenUpdateModal(true);
  };

  const handleSaveBasic = async () => {
    if (!id) return;

    setIsLoading(true);
    try {
      await updateGridBot(id, {
        gridUpperPrice: `${formik.values.gridUpperPrice}`.replace(",", "."),
        gridLowerPrice: `${formik.values.gridLowerPrice}`.replace(",", "."),
        label: formik.values.label,
      });
      enqueueSnackbar(t("gridBot.create.editSuccessMessage"), { variant: "success" });
      navigate(`/grid-strategie/${id}`);
    } catch (error) {
      enqueueSnackbar(t("gridBot.create.editErrorMessage"), { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSave = async (withEnable: boolean) => {
    if (!id) return;

    setIsLoading(true);
    try {
      await updateGridBot(id, {
        gridUpperPrice: `${formik.values.gridUpperPrice}`.replace(",", "."),
        gridLowerPrice: `${formik.values.gridLowerPrice}`.replace(",", "."),
        label: formik.values.label,
        gridPercentStep: formik.values.gridPercentStep,
        gridOrderValue: formik.values.gridOrderValue,
      });

      if (withEnable) {
        await updateBotEnabled(id, { enabled: true });
        refreshMenu?.();
      }

      enqueueSnackbar(t("gridBot.create.editSuccessMessage"), { variant: "success" });
      navigate(`/grid-strategie/${id}`);
    } catch (error) {
      enqueueSnackbar(t("gridBot.create.editErrorMessage"), { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDisable = async () => {
    if (!id) return;

    try {
      await confirm({
        title: t("gridBot.confirmDisable.title"),
        description: t("gridBot.confirmDisable.description"),
      });

      setIsOpenBackdropLoading(true);
      try {
        await updateBotEnabled(id, { enabled: false });
        enqueueSnackbar(t("gridBot.successMessageDisable"), { variant: "success" });
        refreshMenu?.();
        mutate();
        setIsInit(false);
      } catch (error: any) {
        enqueueSnackbar(t("gridBot.errorMessageDisable"), { variant: "error" });
      }
      setIsOpenBackdropLoading(false);
    } catch (err: any) {
      // silent
    }
  };

  const priceTransform = (price: number) => {
    const inUSD = getUSDOriginalPrice(counterCurrency, price);
    return inUSD ?? price;
  };

  const getPriceRange = () => {
    const minValue = showInUSD ? priceTransform(formik.values.gridLowerPrice) : formik.values.gridLowerPrice;
    const maxValue = showInUSD ? priceTransform(formik.values.gridUpperPrice) : formik.values.gridUpperPrice;

    return { minValue, maxValue };
  };

  const chartSection = (priceLevels?: IPriceLevel[], height?: number) => {
    if (!OHLCData) return;

    const result = [];

    if (formik.values.gridUpperPrice) {
      const price = showInUSD ? priceTransform(formik.values.gridUpperPrice) : formik.values.gridUpperPrice;

      result.push({
        price,
        color: getColorByMode(theme, colors.black, "#808080"),
        lineWidth: 3 as LineWidth,
        lineStyle: 0,
        axisLabelVisible: true,
        lineVisible: true,
        title: "",
      });
    }
    if (formik.values.gridLowerPrice) {
      const price = showInUSD ? priceTransform(formik.values.gridLowerPrice) : formik.values.gridLowerPrice;

      result.push({
        price,
        color: getColorByMode(theme, colors.black, "#808080"),
        lineWidth: 3 as LineWidth,
        lineStyle: 0,
        axisLabelVisible: true,
        lineVisible: true,
        title: "",
      });
    }

    if (priceLevels) {
      priceLevels.forEach((level) => {
        if (level.orderSide) {
          const hasEnough
            = !!level.orderSide && (level.priceLevelStatus === "OPEN" || level.priceLevelStatus === "ENOUGH_BALANCE");
          const price = showInUSD ? priceTransform(level.value) : level.value;

          result.push({
            price,
            color: hasEnough ? (level.orderSide === "BUY" ? colors.success : colors.error) : "gray",
            lineWidth: 1,
            lineStyle: hasEnough ? 0 : 3,
            axisLabelVisible: false,
            lineVisible: true,
            title: "",
          });
        }
      });
    }

    return (
      <Tile sx={{ py: 2, px: 1, height: height ? `${height}px` : "100%" }}>
        <CandleChart
          data={convertOHLCData(OHLCData, showInUSD ? priceTransform : undefined)}
          priceLines={result}
          pricePrecision={currencyPairPriceScale}
          priceRange={getPriceRange()}
          dateWithTime={false}
        />
      </Tile>
    );
  };

  const handleBack = () => navigate(`/grid-strategie/${id}`);

  const gridSettingSection = () => (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
        <Stack direction={"column"} spacing={3}>
          {!isLg && !isActive && <Box>{chartSection(previewGridBotData?.priceLevels, 300)}</Box>}
          <Tile
            sx={{ height: "100%" }}
            header={t("gridBot.create.gridSetting.orderDistance.title")}
            footer={
              subscriptionType !== SubscriptionType.PREMIUM && (
                <Stack direction={"row"} spacing={1}>
                  <AccountingCoinsStack sx={{ fontSize: 16 }} />
                  <Typography variant="caption">
                    <Trans
                      i18nKey={`gridBot.create.gridSetting.orderDistance.alert.${subscriptionType}`}
                      components={{
                        a: (
                          <Link
                            to={"/predplatne"}
                            target={"_blank"}
                            color={"inherit"}
                            fontWeight={600}
                            component={RouterLink}
                          />
                        ),
                      }}
                    />
                  </Typography>
                </Stack>
              )
            }
            actions={
              <Tooltip arrow placement="top" title={t("gridBot.create.gridSetting.orderDistance.tooltip") ?? ""}>
                <HelpOutlineIcon sx={{ color: colors.slate500 }} />
              </Tooltip>
            }>
            <Typography color={"#525252"} px={3} pt={2} variant="body2">
              {t("gridBot.create.gridSetting.orderDistance.description")}
            </Typography>
            <PercentStepInput
              id="gridPercentStep"
              value={formik.values.gridPercentStep}
              setFieldValue={formik.setFieldValue}
              disabled={isLoading || isActive}
              subscriptionType={subscriptionType}
              min={aff && VIP_USERS.includes(aff) ? 0.5 : undefined}
              defaultMinValue={aff && VIP_USERS.includes(aff) ? 0.5 : undefined}
            />
          </Tile>
          {!isLg && (
            <CoinmateTradingFeesAlert
              makerFee={tradingFees?.makerFee}
              isCoinmate={isCoinmate}
              gridPercentStep={formik.values.gridPercentStep}
            />
          )}
          <Tile
            sx={{ height: "100%" }}
            header={t("gridBot.create.gridSetting.orderValue.title")}
            actions={
              <Tooltip arrow placement="top" title={t("gridBot.create.gridSetting.orderValue.tooltip") ?? ""}>
                <HelpOutlineIcon sx={{ color: colors.slate500 }} />
              </Tooltip>
            }>
            <Typography color={"#525252"} px={3} pt={2} variant="body2">
              {t("gridBot.create.gridSetting.orderValue.description")}
            </Typography>
            <OrderValueInput
              precision={counterCurrDisplayedScale}
              formik={formik}
              disabled={isLoading || isActive}
              counterCurrency={counterCurrency}
              gridStrategyLimits={gridStrategyLimits}
            />
          </Tile>
        </Stack>
      </Grid>
      {!isActive && (
        <Grid item xs={12} sm={12} md={12} lg={7} xl={7} height={"auto"}>
          <Box height={"100%"} display={{ xs: "none", md: "none", lg: "block" }}>
            {chartSection(previewGridBotData?.priceLevels)}
          </Box>
        </Grid>
      )}
      {isLg && (
        <Grid item xs={12}>
          <CoinmateTradingFeesAlert
            makerFee={tradingFees?.makerFee}
            isCoinmate={isCoinmate}
            gridPercentStep={formik.values.gridPercentStep}
          />
        </Grid>
      )}
      {!isActive && (
        <Grid item xs={12}>
          {previewSection()}
        </Grid>
      )}
    </Grid>
  );

  const previewSection = () => {
    if (!previewGridBotData) return;

    const {
      availableBaseCurrencyBalance,
      availableCounterCurrencyBalance,
      missingBaseCurrencyBalance,
      missingCounterCurrencyBalance,
      priceLevels,
      totalRequiredBaseCurrencyBalance,
      totalRequiredCounterCurrencyBalance,
      ticker,
    } = previewGridBotData;
    const exchangeTitle = gridBotDetailData?.exchangeConnection.exchangeTitle ?? "";

    return (
      <Stack spacing={3}>
        <Stack spacing={2} direction={{ xs: "column", lg: "row" }}>
          <BalanceView
            title={t("gridBot.create.gridSetting.buyOrders")}
            subtitle={t(`gridBot.create.gridSetting.buyTotal`, { title: exchangeTitle })}
            scale={counterCurrDisplayedScale}
            currency={counterCurrency}
            totalRequiredBalance={totalRequiredCounterCurrencyBalance}
            availableBalance={availableCounterCurrencyBalance}
            missingBalance={missingCounterCurrencyBalance}
            exchangeTitle={exchangeTitle}
            priceLevels={priceLevels.filter((level) => level.orderSide === Side.BUY)}
          />
          <BalanceView
            title={t("gridBot.create.gridSetting.sellOrders")}
            subtitle={t(`gridBot.create.gridSetting.sellTotal`, { title: exchangeTitle })}
            scale={baseCurrDisplayedScale}
            currency={baseCurrency}
            totalRequiredBalance={totalRequiredBaseCurrencyBalance}
            availableBalance={availableBaseCurrencyBalance}
            missingBalance={missingBaseCurrencyBalance}
            exchangeTitle={exchangeTitle}
            priceLevels={priceLevels.filter((level) => level.orderSide === Side.SELL)}
          />
        </Stack>
        <Tile p={3}>
          <Typography variant="subtitle2" fontWeight={600}>
            {t("gridBot.update.about.title")}
          </Typography>
          <Typography py={2} variant="subtitle2" fontWeight={400}>
            <Trans i18nKey="gridBot.create.about.description" />
          </Typography>
          <Button sx={{ p: 0 }} onClick={() => setShowLevelsModal(true)}>
            {t("gridBot.create.about.button")}
          </Button>
          {showLevelsModal && (
            <LevelsModal
              data={priceLevels}
              ticker={ticker}
              exchangeEnum={gridBotDetailData?.exchangeConnection.exchangeEnum ?? ""}
              currencyPair={gridBotDetailData?.currencyPair ?? ""}
              handleClose={() => setShowLevelsModal(false)}
            />
          )}
        </Tile>
      </Stack>
    );
  };

  return (
    <>
      <Tile isMain={true} header={t("gridBot.create.header.update")} m={{ xs: -3, md: 0 }}>
        <PageTitle i18nKey="gridBot.create.meta_title.update" />
        <Box p={3} bgcolor={getColorByMode(theme, colors.gray50, darken(colors.gray50, 0.4))}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Tile sx={{ p: 3 }}>
                <Typography variant="subtitle2" fontWeight={600} sx={{ pb: 1.5 }}>
                  {t("gridBot.update.tileTextTitle")}
                </Typography>
                <Typography variant="subtitle2" fontWeight={400} sx={{ pb: 0.5 }}>
                  <Trans i18nKey="gridBot.update.tileText1" />
                </Typography>
                <Typography variant="subtitle2" fontWeight={400} sx={{ pb: 4 }}>
                  <Trans i18nKey="gridBot.update.tileText2" />
                </Typography>
                <TextField
                  fullWidth
                  autoComplete="off"
                  id="label"
                  name="label"
                  label={ResponsiveLabel(t("gridBot.create.label"))}
                  disabled={isLoading}
                  value={formik.values.label}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.label)}
                  helperText={formik.errors.label}
                />
              </Tile>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
              <Stack direction={"column"} spacing={{ lg: 0, xs: 2 }} sx={{ height: "100%" }}>
                <PriceRangeInput
                  closePrice={lastClosePrice}
                  lowerPrice={formik.values.gridLowerPrice}
                  upperPrice={formik.values.gridUpperPrice}
                  currency={counterCurrency}
                  scale={currencyPairPriceScale}
                  isLoading={isLoading}
                  maxPercentageDown={MAX_GRID_LIMIT_PERCENTAGE_DOWN}
                  maxPercentageUp={MAX_GRID_LIMIT_PERCENTAGE_UP}
                  priceRangeTooltip={"gridBot.update.priceRangeTooltip"}
                  setFieldValue={formik.setFieldValue}
                  setFieldTouched={formik.setFieldTouched}
                />
                <Box display={{ lg: "none" }}>{chartSection(undefined, 300)}</Box>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={7} xl={7} sx={{ height: "auto" }}>
              <Box sx={{ height: "100%" }} display={{ xs: "none", md: "none", lg: "block" }}>
                {chartSection()}
              </Box>
            </Grid>
            {isActive && (
              <Grid item xs={12}>
                <Tile sx={{ p: 3 }}>
                  <Typography variant="subtitle2" fontWeight={600}>
                    {t("gridBot.update.activeAlert.title")}
                  </Typography>
                  <Typography variant="subtitle2" fontWeight={400} sx={{ mt: 1 }}>
                    <Trans i18nKey="gridBot.update.activeAlert.description" />
                  </Typography>
                  <Button variant="contained" color="error" onClick={handleDisable} sx={{ mt: 2 }}>
                    {t("gridBot.update.activeAlert.disable")}
                  </Button>
                </Tile>
              </Grid>
            )}
            <Grid item xs={12}>
              {gridSettingSection()}
            </Grid>
            <Grid item xs={12}>
              <Button onClick={handleBack} variant="outlined" sx={{ mt: 1, mr: 1, backgroundColor: colors.white }}>
                {t("back")}
              </Button>
              <Button
                variant="contained"
                disabled={isLoading || hasCoinmateTradingFeesAlert(formik.values.gridPercentStep, isCoinmate, tradingFees?.makerFee)}
                onClick={() => formik.handleSubmit()}
                sx={{ mt: 1, mr: 1 }}>
                {t("gridBot.create.update")}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Tile>
      {isOpenUpdateModal && (
        <UpdateModal
          onCancel={() => setIsOpenUpdateModal(false)}
          onSave={() => handleSave(false)}
          onSaveAndEnable={() => handleSave(true)}
        />
      )}
      <BackdropLoading open={isOpenBackdropLoading} />
    </>
  );
};

export default UpdateGridBot;
