import { Alert, AlertTitle, MenuItem , SelectChangeEvent } from "@mui/material";
import Select from "components/elements/Select";
import { useTranslation } from "react-i18next";

interface IProps {
  value: string;
  error?: string;
  onChange: (event: SelectChangeEvent<unknown>, child: React.ReactNode) => void;
  disabled?: boolean;
}

const LanguageSelect = ({value, error, onChange, disabled}: IProps) => {
  const { t } = useTranslation<string>();

  return (
    <>
      <Select
        fullWidth={true}
        label={t("registrationForm.language")}
        value={value}
        error={error}
        onChange={onChange}
        disabled={disabled}>
        <MenuItem value={"CS"}>Čeština</MenuItem>
        <MenuItem value={"SK"}>Slovenčina</MenuItem>
        <MenuItem value={"EN"}>English</MenuItem>
      </Select>
      {value === "SK" && (
        <Alert severity="info" sx={{ margin: "8px 16px" }}>
          <AlertTitle>{"Slovenská lokalizácia sa\u00a0pripravuje"}</AlertTitle>
          {"Po dokončení lokalizácie sa ti aplikácia automaticky prepne do\u00a0slovenčiny."}
        </Alert>
      )}
      {value === "EN" && (
        <Alert severity="info" sx={{ margin: "8px 16px" }}>
          <AlertTitle>{"English localization is in\u00a0preparation"}</AlertTitle>
          {"App will switch to English once it's\u00a0ready."}
        </Alert>
      )}
    </>
  );
};

export default LanguageSelect;
