import React from "react";

import PageTitle from "components/elements/PageTitle";

import Transactions from "../../modules/Credits/Transactions";

const CreditsTransactionsPage: React.FC = () => {
  return (
    <>
      <PageTitle i18nKey="creditsPage.meta_title_transactions" />
      <Transactions />
    </>
  );
};

export default CreditsTransactionsPage;
