import { useState } from "react";

import { CircularProgress, Grid } from "@mui/material";
import CenterWrapper from "components/elements/CenterWrapper";
import { useTranslation } from "react-i18next";
import { ExchangeType } from "types/enums";
import { isEmptyArray } from "utils";

import CreateModal from "./CreateModal";
import ListItem from "./ListItem";
import ListWrapper from "./ListWrapper";
import Banner from "./ListWrapper/Banner";
import { IProps } from "./types";

const ExchangeConnectionsList = ({ data, refreshData, refreshBotsData }: IProps) => {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [defaultExchange, setDefaultExchange] = useState<ExchangeType | undefined>(undefined);
  const [isRegisteringAnycoin, setIsRegisteringAnycoin] = useState<boolean>(false);
  const { t } = useTranslation();

  const hasPromoConnection = () => {
    return !data?.exchangeConnections.filter(
      (item) => item.exchangeEnum === "COINMATE" || item.exchangeEnum === "ANYCOIN"
    ).length;
  };

  if (!data) {
    return (
      <CenterWrapper>
        <CircularProgress color="primary" />
      </CenterWrapper>
    );
  }

  const renderList = () => {
    if (isEmptyArray(data.exchangeConnections)) return;

    const handleRefreshOnUpdate = () => {
      refreshData();
      refreshBotsData();
    };

    return (
      <Grid container spacing={2}>
        {Array.isArray(data.exchangeConnections)
          && data.exchangeConnections.length > 0
          && data.exchangeConnections?.map((exchangeConnectionsDetail) => (
            <ListItem
              key={`ExchangeConnectionsListItem-${exchangeConnectionsDetail.id}`}
              id={exchangeConnectionsDetail.id}
              exchangeEnum={exchangeConnectionsDetail.exchangeEnum}
              functional={exchangeConnectionsDetail.functional}
              label={exchangeConnectionsDetail.label}
              refreshData={handleRefreshOnUpdate}
            />
          ))}
        {!!hasPromoConnection() && <Banner />}
      </Grid>
    );
  };

  return (
    <>
      <ListWrapper
        header={t("exchangeConnections.listHeader")}
        onOpenExchangeModal={(exchangeType?: ExchangeType) => {
          setDefaultExchange(exchangeType);
          setIsRegisteringAnycoin(false);
          setIsOpenModal(true);
        }}
        onRegisteringAnycoin={() => {
          setIsRegisteringAnycoin(true);
          setIsOpenModal(true);
        }}>
        {renderList()}
      </ListWrapper>
      {isOpenModal && (
        <CreateModal
          isOpen={isOpenModal}
          defaultExchange={defaultExchange}
          isRegisteringAnycoin={isRegisteringAnycoin}
          handleClose={() => {
            setIsOpenModal(false);
            setDefaultExchange(undefined);
          }}
          handleSetIsRegisteringAnycoin={setIsRegisteringAnycoin}
          hasExchangeConnections={!isEmptyArray(data.exchangeConnections)}
        />
      )}
    </>
  );
};

export default ExchangeConnectionsList;
