import { useContext, createContext } from "react";

export type IDefaultLayoutContext = {
  refreshMenu?: () => void;
  isDrawerOpen: boolean;
};

const defaultState: IDefaultLayoutContext = {
  refreshMenu: undefined,
  isDrawerOpen: false,
};

export const DefaultLayoutContext = createContext<IDefaultLayoutContext>(defaultState);
export const useDefaultLayoutContext = () => useContext(DefaultLayoutContext);
