import imageBackgroundHomepage from "assets/images/background-homepage.jpg";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import { whyBitcoinRoute } from "utils";

import coursesConfig from "../../WhyBitcoin/config/courses";

const useCourseDetail = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const course = coursesConfig.courses.find((course) => course.chapters.some((chapter) => whyBitcoinRoute(chapter.url) === pathname));
  const chapter = course?.chapters.find((chap) => whyBitcoinRoute(chap.url) === pathname);
  const globalStyle = {
    ".main-content": {
      background: `url(${imageBackgroundHomepage})`,
      backgroundSize: "contain",
    },
  };

  const isFirstChapter = () => {
    if (!course) return false;

    const { chapters } = course;
    return whyBitcoinRoute(chapters[0].url) === pathname;
  };

  const isLastChapter = () => {
    if (!course) return false;

    const { chapters } = course;
    return whyBitcoinRoute(chapters[chapters.length - 1].url) === pathname;
  };

  const existNextCourse = () => {
    if (!course) return false;

    const numberOfCourses = coursesConfig.courses.length;
    const currentCourseIndex = coursesConfig.courses.findIndex((el) => el.chapters.some((chapter) => whyBitcoinRoute(chapter.url) === pathname));

    return numberOfCourses > currentCourseIndex + 1;
  };

  const handleNextChapter = () => {
    if (isLastChapter() || !course) return;

    const currentChapterIndex = course.chapters.findIndex((el) => whyBitcoinRoute(el.url) === pathname);
    if (currentChapterIndex > -1) navigate(whyBitcoinRoute(course.chapters[currentChapterIndex + 1].url));

    return;
  };

  const handlePreviousChapter = () => {
    if (isFirstChapter() || !course) return;

    const currentChapterIndex = course.chapters.findIndex((el) => whyBitcoinRoute(el.url) === pathname);
    if (currentChapterIndex > -1) navigate(whyBitcoinRoute(course.chapters[currentChapterIndex - 1].url));

    return;
  };

  const handleNextCourse = () => {
    if (!existNextCourse() || !course) return;

    const currentCourseIndex = coursesConfig.courses.findIndex((el) => el.chapters.some((chapter) => whyBitcoinRoute(chapter.url) === pathname));
    if (currentCourseIndex > -1) navigate(whyBitcoinRoute(coursesConfig.courses[currentCourseIndex + 1]?.chapters[0]?.url));

    return;
  };

  return {
    pathname,
    course,
    chapter,
    isFirstChapter: isFirstChapter(),
    isLastChapter: isLastChapter(),
    existNextCourse: existNextCourse(),
    handlePreviousChapter,
    handleNextChapter,
    handleNextCourse,
    globalStyle
  };
};

export default useCourseDetail;
