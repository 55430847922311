const RecurringPayments = () => {
  return (
    <>
      <p>Tato dohoda o opakovaných platbách (Recurring Transaction Agreement, dále jen "Dohoda") je uzavřena mezi Poskytovatelem a Klientem a týká se opakovaných plateb za volitelné předplatné Aplikace.</p>
      <ol>
        <li>
          <p>Opakované platby</p>
        </li>
        <ol type="a">
          <li>
            <p>
              Klient souhlasí, že umožní Poskytovateli provádět opakované platby na základě této Dohody. Opakované platby budou prováděny v pravidelných intervalech dle dohodnuté četnosti (např. měsíčně), a to na základě odsouhlasené částky.
            </p>
          </li>
          <li>
            <p>
              Klient bere na vědomí, že Poskytovatel bude strhávat odsouhlasenou částku z jeho zvoleného platebního způsobu (např. platební karta) v souladu s tímto ujednáním.
            </p>
          </li>
          <li>
            <p>
              Pokud k opakované platbě nedojde kvůli chybě (např. nedostatečný zůstatek na kartě nebo problém s připojením k internetu), může se Poskytovatel pokoušet platbu provést během následujících 7 dní.
            </p>
          </li>
        </ol>
        <li>
          <p>Změny a zrušení</p>
        </li>
        <ol type="a">
          <li>
            <p>
              Klient má právo kdykoliv změnit nebo zrušit tuto Dohodu skrz Aplikaci nebo odkaz zasílaný ve všech e-mailech týkající se této Dohody.
            </p>
          </li>
          <li>
            <p>
              Dohoda může být kdykoliv zrušena i Poskytovatelem.
            </p>
          </li>
        </ol>
        <li>
          <p>Zodpovědnost za údaje</p>
        </li>
        <ol type="a">
          <li>
            <p>
              Klient nese odpovědnost za správnost a aktuálnost svých platebních údajů. Změna platných platebních údajů by měla být okamžitě oznámena Poskytovateli, respektive Klient má povinnost své údaje upravit v Aplikaci v menu Fakturační údaje.
            </p>
          </li>
        </ol>
        <li>
          <p>Platnost dohody</p>
        </li>
        <ol type="a">
          <li>
            <p>
              Tato Dohoda vstupuje v platnost okamžikem, kdy Klient zaškrtnutím odsouhlasí aktivaci opakované platby na fixní částku a frekvenci.
            </p>
          </li>
          <li>
            <p>
              Tato Dohoda zůstává v platnosti, dokud Klient nezruší opakované platby v souladu s ustanovením č. 2. nebo nesmaže celý svůj účet v Aplikaci.
            </p>
          </li>
        </ol>
        <li>
          <p>Způsob komunikace</p>
        </li>
        <ol type="a">
          <li>
            <p>
              Klient bude automaticky informován e-mailem od Poskytovatele neprodleně po vytvoření opakované platby a v případě jakékoliv změny.
            </p>
          </li>
        </ol>
        <li>
          <p>Bezpečnost</p>
        </li>
        <ol type="a">
          <li>
            <p>
              Poskytovatel provádí opakované platby s nejvyšší mírou bezpečnosti a s použitím šifrování.
            </p>
          </li>
        </ol>
        <li>
          <p>Závěrečná ustanovení</p>
        </li>
        <ol type="a">
          <li>
            <p>
              Tato Dohoda je závazná pro obě strany.
            </p>
          </li>
          <li>
            <p>
              Tato Dohoda podléhá zákonům a pravomocem České republiky, a jakékoli spory vyplývající z této Dohody budou řešeny v souladu s těmito zákony.
            </p>
          </li>
        </ol>
      </ol>
    </>
  );
};

export default RecurringPayments;
