export const setWithExpiry = (key: string, value: string) => {
  const now = new Date();
  const item = {
    value: value,
    create: now.getTime(),
  };

  localStorage.setItem(key, JSON.stringify(item));
};

export const getWithExpiry = (key: string, expiry: number) => {
  const itemStr = localStorage.getItem(key);
  // if the item doesn't exist, return null
  if (!itemStr) return null;

  const item = JSON.parse(itemStr);
  const now = new Date();

  // compare the create + expiry time of the item with the current time
  if (now.getTime() > item.create + expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    localStorage.removeItem(key);
    return null;
  }

  if (item.create === undefined) {
    localStorage.setItem(
      key,
      JSON.stringify({
        value: item.value,
        create: now.getTime(),
      })
    );
  }

  return item.value;
};
