import React from "react";

import { Typography } from "@mui/material";
import CurrencyView, { CurrencyViewProps } from "components/elements/CurrencyView";
import { renderNumber } from "utils/formatter";

export interface ISatsViewProps extends Omit<CurrencyViewProps, "value"> {
  fallbackValue?: number | string;
  value?: number | null;
  withoutSats?: boolean;
}

const SatsView: React.FC<ISatsViewProps> = ({
  fallbackValue,
  prefix,
  value,
  currency,
  scale = 2,
  withZeros = false,
  withoutSats,
  ...props
}) => {
  if (!value && fallbackValue) return <Typography {...props}>{fallbackValue}</Typography>;

  if (currency !== "BTC" || withoutSats) {
    return (
      <Typography {...props}>{`${prefix ?? ""}${renderNumber(
        value ?? 0,
        scale,
        withZeros
      )}\u00a0${currency}`}</Typography>
    );
  }

  return (
    <CurrencyView
      {...props}
      prefix={prefix}
      value={value ?? 0}
      currency={currency}
      scale={scale}
      withZeros={withZeros}
    />
  );
};

export default SatsView;
