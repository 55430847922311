import { SvgIcon } from "@mui/material";

export const Sell = (props: any) => {
  return (
    <SvgIcon {...props}>
      <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1.94957 11.658C1.20713 8.8779 1.92645 5.7886 4.10753 3.60752C7.36189 0.353156 12.6383 0.353156 15.8926 3.60753C19.147 6.86189 19.147 12.1383 15.8926 15.3926C13.7116 17.5737 10.6223 18.293 7.84212 17.5506M12.5002 12.0002V7.00017M12.5002 7.00017H7.5002M12.5002 7.00017L4.16671 15.3335"
          stroke="#D92D20"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};
