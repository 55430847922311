import validations from "constants/validations";

import { useState, useEffect } from "react";

import { Typography, Button, Stack, Box } from "@mui/material";
import { completeRegistrationWithEmailOnly } from "API/calls";
import PageTitle from "components/elements/PageTitle";
import Panel from "components/elements/Panel";
import PasswordField from "components/elements/PasswordField";
import LanguageSelect from "components/modules/LanguageSelect";
import { useFormik } from "formik";
import useKeyPress from "hooks/useKeyPress";
import { useIsSmallScreen } from "hooks/useWindowSize";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ICompleteRegistration } from "types/types";
import { StringParam, useQueryParams } from "use-query-params";
import * as yup from "yup";

const CompleteRegistration = () => {
  const navigate = useNavigate();
  const [query, setQuery] = useQueryParams({ token: StringParam });
  const { t } = useTranslation<string>();
  const isSmallScreen = useIsSmallScreen();
  const keyPressed = useKeyPress("Enter");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [token] = useState<string>(query.token || "");
  const { enqueueSnackbar } = useSnackbar();

  const formik = useFormik({
    initialValues: {
      password: "",
      language: "CS",
    },
    validationSchema: yup.object().shape({
      password: yup.string().required(t("fieldRequired")).matches(validations.password, t("fieldErrors.password")),
      language: yup.string().required(t("fieldRequired")),
    }),
    onSubmit: (value) => handleCompletion({ ...value, token }),
  });

  useEffect(() => {
    // to remove token query parameter from URL before it gets collected to Google Analytics
    setQuery({ token: undefined });
  }, []);

  useEffect(() => {
    if (!token) navigate("/");
  }, [token]);

  useEffect(() => {
    if (keyPressed) formik.handleSubmit();
  }, [keyPressed]);

  const handleCompletion = async (payload: ICompleteRegistration) => {
    setIsLoading(true);
    try {
      await completeRegistrationWithEmailOnly(payload);
      enqueueSnackbar(t("completeRegistration.successMessage"), { variant: "success" });
      navigate("/dashboard");
    } catch (error: any) {
      if (error?.response?.status === 403) {
        enqueueSnackbar(t("completeRegistration.errorMessage403"), { variant: "error" });
      } else if (error?.response?.status === 428) {
        enqueueSnackbar(t("completeRegistration.errorMessage428"), { variant: "error" });
      } else {
        enqueueSnackbar(t("completeRegistration.errorMessage"), { variant: "error" });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const changeWithValidate = (e: any) => {
    formik.setFieldTouched(e.target.id);
    formik.handleChange(e);
  };

  return (
    <Box display="flex" justifyContent="center" width="100%">
      <PageTitle i18nKey="completeRegistration.meta_title" />
      <Panel sx={{ p: 4, width: isSmallScreen ? "100%" : "460px" }}>
        <Stack spacing={3}>
          <Typography variant="h4" component="h1" fontWeight={600} sx={{ color: "tertiary.main", textAlign: "center", pb: 2 }}>
            {t("completeRegistration.title")}
          </Typography>
          <PasswordField
            fullWidth={true}
            id={"password"}
            name={"password"}
            label={t("completeRegistration.newPassword")}
            value={formik.values.password ?? ""}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            onChange={changeWithValidate}
            disabled={isLoading}
          />
          <LanguageSelect
            value={formik.values.language ?? ""}
            error={formik.errors.language}
            onChange={(e) => formik.setFieldValue("language", e.target.value)}
            disabled={isLoading}
          />
          <Button
            disabled={isLoading || !token}
            fullWidth
            variant="contained"
            onClick={() => formik.handleSubmit()}
            autoFocus>
            {t("completeRegistration.complete")}
          </Button>
        </Stack>
      </Panel>
    </Box>
  );
};

export default CompleteRegistration;
