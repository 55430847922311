import React from "react";

import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import MUIRadio from "@mui/material/Radio";
import Select from "components/elements/Select";
import useDateTime from "hooks/useDateTime";
import { useTranslation } from "react-i18next";
import { translateNumberToDay } from "utils/index";

import IProps, { ISelectRow } from "./types";

const SelectRow: React.FC<ISelectRow> = ({ prefix, suffix, onChange, error, value, children }) => {
  return (
    <>
      <div style={{ paddingRight: "10px" }}>{prefix}</div>
      <div>
        <Select
          id="frequencyParameter"
          name="frequencyParameter"
          size="small"
          defaultValue={""}
          value={value}
          onChange={onChange}
          error={error}>
          {children}
        </Select>
      </div>
      <div>{suffix}</div>
    </>
  );
};

const FrequencyRadio = ({ checked, subscriptionType, onChangeSelect, error, selectValue, ...props }: IProps) => {
  const { t } = useTranslation();
  const { getLastDayOfMonth } = useDateTime();

  const getDailySliderText = (number: number, type: "each" | "day") => {
    if (number === 1) return t(`dcaBots.create.frequency.dailyRadio.day1.${type}`);
    else if (number > 1 && number < 5) return t(`dcaBots.create.frequency.dailyRadio.day2.${type}`);
    else return t(`dcaBots.create.frequency.dailyRadio.day3.${type}`);
  };

  const renderRow = () => {
    switch (props.value) {
      case "M":
        return (
          <SelectRow
            prefix={t("dcaBots.create.frequency.monthlyRadio.always")}
            onChange={onChangeSelect}
            error={error}
            value={selectValue}>
            {Array.from({ length: getLastDayOfMonth() }, (v, k) => k + 1).map((value, index) => (
              <MenuItem key={`select-m-${index}`} value={value}>
                {`${value}${t("dcaBots.create.frequency.monthlyRadio.inMonth")}`}
              </MenuItem>
            ))}
          </SelectRow>
        );
      case "W":
        return (
          <SelectRow
            prefix={t("dcaBots.create.frequency.weeklyRadio.always")}
            onChange={onChangeSelect}
            error={error}
            value={selectValue && selectValue > 7 ? "" : selectValue}>
            {Array.from({ length: 7 }, (v, k) => k + 1).map((value, index) => (
              <MenuItem key={`select-w-${index}`} value={value}>
                {translateNumberToDay(value - 1, true)}
              </MenuItem>
            ))}
          </SelectRow>
        );
      case "D":
        return (
          <SelectRow
            prefix={selectValue ? getDailySliderText(selectValue, "each") : undefined}
            onChange={onChangeSelect}
            error={error}
            value={selectValue && selectValue > 30 ? "" : selectValue}>
            {Array.from({ length: 30 }, (v, k) => k + 1).map((value, index) => (
              <MenuItem key={`select-d-${index}`} value={value}>
                {`${value} ${selectValue ? getDailySliderText(value, "day") : ""}`}
              </MenuItem>
            ))}
          </SelectRow>
        );
    }
  };
  return (
    <div style={{ width: "100%", display: "flex", alignItems: "center", flexDirection: "row" }}>
      <FormControlLabel control={<MUIRadio />} {...props} sx={{marginRight: "5px"}}/>
      {checked && renderRow()}
    </div>
  );
};

export default FrequencyRadio;
