import { Alert, AlertTitle, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Badge from "components/elements/Badge";
import { BadgeColors } from "components/elements/Badge/types";
import MaskedModeWrapper from "components/elements/MaskedModeWrapper";
import Modal from "components/elements/Modal";
import { useUserDataContext } from "context/UserDataContext";
import { useAPIExchangeWithdrawalFee, useAPIWithdrawalsList } from "hooks/useAPI";
import { useTranslation } from "react-i18next";
import { renderDateTimeWithComma, renderNumber } from "utils/formatter";

import IProps from "./types";

const stateColor = {
  STARTED: "info" as BadgeColors,
  DONE: "success" as BadgeColors,
  ERROR: "error" as BadgeColors,
};

const WithdrawalsListModal = ({ open, handleClose, id, maxFee, exchangeEnum }: IProps) => {
  const { t } = useTranslation();
  const { isMasked } = useUserDataContext();
  const { data } = useAPIWithdrawalsList(id);
  const { data: withdrawalFeeData } = useAPIExchangeWithdrawalFee(exchangeEnum, "BTC", false);

  return (
    <Modal
      open={open}
      onCancel={handleClose}
      withCloseButton
      size="lg"
      title={t("withdrawalConnections.withdrawalsListModal.label")}>
      {withdrawalFeeData?.fee && maxFee < withdrawalFeeData.fee && (
        <Alert severity="warning" sx={{ mb: 2 }}>
          <AlertTitle>{t("withdrawalConnections.withdrawalsListModal.maxFeeWarningTitle")}</AlertTitle>
          {t("withdrawalConnections.withdrawalsListModal.maxFeeWarningDescription", {
            value: `${withdrawalFeeData.fee}`.replace(".", ","),
            maxFee: `${maxFee}`.replace(".", ","),
          })}
        </Alert>
      )}
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t("withdrawalConnections.withdrawalsListModal.state")}</TableCell>
              <TableCell>{t("withdrawalConnections.withdrawalsListModal.startedAt")}</TableCell>
              <TableCell width={"150px"} align="right">{t("withdrawalConnections.withdrawalsListModal.amount")}</TableCell>
              <TableCell align="right">{t("withdrawalConnections.withdrawalsListModal.fee")}</TableCell>
              <TableCell width={"400px"}>{t("withdrawalConnections.withdrawalsListModal.address")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.withdrawals.map((row, index) => (
              <TableRow key={`${index}-${row.startedAt}`} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell>
                  <Badge color={stateColor[row.state as keyof typeof stateColor]}>
                    {t(`withdrawalConnections.withdrawalsListModal.states.${row.state}`)}
                  </Badge>
                </TableCell>
                <TableCell>{renderDateTimeWithComma(row.startedAt)}</TableCell>
                <TableCell width={"150px"} align="right">
                  <MaskedModeWrapper variant="body2" color="inherit" stackProps={{ justifyContent: "end" }}>
                    <Typography variant="body2" fontSize="inherit" color="inherit">{`${renderNumber(row.amount, 8)}\u00a0${row.currency}`}</Typography>
                  </MaskedModeWrapper>
                </TableCell>
                <TableCell align="right">{`${renderNumber(row.fee, 8)}\u00a0${row.currency}`}</TableCell>
                <TableCell width={"400px"}>
                  <MaskedModeWrapper variant="body2" color="inherit">
                    <Typography variant="body2" fontSize="inherit" color="inherit">{row.address}</Typography>
                  </MaskedModeWrapper>
                </TableCell>
              </TableRow>
            ))}
            {data?.withdrawals.length === 0 && (
              <TableRow>
                <TableCell colSpan={5}>
                  <Typography variant="body1" sx={{ textAlign: "center" }}>
                    {t("withdrawalConnections.withdrawalsListModal.noData")}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Modal>
  );
};

export default WithdrawalsListModal;
