import { renderNumber } from "utils/formatter";

import { useAPIExchangeRates } from "./useAPI";

const useExchangeRate = () => {
  const { data } = useAPIExchangeRates();

  const getUSDPrice = (currency: string, value?: number | string, scale?: number) => {
    if (data && value) {
      const rate = data[`USD/${currency}`];
      const val = typeof value === "string" ? Number(value.replace(",", ".")) : value;

      if (rate && val !== 0) {
        const val = typeof value === "string" ? Number(value.replace(",", ".")) : value;
        const inUsd = val / rate;

        return `${renderNumber(inUsd, scale, true)}\u00a0USD`;
      }
    }
  };

  const getUSDOriginalPrice = (currency: string, value?: number | string) => {
    if (data && value) {
      const rate = data[`USD/${currency}`];
      const val = typeof value === "string" ? Number(value.replace(",", ".")) : value;

      if (rate && val !== 0) {
        const val = typeof value === "string" ? Number(value.replace(",", ".")) : value;
        return val / rate;
      }
    }
  };

  return {
    getUSDPrice,
    getUSDOriginalPrice,
  };
};

export default useExchangeRate;
