import React, { useState } from "react";

import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import PauseOutlinedIcon from "@mui/icons-material/PauseOutlined";
import PlayArrowOutlinedIcon from "@mui/icons-material/PlayArrowOutlined";
import SettingsIcon from "@mui/icons-material/Settings";
import { Box, Grid, Stack, IconButton, MenuItem, Menu, ListItemIcon, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import MaskedModeWrapper from "components/elements/MaskedModeWrapper";
import Panel from "components/elements/Panel";
import UsdWrapper, { TypographyItem } from "components/elements/UsdWrapper";
import { AccountingCoinsStack } from "components/icons";
import DcaBotHeader from "components/modules/DcaBotHeader";
import SatsView from "components/modules/SatsView";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import useExchangesMetadata from "hooks/useExchangesMetadata";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { renderDateTimeWithComma, renderNumber } from "utils/formatter";

import Chart from "./Chart";
import { InfoBox, InfoItemGrid, MainWithChartGrid } from "./styles";
import IProps from "./types";

const Detail = ({ id, dcaBotsDetailData, baseCurrency, counterCurrency, onEnabled, onDelete }: IProps) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { colors } = useMUIThemeModeContext();
  const { t } = useTranslation();
  const { details, enabled } = dcaBotsDetailData;
  const [anchorElControl, setAnchorElControl] = useState<null | HTMLElement>(null);
  const { counterCurrDisplayedScale, baseCurrDisplayedScale, currencyPairPriceScale } = useExchangesMetadata(
    dcaBotsDetailData.exchangeConnection.exchangeEnum,
    dcaBotsDetailData.currencyPair
  );

  const handleOpenControlMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElControl(event.currentTarget);
  };

  const handleCloseControlMenu = () => {
    setAnchorElControl(null);
  };

  const handleEnable = () => {
    handleCloseControlMenu();
    onEnabled();
  };

  const handleDelete = () => {
    handleCloseControlMenu();
    onDelete();
  };

  const handleSettings = () => {
    handleCloseControlMenu();
    navigate(`/upravit-strategii/${id}`);
  };

  const getTileColor = () =>
    dcaBotsDetailData?.details?.currentValueDifference !== undefined
    && dcaBotsDetailData?.details?.currentValueDifference >= 0
      ? colors.success
      : colors.error;

  const controlMenu = () => {
    return (
      <Box>
        <IconButton size="small" onClick={handleOpenControlMenu} color="inherit">
          <MoreHorizIcon />
        </IconButton>
        <Menu
          sx={{ mt: "45px" }}
          id="menu-appbar-control"
          anchorEl={anchorElControl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorElControl)}
          onClose={handleCloseControlMenu}>
          <MenuItem
            disabled={
              !dcaBotsDetailData.exchangeConnection.isExchangeEnabled
              || (!enabled && !dcaBotsDetailData.exchangeConnection.functional)
            }
            onClick={handleEnable}>
            <ListItemIcon>
              {enabled ? <PauseOutlinedIcon fontSize="small" /> : <PlayArrowOutlinedIcon fontSize="small" />}
            </ListItemIcon>
            <Typography>{enabled ? t("dcaBots.pause") : t("dcaBots.activate")}</Typography>
          </MenuItem>
          <MenuItem onClick={handleDelete}>
            <ListItemIcon>
              <HighlightOffIcon fontSize="small" />
            </ListItemIcon>
            <Typography>{t("delete")}</Typography>
          </MenuItem>
          <MenuItem onClick={handleSettings}>
            <ListItemIcon>
              <SettingsIcon fontSize="small" />
            </ListItemIcon>
            <Typography>{t("dcaBots.editSettings")}</Typography>
          </MenuItem>
        </Menu>
      </Box>
    );
  };

  return (
    <Panel sx={{ overflow: "hidden" }}>
      <DcaBotHeader
        withBorderBottom={true}
        currencyPair={dcaBotsDetailData.currencyPair}
        enabled={enabled}
        label={dcaBotsDetailData.label}
        mode={dcaBotsDetailData.mode}
        rightSection={
          <Stack direction="row" spacing={1}>
            <Typography color={colors.legacyGray700} variant="body2">
              {dcaBotsDetailData.enabled ? t("dcaBots.enabled") : t("dcaBots.disabled")}
            </Typography>
            <Typography color={colors.legacyGray700} variant="body2" fontWeight={600}>
              {renderDateTimeWithComma(dcaBotsDetailData.enabledChangedAt)}
            </Typography>
          </Stack>
        }
        rightSectionSmallScreen={controlMenu()}
      />
      <Grid container>
        <MainWithChartGrid item xs={12} pr={{ xs: 2, md: 0 }}>
          <Stack width={"100%"} direction={{ xs: "column", md: "row" }}>
            <Stack spacing={2} width={{ xs: "100%", md: "45%" }}>
              <InfoBox color={theme.palette.tertiary.main} px={{ xs: 2, md: 3 }} py={{ xs: 1, md: 3 }}>
                <Stack>
                  <Typography variant="body1" fontWeight={600} sx={{ textTransform: "none" }}>
                    {t("dcaBots.totalInvested")}
                  </Typography>
                  <Stack width={"100%"} direction={"row"} alignItems={"center"}>
                    <AccountingCoinsStack sx={{ fontSize: 24, mr: 1 }} />
                    <MaskedModeWrapper color={theme.palette.tertiary.main} variant={"h6"}>
                      <SatsView
                        variant="h6"
                        color={theme.palette.tertiary.main}
                        value={dcaBotsDetailData?.details?.totalInvested}
                        withZeros={!!dcaBotsDetailData?.details?.totalInvested}
                        scale={counterCurrDisplayedScale}
                        currency={counterCurrency}
                      />
                    </MaskedModeWrapper>
                  </Stack>
                </Stack>
              </InfoBox>
              <InfoBox px={{ xs: 2, md: 3 }} py={{ xs: 1, md: 3 }} color={getTileColor()}>
                <Stack>
                  <Typography variant="body1" fontWeight={600} sx={{ textTransform: "none" }}>
                    {t("dcaBots.currentValue")}
                  </Typography>
                  <Stack width={"100%"} direction={"row"} alignItems={"center"}>
                    <AccountingCoinsStack sx={{ fontSize: 24, mr: 1 }} />
                    <MaskedModeWrapper color={getTileColor()} variant={"h6"}>
                      <SatsView
                        variant="h6"
                        color={getTileColor()}
                        value={dcaBotsDetailData?.details?.currentValue}
                        withZeros={!!dcaBotsDetailData?.details?.currentValue}
                        scale={counterCurrDisplayedScale}
                        currency={counterCurrency}
                      />
                    </MaskedModeWrapper>
                  </Stack>
                </Stack>
              </InfoBox>
            </Stack>
            <Box display={"flex"} justifyContent={"center"} alignItems={"center"} width={{ xs: "100%", md: "55%" }}>
              <Chart
                percentDifference={details?.currentValuePercentDifference ?? 0}
                valueDifference={details?.currentValueDifference ?? 0}
                currency={counterCurrency}
                savingsTarget={dcaBotsDetailData.savingsTarget}
                totalInvested={details?.totalInvested ?? 0}
                currentValue={details?.currentValue ?? 0}
                counterCurrDisplayedScale={counterCurrDisplayedScale}
              />
            </Box>
          </Stack>
        </MainWithChartGrid>
        <InfoItemGrid item xs={12} px={2} py={{ xs: 0.75, sm: 1, md: 2 }} pt={{ xs: 2 }}>
          <Stack width={"100%"} direction="row" justifyContent="space-between">
            <Typography color={"secondary"} variant="body1" pr={1}>
              {t("dcaBots.savingsTarget")}
            </Typography>
            <MaskedModeWrapper color={"secondary"} variant={"body1"} fontWeight={600}>
              <Typography sx={{ textAlign: "end" }} color={"secondary"} variant="body1" fontWeight={600}>
                {dcaBotsDetailData.savingsTarget
                  ? `${renderNumber(
                      dcaBotsDetailData.savingsTarget,
                      counterCurrDisplayedScale,
                      true
                    )}\u00a0${counterCurrency}`
                  : t("dcaBots.notSet")}
              </Typography>
            </MaskedModeWrapper>
          </Stack>
        </InfoItemGrid>
        {dcaBotsDetailData.savingsTarget && (
          <InfoItemGrid item xs={12} px={2} py={{ xs: 0.75, sm: 1, md: 2 }}>
            <Stack width={"100%"} direction="row" justifyContent="space-between">
              <Typography color={"secondary"} variant="body1" pr={1}>
                {t("dcaBots.alreadySaved")}
              </Typography>
              <Typography sx={{ textAlign: "end" }} color={"secondary"} variant="body1" fontWeight={600}>
                {`${renderNumber(dcaBotsDetailData?.details?.savedPercent ?? 0, 3)} %`}
              </Typography>
            </Stack>
          </InfoItemGrid>
        )}
        <InfoItemGrid item xs={12} px={2} py={{ xs: 0.75, sm: 1, md: 2 }}>
          <Stack width={"100%"} direction="row" justifyContent="space-between">
            <Typography color={"secondary"} variant="body1" pr={1}>
              {t("dcaBots.youHaveSaved")}
            </Typography>
            <MaskedModeWrapper color={"secondary"} variant={"body1"} fontWeight={600}>
              <SatsView
                color={theme.palette.secondary.main}
                variant="body1"
                fontWeight={600}
                value={dcaBotsDetailData?.details?.totalAmount}
                currency={baseCurrency}
                scale={baseCurrDisplayedScale}
                withZeros={!!dcaBotsDetailData?.details?.totalAmount}
              />
            </MaskedModeWrapper>
          </Stack>
        </InfoItemGrid>
        <InfoItemGrid item xs={12} px={2} py={{ xs: 0.75, sm: 1, md: 2 }}>
          <Stack width={"100%"} direction="row" justifyContent="space-between">
            <Typography color={"secondary"} variant="body1" pr={1}>
              {t("dcaBots.averagePrice")}
            </Typography>
            <UsdWrapper value={dcaBotsDetailData?.details?.averagePrice} counterCurrency={counterCurrency} baseCurrency={baseCurrency}>
              <TypographyItem
                sx={{ textAlign: "end" }}
                color={"secondary"}
                variant="body1"
                fontWeight={600}
                value={dcaBotsDetailData?.details?.averagePrice}
                scale={currencyPairPriceScale}
                currency={counterCurrency}
                withZeros
              />
            </UsdWrapper>
          </Stack>
        </InfoItemGrid>
        <InfoItemGrid item xs={12} px={2} py={{ xs: 0.75, sm: 1, md: 2 }}>
          <Stack width={"100%"} direction="row" justifyContent="space-between">
            <Typography color={"secondary"} variant="body1" pr={1}>
              {t("dcaBots.askPrice")}
            </Typography>
            <UsdWrapper value={dcaBotsDetailData?.details?.askPrice} counterCurrency={counterCurrency} baseCurrency={baseCurrency}>
              <TypographyItem
                sx={{ textAlign: "end" }}
                color={"secondary"}
                variant="body1"
                fontWeight={600}
                value={dcaBotsDetailData?.details?.askPrice}
                scale={currencyPairPriceScale}
                currency={counterCurrency}
                withZeros
              />
            </UsdWrapper>
          </Stack>
        </InfoItemGrid>
        <InfoItemGrid item xs={12} px={2} py={{ xs: 0.75, sm: 1, md: 2 }} pb={{ xs: 2 }}>
          <Stack width={"100%"} direction="row" justifyContent="space-between">
            <Typography color={"secondary"} variant="body1" pr={1}>
              {t("dcaBots.bidPrice")}
            </Typography>
            <UsdWrapper value={dcaBotsDetailData?.details?.bidPrice} counterCurrency={counterCurrency} baseCurrency={baseCurrency}>
              <TypographyItem
                sx={{ textAlign: "end" }}
                color={"secondary"}
                variant="body1"
                fontWeight={600}
                value={dcaBotsDetailData?.details?.bidPrice}
                scale={currencyPairPriceScale}
                currency={counterCurrency}
                withZeros
              />
            </UsdWrapper>
          </Stack>
        </InfoItemGrid>
      </Grid>
    </Panel>
  );
};

export default Detail;
