import { useState } from "react";

function useStep(steps: string[]) {
  const [activeStep, setActiveStep] = useState<number>(0);

  const totalSteps = () => {
    return steps.length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const isFirstStep = () => {
    return activeStep === 0;
  };

  const handleBack = () => {
    if (isFirstStep()) return;

    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNext = () => {
    if (isLastStep()) return;

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  return {
    activeStep,
    steps,
    nextStep: handleNext,
    backStep: handleBack,
    isLastStep: isLastStep(),
    isFirstStep: isFirstStep(),
    totalSteps: totalSteps(),
  };
}

export default useStep;
