export const Document = () => {
  return (
    <svg width="27" height="30" viewBox="0 0 27 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.285 0.732422H13.3746C15.5162 0.732416 17.1841 0.732412 18.5195 0.848028C19.8761 0.965482 20.9765 1.20754 21.9583 1.74361C23.3492 2.5031 24.4925 3.64639 25.252 5.0373C25.788 6.01903 26.0301 7.11951 26.1475 8.47611C26.2632 9.8115 26.2632 11.4795 26.2632 13.621V16.3772C26.2632 18.5187 26.2632 20.1867 26.1475 21.5221C26.0301 22.8787 25.788 23.9791 25.252 24.9609C24.4925 26.3518 23.3492 27.4951 21.9583 28.2546C20.9765 28.7906 19.8761 29.0327 18.5195 29.1501C17.1841 29.2658 15.5161 29.2658 13.3746 29.2658H13.2851C11.1435 29.2658 9.47556 29.2658 8.14017 29.1501C6.78357 29.0327 5.68309 28.7906 4.70136 28.2546C3.31045 27.4951 2.16716 26.3518 1.40767 24.9609C0.871605 23.9791 0.629545 22.8787 0.512091 21.5221C0.396474 20.1867 0.396479 18.5188 0.396484 16.3772V13.6209C0.396479 11.4794 0.396474 9.81148 0.512091 8.47611C0.629545 7.11951 0.871605 6.01903 1.40767 5.0373C2.16716 3.64639 3.31045 2.5031 4.70136 1.74361C5.68309 1.20754 6.78357 0.965482 8.14017 0.848028C9.47554 0.732412 11.1435 0.732416 13.285 0.732422ZM8.30119 2.70774C7.06763 2.81454 6.257 3.02099 5.59596 3.38194C4.51913 3.96994 3.634 4.85506 3.04601 5.93189C2.68505 6.59293 2.4786 7.40357 2.3718 8.63712C2.26397 9.8826 2.26315 11.4699 2.26315 13.6658V16.3324C2.26315 18.5282 2.26397 20.1156 2.3718 21.3611C2.4786 22.5946 2.68505 23.4052 3.04601 24.0663C3.634 25.1431 4.51913 26.0282 5.59596 26.6162C6.257 26.9772 7.06763 27.1836 8.30119 27.2904C9.54667 27.3983 11.134 27.3991 13.3298 27.3991C15.5256 27.3991 17.113 27.3983 18.3584 27.2904C19.592 27.1836 20.4026 26.9772 21.0637 26.6162C22.1405 26.0282 23.0256 25.1431 23.6136 24.0663C23.9746 23.4052 24.181 22.5946 24.2878 21.3611C24.3957 20.1156 24.3965 18.5282 24.3965 16.3324V13.6658C24.3965 11.4699 24.3957 9.8826 24.2878 8.63712C24.181 7.40357 23.9746 6.59293 23.6136 5.93189C23.0256 4.85506 22.1405 3.96994 21.0637 3.38194C20.4026 3.02099 19.592 2.81454 18.3584 2.70774C17.113 2.5999 15.5256 2.59909 13.3298 2.59909C11.134 2.59909 9.54667 2.5999 8.30119 2.70774Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.6641 21.2659C18.6641 21.7814 18.2462 22.1992 17.7307 22.1992L7.59739 22.1992C7.08193 22.1992 6.66406 21.7814 6.66406 21.2659C6.66406 20.7504 7.08193 20.3326 7.59739 20.3326L17.7307 20.3326C18.2462 20.3326 18.6641 20.7504 18.6641 21.2659Z"
        fill="#FF9900"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.6641 15.9319C18.6641 16.4474 18.2462 16.8652 17.7307 16.8652L7.59739 16.8652C7.08193 16.8652 6.66406 16.4474 6.66406 15.9319C6.66406 15.4164 7.08193 14.9986 7.59739 14.9986L17.7307 14.9986C18.2462 14.9986 18.6641 15.4164 18.6641 15.9319Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3301 10.5999C13.3301 11.1153 12.9122 11.5332 12.3967 11.5332L7.59675 11.5332C7.08128 11.5332 6.66341 11.1153 6.66341 10.5999C6.66341 10.0844 7.08128 9.66654 7.59674 9.66654L12.3967 9.66654C12.9122 9.66654 13.3301 10.0844 13.3301 10.5999Z"
        fill="#FF9900"
      />
    </svg>
  );
};
