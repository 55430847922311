import { useEffect, useRef } from "react";

import { useTheme } from "@mui/material";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import {
  IChartApi,
  ISeriesApi,
  LineWidth,
  MouseEventParams,
  PriceScaleMode,
  createChart,
} from "lightweight-charts-latest";
import ReactDOMServer from "react-dom/server";
import { useTranslation } from "react-i18next";
import { renderDate, renderLocaleDate, renderNumber } from "utils/formatter";

export interface ITooltipData {
  number: string;
  color: string;
  label: string;
  value: number;
}

interface ITooltipProps {
  date: string;
  data?: ITooltipData[];
  customRender?: (data?: ITooltipData[]) => JSX.Element | undefined;
}

export interface IPointChart {
  time: string;
  value: number;
}

export interface IChartProps {
  lineSeriesOptions: { type: "price" | "percent"; position: "right" | "left"; color: string; label: string, lineWidth?: number }[];
  points: IPointChart[][];
  leftPriceScale?: boolean;
  priceScaleMode?: PriceScaleMode;
  valueUnit?: string;
  tooltipScale?: number;
  tooltipRender?: (data?: ITooltipData[]) => JSX.Element | undefined;
  fixedTooltip?: boolean;
}

const createTooltip = (borderColor: string) => {
  const toolTip = document.createElement("div");

  toolTip.style.position = "absolute";
  toolTip.style.display = "none";
  toolTip.style.padding = "8px";
  toolTip.style.boxSizing = "border-box";
  toolTip.style.fontSize = "12px";
  toolTip.style.textAlign = "left";
  toolTip.style.zIndex = "1000";
  toolTip.style.top = "12px";
  toolTip.style.left = "12px";
  toolTip.style.pointerEvents = "none";
  toolTip.style.border = "1px solid";
  toolTip.style.borderRadius = "10px";
  toolTip.style.fontFamily = "Popins";
  toolTip.style.background = "white";
  toolTip.style.color = "black";
  toolTip.style.borderColor = borderColor;

  return toolTip;
};

const Tooltip = ({ date, data, customRender }: ITooltipProps) => {
  const { t } = useTranslation();

  return (
    <>
      {data
        && data.map((item, index) => (
          <div key={index} style={{ whiteSpace: "nowrap" }}>
            <div
              style={{
                height: 10,
                width: 10,
                backgroundColor: item.color,
                borderRadius: "50%",
                marginRight: 4,
                display: "inline-block",
              }}
            />
            {item.label}: <strong>{item.number}</strong>
          </div>
        ))}
      {customRender?.(data)}
      <div>
        {t("date")}: <strong>{renderDate(date)}</strong>
      </div>
    </>
  );
};

const Chart = ({
  points,
  lineSeriesOptions,
  leftPriceScale,
  priceScaleMode = PriceScaleMode.Normal,
  valueUnit,
  tooltipScale,
  tooltipRender,
  fixedTooltip,
}: IChartProps) => {
  const theme = useTheme();
  const { palette } = theme;
  const { colors } = useMUIThemeModeContext();

  const chartContainerRef = useRef<any>();
  const chart = useRef<IChartApi>();
  const lineSeries = useRef<ISeriesApi<"Line">[]>([]);
  const toolTip = useRef<HTMLDivElement>();

  const handleResize = () => {
    chart.current?.priceScale("right").applyOptions({ autoScale: true });
    chart.current?.timeScale().fitContent();

    setTimeout(() => chart.current?.applyOptions({ width: chartContainerRef.current.clientWidth }), 10);
  };

  const handleCrosshairMove = (param: MouseEventParams, toolTip: HTMLElement) => {
    if (
      param.point === undefined
      || !param.time
      || param.point.x < 0
      || param.point.x > chartContainerRef.current.clientWidth
      || param.point.y < 0
      || param.point.y > chartContainerRef.current.clientHeight
    ) {
      toolTip.style.display = "none";
    } else {
      const data = points.map((item, index) => {
        const value = item.find((item) => item.time === param.time)?.value;

        return {
          number: `${renderNumber(value ?? 0, tooltipScale)}${valueUnit ? `\u00a0${valueUnit}` : ""}`,
          value: value ?? 0,
          color: lineSeriesOptions[index]?.color,
          label: lineSeriesOptions[index]?.label,
        };
      });

      if (data && data.length > 0) {
        toolTip.style.display = "block";
        toolTip.innerHTML = ReactDOMServer.renderToString(<Tooltip date={param.time as string} data={data} customRender={tooltipRender} />);
      }

      const y = param.point.y;
      const x = param.point.x;
      const toolTipMargin = 10;

      let left = x + toolTipMargin;
      if (left > chartContainerRef.current.clientWidth - toolTip.clientWidth && !fixedTooltip)
        left = x - toolTip.clientWidth - toolTipMargin;

      let top = y + toolTipMargin;
      if (top > chartContainerRef.current.clientHeight - toolTip.clientHeight)
        top = y - toolTip.clientHeight - toolTipMargin;

      toolTip.style.left = left + "px";
      toolTip.style.top = top + "px";
    }
  };

  const getLineSeries = (type: "price" | "percent", position: "right" | "left", color: string, lineWidth: number) => {
    return {
      color: color,
      lastValueVisible: false,
      priceLineVisible: false,
      priceScaleId: position,
      lineWidth: lineWidth as LineWidth,
      priceFormat: {
        type,
      },
    };
  };

  useEffect(() => {
    chart.current = createChart(chartContainerRef.current, {
      width: chartContainerRef.current.clientWidth,
      layout: {
        background: { color: "transparent" },
      },
      crosshair: {
        horzLine: {
          visible: false,
          labelVisible: false,
        },
        vertLine: {
          visible: false,
          labelVisible: false,
        },
      },
      rightPriceScale: {
        borderColor: colors.borderColor,
        mode: priceScaleMode,
        visible: true,
      },
      leftPriceScale: leftPriceScale
        ? { borderColor: colors.borderColor, visible: true, mode: priceScaleMode }
        : undefined,
      timeScale: {
        borderColor: colors.borderColor,
      },
      localization: {
        timeFormatter: (time: string) => renderLocaleDate(time),
        priceFormatter: (price: number) => `${renderNumber(price, 2)}${valueUnit ? `\u00a0${valueUnit}` : ""}`,
      },
    });

    lineSeriesOptions.forEach((option, index) => {
      if (chart.current) {
        lineSeries.current[index] = chart.current.addLineSeries(
          getLineSeries(option.type, option.position, option.color, option.lineWidth ?? 0.8)
        );
      }
    });

    chart.current.timeScale().fitContent();

    // add a small margin on the right of the last candle
    chart.current.timeScale().applyOptions({ rightOffset: 3 });

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);

      chart.current?.remove();
    };
  }, [lineSeriesOptions]);

  useEffect(() => {
    if (lineSeries.current && points.length > 0 && Array.isArray(points)) {
      points.forEach((item, index) => lineSeries.current[index]?.setData(item));
      chart.current?.timeScale().fitContent();
    }
  }, [points]);

  useEffect(() => {
    chart.current?.applyOptions({
      layout: {
        textColor: colors.black,
      },
      grid: {
        vertLines: { color: palette.mode === "light" ? "#D6DCDE" : "#444" },
        horzLines: { color: palette.mode === "light" ? "#D6DCDE" : "#444" },
      },
    });
  }, [palette.mode, lineSeriesOptions]);

  useEffect(() => {
    if (!toolTip.current) {
      toolTip.current = createTooltip(palette.tertiary.main);
      chartContainerRef.current?.appendChild(toolTip.current);
    }

    chart.current?.subscribeCrosshairMove((param) => toolTip.current && handleCrosshairMove(param, toolTip.current));
  }, [lineSeriesOptions, points]);

  return <div style={{ height: "100%", position: "relative" }} ref={chartContainerRef} />;
};

export default Chart;
