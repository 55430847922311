import { SvgIcon } from "@mui/material";

export const BarLineChart = (props: any) => {
  return (
    <SvgIcon {...props}>
      <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="bar-line-chart">
          <path
            id="Icon"
            d="M16.6663 16.6667V10.8333M9.99967 16.6667V8.33333M3.33301 16.6667L3.33301 13.3333M11.1719 4.18959L15.4789 5.80472M8.99865 4.50077L4.3332 7.99986M17.5502 5.36612C18.0384 5.85427 18.0384 6.64573 17.5502 7.13388C17.0621 7.62204 16.2706 7.62204 15.7825 7.13388C15.2943 6.64573 15.2943 5.85427 15.7825 5.36612C16.2706 4.87796 17.0621 4.87796 17.5502 5.36612ZM4.21689 7.86612C4.70505 8.35427 4.70505 9.14573 4.21689 9.63388C3.72874 10.122 2.93728 10.122 2.44912 9.63388C1.96097 9.14573 1.96097 8.35427 2.44912 7.86612C2.93728 7.37796 3.72874 7.37796 4.21689 7.86612ZM10.8836 2.86612C11.3717 3.35427 11.3717 4.14573 10.8836 4.63388C10.3954 5.12204 9.60395 5.12204 9.11579 4.63388C8.62764 4.14573 8.62764 3.35427 9.11579 2.86612C9.60395 2.37796 10.3954 2.37796 10.8836 2.86612Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    </SvgIcon>
  );
};
