import { Box, Stack, Typography } from "@mui/material";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";

interface IProps {
  children: React.ReactNode;
  title: string | React.ReactNode;
  actions?: React.ReactNode;
  id?: string;
}

const SectionCard = ({ children, title, actions, id }: IProps) => {
  const { colors } = useMUIThemeModeContext();

  return (
    <Box width={"100%"} pb={7} id={id}>
      <Stack pb={2} direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
        {typeof title === "string" ? (
          <Typography color={colors.gray900} fontWeight={500} fontSize={{sx: "1.125rem", sm: "1.375rem"}}>
            {title}
          </Typography>
        ) : (
          title
        )}
        {actions}
      </Stack>
      {children}
    </Box>
  );
};

export default SectionCard;
