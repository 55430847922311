import React from "react";

import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

interface IProps {
  i18nKey?: string;
}

const PageTitle = ({ i18nKey }: IProps) => {
  const { t } = useTranslation<string>();

  return (
    <Helmet>
      <title>{i18nKey ? `${t(i18nKey)}${t("meta_title_suffix")}` : t("meta_title")}</title>
    </Helmet>
  );
};

export default PageTitle;
