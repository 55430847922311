import { SvgIcon } from "@mui/material";

export const Discord = (props: any) => {
  return (
    <SvgIcon {...props}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M19.624 5.0885C18.222 4.445 16.718 3.971 15.146 3.6995C15.1175 3.6945 15.089 3.7075 15.074 3.7335C14.8805 4.0775 14.6665 4.526 14.5165 4.879C12.8255 4.626 11.143 4.626 9.48699 4.879C9.33699 4.5185 9.11499 4.0775 8.92049 3.7335C8.90549 3.708 8.87699 3.695 8.84849 3.6995C7.27699 3.97 5.77349 4.444 4.37049 5.0885C4.35849 5.0935 4.34799 5.1025 4.34099 5.114C1.48899 9.375 0.707488 13.5315 1.09099 17.636C1.09249 17.656 1.10399 17.6755 1.11949 17.6875C3.00099 19.0695 4.82399 19.9085 6.61299 20.4645C6.64149 20.473 6.67199 20.463 6.68999 20.439C7.11299 19.861 7.49049 19.252 7.81399 18.611C7.83299 18.5735 7.81499 18.529 7.77599 18.514C7.17749 18.287 6.60799 18.0105 6.05999 17.696C6.01649 17.6705 6.01299 17.6085 6.05299 17.579C6.16849 17.4925 6.28349 17.4025 6.39399 17.312C6.41399 17.2955 6.44149 17.292 6.46499 17.3025C10.0655 18.9465 13.9635 18.9465 17.5215 17.3025C17.545 17.291 17.5725 17.2945 17.5935 17.311C17.7035 17.402 17.819 17.4925 17.935 17.579C17.975 17.6085 17.9725 17.6705 17.929 17.696C17.381 18.0165 16.811 18.287 16.212 18.513C16.173 18.528 16.1555 18.573 16.1745 18.611C16.505 19.251 16.882 19.86 17.2975 20.438C17.315 20.4625 17.346 20.473 17.3745 20.464C19.172 19.908 20.995 19.069 22.8765 17.687C22.893 17.675 22.9035 17.6565 22.905 17.6365C23.3635 12.891 22.1365 8.769 19.6525 5.1145C19.6465 5.1025 19.636 5.0935 19.624 5.0885ZM8.35149 15.1365C7.26749 15.1365 6.37449 14.1415 6.37449 12.919C6.37449 11.6965 7.25049 10.7015 8.35149 10.7015C9.46149 10.7015 10.346 11.7055 10.3285 12.919C10.329 14.141 9.45299 15.1365 8.35149 15.1365ZM15.662 15.1365C14.578 15.1365 13.685 14.1415 13.685 12.919C13.685 11.6965 14.561 10.7015 15.662 10.7015C16.772 10.7015 17.6565 11.7055 17.639 12.919C17.639 14.141 16.772 15.1365 15.662 15.1365Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};
