import React from "react";

import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Stack, ToggleButton, ToggleButtonGroup } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "components/elements/Dialog";
import { OHLCInterval } from "types/enums";

interface IProps {
  children: React.ReactNode;
  handleClose: () => void;
  title: string;
  interval: OHLCInterval;
  onChangeInterval: (_event: React.MouseEvent<HTMLElement>, newInterval: string) => void;
}

const MODAL_CHART_HEIGHT = (window.innerHeight / 4) * 3; // 75%

export default function ChartModal({ children, handleClose, title, interval, onChangeInterval }: IProps) {
  return (
    <Dialog open fullWidth={true} maxWidth={"xl"} onClose={handleClose}>
      <DialogTitle sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
        {title}
        <Stack direction={"row"} spacing={2} alignItems={"center"}>
          <ToggleButtonGroup color="primary" value={interval} exclusive onChange={onChangeInterval}>
            <ToggleButton sx={{ textTransform: "none" }} value={OHLCInterval.ONE_DAY}>
              1D
            </ToggleButton>
            <ToggleButton sx={{ textTransform: "none" }} value={OHLCInterval.ONE_HOUR}>
              1h
            </ToggleButton>
          </ToggleButtonGroup>
          <IconButton edge="end" size="small" color="inherit" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{height: `${MODAL_CHART_HEIGHT}px`, mb: 2}}>{children}</DialogContent>
    </Dialog>
  );
}
