import React from "react";

import { Grid, Stack, Typography, useTheme } from "@mui/material";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import InputAdornment from "@mui/material/InputAdornment";
import { instantBuyOrder, instantSellOrder } from "API/calls";
import Big from "big.js";
import MaskedModeWrapper, { getMaskedValue } from "components/elements/MaskedModeWrapper";
import PriceField from "components/elements/PriceField";
import Slider from "components/elements/Slider";
import SatsView from "components/modules/SatsView";
import { useUserDataContext } from "context/UserDataContext";
import { useFormik } from "formik";
import useExchangeRate from "hooks/useExchangeRate";
import useExchangesMetadata from "hooks/useExchangesMetadata";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { OrderType, ExchangeType, Side } from "types/enums";
import { getColorByMode } from "utils";
import { getBigWithComma, getNumberWithComma, renderNumber } from "utils/formatter";
import * as yup from "yup";

import IProps from "./types";

const VOLATILITY_FACTOR = 0.03;

const InstantBuy = ({
  id,
  handleClose,
  handleInstantTrade,
  handleBackdropLoading,
  handleProcessingPayment,
  isProcessingPayment,
  baseCurrency,
  counterCurrency,
  exchangeEnum,
  balanceBase,
  balanceCounter,
  askPrice,
  bidPrice,
}: IProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const currencyPair = `${baseCurrency}/${counterCurrency}`;
  const { enqueueSnackbar } = useSnackbar();
  const { isMasked } = useUserDataContext();
  const { getUSDPrice } = useExchangeRate();
  const { counterCurrScale, baseCurrScale, currencyPairBaseScale, currencyPairPriceScale, getLimit }
    = useExchangesMetadata(exchangeEnum ?? "", currencyPair);

  const validationBuyValue = (value?: string, context?: yup.TestContext<any>) => {
    if (value && context && exchangeEnum) {
      const val = Number(value.replace(",", "."));

      // min
      const minBaseAmount = getLimit(Side.BUY, "min", "base", currencyPair, exchangeEnum, OrderType.MARKET) || 0;
      let minBaseAmountInCounter = 0;
      if (minBaseAmount && askPrice) {
        minBaseAmountInCounter = new Big(minBaseAmount * askPrice * (1 + VOLATILITY_FACTOR))
          .round(counterCurrScale, Big.roundDown)
          .toNumber();
      }
      const minCounterAmount = getLimit(Side.BUY, "min", "counter", currencyPair, exchangeEnum, OrderType.MARKET) || 0;
      const minBuyValue = minBaseAmountInCounter >= minCounterAmount ? minBaseAmountInCounter : minCounterAmount;

      // max
      const maxBaseAmount = getLimit(Side.BUY, "max", "base", currencyPair, exchangeEnum, OrderType.MARKET);
      let maxBaseAmountInCounter = undefined;
      if (maxBaseAmount && askPrice) {
        maxBaseAmountInCounter = new Big(maxBaseAmount * askPrice * (1 - VOLATILITY_FACTOR))
          .round(counterCurrScale, Big.roundDown)
          .toNumber();
      }
      const maxCounterAmount = getLimit(Side.BUY, "max", "counter", currencyPair, exchangeEnum, OrderType.MARKET);
      let maxBuyValue = undefined;
      if (maxBaseAmountInCounter && maxCounterAmount) {
        maxBuyValue = maxBaseAmountInCounter <= maxCounterAmount ? maxBaseAmountInCounter : maxCounterAmount;
      } else if (maxBaseAmountInCounter) {
        maxBuyValue = maxBaseAmountInCounter;
      } else if (maxCounterAmount) {
        maxBuyValue = maxCounterAmount;
      }

      if (minBuyValue && minBuyValue >= 0) {
        if (!val || val < minBuyValue) {
          return context.createError({
            message: t("minOrderValues", { value: getNumberWithComma(minBuyValue), currency: counterCurrency }),
          });
        }
      }
      if (maxBuyValue && val && val > maxBuyValue) {
        return context.createError({
          message: t("maxOrderValues", { value: getNumberWithComma(maxBuyValue), currency: counterCurrency }),
        });
      }
      if (val === 0) {
        return context.createError({
          message: t("minOrderValuesFallback", { value: 0, currency: counterCurrency }),
        });
      }
      if (!val) {
        return context.createError({
          message: t("fieldRequired"),
        });
      }

      if (val > balanceCounter.available) {
        return context.createError({
          message: t("insufficientFunds"),
        });
      }

      if (val.toString().length > 19) {
        return context.createError({
          message: t("fieldMaxLength", { max: 19 }),
        });
      }
    }

    return true;
  };

  const validationSellValue = (value?: string, context?: yup.TestContext<any>) => {
    if (value && context && exchangeEnum) {
      const val = Number(value.replace(",", "."));

      // min
      const minCounterAmount = getLimit(Side.SELL, "min", "counter", currencyPair, exchangeEnum, OrderType.MARKET) || 0;
      let minCounterAmountInBase = 0;
      if (minCounterAmount && askPrice) {
        minCounterAmountInBase = new Big((minCounterAmount / askPrice) * (1 + VOLATILITY_FACTOR))
          .round(currencyPairBaseScale, Big.roundDown)
          .toNumber();
      }
      const minBaseAmount = getLimit(Side.SELL, "min", "base", currencyPair, exchangeEnum, OrderType.MARKET) || 0;
      const minSellValue = minCounterAmountInBase >= minBaseAmount ? minCounterAmountInBase : minBaseAmount;

      // max
      const maxCounterAmount = getLimit(Side.SELL, "max", "counter", currencyPair, exchangeEnum, OrderType.MARKET);
      let maxCounterAmountInBase = undefined;
      if (maxCounterAmount && askPrice) {
        maxCounterAmountInBase = new Big((maxCounterAmount / askPrice) * (1 - VOLATILITY_FACTOR))
          .round(currencyPairBaseScale, Big.roundDown)
          .toNumber();
      }
      const maxBaseAmount = getLimit(Side.SELL, "max", "base", currencyPair, exchangeEnum, OrderType.MARKET);
      let maxSellValue = undefined;
      if (maxCounterAmountInBase && maxBaseAmount) {
        maxSellValue = maxCounterAmountInBase <= maxBaseAmount ? maxCounterAmountInBase : maxBaseAmount;
      } else if (maxCounterAmountInBase) {
        maxSellValue = maxCounterAmountInBase;
      } else if (maxBaseAmount) {
        maxSellValue = maxBaseAmount;
      }

      if (minSellValue && minSellValue >= 0) {
        if (!val || val < minSellValue) {
          return context.createError({
            message: t("minOrderValues", { value: getNumberWithComma(minSellValue), currency: baseCurrency }),
          });
        }
      }
      if (maxSellValue && val && val > maxSellValue) {
        return context.createError({
          message: t("maxOrderValues", { value: getNumberWithComma(maxSellValue), currency: baseCurrency }),
        });
      }
      if (val === 0) {
        return context.createError({
          message: t("minOrderValuesFallback", { value: 0, currency: baseCurrency }),
        });
      }
      if (!val) {
        return context.createError({
          message: t("fieldRequired"),
        });
      }

      if (val > balanceBase.available) {
        return context.createError({
          message: t("insufficientFunds"),
        });
      }

      if (val.toString().length > 19) {
        return context.createError({
          message: t("fieldMaxLength", { max: 19 }),
        });
      }
    }

    return true;
  };

  const requiredValidation = () => {
    return t("fieldRequired");
  };

  const handleInstantBuyOrder = async (value: number) => {
    handleBackdropLoading(true);

    try {
      await instantBuyOrder(id, value);
      enqueueSnackbar(t("dcaBots.balances.successBuyMessage"), { variant: "success" });
      handleInstantTrade();
      if (exchangeEnum === ExchangeType.ANYCOIN) handleProcessingPayment(true);
    } catch (err) {
      enqueueSnackbar(t("dcaBots.balances.errorBuyMessage"), { variant: "error" });
    } finally {
      handleBackdropLoading(false);
      if (exchangeEnum !== ExchangeType.ANYCOIN) handleClose();
    }
  };

  const handleInstantSellOrder = async (value: number) => {
    handleBackdropLoading(true);

    try {
      await instantSellOrder(id, value);
      enqueueSnackbar(t("dcaBots.balances.successSellMessage"), { variant: "success" });
      handleInstantTrade();
      if (exchangeEnum === ExchangeType.ANYCOIN) handleProcessingPayment(true);
    } catch (err) {
      enqueueSnackbar(t("dcaBots.balances.errorSellMessage"), { variant: "error" });
    } finally {
      handleBackdropLoading(false);
      if (exchangeEnum !== ExchangeType.ANYCOIN) handleClose();
    }
  };

  const formikBuyInitial = {
    buyValue: 0,
  };
  const formikBuy = useFormik({
    initialValues: formikBuyInitial,
    validationSchema: yup.object().shape({
      buyValue: yup.string().required(requiredValidation()).test("minBuyValue", "minBuyValueError", validationBuyValue),
    }),
    onSubmit: (values) => {
      const { buyValue } = values;
      buyValue && handleInstantBuyOrder(Number(`${buyValue}`.replace(",", ".")));
    },
  });

  const formikSellInitial = {
    sellValue: 0,
  };
  const formikSell = useFormik({
    initialValues: formikSellInitial,
    validationSchema: yup.object().shape({
      sellValue: yup
        .string()
        .required(requiredValidation())
        .test("minSellValue", "minSellValueError", validationSellValue),
    }),
    onSubmit: (values) => {
      const { sellValue } = values;
      sellValue && handleInstantSellOrder(Number(`${sellValue}`.replace(",", ".")));
    },
  });

  const onBuySliderChange = (_: React.SyntheticEvent | Event, newValue: number | number[]) => {
    if (typeof newValue === "number") {
      formikBuy.setFieldValue("buyValue", getBigWithComma(new Big(newValue).round(counterCurrScale, Big.roundDown)));
    }
  };

  const onSellSliderChange = (_: React.SyntheticEvent | Event, newValue: number | number[]) => {
    if (typeof newValue === "number") {
      formikSell.setFieldValue("sellValue", getBigWithComma(new Big(newValue).round(currencyPairBaseScale, Big.roundDown)));
    }
  };

  const getSliderStepSize = (value: number) => {
    const valueFraction = value / 100;
    if (valueFraction > 1) {
      return 1;
    }
    return valueFraction;
  };

  const getSliderInputValue = (value: number | string): number => {
    if (typeof value === "number") {
      return value;
    } else {
      return Number(value.replace(",", "."));
    }
  };

  const getApproximateAmount = (value: string, price: string, side: Side) => {
    switch (side) {
      case Side.SELL:
        return Number(value.replace(",", ".")) * Number(price.replace(",", "."));
      case Side.BUY:
        if (value === "0") return 0;

        return Number(value.replace(",", ".")) / Number(price.replace(",", "."));
    }
  };

  const hasAnyFrozenBalance = Boolean(balanceCounter.frozen || balanceBase.frozen);

  const getAskBidPrice = (price: number, currency: string) => {
    const usdPrice = getUSDPrice(currency, price, 0);

    if (usdPrice) {
      return (
        <Typography fontWeight={600} fontSize={"inherit"}>{`(~\u00a0${usdPrice})`}</Typography>
      );
    }
  };

  return (
    <>
      {isProcessingPayment ? (
        <>
          <Box sx={{ display: "flex", justifyContent: "center", mt: 1, mb: 6 }}>
            <CircularProgress color="primary" />
          </Box>
          <Alert severity="success">
            <AlertTitle>{t("dcaBots.balances.processingPaymentBox.title")}</AlertTitle>
            {t("dcaBots.balances.processingPaymentBox.subtitle")}
          </Alert>
        </>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                background: getColorByMode(theme, theme.palette.grey[100], theme.palette.grey[900]),
                p: 2,
                borderRadius: "10px",
              }}>
              <Box pb={2}>
                <Stack direction={{ xs: "column", sm: "row" }} spacing={{ xs: 0, sm: 0.5 }}>
                  <Typography>{`${t("dcaBots.balances.available")}:`}</Typography>
                  <MaskedModeWrapper color={theme.palette.text.primary} fontWeight={600}>
                    <SatsView
                      color={theme.palette.text.primary}
                      fontWeight={600}
                      value={new Big(balanceCounter.available).round(counterCurrScale, Big.roundDown).toNumber()}
                      currency={counterCurrency}
                      scale={counterCurrScale}
                      withZeros
                    />
                  </MaskedModeWrapper>
                </Stack>
                {hasAnyFrozenBalance && (
                  <Box sx={{ fontSize: "0.8em", color: theme.palette.grey[600], minHeight: "20px" }}>
                    {Boolean(balanceCounter.frozen) && (
                      <>
                        {`${t("dcaBots.balances.frozen")}: ${getMaskedValue(
                          isMasked,
                          renderNumber(
                            new Big(balanceCounter.frozen).round(counterCurrScale, Big.roundDown).toNumber(),
                            counterCurrScale,
                            true
                          )
                        )}\u00a0${counterCurrency}`}
                      </>
                    )}
                  </Box>
                )}
              </Box>
              <PriceField
                autoComplete="off"
                allowZero
                precision={counterCurrScale}
                fullWidth={true}
                id="buyValue"
                name="buyValue"
                label={t("dcaBots.balances.amount")}
                value={formikBuy.values.buyValue ?? 0}
                onChange={formikBuy.handleChange}
                onBlur={formikBuy.handleBlur}
                error={Boolean(formikBuy.errors.buyValue)}
                helperText={formikBuy.errors.buyValue}
                disabled={isProcessingPayment}
                InputProps={{
                  endAdornment: <InputAdornment position="start">{counterCurrency}</InputAdornment>,
                }}
              />
              <Box sx={{ py: 2, px: 2 }}>
                <Slider
                  key="sliderBuy"
                  value={getSliderInputValue(formikBuy.values.buyValue)}
                  min={0}
                  step={getSliderStepSize(balanceCounter.available)}
                  max={balanceCounter.available}
                  onChange={onBuySliderChange}
                  valueLabelDisplay="off"
                  sx={{
                    color: "success.main",
                  }}
                />
              </Box>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={{ xs: 0, sm: 0.5 }}>
                <Typography>{t("dcaBots.balances.youWillGetApproximately")}</Typography>
                <SatsView
                  color={theme.palette.text.primary}
                  fontWeight={600}
                  value={getApproximateAmount(`${formikBuy.values.buyValue}`, `${askPrice}`, Side.BUY)}
                  currency={baseCurrency}
                  scale={baseCurrScale}
                  withZeros
                />
              </Stack>
              <Stack
                sx={{ fontSize: "0.8em", color: theme.palette.grey[600], minHeight: "20px" }}
                direction={"row"}
                flexWrap={"wrap"}
                columnGap={0.5}>
                <Typography fontSize={"inherit"}>{`${t("dcaBots.askPrice")}:`}</Typography>
                <Stack direction={"row"} spacing={0.5}>
                  <Typography fontWeight={600} fontSize={"inherit"}>
                    {`${renderNumber(askPrice, currencyPairPriceScale, true)}\u00a0${counterCurrency}`}
                  </Typography>
                  {getAskBidPrice(askPrice, counterCurrency)}
                </Stack>
              </Stack>
              {!isProcessingPayment && (
                <Box sx={{ display: "flex", justifyContent: "flex-end " }}>
                  <Button
                    variant="contained"
                    color="success"
                    onClick={() => formikBuy.handleSubmit()}
                    sx={{ mt: 2 }}>
                    {t("dcaBots.balances.confirmBuy")}
                  </Button>
                </Box>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                background: getColorByMode(theme, theme.palette.grey[100], theme.palette.grey[900]),
                p: 2,
                borderRadius: "10px",
              }}>
              <Box pb={2}>
                <Stack direction={{ xs: "column", sm: "row" }} spacing={{ xs: 0, sm: 0.5 }}>
                  <Typography>{`${t("dcaBots.balances.available")}:`}</Typography>
                  <MaskedModeWrapper color={theme.palette.text.primary} fontWeight={600}>
                    <SatsView
                      color={theme.palette.text.primary}
                      fontWeight={600}
                      value={new Big(balanceBase.available).round(baseCurrScale, Big.roundDown).toNumber()}
                      currency={baseCurrency}
                      scale={baseCurrScale}
                      withZeros
                    />
                  </MaskedModeWrapper>
                </Stack>
                {hasAnyFrozenBalance && (
                  <Box sx={{ fontSize: "0.8em", color: theme.palette.grey[600], minHeight: "20px" }}>
                    {Boolean(balanceBase.frozen) && (
                      <>
                        {`${t("dcaBots.balances.frozen")}: ${getMaskedValue(
                          isMasked,
                          renderNumber(
                            new Big(balanceBase.frozen).round(baseCurrScale, Big.roundDown).toNumber(),
                            baseCurrScale,
                            true
                          )
                        )}\u00a0${baseCurrency}`}
                      </>
                    )}
                  </Box>
                )}
              </Box>
              <PriceField
                autoComplete="off"
                allowZero
                precision={currencyPairBaseScale}
                fullWidth={true}
                id="sellValue"
                name="sellValue"
                label={t("dcaBots.balances.quantity")}
                value={formikSell.values.sellValue ?? 0}
                onChange={formikSell.handleChange}
                onBlur={formikSell.handleBlur}
                error={Boolean(formikSell.errors.sellValue)}
                helperText={formikSell.errors.sellValue}
                disabled={isProcessingPayment}
                InputProps={{
                  endAdornment: <InputAdornment position="start">{baseCurrency}</InputAdornment>,
                }}
              />
              <Box sx={{ py: 2, px: 2 }}>
                <Slider
                  key="sliderSell"
                  value={getSliderInputValue(formikSell.values.sellValue)}
                  min={0}
                  step={getSliderStepSize(balanceBase.available)}
                  max={balanceBase.available}
                  onChange={onSellSliderChange}
                  valueLabelDisplay="off"
                  sx={{
                    color: "error.main",
                  }}
                />
              </Box>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={{ xs: 0, sm: 0.5 }}>
                <Typography>{t("dcaBots.balances.youWillGetApproximately")}</Typography>
                <SatsView
                  color={theme.palette.text.primary}
                  fontWeight={600}
                  value={getApproximateAmount(`${formikSell.values.sellValue}`, `${bidPrice}`, Side.SELL)}
                  currency={counterCurrency}
                  scale={currencyPairPriceScale}
                  withZeros
                />
              </Stack>
              <Stack
                sx={{ fontSize: "0.8em", color: theme.palette.grey[600], minHeight: "20px" }}
                direction={"row"}
                flexWrap={"wrap"}
                columnGap={0.5}>
                <Typography fontSize={"inherit"}>{`${t("dcaBots.bidPrice")}:`}</Typography>
                <Stack direction={"row"} spacing={0.5}>
                  <Typography fontWeight={600} fontSize={"inherit"}>
                    {`${renderNumber(bidPrice, currencyPairPriceScale, true)}\u00a0${counterCurrency}`}
                  </Typography>
                  {getAskBidPrice(bidPrice, counterCurrency)}
                </Stack>
              </Stack>
              {!isProcessingPayment && (
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => formikSell.handleSubmit()}
                    sx={{ mt: 2 }}>
                    {t("dcaBots.balances.confirmSell")}
                  </Button>
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default InstantBuy;
