import React, { useState } from "react";

import { Drawer, Toolbar, Stack, Box, Typography } from "@mui/material";
import { Award03, Settings1, ShieldPlus } from "components/icons";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { useUserDataContext } from "context/UserDataContext";
import { useAPIBadgeTypes } from "hooks/useAPI";
import { useIsSmallScreen } from "hooks/useWindowSize";
import { useTranslation } from "react-i18next";
import { ISidebarItems } from "types/types";
import { getLocalStorageBooleanValue } from "utils";
import { renderNumber } from "utils/formatter";

import AccordionItem from "../AccordionItem";
import CreditBadge from "../CreditBadge";
import MenuItem from "../MenuItem";

interface Props {
  sidebarItems: ISidebarItems[];
  drawerOpen: boolean;
  drawerWidth: number;
  onItemClick: () => void;
  userLoggedIn: boolean;
  botsList: {
    enabledList: React.ReactNode[];
    disabledList: React.ReactNode[];
  };
}

const SidebarMenu: React.FC<Props> = ({
  sidebarItems,
  drawerOpen,
  drawerWidth,
  onItemClick,
  userLoggedIn,
  botsList,
}) => {
  const { colors } = useMUIThemeModeContext();
  const isSmallScreen = useIsSmallScreen();
  const { t } = useTranslation();
  const { subscriptionType, badgesCount, creditsBalance } = useUserDataContext();
  const { data: badgeTypesData } = useAPIBadgeTypes();
  const [enabledListExpanded, setEnabledListExpanded] = useState<boolean>(getLocalStorageBooleanValue("menuEnabledListExpanded", true));
  const [disabledListExpanded, setDisabledListExpanded] = useState<boolean>(getLocalStorageBooleanValue("menuDisabledListExpanded", false));
  const [infoExpanded, setInfoExpanded] = useState<boolean>(getLocalStorageBooleanValue("menuInfoExpanded", true));

  const handleListExpanded = (list: "enabledList" | "disabledList" | "info") => {
    if (list === "enabledList") {
      localStorage.setItem("menuEnabledListExpanded", (!enabledListExpanded).toString());
      setEnabledListExpanded((prev) => !prev);
    } else if (list === "disabledList") {
      localStorage.setItem("menuDisabledListExpanded", (!disabledListExpanded).toString());
      setDisabledListExpanded((prev) => !prev);
    } else if (list === "info") {
      localStorage.setItem("menuInfoExpanded", (!infoExpanded).toString());
      setInfoExpanded((prev) => !prev);
    }
  };

  const getBadgesCount = () => {
    if(!badgeTypesData || !badgesCount) return undefined;

    return `${badgesCount} / ${badgeTypesData?.badgeTypes.length}`;
  };

  return (
    <Drawer
      variant={!!isSmallScreen ? "temporary" : "persistent"}
      open={drawerOpen}
      onClose={onItemClick}
      sx={{
        width: drawerOpen ? drawerWidth : 0,
        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: "border-box" },
      }}>
      <Toolbar />
      <Box overflow={"auto"} flex={1}>
        <Stack direction={"column"} px={2} py={1.5}>
          <Stack direction={"column"} spacing={0.5}>
            {sidebarItems
              .filter((x) => !x.isSection)
              .map((item, i) => (
                <MenuItem key={"sidebar-item-" + i} icon={item.icon} title={t(item.id)} to={item.to} callback={onItemClick} withSelected />
              ))}
          </Stack>
          {userLoggedIn && (
            <>
              {botsList.enabledList.length ? (
                <AccordionItem
                  sx={{ pt: 1.5 }}
                  defaultExpanded
                  title={t("bottomNavigation.subMenu.STRATEGIES.title")}
                  expanded={enabledListExpanded}
                  onChange={() => handleListExpanded("enabledList")}>
                  <Stack direction={"column"} spacing={0.5}>
                    {botsList.enabledList}
                  </Stack>
                </AccordionItem>
              ) : undefined}
              {botsList.disabledList.length ? (
                <AccordionItem
                  sx={{ pt: 1.5 }}
                  title={t("bottomNavigation.subMenu.STRATEGIES.disabled")}
                  expanded={disabledListExpanded}
                  onChange={() => handleListExpanded("disabledList")}>
                  <Stack direction={"column"} spacing={0.5}>
                    {botsList.disabledList}
                  </Stack>
                </AccordionItem>
              ) : undefined}
              <AccordionItem
                sx={{ pt: 1.5 }}
                defaultExpanded
                title={t("bottomNavigation.info")}
                expanded={infoExpanded}
                onChange={() => handleListExpanded("info")}>
                <Stack direction={"column"} spacing={0.5}>
                  {sidebarItems
                    .filter((x) => x.isSection)
                    .map((item, i) => (
                      <MenuItem
                        key={"sidebar-item-info-" + i}
                        icon={item.icon}
                        title={t(item.id)}
                        to={item.to}
                        withSelected
                      />
                    ))}
                </Stack>
              </AccordionItem>
            </>
          )}
        </Stack>
      </Box>
      {userLoggedIn && (
        <Stack
          borderTop={`1px solid ${colors.gray200}`}
          bgcolor={colors.gray25}
          direction={"column"}
          spacing={0.5}
          p={2}>
          <Box px={2.2} py={1}>
            <Typography color={colors.gray900} fontWeight={600} fontSize={"0.875rem"}>
              {t("myAccount")}
            </Typography>
          </Box>
          <MenuItem
            icon={<ShieldPlus color="inherit" sx={{ fontSize: 20 }} />}
            title={t("subscription")}
            to={"/predplatne"}
            badge={subscriptionType}
            withSelected
          />
          <MenuItem
            icon={<CreditBadge size="small" />}
            title={t("credits")}
            to={"/stosaky"}
            badge={renderNumber(creditsBalance ?? 0)}
            withSelected
          />
          <MenuItem
            icon={<Award03 color="inherit" sx={{ fontSize: 20 }} />}
            title={t("badges")}
            to={"/stosaky#odznaky"}
            badge={getBadgesCount()}
            withSelected
          />
          <MenuItem
            icon={<Settings1 color="inherit" sx={{ fontSize: 20 }} />}
            title={t("settings")}
            to={"/nastaveni"}
            withSelected
          />
        </Stack>
      )}
    </Drawer>
  );
};

export default SidebarMenu;
