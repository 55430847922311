import { Box, Button, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const CustomButton = styled(Button)({
  width: "147px",
}) as typeof Button;

export const Content = styled(Box)({
  padding: "36px 0px 12px 0px",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
});

export const Title = styled(Typography)({
  color: "#023047",
  fontWeight: 600,
});
