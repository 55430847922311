import React, { useState } from "react";

import { Typography, Button, Stack } from "@mui/material";
import { creditsVouchersClaim } from "API/calls";
import BackdropLoading from "components/elements/BackdropLoading";
import CodeField from "components/elements/CodeField";
import Panel from "components/elements/Panel";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

interface IProps {
  refreshData?: () => void;
}

const CreditsVoucher = ({ refreshData }: IProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const formik = useFormik({
    initialValues: { voucher: "" },
    validationSchema: yup.object({
      voucher: yup
        .string()
        .required(t("fieldRequired"))
        .min(6, t("creditsVoucher.fieldMinLength")),
    }),
    onSubmit: (values) => {
      const { voucher } = values;
      voucher && handleCreditsVouchersClaim(voucher.toUpperCase());
    },
  });

  const handleCreditsVouchersClaim = async (voucher: string) => {
    setIsLoading(true);

    try {
      const { data } = await creditsVouchersClaim(voucher);
      enqueueSnackbar(t("creditsVoucher.success", { amount: data?.creditsClaimed }), { variant: "success" });
      formik.resetForm();
      refreshData?.();
    } catch (err: any) {
      if (err?.response?.status === 404) enqueueSnackbar(t("creditsVoucher.error404"), { variant: "error" });
      else if (err?.response?.status === 428) enqueueSnackbar(t("creditsVoucher.error428"), { variant: "error" });
      else enqueueSnackbar(t("commonError"), { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Panel sx={{ mb: 4, p: 3 }} id="voucher">
      <Typography variant="h5" component="h1" fontWeight={600} sx={{ pb: 1, color: "tertiary.main" }}>
        {t("creditsVoucher.title")}
      </Typography>
      <Typography variant="subtitle2" fontWeight={400} sx={{ pb: 2 }}>
        {t("creditsVoucher.subtitle")}
      </Typography>
      <Stack spacing={2} direction="row" >
        <CodeField
          autoComplete="off"
          disabled={isLoading}
          maxLength={6}
          customRegex={/[^A-Za-z0-9]/g}
          id={"voucher"}
          name={"voucher"}
          label={t("creditsVoucher.label")}
          value={formik.values.voucher ?? ""}
          error={formik.touched.voucher && Boolean(formik.errors.voucher)}
          helperText={formik.touched.voucher && formik.errors.voucher}
          onChange={formik.handleChange}
          inputProps={{ style: { textTransform: "uppercase" } }}
        />
        <Button onClick={() => formik.handleSubmit()} variant="contained" disabled={isLoading}>
          {t("creditsVoucher.button")}
        </Button>
      </Stack>
      <BackdropLoading open={isLoading} />
    </Panel>
  );
};

export default CreditsVoucher;
