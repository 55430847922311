import React from "react";

import { Link, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { LinkedIn } from "components/icons";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";

interface Props {
  photo: string;
  name: string;
  role: string;
  link: string;
}

const AuthorCard: React.FC<Props> = ({ photo, name, role, link }) => {
  const { colors } = useMUIThemeModeContext();
  const theme = useTheme();
  const isTinyScreen = useMediaQuery(theme.breakpoints.down(360));

  return (
    <Stack spacing={3} direction={"row"} alignItems="center">
      <Avatar src={photo} sx={{ width: isTinyScreen ? 80 : 116, height: isTinyScreen ? 80 : 116 }}>
        {name[0]}
      </Avatar>
      <Stack>
        <Typography fontWeight={600} fontSize={"1rem"} color={colors["deepMidnightBlue"]}>
          {name}
        </Typography>
        <Typography fontWeight={300} fontSize={"1rem"} color={colors["blueGray"]}>
          {role}
        </Typography>
        <Link sx={{ pt: 2 }} href={link} target="_blank" rel="noreferrer" color={colors["skyBlue"]}>
          <LinkedIn />
        </Link>
      </Stack>
    </Stack>
  );
};

export default AuthorCard;
