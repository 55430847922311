import { SvgIcon } from "@mui/material";

export const CoinsSwap = (props: any) => {
  return (
    <SvgIcon {...props}>
      <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="coins-swap-02" clipPath="url(#clip0_2305_20620)">
          <path
            id="Icon"
            d="M5.00033 4.99984L6.66699 3.33317M6.66699 3.33317L5.00033 1.6665M6.66699 3.33317H5.00033C3.15938 3.33317 1.66699 4.82555 1.66699 6.6665M15.0003 14.9998L13.3337 16.6665M13.3337 16.6665L15.0003 18.3332M13.3337 16.6665H15.0003C16.8413 16.6665 18.3337 15.1741 18.3337 13.3332M8.49118 5.4165C9.04623 3.25997 11.0039 1.6665 13.3337 1.6665C16.0951 1.6665 18.3337 3.90508 18.3337 6.6665C18.3337 8.99629 16.7402 10.9539 14.5837 11.509M11.667 13.3332C11.667 16.0946 9.42842 18.3332 6.66699 18.3332C3.90557 18.3332 1.66699 16.0946 1.66699 13.3332C1.66699 10.5717 3.90557 8.33317 6.66699 8.33317C9.42842 8.33317 11.667 10.5717 11.667 13.3332Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_2305_20620">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};
