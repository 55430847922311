import {
  Calculator,
  Certificate,
  CoinsSwap,
  GraduationHat,
  Laptop,
  PlaySquare,
  Tag,
  Users,
  HomeLine,
} from "components/icons";
import { ISidebarItems } from "types/types";

export function userSidebarItems(userLoggedIn: boolean) {
  const defaultMenu: ISidebarItems[] = [
    {
      id: "home",
      to: "/",
      icon: <Laptop color="inherit" sx={{ fontSize: 20 }} />,
    },
    {
      id: "guide",
      to: "/proc-bitcoin/jak-vam-stosuj-cz-pomuze",
      icon: <PlaySquare color="inherit" sx={{ fontSize: 20 }} />,
    },
    {
      id: "whyBitcoinSidebarLink",
      to: "/proc-bitcoin",
      icon: <GraduationHat color="inherit" sx={{ fontSize: 20 }} />,
    },
    {
      id: "pricing",
      to: "/cenik",
      icon: <Tag color="inherit" sx={{ fontSize: 20 }} />,
    },
    {
      id: "currencyPairs",
      to: "/menove-pary",
      icon: <CoinsSwap color="inherit" sx={{ fontSize: 20 }} />,
    },
    {
      id: "dcaCalc",
      to: "/#dca-kalkulacka",
      icon: <Calculator color="inherit" sx={{ fontSize: 20 }} />,
    },
    {
      id: "communityAndContact",
      to: "/#kontakt",
      icon: <Users color="inherit" sx={{ fontSize: 20 }} />,
    },
    {
      id: "terms",
      to: "/podminky",
      icon: <Certificate color="inherit" sx={{ fontSize: 20 }} />,
    },
  ];

  const menu: ISidebarItems[] = [
    {
      id: "overview",
      to: "/dashboard",
      icon: <HomeLine color="inherit" sx={{ fontSize: 20 }} />,
    },
    {
      id: "home",
      to: "/",
      icon: <Laptop color="inherit" sx={{ fontSize: 20 }} />,
      isSection: true,
    },
    {
      id: "guide",
      to: "/proc-bitcoin/jak-vam-stosuj-cz-pomuze",
      icon: <PlaySquare color="inherit" sx={{ fontSize: 20 }} />,
      isSection: true,
    },
    {
      id: "whyBitcoinSidebarLink",
      to: "/proc-bitcoin",
      icon: <GraduationHat color="inherit" sx={{ fontSize: 20 }} />,
      isSection: true,
    },
    {
      id: "dcaCalc",
      to: "/#dca-kalkulacka",
      icon: <Calculator color="inherit" sx={{ fontSize: 20 }} />,
      isSection: true,
    },
    {
      id: "communityAndContact",
      to: "/#kontakt",
      icon: <Users color="inherit" sx={{ fontSize: 20 }} />,
      isSection: true,
    },
    {
      id: "terms",
      to: "/podminky",
      icon: <Certificate color="inherit" sx={{ fontSize: 20 }} />,
      isSection: true,
    },
  ];

  return userLoggedIn ? menu : defaultMenu;
}
