import { useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import {
  Alert,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Legend from "components/elements/CandleChart/Legend";
import { ErrorBoundary } from "react-error-boundary";
import { Trans } from "react-i18next";

import Chart, { IChartProps } from ".";

interface IProps extends IChartProps {
  loading: boolean;
  title: string;
  subtitle?: React.ReactNode;
  pointsOriginal: IChartProps["points"];
  fixedTooltip?: boolean;
}

const ChartWrapper = ({ loading, title, subtitle, lineSeriesOptions, valueUnit, points, pointsOriginal, tooltipRender, tooltipScale, fixedTooltip }: IProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [fullScreen, setFullScreen] = useState(false);

  return (
    <ErrorBoundary
      FallbackComponent={() => (
        <Alert severity="error">
          <Trans i18nKey="backtestDcaStrategy.errorBoundaryChart" values={{ title }} />
        </Alert>
      )}>
      <Stack sx={{ pb: 1 }}>
        <Stack direction={"row"} justifyContent={"space-between"} alignItems={subtitle ? "start" : "center"} pb={1}>
          <Stack>
            <Typography variant="h6" component="h2" fontWeight={400} sx={{ color: "tertiary.main" }}>
              {title}
            </Typography>
            {subtitle && (
              <Typography variant="body2" component="h2" color={"text.secondary"}>
                {subtitle}
              </Typography>
            )}
          </Stack>
          <Stack spacing={0.5} direction={"row"}>
            {!isMobile && (
              <IconButton size="small" onClick={() => setFullScreen(true)}>
                <FullscreenIcon />
              </IconButton>
            )}
          </Stack>
        </Stack>
        <Box height={"250px"}>
          {loading ? (
            <Skeleton sx={{ height: "100%" }} animation="wave" variant="rectangular" />
          ) : (
            <Chart
              lineSeriesOptions={lineSeriesOptions}
              points={points}
              valueUnit={valueUnit}
              tooltipScale={tooltipScale}
              tooltipRender={tooltipRender}
              fixedTooltip={fixedTooltip}
            />
          )}
        </Box>
        {!loading && lineSeriesOptions && (
          <Stack direction={"column"} spacing={0.5}>
            {lineSeriesOptions.map((lineSerie, index) => (
              <Legend key={`${title}-${index}`} color={lineSerie.color} title={lineSerie.label} />
            ))}
          </Stack>
        )}
        <Dialog open={fullScreen} fullWidth={true} maxWidth={"xl"} onClose={() => setFullScreen(false)}>
          <DialogTitle sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            {title}
            <IconButton edge="end" size="small" color="inherit" onClick={() => setFullScreen(false)}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent sx={{ height: `${(window.innerHeight / 4) * 3}px` }}>
            <Chart
              lineSeriesOptions={lineSeriesOptions}
              points={pointsOriginal}
              valueUnit={valueUnit}
              tooltipScale={tooltipScale}
              tooltipRender={tooltipRender}
            />
          </DialogContent>
          <DialogActions sx={{ justifyContent: "start", px: 2 }}>
            <Stack direction={"row"} spacing={2}>
              {lineSeriesOptions.map((lineSerie, index) => (
                <Legend key={`${title}-${index}`} color={lineSerie.color} title={lineSerie.label} />
              ))}
            </Stack>
          </DialogActions>
        </Dialog>
      </Stack>
    </ErrorBoundary>
  );
};

export default ChartWrapper;
