import { SvgIcon } from "@mui/material";

export const Tag = (props: any) => {
  return (
    <SvgIcon {...props}>
      <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="tag-01">
          <path
            id="Icon"
            d="M6.66699 6.6665H6.67533M1.66699 4.33317L1.66699 8.06193C1.66699 8.46958 1.66699 8.67341 1.71304 8.86522C1.75387 9.03528 1.82121 9.19786 1.91259 9.34698C2.01566 9.51517 2.15979 9.6593 2.44804 9.94755L8.83856 16.3381C9.82861 17.3281 10.3236 17.8231 10.8944 18.0086C11.3966 18.1718 11.9374 18.1718 12.4395 18.0086C13.0104 17.8231 13.5054 17.3281 14.4954 16.3381L16.3386 14.4949C17.3286 13.5049 17.8236 13.0099 18.0091 12.439C18.1722 11.9369 18.1722 11.3961 18.0091 10.894C17.8236 10.3231 17.3286 9.82812 16.3386 8.83808L9.94804 2.44755C9.65979 2.1593 9.51566 2.01517 9.34746 1.9121C9.19834 1.82072 9.03577 1.75338 8.86571 1.71255C8.6739 1.6665 8.47007 1.6665 8.06242 1.6665L4.33366 1.6665C3.40024 1.6665 2.93353 1.6665 2.57701 1.84816C2.26341 2.00795 2.00844 2.26292 1.84865 2.57652C1.66699 2.93304 1.66699 3.39975 1.66699 4.33317ZM7.08366 6.6665C7.08366 6.89662 6.89711 7.08317 6.66699 7.08317C6.43687 7.08317 6.25033 6.89662 6.25033 6.6665C6.25033 6.43639 6.43687 6.24984 6.66699 6.24984C6.89711 6.24984 7.08366 6.43639 7.08366 6.6665Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    </SvgIcon>
  );
};
