import { useContext, createContext } from "react";

export type MUIThemeMode = "dark" | "light" | "device";
interface IColors { [key: string]: string };

export interface IMUIThemeModeContext {
  changeMode: (mode: MUIThemeMode, save?: boolean) => void;
  loadStoredMode: () => void;
  mode: MUIThemeMode;
  colors: IColors;
}

const defaultState: IMUIThemeModeContext = {
  changeMode: () => null,
  loadStoredMode: () => null,
  mode: "light",
  colors: {},
};

export const MUIThemeModeContext = createContext<IMUIThemeModeContext>(defaultState);
export const useMUIThemeModeContext = () => useContext(MUIThemeModeContext);
