import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";

interface IMediaQueryGridProps { disabled?: boolean };

export const MediaQueryGrid = styled(Grid)<IMediaQueryGridProps>(({ disabled }) => ({
  "@media (max-width: 1199px)": {
    display: disabled ? "block" : "none",
  },
}));

export const MediaQueryGridReverse = styled(Grid)<IMediaQueryGridProps>(({ disabled }) => ({
  display: disabled ? "block" : "none",
  "@media (min-width: 1200px)": {
    display: "none",
  },
}));
