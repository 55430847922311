// https://github.com/vercel/swr/blob/master/examples/axios-typescript/libs/useRequest.ts
import { axiosApiInstance } from "API";
import { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import useSWR, { SWRConfiguration, SWRResponse, mutate as swrMutate } from "swr";
import { getApiPath } from "utils";

export type GetRequest = AxiosRequestConfig | null;

interface Return<Data, Error>
  extends Pick<SWRResponse<AxiosResponse<Data>, AxiosError<Error>>, "isValidating" | "error" | "mutate"> {
  data: Data | undefined;
  response: AxiosResponse<Data> | undefined;
  clearCache: () => void;
}

export interface Config<Data = unknown, Error = unknown>
  extends Omit<SWRConfiguration<AxiosResponse<Data>, AxiosError<Error>>, "fallbackData"> {
  fallbackData?: Data;
}

export default function useRequest<Data = unknown, Error = unknown>(
  request: GetRequest,
  { fallbackData, ...config }: Config<Data, Error> = {}
): Return<Data, Error> {
  if (request) {
    request.url = getApiPath(request.url ?? "");
    request.withCredentials = true;
  }
  const key = request && JSON.stringify(request);

  const {
    data: response,
    error,
    isValidating,
    mutate,
  } = useSWR<AxiosResponse<Data>, AxiosError<Error>>(
    key,
    /**
     * NOTE: Typescript thinks `request` can be `null` here, but the fetcher
     * function is actually only called by `useSWR` when it isn't.
     */
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    () => axiosApiInstance.request<Data>(request!),
    {
      ...config,
      fallbackData: fallbackData && {
        status: 200,
        statusText: "InitialData",
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        config: request!,
        headers: {},
        data: fallbackData,
      },
    }
  );

  return {
    data: response && response.data,
    response,
    error,
    isValidating,
    mutate,
    clearCache: () => swrMutate(key, null, true),
  };
}
