import { Box, useTheme } from "@mui/material";
import { ApexOptions } from "apexcharts";
import { getMaskedValue } from "components/elements/MaskedModeWrapper";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { useUserDataContext } from "context/UserDataContext";
import dayjs from "dayjs";
import isToday from "dayjs/plugin/isToday";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import { IBaseCurrencyProfitChart } from "types/types";
import { renderPrice } from "utils/formatter";

dayjs.extend(isToday);

interface Props {
  chartData: IBaseCurrencyProfitChart;
  isMobile: boolean;
  baseCurrency: string;
  baseCurrDisplayedScale: number;
}

const PriceChart = ({ chartData, isMobile, baseCurrency, baseCurrDisplayedScale }: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { isMasked } = useUserDataContext();
  const { colors } = useMUIThemeModeContext();

  const labels = chartData.points.map((item) =>
    Intl.DateTimeFormat("cs-CZ").format(new Date(dayjs(item.date).format()))
  );
  const min = 0;

  const series = [
    {
      name: t("profit"),
      data: chartData.points.map((item) => item.profit),
    },
  ];
  const strokeCurve: ApexStroke["curve"] = ["stepline"];
  const strokeWidth = [4];
  const chartColors = [theme.palette.primary.main];

  // chart config
  const options: ApexOptions = {
    theme: { mode: theme.palette.mode },
    tooltip: {
      followCursor: true,
      inverseOrder: true,
      y: {
        formatter: (val: number, _opts?: any) => getMaskedValue(isMasked, renderPrice(val, baseCurrency, baseCurrDisplayedScale)),
      },
    },
    colors: chartColors,
    chart: {
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      background: "transparent",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: strokeCurve,
      width: strokeWidth,
    },
    grid: {
      row: {
        colors: [colors.legacyGray100, "transparent"],
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: labels,
      labels: {
        hideOverlappingLabels: true,
        rotate: -30,
        show: !isMobile,
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      show: !isMobile,
      labels: {
        formatter: (val: any) => getMaskedValue(isMasked, renderPrice(val, baseCurrency, baseCurrDisplayedScale, true)),
      },
      min,
    },
  };

  return (
    <Box sx={{ pr: { xs: 0, md: 2 }, textAlign: "center" }}>
      <ReactApexChart options={options} series={series} type="line" width={"100%"} height={400} />
    </Box>
  );
};

export default PriceChart;
