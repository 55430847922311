import { SvgIcon } from '@mui/material';

export const AwardTrophyStar = (props: any) => {
  return (
    <SvgIcon {...props}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.9961 15.75V18.75" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M18.3611 23.25C17.8958 21.9337 17.0338 20.794 15.8939 19.9879C14.754 19.1819 13.3922 18.749 11.9961 18.749C10.6 18.749 9.2382 19.1819 8.09829 19.9879C6.95838 20.794 6.09642 21.9337 5.6311 23.25H18.3611Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M16.5 0.75H20.25C20.6478 0.75 21.0294 0.908035 21.3107 1.18934C21.592 1.47064 21.75 1.85218 21.75 2.25V6C21.75 8.58586 20.7228 11.0658 18.8943 12.8943C17.0658 14.7228 14.5859 15.75 12 15.75C9.41414 15.75 6.93419 14.7228 5.10571 12.8943C3.27723 11.0658 2.25 8.58586 2.25 6V2.25C2.25 1.85218 2.40804 1.47064 2.68934 1.18934C2.97064 0.908035 3.35218 0.75 3.75 0.75H7.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12.53 2.57298L13.779 5.03098H15.906C16.0231 5.02812 16.1383 5.06066 16.2366 5.12434C16.3349 5.18801 16.4117 5.27987 16.457 5.3879C16.5023 5.49592 16.5139 5.61509 16.4903 5.72983C16.4668 5.84456 16.4092 5.94952 16.325 6.03098L14.372 7.94898L15.454 10.434C15.5017 10.5478 15.5125 10.6737 15.4847 10.794C15.457 10.9142 15.3922 11.0227 15.2995 11.1041C15.2068 11.1856 15.0908 11.2359 14.968 11.2478C14.8452 11.2598 14.7217 11.233 14.615 11.171L12 9.69998L9.38501 11.171C9.2783 11.233 9.15482 11.2598 9.032 11.2478C8.90918 11.2359 8.79324 11.1856 8.70053 11.1041C8.60781 11.0227 8.54302 10.9142 8.5153 10.794C8.48758 10.6737 8.49832 10.5478 8.54601 10.434L9.62801 7.94898L7.67501 6.02698C7.59086 5.94552 7.53324 5.84056 7.50969 5.72583C7.48614 5.61109 7.49776 5.49192 7.54302 5.3839C7.58828 5.27587 7.66509 5.18401 7.76339 5.12034C7.86169 5.05666 7.97692 5.02412 8.09401 5.02698H10.221L11.47 2.57298C11.5196 2.47499 11.5954 2.39267 11.689 2.33515C11.7825 2.27764 11.8902 2.24719 12 2.24719C12.1098 2.24719 12.2175 2.27764 12.3111 2.33515C12.4046 2.39267 12.4804 2.47499 12.53 2.57298V2.57298Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    </SvgIcon>
  );
}
