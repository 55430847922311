import { DISCORD, EMAIL, INSTAGRAM, TWITTER } from "constants/links";

import { Typography, Link, Box, useTheme, Stack, Avatar, alpha, useMediaQuery } from "@mui/material";
import imageDaliborFujerik from "assets/images/team/dalibor-fujerik.jpg";
import imageJakubMinarovic from "assets/images/team/jakub-minarovic.jpg";
import imageLubosKovarik from "assets/images/team/lubos-kovarik.png";
import imageLukasHopp from "assets/images/team/lukas-hopp.jpg";
import AuthorCard from "components/elements/AuthorCard";
import { Discord as DiscordIcon, Instagram, LinkedIn, Message, X } from "components/icons";
import { HeaderButton } from "components/layouts/Default/Header";
import HomeBox from "components/modules/HomeBox";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { t } from "i18next";
import { Trans, useTranslation } from "react-i18next";

const authors = [
  {
    photo: imageLukasHopp,
    name: "Lukáš Hopp",
    role: t("homepage.authorCofounder"),
    link: "https://www.linkedin.com/in/lukáš-hopp-29a45897",
  },
  {
    photo: imageJakubMinarovic,
    name: "Jakub Minarovič",
    role: t("homepage.authorBackend"),
    link: "https://www.linkedin.com/in/jakub-minarovi%C4%8D-8baa1976",
  },
  {
    photo: imageDaliborFujerik,
    name: "Dalibor Fujerík",
    role: t("homepage.authorFrontend"),
    link: "https://www.linkedin.com/in/dalibor-fujerík-18295a185",
  },
];

const founder = {
  photo: imageLubosKovarik,
  name: "Luboš Kovařík",
  role: t("homepage.authorFounder"),
  linkendLink: "https://www.linkedin.com/in/kovariklubos/",
  xLink: TWITTER,
  instagramLink: INSTAGRAM,
  contact: EMAIL,
};

const Contact = () => {
  const { t } = useTranslation();
  const { colors } = useMUIThemeModeContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTinyScreen = useMediaQuery(theme.breakpoints.down(360));

  const founderCard = () => (
    <Stack spacing={3} direction={"row"} justifyContent="center" alignItems="center">
      <Avatar
        src={imageLubosKovarik}
        sx={{
          width: isMobile ? (isTinyScreen ? 80 : 128) : 164,
          height: isMobile ? (isTinyScreen ? 80 : 128) : 164,
        }}>
        {founder.name}
      </Avatar>
      <Stack>
        <Typography fontWeight={600} fontSize={{ xs: "1rem", md: "1.5rem" }} color={"white"}>
          {founder.name}
        </Typography>
        <Typography fontWeight={600} fontSize={{ xs: "0.75rem", md: "1rem" }} color={"primary"}>
          {founder.role}
        </Typography>
        <Stack pt={2} spacing={2} direction={"row"}>
          <Link href={founder.instagramLink} target="_blank" rel="noreferrer" color={colors["skyBlue"]}>
            <Instagram />
          </Link>
          <Link href={founder.xLink} target="_blank" rel="noreferrer" color={colors["skyBlue"]}>
            <X />
          </Link>
          <Link href={founder.linkendLink} target="_blank" rel="noreferrer" color={colors["skyBlue"]}>
            <LinkedIn />
          </Link>
        </Stack>
      </Stack>
    </Stack>
  );

  return (
    <HomeBox
      id={"kontakt"}
      title={t("homepage.contact.ourTeam")}
      name={t("homepage.contact.title")}
      p={{ xs: 1, md: 8 }}>
      <Stack pt={{ xs: 2, md: 6 }} direction={{ xs: "column", lg: "row" }} width={"100%"} textAlign={"start"}>
        <Box
          width={"100%"}
          borderRadius={"24px"}
          bgcolor={colors["deepMidnightBlue"]}
          sx={{ backgroundImage: "linear-gradient(90deg, rgba(17,24,39,1) 0%, rgba(138,149,175,0.2) 100%)" }}
          p={{ xs: 2, md: 6 }}
          color={"white"}>
          <Stack spacing={4}>
            {founderCard()}
            <Typography fontSize={{ xs: "0.875rem", md: "1.125rem" }} fontWeight={300} color={colors["silverGray"]}>
              <Trans i18nKey="homepage.contact.founderInfo" />
            </Typography>
            <Box p={3} borderRadius={"12px"} border={`1px solid ${alpha("#FFFFFF", 0.1)}`}>
              <Stack spacing={2} textAlign={"center"} alignItems={"center"}>
                <Typography color={"white"} fontWeight={500} fontSize={{ xs: "0.875rem", md: "1.125rem" }}>
                  {t("homepage.contact.founderConsultation")}
                </Typography>
                <Stack direction={"row"} spacing={1.5} alignItems={"center"}>
                  <Message />
                  <Typography
                    color={theme.palette.primary.main}
                    fontWeight={600}
                    fontSize={{ xs: "0.875rem", md: "1.125rem" }}>
                    <Link href={`mailto:${founder.contact}`} underline="none">
                      {founder.contact}
                    </Link>
                  </Typography>
                </Stack>
                <Typography color={"white"} fontWeight={300} fontSize={"0.75rem"}>
                  <Trans
                    i18nKey="homepage.contact.reportProblem"
                    components={{
                      a: (
                        <Link href={DISCORD} target="_blank" rel="noreferrer" color={"inherit"} fontSize={"0.75rem"} />
                      ),
                    }}
                  />
                </Typography>
              </Stack>
            </Box>
          </Stack>
        </Box>
        <Stack width={"100%"} spacing={4} px={{ xs: 0, md: 8 }} pb={2} pt={3} alignItems={"center"}>
          <Box p={3} borderRadius={"24px"} bgcolor={colors["lightGray"]}>
            <Stack spacing={3}>
              <DiscordIcon height={32} width={32} style={{ width: 32, height: 32, color: "black" }} />
              <Typography fontSize={"1.125rem"} fontWeight={300} color={colors["blueGray"]}>
                <Trans i18nKey="homepage.contact.discord" />
              </Typography>
              <Box display={"flex"}>
                <HeaderButton variant={"contained"} size="small" to={DISCORD} target="_blank">
                  {t("homepage.contact.join")}
                </HeaderButton>
              </Box>
            </Stack>
          </Box>
          <Stack spacing={2} width={"fit-content"}>
            {authors.map((item, index) => (
              <AuthorCard key={index} photo={item.photo} name={item.name} role={item.role} link={item.link} />
            ))}
          </Stack>
        </Stack>
      </Stack>
    </HomeBox>
  );
};

export default Contact;
