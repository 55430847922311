import React, { useState } from "react";

import { useMediaQuery } from "@mui/material";
import { ThemeProvider as MUIThemeProvider, responsiveFontSizes, createTheme } from "@mui/material/styles";
import { MUIThemeModeContext, MUIThemeMode } from "context/MUIThemeModeContext";
import { ConfirmProvider } from "material-ui-confirm";

import confirmOptions from "./confirmOptions";
import { getThemeOptions, getColors } from "./options";

interface IProps {
  children: React.ReactNode;
}

const isMUIThemeMode = (mode: string | null) => mode === "dark" || mode === "light" || mode === "device";

const Theme = ({ children }: IProps) => {
  const uiTheme = localStorage.getItem("uiTheme");
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const [mode, setMode] = useState<MUIThemeMode>(isMUIThemeMode(uiTheme) ? (uiTheme as MUIThemeMode) : "device");

  const handleChangeMode = (mode: MUIThemeMode, save = true) => {
    setMode(mode);
    save && localStorage.setItem("uiTheme", mode);
  };

  const handleLoadStoredMode = () => {
    const storedMode = localStorage.getItem("uiTheme");

    if (isMUIThemeMode(storedMode) && storedMode !== mode) setMode(storedMode as MUIThemeMode);
  }

  const theme = React.useMemo(() => {
    const newTheme = createTheme(getThemeOptions(mode === "device" ? (prefersDarkMode ? "dark" : "light") : mode));

    // Add custom shadows
    newTheme.shadows[24] = "0px 3px 7px 0px #100D1C0D, 0px 12px 12px 0px #100D1C0A, 0px 28px 17px 0px #100D1C08, 0px 50px 20px 0px #100D1C03, 0px 77px 22px 0px #100D1C00";

    return newTheme;
  }, [mode, prefersDarkMode]);

  const colors = React.useMemo(
    () => getColors(mode === "device" ? (prefersDarkMode ? "dark" : "light") : mode),
    [mode, prefersDarkMode]
  );

  return (
    <MUIThemeModeContext.Provider value={{ changeMode: handleChangeMode, loadStoredMode: handleLoadStoredMode, mode, colors }}>
      <MUIThemeProvider theme={responsiveFontSizes(theme)}>
        <ConfirmProvider defaultOptions={confirmOptions}>{children}</ConfirmProvider>
      </MUIThemeProvider>
    </MUIThemeModeContext.Provider>
  );
};

export default Theme;
