import { SvgIcon } from "@mui/material";

export const Edit = (props: any) => {
  return (
    <SvgIcon {...props}>
      <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="edit-02">
          <path
            id="Icon"
            d="M15.0007 8.33326L11.6673 4.99993M2.08398 17.9166L4.90429 17.6032C5.24887 17.5649 5.42115 17.5458 5.58219 17.4937C5.72506 17.4474 5.86102 17.3821 5.98639 17.2994C6.1277 17.2062 6.25027 17.0836 6.49542 16.8385L17.5007 5.83326C18.4211 4.91279 18.4211 3.4204 17.5007 2.49993C16.5802 1.57945 15.0878 1.57945 14.1673 2.49992L3.16209 13.5052C2.91694 13.7503 2.79436 13.8729 2.70118 14.0142C2.61851 14.1396 2.55316 14.2755 2.50691 14.4184C2.45478 14.5794 2.43564 14.7517 2.39735 15.0963L2.08398 17.9166Z"
            stroke="currentColor"
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    </SvgIcon>
  );
};
