export const X = () => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 0.949911H7.09819L13.6592 9.92987L21.8261 0.744141L23.7666 0.778436L14.6267 11.2217L24 24.0486H16.9046L10.712 15.6802L3.14694 24.2543H1.2374L9.77267 14.457L0 0.949911ZM6.42888 2.27604H2.6801L17.678 22.6881H21.3593L6.42888 2.27604Z"
        fill="#D1D5DB"
      />
    </svg>
  );
};
