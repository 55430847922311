import { SvgIcon } from "@mui/material";

export const Diamond = (props: any) => {
  return (
    <SvgIcon {...props}>
      <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="diamond-01">
          <path
            id="Icon"
            d="M2.08295 7.5H17.9163M8.33295 2.5L6.66628 7.5L9.99961 17.0833L13.3329 7.5L11.6663 2.5M10.5118 16.8854L17.9773 7.92679C18.1038 7.77496 18.1671 7.69905 18.1912 7.6143C18.2126 7.53959 18.2126 7.46041 18.1912 7.38569C18.1671 7.30095 18.1038 7.22504 17.9773 7.07321L14.3662 2.73988C14.2927 2.6517 14.256 2.60762 14.2109 2.57592C14.171 2.54784 14.1265 2.52698 14.0794 2.51431C14.0262 2.5 13.9688 2.5 13.854 2.5H6.1452C6.03042 2.5 5.97303 2.5 5.91985 2.51431C5.87273 2.52698 5.82821 2.54784 5.7883 2.57592C5.74327 2.60762 5.70653 2.6517 5.63305 2.73988L2.02194 7.07321C1.89541 7.22504 1.83215 7.30095 1.80798 7.38569C1.78666 7.46041 1.78666 7.53959 1.80798 7.6143C1.83215 7.69904 1.89541 7.77496 2.02194 7.92679L9.48747 16.8854C9.66335 17.0965 9.75129 17.202 9.85657 17.2405C9.94894 17.2743 10.0503 17.2743 10.1427 17.2405C10.2479 17.202 10.3359 17.0965 10.5118 16.8854Z"
            stroke="currentColor"
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    </SvgIcon>
  );
};
