import { SvgIcon } from "@mui/material";

export const PlaySquare = (props: any) => {
  return (
    <SvgIcon {...props}>
      <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="play-square">
          <g id="Icon">
            <path
              d="M7.91667 7.47111C7.91667 7.07337 7.91667 6.8745 7.99978 6.76348C8.07222 6.66673 8.18309 6.6062 8.30365 6.59759C8.44199 6.58771 8.60927 6.69524 8.94384 6.91032L12.8777 9.43921C13.168 9.62585 13.3131 9.71917 13.3633 9.83783C13.4071 9.9415 13.4071 10.0585 13.3633 10.1622C13.3131 10.2808 13.168 10.3742 12.8777 10.5608L8.94384 13.0897C8.60927 13.3048 8.44199 13.4123 8.30365 13.4024C8.18309 13.3938 8.07222 13.3333 7.99978 13.2365C7.91667 13.1255 7.91667 12.9266 7.91667 12.5289V7.47111Z"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M2.5 6.5C2.5 5.09987 2.5 4.3998 2.77248 3.86502C3.01217 3.39462 3.39462 3.01217 3.86502 2.77248C4.3998 2.5 5.09987 2.5 6.5 2.5H13.5C14.9001 2.5 15.6002 2.5 16.135 2.77248C16.6054 3.01217 16.9878 3.39462 17.2275 3.86502C17.5 4.3998 17.5 5.09987 17.5 6.5V13.5C17.5 14.9001 17.5 15.6002 17.2275 16.135C16.9878 16.6054 16.6054 16.9878 16.135 17.2275C15.6002 17.5 14.9001 17.5 13.5 17.5H6.5C5.09987 17.5 4.3998 17.5 3.86502 17.2275C3.39462 16.9878 3.01217 16.6054 2.77248 16.135C2.5 15.6002 2.5 14.9001 2.5 13.5V6.5Z"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
};
