import * as Icons from "../../../assets/currencies/color";

interface IProps extends React.SVGProps<SVGSVGElement> { name: string }

const CurrencyIcon = ({ name, ...props }: IProps) => {
  const IconComponent = Icons[name as keyof typeof Icons];

  if(!IconComponent) return null;

  return <IconComponent {...props} />;
};

export default CurrencyIcon;
