import { IconButton, Typography, Box, Grid } from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";

export const InfoBox = styled(Box)(({ color }: { color: string }) => ({
  backgroundColor: alpha(color, 0.1),
  borderRadius: "10px",
  color: color,
  display: "flex",
  alignItems: "center",
  textTransform: "uppercase",
}));

export const CustomIconButton = styled(IconButton)({
  backgroundColor: "rgba(0, 0, 0, 0.54)",
  color: "white",
  marginLeft: 5,
  padding: 2,

  "& svg": {
    fontSize: 12,
  },
});

export const TypographyWithTooltip = styled(Typography)({
  display: "flex",
  alignItems: "center",
});

export const InfoItemGrid = styled(Grid)({
  display: "flex",
});

export const MainWithChartGrid = styled(Grid)(() => {
  const { colors } = useMUIThemeModeContext();

  return {
    borderBottom: `2px solid ${colors.legacyGray50}`,
    display: "flex",
    justifyContent: "center",
    padding: "16px 0px 16px 16px",
  };
});
