import React, { useEffect, useRef, useState } from "react";

import { Box, CircularProgress } from "@mui/material";
import Big from "big.js";
import CenterWrapper from "components/elements/CenterWrapper";
import { iOS, increaseByPercentage, percentageChange } from "utils";

import { CustomSlider } from "../../styles";

interface IProps {
  id: string;
  precision: number;
  value: number;
  closePrice?: number;
  sliderMax: number;
  increase: number;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  setFieldTouched: (field: string, touched?: boolean, shouldValidate?: boolean | undefined) => void;
  disabled?: boolean;
}

const LimitInput = ({
  id,
  precision,
  value,
  closePrice,
  sliderMax,
  increase,
  setFieldValue,
  setFieldTouched,
  disabled,
}: IProps) => {
  const [sliderDefault, setSliderDefault] = useState<number>();
  const sliderValue = useRef<number>();

  useEffect(() => {
    if (closePrice && value > 0 && !sliderDefault) setSliderDefault(getSliderDefaultValue(value));
  }, [closePrice, value]);

  useEffect(() => {
    if (closePrice && sliderValue.current) {
      const price = increaseByPercentage(closePrice, sliderValue.current);
      setFieldValue(id, new Big(price).round(precision, Big.roundDown).toNumber());
    }
  }, [closePrice]);

  const getSliderDefaultValue = (current: number) => {
    const defaultValue = Math.abs(percentageChange(closePrice ?? 0, Number(`${current}`.replace(",", "."))));

    return isNaN(defaultValue) ? 0 : new Big(defaultValue).round(0).toNumber();
  };

  const handleSliderChange = (_event: React.SyntheticEvent | Event, newValue: number | Array<number>) => {
    // Slider component workaround for Safari https://github.com/mui/material-ui/issues/31869
    if (_event.type === "mouseup" && iOS()) return;

    const price = increaseByPercentage(closePrice ?? 0, newValue as number);
    setFieldValue(id, new Big(price).round(precision, Big.roundDown).toNumber());

    sliderValue.current = newValue as number;
    setFieldTouched(id, true);
  };

  const getDefaultValue = () => sliderDefault && (increase ? sliderDefault : sliderDefault * -1);

  return (
    <>
      {typeof sliderDefault === "number" ? (
        <Box width={"100%"}>
          <CustomSlider
            increase={increase}
            onChangeCommitted={handleSliderChange}
            defaultValue={getDefaultValue()}
            min={increase ? 1 : sliderMax}
            max={increase ? sliderMax : -1}
            step={1}
            track={increase ? "normal" : "inverted"}
            disabled={disabled}
            valueLabelDisplay={"on"}
            valueLabelFormat={(n: number) => `${increase ? "+" : ""}${n}%`}
            marks={[
              {
                value: sliderMax,
                label: `${increase ? "+" : ""}${sliderMax} %`,
              },
            ]}
          />
        </Box>
      ) : (
        <CenterWrapper>
          <CircularProgress color="primary" />
        </CenterWrapper>
      )}
    </>
  );
};

export default LimitInput;
