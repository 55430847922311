import {
  Button,
  Stack,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  stepLabelClasses,
  stepIconClasses,
  Box,
  useTheme,
  darken,
  lighten,
} from "@mui/material";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { useTranslation } from "react-i18next";
import { getColorByMode } from "utils";

import { CustomConnector } from "./styles";

const BACKGROUND_COLOR = "#F1F5F9";
const ACTIVE_COLOR = "#334155";
const NORMAL_COLOR = "#94A3B8";
const STEP_ICON_TEXT_COLOR = "#E2E8F0";

interface IProps {
  steps: string[];
  activeStep?: number;
  getContent: (index: number) => void;
  backStep: () => void;
  nextStep: () => void;
  submit: () => void;
  disableNextStep: (index: number) => boolean;
}

const GridStepper = ({ steps, activeStep, getContent, backStep, disableNextStep, nextStep, submit }: IProps) => {
  const { t } = useTranslation<string>();
  const theme = useTheme();
  const { colors } = useMUIThemeModeContext();

  return (
    <Stepper activeStep={activeStep} orientation="vertical" connector={<CustomConnector />}>
      {steps.map((step, index) => (
        <Step
          key={`${step}-${index}`}
          id={`${index + 1}`}
          sx={{ backgroundColor: activeStep === index ? getColorByMode(theme, colors.gray50, darken(colors.gray50, 0.4)) : undefined }}>
          <StepLabel
            sx={{
              px: 3,
              py: 2,
              [`& .${stepLabelClasses.label}`]: {
                [`&.${stepLabelClasses.completed}`]: {
                  color: getColorByMode(theme, ACTIVE_COLOR, lighten(ACTIVE_COLOR, 0.85)),
                },
                [`&.${stepLabelClasses.active}`]: {
                  color: getColorByMode(theme, ACTIVE_COLOR, lighten(ACTIVE_COLOR, 0.85)),
                },
                color: NORMAL_COLOR,
                fontWeight: "500",
                ml: 0.5,
              },
            }}
            StepIconProps={{
              sx: {
                width: "28px",
                height: "28px",
                color: STEP_ICON_TEXT_COLOR,
                [`&.${stepIconClasses.active} .${stepIconClasses.text}`]: {
                  fill: getColorByMode(theme, BACKGROUND_COLOR, "#000"),
                },
                [`& .${stepIconClasses.text}`]: {
                  fill: getColorByMode(theme, NORMAL_COLOR, "#000"),
                },
              },
            }}>
            <Stack
              direction={"row"}
              spacing={2}
              justifyContent={{ xs: "space-between", md: "left" }}
              alignItems={"center"}>
              <Box>{step}</Box>
              {index === steps.length - 2 && activeStep == steps.length - 1 && (
                <Button onClick={backStep}>{t("edit")}</Button>
              )}
            </Stack>
          </StepLabel>
          <StepContent sx={{ ml: 0, borderLeft: "none", px: 3 }}>
            {getContent(index)}
            <Stack my={4} display={"flex"} justifyContent={"flex-start"} flexDirection={"row"}>
              {index !== 0 && (
                <Button onClick={backStep} variant="outlined" sx={{ mt: 1, mr: 1, backgroundColor: colors.white }}>
                  {t("back")}
                </Button>
              )}
              <Button
                variant="contained"
                disabled={disableNextStep(index)}
                onClick={index === steps.length - 1 ? submit : nextStep}
                sx={{ mt: 1, mr: 1 }}>
                {index === steps.length - 1 ? t("gridBot.create.create") : t("continue")}
              </Button>
            </Stack>
          </StepContent>
        </Step>
      ))}
    </Stepper>
  );
};

export default GridStepper;
