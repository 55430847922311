import React, { useEffect, useState } from "react";

import LaunchIcon from "@mui/icons-material/Launch";
import { Alert, Box, Link, useTheme } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "components/elements/Select";
import { FormikErrors } from "formik";
import { Trans, useTranslation } from "react-i18next";
import { DcaDynamicStrategyType } from "types/enums";

import { IExchangeCurrencyPair, IObject } from "../module/types";

interface IProps {
  onChangeSelect: {
    (e: React.ChangeEvent<any>): void;
    <T_1 = string | React.ChangeEvent<any>>(field: T_1): T_1 extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
  error?: string | false | string[] | FormikErrors<any> | FormikErrors<any>[];
  selectValue?: DcaDynamicStrategyType;
  exchangesCurrencyPair: IExchangeCurrencyPair[];
  currencyPair: string;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<FormikErrors<IObject>>;
}

export const ALLOWED_DYNAMIC_STRATEGIES = [DcaDynamicStrategyType.BTC_NUPL, DcaDynamicStrategyType.BTC_FEAR_AND_GREED];
export const ABOUT_DYNAMIC_STRATEGIES_LINK = {
  [DcaDynamicStrategyType.BTC_NUPL]: "https://www.bitcoinmagazinepro.com/charts/relative-unrealized-profit--loss/",
  [DcaDynamicStrategyType.BTC_FEAR_AND_GREED]: "https://www.bitcoinmagazinepro.com/charts/bitcoin-fear-and-greed-index/",
};

const DynamicStrategySelect = ({
  error,
  selectValue,
  onChangeSelect,
  currencyPair,
  exchangesCurrencyPair,
  setFieldValue,
}: IProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [dynamicStrategies, setDynamicStrategies] = useState<React.ReactElement[]>();
  const [isAllDisabled, setIsAllDisabled] = useState(false);

  useEffect(() => {
    if (currencyPair) {
      const result = [];
      const enabledDynamicStrategies = exchangesCurrencyPair.find(
        (item) => item.key === currencyPair
      )?.enabledDynamicStrategies;

      if (!enabledDynamicStrategies || enabledDynamicStrategies.length === 0) {
        result.push(
          <MenuItem key={DcaDynamicStrategyType.EMPTY} value={DcaDynamicStrategyType.EMPTY}>
            {DcaDynamicStrategyType.EMPTY}
          </MenuItem>
        );
        setIsAllDisabled(true);
        setFieldValue("dynamicStrategyType", DcaDynamicStrategyType.EMPTY);
      } else {
        setIsAllDisabled(false);
      }

      ALLOWED_DYNAMIC_STRATEGIES.forEach((strategy, index) => {
        result.push(
          <MenuItem
            key={`${strategy}-${index}`}
            disabled={!enabledDynamicStrategies?.some((val) => val === strategy)}
            value={strategy}>
            {t(`dcaBots.create.dynamicStrategyLabel.types.${strategy}`)}
          </MenuItem>
        );
      });

      result.push(
        <MenuItem key={"other"} disabled>
          {t("dcaBots.create.dynamicStrategyLabel.other")}
        </MenuItem>
      );

      setDynamicStrategies(result);
    }
  }, [currencyPair, exchangesCurrencyPair]);

  return (
    <>
      <Box width={"100%"}>
        {dynamicStrategies?.length && (
          <Box maxWidth={"300px"} pt={2}>
            <Select
              id="dynamicStrategyType"
              name="dynamicStrategyType"
              size="small"
              fullWidth
              label={t("dcaBots.create.dynamicStrategy.label")}
              value={selectValue ?? ""}
              onChange={onChangeSelect}
              error={error}>
              {dynamicStrategies}
            </Select>
          </Box>
        )}
        {isAllDisabled && (
          <Alert severity="info" sx={{ mt: 2 }}>
            <Trans i18nKey="dcaBots.create.dynamicStrategyLabel.alert" />
          </Alert>
        )}
      </Box>
      {selectValue && selectValue !== DcaDynamicStrategyType.EMPTY && (
        <Link
          href={ABOUT_DYNAMIC_STRATEGIES_LINK[selectValue]}
          sx={{ mt: 1, color: theme.palette.secondary.contrastText, fontSize: "14px", display: "flex", alignItems: "center"}}
          target="_blank">
          {t("dcaBots.create.dynamicStrategy.link", { strategy: t(`dcaBots.create.dynamicStrategyLabel.typesShort.${selectValue}`) })}
          <LaunchIcon sx={{ fontSize: "14px", ml: 0.5 }} />
        </Link>
      )}
    </>
  );
};

export default DynamicStrategySelect;
