import * as React from "react";

import Accordion, { AccordionProps } from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { ChevronDown } from "components/icons";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";

interface Props extends Omit<AccordionProps, "children"> {
  title: string;
  children: React.ReactNode;
  icon?: React.ReactNode;
  isMobile?: boolean;
}

const AccordionItem = ({ title, children, icon, isMobile, ...props }: Props) => {
  const { colors } = useMUIThemeModeContext();

  return (
    <Accordion
      {...props}
      sx={{
        minHeight: "0px",
        "&:before": {
          display: "none",
        },
        ...props.sx,
      }}
      disableGutters
      elevation={0}
      square>
      <AccordionSummary
        sx={{
          "&.MuiAccordionSummary-root": {
            minHeight: 0,
          },
          "& .MuiAccordionSummary-content": {
            m: 0,
          },
          py: 0.75,
        }}
        expandIcon={<ChevronDown sx={{ fontSize: 20 }} />}>
        {icon}
        <Typography fontSize={isMobile ? "0.813rem" : "0.875rem"} color={colors.gray900} fontWeight={500}>
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>{children}</AccordionDetails>
    </Accordion>
  );
};

export default AccordionItem;
