import React from "react";

import { ThemeProvider, createTheme } from "@mui/material/styles";
import MUITextField from "@mui/material/TextField";

import IProps from "./types";

const theme = createTheme({});

const CustomTextField: React.FC<IProps> = ({ color, ...props }: IProps) => {
  if (color === "blue") {
    return (
      <ThemeProvider theme={theme}>
        <MUITextField {...props} color="primary" />
      </ThemeProvider>
    );
  }

  return <MUITextField {...props} color={color ?? "primary"} />;
};

export default CustomTextField;
