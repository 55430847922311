import { Box, Stack, Typography, alpha, useMediaQuery, useTheme } from "@mui/material";
import MaskedModeWrapper from "components/elements/MaskedModeWrapper";
import { BarLineChart } from "components/icons";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { renderNumber } from "utils/formatter";

interface IProps {
  value: number;
  currency: string;
  scale: number;
  showIcon?: boolean;
  bgColor?: string;
}

const ProfitBox = ({ value, currency, scale, showIcon, bgColor }: IProps) => {
  const { colors } = useMUIThemeModeContext();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(320));

  return (
    <Stack spacing={1} direction={"row"} alignItems={"center"}>
      {!isSmallScreen && showIcon && <BarLineChart color="inherit" sx={{ fontSize: 20 }} />}
      {value !== 0 && (
        <Box
          p={{ xs: 0.4, sm: 0.8 }}
          bgcolor={alpha(bgColor ?? colors.gray800, 0.15)}
          color={colors.gray800}
          borderRadius={"10px"}>
          <MaskedModeWrapper color={"inherit"} fontSize={"0.75rem"} fontWeight={600}>
            <Typography fontSize={"0.75rem"} fontWeight={600}>
              {`~\u00a0${renderNumber(value, scale, true)}\u00a0${currency}`}
            </Typography>
          </MaskedModeWrapper>
        </Box>
      )}
    </Stack>
  );
};

export default ProfitBox;
