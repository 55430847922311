import React from "react";

import { Box, Stack, alpha, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import CurrencyIcon from "components/elements/CurrencyIcon";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { useTranslation } from "react-i18next";
import { splitCurrencyPair } from "utils";

import IProps from "./types";

const Header: React.FC<IProps> = ({
  currencyPair,
  label,
  rightSection,
  withBorderBottom,
  rightSectionSmallScreen,
  backgroundColor,
  enabled,
}) => {
  const theme = useTheme();
  const { colors } = useMUIThemeModeContext();
  const { t } = useTranslation();
  const { baseCurrency } = splitCurrencyPair(currencyPair);
  const iconBreakpoint = useMediaQuery(theme.breakpoints.down(330));
  const headerBreakpoint = useMediaQuery(theme.breakpoints.down("lg"));

  const getTitle = () => {
    return (
      <Stack direction="row" spacing={1}>
        <Typography color="secondary.main" variant="subtitle1" fontWeight={500}>
          {t("bots.gridLabel")}
        </Typography>
        <Typography color="tertiary.main" variant="subtitle1" fontWeight={500}>
          {currencyPair}
        </Typography>
      </Stack>
    );
  };

  return (
    <Box
      sx={{
        p: 3,
        backgroundColor: backgroundColor
          ? backgroundColor
          : enabled
          ? alpha(colors.black, theme.palette.mode === "light" ? 0.03 : 0.05)
          : alpha(colors.error, 0.15),
        borderBottom: withBorderBottom ? `2px solid ${colors.legacyGray50}` : "none",
      }}
      display="flex"
      alignItems={"center"}>
      {!iconBreakpoint && (
        <CurrencyIcon style={{ marginRight: "14px" }} name={baseCurrency.toLocaleLowerCase()} width="40" height="40" />
      )}
      <Stack
        flexGrow={1}
        direction={headerBreakpoint ? "column" : "row"}
        alignItems={headerBreakpoint ? "flex-start" : "row"}
        justifyContent="space-between">
        <Stack width={headerBreakpoint ? "100%" : "auto"} justifyContent="space-between" direction="row">
          <Stack>
            {getTitle()}
            <Typography color="secondary.main" variant="subtitle2" fontWeight={400}>
              {label}
            </Typography>
            {headerBreakpoint && !label && <Stack alignItems={"flex-start"}>{rightSection}</Stack>}
          </Stack>
          {headerBreakpoint && rightSectionSmallScreen}
        </Stack>
        {label && headerBreakpoint && <Stack alignItems={"flex-start"}>{rightSection}</Stack>}
        {!headerBreakpoint && <Stack alignItems={"flex-end"}>{rightSection}</Stack>}
      </Stack>
    </Box>
  );
};

export default Header;
