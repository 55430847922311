import React from "react";

import { Divider } from "@mui/material";
import Box from "@mui/material/Box";
import MUIMobileStepper from "@mui/material/MobileStepper";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { StepperButton } from "./styles";
import IProps from "./types";

const MobileStepper: React.FC<IProps> = ({
  title,
  steps,
  activeStep,
  isFirstStep,
  isLastStep,
  onNext,
  onBack,
  onDone,
  children,
}) => {
  const { t } = useTranslation<string>();

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Box sx={{ pt: 4, pb: 2, px: 2 }}>
        <Typography variant="subtitle2" fontWeight={600}>
          {steps[activeStep]}
        </Typography>
      </Box>
      <Divider />
      <Box sx={{ p: { xs: 1, sm: 2 } }}>
        <Typography variant="subtitle2" fontWeight={400} sx={{ color: "secondary.main" }}>
          {title}
        </Typography>
        <Box sx={{ width: "100%", p: { xs: 0, sm: 2 } }}>{children}</Box>
        <MUIMobileStepper
          sx={{ bgcolor: "white", p: 0 }}
          variant="text"
          steps={steps.length}
          position="static"
          activeStep={activeStep}
          nextButton={
            <>
              {isLastStep && (
                <StepperButton color="primary" variant="contained" disableElevation onClick={onDone}>
                  {t("twoFactorAuth.loginAgain")}
                </StepperButton>
              )}
              {!isLastStep && (
                <StepperButton color="primary" variant="contained" disableElevation onClick={onNext}>
                  {t("twoFactorAuth.next")}
                </StepperButton>
              )}
            </>
          }
          backButton={
            <>
              {isFirstStep && (
                <StepperButton color="inherit" variant="contained" disableElevation to="/nastaveni" component={Link}>
                  {t("twoFactorAuth.previousStep")}
                </StepperButton>
              )}
              {!isFirstStep && !isLastStep && onBack && (
                <StepperButton
                  color="inherit"
                  variant="contained"
                  disableElevation
                  onClick={onBack}>
                  {t("twoFactorAuth.previousStep")}
                </StepperButton>
              )}
            </>
          }
        />
      </Box>
    </Box>
  );
};

export default MobileStepper;
