import { Typography } from "@mui/material";

const ResponsiveLabel = (label: string) => {
  return (
    <Typography fontWeight={400} fontSize={{ xs: "13px", sm: "16px" }}>
      {label}
    </Typography>
  );
};

export default ResponsiveLabel;
