import { SvgIcon } from "@mui/material";

export const GraduationHat = (props: any) => {
  return (
    <SvgIcon {...props}>
      <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="graduation-hat-02">
          <path
            id="Icon"
            d="M14.167 12.0833V9.57866C14.167 9.4291 14.167 9.35431 14.1442 9.28829C14.1241 9.22991 14.0912 9.17673 14.048 9.1326C13.9991 9.08271 13.9322 9.04927 13.7985 8.98238L10.0003 7.08331M3.33366 7.91664V13.5888C3.33366 13.8987 3.33366 14.0537 3.38201 14.1894C3.42475 14.3093 3.49441 14.4179 3.58564 14.5067C3.68884 14.6072 3.82972 14.6718 4.11145 14.8009L9.44479 17.2454C9.64915 17.339 9.75133 17.3859 9.85778 17.4043C9.9521 17.4207 10.0485 17.4207 10.1429 17.4043C10.2493 17.3859 10.3515 17.339 10.5559 17.2454L15.8892 14.8009C16.1709 14.6718 16.3118 14.6072 16.415 14.5067C16.5062 14.4179 16.5759 14.3093 16.6186 14.1894C16.667 14.0537 16.667 13.8987 16.667 13.5888V7.91664M1.66699 7.08331L9.70218 3.06571C9.8115 3.01105 9.86616 2.98372 9.92349 2.97297C9.97427 2.96344 10.0264 2.96344 10.0772 2.97297C10.1345 2.98372 10.1891 3.01105 10.2985 3.06571L18.3337 7.08331L10.2985 11.1009C10.1891 11.1556 10.1345 11.1829 10.0772 11.1936C10.0264 11.2032 9.97427 11.2032 9.92349 11.1936C9.86616 11.1829 9.8115 11.1556 9.70218 11.1009L1.66699 7.08331Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    </SvgIcon>
  );
};
