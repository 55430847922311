import React from "react";

import CandleChart, { convertOHLCData } from "components/elements/CandleChart";
import { useUserDataContext } from "context/UserDataContext";
import useExchangeRate from "hooks/useExchangeRate";
import useExchangesMetadata from "hooks/useExchangesMetadata";
import { OHLCInterval } from "types/enums";
import { splitCurrencyPair } from "utils";

import IProps from "./types";

export const BROKERAGE_PRICE_LINE_COLOR = {
  askPrice: "#0d7800",
  bidPrice: "#7c0000",
};

const ChartItem: React.FC<IProps> = ({ data, OHLCData, exchangeEnum, currencyPair, isBrokerage, interval }) => {
  const { currencyPairPriceScale } = useExchangesMetadata(exchangeEnum ?? "", currencyPair);
  const { showInUSD } = useUserDataContext();
  const { getUSDOriginalPrice } = useExchangeRate();
  const { counterCurrency } = splitCurrencyPair(currencyPair);

  const createBrokeragePriceLine = (priceLines: any[], type: "askPrice" | "bidPrice") => {
    const value = showInUSD && data?.[type] ? priceTransform(data?.[type]) : data?.[type];

    priceLines.push({
      price: value,
      color: BROKERAGE_PRICE_LINE_COLOR[type],
      lineWidth: 3,
      lineStyle: 0,
      axisLabelVisible: true,
    });
  };

  const priceTransform = (price: number) => {
    const inUSD = getUSDOriginalPrice(counterCurrency, price);
    return inUSD ?? price;
  };

  const getPriceLines = () => {
    const result: any[] = [];

    if (data?.priceLevels) result.push(...data.priceLevels);

    if (isBrokerage) {
      createBrokeragePriceLine(result, "askPrice");
      createBrokeragePriceLine(result, "bidPrice");
    }

    return result;
  };

  return (
    <CandleChart
      data={convertOHLCData(OHLCData, showInUSD ? priceTransform : undefined)}
      dateWithTime={interval !== OHLCInterval.ONE_DAY}
      pricePrecision={currencyPairPriceScale}
      priceLines={getPriceLines()}
      lastValueVisible={!isBrokerage}
    />
  );
};

export default ChartItem;
