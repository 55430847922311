import React from "react";

import { Stack } from "@mui/material";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";

const ChartSkeleton: React.FC = () => {
  return (
    <Box height={"100%"} alignContent={"center"}>
      <Stack spacing={1} direction={"row"} justifyContent={"center"} alignItems={"flex-end"}>
        <Skeleton variant="rectangular" width={20} height={20} />
        <Skeleton variant="rectangular" width={20} height={80} />
        <Skeleton variant="rectangular" width={20} height={60} />
        <Skeleton variant="rectangular" width={20} height={100} />
        <Skeleton variant="rectangular" width={20} height={160} />
        <Skeleton variant="rectangular" width={20} height={90} />
        <Skeleton variant="rectangular" width={20} height={40} />
        <Skeleton variant="rectangular" width={20} height={80} />
        <Skeleton variant="rectangular" width={20} height={60} />
        <Skeleton variant="rectangular" width={20} height={80} />
        <Skeleton variant="rectangular" width={20} height={130} />
        <Skeleton variant="rectangular" width={20} height={160} />
        <Skeleton variant="rectangular" width={20} height={40} />
        <Skeleton variant="rectangular" width={20} height={80} />
      </Stack>
    </Box>
  );
};

export default ChartSkeleton;
