import * as React from "react";

import { Typography, MenuItem as MUIMenuItem, ListItemIcon, ListItemText, Stack, useTheme, Box } from "@mui/material";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";

interface Props {
  icon: React.ReactNode;
  title: string;
  subtitle?: string;
  withSubtitle?: boolean;
  badge?: string | number;
  to?: string;
  withSelected?: boolean;
  callback?: () => void;
}

const MenuItem: React.FC<Props> = ({ icon, title, badge, to, subtitle, withSubtitle, withSelected, callback }) => {
  const navigate = useNavigate();
  const { colors } = useMUIThemeModeContext();
  const { pathname, hash } = useLocation();
  const { t } = useTranslation<string>();
  const theme = useTheme();
  const selected = withSelected ? `${pathname}${hash}` === to : false;

  const handleClick = () => {
    if(to) {
      navigate(to);
      callback?.();
    }
  }

  return (
    <MUIMenuItem dense onClick={handleClick} selected={selected} sx={{ borderRadius: "10px" }}>
      <ListItemIcon
        sx={{
          color: selected ? theme.palette.primary.main : colors.gray900,
          minWidth: "0px",
          pr: 1.5,
          "&.MuiListItemIcon-root": {
            minWidth: "0px",
          },
        }}>
        {icon}
      </ListItemIcon>
      <ListItemText disableTypography>
        <Stack direction={"row"} spacing={0.5}>
          <Typography
            fontSize={"0.813rem"}
            color={selected ? theme.palette.primary.main : colors.gray900}
            fontWeight={selected ? 600 : 500}>
            {title}
          </Typography>
          {withSubtitle && (
            <Typography
              fontSize={"0.813rem"}
              color={subtitle ? colors.gray900 : colors.gray400}
              fontWeight={500}
              textOverflow={"ellipsis"}
              overflow={"hidden"}
              whiteSpace={"nowrap"}>
              {subtitle?.length ? subtitle : t("withoutLabel")}
            </Typography>
          )}
        </Stack>
      </ListItemText>
      {badge !== undefined && (
        <Box px={1} py={0.25} bgcolor={colors.gray200} borderRadius={"10px"}>
          <Typography fontWeight={500} color={colors.gray900} fontSize={"0.75rem"}>
            {badge}
          </Typography>
        </Box>
      )}
    </MUIMenuItem>
  );
};

export default MenuItem;
