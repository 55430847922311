import React from "react";

import { ChevronRight } from "@mui/icons-material";
import { Button, Grid, Typography } from "@mui/material";
import { ReactComponent as HowItWorksDiagram } from "assets/images/how-it-works.svg";
import HomeBox from "components/modules/HomeBox";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const HowItWorks: React.FC = () => {
  const { t } = useTranslation();
  const { colors } = useMUIThemeModeContext();
  const navigate = useNavigate();

  return (
    <HomeBox size="small" textAlign={"start"} pb={{ xs: 0, md: 6 }}>
      <Grid container spacing={{ xs: 0, sm: 2, lg: 3 }}>
        <Grid item xs={12} lg={5}>
          <Typography fontSize={{ xs: "1.25rem", sm: "1.5rem", lg: "2rem" }} fontWeight={600} color={"black"}>
            {t("homepage.howItWorks.title")}
          </Typography>
          <Typography pt={{ xs: 1, sm: 3 }} fontSize={{ xs: "0.8rem", sm: "1.125rem" }} fontWeight={300} color={colors["blueGray"]}>
            <Trans i18nKey={"homepage.howItWorks.subtitle"} />
          </Typography>
          <Button sx={{ mt: { xs: 2, sm: 6 } }} variant="contained" endIcon={<ChevronRight />} onClick={() => navigate("#vyber-strategie")}>
            {t("homepage.howItWorks.moreAbout")}
          </Button>
        </Grid>
        <Grid item xs={12} lg={7}>
          <HowItWorksDiagram style={{ width: "100%" }} />
        </Grid>
      </Grid>
    </HomeBox>
  );
};

export default HowItWorks;
