import React from "react";

import { Box, Stack, SxProps, Theme, Typography, TypographyProps } from "@mui/material";
import Panel, { Props as PanelProps } from "components/elements/Panel";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";

export interface CardProps extends Omit<PanelProps, "title"> {
  subtitle?: string | React.ReactFragment;
  action?: React.ReactFragment;
  title?: string | React.ReactFragment;
  childrenBoxSx?: SxProps<Theme>;
  headerStackSx?: SxProps<Theme>;
  subtitleProps?: TypographyProps;
}

const Card2: React.FC<CardProps> = ({
  children,
  subtitle,
  title,
  action,
  childrenBoxSx,
  headerStackSx,
  subtitleProps,
  ...props
}: CardProps) => {
  const { colors } = useMUIThemeModeContext();

  return (
    <Panel
      shadow={false}
      width={"100%"}
      overflow={"hidden"}
      p={{xs: 1.5, sm: 3}}
      display={"flex"}
      flexDirection={"column"}
      {...props}>
      {(title || action) && (
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          mb={3}
          sx={{ ...headerStackSx }}>
          {typeof title === "string" ? (
            <Typography fontSize={"1rem"} fontWeight={600}>
              {title}
            </Typography>
          ) : (
            title
          )}
          {action}
        </Stack>
      )}
      {subtitle && (
        <Typography pb={3} color={colors.gray600} fontSize={"0.875rem"} lineHeight={"1.4rem"} {...subtitleProps}>
          {subtitle}
        </Typography>
      )}
      <Box sx={{ ...childrenBoxSx }}>{children}</Box>
    </Panel>
  );
};

export default Card2;
