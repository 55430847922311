import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";

export const CustomBox = styled(Box)(({ theme }) => {
  const { colors } = useMUIThemeModeContext();
  
  return {
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  borderBottom: `1px solid ${colors.gray200}`,
}});
