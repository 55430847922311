import { useEffect, useRef, useState } from "react";

import { Stack, Typography, Box, useTheme } from "@mui/material";
import UsdWrapper, { TypographyItem } from "components/elements/UsdWrapper";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { useTranslation } from "react-i18next";
import { IGridBotDetail } from "types/types";
import { percentage } from "utils";

interface IProps {
  data: IGridBotDetail;
  midPrice: number;
  priceScale: number;
  counterCurrency: string;
  baseCurrency: string;
}

const SEPARATOR_HEIGHT = 20;
const SEPARATOR_TOP_CORRECTION = -4;

const MidPriceItem = ({ data, midPrice, priceScale, counterCurrency, baseCurrency }: IProps) => {
  const { colors } = useMUIThemeModeContext();
  const { t } = useTranslation();
  const theme = useTheme();

  const [percent, setPercent] = useState<number>(50);
  const [left, setLeft] = useState<number>();
  const [right, setRight] = useState<number>();

  const containerRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const origin = data.gridUpperPrice - data.gridLowerPrice;
    const current = midPrice - data.gridLowerPrice;

    const value = percentage(origin, current);
    setPercent(value);
  }, [midPrice, data]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [percent]);

  useEffect(() => {
    handleResize();
  }, [percent]);

  const getCorrectionPercentage = () => {
    const containerWidth = containerRef.current?.offsetWidth;
    const textWidth = textRef.current?.offsetWidth;

    if (!containerWidth || !textWidth) return 6;

    return ((textWidth / containerWidth) * 100) / 2;
  };

  const handleResize = () => {
    const correction = getCorrectionPercentage();

    if (percent > 50) {
      const result = 100 - percent - correction;

      setLeft(undefined);
      setRight(result < 0 ? 0 : result);
    } else {
      const result = percent - correction;

      setLeft(result < 0 ? 0 : result);
      setRight(undefined);
    }
  };

  return (
    <Box ref={containerRef} width={"100%"} height={"50px"}>
      <Box position={"relative"}>
        <Box bgcolor={theme.palette.primary.main} height={"6px"} width={"100%"} borderRadius={"10px"} />
        {percent >= 0 && percent <= 100 && (
          <Box
            display={"flex"}
            justifyContent={"center"}
            position={"absolute"}
            bgcolor={colors.gray50}
            height={SEPARATOR_HEIGHT}
            top={SEPARATOR_TOP_CORRECTION}
            left={`${percent === 100 ? 99.7 : percent}%`}
            width={"5px"}>
            <Box bgcolor={theme.palette.primary.main} width={"1px"} height={"100%"} />
          </Box>
        )}
        <Box
          ref={textRef}
          position={"absolute"}
          display={"flex"}
          justifyContent={"center"}
          top={SEPARATOR_HEIGHT + SEPARATOR_TOP_CORRECTION}
          left={`${left}%`}
          right={`${right}%`}>
          <Stack textAlign={"center"}>
            <UsdWrapper value={midPrice} counterCurrency={counterCurrency} baseCurrency={baseCurrency}>
              <TypographyItem
                sx={{
                  fontSize: "0.875rem",
                  color: colors.gray950,
                  fontWeight: 500,
                }}
                value={midPrice}
                scale={priceScale}
                currency={counterCurrency}
                withZeros
              />
            </UsdWrapper>
            <Typography color={colors.gray500} fontSize={"0.75rem"}>
              {t("bots.tile.midPrice")}
            </Typography>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

export default MidPriceItem;
