import { Box, Typography, darken, lighten, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getColorByMode } from "utils";

interface IBadgeColor {
  color: string;
  border: string;
  background: string;
}

const SUCCESS_COLORS: IBadgeColor = {
  color: "#067647",
  border: "#ABEFC6",
  background: "#ECFDF3"
}

const NORMAL_COLORS: IBadgeColor = {
  color: "#175CD3",
  border: "#B2DDFF",
  background: "#EFF8FF"
}

const BLACK_COLORS: IBadgeColor = {
  color: "#344054",
  border: "#EAECF0",
  background: "#F9FAFB"
}

const ERROR_COLORS: IBadgeColor = {
  color: "#B42318",
  border: "#FECDCA",
  background: "#FEF3F2"
}

const WARNING_COLORS: IBadgeColor = {
  color: "#B54708",
  border: "#FEDF89",
  background: "#FFFAEB"
}

export const statusColors = {
  PENDING_NEW: NORMAL_COLORS,
  OPEN: NORMAL_COLORS,
  PENDING_FUNDS: WARNING_COLORS,
  FILLED: SUCCESS_COLORS,
  PARTIALLY_FILLED: SUCCESS_COLORS,
  PARTIALLY_CANCELED: SUCCESS_COLORS,
  ERROR_CALCULATING: ERROR_COLORS,
  ERROR_SMALL_BALANCE: WARNING_COLORS,
  ERROR_PLACING: ERROR_COLORS,
  ERROR_CANCELING: ERROR_COLORS,
  PENDING_CANCEL: ERROR_COLORS,
  CANCELED: BLACK_COLORS,
  ENOUGH_BALANCE: BLACK_COLORS,
  NOT_ENOUGH_BALANCE: WARNING_COLORS,
  GAP: BLACK_COLORS,
  ERROR_PENDING_FUNDS_TIMEOUT: ERROR_COLORS,
};

export const Badge = styled(Box)<{ colors: IBadgeColor }>(({ colors, theme } ) => ({
  backgroundColor: getColorByMode(theme, colors.background, darken(colors.background, 0.85)),
  border: `1px solid ${getColorByMode(theme, colors.border, darken(colors.border, 0.7))}`,
  borderRadius: "10px",
  padding: "2px 8px",
  color: getColorByMode(theme, colors.color, lighten(colors.color, 0.8)),
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center"
}));

const StatusBadge = ({ status, currency }: { status: string, currency?: string }) => {
  const { t } = useTranslation<string>();

  return (
    <Badge colors={statusColors[status as keyof typeof statusColors] ?? BLACK_COLORS}>
      <Typography variant="caption" fontWeight={500}>{t(`gridBot.orderList.orderStatus.${status}`, { currency })}</Typography>
    </Badge>
  );
};

export default StatusBadge;
