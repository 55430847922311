import { OrderType, Side } from "types/enums";
import { IGridStrategyLimits } from "types/types";
import { splitCurrencyPair } from "utils";

import { useAPIExchangesMetadata } from "./useAPI";

function useExchangesMetadata(exchangeEnum: string, currencyPair: string) {
  const { data } = useAPIExchangesMetadata();
  const { baseCurrency, counterCurrency } = splitCurrencyPair(currencyPair);

  const getCurrencyDisplayedScale = (currencyEnum: string) => {
    return data?.[exchangeEnum]?.currencies?.[currencyEnum]?.displayed_scale ?? 2;
  };

  const getCurrencyPairPriceScale = () => {
    return data?.[exchangeEnum]?.currency_pairs?.[currencyPair]?.price_scale ?? 2;
  };

  const getCurrencyPairBaseScale = () => {
    return data?.[exchangeEnum]?.currency_pairs?.[currencyPair]?.base_scale ?? 2;
  };

  const getCurrencyScale = (currencyEnum: string) => {
    return data?.[exchangeEnum]?.currencies?.[currencyEnum]?.scale ?? 2;
  };

  const getGridStrategyLimits = (): IGridStrategyLimits => {
    return {
      min: data?.[exchangeEnum]?.currency_pairs?.[currencyPair]?.grid_strategy_counter_min_amount,
      max: data?.[exchangeEnum]?.currency_pairs?.[currencyPair]?.grid_strategy_counter_max_amount,
    }
  };

  const getLimit = (
    side: Side,
    limitDirection: "min" | "max",
    currencyType: "base" | "counter",
    currencyEnum: string,
    exchEnum: string,
    orderType?: OrderType
  ) => {
    type TProperty = "min_amount" | "max_amount" | "counter_min_amount" | "counter_max_amount";
    type TOrderType = "limit" | "market";
    type TSide = "buy" | "sell";

    const sideProp: TSide = side.toLowerCase() as TSide;
    const currencyTypeProp = currencyType === "counter" ? "counter_" : "";
    const property: TProperty = `${currencyTypeProp}${limitDirection}_amount`;
    const exchangeData = data?.[exchEnum]?.currency_pairs?.[currencyEnum];
    const result = exchangeData?.[property];
    if (orderType) {
      const orderTypeProp: TOrderType = orderType.toLowerCase() as TOrderType;
      return (
        exchangeData?.[orderTypeProp]?.[sideProp]?.[property] || exchangeData?.[orderTypeProp]?.[property] || result
      );
    }
    return result;
  };
  const getDcaBotSellModeInFreeSubscription = (exchangeEnum: string) => {
    return data?.[exchangeEnum]?.dcaBotSellModeInFreeSubscription ?? false;
  }

  return {
    data,
    counterCurrDisplayedScale: getCurrencyDisplayedScale(counterCurrency),
    baseCurrDisplayedScale: getCurrencyDisplayedScale(baseCurrency),
    currencyPairPriceScale: getCurrencyPairPriceScale(),
    currencyPairBaseScale: getCurrencyPairBaseScale(),
    counterCurrScale: getCurrencyScale(counterCurrency),
    baseCurrScale: getCurrencyScale(baseCurrency),
    getLimit,
    gridStrategyLimits: getGridStrategyLimits(),
    getDcaBotSellModeInFreeSubscription,
  };
}

export default useExchangesMetadata;
