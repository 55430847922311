import { useState, useRef, useEffect } from "react";

import { Grid, CircularProgress, Stack, Link, IconButton, Alert } from "@mui/material";
import Typography from "@mui/material/Typography";
import { updateExchangeConnections, deleteExchangeConnections } from "API/calls";
import Card2 from "components/elements/Card2";
import CustomTextField from "components/elements/CustomTextField";
import ExchangeIcon from "components/elements/ExchangeIcon";
import { Check, Edit, Trash, Clear } from "components/icons";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { useFormik } from "formik";
import useConfirm from "hooks/useConfirm";
import useKeyPress from "hooks/useKeyPress";
import { useSnackbar } from "notistack";
import { Trans, useTranslation } from "react-i18next";
import { ExchangeType } from "types/enums";
import * as yup from "yup";

import DepositInfoModal from "./DepositInfoModal";
import IProps from "./types";

const ListItem = ({ exchangeEnum, label, id, refreshData, functional }: IProps) => {
  const { colors } = useMUIThemeModeContext();
  const { t } = useTranslation();
  const { confirm } = useConfirm();
  const keyPressEnter = useKeyPress("Enter");
  const keyPressedEsc = useKeyPress("Escape");
  const inputRef = useRef<any>();
  const { enqueueSnackbar } = useSnackbar();
  const [isOpenDepositModal, setIsOpenDepositModal] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: { label: label },
    validationSchema: yup.object({
      label: yup.string().max(50, t("fieldMaxLength", { max: 50 })),
    }),
    onSubmit: (values) => {
      handleUpdate(values);
    },
  });

  useEffect(() => {
    if (typeof editMode === "boolean" && editMode === true) {
      inputRef.current.focus();
    }
  }, [editMode]);

  useEffect(() => {
    if (editMode && keyPressedEsc) {
      handleClear();
    }
  }, [keyPressedEsc]);

  useEffect(() => {
    if (editMode && keyPressEnter) {
      formik.handleSubmit();
    }
  }, [keyPressEnter]);

  const handleUpdate = async (body: object) => {
    setIsLoading(true);

    try {
      await updateExchangeConnections(id, body);
      enqueueSnackbar(t("exchangeConnections.editSuccessMessage"), { variant: "success" });
      refreshData();
    } catch (error) {
      enqueueSnackbar(t("exchangeConnections.editErrorMessage"), { variant: "error" });
    }

    setEditMode(false);
    setIsLoading(false);
  };

  const handleDelete = async () => {
    try {
      await confirm({
        title: t("exchangeConnections.modal.delete.title", { label: label ? label : t("withoutLabel") }),
        description: t("exchangeConnections.modal.delete.description"),
        dialogProps: { maxWidth: "xs" },
      });

      try {
        await deleteExchangeConnections(id);
        enqueueSnackbar(t("exchangeConnections.deleteSuccessMessage"), { variant: "success" });
        refreshData();
      } catch (error: any) {
        if (error?.response?.status === 428) {
          enqueueSnackbar(t("exchangeConnections.delete428Message"), { variant: "error" });
        } else {
          enqueueSnackbar(t("exchangeConnections.deleteErrorMessage"), { variant: "error" });
        }
      }
    } catch (err: any) {
      // silent
    }
  };

  const handleClear = () => {
    formik.setFieldValue("label", label);
    setEditMode(false);
  };

  const onDepositLinkClick = (e: any) => {
    e.preventDefault();
    setIsOpenDepositModal(true);
  };

  const getTitle = () => {
    if (editMode) {
      return (
        <CustomTextField
          fullWidth
          autoComplete="off"
          id="label"
          name="label"
          variant="standard"
          disabled={isLoading}
          inputRef={inputRef}
          label={t("exchangeConnections.modal.label")}
          value={formik.values.label}
          onChange={formik.handleChange}
          error={formik.touched.label && Boolean(formik.errors.label)}
          helperText={formik.touched.label && formik.errors.label}
        />
      );
    }

    if (label) {
      return (
        <Typography overflow={"hidden"} fontSize={"1rem"} fontWeight={600}>
          {label}
        </Typography>
      );
    }

    return (
      <Typography color={colors.gray400} fontSize={"1rem"} fontWeight={500}>
        {t("withoutLabel")}
      </Typography>
    );
  };

  return (
    <Grid item xs={12} lg={6} height={"auto"}>
      <Card2
        sx={{ height: "100%" }}
        title={getTitle()}
        childrenBoxSx={{
          display: "flex",
          flex: 1,
          alignItems: "flex-end",
          flexDirection: "column",
        }}
        action={
          <Stack direction={"row"} spacing={1}>
            {editMode ? (
              <>
                <IconButton size="small" disabled={isLoading} onClick={() => formik.handleSubmit()}>
                  {isLoading ? <CircularProgress size={24} /> : <Check sx={{ fontSize: 20, color: colors.gray700 }} />}
                </IconButton>
                <IconButton size="small" disabled={isLoading} onClick={handleClear}>
                  <Clear sx={{ fontSize: 20, color: colors.gray700 }} />
                </IconButton>
              </>
            ) : (
              <>
                <IconButton size="small" onClick={() => setEditMode(true)}>
                  <Edit sx={{ fontSize: 20, color: colors.gray700 }} />
                </IconButton>
                <IconButton size="small" onClick={handleDelete}>
                  <Trash sx={{ fontSize: 20, color: colors.gray700 }} />
                </IconButton>
              </>
            )}
          </Stack>
        }>
        <Stack
          width={"100%"}
          direction={{ xs: "column", sm: "row" }}
          alignItems={{ xs: "flex-start", sm: "center" }}
          justifyContent={"space-between"}
          spacing={1}>
          <ExchangeIcon type={exchangeEnum as ExchangeType} />
          {(exchangeEnum === ExchangeType.COINMATE || exchangeEnum === ExchangeType.ANYCOIN) && (
            <Link
              sx={{ textDecoration: "none" }}
              fontSize={"14px"}
              fontWeight={500}
              href=""
              onClick={onDepositLinkClick}>
              {t("exchangeConnections.deposit.title", {
                exchangeEnum: t(`exchangeConnections.deposit.${exchangeEnum}`),
              })}
            </Link>
          )}
        </Stack>
        {!functional && (
          <Alert severity="error" sx={{ mt: 2, width: "100%" }}>
            <Trans i18nKey="exchangeConnections.functionalError.description" />
          </Alert>
        )}
      </Card2>
      {isOpenDepositModal && (
        <DepositInfoModal
          id={id}
          exchangeEnum={exchangeEnum}
          functional={functional}
          open={isOpenDepositModal}
          handleClose={() => setIsOpenDepositModal(false)}
        />
      )}
    </Grid>
  );
};

export default ListItem;
