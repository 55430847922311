import { useUserDataContext } from "context/UserDataContext";

export function useIsLogin() {
  const data = useUserDataContext();

  if (data.loading) {
    return undefined;
  }

  // TODO create a better solution
  return !!data?.subscriptionType;
}
