import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import { Box, Stack, Typography, alpha } from "@mui/material";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";

interface IProps {
  value: number;
}

const PercentDifferenceBox = ({ value }: IProps) => {
  const { colors } = useMUIThemeModeContext();
  const getColor = () => (value < 0 ? colors.error : colors.success);

  if (value === 0) return null;

  return (
    <Box p={{ xs: 0.5, sm: 1 }} bgcolor={alpha(getColor(), 0.15)} color={getColor()} borderRadius={"10px"}>
      <Stack spacing={0.5} direction={"row"} alignItems={"center"}>
        {value < 0 ? <TrendingDownIcon fontSize={"small"} /> : <TrendingUpIcon fontSize={"small"} />}
        <Typography fontSize={"0.75rem"} fontWeight={600}>{`${value < 0 ? "-" : "+"} ${Math.floor(
          Math.abs(value)
        )} %`}</Typography>
      </Stack>
    </Box>
  );
};

export default PercentDifferenceBox;
