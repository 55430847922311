import React, { useState } from "react";

import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { cancelRun } from "API/calls";
import Badge from "components/elements/Badge";
import MaskedModeWrapper from "components/elements/MaskedModeWrapper";
import UsdWrapper, { TypographyItem } from "components/elements/UsdWrapper";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import useConfirm from "hooks/useConfirm";
import useExchangesMetadata from "hooks/useExchangesMetadata";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { Side } from "types/enums";
import { processErrorCode } from "utils";
import { renderNumber } from "utils/formatter";

import { ItemBox } from "../OrderRow/styles";
import { TableCell, orderStatusColors } from "../OrdersTable";
import IProps from "./types";

const OpenLimitRow = ({ dcaBotRun, baseCurrency, counterCurrency, exchangeEnum, dcaBotId, refreshData }: IProps) => {
  const theme = useTheme();
  const { colors } = useMUIThemeModeContext();
  const { t } = useTranslation();
  const { confirm } = useConfirm();
  const { enqueueSnackbar } = useSnackbar();
  const { currencyPairBaseScale, currencyPairPriceScale, counterCurrScale } = useExchangesMetadata(
    exchangeEnum ?? "",
    `${baseCurrency}/${counterCurrency}`
  );

  const [isCanceling, setIsCanceling] = useState<boolean>(false);
  const breakpoint1420 = useMediaQuery(theme.breakpoints.down(1420));
  const breakpoint1200 = useMediaQuery(theme.breakpoints.down(1200));
  const breakpoint970 = useMediaQuery(theme.breakpoints.down(970));
  const breakpoint768 = useMediaQuery(theme.breakpoints.down(768));
  const breakpoint660 = useMediaQuery(theme.breakpoints.down(660));

  const isMobileLayout = () => {
    return breakpoint660 || (breakpoint970 && !breakpoint768) || (breakpoint1420 && !breakpoint1200);
  };

  const handleCancelRun = async () => {
    try {
      await confirm({
        title: t("dcaBots.runsList.confirmCancel.title"),
        description: t("dcaBots.runsList.confirmCancel.description"),
        dialogProps: { maxWidth: "xs" },
      });

      try {
        setIsCanceling(true);
        await cancelRun(dcaBotId, dcaBotRun.id);
        enqueueSnackbar(t("dcaBots.runsList.successLimitCancelMessage"), { variant: "success" });
        refreshData(() =>  setIsCanceling(false));
      } catch (_error: any) {
        enqueueSnackbar(t("dcaBots.runsList.errorLimitCancelMessage"), { variant: "error" });
        setIsCanceling(false);
      }
    } catch (err: any) {
      setIsCanceling(false);
      // silent
    }
  };

  const cancelBtn = () => {
    return (
      <Button
        size="small"
        color="error"
        disabled={isCanceling}
        onClick={handleCancelRun}
        startIcon={isCanceling ? <CircularProgress size={16} color="inherit" /> : undefined}>
        {t("cancel")}
      </Button>
    );
  };

  return (
    <ItemBox>
      <Box overflow={"auto"} width={"100%"}>
        <Table size="small">
          <TableHead>
            <TableRow>
              {isMobileLayout() ? (
                <>
                  <TableCell withBorder>
                    <Badge color={dcaBotRun.side === Side.BUY ? "success" : "error"}>
                      <Typography variant="caption">{t(`dcaBots.runsList.${dcaBotRun.side}`)}</Typography>
                    </Badge>
                  </TableCell>
                  <TableCell withBorder>
                    <Typography textAlign={"end"} variant="subtitle2" fontWeight={600} color={"secondary"}>
                      {t("dcaBots.runsList.valueAmountAndPrice")}
                    </Typography>
                  </TableCell>
                </>
              ) : (
                <>
                  <TableCell withBorder>
                    <Badge color={dcaBotRun.side === Side.BUY ? "success" : "error"}>
                      <Typography variant="caption">{t(`dcaBots.runsList.${dcaBotRun.side}`)}</Typography>
                    </Badge>
                  </TableCell>
                  <TableCell withBorder>
                    <Typography variant="subtitle2" fontWeight={600} color={"secondary"}>
                      {t("dcaBots.runsList.value")}
                    </Typography>
                  </TableCell>
                  <TableCell withBorder>
                    <Typography variant="subtitle2" fontWeight={600} color={"secondary"}>
                      {t("dcaBots.runsList.amountAndPrice")}
                    </Typography>
                  </TableCell>
                  <TableCell withBorder />
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {dcaBotRun.orders.map((order, index: number) => (
              <React.Fragment key={`orders-${index}`}>
                <TableRow>
                  {isMobileLayout() ? (
                    <>
                      <TableCell>
                        <Typography
                          variant="subtitle2"
                          fontWeight={400}
                          sx={{ pb: 1, color: orderStatusColors[order.status as keyof typeof orderStatusColors] }}>
                          {t(`dcaBots.runsList.dcaBotOrderStatus.${order.status}`)}
                        </Typography>
                        {cancelBtn()}
                      </TableCell>
                      <TableCell>
                        <MaskedModeWrapper
                          variant="subtitle2"
                          fontWeight={400}
                          color="secondary"
                          stackProps={{ justifyContent: "end" }}
                          compact>
                          <Typography
                            textAlign={"end"}
                            variant="subtitle2"
                            fontWeight={400}
                            color={"secondary"}
                            fontFamily={"monospace"}>
                            {`${renderNumber(
                              order.cumulativeValue == 0 ? order.originalValue : order.cumulativeValue,
                              counterCurrScale
                            )}\u00a0${counterCurrency}`}
                          </Typography>
                        </MaskedModeWrapper>
                        <Stack direction={"row"} spacing={1} justifyContent={"end"}>
                          <MaskedModeWrapper
                            variant="subtitle2"
                            fontWeight={400}
                            color="secondary"
                            compact
                            stackProps={{ justifyContent: "end" }}>
                            <Typography
                              textAlign={"end"}
                              variant="subtitle2"
                              fontWeight={400}
                              color={"secondary"}
                              fontFamily={"monospace"}>
                              {`${order.side === "BUY" ? "" : "-"}${renderNumber(
                                order.status === "FILLED" ? order.cumulativeAmount : order.originalAmount,
                                currencyPairBaseScale,
                                true
                              )}\u00a0${baseCurrency}`}
                            </Typography>
                          </MaskedModeWrapper>
                          <UsdWrapper value={order.averagePrice ?? order.originalPrice} counterCurrency={counterCurrency} baseCurrency={baseCurrency}>
                            <TypographyItem
                              textAlign={"end"}
                              variant="subtitle2"
                              fontWeight={400}
                              color={"secondary"}
                              fontFamily={"monospace"}
                              value={order.averagePrice ?? order.originalPrice}
                              scale={currencyPairPriceScale}
                              currency={counterCurrency}
                              prefix={"@\u00a0"}
                              withZeros
                            />
                          </UsdWrapper>
                        </Stack>
                      </TableCell>
                    </>
                  ) : (
                    <>
                      <TableCell>
                        <Typography
                          variant="subtitle2"
                          fontWeight={400}
                          sx={{ color: orderStatusColors[order.status as keyof typeof orderStatusColors] }}>
                          {t(`dcaBots.runsList.dcaBotOrderStatus.${order.status}`)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <MaskedModeWrapper variant="subtitle2" fontWeight={400} color="secondary" compact>
                          <Typography variant="subtitle2" fontWeight={400} color={"secondary"} fontFamily={"monospace"}>
                            {`${renderNumber(
                              order.cumulativeValue == 0 ? order.originalValue : order.cumulativeValue,
                              counterCurrScale
                            )}\u00a0${counterCurrency}`}
                          </Typography>
                        </MaskedModeWrapper>
                      </TableCell>
                      <TableCell>
                        <Stack direction={"row"} spacing={1}>
                          <MaskedModeWrapper variant="subtitle2" fontWeight={400} color="secondary" compact>
                            <Typography variant="subtitle2" fontWeight={400} color={"secondary"} fontFamily={"monospace"}>
                              {`${order.side === "BUY" ? "" : "-"}${renderNumber(
                                order.status === "FILLED" ? order.cumulativeAmount : order.originalAmount,
                                currencyPairBaseScale,
                                true
                              )}\u00a0${baseCurrency}`}
                            </Typography>
                          </MaskedModeWrapper>
                          <UsdWrapper value={order.averagePrice ?? order.originalPrice} counterCurrency={counterCurrency} baseCurrency={baseCurrency}>
                            <TypographyItem
                              variant="subtitle2"
                              fontWeight={400}
                              color={"secondary"}
                              fontFamily={"monospace"}
                              value={order.averagePrice ?? order.originalPrice}
                              scale={currencyPairPriceScale}
                              currency={counterCurrency}
                              prefix={"@\u00a0"}
                              withZeros
                            />
                          </UsdWrapper>
                        </Stack>
                      </TableCell>
                      <TableCell>{cancelBtn()}</TableCell>
                    </>
                  )}
                </TableRow>
                {(order.error || order.errorCode) && (
                  <TableRow>
                    {!isMobileLayout() && <TableCell colSpan={1}></TableCell>}
                    <TableCell colSpan={3}>
                      <Typography variant="subtitle2" fontWeight={600} sx={{ color: colors.error }}>
                        {`${processErrorCode(order.errorCode, order.errorParameters, order.error)}`}
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </Box>
    </ItemBox>
  );
};

export default OpenLimitRow;
