import { useEffect, useState } from "react";

import { Box, Button, Stack, Typography } from "@mui/material";
import { cancelCreditsRecharge } from "API/calls";
import BackdropLoading from "components/elements/BackdropLoading";
import PageTitle from "components/elements/PageTitle";
import Panel from "components/elements/Panel";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import useConfirm from "hooks/useConfirm";
import { useIsLogin } from "hooks/useUser";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { StringParam, useQueryParams } from "use-query-params";

const CancelRecharge = () => {
  const [query, setQuery] = useQueryParams({ id: StringParam });
  const navigate = useNavigate();
  const isLoggedIn = useIsLogin();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [id] = useState<string>(query.id || "");
  const { colors } = useMUIThemeModeContext();
  const [isOpenBackdropLoading, setIsOpenBackdropLoading] = useState<boolean>(false);
  const { confirm } = useConfirm();

  useEffect(() => {
    if (isLoggedIn !== undefined) {
      if (!id && !isLoggedIn) navigate("/");
    }

    // to remove id query parameter from URL before it gets collected to Google Analytics
    setQuery({ id: undefined });
  }, [isLoggedIn]);

  const handleCancelRecharge = async () => {
    try {
      await confirm({
        title: t("recharge.confirmCancel.title"),
        description: t("recharge.confirmCancel.description"),
        dialogProps: { maxWidth: "sm" },
      });

      setIsOpenBackdropLoading(true);
      try {
        await cancelCreditsRecharge(!isLoggedIn ? id : undefined);
        enqueueSnackbar(t("recharge.confirmCancel.cancelSuccess"), { variant: "success" });
      } catch (error: any) {
        if (error?.response?.status === 404) {
          enqueueSnackbar(t("recharge.confirmCancel.error404"), { variant: "error" });
        } else {
          enqueueSnackbar(t("recharge.confirmCancel.cancelError"), { variant: "error" });
        }
      }
      setIsOpenBackdropLoading(false);
    } catch (err: any) {
      // silent
    }
  };

  return (
    <Box justifyContent="center" display={"flex"}>
      <PageTitle i18nKey="recharge.end" />
      <BackdropLoading open={isOpenBackdropLoading} />
      <Panel sx={{ p: 3, mb: 10, width: "100%", maxWidth: "sm" }}>
        <Stack direction={"column"}>
          <Typography variant="h5" component="h1" fontWeight={600} sx={{ color: "tertiary.main" }}>
            {t("recharge.end")}
          </Typography>
          <Typography variant="subtitle2" fontWeight={400} sx={{ py: 3 }}>
            {t("recharge.cancelPageText")}
          </Typography>
          <Button
            onClick={handleCancelRecharge}
            variant="outlined"
            color="inherit"
            sx={{ backgroundColor: colors.white, border: `1px solid ${colors.gray300}` }}>
            {t("recharge.cancelButton")}
          </Button>
        </Stack>
      </Panel>
    </Box>
  );
};
export default CancelRecharge;
