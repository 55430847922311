import currenciesMetadata from "constants/currenciesMetadata";

import React, { Children, isValidElement, cloneElement } from "react";

import { Typography, TypographyProps } from "@mui/material";
import { useUserDataContext } from "context/UserDataContext";
import useExchangeRate from "hooks/useExchangeRate";
import { renderNumber } from "utils/formatter";

interface IProps {
  children: React.ReactNode;
  value?: number | null;
  counterCurrency?: string;
  baseCurrency?: string;
  disabled?: boolean;
}

interface ITypographyItemProps extends TypographyProps {
  value?: number;
  currency?: string;
  scale: number;
  withZeros?: boolean;
  prefix?: string;
}

const ALLOWED_CURRENCIES = ["EUR", "CZK"];

export const TypographyItem = ({ value, currency, scale, withZeros, prefix, ...props }: ITypographyItemProps) => {
  return <Typography {...props} >{value ? `${prefix ? prefix : ""}${renderNumber(value, scale, withZeros)}\u00a0${currency}` : "-"}</Typography>;
};

const UsdWrapper = ({ children, value, counterCurrency, baseCurrency, disabled }: IProps) => {
  const { showInUSD } = useUserDataContext();
  const { getUSDOriginalPrice } = useExchangeRate();
  let childrenWithProps = children;

  if (showInUSD && ALLOWED_CURRENCIES.some((c) => c === counterCurrency) && !disabled) {
    childrenWithProps = Children.map(children, (child) => {
      if (isValidElement(child)) {
        const scale = currenciesMetadata.currency_pairs[`${baseCurrency}/USD` as keyof typeof currenciesMetadata.currency_pairs]?.price_scale ?? 0;

        return cloneElement<any>(child, {
          currency: "USD",
          value: value ? getUSDOriginalPrice(counterCurrency ?? "", value) : value,
          scale,
        });
      }
      return child;
    });
  }

  return <>{childrenWithProps}</>;
};

export default UsdWrapper;
