import { LinearProgress, LinearProgressProps, linearProgressClasses, styled } from "@mui/material";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { percentage } from "utils";

interface IBorderLinearProgressProps extends LinearProgressProps {
  type: "error" | "success" | "sats";
}

const BorderLinearProgress = styled(LinearProgress)<IBorderLinearProgressProps>(({ theme, value, type }) => {
  const { colors } = useMUIThemeModeContext();
  const basicBackgroundColor = type === "error" ? colors.error : colors.success;

  return {
    height: 6,
    borderRadius: 10,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: type === "sats" ? theme.palette.primary.main : basicBackgroundColor,
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRight: value && value < 100 ? `3px solid ${colors.gray100}` : undefined,
      backgroundColor: type === "sats" ? theme.palette.grey[600] : theme.palette.tertiary.main,
    },
  };
});

const LinearDifference = ({ value, currentValue, isSats, ...props }: LinearProgressProps & { currentValue?: number, isSats?: boolean }) => {
  const getValue = () => {
    if (value == null || currentValue == null) return 100;
    if (currentValue === 0) return 100;
    if (currentValue < value) return percentage(value, currentValue);

    return percentage(currentValue, value);
  };

  const getType = () => {
    if (isSats) return "sats";
    if (!value || !currentValue) return "success";

    return currentValue < value ? "error" : "success";
  };

  return <BorderLinearProgress {...props} type={getType()} variant="determinate" value={getValue()} />;
};

export default LinearDifference;
