import { AFFILIATE_BY_TTL } from "constants/localStorageExpiryDate";
import validations from "constants/validations";

import { useEffect, useState } from "react";

import {
  alpha,
  Button,
  CircularProgress,
  InputAdornment,
  Link,
  OutlinedInput,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { registerWithEmailOnly } from "API/calls";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import useKeyPress from "hooks/useKeyPress";
import { getWithExpiry } from "hooks/useTTLLocalStorage";
import { useSnackbar } from "notistack";
import { Trans, useTranslation } from "react-i18next";

import RegisterSuccess from "./RegisterSuccess";

interface IProps {
  textAlign?: "left" | "center" | "right";
  withBorder?: boolean;
  withBackground?: boolean;
}

const RegisterWithEmailOnly = ({ textAlign = "right", withBorder = false, withBackground = false }: IProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { colors } = useMUIThemeModeContext();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { enqueueSnackbar } = useSnackbar();
  const [email, setEmail] = useState<string>("");
  const [emailValidationError, setEmailValidationError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const keyPressEnter = useKeyPress("Enter");

  useEffect(() => {
    if ((emailValidationError && validations.email.test(email)) || email.length === 0) {
      setEmailValidationError(false);
    }
  }, [email, emailValidationError]);

  useEffect(() => {
    if (email.length && keyPressEnter) {
      handleRegister();
    }
  }, [keyPressEnter]);

  const handleRegister = async () => {
    if (!email) return;

    if (validations.email.test(email) === false) {
      setEmailValidationError(true);
      return;
    }

    setIsLoading(true);
    const affBy = getWithExpiry("affBy", AFFILIATE_BY_TTL);
    try {
      await registerWithEmailOnly(email, affBy ? affBy : undefined);
      setEmail("");
      enqueueSnackbar("", {
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
        autoHideDuration: 30000, // 30 seconds
        content: (key) => <RegisterSuccess id={key} />,
      });
    } catch (error: any) {
      if (error?.response?.status === 409) {
        enqueueSnackbar(t("registrationForm.userAlreadyExists"), { variant: "error" });
      } else {
        enqueueSnackbar(t("registrationForm.errorMessage"), { variant: "error" });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getBorder = () => {
    if (emailValidationError) return `1px solid ${theme.palette.error.main}`;

    return withBorder ? `1px solid ${alpha(colors["deepMidnightBlue"], 0.1)}` : 0;
  };

  return (
    <Stack spacing={1} textAlign={textAlign}>
      <OutlinedInput
        notched={false}
        fullWidth
        placeholder={t("homepage.register.email")}
        value={email}
        size={isMobile ? "small" : "medium"}
        onChange={(e) => setEmail(e.target.value)}
        disabled={isLoading}
        error={emailValidationError}
        endAdornment={
          <InputAdornment position="end">
            <Button
              variant="contained"
              size={isMobile ? "small" : "large"}
              onClick={handleRegister}
              disabled={isLoading}
              startIcon={isLoading ? <CircularProgress size={20} color="inherit" /> : undefined}
              sx={{
                "&.MuiButton-root": {
                  borderRadius: "12px",
                },
              }}>
              {t("homepage.register.button")}
            </Button>
          </InputAdornment>
        }
        sx={{
          paddingRight: "7px",
          borderRadius: "14px",
          bgcolor: withBackground ? colors["blueWhite"] : undefined,
          "& .MuiOutlinedInput-notchedOutline": {
            borderRadius: "14px",
            border: getBorder(),
          },
        }}
      />
      <Typography color={alpha(colors["blueGray"], 0.5)} fontSize={"0.75rem"}>
        <Trans
          i18nKey="homepage.register.info"
          components={{
            a: <Link href={"/podminky"} target={"_blank"} color={"inherit"} />,
          }}
        />
      </Typography>
    </Stack>
  );
};

export default RegisterWithEmailOnly;
