import { subscriptionDcaBots, subscriptionBots } from "constants/subscriptions";

import React from "react";

import useTheme from "@mui/material/styles/useTheme";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { SubscriptionType } from "types/enums";

interface IProps {
  subscriptionType: SubscriptionType;
  id: string;
}

const getConstants = (id: string) => {
  if (id === "bots") return subscriptionBots;
  else return subscriptionDcaBots;
};

const SubscriptionLimitMsg = ({ subscriptionType, id }: IProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <div>
      {t(`${id}.create.subscriptionLimit.with`)}{" "}
      <Link to="/predplatne" style={{ color: theme.palette.primary.main }}>
        {subscriptionType}
      </Link>{" "}
      {t(`${id}.create.subscriptionLimit.only${subscriptionType}`, {
        number: getConstants(id)[subscriptionType],
      })}{" "}
      {subscriptionType !== "PREMIUM" && (
        <Link to="/predplatne" style={{ color: theme.palette.primary.main }}>
          {t(`${id}.create.subscriptionLimit.question`)}
        </Link>
      )}
    </div>
  );
};

export default SubscriptionLimitMsg;
