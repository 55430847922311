import React, { useState } from "react";

import { Link, Box, useTheme } from "@mui/material";
import { t } from "i18next";

const MoreInfo: React.FC = ({ children }) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const onClick = (e: any) => {
    e.preventDefault();
    setOpen(!open);
  };
  return (
    <Box>
      <Link href="#" onClick={onClick} sx={{ color: theme.palette.secondary.contrastText, fontSize: "14px" }}>
        {open ? t("tutorial.close") : t("tutorial.open")}
      </Link>
      {open && <Box sx={{ py: 1 }}>{children}</Box>}
    </Box>
  );
};

export default MoreInfo;
