import * as React from "react";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { styled, useMediaQuery, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { badgesPATCH } from "API/calls";
import Panel from "components/elements/Panel";
import { Award03, CreditMenuDark, CreditShadow } from "components/icons";
import { CreditInverted } from "components/icons/components/CreditInverted";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { useUserDataContext } from "context/UserDataContext";
import { useAPIBadges, useAPIBadgeTypes } from "hooks/useAPI";
import { useTranslation, Trans } from "react-i18next";
import { Link } from "react-router-dom";
import { BadgeState, BadgeType } from "types/enums";
import { getComponentByMode } from "utils";

import ErrorBox from "../ErrorBox";
import Loading from "../Loading";

export const TileBox = styled(Box)<{ border: string; background?: string; mobile: boolean }>(
  ({ border, background, mobile }) => ({
    border: border,
    borderRadius: "10px",
    padding: mobile ? "4px" : "12px",
    background: background,
    height: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    maxHeight: "54px",
    textAlign: "start",
  })
);

const BadgesTable: React.FC = () => {
  const { colors } = useMUIThemeModeContext();
  const theme = useTheme();
  const { t } = useTranslation();
  const [showAll, setShowAll] = React.useState<boolean>(false);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(330));
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const badgeTypesRequest = useAPIBadgeTypes();
  const badgesRequest = useAPIBadges();
  const { refreshData } = useUserDataContext();

  const badges = badgesRequest.data?.badges;
  const badgeTypes = badgeTypesRequest.data?.badgeTypes;

  function getBadges(type: string) {
    return badges?.find((badges) => badges.type === type);
  }

  async function onClaim(type: BadgeType) {
    badgesPATCH(type, BadgeState.CLAIMED).finally(() => {
      refreshData?.();
      badgesRequest.mutate();
    });
  }

  const showAllBadges = () => setShowAll(true);

  if (badgeTypesRequest.error || badgesRequest.error) {
    return (
      <>
        {!!badgeTypesRequest.error && <ErrorBox>{badgeTypesRequest.error.message}</ErrorBox>}
        {!!badgesRequest.error && <ErrorBox>{badgesRequest.error.message}</ErrorBox>}
      </>
    );
  }
  if (!badgeTypesRequest.data && !badgesRequest.data) return <Loading />;

  return (
    <Panel sx={{ mb: 4, p: { xs: 2, md: 3 } }} id="odznaky">
      <Typography variant="h5" component="h1" fontWeight={600} sx={{ pb: 1, color: "tertiary.main" }}>
        {t("badgesTable.title")}
      </Typography>
      <Typography variant="subtitle2" fontWeight={400} sx={{ pb: 3 }}>
        <Trans
          i18nKey="badgesTable.subtitle"
          components={{
            a: <Link style={{ color: theme.palette.primary.main }} to="/predplatne" />,
          }}
        />
      </Typography>
      <Grid container spacing={1}>
        {badgeTypes?.slice(0, showAll ? Infinity : 6).map((item) => {
          const state = getBadges(item.type)?.state;
          return (
            <Grid item xs={12} lg={6} sx={{ textAlign: "center" }} key={item.type}>
              {state === BadgeState.UNCLAIMED && (
                <TileBox
                  mobile={isSmallScreen}
                  border={`2px solid ${theme.palette.primary.background}`}
                  background={theme.palette.primary.background}>
                  <Typography color={"primary"} fontSize={{ xs: "12px", sm: "14px" }}>
                    {t("badgeType." + item.type)}
                  </Typography>
                  <Button
                    sx={{ ml: 1 }}
                    variant="contained"
                    size="small"
                    onClick={() => onClaim(item.type)}
                    endIcon={
                      <CreditInverted className="animate__animated animate__slow animate__heartBeat animate__infinite" />
                    }>
                    {item.credits}
                  </Button>
                </TileBox>
              )}
              {state === BadgeState.CLAIMED && (
                <TileBox mobile={isSmallScreen} border={`2px solid rgba(109, 109, 109, .1)`}>
                  <Typography color={colors.success} fontSize={{ xs: "12px", sm: "14px" }}>
                    {t("badgeType." + item.type)}
                  </Typography>
                  <Award03 sx={{ fontSize: 30, ml: 1, color: colors.success }} />
                </TileBox>
              )}
              {state !== BadgeState.CLAIMED && state !== BadgeState.UNCLAIMED && (
                <TileBox mobile={isSmallScreen} border={`2px solid rgba(109, 109, 109, .1)`}>
                  <Typography color={"#6D6D6D"} fontSize={{ xs: "12px", sm: "14px" }}>
                    {t("badgeTypeAction." + item.type)}
                  </Typography>
                  <Button
                    sx={{ ml: 1 }}
                    variant="contained"
                    size="small"
                    disabled
                    endIcon={getComponentByMode(theme, <CreditShadow />, <CreditMenuDark />)}>
                    {item.credits}
                  </Button>
                </TileBox>
              )}
            </Grid>
          );
        })}
      </Grid>
      {!showAll && (
        <Button
          sx={{ mt: 2, px:0 }}
          fullWidth={isMobile}
          onClick={showAllBadges}
          variant="text"
          color="inherit"
          endIcon={<KeyboardArrowDownIcon />}>
          {t("badge.showAll", { count: badgeTypes?.length })}
        </Button>
      )}
    </Panel>
  );
};

export default BadgesTable;
