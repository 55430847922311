import imageKicom from "assets/images/partners/kicom.png";
import imageInvesticniPodcast from "assets/images/partners/vojta-zizka.png";

export const mainReferences = [
  {
    text: "Jediná možnost, jak dlouhodobě spořit do Bitcoinu a nezbláznit se z toho, je nakupovat postupně metodou DCA. Proto podporuji Štosuj.cz!",
    author: {
      name: "Jakub 'Kicom' Vejmola",
      title: "Bitcoinovej kanál",
      image: imageKicom,
    },
  },
  {
    text: "Trh zásadně nečasuji. Pro většinu investorů do Bitcoinu je nejlepší metoda DCA, kterou Štosuj.cz dotáhlo k dokonalosti.",
    author: {
      name: "Vojta Žižka",
      title: "Investiční podcast",
      image: imageInvesticniPodcast,
    },
  },
];

export const references = [
  { rating: 10, name: "Robert H.", text: "Dvěma slovy: Prostě skvělé!" },
  {
    rating: 8,
    name: "Patrik K.",
    text: "Štosuj je super platforma pro lidi, jak už název projektu napovídá, kteří chtějí štosovat. Ale pro ty, kteří by rádi klasickou formu DCA posunuli ještě o kus dál, naučili se novým věcem a objevili neznámé, pro takové je Štosuj must have věc.💪",
  },
  { rating: 10, name: null, text: "Moc se mi líbí vás nápad. Myslím, že to tu chybělo." },
  {
    rating: 10,
    name: "Miricius",
    text: "Štosuj.cz jsem podpořil už na Hithitu a jsem s nimi pořád. Skvělá platforma pro nastavení DCA, navíc už nabízí i dynamickou DCA strategii. A díky nové grid strategii můžete těžit i z krátkodobé volatility! Můžu doporučit všemi deseti!",
  },
  { rating: 10, name: "Kufčíček", text: "Na pravidelný nákup bitcoinu jedině Štosuj!" },
  {
    rating: 9,
    name: null,
    text: "Skvělá služba, která ulehčuje pravidelné investování za rozumných podmínek. Pro většinu, která začíná s investicí do bitcoinu a nechce se o nic starat je to must.",
  },
  { rating: 10, name: null, text: "Super funkce. Nakupuji denně a bez emocí. Radost z dokupu při poklesu ceny." },
  {
    rating: 10,
    name: "Tomáš J.",
    text: "Hledal jsem řešení 'nastav a zapomeň', pro dlouhodobé spoření dětem. Na Štosuj jsem ho konečně našel!",
  },
  {
    rating: 10,
    name: null,
    text: "Super služba, díky které si stačí nastavit trvalý příkaz a už se nemusím o nic starat.",
  },
  {
    rating: 8,
    name: null,
    text: "Bohužel dlouho jsem váhal s investicí do BTC, ale Štosuj mě nadchlo a zalíbilo se mi. Vřele doporučuji i pro ostatní, kteří tak dlouho váhali.",
  },
  {
    rating: 8,
    name: null,
    text: "Ze začátku jsem si zřídil účet na Štosuj jen ze zvědavosti, ale postupně jsem opravdu ocenil, že se o nic krom doplňování peněz na burzu nemusím starat.",
  },
  {
    rating: 10,
    name: "Pavel P.",
    text: "Na krypto jsem zanevřel, ale když jsem se seznámil s automatickým nakupováním a gridem, jaké má výhody, tak jsem si řekl, že už nebudu muset řešit stres a stále koukat na kurz, ale budu se držet plánu. Dík!",
  },
  {
    rating: 10,
    name: "Michal S.",
    text: "Štosuj je (mimo jiné) skvělý nástroj pro každého, kdo si váží svého času a nechce se aktivně starat o své portfolio.",
  },
  {
    rating: 10,
    name: null,
    text: "Nastavit Štosuj.cz a zapomenout! Ideální způsob, jak pravidelně nakupovat bitcoin.",
  },
  {
    rating: 10,
    name: null,
    text: "Děkuji za skvělý nástroj, který mi pomáhá nestresovat se a pravidelně nakupovat tak, jak potřebuji. Další vytuněné funkce jako grid a dynamický nákup jen potvrzují to, že borci vědí, co dělají.",
  },
  { rating: 10, name: null, text: "Jste skvělí a děláte to dobře. Jsem rád, že jste součástí mé cesty Bitcoinem!" },
  {
    rating: 10,
    name: "Pavel D.",
    text: "Díky Štosuj jsem opravdu schopen štosovat a nepřemýšlet nad tím. Tato automatizace se opravdu velmi povedla. Díky za vše.",
  },
  {
    rating: 10,
    name: "Petr S.",
    text: "Stosuj.cz je skvělý, fungující, jednoduchý a spolehlivý způsob automatizace nákupů BTC strategií (dle mého názoru nejlepší) DCA. Eliminuji tak emoce, které jsou téměř vždy k neprospěchu dobrých investic.",
  },
  {
    rating: 8,
    name: null,
    text: "Používám Štosuj už víc jak rok a díky přehlednosti a jednoduchosti je to pro mě vedle Trezoru nejdůležitější nástroj pro nákup a správu BTC.",
  },
  {
    rating: 10,
    name: null,
    text: "Líbí se mi možnost automatizace nakupování BTC metodou DCA. A ještě více oceňuji grid strategii, kdy si člověk v klidu může rozmyslet a nastavit strategii a nenechat se ovlivnit emocemi, když cena BTC roste.",
  },
  {
    rating: 9,
    name: "Radim B.",
    text: "Všem doporučuji Štosuj.cz vyzkoušet. Sami zjistíte, že to je velice pohodlné a velice přehledné. Každý se rád podívá na svůj rostoucí graf a díky DCA to je skvělá podívaná.",
  },
  {
    rating: 10,
    name: "Vladimír K.",
    text: "Ahoj, fandím lidem jako je Luboš. To, co dělá, je skvělá věc a dělá to výborně a jsem pyšný, že je z České republiky. Baví mě neskutečně.",
  },
  {
    rating: 9,
    name: "Marian V.",
    text: "Platforma na automatizáciu nákupov, predajov, tradingu a ďalších vecí spojených s kryptom. Vie ušetriť pomerne dosť času.",
  },
  {
    rating: 9,
    name: "Vladimír Z.",
    text: "Využívám Štosuj.cz dlouhodobě pod několika účty a rozhodně to stojí za to. Pravidelná DCA strategie nákupu přes burzu a grid strategie jsou super.",
  },
  { rating: 10, name: null, text: "Odvádíte excelentní práci se Štosuj. Maximální spokojenost!" },
  { rating: 10, name: null, text: "Štosuj dělá přesně to, co já nedokážu. Štosovat saty bez emocí!" },
  {
    rating: 10,
    name: "Snowman",
    text: "Skvělá služba pro člověka, který chce mít expozici v Bitcoinu. Vše se dá hezky automatizovat, nastavit. Skvělá práce!",
  },
  {
    rating: 10,
    name: "Šimi",
    text: "Nejlepší služba na štosování satů. Pojďte si taky nasadit ty laserový oči. Díky Luboši!",
  },
  {
    rating: 10,
    name: "Lukáš R.",
    text: "Štosuj je super aplikace, díky které můžu štosovat saty na denní bázi, případně jedním kliknutím okamžitě dokoupit individuálně tak jak potřebuji. Oceňuji i přehled jak si vede moje portfolio. Za mě super appka.",
  },
  {
    rating: 9,
    name: "Martin T.",
    text: "Štosuj používám od úplného začátku jako účastník Hithit kampaně, jelikož jsem to považoval za velmi přínosné. V té době ještě burzy/směnárny DCA nákupy nenabízely a taková přehledná stránka v CZ kde jsi vše jednoduše nastavit se hodí. Je super, že se stále rozšiřuje a přibývají nové funkce, které mají smysl (třeba GRID). Díky Luboši!",
  },
  {
    rating: 8,
    name: "Milan S.",
    text: "Štosuj.cz jednoznačne za mňa perfektná vec. Ulahčuje mi nakupy a sporenie do krypta a šetrí mi čas.",
  },
  {
    rating: 10,
    name: "Jindřich H.",
    text: "Jsem rád za spousty rad do začátků, ať psaných nebo ve videích. Hodně mi to pomohlo, děkuji.",
  },
  { rating: 10, name: "Ondřej K.", text: "Vynikající služba, skvělá podpora. Rozhodně doporučuji!" },
  {
    rating: 9,
    name: "Matěj",
    text: "Štosuj je skvělé. Nemusíte se o nic starat a jen hromadíte BTC tím nejlepším způsobem, DCA.",
  },
  { rating: 10, name: null, text: "Díky Štosuj jsem konečně začal investovat do Bitcoinu." },
  {
    rating: 10,
    name: "Pavel D.",
    text: "Moc Vám přeji ať nadšení pro projekt neopadne a je fajn že děláte co děláte.",
  },
  { rating: 9, name: "Martin B.", text: "Konečně jednoduché a pravidelné nákupy BTC bez emocí. Díky Štosuj.cz!" },
  {
    rating: 10,
    name: "Flykite",
    text: "Štosuj používám už skoro rok a líbí se mi jak kluci tento projekt posouvají dál a dál. Nové funkce jsou super, ale nejlepší je, že si vše nastavím jak potřebuji a už se nemusím o nic starat. Vše je automatizované do posledního puntíku. Díky kluci!",
  },
  { rating: 10, name: null, text: "Super služba pro někoho, kdo rád věci jednou nastaví a už to nemusí řešit!" },
  {
    rating: 9,
    name: "Jakub",
    text: "Štosuj dělá pravidelné nakupování BTC naprosto primitivní. Jednodušší uživatelskou zkušenost těžko pohledat.",
  },
  {
    rating: 10,
    name: "Tomáš Z.",
    text: "Pro zaměstnaného člověka, který má v otázce Bitcoinu jasno a chce dlouhodobě střádat saty se jedná o fantastickou službu. Jednou nastavit, nic víc neřešit a užít si chytré, automatické DCA. Díky.",
  },
  {
    rating: 10,
    name: "Tomáš R.",
    text: "Štosuj je plnohodnotným nástrojem na správu kryptoměn. Rozhraní je jednodušší než na směnárnách a umožňuje tak jejich pohodlný nákup. Díky Štosuj už na stránky směnáren nechodím, vše dokáži ovládat odsud. A navíc automaticky!",
  },
  {
    rating: 10,
    name: "Vladimír D.",
    text: "Nějakou dobu jsem se věnoval intradennímu tradingu. Pak jsem ale pochopil, že nejcennější lidské aktivum je čas. A v jednoduchosti (DCA strategie) je síla. Naklikal jsem si pár cílů a kupuju BTC a ETH, veškerou práci za mne stosuj.cz odmaká a já se můžu věnovat ženě, dětem a rodině. Váš projekt mi zajišťuje lepší život <3 Díky za něj! <3",
  },

  {
    rating: 10,
    name: "Venca Z.",
    text: "Díky za perfektní službu, využívám už přes rok a každemu, kdo si BTC kupuje, doporučuji.",
  },
  {
    rating: 9,
    name: "Radek H.",
    text: "Super jednoduché k pochopení. Snadné na používání. Doporučuji! Je vidět, že to dělaj srdcaři a ne flexeři :-)",
  },
  {
    rating: 10,
    name: "Adam Č.",
    text: "Děkuji za Váš čas a práci v oblasti edukace a osvěty BTC v ČR. Myslím, že v ČR můžeme být hrdí na to, že máme takové chytré hlavy / kapacity, jako jste Vy.",
  },
  {
    rating: 9,
    name: "Přemek V.",
    text: "Štosuj je ideální nástroj jak začít spořit do Bitcoinu pomocí jednoduchých strategií.",
  },
  {
    rating: 9,
    name: "Tom S.",
    text: "Štosuj je pro mě skvělým nástrojem, jak bezstarostně nakupovat BTC a zároveň mít přehled o svém portfoliu.",
  },
  {
    rating: 10,
    name: null,
    text: "Vše co neznám je pro mě magie, ale vy mi ji pomáháte pochopit logikou, díky za to.",
  },
  {
    rating: 9,
    name: null,
    text: "Štosuj používáme na spoření pro děti, polovinu jim spoříme konzervativně a druhou do BTC na Štosuj. Úžasné vidět ten rozdíl ve výkonu moderních a standardních finančních instrumentů.",
  },
  {
    rating: 10,
    name: "Daniel Š.",
    text: "Moc cením zpracování a hlavně možnost FREE verze aplikace. Podle mého moc hezký a hlavně český projekt. Vše působí hodně přátelsky, žádný korporát :)",
  },
  {
    rating: 10,
    name: null,
    text: "Skvělá aplikace pro investování bez emocí. Navíc grid strategie pro pocit, že i aktivně zhodnocuju při čekání na price action.",
  },
  {
    rating: 10,
    name: null,
    text: "Super služba, na kterou jsem čekal. Přehledná, a stále zdokonaluje všechny své možnosti. Doporučuji všem kteří hledají bezstarostný nákup a nechtějí si vysedět pleš jako já kdysi.",
  },
];
