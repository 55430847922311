import { Button, Stack } from "@mui/material";
import Modal from "components/elements/Modal";
import { useTranslation } from "react-i18next";

interface IProps {
  onCancel: () => void;
  onSave: () => void;
  onSaveAndEnable: () => void;
}

const UpdateModal = ({ onCancel, onSave, onSaveAndEnable }: IProps) => {
  const { t } = useTranslation<string>();

  return (
    <Modal
      open={true}
      size="sm"
      onCancel={onCancel}
      title={t("gridBot.update.updateModal.title")}
      description={t("gridBot.update.updateModal.description")}
      withCloseButton
      disableCancelButton
      customActions={
        <Stack direction={{ xs: "column", sm: "row" }} spacing={1} width={{ xs: "100%", sm: "unset" }}>
          <Button color="error" onClick={onCancel}>
            {t("cancel")}
          </Button>
          <Button variant="outlined" onClick={onSave}>
            {t("gridBot.update.updateModal.onlySave")}
          </Button>
          <Button variant="contained" onClick={onSaveAndEnable}>
            {t("gridBot.update.updateModal.saveAndEnable")}
          </Button>
        </Stack>
      }
    />
  );
};

export default UpdateModal;
