import { useEffect, useState } from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import {
  Box,
  Button,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  Link as MUILink,
  GlobalStyles,
  useTheme,
} from "@mui/material";
import AspectRatioWrapper from "components/elements/AspectRatioWrapper";
import Card from "components/elements/Card";
import Gallery from "components/elements/Gallery";
import Panel from "components/elements/Panel";
import Shadow from "components/elements/Shadow";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import { Link } from "react-router-dom";
import { getComponentByMode, whyBitcoinRoute } from "utils";

import ChapterItem from "./ChapterItem";
import useCourseDetail from "./module/useCourseDetail";

const CourseDetail = () => {
  const { t } = useTranslation<string>();
  const theme = useTheme();
  const headerBreakpoint = useMediaQuery("(max-width:425px)");
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [readable, setReadable] = useState({ md: "" });
  const {
    pathname,
    course,
    chapter,
    isFirstChapter,
    isLastChapter,
    existNextCourse,
    handleNextChapter,
    handleNextCourse,
    handlePreviousChapter,
    globalStyle,
  } = useCourseDetail();

  useEffect(() => {
    const topPos = document?.getElementById("active-chapter")?.offsetTop;
    const container = document?.getElementById("chapter-container");

    if (container && topPos) container.scrollTop = topPos - 80;
  }, []);

  useEffect(() => {
    const fetchMDData = async () => {
      const module = await import(`../WhyBitcoin/config/courses/${course?.directory}/${chapter?.file}.md`);

      fetch(module.default)
        .then((res) => res.text())
        .then((md) => {
          setReadable({ md });
        });
    };

    if (chapter?.file.length) fetchMDData();
  }, [chapter?.file]);

  const isPreviousChapter = (index: number) => {
    const currentChapterIndex = course?.chapters.findIndex((chapter) => whyBitcoinRoute(chapter.url) === pathname) ?? 0;

    return index < currentChapterIndex;
  };

  const controls = (pt: number, pb?: number, hasTextSummary?: boolean) => (
    <Stack display={"flex"} spacing={2} alignItems="center" sx={{ pt, pb }}>
      <Stack spacing={2} direction="row">
        {!isFirstChapter && (
          <Button
            variant="contained"
            sx={{ backgroundColor: "#E0E0E0", color: "black" }}
            onClick={handlePreviousChapter}>
            <Typography fontSize={{ xs: "10px", sm: "14px" }}>{t("whyBitcoin.prevChapter")}</Typography>
          </Button>
        )}
        {!isLastChapter && (
          <Button variant="contained" onClick={handleNextChapter}>
            <Typography fontWeight={400} fontSize={{ xs: "10px", sm: "14px" }}>
              {t("whyBitcoin.nextChapter")}
            </Typography>
          </Button>
        )}
        {isLastChapter && existNextCourse && (
          <Button variant="contained" onClick={handleNextCourse}>
            <Typography fontWeight={400} fontSize={{ xs: "10px", sm: "14px" }}>
              {t("whyBitcoin.nextCourse")}
            </Typography>
          </Button>
        )}
      </Stack>
      {hasTextSummary && (
        <Button href="#textSummary" color="secondary" endIcon={<ExpandMoreIcon />}>
          <Typography fontWeight={400} fontSize={{ xs: "12px", sm: "14px" }}>
            {t("whyBitcoin.textSummary")}
          </Typography>
        </Button>
      )}
    </Stack>
  );

  return (
    <>
      {getComponentByMode(theme, <GlobalStyles styles={globalStyle} />, null)}
      <Stack direction={headerBreakpoint ? "column-reverse" : "row"} justifyContent="space-between">
        <Typography variant="h4" color={theme.palette.tertiary.main} fontWeight={600}>
          {course?.title}
        </Typography>
        <Button
          color="secondary"
          sx={{ backgroundColor: "tertiary.main", color: "white", mb: headerBreakpoint ? 4 : 0 }}
          component={Link}
          startIcon={<KeyboardArrowLeftIcon />}
          to={"/proc-bitcoin"}
          variant="contained">
          {t("whyBitcoin.back")}
        </Button>
      </Stack>
      <Grid container spacing={2} pt={2}>
        <Grid item xs={12} md={6} lg={7}>
          <Shadow sx={{ borderRadius: 3, overflow: "hidden", height: isMobile ? undefined : "100%" }}>
            <AspectRatioWrapper aspectRatio={"16 / 9"} withAspectRatio={isMobile}>
              <iframe
                width="100%"
                src={`https://www.youtube-nocookie.com/embed/${chapter?.youtubeId}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                style={
                  isMobile ? { display: "block", aspectRatio: "16 / 9" } : { display: "block", height: "100%" }
                }></iframe>
            </AspectRatioWrapper>
          </Shadow>
        </Grid>
        <Grid item xs={12} md={6} lg={5}>
          {isMobile && controls(0, 2, true)}
          <Panel p={1} height={{ xs: undefined, md: "350px" }}>
            <Stack spacing={1} height={"100%"} overflow={{ xs: "unset", md: "auto" }} id={"chapter-container"}>
              {course?.chapters.map((chapter, index) => (
                <ChapterItem
                  key={`${chapter.title}-${index}`}
                  chapter={chapter}
                  isActiveChapter={pathname === whyBitcoinRoute(chapter.url)}
                  isPreviousChapter={isPreviousChapter(index)}
                />
              ))}
            </Stack>
          </Panel>
          {!isMobile && controls(2)}
        </Grid>
        <Grid item xs={12}>
          <Card sx={{ mb: 0, color: "secondary.main" }} header={chapter?.title} id={"textSummary"}>
            <ReactMarkdown
              components={{
                h1: ({ children }) => (
                  <Typography variant="h5" component="h1" fontWeight={600} sx={{ color: "tertiary.main" }}>
                    {children}
                  </Typography>
                ),
                h2: ({ children }) => (
                  <Typography py={1} variant="h6" component="h2" fontWeight={600} sx={{ color: "tertiary.main" }}>
                    {children}
                  </Typography>
                ),
                h3: ({ children }) => (
                  <Typography py={1} variant="h6" component="h3" fontWeight={600} sx={{ color: "secondary.main" }}>
                    {children}
                  </Typography>
                ),
                h4: ({ children }) => (
                  <Typography py={1} variant="body1" component="h4" fontWeight={600} sx={{ color: "secondary.main" }}>
                    {children}
                  </Typography>
                ),
                p: ({ children }) => (
                  <Typography py={1} variant="body1" component="div" sx={{ color: "secondary.main" }}>
                    {children}
                  </Typography>
                ),
                pre: ({ children }) => (
                  <Box p={1} bgcolor={"#F1F1F1"}>
                    <Typography variant="body1" sx={{ color: "#6D6D6D" }}>
                      {children}
                    </Typography>
                  </Box>
                ),
                a: ({ children, href }) => (
                  <MUILink color={"inherit"} href={href} target={"_blank"}>
                    {children}
                  </MUILink>
                ),
                img: ({ src, alt }) => <Gallery src={src} alt={alt} />,
              }}
              children={readable.md}
            />
            {controls(4)}
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default CourseDetail;
