import React from "react";

import { AppBar, Box, Button, Container, Stack, alpha, styled, useMediaQuery, useTheme } from "@mui/material";
import { ReactComponent as Logo } from "assets/logo.svg";
import UserProfile from "components/elements/UserProfile";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { useIsLogin } from "hooks/useUser";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

interface ICustomButtonProps {
  children: React.ReactNode;
  variant: "text" | "outlined" | "contained";
  to: string;
  sx?: any;
  size?: "small" | "medium" | "large";
  target?: React.HTMLAttributeAnchorTarget;
}

export const HeaderButton = styled((props: ICustomButtonProps) => <Button component={Link} {...props} />)(() => {
  const { colors } = useMUIThemeModeContext();

  return {
    "&.MuiButton-text": {
      color: colors["deepMidnightBlue"],
      "&:hover": {
        backgroundColor: alpha(colors["deepMidnightBlue"], 0.05),
      },
    },
    "&.MuiButton-contained": {
      backgroundColor: colors["deepMidnightBlue"],
      "&:hover": {
        backgroundColor: alpha(colors["deepMidnightBlue"], 0.5),
      },
    },
    "&.MuiButton-outlined": {
      color: colors["deepMidnightBlue"],
      border: `1px solid ${alpha(colors["blueBlack"], 0.1)}`,
      "&:hover": {
        backgroundColor: alpha(colors["deepMidnightBlue"], 0.1),
        border: `1px solid ${alpha(colors["blueBlack"], 0.5)}`,
      },
    },
  };
});

const Header: React.FC = () => {
  const { t } = useTranslation();
  const isLogin = useIsLogin();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const registerButtonBreakpoint = useMediaQuery(theme.breakpoints.down(720));
  const hideWhyBitcoinBreakpoint = useMediaQuery(theme.breakpoints.down(900));
  const isTinyScreen = useMediaQuery(theme.breakpoints.down(360));

  return (
    <AppBar position="absolute" elevation={0} sx={{ backgroundColor: "unset" }}>
      <Container sx={{ pt: "24px" }}>
        <Box
          py={2}
          px={{ xs: 2, md: 6 }}
          bgcolor={"white"}
          borderRadius={"24px"}
          boxShadow={theme.shadows[24]}
          display={"flex"}
          alignContent={"center"}
          alignItems={"center"}>
          <Link to={isLogin ? "/dashboard" : "/"} style={{ display: "flex" }}>
            <Logo height={isMobile ? "24" : "33"} />
          </Link>
          <Box flexGrow={1} display={"flex"} justifyContent={"center"}>
            {!isMobile && (
              <Stack spacing={{ xs: 1, md: 3 }} direction={"row"}>
                <HeaderButton variant="contained" to={"#vyber-strategie"}>
                  {t("header.strategies")}
                </HeaderButton>
                <HeaderButton variant="text" to={"#cenik"}>
                  {t("header.pricing")}
                </HeaderButton>
                {!hideWhyBitcoinBreakpoint && (
                  <HeaderButton variant="text" to={"/proc-bitcoin"}>
                    {t("header.whyBitcoin")}
                  </HeaderButton>
                )}
              </Stack>
            )}
          </Box>
          {isLogin === false && (
            <Stack spacing={{xs: 1, md: 2}} direction="row">
              <HeaderButton variant="outlined" size={isMobile ? "small" : "medium"} to={"/login"}>
                {t("login")}
              </HeaderButton>
              {(!registerButtonBreakpoint || (isMobile && !isTinyScreen)) && (
                <HeaderButton variant="contained" size={isMobile ? "small" : "medium"} to={"/registrace"}>
                  {t("registration")}
                </HeaderButton>
              )}
            </Stack>
          )}
          {isLogin === true && <UserProfile disableThemeMode />}
        </Box>
      </Container>
    </AppBar>
  );
};

export default Header;
