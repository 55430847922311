import { Box, useTheme } from "@mui/material";
import HeroDashboard from "assets/images/hero-dashboard.png";
import VideoPoster from "assets/images/hero-video-poster.png";
import MobileFrame from "assets/images/mobile-frame.png";
import Video from "assets/videos/hero.mp4";

const HeroMobileWithDashboard = () => {
  const theme = useTheme();

  return (
    <Box position={"relative"}>
      <img
        style={{ width: "100%", boxShadow: theme.shadows[24], borderRadius: 10 }}
        src={HeroDashboard}
        alt="mobile-frame"></img>
      <img
        style={{
          position: "absolute",
          height: "110%",
          top: "10%",
          right: "5%",
          zIndex: 2,
        }}
        src={MobileFrame}
        alt="mobile-frame"></img>
      <video
        style={{
          height: "82%",
          position: "absolute",
          right: "15%",
          top: "23%",
        }}
        autoPlay
        muted
        loop
        poster={VideoPoster}
        src={Video}></video>
    </Box>
  );
};

export default HeroMobileWithDashboard;
