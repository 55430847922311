import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { styled } from "@mui/material/styles";
import Slider from "components/elements/Slider";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { getColorByMode } from "utils";

export const Dot = styled(FiberManualRecordIcon)({
  color: "#374151",
  height: "12px",
  width: "12px",
  margin: "8px",
});

export const CustomSlider = styled(Slider)<{ increase?: number; basic?: number; bgcolor?: string }>(
  ({ theme, increase, basic, bgcolor = "gray200" }) => {
    const { colors } = useMUIThemeModeContext();

    return {
      color: theme.palette.primary.main,
      height: 8,
      "& .MuiSlider-rail": {
        backgroundColor: increase ? getColorByMode(theme, colors[bgcolor], colors.gray400) : undefined,
        opacity: increase ? 1 : undefined,
      },
      "& .MuiSlider-track": {
        border: "none",
        backgroundColor: increase ? undefined : getColorByMode(theme, colors[bgcolor], colors.gray400),
      },
      "& .MuiSlider-mark": {
        display: basic ? undefined : "none",
      },
      "& .MuiSlider-thumb": {
        height: 24,
        width: 24,
        backgroundColor: colors.white,
        border: "4px solid currentColor",
        "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
          boxShadow: "inherit",
        },
        "&:before": {
          display: "none",
        },
      },
      "& .MuiSlider-valueLabel": {
        backgroundColor: "#101828",
        borderRadius: "10px",
        padding: "8px 12px",
        fontWeight: 600,
        fontSize: "12px",
      },
      "& .MuiSlider-markLabel": {
        fontWeight: 500,
        fontWize: "12px",
        lineHeight: "16px0",
        letterSpacing: "0.005em",
        color: colors.black,
      },
    };
  }
);
