import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Panel from "components/elements/Panel";
import Shadow from "components/elements/Shadow";

export const YoutubeShadow = styled(Shadow)({
  borderTopLeftRadius: 12,
  borderTopRightRadius: 12,
  overflow: "hidden",
});

export const FirstCoursePanel = styled(Panel)({
  borderBottomLeftRadius: "10px !important",
  borderBottomRightRadius: "10px !important",
  overflow: "hidden",
});

export const UnderlineTypography = styled(Typography)({
  position: "relative",
  marginRight: "1rem",

  "&::after": {
    content: '""',
    position: "absolute",
    bottom: "-5px",
    left: "0",
    height: "10px",
    width: "100%",
    border: "solid 4px",
    borderColor: "currentColor transparent transparent transparent",
    borderRadius: "50%",
  },
});
