import "dayjs/locale/cs";
import "dayjs/locale/sk";
import TextField from "@mui/material/TextField";
import { DateTimePicker, DateTimePickerProps } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Dayjs } from "dayjs";
import { getBrowserLocale } from "utils";

interface IProps extends Omit<DateTimePickerProps<Dayjs | null, Dayjs | null>, "renderInput"> {
  error?: string | boolean;
}

const LocalizedDateTimePicker = ({ error, ...props }: IProps) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={getBrowserLocale()}>
      <DateTimePicker
        {...props}
        renderInput={(params) => (
          <TextField
            {...params}
            error={Boolean(error)}
            helperText={error}
            inputProps={{
              ...params.inputProps,
              autoComplete: "off",
            }}
          />
        )}
        inputFormat={"D. M. YYYY HH:mm"}
      />
    </LocalizationProvider>
  );
};

export default LocalizedDateTimePicker;
