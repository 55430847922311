import { Box, CircularProgress, CircularProgressProps, Typography, circularProgressClasses } from "@mui/material";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";

const CircularProgressWithLabel = ({ value, ...props }: CircularProgressProps & { value: number }) => {
  const { colors } = useMUIThemeModeContext();
  
  return (
    <Box sx={{ position: "relative", display: "inline-flex", height: "40px" }}>
      <Box sx={{ position: "relative" }}>
        <CircularProgress
          variant="determinate"
          sx={{
            color: colors.slate200,
          }}
          value={100}
        />
        <CircularProgress
          variant="determinate"
          sx={{
            position: "absolute",
            left: 0,
            [`& .${circularProgressClasses.circle}`]: {
              strokeLinecap: "round",
            },
          }}
          {...props}
          value={value > 100 ? 100 : (value < 0 ? 0 : value)}
        />
      </Box>
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
        <Typography fontSize={"0.625rem"} color={colors.slate700} fontWeight={600}>{`${Math.floor(
          value
        )}%`}</Typography>
      </Box>
    </Box>
  );
};

export default CircularProgressWithLabel;
