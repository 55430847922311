import { useState } from "react";

import { Box, ToggleButton as MUIToggleButton, ToggleButtonGroup, useTheme } from "@mui/material";
import { ApexOptions } from "apexcharts";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { IPriceHistoryItem } from "types/types";
import { renderPrice } from "utils/formatter";

import { ChartConfig } from "./types";

const ToggleButton = styled(MUIToggleButton)`
  font-size: 0.8rem;
  padding: 4px 10px;
`;

interface Props {
  config: ChartConfig;
}

const SAMPLE_COUNT_MOBILE = 48;
const SAMPLE_COUNT_DESKTOP = 200;

const Chart = ({ config }: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { colors } = useMUIThemeModeContext();
  const [logarithmic, setLogarithmic] = useState(false);

  let dataAmountInvestedTempSum = 0;
  let dataAmountInvested = config.history.map(() => (dataAmountInvestedTempSum += config.amount));

  let dataInvestedCryptoInFiatTempSum = 0;
  let dataInvestedCryptoInFiat = config.history
    .map((item) => (dataInvestedCryptoInFiatTempSum += config.amount / item.p))
    .map((item, index) => item * config.history[index].p);

  // decimate both datasets to make the chart more performant
  const samples = config.isMobile ? SAMPLE_COUNT_MOBILE : SAMPLE_COUNT_DESKTOP;
  const sampleGap = Math.ceil(config.history.length / samples);
  const filterSamples = (_item: any, index: number) =>
    (index - config.history.length + sampleGap + 1) % sampleGap === 0;
  let history: IPriceHistoryItem[];
  if (config.history.length > samples) {
    history = config.history.filter(filterSamples);
    dataAmountInvested = dataAmountInvested.filter(filterSamples);
    dataInvestedCryptoInFiat = dataInvestedCryptoInFiat.filter(filterSamples);
  } else {
    history = [...config.history];
  }

  const min
    = dataAmountInvested[0] <= dataInvestedCryptoInFiat[0] ? dataAmountInvested[0] : dataInvestedCryptoInFiat[0];

  const labels = history.map((item) =>
    config.isMobile ? "" : new Intl.DateTimeFormat("cs-CZ").format(new Date(item.t))
  );

  const options: ApexOptions = {
    theme: { mode: theme.palette.mode },
    tooltip: {
      followCursor: true,
      inverseOrder: true,
    },
    colors: [theme.palette.grey[300], theme.palette.primary.main],
    chart: {
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      background: "transparent",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    grid: {
      row: {
        colors: ["#F1F1F1", "transparent"],
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: labels,
      labels: {
        hideOverlappingLabels: true,
        rotate: -30,
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      show: !config.isMobile,
      labels: {
        formatter: (val) => renderPrice(val),
      },
      logarithmic,
      min,
    },
  };

  const series = [
    {
      name: t("invested"),
      data: dataAmountInvested,
    },
    {
      name: t("value"),
      data: dataInvestedCryptoInFiat,
    },
  ];

  return (
    <Box sx={{ pt: { xs: 0, md: 2, lg: 4 }, pr: { xs: 0, lg: 4 } }}>
      {config.amount > 0 && (
        <>
          <ToggleButtonGroup
            color="primary"
            value={logarithmic}
            onChange={() => setLogarithmic(!logarithmic)}
            exclusive>
            <ToggleButton value={false}>{t("scale.linear")}</ToggleButton>
            <ToggleButton value={true}>{t("scale.log")}</ToggleButton>
          </ToggleButtonGroup>
          <ReactApexChart options={options} series={series} type="line" width={"100%"} />
        </>
      )}
    </Box>
  );
};

export default Chart;
