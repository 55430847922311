import validations from "constants/validations";

import React, { useState } from "react";

import MUITextField from "@mui/material/TextField";

import IProps from "./types";

const PriceField = ({
  onChange,
  onlyPositive,
  allowZero,
  InputProps,
  inputProps,
  precision = 2,
  maxLength,
  ...props
}: IProps) => {
  const pattern = new RegExp(`^(\\d+)(,\\d{0,${precision}}|\\.\\d{0,${precision}})?$`);
  const [prevValue, setPrevValue] = useState<string>("");
  const min = allowZero ? 0 : 1;

  const handleChange = (e: React.ChangeEvent<any>) => {
    const { value } = e.target;

    if (pattern.test(value) || value.length === 0) setPrevValue(value)
    else e.target.value = prevValue;;

    if (onlyPositive && e.target.value.length >= min) {
      Number(e.target.value) < min ? (e.target.value = min) : e.target.value;
    }

    if (validations.number.test(value)) {
      return onChange && onChange(e);
    }
  };

  const maxLengthInput = (e: any) => {
    if (e.target.value !== "") {
      e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, maxLength);
    }
  };

  return (
    <MUITextField
      {...props}
      inputProps={{ ...inputProps, inputMode: precision === 0 ? "numeric" : "decimal", ...(onlyPositive && { min }) }}
      InputProps={InputProps}
      onChange={handleChange}
      onInput={maxLength ? maxLengthInput : undefined}
    />
  );
};

export default PriceField;
