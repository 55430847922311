import { SvgIcon } from "@mui/material";

export const Laptop = (props: any) => {
  return (
    <SvgIcon {...props}>
      <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="laptop-02">
          <path
            id="Icon"
            d="M17.5003 13.3335V6.00016C17.5003 5.06674 17.5003 4.60003 17.3187 4.24351C17.1589 3.92991 16.9039 3.67494 16.5903 3.51515C16.2338 3.3335 15.7671 3.3335 14.8337 3.3335H5.16699C4.23357 3.3335 3.76686 3.3335 3.41034 3.51515C3.09674 3.67494 2.84177 3.92991 2.68198 4.24351C2.50033 4.60003 2.50033 5.06674 2.50033 6.00016V13.3335M3.88921 16.6668H16.1114C16.6281 16.6668 16.8864 16.6668 17.0984 16.61C17.6735 16.4559 18.1228 16.0067 18.2769 15.4315C18.3337 15.2196 18.3337 14.9613 18.3337 14.4446C18.3337 14.1863 18.3337 14.0571 18.3053 13.9511C18.2282 13.6636 18.0036 13.4389 17.716 13.3619C17.61 13.3335 17.4809 13.3335 17.2225 13.3335H2.7781C2.51978 13.3335 2.39062 13.3335 2.28464 13.3619C1.99707 13.4389 1.77244 13.6636 1.69539 13.9511C1.66699 14.0571 1.66699 14.1863 1.66699 14.4446C1.66699 14.9613 1.66699 15.2196 1.72378 15.4315C1.87789 16.0067 2.32714 16.4559 2.90229 16.61C3.11424 16.6668 3.37256 16.6668 3.88921 16.6668Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    </SvgIcon>
  );
};
