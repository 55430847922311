import { IExchangesMetadata } from "types/types";

const currenciesMetadata = {
  currency_pairs: {
    "AAVE/USD": { price_scale: 2, base_scale: 6 },
    "BTC/USD": { price_scale: 0, base_scale: 8 },
    "BTC/CZK": { price_scale: 0, base_scale: 8 },
    "BTC/EUR": { price_scale: 0, base_scale: 8 },
    "ETH/USD": { price_scale: 0, base_scale: 8 },
    "LINK/USD": { price_scale: 3, base_scale: 5 },
    "LTC/USD": { price_scale: 2, base_scale: 8 },
    "XLM/USD": { price_scale: 6, base_scale: 4 },
    "ADA/USD": { price_scale: 4, base_scale: 2 },
    "ALGO/USD": { price_scale: 4, base_scale: 1 },
    "APE/USD": { price_scale: 3, base_scale: 2 },
    "ATOM/USD": { price_scale: 3, base_scale: 2 },
    "AVAX/USD": { price_scale: 2, base_scale: 3 },
    "AXS/USD": { price_scale: 2, base_scale: 3 },
    "DOT/USD": { price_scale: 3, base_scale: 3 },
    "MANA/USD": { price_scale: 4, base_scale: 2 },
    "MATIC/USD": { price_scale: 4, base_scale: 1 },
    "SAND/USD": { price_scale: 4, base_scale: 2 },
    "SHIB/USD": { price_scale: 8, base_scale: 0 },
    "SOL/USD": { price_scale: 2, base_scale: 3 },
    "UNI/USD": { price_scale: 3, base_scale: 6 },
  },
  currencies: {
    BTC: { displayed_scale: 8, scale: 8 },
    EUR: { displayed_scale: 2, scale: 2 },
    CZK: { displayed_scale: 0, scale: 2 },
    DASH: { displayed_scale: 8, scale: 8 },
    LTC: { displayed_scale: 8, scale: 8 },
    ETH: { displayed_scale: 8, scale: 8 },
    ADA: { displayed_scale: 6, scale: 6 },
    SOL: { displayed_scale: 8, scale: 8 },
    XRP: { displayed_scale: 8, scale: 8 },
    AAVE: { displayed_scale: 7, scale: 7 },
    ALGO: { displayed_scale: 7, scale: 7 },
    APE: { displayed_scale: 8, scale: 8 },
    ATOM: { displayed_scale: 7, scale: 7 },
    AVAX: { displayed_scale: 6, scale: 6 },
    AXS: { displayed_scale: 6, scale: 6 },
    DOT: { displayed_scale: 6, scale: 6 },
    LINK: { displayed_scale: 8, scale: 8 },
    MANA: { displayed_scale: 8, scale: 8 },
    MATIC: { displayed_scale: 8, scale: 8 },
    QNT: { displayed_scale: 8, scale: 8 },
    SAND: { displayed_scale: 8, scale: 8 },
    SHIB: { displayed_scale: 2, scale: 2 },
    UNI: { displayed_scale: 7, scale: 7 },
    XLM: { displayed_scale: 8, scale: 8 },
    BNB: { displayed_scale: 8, scale: 8 },
    HBAR: { displayed_scale: 8, scale: 8 },
    XMR: { displayed_scale: 8, scale: 8 },
    BUSD: { displayed_scale: 2, scale: 2 },
    USDC: { displayed_scale: 2, scale: 2 },
    USDT: { displayed_scale: 2, scale: 2 },
    GBP: { displayed_scale: 2, scale: 2 },
    USD: { displayed_scale: 2, scale: 2 },
  },
};

export default currenciesMetadata;

// HELPER FUNCTION FOR GETTING AVERAGE CURRENCY SCALE FROM EXCHANGES METADATA ACROSS ALL EXCHANGES
function _averageCurrenciesScale(data: IExchangesMetadata) {
  const exchanges = Object.keys(data);
  const averages: any = {};

  exchanges.forEach((exchange) => {
    const currency = data[exchange].currencies;

    if (currency) {
      Object.keys(currency).forEach((pair) => {
        const { displayed_scale, scale } = currency[pair];

        if (!averages[pair]) {
          averages[pair] = { displayed_scale: 0, scale: 0, count: 0 };
        }

        averages[pair].displayed_scale += displayed_scale;
        averages[pair].scale += scale;
        averages[pair].count += 1;
      });
    }
  });

  Object.keys(averages).forEach((pair) => {
    const { displayed_scale, scale, count } = averages[pair];
    averages[pair].displayed_scale = Math.round(displayed_scale / count);
    averages[pair].scale = Math.round(scale / count);
    delete averages[pair].count;
  });

  return averages;
}

// HELPER FUNCTION FOR GETTING AVERAGE CURRRENCY PAIRS SCALE FROM EXCHANGES METADATA ACROSS ALL EXCHANGES
function _averageCurrencyPairs(data: IExchangesMetadata, reference = "USD") {
  const exchanges = Object.keys(data);
  const averages: any = {};

  exchanges.forEach((exchange) => {
    const currencyPairs = data[exchange].currency_pairs;

    if (currencyPairs) {
      Object.keys(currencyPairs).forEach((pair) => {
        const { price_scale, base_scale } = currencyPairs[pair];

        if (pair.endsWith(`/${reference}`)) {
          if (!averages[pair]) {
            averages[pair] = { price_scale: 0, base_scale: 0, count: 0 };
          }

          averages[pair].price_scale += price_scale;
          averages[pair].base_scale += base_scale;
          averages[pair].count += 1;
        }
      });
    }
  });

  Object.keys(averages).forEach((pair) => {
    const { price_scale, base_scale, count } = averages[pair];
    averages[pair].price_scale = Math.round(price_scale / count);
    averages[pair].base_scale = Math.round(base_scale / count);
    delete averages[pair].count;
  });

  return averages;
}
