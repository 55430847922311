import { SyntheticEvent } from "react";

import AddIcon from "@mui/icons-material/Add";
import { Alert, Box, Stack, Typography, darken, useMediaQuery, useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import PriceField from "components/elements/PriceField";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { useUserDataContext } from "context/UserDataContext";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { getColorByMode } from "utils";

import PercentageInput from "../PercentageInput";
import { IProps, IObject } from "./types";

const LimitOrdersSection = ({
  limitOrders,
  numOfOrders,
  setFieldValue,
  onChange,
  onBlur,
  errors,
  counterCurrency,
  period,
  touched,
  disabled,
  counterCurrDisplayedScale,
  label,
  dynamicStrategyMultiplierInfo,
  mode,
}: IProps) => {
  const { t } = useTranslation();
  const { subscriptionType } = useUserDataContext();
  const theme = useTheme();
  const { colors } = useMUIThemeModeContext();

  const handleAddOrder = () => {
    const orders = [...limitOrders];
    const previousPercentChange = orders[orders.length - 1].percentChange ?? 1;
    const nextPercentChange = previousPercentChange < 90 ? previousPercentChange + 1 : previousPercentChange;

    orders.push({
      value: undefined,
      percentChange: nextPercentChange,
      executeAsMarketWhenNotFilled: true,
    });
    setFieldValue("limitOrders", orders);
  };

  const handleRemoveOrder = (index: number) => {
    const orders = [...limitOrders];
    orders.splice(index, 1);
    setFieldValue("limitOrders", orders);
  };

  const handleSliderChange = (index: string, newValue?: number) => {
    setFieldValue(index, newValue);
  };

  const handleCheckboxChange = (index: string, newValue: boolean) => {
    setFieldValue(index, newValue);
  };

  const getError = (index: number, key: string) => {
    const error = Array.isArray(errors.limitOrders) ? (errors.limitOrders[index] as IObject) : {};
    return error && key in error ? error[key] : undefined;
  };

  const getIsTouched = (index: number, key: string) => {
    const isTouched = Array.isArray(touched.limitOrders) ? (touched.limitOrders[index] as IObject) : {};
    return isTouched && key in isTouched ? isTouched[key] : undefined;
  };

  return (
    <Grid item xs={12}>
      {limitOrders
        && limitOrders.length > 0
        && limitOrders.map((order, index) => (
          <div key={`limitOrderRow-${index}`}>
            <Box sx={{ background: getColorByMode(theme, colors.legacyGray100, darken(colors.legacyGray100, 0.95)), borderRadius: "10px", p: 2, mb: 1 }}>
              <Grid container>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <PercentageInput
                            value={order.percentChange}
                            onChange={(newValue?: number | string | Array<number | string>) =>
                              handleSliderChange(
                                `limitOrders.${index}.percentChange`,
                                newValue !== undefined ? Number(newValue) : newValue
                              )
                            }
                            min={1}
                            max={90}
                            error={getError(index, "percentChange")}
                            index={index}
                            handleRemoveOrder={handleRemoveOrder}
                            mode={mode}
                          />
                        </Grid>
                        <Grid item xs={12} sm={7}>
                          <PriceField
                            id={`limitOrders.${index}.value`}
                            name={`limitOrders.${index}.value`}
                            label={label}
                            autoComplete="off"
                            allowZero
                            precision={counterCurrDisplayedScale}
                            onlyPositive
                            onChange={onChange}
                            onBlur={onBlur}
                            value={order.value ?? ""}
                            fullWidth={true}
                            error={getIsTouched(index, "value") && Boolean(getError(index, "value"))}
                            helperText={getIsTouched(index, "value") && getError(index, "value")}
                            disabled={disabled}
                            InputProps={{
                              endAdornment: <InputAdornment position="start">{counterCurrency}</InputAdornment>,
                            }}
                          />
                        </Grid>
                        {dynamicStrategyMultiplierInfo(order.value) && (
                          <Grid item xs={12}>
                            <Typography variant="body2" color="text.secondary">
                              {dynamicStrategyMultiplierInfo(order.value)}
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      {subscriptionType === "FREE" ? (
                        <Stack spacing={1} pt={2}>
                          <Typography variant="body2">
                            {t(`dcaBots.create.limitOrder.free.info1.${mode}`, { period: period })}
                          </Typography>
                          <Typography variant="body2">
                            {t(`dcaBots.create.limitOrder.free.info2.${mode}`, {
                              percentChange: order.percentChange ?? "-",
                            })}
                          </Typography>
                        </Stack>
                      ) : (
                        <FormControlLabel
                          control={<Checkbox />}
                          label={
                            <Box sx={{ fontSize: "0.9em" }}>
                              {t(`dcaBots.create.limitOrder.checkboxValue.${mode}`, { period: period })}
                            </Box>
                          }
                          checked={order?.executeAsMarketWhenNotFilled}
                          onChange={(event: SyntheticEvent<Element, Event>, checked: boolean) =>
                            handleCheckboxChange(`limitOrders.${index}.executeAsMarketWhenNotFilled`, checked)
                          }
                          sx={{ pt: 2 }}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </div>
        ))}
      {subscriptionType === "FREE" && (
        <Grid item xs={12}>
          <Alert severity="info">
            <Trans
              i18nKey="dcaBots.create.alert.limitOrders.free"
              components={{
                a: <Link color={theme.palette.info.main} to="/predplatne" />,
              }}
            />
          </Alert>
        </Grid>
      )}
      {limitOrders.length < numOfOrders && (
        <Grid item xs={12} pt={1}>
          <Button
            startIcon={<AddIcon />}
            variant="contained"
            color="info"
            onClick={() => handleAddOrder()}
            size="small">
            {t("dcaBots.create.limitOrder.nextOrderButton", { number: limitOrders.length + 1 })}
          </Button>
        </Grid>
      )}
      {subscriptionType === "PLUS" && limitOrders.length >= numOfOrders && (
        <Grid item xs={12} pt={2}>
          <Alert severity="info">
            <Trans
              i18nKey="dcaBots.create.alert.limitOrders.plus"
              components={{
                a: <Link color={theme.palette.info.main} to="/predplatne" />,
              }}
            />
          </Alert>
        </Grid>
      )}
    </Grid>
  );
};

export default LimitOrdersSection;
