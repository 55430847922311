import { SvgIcon } from "@mui/material";

export const Message = (props: any) => {
  return (
      <svg width="36" height="32" viewBox="0 0 36 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.5001 7.43532C4.83556 7.04395 5.42478 6.99863 5.81615 7.33409L5.20874 8.04273L4.60134 8.75136C4.20996 8.4159 4.16464 7.82669 4.5001 7.43532ZM16.4998 16.3267C16.3508 16.2388 16.1565 16.1107 15.9234 15.9461C15.4152 15.5874 14.7865 15.1011 14.1661 14.6048C13.5484 14.1106 6.99224 8.32445 6.54746 7.95208C6.3253 7.76609 6.14199 7.61117 6.01441 7.50293L5.86705 7.37758L5.81615 7.33409L5.20874 8.04273C4.60134 8.75136 4.60134 8.75136 4.60134 8.75136L4.65557 8.79771L4.80672 8.92628C4.93695 9.03678 5.12343 9.19436 5.34918 9.38337C5.80024 9.761 12.3678 15.5567 13 16.0625C13.6297 16.5662 14.2927 17.0799 14.8469 17.4712C15.1222 17.6655 15.387 17.8412 15.6161 17.9721C15.7299 18.0372 15.8519 18.101 15.9731 18.1511C16.0694 18.191 16.2672 18.267 16.4998 18.267C16.7323 18.267 16.9302 18.191 17.0264 18.1511C17.1476 18.101 17.2696 18.0372 17.3834 17.9721C17.6125 17.8412 17.8773 17.6655 18.1526 17.4712C18.7068 17.0799 19.3699 16.5662 19.9995 16.0625C20.6317 15.5567 27.1999 9.80449 27.6509 9.42686C27.8767 9.23786 28.0632 9.08027 28.1934 8.96977L28.3445 8.84121L28.3977 8.79576C28.3977 8.79576 28.3988 8.79486 27.7914 8.08622L28.3988 8.79486C28.7901 8.4594 28.8355 7.87019 28.5 7.47882C28.1645 7.08745 27.5753 7.04212 27.184 7.37758L27.133 7.42108L26.9857 7.54642C26.8581 7.65467 26.6748 7.80958 26.4526 7.99558C26.0079 8.36795 19.4511 14.1106 18.8334 14.6048C18.213 15.1011 17.5843 15.5874 17.0761 15.9461C16.843 16.1107 16.6487 16.2388 16.4998 16.3267Z"
          fill="#FF9900"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.7905 4.40039H19.2077C21.039 4.40039 22.4707 4.40038 23.6204 4.49432C24.7898 4.58986 25.7495 4.78722 26.6161 5.22874C28.0461 5.95738 29.2088 7.12003 29.9374 8.55006C30.3789 9.41661 30.5763 10.3763 30.6718 11.5457C30.7658 12.6955 30.7658 14.1271 30.7658 15.9585V16.0423C30.7658 17.8736 30.7658 19.3053 30.6718 20.455C30.5763 21.6244 30.3789 22.5842 29.9374 23.4507C29.2088 24.8808 28.0461 26.0434 26.6161 26.772C25.7495 27.2136 24.7898 27.4109 23.6204 27.5065C22.4707 27.6004 21.039 27.6004 19.2076 27.6004H13.7905C11.9592 27.6004 10.5275 27.6004 9.37777 27.5065C8.20837 27.4109 7.24864 27.2136 6.38209 26.772C4.95206 26.0434 3.78941 24.8808 3.06077 23.4507C2.61925 22.5842 2.42189 21.6244 2.32635 20.455C2.23241 19.3053 2.23242 17.8737 2.23242 16.0423V15.9584C2.23242 14.1271 2.23241 12.6955 2.32635 11.5457C2.42189 10.3763 2.61925 9.41661 3.06077 8.55006C3.78941 7.12003 4.95206 5.95738 6.38209 5.22874C7.24864 4.78722 8.20837 4.58986 9.37777 4.49432C10.5275 4.40038 11.9591 4.40039 13.7905 4.40039ZM9.52977 6.35479C8.47581 6.4409 7.78908 6.60686 7.22954 6.89195C6.15075 7.44163 5.27366 8.31872 4.72398 9.39751C4.43889 9.95705 4.27293 10.6438 4.18682 11.6977C4.09981 12.7626 4.09909 14.1181 4.09909 16.0004C4.09909 17.8826 4.09981 19.2382 4.18682 20.303C4.27293 21.357 4.43889 22.0437 4.72398 22.6033C5.27366 23.6821 6.15075 24.5592 7.22954 25.1088C7.78908 25.3939 8.47581 25.5599 9.52977 25.646C10.5946 25.733 11.9502 25.7337 13.8324 25.7337H19.1658C21.048 25.7337 22.4035 25.733 23.4684 25.646C24.5224 25.5599 25.2091 25.3939 25.7686 25.1088C26.8474 24.5592 27.7245 23.6821 28.2742 22.6033C28.5593 22.0437 28.7252 21.357 28.8114 20.303C28.8984 19.2382 28.8991 17.8826 28.8991 16.0004C28.8991 14.1181 28.8984 12.7626 28.8114 11.6977C28.7252 10.6438 28.5593 9.95705 28.2742 9.39751C27.7245 8.31872 26.8474 7.44163 25.7686 6.89195C25.2091 6.60686 24.5224 6.4409 23.4684 6.35479C22.4035 6.26778 21.048 6.26706 19.1658 6.26706H13.8324C11.9502 6.26706 10.5946 6.26778 9.52977 6.35479Z"
          fill="white"
        />
        <circle cx="31.166" cy="8" r="4" fill="#00B860" />
      </svg>
  );
};
