import validations from "constants/validations";

import { useState } from "react";

import LoadingButton from "@mui/lab/LoadingButton";
import { Alert, Box, Stack, TextField } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { requestUserPasswordReset } from "API/calls";
import Panel from "components/elements/Panel";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

const LostPasswordPage = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [emailSent, setEmailSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tokenStillValid, setTokenStillValid] = useState(false);

  const handleRequestPasswordReset = async (email: string) => {
    try {
      setLoading(true);
      await requestUserPasswordReset(email);
      setEmailSent(true);
      enqueueSnackbar(t("passwordResetPage.resetRequested"), { variant: "success" });
    } catch (err: any) {
      if (err?.response?.status === 404) {
        enqueueSnackbar(t("passwordResetPage.error404"), { variant: "error" });
      } else enqueueSnackbar(err?.response?.data?.message ?? t("commonError"), { variant: "error" });

      if (err?.response?.status === 428) {
        setTokenStillValid(true);
      }
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: yup.object().shape({
      email: yup.string().required(t("fieldRequired")).matches(validations.email, t("fieldErrors.email")),
    }),
    onSubmit: (values) => {
      handleRequestPasswordReset(values.email);
    },
  });

  return (
    <Grid container justifyContent="center" spacing={8}>
      <Grid item sx={{ width: "100%", maxWidth: "sm" }}>
        <Panel sx={{ p: 4, mb: 10 }}>
          <Typography variant="h5" component="h1" fontWeight={600} sx={{ pb: 3, color: "tertiary.main" }}>
            {t("passwordResetPage.title")}
          </Typography>
          <Stack spacing={3}>
            <Box>{t("passwordResetPage.content")}</Box>
            {emailSent && <Alert severity="info">{t("passwordResetPage.infoSent")}</Alert>}
            {tokenStillValid && <Alert severity="info">{t("passwordResetPage.tokenStillValid")}</Alert>}
            <TextField
              fullWidth={true}
              id={"email"}
              name={"email"}
              type={"email"}
              inputProps={{
                autoCapitalize: "none",
              }}
              label={t("email")}
              value={formik.values.email ?? ""}
              error={Boolean(formik.errors.email)}
              helperText={formik.errors.email}
              onChange={formik.handleChange}
              disabled={loading || emailSent}
            />
            <LoadingButton
              fullWidth
              variant="contained"
              disableElevation
              onClick={() => formik.handleSubmit()}
              loading={loading}
              disabled={emailSent}>
              {t("passwordResetPage.reset")}
            </LoadingButton>
          </Stack>
        </Panel>
      </Grid>
    </Grid>
  );
};
export default LostPasswordPage;
