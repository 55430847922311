import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const DashedBox = styled(Box)({
  border: "1px dashed #374151",
  width: "1px",
});

export const Dot = styled(FiberManualRecordIcon)({
  color: "#374151",
  height: "12px",
  width: "12px",
  margin: "8px",
});
