import { REFERRAL_COINMATE } from "constants/links";
import validations from "constants/validations";

import { useEffect, useState } from "react";

import {
  Alert,
  Link,
  useTheme,
  CircularProgress,
  Box,
  Stack,
  Typography,
  InputAdornment,
  Divider,
} from "@mui/material";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { createWithdrawalConnections, createWithdrawalConnectionsFromExchangeConnection, getExchangeWithdrawalFee } from "API/calls";
import Modal from "components/elements/Modal";
import PriceField from "components/elements/PriceField";
import ResponsiveLabel from "components/elements/ResponsiveLabel";
import Select from "components/elements/Select";
import TooltipFreeUserUpsell from "components/elements/TooltipFreeUserUpsell";
import { CreditInverted } from "components/icons";
import { useUserDataContext } from "context/UserDataContext";
import { useFormik } from "formik";
import { useAPIWithdrawalConnectionsList, useAPIExchanges, useAPIExchangeConnectionsList } from "hooks/useAPI";
import { useSnackbar } from "notistack";
import { Trans, useTranslation } from "react-i18next";
import { ExchangeType, SubscriptionType } from "types/enums";
import { ConditionalWrapper } from "utils";
import { getExchangeConnectionLabel, getNumberWithComma } from "utils/formatter";
import * as yup from "yup";

import { InputWrapper } from "./styles";
import IProps, { IObject } from "./types";

export const FEE = {
  default: "0,0001",
  min: 0.00002,
  max: 0.002,
}
const ALLOWED_EXCHANGE: string[] = [ExchangeType.ANYCOIN, ExchangeType.COINMATE];

const CreateModal = ({ isOpen, handleClose }: IProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { refreshData, subscriptionType } = useUserDataContext();
  const { mutate } = useAPIWithdrawalConnectionsList();
  const { data } = useAPIExchanges();
  const isFreeUser = subscriptionType === SubscriptionType["FREE"];

  const { data: userExchangeConnections } = useAPIExchangeConnectionsList(false);
  const anycoinExchangeConnections = userExchangeConnections?.exchangeConnections.filter(
    (item) => item.exchangeEnum === ExchangeType.ANYCOIN
  );

  const btcThresholdValidation = (value?: string, context?: yup.TestContext<any>) => {
    if (value && context) {
      const min = 0.001;
      const max = 20999999.9769;
      const val = Number(value.replace(",", "."));

      if (val < min) {
        return context.createError({
          message: t("withdrawalConnections.modal.minBtcValue", { value: `${min}`.replace(".", ",") }),
        });
      }
      if (val > max) {
        return context.createError({
          message: t("withdrawalConnections.modal.maxBtcValue", { value: `${max}`.replace(".", ",") }),
        });
      }
    }

    return true;
  };

  const maxFeeValidation = (value?: string, context?: yup.TestContext<any>) => {
    if (value && context) {
      const min = FEE.min;
      const max = FEE.max;
      const val = Number(value.replace(",", "."));

      if (val < min) {
        return context.createError({
          message: t("withdrawalConnections.modal.minFeeValue", { value: `${min}`.replace(".", ",") }),
        });
      }
      if (val > max) {
        return context.createError({
          message: t("withdrawalConnections.modal.maxFeeValue", { value: `${max}`.replace(".", ",") }),
        });
      }
    }

    return true;
  };

  const initValues = {
    exchangeEnum: "",
    label: "",
    btcAddress: "",
    btcThreshold: "0,01",
    privateKey: "",
    publicKey: "",
    clientId: "",
    exchangeConnectionId: "",
    maxFee: FEE.default,
  };
  const defaultSchema = {
    exchangeEnum: yup.string().required(t("fieldRequired")),
    label: yup.string().max(50, t("fieldMaxLength", { max: 50 })),
    btcAddress: yup.string().required(t("fieldRequired")).matches(validations.btcAddress, t("fieldErrors.btcAddress")),
    btcThreshold: yup
      .string()
      .required(t("fieldRequired"))
      .test("btcThresholdValue", "btcThresholdValueError", btcThresholdValidation),
    privateKey: yup.string().when("exchangeEnum", {
      is: ExchangeType.COINMATE,
      then: yup.string().required(t("fieldRequired")),
    }),
    publicKey: yup.string().when("exchangeEnum", {
      is: ExchangeType.COINMATE,
      then: yup.string().required(t("fieldRequired")),
    }),
    clientId: yup.string().when("exchangeEnum", {
      is: ExchangeType.COINMATE,
      then: yup.string().required(t("fieldRequired")),
    }),
    exchangeConnectionId: yup.string().when("exchangeEnum", {
      is: ExchangeType.ANYCOIN,
      then: yup.string().required(t("fieldRequired")),
    }),
    maxFee: yup.string().required(t("fieldRequired")).test("maxFeeValue", "maxFeeValueError", maxFeeValidation),
  };
  const [initialValues, setInitialValues] = useState<IObject>(initValues);
  const [schema, setSchema] = useState<any>(defaultSchema);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentFee, setCurrentFee] = useState<number>();

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: yup.object(schema),
    onSubmit: async (values) => {
      const { label, btcAddress, btcThreshold, exchangeEnum, exchangeConnectionId, maxFee, ...parameters } = values;

      const createApi
        = exchangeEnum === ExchangeType.COINMATE
          ? createWithdrawalConnections
          : createWithdrawalConnectionsFromExchangeConnection;

      try {
        setIsLoading(true);
        await createApi({
          label,
          btcAddress,
          btcThreshold: Number(`${btcThreshold}`.replace(",", ".")),
          exchangeEnum,
          parameters: exchangeEnum === ExchangeType.COINMATE ? parameters : undefined,
          exchangeConnectionId: exchangeEnum === ExchangeType.ANYCOIN ? exchangeConnectionId : undefined,
          maxFee: Number(`${maxFee}`.replace(",", ".")),
        });
        await mutate();

        refreshData?.();
        enqueueSnackbar(t("withdrawalConnections.modal.successMessage"), { variant: "success" });
        handleCloseModal();
      } catch (err: any) {
        enqueueSnackbar(t("withdrawalConnections.modal.errorMessage"), { variant: "error" });
      } finally {
        setIsLoading(false);
      }
    },
  });

  useEffect(() => {
    if (anycoinExchangeConnections && anycoinExchangeConnections?.length > 0) {
      if (!formik.values.exchangeConnectionId) {
        const exchangeConnection = anycoinExchangeConnections.find((item) => item.functional);

        if (exchangeConnection) formik.setFieldValue("exchangeConnectionId", exchangeConnection.id);
      }
    }
  }, [anycoinExchangeConnections]);

  useEffect(() => {
    if (formik.values.exchangeEnum) {
      (async () => {
        const { data } = await getExchangeWithdrawalFee(formik.values.exchangeEnum);
        setCurrentFee(data?.fee);
      })();
    }
  }, [formik.values.exchangeEnum]);

  const initState = () => {
    setInitialValues(initValues);
    setSchema(defaultSchema);
  };

  const handleCloseModal = () => {
    initState();
    setIsLoading(false);
    handleClose();
  };

  const getExchangeTitle = (exchangeEnum: ExchangeType) =>
    data?.exchanges.find((item) => item.exchangeEnum === exchangeEnum)?.title;

  return (
    <Modal
      title={t("withdrawalConnections.modal.create")}
      withCloseButton
      open={isOpen}
      onCancel={handleClose}
      customConfirmButton={
        <ConditionalWrapper
          condition={isFreeUser}
          wrapper={(children) => <TooltipFreeUserUpsell>{children}</TooltipFreeUserUpsell>}>
          <Button
            variant="contained"
            endIcon={isFreeUser ? <CreditInverted /> : undefined}
            onClick={() => (!isFreeUser ? formik.handleSubmit() : undefined)}>
            {t("create")}
          </Button>
        </ConditionalWrapper>
      }>
      {isLoading && (
        <Box sx={{ pt: 8, pb: 12, alignSelf: "center", textAlign: "center" }}>
          <Stack spacing={4} sx={{ display: "flex", alignItems: "center" }}>
            <CircularProgress size={64} />
          </Stack>
        </Box>
      )}
      {!isLoading && (
        <>
          <Select
            id="exchangeEnum"
            name="exchangeEnum"
            label={ResponsiveLabel(t("withdrawalConnections.modal.exchange"))}
            value={formik.values.exchangeEnum ?? ""}
            onChange={formik.handleChange}
            error={formik.touched.exchangeEnum && formik.errors.exchangeEnum}>
            {data?.exchanges?.map((exchange, index) => (
              <MenuItem
                key={`exchange-${index}`}
                disabled={!ALLOWED_EXCHANGE.includes(exchange.exchangeEnum)}
                value={exchange.exchangeEnum}>
                {exchange.title}
              </MenuItem>
            ))}
          </Select>
          <InputWrapper>
            <TextField
              fullWidth
              autoComplete="off"
              id="label"
              name="label"
              label={ResponsiveLabel(t("withdrawalConnections.modal.label"))}
              value={formik.values.label}
              onChange={formik.handleChange}
              error={Boolean(formik.errors.label)}
              helperText={formik.errors.label}
            />
          </InputWrapper>
          {formik.values.exchangeEnum === ExchangeType.COINMATE && (
            <>
              <Alert severity="info" sx={{ mt: 2 }}>
                <Link
                  href={"https://docs.google.com/document/d/1kDWRvZW-8b5li-wXqsd035ZeGHe8J546_GzU4SodTxs"}
                  target={"_blank"}
                  component="a"
                  color={theme.palette.info.main}>
                  <Trans i18nKey="withdrawalConnections.modal.infoInstructions">
                    {{ exchange: getExchangeTitle(ExchangeType.COINMATE) }}
                  </Trans>
                </Link>
                <Divider sx={{ pt: 2, mb: 2 }} />
                {t("exchangeConnections.modal.infoCoinmateNoAccount")}{" "}
                <Link href={REFERRAL_COINMATE} color={theme.palette.info.main} target="_blank">
                  <strong>
                    <Trans i18nKey="exchangeConnections.modal.infoCoinmate" />
                  </strong>
                </Link>
              </Alert>
            </>
          )}
          {formik.values.exchangeEnum === ExchangeType.ANYCOIN && (
            <Alert severity="info" sx={{ mt: 2, pb: 0 }}>
              <Trans
                i18nKey="withdrawalConnections.modal.infoAnycoin"
                components={{
                  a: (
                    <Link
                      color={theme.palette.info.main}
                      href="https://www.anycoin.cz/myaccount?section=applications"
                      target="_blank"
                      sx={{ fontWeight: "bold" }}
                    />
                  ),
                }}
              />
              <ul style={{ padding: 0 }}>
                <li>
                  <Trans i18nKey={"withdrawalConnections.modal.infoAnycoin1"} />
                </li>
                <li>
                  <Trans i18nKey={"withdrawalConnections.modal.infoAnycoin2"} />
                </li>
                <li>
                  <Trans i18nKey={"withdrawalConnections.modal.infoAnycoin3"} />
                </li>
                <li>
                  <Trans i18nKey={"withdrawalConnections.modal.infoAnycoin4"} />
                </li>
              </ul>
            </Alert>
          )}
          {formik.values.exchangeEnum && (
            <Typography variant="body2" sx={{ pt: 1 }}>
              <Trans i18nKey={`withdrawalConnections.modal.btcAddressDescription.${formik.values.exchangeEnum}`} />
            </Typography>
          )}
          <InputWrapper>
            <TextField
              fullWidth
              autoComplete="off"
              id="btcAddress"
              name="btcAddress"
              label={ResponsiveLabel(t("withdrawalConnections.modal.btcAddress"))}
              value={formik.values.btcAddress}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.btcAddress && Boolean(formik.errors.btcAddress)}
              helperText={formik.touched.btcAddress && formik.errors.btcAddress}
            />
          </InputWrapper>
          <InputWrapper>
            <PriceField
              autoComplete="off"
              allowZero
              precision={8}
              fullWidth={true}
              id="btcThreshold"
              name="btcThreshold"
              label={ResponsiveLabel(t("withdrawalConnections.modal.btcThreshold"))}
              value={formik.values.btcThreshold}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={Boolean(formik.errors.btcThreshold)}
              helperText={formik.errors.btcThreshold}
              InputProps={{
                endAdornment: <InputAdornment position="start">{"BTC"}</InputAdornment>,
              }}
            />
          </InputWrapper>
          {formik.values.exchangeEnum && (
            <Typography variant="body2" sx={{ pt: 1, pb: 1 }}>
              <Trans i18nKey="withdrawalConnections.modal.btcThresholdDescription" />
            </Typography>
          )}
          {formik.values.exchangeEnum && currentFee && (
            <Typography variant="body2" sx={{ pt: 3 }}>
              <Trans i18nKey="withdrawalConnections.modal.currentFee" values={{ value: getNumberWithComma(currentFee) }} />
            </Typography>
          )}
          <InputWrapper>
            <PriceField
              autoComplete="off"
              allowZero
              precision={8}
              fullWidth={true}
              id="maxFee"
              name="maxFee"
              label={ResponsiveLabel(t("withdrawalConnections.modal.maxFee"))}
              value={formik.values.maxFee}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={Boolean(formik.errors.maxFee)}
              helperText={formik.errors.maxFee}
              InputProps={{
                endAdornment: <InputAdornment position="start">{"BTC"}</InputAdornment>,
              }}
            />
          </InputWrapper>
          {formik.values.exchangeEnum && (
            <Typography variant="body2" sx={{ pt: 1, pb: 1 }}>
              <Trans i18nKey="withdrawalConnections.modal.maxFeeDescription" />
            </Typography>
          )}
          {!isFreeUser && formik.values.exchangeEnum === ExchangeType.COINMATE && (
            <>
              <InputWrapper>
                <TextField
                  fullWidth
                  autoComplete="off"
                  id="privateKey"
                  name="privateKey"
                  label={ResponsiveLabel(t("withdrawalConnections.modal.privateKey"))}
                  value={formik.values.privateKey}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.privateKey && Boolean(formik.errors.privateKey)}
                  helperText={formik.touched.privateKey && formik.errors.privateKey}
                />
              </InputWrapper>
              <InputWrapper>
                <TextField
                  fullWidth
                  autoComplete="off"
                  id="publicKey"
                  name="publicKey"
                  label={ResponsiveLabel(t("withdrawalConnections.modal.publicKey"))}
                  value={formik.values.publicKey}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.publicKey && Boolean(formik.errors.publicKey)}
                  helperText={formik.touched.publicKey && formik.errors.publicKey}
                />
              </InputWrapper>
              <InputWrapper>
                <TextField
                  fullWidth
                  autoComplete="off"
                  id="clientId"
                  name="clientId"
                  label={ResponsiveLabel(t("withdrawalConnections.modal.clientId"))}
                  value={formik.values.clientId}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.clientId && Boolean(formik.errors.clientId)}
                  helperText={formik.touched.clientId && formik.errors.clientId}
                />
              </InputWrapper>
            </>
          )}
          {!isFreeUser && formik.values.exchangeEnum === ExchangeType.ANYCOIN && (
            <InputWrapper>
              <Select
                id="exchangeConnectionId"
                name="exchangeConnectionId"
                label={ResponsiveLabel(t("withdrawalConnections.modal.exchangeConnectionId"))}
                value={formik.values.exchangeConnectionId ?? ""}
                onChange={formik.handleChange}
                disabled={!anycoinExchangeConnections?.length}
                error={formik.touched.exchangeConnectionId && formik.errors.exchangeConnectionId}>
                {anycoinExchangeConnections?.map((ex, index) => (
                  <MenuItem key={`anycoin-ex-${index}`} value={ex.id} disabled={!ex.functional}>
                    {getExchangeConnectionLabel(ex.exchangeTitle, ex.functional, ex.label)}
                  </MenuItem>
                ))}
              </Select>
              {!anycoinExchangeConnections?.length && (
                <Alert severity="warning" sx={{ mt: 1 }}>
                  {t("withdrawalConnections.modal.withoutAnycoinConnectionWarning")}
                </Alert>
              )}
            </InputWrapper>
          )}
        </>
      )}
    </Modal>
  );
};

export default CreateModal;
