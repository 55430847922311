import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { getColorByMode } from "utils";

export const ItemBox = styled(Box)<{ isGap?: boolean }>(({ theme, isGap }) => {
  const { colors } = useMUIThemeModeContext();

  return {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    borderBottom: isGap ? "none" : `1px solid ${colors.gray200}`,
  };
});

export const GapBox = styled(Box)(({ theme }) => {
  const { colors } = useMUIThemeModeContext();

  return {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(2.25),
    paddingRight: theme.spacing(2.25),
    borderRadius: "0px 1000px 1000px 0px",
    backgroundColor: getColorByMode(theme, colors.gray600, colors.gray400),
    color: "#fff",
    width: "fit-content",
    display: "flex",
  };
});

export const LineBox = styled(Box)(() => {
  const { colors } = useMUIThemeModeContext();

  return {
    flex: 1,
    height: "1px",
    border: `1px dashed ${colors.gray400}`,
  };
});

export const HiddenBox = styled(Box)(({ theme }) => {
  const { colors } = useMUIThemeModeContext();

  return {
    paddingTop: theme.spacing(1.25),
    paddingBottom: theme.spacing(1.25),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    flex: 1,
    borderBottom: `1px solid ${colors.gray200}`,
  };
});
