import React from "react";

import AccessTimeIcon from "@mui/icons-material/AccessTime";
import MenuBookIcon from '@mui/icons-material/MenuBook';
import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IChapter } from "types/types";
import { toHoursAndMinutes } from "utils/formatter";

interface IProps {
  lengthInMinutes: number | null;
  chapters: IChapter[];
}

const CourseLength = ({ lengthInMinutes, chapters }: IProps) => {
  const { t } = useTranslation<string>();

  const courseLength = () => {
    if (lengthInMinutes) return lengthInMinutes;

    return chapters.reduce((total, chapter) => total + chapter.lengthInMinutes, 0);
  };

  return (
    <Stack direction={"row"} spacing={2} sx={{ mt: 0.5 }}>
      <Typography display="flex" justifyContent="center" variant="body2" component="h1" sx={{ color: "#9E9E9E" }}>
        <AccessTimeIcon sx={{marginRight: "4px"}} fontSize="small" />
        {toHoursAndMinutes(courseLength())}
      </Typography>
      <Typography display="flex" justifyContent="center" variant="body2" component="h1" sx={{ color: "#9E9E9E" }}>
        <MenuBookIcon sx={{marginRight: "4px"}} fontSize="small" />
        {chapters.length}{'\u00A0'}{t("whyBitcoin.chapters", { count: chapters.length })}
      </Typography>
    </Stack>
  );
};

export default CourseLength;
