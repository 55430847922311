import { forwardRef, Ref, useCallback } from "react";

import { Box, Stack, Typography } from "@mui/material";
import { Accept } from "components/icons";
import { SnackbarContent, SnackbarKey, useSnackbar } from "notistack";
import { Trans, useTranslation } from "react-i18next";

interface IProps {
  id: SnackbarKey;
}

const RegisterSuccess = forwardRef(({ id }: IProps, ref: Ref<HTMLDivElement>) => {
  const { t } = useTranslation();
  const { closeSnackbar } = useSnackbar();

  const handleDismiss = useCallback(() => {
    closeSnackbar(id);
  }, [id, closeSnackbar]);

  return (
    <SnackbarContent ref={ref}>
      <Box p={2} sx={{ backgroundColor: "#E5FFF3", borderRadius: "12px" }} onClick={handleDismiss}>
        <Stack spacing={2} direction="row" alignItems="center">
          <Accept />
          <Stack>
            <Typography color="#00B860" fontWeight={600}>
              {t("homepage.register.success.title")}
            </Typography>
            <Typography color="#1E1127" pt={0.5}>
              <Trans i18nKey="homepage.register.success.subtitle" />
            </Typography>
          </Stack>
        </Stack>
      </Box>
    </SnackbarContent>
  );
});

export default RegisterSuccess;
