import { SvgIcon } from "@mui/material";

export const Certificate = (props: any) => {
  return (
    <SvgIcon {...props}>
      <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="certificate-01">
          <path
            id="Icon"
            d="M5.41667 16.6665H4.16667C3.24619 16.6665 2.5 15.9203 2.5 14.9998V3.33317C2.5 2.4127 3.24619 1.6665 4.16667 1.6665H15.8333C16.7538 1.6665 17.5 2.4127 17.5 3.33317V14.9998C17.5 15.9203 16.7538 16.6665 15.8333 16.6665H14.5833M10 15.8332C11.3807 15.8332 12.5 14.7139 12.5 13.3332C12.5 11.9525 11.3807 10.8332 10 10.8332C8.61929 10.8332 7.5 11.9525 7.5 13.3332C7.5 14.7139 8.61929 15.8332 10 15.8332ZM10 15.8332L10.0179 15.833L7.35723 18.4936L5.0002 16.1366L7.51638 13.6204M10 15.8332L12.6607 18.4936L15.0177 16.1366L12.5015 13.6204M7.5 4.99984H12.5M5.83333 7.9165H14.1667"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    </SvgIcon>
  );
};
