import { Typography } from "@mui/material";
import ListStyle from "assets/images/list-style.png";
import { Trans } from "react-i18next";

interface IProps {
  data: string[];
  color?: string;
}

const List = ({ data, color = "black" }: IProps) => {
  return (
    <ul style={{ paddingLeft: "15px", listStyle: `url(${ListStyle})`, margin: 0, color }}>
      {data.map((item, index) => (
        <li key={index} style={{ paddingTop: "12px", paddingLeft: "6px" }}>
          <Typography fontSize={"0.9rem"} fontWeight={300}>
            <Trans i18nKey={item} />
          </Typography>
        </li>
      ))}
    </ul>
  );
};

export default List;
