import { OHLCInterval, OrderTriggering } from "types/enums";
import {
  CurrencyPair,
  ExchangeRates,
  IAnycoinDepositInfoResponse,
  IBadges,
  IBadgeTypes,
  IBotsBalances,
  IBotsOrdersList,
  ICashbackSatsList,
  ICoinmateDepositInfoResponse,
  ICountryCodes,
  ICreditRecharge,
  ICreditsPaymentType,
  ICreditsTransactions,
  ICurrencyPairsTicker,
  IDashboardStrategies,
  IDcaBotDetail,
  IDCABotsBalances,
  IDcaBotsList,
  IDcaBotsRunsList,
  IDynamicStrategyChart,
  IExchangeConnectionsList,
  IExchangeConnectionTradingFees,
  IExchanges,
  IExchangesMetadata,
  IGridBotDetail,
  IGridBotList,
  IOHLC,
  ISummarieSettings,
  IUserData,
  IUserDataAffiliate,
  IUserEmailPreferences,
  IUserInvoiceData,
  IWithdrawalConnectionsList,
  IWithdrawalFee,
  IWithdrawalsList,
  PriceHistory,
} from "types/types";

import useRequest from "./useRequest";

export function useAPICreditsTransactions() {
  return useRequest<ICreditsTransactions>({
    url: "/api/credits-transactions",
  });
}

export function useAPIBadgeTypes() {
  return useRequest<IBadgeTypes>({
    url: "/api/badge-types",
  });
}

export function useAPIBadges() {
  return useRequest<IBadges>({
    url: "/api/badges",
  });
}

export function useAPIExchangeConnectionsList(withRevalidate = true) {
  const SWRConfig = {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  };

  return useRequest<IExchangeConnectionsList>(
    {
      url: "/api/exchange-connections",
    },
    withRevalidate ? undefined : SWRConfig
  );
}

export function useAPIUserData() {
  return useRequest<IUserData>({
    url: "/api/user-data",
  });
}

export function useAPIDcaBotsDetail(id?: string, withRevalidate = true) {
  const SWRConfig = {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  };

  const request = {
    url: `/api/dca-bots/${id}`,
    params: { "fetch-details": true },
  };

  return useRequest<IDcaBotDetail>(id ? request : null, withRevalidate ? undefined : SWRConfig);
}

export function useAPIDcaBotsList(autoFetch = true) {
  const request = {
    url: "/api/dca-bots",
  };

  return useRequest<IDcaBotsList>(autoFetch ? request : null);
}

export function useAPIDcaBotsBalances(id: string, shouldFetch = false) {
  const SWRConfig = {
    errorRetryCount: 0,
  };

  return useRequest<IDCABotsBalances>(
    shouldFetch ? { url: `/api/dca-bots/${id}/balances` } : null,
    SWRConfig
  );
}

export function useAPIDcaBotsRunsList(id: string, triggering?: OrderTriggering[]) {
  const SWRConfig = {
    revalidateOnFocus: false,
  };
 
  const params = new URLSearchParams();
  triggering?.forEach((trigger) => {
    params.append("order-triggering", trigger);
  });

  return useRequest<IDcaBotsRunsList>(
    {
      url: `/api/dca-bots/${id}/runs${triggering?.length ? `?${params.toString()}` : ""}`,
    },
    SWRConfig
  );
}

export function useAPIPriceHistory(currencyPair: CurrencyPair) {
  const SWRConfig = {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  };
  return useRequest<PriceHistory>(
    {
      url: `/api/price-history?currency-pair=${currencyPair}`,
    },
    SWRConfig
  );
}

export function useAPIExchanges() {
  const SWRConfig = {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    dedupingInterval: 300000, //5 Minutes
  };

  return useRequest<IExchanges>(
    {
      url: "/api/exchanges",
    },
    SWRConfig
  );
}

export function useAPIExchangesMetadata() {
  const SWRConfig = {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    dedupingInterval: 300000, //5 Minutes
  };

  return useRequest<IExchangesMetadata>(
    {
      url: "/api/exchanges-metadata",
    },
    SWRConfig
  );
}

export function useAPIAnycoinDepositInfo(id: string, shouldFetch = false) {
  const SWRConfig = {
    revalidateOnFocus: false,
  };

  const request = {
    url: `/api/exchange-connections/${id}/anycoin-deposit-info`,
  };

  return useRequest<IAnycoinDepositInfoResponse>(shouldFetch ? request : null, SWRConfig);
}

export function useAPICoinmateDepositInfo(id: string, shouldFetch = false) {
  const SWRConfig = {
    revalidateOnFocus: false,
  };

  const request = {
    url: `/api/exchange-connections/${id}/coinmate-deposit-info`,
  };

  return useRequest<ICoinmateDepositInfoResponse>(shouldFetch ? request : null, SWRConfig);
}

export function useAPIUserDataAffiliate() {
  return useRequest<IUserDataAffiliate>({
    url: "/api/user-data/affiliate",
  });
}

export function useAPICountryCodes() {
  const SWRConfig = {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    dedupingInterval: 300000, //5 Minutes
  };

  return useRequest<ICountryCodes>(
    {
      url: "/api/country-codes",
    },
    SWRConfig
  );
}

export function useAPIUserInvoiceData() {
  const SWRConfig = {
    revalidateOnFocus: false,
  };

  return useRequest<IUserInvoiceData>(
    {
      url: `/api/user/invoice-data`,
    },
    SWRConfig
  );
}

export function useAPICurrencyPairsTicker(currencyPair: string, exchangeType?: string) {
  const request = {
    url: `/api/exchanges/${exchangeType}/currency-pairs/${currencyPair}/ticker`,
  };

  const shouldFetch = exchangeType && currencyPair ? true : false;

  return useRequest<ICurrencyPairsTicker>(shouldFetch ? request : null, { refreshInterval: 5000 });
}

export function useAPIWithdrawalConnectionsList() {
  return useRequest<IWithdrawalConnectionsList>({
    url: "/api/withdrawal-connections",
  });
}

export function useAPIWithdrawalsList(id: string) {
  const SWRConfig = {
    revalidateOnFocus: false,
  };

  const request = {
    url: `/api/withdrawal-connections/${id}/withdrawals`,
  };

  return useRequest<IWithdrawalsList>(request, SWRConfig);
}

export function useAPIGridBotDetail(id?: string, withRevalidate = true, refreshInterval?: number) {
  const SWRConfig = {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshInterval,
  };

  const request = {
    url: `/api/bots/grid/${id}`,
  };

  return useRequest<IGridBotDetail>(id ? request : null, withRevalidate ? { refreshInterval } : SWRConfig);
}

export function useAPIGridBotsList(autoFetch = true) {
  const request = {
    url: "/api/bots/grid",
  };

  return useRequest<IGridBotList>(autoFetch ? request : null);
}

export function useAPIBotsBalances(id: string, shouldFetch = false) {
  const SWRConfig = {
    errorRetryCount: 0,
  };

  return useRequest<IBotsBalances>(
    shouldFetch ? { url: `/api/bots/${id}/balances` }  : null,
    SWRConfig
  );
}

export function useAPIBotsOrdersList(id: string, refreshInterval?: number) {
  const SWRConfig = {
    revalidateOnFocus: false,
    refreshInterval,
  };

  return useRequest<IBotsOrdersList>(
    {
      url: `/api/bots/${id}/orders`,
    },
    SWRConfig
  );
}

export function useAPIOHLC(currencyPair: string, interval: OHLCInterval, exchangeType?: string, refreshInterval?: number, enabled = true) {
  const SWRConfig = {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshInterval,
  };
  const shouldFetch = exchangeType && currencyPair && enabled ? true : false;
  const request = {
    url: `/api/ohlc?exchange=${exchangeType}&currency-pair=${currencyPair}&interval=${interval}`,
  };

  return useRequest<IOHLC>(shouldFetch ? request : null, SWRConfig);
}

export function useAPIExchangeRates() {
  const SWRConfig = {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  };
  return useRequest<ExchangeRates>(
    {
      url: "/api/exchange-rates",
    },
    SWRConfig
  );
}

export function useAPIUserEmailPreferences(preferencesId: string) {
  const SWRConfig = {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  };
  const shouldFetch = preferencesId.length ? true : false;
  const request = {
    url: `/api/user-email-preferences/${preferencesId}`,
  };

  return useRequest<IUserEmailPreferences>(shouldFetch ? request : null, SWRConfig);
}

export function useAPIDashboardStrategies(fetch = false, params?: ISummarieSettings) {
  const request = {
    url: "/api/dashboard/strategies",
    params,
  };

  return useRequest<IDashboardStrategies>(fetch ? request : null);
}

export function useAPICreditsRecharge() {
  return useRequest<ICreditRecharge>(
    {
      url: "/api/credits-recharge",
    },
    { errorRetryCount: 0, revalidateOnMount: true }
  );
}

export function useAPICreditsPaymentsRechargeTypes() {
  return useRequest<ICreditsPaymentType>(
    {
      url: "/api/credits-payments",
    },
    { errorRetryCount: 0 }
  );
}

export function useAPIExchangeWithdrawalFee(exchangeEnum: string, currency = "BTC", withRevalidate = true) {
  const SWRConfig = {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  };

  return useRequest<IWithdrawalFee>(
    {
      url: `/api/exchanges/${exchangeEnum}/currency/${currency}/withdrawal-fee`,
    },
    withRevalidate ? undefined : SWRConfig
  );
}

export function useAPIDcaBotsDynamicStrategyChart(id: string, shouldFetch = false) {
  const SWRConfig = {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  };

  return useRequest<IDynamicStrategyChart>(
    shouldFetch ? { url: `/api/dca-bots/${id}/dynamic-strategy-chart`} : null,
    SWRConfig
  );
}

export function useAPIExchangeConnectionTradingFees(id: string, currencyPair: string, shouldFetch = false) {
  const SWRConfig = {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    errorRetryCount: 0,
  };

  return useRequest<IExchangeConnectionTradingFees>(
    shouldFetch ? { url: `/api/exchange-connections/${id}/trading-fees/${currencyPair}` }  : null,
    SWRConfig
  );
}

export function useAPICashbackSats() {
  return useRequest<ICashbackSatsList>({
    url: "/api/cashback-sats",
  });
}