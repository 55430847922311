import React from "react";

import { useMediaQuery, useTheme } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tile from "components/elements/Tile";
import { useTranslation } from "react-i18next";
import { IPriceLevel } from "types/types";
import { renderNumber } from "utils/formatter";

interface IProps {
  title: string;
  subtitle: string;
  availableBalance: number;
  missingBalance: number;
  totalRequiredBalance: number;
  currency: string;
  scale: number;
  exchangeTitle: string;
  priceLevels: IPriceLevel[];
}
const BalanceView = ({
  title,
  subtitle,
  availableBalance,
  missingBalance,
  totalRequiredBalance,
  currency,
  scale,
  exchangeTitle,
  priceLevels,
}: IProps) => {
  const { t } = useTranslation<string>();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const countAvailableLevels = priceLevels.filter((level) => level.priceLevelStatus === "OPEN" || level.priceLevelStatus === "ENOUGH_BALANCE").length;
  const countMissingLevels = priceLevels.filter((level) => level.priceLevelStatus === "NOT_ENOUGH_BALANCE").length;

  const getTableHeader = (cells: string[]) => (
    <TableRow sx={{ "& td, & th": { border: 0, fontSize: "12px", p: isMobile ? 0.5 : undefined } }}>
      {cells.map((cell, index) => (
        <TableCell
          key={`header-${index}`}
          padding={isMobile ? "none" : "normal"}
          sx={{ color: "#9E9E9E", fontWeight: 500 }}
          align={cell.length ? "right" : "inherit"}>
          {cell}
        </TableCell>
      ))}
    </TableRow>
  );

  const getTableRow = (title: string, value: number | string, count: number | string, fontWeight: number, highlighted = false) => (
    <TableRow
      sx={{ "& td, & th": { border: 0, fontSize: isMobile ? "12px" : undefined, p: isMobile ? 0.5 : undefined } }}>
      <TableCell padding={isMobile ? "none" : "normal"} component="th" scope="row" sx={{fontWeight}}>
        <span style={highlighted ? {color: 'darkorange'} : {}}>{title}</span>
      </TableCell>
      <TableCell padding={isMobile ? "none" : "normal"} sx={{fontFamily: "monospace", fontWeight}} align="right">
        <span style={highlighted ? {color: 'darkorange'} : {}}>{value}</span>
      </TableCell>
      <TableCell padding={isMobile ? "none" : "normal"} sx={{ fontFamily: "monospace", fontWeight }} align="right">
        {count}
      </TableCell>
    </TableRow>
  );

  return (
    <Tile header={title} sx={{ width: "100%" }}>
      <TableContainer sx={{ padding: "8px 8px 12px 8px" }}>
        <Table size="small">
          <TableHead>
            {getTableHeader(["", t("gridBot.create.gridSetting.value"), t("gridBot.create.gridSetting.count")])}
          </TableHead>
          <TableBody>
            {getTableRow(
              subtitle,
              `${renderNumber(totalRequiredBalance, scale, true)}\u00a0${currency}`,
              priceLevels.length,
              600
            )}
            {getTableRow(
              t(`gridBot.create.gridSetting.available`, { title: exchangeTitle }),
              `${renderNumber(availableBalance, scale, true)}\u00a0${currency}`,
              countAvailableLevels > 0 ? countAvailableLevels : "",
              400
            )}
            {getTableRow(
              t(`gridBot.create.gridSetting.missing`, { title: exchangeTitle }) + "*",
              missingBalance > 0 ? `${renderNumber(missingBalance, scale, true)}\u00a0${currency}` : "-",
              countMissingLevels > 0 ? countMissingLevels : "",
              400,
              true
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Tile>
  );
};

export default BalanceView;
