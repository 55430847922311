import React from "react";

import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import PauseOutlinedIcon from "@mui/icons-material/PauseOutlined";
import PlayArrowOutlinedIcon from "@mui/icons-material/PlayArrowOutlined";
import SettingsIcon from "@mui/icons-material/Settings";
import { Stack, Button, useMediaQuery, useTheme } from "@mui/material";
import Card from "components/elements/Card";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import IProps from "./types";

const ControlPanel: React.FC<IProps> = ({ onDelete, onEnabled, enabled, id, isExchangeEnabled, functional }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const controlBreakpoint = useMediaQuery(theme.breakpoints.down(1290));

  return (
    <Card divider={false} sx={{ mb: 0 }}>
      <Stack direction="row" justifyContent="space-between" alignItems={"center"}>
        <Stack direction={!controlBreakpoint ? "row" : "column"} justifyContent="space-between" spacing={{ md: 0, xl: 1 }}>
          <Button
            onClick={onEnabled}
            disabled={!isExchangeEnabled || (!functional && !enabled)}
            sx={{
              color: "tertiary.main",
              fontSize: "0.8rem",
              "&:disabled": {
                color: theme.palette.text.disabled,
              },
            }}
            color={"secondary"}
            startIcon={enabled ? <PauseOutlinedIcon /> : <PlayArrowOutlinedIcon />}>
            {enabled ? t("dcaBots.pause") : t("dcaBots.activate")}
          </Button>
          <Button sx={{ fontSize: "0.8rem" }} onClick={onDelete} startIcon={<HighlightOffIcon />} color="error">
            {t("delete")}
          </Button>
        </Stack>
        <Button
          sx={{ backgroundColor: "tertiary.main", fontSize: "0.70rem", height: "max-content", padding: "6px 10px"}}
          component={Link}
          to={`/upravit-strategii/${id}`}
          startIcon={<SettingsIcon />}
          variant="contained">
          {t("dcaBots.editSettings")}
        </Button>
      </Stack>
    </Card>
  );
};

export default ControlPanel;
