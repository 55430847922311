import { GlobalStyles } from "@mui/material";
import imageBackgroundBlueBottom from "assets/images/background-blue-bottom.png";
import imageBackgroundBlue from "assets/images/background-blue.png";
import imageBackgroundGold from "assets/images/background-gold.png";
import imageBackgroundHomepage from "assets/images/background-homepage.png";

import AffiliateProgram from "./AffiliateProgram";
import Contact from "./Contact";
import Exchanges from "./Exchanges";
import Faq from "./Faq";
import Hero from "./Hero";
import HowItWorks from "./HowItWorks";
import HowToStart from "./HowToStart";
import OtherFunctions from "./OtherFunctions";
import Pricing from "./Pricing";
import References from "./References";
import SelectStrategy from "./SelectStrategy";
import Statistics from "./Statistics";
import Strategies from "./Strategies";

const HomePage = () => {
  const globalStyle = {
    ".main-content": {
      background: `url(${imageBackgroundHomepage}), url(${imageBackgroundBlue}), url(${imageBackgroundBlueBottom}), url(${imageBackgroundGold})`,
      backgroundRepeat: "no-repeat",
      backgroundColor: "white",
      backgroundSize: "55%, 50%, 50%, 25%",
      backgroundPosition: "top left, right 0% top 18%, left 0% bottom 14%, right 0% bottom 22%",
      "@media (max-width: 2000px)": {
        backgroundSize: "65%, 50%, 50%, 25%",
      },
      "@media (max-width: 1500px)": {
        backgroundSize: "75%, 50%, 50%, 25%",
      },
      "@media (max-width: 1249px)": {
        backgroundSize: "100%, 50%, 50%, 25%",
      },
    },
  };

  return (
    <>
      <GlobalStyles styles={globalStyle} />
      <Hero />
      <Statistics />
      <Strategies />
      <HowItWorks />
      <Exchanges />
      <Pricing />
      <HowToStart />
      <SelectStrategy />
      <OtherFunctions />
      <Faq />
      <Contact />
      <References />
      <AffiliateProgram />
    </>
  );
};

export default HomePage;
