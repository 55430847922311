import { REFERRAL_COINMATE } from "constants/links";

import { useState, useEffect, useRef } from "react";

import AccountBoxIcon from "@mui/icons-material/AccountBox";
import ErrorIcon from "@mui/icons-material/Error";
import {
  Alert,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Link,
  useTheme,
  AlertTitle,
  Box,
  Stack,
  Typography,
  CircularProgress,
} from "@mui/material";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { createExchangeConnections, registerAnycoinAccount } from "API/calls";
import CheckMarkLoader from "components/elements/CheckMarkLoader";
import Modal from "components/elements/Modal";
import Select from "components/elements/Select";
import { useUserDataContext } from "context/UserDataContext";
import { FocusError } from "focus-formik-error";
import { useFormik } from "formik";
import { useAPIExchangeConnectionsList, useAPIExchanges } from "hooks/useAPI";
import { useSnackbar } from "notistack";
import { Trans, useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import { ExchangeType, SubscriptionType } from "types/enums";
import * as yup from "yup";

import { InputWrapper } from "./styles";
import IProps, { IObject, AnycointAccountState } from "./types";

const CreateModal = ({
  isOpen,
  defaultExchange,
  isRegisteringAnycoin,
  handleClose,
  handleSetIsRegisteringAnycoin,
  hasExchangeConnections
}: IProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { refreshData, subscriptionType } = useUserDataContext();
  const { mutate: exchangeConnectionsMutate } = useAPIExchangeConnectionsList();
  const { enqueueSnackbar } = useSnackbar();
  const { data } = useAPIExchanges();
  const testConnectionAlert = useRef<HTMLDivElement>(null);
  const isFree = subscriptionType === SubscriptionType.FREE;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isTestingConnection, setIsTestingConnection] = useState<boolean>(false);
  const [isCompleted, setIsCompleted] = useState<boolean>(false);
  const [isFirstConnection] = useState<boolean>(!hasExchangeConnections);
  const [testConnectionErrors, setTestConnectionErrors] = useState<string[] | null>(null);
  const [anycoinAccountState, setAnycoinAccountState] = useState<AnycointAccountState>(null);

  const initValues = { exchangeEnum: "", label: "" };
  const defaultSchema = {
    exchangeEnum: yup.string().required(t("fieldRequired")),
    label: yup.string().max(50, t("fieldMaxLength", { max: 50 })),
  };
  const [initialValues, setInitialValues] = useState<IObject>(initValues);
  const [schema, setSchema] = useState<any>(defaultSchema);
  const [renderParameters, setRenderParameters] = useState<boolean>(false);
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: yup.object(schema),
    enableReinitialize: true,
    onSubmit: async (values) => {
      const { exchangeEnum, label, ...parameters } = values;
      try {
        setIsLoading(true);
        setIsTestingConnection(true);
        const response = await createExchangeConnections({ exchangeEnum, label, parameters });
        if (!response.data.ok) {
          const errors = [];
          if (response.data.cannotReadBalances) {
            errors.push(t("exchangeConnections.modal.testConnection.errorBalance"));
          }
          if (response.data.cannotTrade) {
            errors.push(t("exchangeConnections.modal.testConnection.errorTrade"));
          }
          if (response.data.canWithdraw) {
            errors.push(t("exchangeConnections.modal.testConnection.errorWithdrawal"));
          }
          setTestConnectionErrors(errors);
          setIsTestingConnection(false);
          setIsLoading(false);
        } else {
          setTimeout(async () => {
            await exchangeConnectionsMutate();
            refreshData?.();
            enqueueSnackbar(t("exchangeConnections.successMessage"), { variant: "success" });
            setIsCompleted(true);
          }, 1500);
        }
      } catch (err: any) {
        if (err?.response?.status === 428) {
          enqueueSnackbar(t("exchangeConnections.errorMessage428"), { variant: "error" });
        } else {
          enqueueSnackbar(t("exchangeConnections.errorMessage"), { variant: "error" });
        }
        setIsTestingConnection(false);
        setIsLoading(false);
      }
    },
  });

  const initValuesAnycoin = { agreement: false };
  const [initialValuesAnycoin, setInitialValuesAnycoin] = useState<IObject>(initValuesAnycoin);
  const formikAnycoin = useFormik({
    initialValues: initialValuesAnycoin,
    validationSchema: yup.object().shape({
      agreement: yup.bool().oneOf([true], t("exchangeConnections.modal.registerAnycoinError")),
    }),
    enableReinitialize: true,
    onSubmit: () => {
      handleRegisterAnycoin();
    },
  });

  const initFormik = (exchangeEnum: ExchangeType) => {
    const connectionParameters = getSelectedConnectionParameters();
    const formValues: IObject = { exchangeEnum: exchangeEnum, label: formik.values.label };
    const formSchema: IObject = defaultSchema;

    connectionParameters?.forEach((parameter) => {
      formValues[parameter.parameter] = "";
      if (parameter.parameter === "clientId" && exchangeEnum === ExchangeType.COINMATE) {
        formSchema[parameter.parameter] = yup
          .number()
          .typeError(t("fieldErrors.onlyNumber"))
          .required(t("fieldRequired"));
      } else formSchema[parameter.parameter] = yup.string().required(t("fieldRequired"));
    });

    setInitialValues(formValues);
    setSchema(formSchema);
    setRenderParameters(true);
  };

  useEffect(() => {
    if (defaultExchange) {
      initFormik(defaultExchange);
    }
  }, [defaultExchange]);

  useEffect(() => {
    if (formik.values.exchangeEnum) {
      initFormik(formik.values.exchangeEnum);
      setTestConnectionErrors(null);
    }
  }, [formik.values.exchangeEnum]);

  useEffect(() => {
    if (isRegisteringAnycoin) {
      initFormik(ExchangeType.ANYCOIN);
    }
  }, [isRegisteringAnycoin]);

  useEffect(() => {
    if (testConnectionAlert.current && testConnectionErrors !== null && testConnectionErrors.length > 0) {
      testConnectionAlert.current.scrollIntoView();
    }
  }, [testConnectionAlert.current, testConnectionErrors]);

  const getSelectedConnectionParameters = () => {
    return data?.exchanges.filter((exchange) => {
      return exchange.exchangeEnum === formik.values.exchangeEnum;
    })?.[0]?.connectionParameters;
  };

  const handleRegisterAnycoin = async () => {
    try {
      setIsLoading(true);
      await registerAnycoinAccount();
      setAnycoinAccountState("SUCCESS");
      enqueueSnackbar(t("exchangeConnections.modal.registerAnycoinSuccess"), { variant: "success" });
      await exchangeConnectionsMutate();
      refreshData?.();
    } catch (error: any) {
      if (error?.response?.status === 409) {
        enqueueSnackbar(t("exchangeConnections.modal.anycoinAccountExistsError"), { variant: "warning" });
        setAnycoinAccountState("ALREADY_EXISTS");
      } else {
        enqueueSnackbar(t("commonError"), { variant: "error" });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getExchangeTitle = (exchangeEnum: ExchangeType) =>
    data?.exchanges.find((item) => item.exchangeEnum === exchangeEnum)?.title;

  const renderInstructions = (exchangeEnum: ExchangeType) => {
    const instructionLinks = {
      COINMATE: "https://docs.google.com/document/d/1RKgwGfQ8X3q2LFrcjz6CF6E18mWkw_QkW_uORHUaWfc/edit?usp=sharing",
      ANYCOIN: "#",
      BINANCE: "https://docs.google.com/document/d/1UYdm-cHIA1UTAcea24ecXnw-KX_LQZNSyvfOwp3acr0/edit?usp=sharing",
      BITSTAMP: "https://docs.google.com/document/d/1nGK4AkB_-slQBobsM0NCzVW66vLLsi9tsCRuV3V8YLw/edit?usp=sharing",
      COINBASEPRO: "https://docs.google.com/document/d/1cH6CyPSCEsqDG0GW79Q5vplepr7yaFkgeMsojNCWEmE/edit?usp=sharing",
    };
    return (
      <>
        {exchangeEnum === ExchangeType.ANYCOIN ? (
          <Alert severity="info" sx={{ mt: 2 }}>
            <Trans
              i18nKey="exchangeConnections.modal.infoAnycoin"
              components={{
                a: (
                  <Link
                    color={theme.palette.info.main}
                    href="https://www.anycoin.cz/myaccount?section=applications"
                    target="_blank"
                    sx={{ fontWeight: "bold" }}
                  />
                ),
              }}
            />
            <Divider sx={{ pt: 2, mb: 2 }} />
            {t("exchangeConnections.modal.infoAnycoinNoAccount")}{" "}
            <Link
              href={instructionLinks[exchangeEnum]}
              color={theme.palette.info.main}
              onClick={() => handleSetIsRegisteringAnycoin(true)}>
              <Trans i18nKey="exchangeConnections.modal.infoAnycoinBonus" />
            </Link>
          </Alert>
        ) : exchangeEnum === ExchangeType.COINMATE ? (
          <Alert severity="info" sx={{ mt: 2 }}>
            <Link href={instructionLinks[exchangeEnum]} target={"_blank"} component="a" color={theme.palette.info.main}>
              <Trans i18nKey="exchangeConnections.modal.infoInstructions">
                {{ exchange: getExchangeTitle(exchangeEnum) }}
              </Trans>
            </Link>
            <Divider sx={{ pt: 2, mb: 2 }} />
            {t("exchangeConnections.modal.infoCoinmateNoAccount")}{" "}
            <Link href={REFERRAL_COINMATE} color={theme.palette.info.main} target="_blank">
              <strong>
                <Trans i18nKey="exchangeConnections.modal.infoCoinmate" />
              </strong>
            </Link>
          </Alert>
        ) : (
          <Alert severity="info" sx={{ mt: 2 }}>
            <Link href={instructionLinks[exchangeEnum]} target={"_blank"} component="a" color={theme.palette.info.main}>
              <Trans i18nKey="exchangeConnections.modal.infoInstructions">
                {{ exchange: getExchangeTitle(exchangeEnum) }}
              </Trans>
            </Link>
          </Alert>
        )}
      </>
    );
  };

  const renderConnectionParameters = () => {
    const connectionParameters = data?.exchanges.filter((exchange) => {
      return exchange.exchangeEnum === formik.values.exchangeEnum;
    })?.[0]?.connectionParameters;

    return (
      <>
        <InputWrapper>
          <TextField
            fullWidth
            autoComplete="off"
            id="label"
            name="label"
            label={t("exchangeConnections.modal.label")}
            value={formik.values.label}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.label)}
            helperText={formik.errors.label}
          />
        </InputWrapper>

        {renderInstructions(formik.values.exchangeEnum)}

        {connectionParameters?.map((parameter, index) => {
          return (
            <InputWrapper key={index + parameter.parameter}>
              <TextField
                required
                fullWidth
                autoComplete="off"
                id={parameter.parameter}
                name={parameter.parameter}
                label={parameter.title}
                value={formik.values[parameter.parameter] ?? ""}
                onChange={formik.handleChange}
                error={formik.touched[parameter.parameter] && Boolean(formik.errors[parameter.parameter])}
                helperText={formik.touched[parameter.parameter] && formik.errors[parameter.parameter]}
              />
            </InputWrapper>
          );
        })}
      </>
    );
  };

  const getActions = () => {
    if (isRegisteringAnycoin) {
      return (
        <>
          {anycoinAccountState !== "SUCCESS" && (
            <Button color="error" onClick={handleClose}>
              {t("cancel")}
            </Button>
          )}
          {anycoinAccountState !== null ? (
            <>
              {anycoinAccountState === "SUCCESS" && (
                <Button variant="contained" onClick={handleClose}>
                  {t("continue")}
                </Button>
              )}
              {anycoinAccountState === "ALREADY_EXISTS" && (
                <Button variant="contained" onClick={() => handleSetIsRegisteringAnycoin(false)}>
                  {t("exchangeConnections.modal.connectExistingAccount")}
                </Button>
              )}
            </>
          ) : (
            <Button variant="contained" onClick={() => formikAnycoin.handleSubmit()}>
              {t("exchangeConnections.modal.registerAnycoin")}
            </Button>
          )}
        </>
      );
    }

    if (isTestingConnection) return;

    return (
      <>
        <Button color="error" onClick={handleClose}>
          {t("cancel")}
        </Button>
        <Button variant="contained" onClick={() => formik.handleSubmit()}>
          {t("create")}
        </Button>
      </>
    );
  };

  const getLoading = () => {
    if (isRegisteringAnycoin) {
      <Box sx={{ pt: 8, pb: 12, alignSelf: "center", textAlign: "center" }}>
        <Stack sx={{ display: "flex", alignItems: "center" }}>
          <CircularProgress size={64} />
        </Stack>
      </Box>;
    }

    return (
      <Box sx={{ pt: 2, alignSelf: "center", textAlign: "center" }}>
        <Stack spacing={2} sx={{ display: "flex", alignItems: "center" }}>
          <CheckMarkLoader completed={isCompleted} />
          {isTestingConnection && !isCompleted && (
            <Alert severity="info" sx={{ mt: 2 }}>
              {t("exchangeConnections.modal.testConnection.info")}
            </Alert>
          )}
          {isCompleted && (
            <Stack spacing={3}>
              <Typography variant="h5">{t("exchangeConnections.modal.testConnection.success")}</Typography>
              {isFirstConnection && isFree ? (
                <Alert severity="info">
                <Trans i18nKey="exchangeConnections.modal.testConnection.isFree" />
              </Alert>
              ) : undefined}
              <Button size="large" color="success" variant="contained" onClick={handleClose}>
                {t("exchangeConnections.modal.testConnection.successButton")}
              </Button>
            </Stack>
          )}
        </Stack>
      </Box>
    );
  };

  return (
    <Modal
      title={
        isRegisteringAnycoin
          ? t("exchangeConnections.modal.registerAnycoinTitle")
          : t("exchangeConnections.modal.createNew")
      }
      open={isOpen}
      onCancel={handleClose}
      withCloseButton
      disableCancelButton
      customActions={getActions()}>
      {isLoading && getLoading()}
      {!isLoading && (
        <>
          <FocusError formik={formikAnycoin} />
          {isRegisteringAnycoin ? (
            <>
              {anycoinAccountState !== null ? (
                <>
                  {anycoinAccountState === "SUCCESS" && (
                    <>
                      <Alert severity="success" sx={{ mb: 2 }}>
                        <AlertTitle>{t("exchangeConnections.modal.successAnycoinTitle")}</AlertTitle>
                        <Trans i18nKey="exchangeConnections.modal.successAnycoinSubtitle" />
                      </Alert>
                      <Alert severity="info" sx={{ mb: 2 }}>
                        <AlertTitle>{t("exchangeConnections.modal.successAnycoinLinkFundTitle")}</AlertTitle>
                        <Trans
                          i18nKey="exchangeConnections.modal.successAnycoinLinkFundSubtitle"
                          components={{
                            a: (
                              <Link
                                color={theme.palette.info.main}
                                href="https://www.anycoin.cz/myaccount?section=funding"
                                target="_blank"
                              />
                            ),
                          }}
                        />
                      </Alert>
                      <Alert icon={<AccountBoxIcon fontSize="inherit" />} severity="warning" sx={{ mb: 2 }}>
                        <AlertTitle>{t("exchangeConnections.modal.successAnycoinLinkAccountTitle")}</AlertTitle>
                        <Trans
                          i18nKey="exchangeConnections.modal.successAnycoinLinkAccountSubtitle"
                          components={{
                            a: (
                              <Link
                                color={theme.palette.info.main}
                                href="https://www.anycoin.cz/myaccount"
                                target="_blank"
                              />
                            ),
                          }}
                        />
                      </Alert>
                    </>
                  )}
                  {anycoinAccountState === "ALREADY_EXISTS" && (
                    <Alert severity="warning" sx={{ mb: 2 }}>
                      <AlertTitle>{t("exchangeConnections.modal.anycoinAccountExistsTitle")}</AlertTitle>
                      {t("exchangeConnections.modal.anycoinAccountExistsSubtitle")}
                    </Alert>
                  )}
                </>
              ) : (
                <>
                  <Alert severity="success" sx={{ mb: 2 }}>
                    <AlertTitle>{t("exchangeConnections.modal.upsellAnycoinTitle")}</AlertTitle>
                    <ul style={{ padding: "0 0 0 1em" }}>
                      <li>
                        <Trans
                          i18nKey="exchangeConnections.modal.upsellAnycoinAuthorization1"
                          components={{
                            strong: <strong />,
                          }}
                        />
                      </li>
                      <li>
                        <Trans
                          i18nKey="exchangeConnections.modal.upsellAnycoinAuthorization2"
                          components={{
                            strong: <strong />,
                          }}
                        />
                      </li>
                    </ul>
                  </Alert>
                  <Alert severity="info" sx={{ mb: 2 }}>
                    {t("exchangeConnections.modal.upsellAnycoinInfo")}
                  </Alert>
                  <Alert icon={<AccountBoxIcon fontSize="inherit" />} severity="warning" sx={{ mb: 2 }}>
                    <Box>
                      {t("exchangeConnections.modal.upsellAnycoinExistingAccount")}{" "}
                      <Link
                        href="#"
                        color={theme.palette.info.main}
                        onClick={() => handleSetIsRegisteringAnycoin(false)}>
                        {t("exchangeConnections.modal.upsellAnycoinExistingAccountAction")}
                      </Link>
                    </Box>
                  </Alert>
                  <FormControl
                    error={Boolean(formikAnycoin.errors.agreement)}
                    variant="standard"
                    sx={{ width: "100%", alignItems: "center" }}>
                    <FormControlLabel
                      control={<Checkbox />}
                      id={"agreement"}
                      name={"agreement"}
                      label={
                        <>
                          {`${t("exchangeConnections.modal.registerAnycoinAgreeWith")}`}{" "}
                          <a href="https://www.anycoin.cz/terms" target="_blank">
                            {t("exchangeConnections.modal.registerAnycoinTerms")}
                          </a>
                        </>
                      }
                      checked={formikAnycoin.values.agreement}
                      onChange={formikAnycoin.handleChange}
                    />
                    {<FormHelperText>{formikAnycoin.errors.agreement}</FormHelperText>}
                  </FormControl>
                </>
              )}
            </>
          ) : (
            <>
              <Select
                id="exchangeEnum"
                name="exchangeEnum"
                label={t("exchangeConnections.modal.exchange")}
                value={formik.values.exchangeEnum ?? ""}
                onChange={formik.handleChange}
                error={formik.touched.exchangeEnum && formik.errors.exchangeEnum}>
                {data?.exchanges?.map((exchange, index) => (
                  <MenuItem key={`exchange-${index}`} value={exchange.exchangeEnum}>
                    {exchange.title}
                  </MenuItem>
                ))}
              </Select>
              {formik.values.exchangeEnum === ExchangeType.COINBASEPRO && (
                <Alert severity="error" sx={{ marginTop: 1.5 }}>
                  <AlertTitle>{t("exchangeConnections.modal.coinbaseProAlert.title")}</AlertTitle>
                  {t("exchangeConnections.modal.coinbaseProAlert.description")}
                </Alert>
              )}
              {formik.values.exchangeEnum === ExchangeType.BITSTAMP && (
                <Alert severity="warning" sx={{ marginTop: 1.5 }}>
                  <AlertTitle>{t("exchangeConnections.modal.bitstampAlert.title")}</AlertTitle>
                  {t("exchangeConnections.modal.bitstampAlert.description")}
                </Alert>
              )}
              {renderParameters && renderConnectionParameters()}
            </>
          )}
          {testConnectionErrors !== null && testConnectionErrors.length > 0 && (
            <Alert icon={<ErrorIcon fontSize="inherit" />} severity="error" sx={{ mt: 1 }} ref={testConnectionAlert}>
              <AlertTitle>{t("exchangeConnections.modal.testConnection.errorTitle")}</AlertTitle>
              <Box>
                <p>{t("exchangeConnections.modal.testConnection.errorSubtitle")}</p>
                <ul style={{ margin: 0, padding: 0, marginLeft: "1.5em", marginBottom: "0.5em" }}>
                  {testConnectionErrors.map((item, key) => {
                    return <li key={key}>{item}</li>;
                  })}
                </ul>
              </Box>
            </Alert>
          )}
        </>
      )}
    </Modal>
  );
};

export default CreateModal;
