import { useState } from "react";

import { TextField, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import { updateInitialAmounts } from "API/calls";
import Modal from "components/elements/Modal";
import PriceField from "components/elements/PriceField";
import { useFormik } from "formik";
import useExchangeRate from "hooks/useExchangeRate";
import useExchangesMetadata from "hooks/useExchangesMetadata";
import { useSnackbar } from "notistack";
import { useTranslation, Trans } from "react-i18next";
import { renderNumber } from "utils/formatter";
import * as yup from "yup";

interface IProps {
  id: string;
  isOpen: boolean;
  initialBaseCurrencyAmount?: number;
  initialCounterCurrencyAmount?: number;
  baseCurrency?: string;
  counterCurrency?: string;
  exchangeEnum?: string;
  handleClose: () => void;
  refreshData: () => void;
  bidPrice?: number;
}

const InitialValuesModal = ({
  id,
  isOpen,
  initialBaseCurrencyAmount,
  initialCounterCurrencyAmount,
  baseCurrency,
  counterCurrency,
  exchangeEnum,
  handleClose,
  refreshData,
  bidPrice,
}: IProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { getUSDPrice } = useExchangeRate();

  const currencyPair = `${baseCurrency}/${counterCurrency}`;
  const { counterCurrDisplayedScale, baseCurrDisplayedScale, currencyPairPriceScale } = useExchangesMetadata(
    exchangeEnum ?? "",
    currencyPair
  );

  const initValues = {
    initialBaseCurrency: `${initialBaseCurrencyAmount}`.replace(".", ","),
    initialCounterCurrency: `${initialCounterCurrencyAmount}`.replace(".", ",")
  };
  const formik = useFormik({
    initialValues: initValues,
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      initialBaseCurrency: yup
        .string()
        .test("initialBaseCurrency", t("dcaBots.initialValues.validationError"), (initialBaseCurrency, ctx) => {
          if (ctx.parent.initialCounterCurrency && !initialBaseCurrency) {
            return false;
          }
          if (Number(initialBaseCurrency) === 0) {
            return Number(ctx.parent.initialCounterCurrency) === 0;
          }
          return true;
        }),
      initialCounterCurrency: yup
        .string()
        .test("initialCounterCurrency", t("dcaBots.initialValues.validationError"), (initialCounterCurrency, ctx) => {
          if (ctx.parent.initialBaseCurrency && !initialCounterCurrency) {
            return false;
          }
          if (Number(initialCounterCurrency) === 0) {
            return Number(ctx.parent.initialBaseCurrency) === 0;
          }
          return true;
        }),
    }),
    onSubmit: async () => {
      try {
        setIsLoading(true);
        await updateInitialAmounts({
          id,
          initialBaseCurrencyAmount: Number(`${formik.values.initialBaseCurrency}`.replace(",", ".")),
          initialCounterCurrencyAmount: Number(`${formik.values.initialCounterCurrency}`.replace(",", ".")),
        });
        refreshData();
        handleClose();
        enqueueSnackbar(t("dcaBots.initialValues.success"), { variant: "success" });
      } catch (error: any) {
        if (error?.response?.status === 403) {
          enqueueSnackbar(t("dcaBots.initialValues.errorForbidden"), { variant: "error" });
        } else {
          enqueueSnackbar(t("dcaBots.initialValues.error"), { variant: "error" });
        }
      } finally {
        setIsLoading(false);
      }
    },
  });

  const getPctDifference = (yourAvgPrice: number) => {
    if (!bidPrice) return 0;

    return (100 * (bidPrice - yourAvgPrice)) / yourAvgPrice;
  };

  const getInitialAvgPriceUSD = (initialAvgPrice: number) => {
    const usdPrice = getUSDPrice(counterCurrency ?? "", initialAvgPrice, currencyPairPriceScale);

    if (usdPrice) return `~ ${usdPrice}`;
  }; 

  const getBidPriceUsd = () => {
    const usdPrice = getUSDPrice(counterCurrency ?? "", bidPrice ?? 0, currencyPairPriceScale);

    if (usdPrice) return ` (~ ${usdPrice})`;
  };

  const getInitialValuesForm = () => {
    const counterInitial = Number(`${formik.values.initialCounterCurrency}`.replace(",", "."));
    const baseInitial = Number(`${formik.values.initialBaseCurrency}`.replace(",", "."));
    const pctDifference = getPctDifference(counterInitial / baseInitial);
    const canRenderResults = !isNaN(pctDifference) && counterInitial > 0 && baseInitial > 0;
    const initialAvgPrice = canRenderResults ? counterInitial / baseInitial : 0;
    const initialAvgPriceUSD = canRenderResults ? getInitialAvgPriceUSD(initialAvgPrice) : "";

    return (
      <Stack spacing={2.5}>
        <Typography>
          <Trans i18nKey="dcaBots.initialValues.description1" />
        </Typography>
        <Typography>
          <Trans i18nKey="dcaBots.initialValues.description2" />
        </Typography>
        <PriceField
          autoComplete="off"
          precision={baseCurrDisplayedScale}
          fullWidth
          onlyPositive
          allowZero
          id="initialBaseCurrency"
          name="initialBaseCurrency"
          label={t("dcaBots.initialValues.initialBaseCurrencyLabel")}
          value={formik.values.initialBaseCurrency}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={Boolean(formik.errors.initialBaseCurrency)}
          helperText={formik.errors.initialBaseCurrency}
          inputProps={{
            maxLength: 19,
          }}
          InputProps={{
            endAdornment: <InputAdornment position="start">{baseCurrency}</InputAdornment>,
          }}
        />
        <PriceField
          autoComplete="off"
          precision={counterCurrDisplayedScale}
          fullWidth
          onlyPositive
          allowZero
          id="initialCounterCurrency"
          name="initialCounterCurrency"
          label={t("dcaBots.initialValues.initialCounterCurrencyLabel")}
          value={formik.values.initialCounterCurrency}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={Boolean(formik.errors.initialCounterCurrency)}
          helperText={formik.errors.initialCounterCurrency}
          inputProps={{
            maxLength: 19,
          }}
          InputProps={{
            endAdornment: <InputAdornment position="start">{counterCurrency}</InputAdornment>,
          }}
        />
        <TextField
          disabled
          fullWidth
          label={t("dcaBots.initialValues.initialAvgPriceLabel")}
          value={canRenderResults ? renderNumber(initialAvgPrice, currencyPairPriceScale, true) : "-"}
          helperText={canRenderResults ? initialAvgPriceUSD : ""}
          InputProps={{
            endAdornment: <InputAdornment position="start">{counterCurrency}</InputAdornment>,
          }}
        />
        <ul>
          <li>
            <Trans
              i18nKey="dcaBots.initialValues.bidPrice"
              values={{
                baseCurrency,
                counterCurrency,
                price: bidPrice ? renderNumber(bidPrice, currencyPairPriceScale, true) : "-",
              }}
            />
            {getBidPriceUsd()}
          </li>
          <li style={{ marginTop: "0.5em" }}>
            <Trans
              i18nKey="dcaBots.initialValues.currentDifference"
              values={{
                pct: canRenderResults ? renderNumber(pctDifference, 0) : "-",
                sign: canRenderResults ? (pctDifference < 0 ? "" : "+") : "",
              }}
            />
          </li>
        </ul>
      </Stack>
    );
  };

  return (
    <Modal
      open={isOpen}
      size="xs"
      onCancel={handleClose}
      withCloseButton
      title={t("dcaBots.initialValues.title")}
      customConfirmButton={
        <Button
          disabled={isLoading}
          variant="contained"
          onClick={() => formik.handleSubmit()}>
          {t("dcaBots.initialValues.ctaSave")}
        </Button>
      }>
      {getInitialValuesForm()}
    </Modal>
  );
};

export default InitialValuesModal;
