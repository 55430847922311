import React from "react";

import Box from "@mui/material/Box";
import CenterWrapper from "components/elements/CenterWrapper";
import PageTitle from "components/elements/PageTitle";
import DcaBotDetail from "components/modules/DcaBotDetail";
import Loading from "components/modules/Loading";
import { useParams } from "react-router-dom";

const DcaBotDetailPage: React.FC = () => {
  const { id } = useParams();

  if (!id) {
    return (
      <CenterWrapper>
        <Loading />
      </CenterWrapper>
    );
  } 

  return (
    <Box key={id} sx={{ display: "flex", width: "100%", justifyContent: "center" }}>
      <PageTitle i18nKey="dcaBots.meta_title" />
      <DcaBotDetail id={id} />
    </Box>
  );
};

export default DcaBotDetailPage;
