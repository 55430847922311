import { Box } from "@mui/material";
import VideoPoster from "assets/images/hero-video-poster.png";
import MobileFrame from "assets/images/mobile-frame.png";
import Video from "assets/videos/hero.mp4";

const HeroMobile = () => {
  return (
    <Box position={"relative"} display={"flex"} justifyContent={"center"}>
      <img style={{ width: "70%", visibility: "hidden" }} src={MobileFrame} alt="mobile-frame"></img>
      <img
        style={{
          position: "absolute",
          height: "100%",
          zIndex: 2,
        }}
        src={MobileFrame}
        alt="mobile-frame"></img>
      <video
        style={{
          height: "75%",
          position: "absolute",
          right: "30%",
          top: "11%",
        }}
        autoPlay
        muted
        loop
        poster={VideoPoster}
        src={Video}></video>
    </Box>
  );
};

export default HeroMobile;
