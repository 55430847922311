import { SvgIcon } from '@mui/material';

export const CircleCheck = (props: any) => {
  return (
    <SvgIcon {...props}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6 13.223L8.45 16.7C8.54293 16.8388 8.66768 16.9534 8.81385 17.0343C8.96002 17.1152 9.12339 17.16 9.29037 17.165C9.45735 17.17 9.6231 17.135 9.77384 17.063C9.92458 16.991 10.0559 16.884 10.157 16.751L18 6.828" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12 23.249C18.2132 23.249 23.25 18.2122 23.25 11.999C23.25 5.78582 18.2132 0.749023 12 0.749023C5.7868 0.749023 0.75 5.78582 0.75 11.999C0.75 18.2122 5.7868 23.249 12 23.249Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    </SvgIcon>
  );
}
