import React from "react";

import { useTheme } from "@mui/material";
import CandleChart, { convertOHLCData } from "components/elements/CandleChart";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { useUserDataContext } from "context/UserDataContext";
import useExchangeRate from "hooks/useExchangeRate";
import useExchangesMetadata from "hooks/useExchangesMetadata";
import { useTranslation } from "react-i18next";
import { OHLCInterval } from "types/enums";
import { getColorByMode, splitCurrencyPair } from "utils";

import IProps from "./types";

const BROKERAGE_PRICE_LINE_COLOR = {
  askPrice: "#0d7800",
  bidPrice: "#7c0000",
};

const ChartItem: React.FC<IProps> = ({ data, OHLCData, exchangeEnum, currencyPair, isBrokerage, interval }) => {
  const { colors } = useMUIThemeModeContext();
  const theme = useTheme();
  const { t } = useTranslation();
  const { currencyPairPriceScale } = useExchangesMetadata(exchangeEnum ?? "", currencyPair);
  const { showInUSD } = useUserDataContext();
  const { getUSDOriginalPrice } = useExchangeRate();
  const { counterCurrency } = splitCurrencyPair(currencyPair);

  const createBrokeragePriceLine = (priceLines: any[], type: "askPrice" | "bidPrice") => {
    const value = showInUSD && data?.[type] ? priceTransform(data?.[type]) : data?.[type];

    priceLines.push({
      price: value,
      color: BROKERAGE_PRICE_LINE_COLOR[type],
      lineWidth: 3,
      lineStyle: 0,
      axisLabelVisible: true,
      title: t(`gridBot.brokeragePriceLines.${type}`),
    });
  };

  const priceTransform = (price: number) => {
    const inUSD = getUSDOriginalPrice(counterCurrency, price);
    return inUSD ?? price;
  };

  const getPriceLines = () => {
    const result: any[] = [];

    if (data?.priceLevels) {
      data.priceLevels.forEach((level) => {
        const isOpen = !!level.orderSide && (level.priceLevelStatus === "OPEN" || level.priceLevelStatus === "PARTIALLY_FILLED");
        const isNotEnough = level.priceLevelStatus === "NOT_ENOUGH_BALANCE";
        const value = showInUSD ? priceTransform(level.value) : level.value;

        result.push({
          price: value,
          color: isOpen
            ? level.orderSide === "BUY"
              ? colors.success
              : colors.error
            : isNotEnough
            ? "gray"
            : getColorByMode(theme, "black", "gray"),
          lineWidth: isNotEnough ? 1 : 1.5,
          lineStyle: isOpen ? 0 : isNotEnough ? 3 : 2,
          axisLabelVisible: isOpen, // show axis price label only for created orders
        });
      });
    }

    if (isBrokerage) {
      createBrokeragePriceLine(result, "askPrice");
      createBrokeragePriceLine(result, "bidPrice");
    }

    return result;
  };

  return (
    <CandleChart
      data={convertOHLCData(OHLCData, showInUSD ? priceTransform : undefined)}
      dateWithTime={interval !== OHLCInterval.ONE_DAY}
      xAxisWithTime={interval === OHLCInterval.ONE_MINUTE}
      pricePrecision={currencyPairPriceScale}
      priceLines={getPriceLines()}
    />
  );
};

export default ChartItem;
