import React from "react";

import { useAPICashbackSats } from "hooks/useAPI";

import Item from "..";
import SkeletonItem from "../Skeleton";

const CashbackSatsItem: React.FC = () => {
  const { data } = useAPICashbackSats();

  if (!data) return <SkeletonItem canRenderCashbackSats={true} />;

  const totalAmount = data.cashbackSats.reduce((acc, { s }) => acc + s, 0);

  return (
    <Item
      key={`DcaSummaryItem-sats`}
      summary={{
        totalAmount: totalAmount,
        withdrawnAmount: 0,
        availableAmount: totalAmount,
      }}
      isSats
    />
  );
};

export default CashbackSatsItem;
