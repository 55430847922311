import React from "react";

import { Alert, AlertTitle, Button, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";

const POSITIVE_INFINITY = 99999999999999;

const StyledTooltipFreeUserUpsell = styled(({ className, placement, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    disableFocusListener
    leaveTouchDelay={POSITIVE_INFINITY}
    enterTouchDelay={1}
    placement={placement}
    TransitionComponent={Zoom}
    classes={{ popper: className }}
  />
))(() => {
  const { colors } = useMUIThemeModeContext();

  return {
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: colors.white,
      color: "rgba(0, 0, 0, 0.87)",
      border: "1px solid #dadde9",
      boxShadow: "0px 1px 20px 3px rgba(0, 0, 0, 0.2)",
      marginBottom: "12px !important",
    },
  };
});

interface TTooltipFreeUserUpsellProps {
  children: React.ReactNode;
  placement?: 'bottom-end' | 'bottom-start' | 'bottom' | 'left-end' | 'left-start' | 'left' | 'right-end' | 'right-start' | 'right' | 'top-end' | 'top-start' | 'top';
}

const TooltipFreeUserUpsell: React.FC<TTooltipFreeUserUpsellProps> = ({ children, placement }) => {
  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    event.preventDefault();

    navigate("/predplatne");
  };

  return (
    <StyledTooltipFreeUserUpsell
      placement={placement || "top"}
      title={
        <Stack spacing={1}>
          <Alert severity="warning">
            <AlertTitle>{t("subscriptionUpsellModal.title")}</AlertTitle>
            <div>{t("subscriptionUpsellModal.description")}</div>
          </Alert>
          <Button color="success" variant="contained" size="small" onClick={handleClick}>
            {t("subscriptionUpsellModal.cta")}
          </Button>
        </Stack>
      }>
      <div>{children}</div>
    </StyledTooltipFreeUserUpsell>
  );
};

export default TooltipFreeUserUpsell;
