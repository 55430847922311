import { DISCORD } from "constants/links";

import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Box, Grid, Link, Stack, Typography, useTheme } from "@mui/material";
import stosujTutorial from "assets/images/stosuj-tutorial.jpg";
import Card2 from "components/elements/Card2";
import SectionCard from "components/elements/SectionCard";
import { Discord } from "components/icons";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { useTranslation, Trans } from "react-i18next";

const Community = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { colors } = useMUIThemeModeContext();

  const VideoBox = () => {
    return (
      <Link
        href="https://www.youtube.com/watch?v=uuMalJpmHnM"
        target="_blank"
        sx={{ display: "block", position: "relative", width: "100%" }}>
        <OpenInNewIcon
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            marginLeft: "-32px",
            marginTop: "-32px",
            color: colors.white,
            fontSize: "64px",
            filter: "drop-shadow(3px 3px 10px rgb(0 0 0 / 0.8))",
            transition: "transform 0.2s ease",
            "&:hover": {
              transform: "scale(1.1)",
            },
          }}
        />
        <img
          src={stosujTutorial}
          alt={t("dashboard.community.watchTutorial")}
          style={{ width: "100%", borderRadius: "10px" }}
        />
      </Link>
    );
  };

  const DiscordBox = () => {
    const { colors } = useMUIThemeModeContext();

    return (
      <Box sx={{ textAlign: "center", alignItems: "center", display: "flex", height: "100%", p: 2 }}>
        <Stack sx={{ alignSelf: "center", width: "100%" }} spacing={1}>
          <Box>
            <Link href={DISCORD} target="_blank">
              <Discord height={80} width={80} style={{ width: 80, height: 80 }} />
            </Link>
          </Box>
          <Box>
            <Typography color={colors.gray600} fontSize={"0.875rem"}>
              <Trans
                i18nKey="dashboard.community.discord"
                components={{
                  a: (
                    <Link
                      color={theme.palette.info.main}
                      href={DISCORD}
                      target="_blank"
                      sx={{ fontWeight: "bold" }}
                    />
                  ),
                  strong: <strong />,
                }}
              />
            </Typography>
          </Box>
        </Stack>
      </Box>
    );
  };

  return (
    <SectionCard title={t("dashboard.community.title")}>
      <Card2 pb={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={5}>
            <VideoBox />
          </Grid>
          <Grid item xs={12} lg={7}>
            <DiscordBox />
          </Grid>
        </Grid>
      </Card2>
    </SectionCard>
  );
};

export default Community;
