import { Badge, BadgeProps } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { CreditMenu, CreditMenuDark } from "components/icons";
import { useUserDataContext } from "context/UserDataContext";
import { getComponentByMode } from "utils";

interface IProps {
  size?: "small" | "normal";
}

const StyledBadge = styled(Badge)<BadgeProps & IProps>(({ theme, size }) => ({
  "& .MuiBadge-badge": {
    right: size === "small" ? 2 : -5,
    top: size === "small" ? 0 : 4,
    border: `2px solid ${theme.palette.common.white}`,
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
    fontSize: size === "small" ? "0.625rem" : "0.75rem",
  },
}));

const CreditBadge = ({ size = "normal" }: IProps) => {
  const theme = useTheme();
  const { creditsUnclaimed } = useUserDataContext();

  return (
    <StyledBadge
      badgeContent={creditsUnclaimed}
      invisible={!creditsUnclaimed}
      color="primary"
      max={Number.MAX_SAFE_INTEGER}
      size={size}>
      {getComponentByMode(theme, <CreditMenu color="inherit" />, <CreditMenuDark color="inherit" />)}
    </StyledBadge>
  );
};

export default CreditBadge;
