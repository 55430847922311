import React from "react";

import FormControlLabel from "@mui/material/FormControlLabel";
import MUIRadio from "@mui/material/Radio";

import IProps from "./types";

const Radio = ({ ...props }: IProps) => {
  return <FormControlLabel control={<MUIRadio />} {...props} />;
};

export default Radio;
