import { SvgIcon } from "@mui/material";

export const BarChart = (props: any) => {
  return (
    <SvgIcon {...props}>
      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          opacity="0.12"
          d="M15.2998 8.6C15.2998 8.03995 15.2998 7.75992 15.1908 7.54601C15.0949 7.35785 14.942 7.20487 14.7538 7.10899C14.5399 7 14.2599 7 13.6998 7L10.8998 7C10.3398 7 10.0597 7 9.84581 7.10899C9.65765 7.20487 9.50467 7.35785 9.4088 7.54601C9.2998 7.75992 9.2998 8.03995 9.2998 8.6L9.2998 21H15.2998L15.2998 8.6Z"
          fill="currentColor"
        />
        <path
          d="M9.2998 12H4.8998C4.33975 12 4.05973 12 3.84581 12.109C3.65765 12.2049 3.50467 12.3578 3.4088 12.546C3.2998 12.7599 3.2998 13.0399 3.2998 13.6V19.4C3.2998 19.9601 3.2998 20.2401 3.4088 20.454C3.50467 20.6422 3.65765 20.7951 3.84581 20.891C4.05973 21 4.33975 21 4.8998 21H9.2998M9.2998 21H15.2998M9.2998 21L9.2998 8.6C9.2998 8.03995 9.2998 7.75992 9.4088 7.54601C9.50467 7.35785 9.65765 7.20487 9.84581 7.10899C10.0597 7 10.3398 7 10.8998 7H13.6998C14.2599 7 14.5399 7 14.7538 7.10899C14.942 7.20487 15.0949 7.35785 15.1908 7.54601C15.2998 7.75992 15.2998 8.03995 15.2998 8.6V21M15.2998 21H19.6998C20.2599 21 20.5399 21 20.7538 20.891C20.942 20.7951 21.0949 20.6422 21.1908 20.454C21.2998 20.2401 21.2998 19.9601 21.2998 19.4V4.6C21.2998 4.03995 21.2998 3.75992 21.1908 3.54601C21.0949 3.35785 20.942 3.20487 20.7538 3.10899C20.5399 3 20.2599 3 19.6998 3H16.8998C16.3398 3 16.0597 3 15.8458 3.10899C15.6577 3.20487 15.5047 3.35785 15.4088 3.54601C15.2998 3.75992 15.2998 4.03995 15.2998 4.6V8"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};
