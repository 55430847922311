export const Accept = () => {
  return (
    <svg width="27" height="28" viewBox="0 0 27 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5605 9.47758C16.8495 9.05073 17.4298 8.93896 17.8566 9.22793C18.2835 9.5169 18.3952 10.0972 18.1063 10.524L17.3334 10.0008L16.5605 9.47758ZM10.0404 14.0582L10.0463 14.065L10.0647 14.0863L10.1364 14.1688C10.1987 14.2403 10.2884 14.3427 10.3972 14.4657C10.6154 14.7122 10.9085 15.0393 11.2116 15.3649C11.4511 15.6222 11.6897 15.8709 11.8989 16.0761C12.0016 15.9454 12.1169 15.795 12.2432 15.6272C12.751 14.9523 13.399 14.0457 14.0412 13.1312C14.682 12.2184 15.312 11.3048 15.782 10.619C16.017 10.2762 16.2118 9.9906 16.3478 9.79078L16.5605 9.47758C16.5605 9.47754 16.5605 9.47758 17.3334 10.0008L18.1063 10.524L17.8909 10.841C17.754 11.0423 17.558 11.3296 17.3218 11.6743C16.8495 12.3634 16.2152 13.2832 15.5689 14.2038C14.9238 15.1226 14.2615 16.0494 13.7348 16.7495C13.4729 17.0975 13.2351 17.4021 13.0452 17.6247C12.9524 17.7335 12.8553 17.8414 12.7631 17.9289C12.7188 17.9709 12.6541 18.029 12.5761 18.0825C12.5369 18.1095 12.476 18.148 12.3982 18.1824C12.3287 18.2131 12.1866 18.2675 12.0001 18.2675C11.7406 18.2675 11.542 18.1656 11.4744 18.13C11.3782 18.0793 11.2917 18.0198 11.2229 17.9687C11.0833 17.865 10.9337 17.7333 10.7903 17.5991C10.4992 17.3269 10.16 16.9749 9.84528 16.6367C9.52781 16.2957 9.22344 15.9561 8.99919 15.7026C8.88684 15.5756 8.7941 15.4697 8.72926 15.3953L8.65386 15.3086L8.63374 15.2853L8.62696 15.2775C8.62692 15.2774 8.62635 15.2767 9.33339 14.6675L8.62696 15.2775C8.29047 14.887 8.33364 14.2969 8.72412 13.9604C9.1146 13.624 9.70395 13.6678 10.0404 14.0582Z"
        fill="#00B860"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.66208 25.3406C9.03648 25.4652 10.8067 25.467 13.3333 25.467C15.86 25.467 17.6302 25.4652 19.0046 25.3406C20.3533 25.2184 21.1366 24.9898 21.7333 24.6453C22.6657 24.107 23.44 23.3327 23.9783 22.4003C24.3228 21.8036 24.5514 21.0203 24.6736 19.6716C24.7982 18.2972 24.8 16.527 24.8 14.0003C24.8 11.4737 24.7982 9.70347 24.6736 8.32907C24.5514 6.98033 24.3228 6.19707 23.9783 5.60032C23.44 4.66794 22.6657 3.89368 21.7333 3.35537C21.1366 3.01084 20.3533 2.78225 19.0046 2.66001C17.6302 2.53545 15.86 2.53366 13.3333 2.53366C10.8067 2.53366 9.03648 2.53545 7.66208 2.66001C6.31334 2.78225 5.53008 3.01084 4.93333 3.35537C4.00095 3.89368 3.22669 4.66794 2.68838 5.60032C2.34385 6.19707 2.11526 6.98033 1.99302 8.32907C1.86845 9.70347 1.86667 11.4737 1.86667 14.0003C1.86667 16.527 1.86845 18.2972 1.99302 19.6716C2.11526 21.0203 2.34385 21.8036 2.68838 22.4003C3.22669 23.3327 4.00095 24.107 4.93333 24.6453C5.53008 24.9898 6.31334 25.2184 7.66208 25.3406ZM1.0718 4.66699C0 6.5234 0 9.01571 0 14.0003C0 18.9849 0 21.4773 1.0718 23.3337C1.77394 24.5498 2.78385 25.5597 4 26.2619C5.85641 27.3337 8.34871 27.3337 13.3333 27.3337C18.3179 27.3337 20.8103 27.3337 22.6667 26.2619C23.8828 25.5597 24.8927 24.5498 25.5949 23.3337C26.6667 21.4773 26.6667 18.9849 26.6667 14.0003C26.6667 9.01571 26.6667 6.5234 25.5949 4.66699C24.8927 3.45084 23.8828 2.44094 22.6667 1.73879C20.8103 0.666992 18.3179 0.666992 13.3333 0.666992C8.34871 0.666992 5.85641 0.666992 4 1.73879C2.78385 2.44094 1.77394 3.45084 1.0718 4.66699Z"
        fill="#00B860"
      />
    </svg>
  );
};
