import { Box, CircularProgress, Typography, useMediaQuery, useTheme } from "@mui/material";
import PageTitle from "components/elements/PageTitle";
import Panel from "components/elements/Panel";
import { useUserDataContext } from "context/UserDataContext";
import { useTranslation } from "react-i18next";

import Disable from "./Disable";
import Enable from "./Enable";

const TwoFactorAuthPage = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { mfaEnabled, loading } = useUserDataContext();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <Panel sx={{ mb: 4, p: 3 }}>
        <PageTitle i18nKey="twoFactorAuth.meta_title" />
        <Typography variant="h5" component="h1" fontWeight={600} sx={{ pb: 1, color: "tertiary.main" }}>
          {t("twoFactorAuth.title")}
        </Typography>
        <Typography variant="subtitle2" fontWeight={400} sx={{ pb: isMobile ? 0 : 3, color: "secondary.main" }}>
          {t("twoFactorAuth.subtitle")}
        </Typography>
        {loading && (
          <Box sx={{ width: "100%", justifyContent: "center", display: "flex" }}>
            <CircularProgress />
          </Box>
        )}
        {!isMobile && !loading && (mfaEnabled ? <Disable /> : <Enable />)}
      </Panel>
      {isMobile && !loading && <Panel sx={{ mb: 4 }}>{mfaEnabled ? <Disable /> : <Enable />}</Panel>}
    </>
  );
};

export default TwoFactorAuthPage;
