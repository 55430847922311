import CenterWrapper from "components/elements/CenterWrapper";
import ScrollToTop from "components/elements/ScrollToTop";
import Loading from "components/modules/Loading";
import useAppInit from "hooks/useAppInit";
import { BrowserRouter } from "react-router-dom";
import { QueryParamProvider,  } from "use-query-params";
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

import AppRoutes from "./routes";


const Router = () => {
  const { isPossibleRender } = useAppInit();

  if (!isPossibleRender)
    return (
      <CenterWrapper>
        <Loading />
      </CenterWrapper>
    );

  return (
    <BrowserRouter>
      <QueryParamProvider adapter={ReactRouter6Adapter}>
        <ScrollToTop>
          <AppRoutes />
        </ScrollToTop>
      </QueryParamProvider>
    </BrowserRouter>
  );
};

export default Router;
