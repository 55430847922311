import { useCallback, useRef, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogContent, IconButton, Stack, Typography } from "@mui/material";
import useImage from "hooks/useImage";
import QuickPinchZoom, { make3dTransformValue } from "react-quick-pinch-zoom";

import IProps from "./types";

const Gallery = ({ src, alt }: IProps) => {
  const { image } = useImage(src ?? "", "courses");
  const [open, setOpen] = useState<boolean>(false);
  const imgRef = useRef<HTMLImageElement>(null);

  const onUpdate = useCallback(({ x, y, scale }) => {
    const { current: img } = imgRef;

    if (img) {
      const value = make3dTransformValue({ x, y, scale });

      img.style.setProperty("transform", value);
    }
  }, []);

  return (
    <>
      <Stack spacing={1} alignItems="center">
        <img src={image} alt={alt} style={{ maxHeight: '300px', maxWidth: '100%', cursor: 'pointer' }} onClick={() => setOpen(true)} />
        <Typography variant="body2">{alt}</Typography>
      </Stack>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="xl">
        <IconButton
          sx={{ position: "absolute", right: 0, backgroundColor: "lightgray", zIndex: 1 }}
          edge="start"
          color="inherit"
          disableRipple
          size="small"
          onClick={() => setOpen(false)}>
          <CloseIcon />
        </IconButton>
        <DialogContent sx={{ p: 0 }}>
          <QuickPinchZoom onUpdate={onUpdate}>
            <img src={image} ref={imgRef} alt={alt} />
          </QuickPinchZoom>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Gallery;
