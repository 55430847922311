import validations from "constants/validations";

import React from "react";

import MUITextField from "@mui/material/TextField";

import IProps from "./types";

const NumberField = ({
  onlyInteger,
  onChange,
  maxLength,
  onlyPositive,
  allowZero,
  InputProps,
  inputProps,
  ...props
}: IProps) => {
  const min = allowZero ? 0 : 1;
  const regex = onlyPositive ? /\D/g : /[^\d-]/g;

  const handleChange = (e: React.ChangeEvent<any>) => {
    const { value } = e.target;

    e.target.value = value.replace(regex, "");

    if (onlyPositive && e.target.value.length >= min) {
      Number(e.target.value) < min ? (e.target.value = min) : e.target.value;
    }

    if (validations.number.test(value)) {
      return onChange && onChange(e);
    }
  };

  const maxLengthInput = (e: any) => {
    e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, maxLength);
  };

  return (
    <MUITextField
      {...props}
      inputProps={{ ...inputProps, inputMode: "numeric" }}
      InputProps={{ ...InputProps, inputProps: onlyPositive ? { min } : undefined }}
      onChange={onlyInteger ? handleChange : onChange}
      onInput={maxLength ? maxLengthInput : undefined}
    />
  );
};

export default NumberField;
