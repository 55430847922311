import React, { useEffect } from "react";

import PageTitle from "components/elements/PageTitle";
import AffiliateBox from "components/modules/AffiliateBox";
import BadgesTable from "components/modules/Credits/BadgesTable";
import Buy from "components/modules/Credits/Buy";
import CreditsVoucher from "components/modules/Credits/CreditsVoucher";
import Transactions from "components/modules/Credits/Transactions";
import { useUserDataContext } from "context/UserDataContext";
import { useAPIBadges, useAPIBadgeTypes } from "hooks/useAPI";

import Overview from "./Overview";

const CreditsDashboardPage: React.FC = () => {
  const { subscriptionType, creditsBalance, creditsUnclaimed, badgesCount, refreshData } = useUserDataContext();
  const { data: badgeTypesData } = useAPIBadgeTypes();
  const { data: badgesData } = useAPIBadges();

  useEffect(() => {
    refreshData?.();
  }, [badgesData?.badges.length, badgesCount, creditsUnclaimed, creditsBalance]);

  return (
    <>
      <PageTitle i18nKey="creditsPage.meta_title" />
      <Overview
        badges={badgesCount}
        badgesTotal={badgeTypesData?.badgeTypes.length}
        credits={creditsBalance}
        creditsUnclaimed={creditsUnclaimed}
        subscription={subscriptionType}
      />
      <BadgesTable />
      <Buy />
      <AffiliateBox />
      <Transactions limit={5} />
      <CreditsVoucher refreshData={refreshData} />
    </>
  );
};

export default CreditsDashboardPage;
