import React, { useState } from "react";

import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Box } from "@mui/material";
import Tab from "@mui/material/Tab";
import BackdropLoading from "components/elements/BackdropLoading";
import Modal from "components/elements/Modal";
import { Credit } from "components/icons";
import { useUserDataContext } from "context/UserDataContext";
import { useAPICurrencyPairsTicker } from "hooks/useAPI";
import { useTranslation } from "react-i18next";
import { SubscriptionType } from "types/enums";

import InstantBuy from "./InstantBuy";
import LimitBuy from "./LimitBuy";
import IProps from "./types";

const TradeModal = ({
  id,
  isOpen,
  handleClose,
  handleInstantTrade,
  baseCurrency,
  counterCurrency,
  exchangeEnum,
  balanceBase,
  balanceCounter,
  sumsOfOpenInstantLimit,
}: IProps) => {
  const { t } = useTranslation();
  const { subscriptionType } = useUserDataContext();
  const [activeTab, setActiveTab] = useState<string>("1");
  const [isOpenBackdropLoading, setIsOpenBackdropLoading] = useState<boolean>(false);
  const [isProcessingPayment, setIsProcessingPayment] = useState<boolean>(false);
  const isFreeUser = subscriptionType === SubscriptionType["FREE"];
  const { data } = useAPICurrencyPairsTicker(`${baseCurrency}-${counterCurrency}`, exchangeEnum);

  const handleTabChange = (_event: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue);
  };

  return (
    <Modal
      open={isOpen}
      onCancel={handleClose}
      title={t("dcaBots.balances.buySellNowTitle", { baseCurrency })}
      cancelTitle={isProcessingPayment ? t("close") : t("cancel")}
      size="md"
      disableCancelButton
      disableCloseOnClickOutside
      withCloseButton>
      <TabContext value={activeTab}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleTabChange}>
            <Tab label={t("dcaBots.balances.instaBuy")} value="1" />
            <Tab
              icon={isFreeUser ? <Credit /> : undefined}
              iconPosition="end"
              label={t("dcaBots.balances.limitBuy")}
              value="2"
            />
          </TabList>
        </Box>
        <TabPanel value="1" sx={{ px: { xs: 0, sm: 1, md: 3 }, pt: { xs: 1, sm: 2, md: 3 }, pb: 0 }}>
          <InstantBuy
            id={id}
            handleClose={handleClose}
            handleInstantTrade={handleInstantTrade}
            handleBackdropLoading={(value: boolean) => setIsOpenBackdropLoading(value)}
            handleProcessingPayment={(value: boolean) => setIsProcessingPayment(value)}
            isProcessingPayment={isProcessingPayment}
            baseCurrency={baseCurrency}
            counterCurrency={counterCurrency}
            exchangeEnum={exchangeEnum}
            balanceBase={balanceBase}
            balanceCounter={balanceCounter}
            askPrice={data?.askPrice ?? 0}
            bidPrice={data?.bidPrice ?? 0}
          />
        </TabPanel>
        <TabPanel value="2" sx={{ px: { xs: 0, sm: 1, md: 3 }, pt: { xs: 1, sm: 2, md: 3 }, pb: 0 }}>
          {data?.askPrice && data?.bidPrice && (
            <LimitBuy
              id={id}
              handleClose={handleClose}
              handleInstantTrade={handleInstantTrade}
              handleBackdropLoading={(value: boolean) => setIsOpenBackdropLoading(value)}
              isProcessingPayment={isProcessingPayment}
              baseCurrency={baseCurrency}
              counterCurrency={counterCurrency}
              exchangeEnum={exchangeEnum}
              isFreeUser={isFreeUser}
              balanceBase={balanceBase}
              balanceCounter={balanceCounter}
              askPrice={data?.askPrice}
              bidPrice={data?.bidPrice}
              sumsOfOpenInstantLimit={sumsOfOpenInstantLimit}
            />
          )}
        </TabPanel>
      </TabContext>
      <BackdropLoading open={isOpenBackdropLoading} />
    </Modal>
  );
};

export default TradeModal;
