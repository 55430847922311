import React from "react";

import { ChevronRight } from "@mui/icons-material";
import { Button, Paper, Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import DcaStrategy from "assets/images/dca-strategie.png";
import ExitStrategy from "assets/images/exit-strategie.png";
import GridStrategy from "assets/images/grid-strategie.png";
import List from "components/elements/List";
import HomeBox from "components/modules/HomeBox";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const ITEMS = [
  {
    img: DcaStrategy,
    imgWidth: "50%",
    imgMaxWidth: "119px",
    title: "homepage.strategies.dca.title",
    subtitle1: "homepage.strategies.dca.subtitle1",
    subtitle2: "homepage.strategies.dca.subtitle2",
    to: "#dca-strategie",
  },
  {
    img: GridStrategy,
    imgWidth: "60%",
    imgMaxWidth: "145px",
    title: "homepage.strategies.grid.title",
    subtitle1: "homepage.strategies.grid.subtitle1",
    subtitle2: "homepage.strategies.grid.subtitle2",
    to: "#grid-strategie",
  },
  {
    img: ExitStrategy,
    imgWidth: "45%",
    imgMaxWidth: "115px",
    title: "homepage.strategies.exit.title",
    subtitle1: "homepage.strategies.exit.subtitle1",
    subtitle2: "homepage.strategies.exit.subtitle2",
    to: "#exit-strategie",
  },
];

const Strategies: React.FC = () => {
  const { t } = useTranslation();
  const { colors } = useMUIThemeModeContext();
  const navigate = useNavigate();

  return (
    <HomeBox size="small" textAlign={"start"}>
      <Box
        bgcolor={colors["deepMidnightBlue"]}
        sx={{ backgroundImage: "linear-gradient(0deg, rgba(17,24,39,1) 0%, rgba(138,149,175,0.2) 100%)" }}
        py={{ xs: 4, md: 9 }}
        px={{ xs: 1, sm: 4 }}
        borderRadius={"24px"}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}>
        <Box maxWidth={800} color={"white"} textAlign={"center"}>
          <Typography fontWeight={600} fontSize={{ xs: "1.25rem", sm: "2rem", lg: "2.5rem" }}>
            {t("homepage.strategies.title")}
          </Typography>
          <Typography pt={4} fontWeight={300} fontSize={{ xs: "0.8rem", md: "1rem", lg: "1.125rem" }}>
            <Trans i18nKey={"homepage.strategies.subtitle"} />
          </Typography>
        </Box>
        <Stack
          direction={{ xs: "column", lg: "row" }}
          pt={12}
          spacing={{ xs: 10, lg: 3 }}
          px={{ xs: 0, md: 8, lg: 10, xl: 12 }}>
          {ITEMS.map((item, index) => (
            <Paper
              key={index}
              sx={{
                color: "black",
                backgroundColor: "white",
                width: "100%",
                borderRadius: "24px",
                px: { xs: 2, sm: 4, lg: 2, xl: 4 },
                pt: 6,
                pb: 2,
              }}
              elevation={6}>
              <img
                width={item.imgWidth}
                style={{ marginTop: "-50%", maxWidth: item.imgMaxWidth }}
                src={item.img}
                alt={item.title}
              />
              <Typography fontSize={{ xs: "1.25rem", md: "1.5rem" }} fontWeight={600}>
                {t(item.title)}
              </Typography>
              <List data={[item.subtitle1, item.subtitle2]} />
              <Box pt={3} display={"flex"} justifyContent={"end"}>
                <Button variant="text" endIcon={<ChevronRight />} onClick={() => navigate(item.to)}>
                  {t("homepage.strategies.findOutMore")}
                </Button>
              </Box>
            </Paper>
          ))}
        </Stack>
      </Box>
    </HomeBox>
  );
};

export default Strategies;
