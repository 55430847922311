import React from "react";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import IProps from "./types";

const BackdropLoading = ({ open }: IProps) => {
  return (
    <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
      <CircularProgress color="primary" />
    </Backdrop>
  );
};

export default BackdropLoading;
