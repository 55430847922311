import validations from "constants/validations";

import { Stack } from "@mui/material";
import DialogContentText from "@mui/material/DialogContentText";
import { changeUserPassword } from "API/calls";
import Modal from "components/elements/Modal";
import PasswordField from "components/elements/PasswordField";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { IChangeUserPassword } from "types/types";
import * as yup from "yup";

interface Props {
  open: boolean;
  onClose: () => void;
}

const ModalChangePassword = ({ open, onClose }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const handleChangePassword = async (values: IChangeUserPassword) => {
    try {
      await changeUserPassword(values);
      enqueueSnackbar(t("settingsPage.changePasswordSuccess"), { variant: "success" });
    } catch (err: any) {
      enqueueSnackbar(err?.response?.data?.message ?? t("commonError"), { variant: "error" });
    }
  };

  const formik = useFormik({
    initialValues: {
      passwordCurrent: "",
      passwordNew: "",
      passwordNewCheck: "",
    },
    validationSchema: yup.object().shape({
      passwordCurrent: yup
        .string()
        .required(t("fieldRequired"))
        .matches(validations.password, t("fieldErrors.password")),
      passwordNew: yup.string().required(t("fieldRequired")).matches(validations.password, t("fieldErrors.password")),
      passwordNewCheck: yup
        .string()
        .required(t("fieldRequired"))
        .matches(validations.password, t("fieldErrors.password"))
        .oneOf([yup.ref("passwordNew"), null], t("fieldErrors.passwordCheck")),
    }),
    onSubmit: async (values, { resetForm }) => {
      await handleChangePassword({
        oldPassword: values.passwordCurrent,
        newPassword: values.passwordNew,
      });
      onClose();
      resetForm();
    },
    onReset: () => {
      onClose();
    },
  });

  return (
      <Modal
        open={open}
        onCancel={() => formik.handleReset(null)}
        title={t("settingsPage.changePasswordModalTitle")}
        onConfirm={formik.handleSubmit}
        withCloseButton
        confirmTitle={t("settingsPage.changePassword")}>
        <DialogContentText sx={{ mb: 2 }}>{t("settingsPage.changePasswordContent")}</DialogContentText>
        <Stack spacing={2}>
          <PasswordField
            fullWidth={true}
            id={"passwordCurrent"}
            name={"passwordCurrent"}
            label={t("settingsPage.passwordCurrent")}
            value={formik.values.passwordCurrent}
            error={Boolean(formik.errors.passwordCurrent)}
            helperText={formik.errors.passwordCurrent}
            onChange={formik.handleChange}
            autoComplete={"off"}
          />
          <PasswordField
            fullWidth={true}
            id={"passwordNew"}
            name={"passwordNew"}
            label={t("passwordNew")}
            value={formik.values.passwordNew}
            error={Boolean(formik.errors.passwordNew)}
            helperText={formik.errors.passwordNew}
            onChange={formik.handleChange}
            autoComplete={"off"}
          />
          <PasswordField
            fullWidth={true}
            id={"passwordNewCheck"}
            name={"passwordNewCheck"}
            label={t("passwordNewCheck")}
            value={formik.values.passwordNewCheck}
            error={Boolean(formik.errors.passwordNewCheck)}
            helperText={formik.errors.passwordNewCheck}
            onChange={formik.handleChange}
            autoComplete={"off"}
          />
        </Stack>
      </Modal>
  );
};

export default ModalChangePassword;
