import i18n from "i18n/i18n";
import { useConfirm as useConfirmMui, ConfirmOptions } from "material-ui-confirm";
import { useTranslation } from "react-i18next";

function useConfirm() {
  const { t } = useTranslation();
  const confirmMui = useConfirmMui();

  const confirm = (props?: ConfirmOptions) => {
    return confirmMui({
      cancellationButtonProps: { color: "error" },
      confirmationText: t("confirm"),
      ...props,
    });
  };

  const confirmUpsell = (props?: ConfirmOptions) => {
    return confirmMui({
      ...props,
      title: i18n.t("subscriptionUpsellModal.title"),
      description: i18n.t("subscriptionUpsellModal.description"),
      confirmationText: i18n.t("subscriptionUpsellModal.cta"),
    });
  };

  return {
    confirm,
    confirmUpsell,
  };
}

export default useConfirm;
