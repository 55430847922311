import { subscriptionSingleLimitOrders } from "constants/subscriptions";

import React from "react";

import { Grid, Stack, Typography, useTheme } from "@mui/material";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import { independentLimitBuyOrder, independentLimitSellOrder } from "API/calls";
import Big from "big.js";
import MaskedModeWrapper, { getMaskedValue } from "components/elements/MaskedModeWrapper";
import PriceField from "components/elements/PriceField";
import Slider from "components/elements/Slider";
import TooltipFreeUserUpsell from "components/elements/TooltipFreeUserUpsell";
import { Credit } from "components/icons";
import SatsView from "components/modules/SatsView";
import { useUserDataContext } from "context/UserDataContext";
import { useFormik } from "formik";
import useExchangeRate from "hooks/useExchangeRate";
import useExchangesMetadata from "hooks/useExchangesMetadata";
import { useSnackbar } from "notistack";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { OrderType, Side, SubscriptionType } from "types/enums";
import {
  ConditionalWrapper,
  decreaseByPercentage,
  getColorByMode,
  increaseByPercentage,
  percentageChange,
} from "utils";
import { getBigWithComma, getNumberWithComma, renderNumber } from "utils/formatter";
import * as yup from "yup";

import LogScale from "./LogScale";
import IProps from "./types";

const LimitBuy = ({
  id,
  handleClose,
  handleInstantTrade,
  handleBackdropLoading,
  isProcessingPayment,
  baseCurrency,
  counterCurrency,
  exchangeEnum,
  balanceBase,
  balanceCounter,
  askPrice,
  bidPrice,
  isFreeUser,
  sumsOfOpenInstantLimit,
}: IProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { subscriptionType, isMasked } = useUserDataContext();
  const { getUSDPrice } = useExchangeRate();
  const currencyPair = `${baseCurrency}/${counterCurrency}`;
  const { enqueueSnackbar } = useSnackbar();
  const {
    counterCurrScale,
    baseCurrScale,
    currencyPairPriceScale,
    counterCurrDisplayedScale,
    currencyPairBaseScale,
    getLimit,
  } = useExchangesMetadata(exchangeEnum ?? "", currencyPair);

  const validationValue = (side: Side) => (value?: string, context?: yup.TestContext<any>) => {
    if (value && context && exchangeEnum) {
      const val = Number(value.replace(",", "."));

      const minBaseAmount = getLimit(side, "min", "base", currencyPair, exchangeEnum, OrderType.LIMIT) || 0;
      const maxBaseAmount = getLimit(side, "max", "base", currencyPair, exchangeEnum, OrderType.LIMIT);
      const minCounterAmount = getLimit(side, "min", "counter", currencyPair, exchangeEnum, OrderType.LIMIT) || 0;
      const maxCounterAmount = getLimit(side, "max", "counter", currencyPair, exchangeEnum, OrderType.LIMIT);

      if (side === Side.SELL) {
        let minCounterAmountInBase = 0;
        if (minCounterAmount && context?.parent?.sellPrice) {
          const sellPrice = Number(context?.parent?.sellPrice.replace(",", "."));
          minCounterAmountInBase = new Big(minCounterAmount / sellPrice)
            .round(currencyPairBaseScale, Big.roundDown)
            .toNumber();
        }
        const minSellValue = minCounterAmountInBase >= minBaseAmount ? minCounterAmountInBase : minBaseAmount;

        if (minSellValue && minSellValue >= 0) {
          if (!val || val < minSellValue) {
            return context.createError({
              message: t("minOrderValues", { value: getNumberWithComma(minSellValue), currency: baseCurrency }),
            });
          }
        }
        if (minBaseAmount && minBaseAmount >= 0) {
          if (!val || val < minBaseAmount) {
            return context.createError({
              message: t("minOrderValues", { value: getNumberWithComma(minBaseAmount), currency: baseCurrency }),
            });
          }
        }
        if (maxBaseAmount && val && val > maxBaseAmount) {
          return context.createError({
            message: t("maxOrderValues", { value: getNumberWithComma(maxBaseAmount), currency: baseCurrency }),
          });
        }
        if (val > balanceBase.available) {
          return context.createError({
            message: t("insufficientFunds"),
          });
        }
        if (val === 0) {
          return context.createError({
            message: t("minOrderValuesFallback", { value: 0, currency: baseCurrency }),
          });
        }
      }

      if (side === Side.BUY) {
        // min
        let minBaseAmountInCounter = 0;
        if (minBaseAmount && context?.parent?.buyPrice) {
          const buyPrice =  Number(context?.parent?.buyPrice.replace(",", "."));
          minBaseAmountInCounter = new Big(minBaseAmount * buyPrice)
            .round(counterCurrDisplayedScale, Big.roundUp)
            .toNumber();
        }
        const minBuyValue = minBaseAmountInCounter >= minCounterAmount ? minBaseAmountInCounter : minCounterAmount;

        if (minBuyValue && minBuyValue >= 0) {
          if (!val || val < minBuyValue) {
            return context.createError({
              message: t("minOrderValues", { value: getNumberWithComma(minBuyValue), currency: counterCurrency }),
            });
          }
        }
        if (maxCounterAmount && val && val > maxCounterAmount) {
          return context.createError({
            message: t("maxOrderValues", { value: getNumberWithComma(maxCounterAmount), currency: counterCurrency }),
          });
        }
        if (val > balanceCounter.available) {
          return context.createError({
            message: t("insufficientFunds"),
          });
        }
        if (val === 0) {
          return context.createError({
            message: t("minOrderValuesFallback", { value: 0, currency: counterCurrency }),
          });
        }
      }

      if (!val) {
        return context.createError({
          message: t("fieldRequired"),
        });
      }
      if (val.toString().length > 19) {
        return context.createError({
          message: t("fieldMaxLength", { max: 19 }),
        });
      }
    }

    return true;
  };

  const validationLimitPrice = (side: Side) => (value?: string, context?: yup.TestContext<any>) => {
    if (value && context && exchangeEnum) {
      const val = Number(value.replace(",", "."));

      const minCounterAmount = getLimit(side, "min", "counter", currencyPair, exchangeEnum, OrderType.LIMIT) || 0;

      if (minCounterAmount && minCounterAmount >= 0) {
        if (!val || val < minCounterAmount) {
          return context.createError({
            message: t("minOrderValues", { value: getNumberWithComma(minCounterAmount), currency: counterCurrency }),
          });
        }
      }
      if (val === 0) {
        return context.createError({
          message: t("minLimitPriceFallback"),
        });
      }
      if (!val) {
        return context.createError({
          message: t("fieldRequired"),
        });
      }

      if (side === Side.BUY) {
        if (val > askPrice) {
          return context.createError({
            message: t("maxLimitValueFallback", { value: getNumberWithComma(askPrice), currency: counterCurrency }),
          });
        }
      }

      if (side === Side.SELL) {
        if (val < bidPrice) {
          return context.createError({
            message: t("minLimitValueFallback", { value: getNumberWithComma(bidPrice), currency: counterCurrency }),
          });
        }
      }

      if (val.toString().length > 19) {
        return context.createError({
          message: t("fieldMaxLength", { max: 19 }),
        });
      }
    }

    return true;
  };

  const requiredValidation = () => {
    return t("fieldRequired");
  };

  const handleLimitBuyOrder = async (value: number, price: number) => {
    handleBackdropLoading(true);

    try {
      await independentLimitBuyOrder(id, value, price);
      enqueueSnackbar(t("dcaBots.balances.successLimitBuyMessage"), { variant: "success" });
      handleInstantTrade();
    } catch (err) {
      enqueueSnackbar(t("dcaBots.balances.errorLimitBuyMessage"), { variant: "error" });
    } finally {
      handleBackdropLoading(false);
      handleClose();
    }
  };

  const handleLimitSellOrder = async (amount: number, price: number) => {
    handleBackdropLoading(true);

    try {
      await independentLimitSellOrder(id, amount, price);
      enqueueSnackbar(t("dcaBots.balances.successLimitSellMessage"), { variant: "success" });
      handleInstantTrade();
    } catch (err) {
      enqueueSnackbar(t("dcaBots.balances.errorLimitSellMessage"), { variant: "error" });
    } finally {
      handleBackdropLoading(false);
      handleClose();
    }
  };

  const formikBuyInitial = {
    buyValue: 0,
    buyPrice: getBigWithComma(new Big(decreaseByPercentage(bidPrice, 5)).round(currencyPairPriceScale, Big.roundDown)),
  };
  const formikBuy = useFormik({
    initialValues: formikBuyInitial,
    validationSchema: yup.object().shape({
      buyValue: yup
        .string()
        .required(requiredValidation())
        .test("minBuyValue", "minBuyValueError", validationValue(Side.BUY)),
      buyPrice: yup
        .string()
        .required(requiredValidation())
        .test("limitBuyPrice", "limitBuyError", validationLimitPrice(Side.BUY)),
    }),
    onSubmit: (values) => {
      const { buyValue, buyPrice } = values;
      buyValue
        && buyPrice
        && handleLimitBuyOrder(Number(`${buyValue}`.replace(",", ".")), Number(`${buyPrice}`.replace(",", ".")));
    },
  });

  const formikSellInitial = {
    sellValue: 0,
    sellPrice: getBigWithComma(new Big(increaseByPercentage(askPrice, 5)).round(currencyPairPriceScale, Big.roundDown)),
  };
  const formikSell = useFormik({
    initialValues: formikSellInitial,
    validationSchema: yup.object().shape({
      sellValue: yup
        .string()
        .required(requiredValidation())
        .test("minSellValue", "minSellValueError", validationValue(Side.SELL)),
      sellPrice: yup
        .string()
        .required(requiredValidation())
        .test("limitSellPrice", "limitSellError", validationLimitPrice(Side.SELL)),
    }),
    onSubmit: (values) => {
      const { sellValue, sellPrice } = values;

      sellValue
        && sellPrice
        && handleLimitSellOrder(Number(`${sellValue}`.replace(",", ".")), Number(`${sellPrice}`.replace(",", ".")));
    },
  });

  const onBuySliderChange = (_: React.SyntheticEvent | Event, newValue: number | number[]) => {
    if (typeof newValue === "number") {
      formikBuy.setFieldValue("buyValue", getBigWithComma(new Big(newValue).round(counterCurrScale, Big.roundDown)));
    }
  };

  const onBuyLimitSliderChange = (value: number) => {
    if (typeof value === "number") {
      const price = decreaseByPercentage(askPrice, value);
      formikBuy.setFieldValue("buyPrice", getBigWithComma(new Big(price).round(currencyPairPriceScale, Big.roundDown)));
    }
  };

  const onSellSliderChange = (_: React.SyntheticEvent | Event, newValue: number | number[]) => {
    if (typeof newValue === "number") {
      formikSell.setFieldValue("sellValue", getBigWithComma(new Big(newValue).round(currencyPairBaseScale, Big.roundDown)));
    }
  };

  const onSellLimitSliderChange = (value: number) => {
    if (typeof value === "number") {
      const price = increaseByPercentage(bidPrice, value);
      formikSell.setFieldValue("sellPrice", getBigWithComma(new Big(price).round(currencyPairPriceScale, Big.roundDown)));
    }
  };

  const getSliderStepSize = (value: number) => {
    const valueFraction = value / 100;
    if (valueFraction > 1) {
      return 1;
    }
    return valueFraction;
  };

  const getSliderInputValue = (value: number | string): number => {
    if (typeof value === "number") {
      return value;
    } else {
      return Number(value.replace(",", "."));
    }
  };

  const getApproximateLimitAmount = (value: string, limitPrice: string, side: Side) => {
    switch (side) {
      case Side.SELL:
        return Number(value.replace(",", ".")) * Number(limitPrice.replace(",", "."));
      case Side.BUY:
        if (value === "0" || limitPrice === "0") return 0;

        return Number(value.replace(",", ".")) / Number(limitPrice.replace(",", "."));
    }
  };

  const getSliderDefaultValue = (origin: number, current: number | string) => {
    return Math.abs(percentageChange(origin, Number(`${current}`.replace(",", "."))));
  };

  const hasMaxOpenInstantLimit = () => {
    switch (subscriptionType) {
      case SubscriptionType["PLUS"]:
        if (sumsOfOpenInstantLimit >= subscriptionSingleLimitOrders["PLUS"]) {
          return true;
        }
        return false;
      case SubscriptionType["PREMIUM"]:
        if (sumsOfOpenInstantLimit >= subscriptionSingleLimitOrders["PREMIUM"]) {
          return true;
        }
        return false;
      default:
        return false;
    }
  };

  const sumsOfOpenInstantLimitWarning = () => {
    switch (subscriptionType) {
      case SubscriptionType["PLUS"]:
        if (hasMaxOpenInstantLimit()) {
          return (
            <Box pt={2}>
              <Alert severity="warning">
                <AlertTitle>{t("dcaBots.balances.maxOpenInstantLimitWarning.plusTitle")}</AlertTitle>
                <Trans
                  i18nKey="dcaBots.balances.maxOpenInstantLimitWarning.plusDesc"
                  components={{
                    a: <Link style={{ color: theme.palette.info.main }} to="/predplatne" />,
                  }}
                />
              </Alert>
            </Box>
          );
        }
        break;
      case SubscriptionType["PREMIUM"]:
        if (hasMaxOpenInstantLimit()) {
          return (
            <Box pt={2}>
              <Alert severity="warning">
                <Typography variant="body1" fontWeight={500}>
                  {t("dcaBots.balances.maxOpenInstantLimitWarning.premiumTitle")}
                </Typography>
              </Alert>
            </Box>
          );
        }
        break;
    }
  };

  const hasAnyFrozenBalance = Boolean(balanceCounter.frozen || balanceBase.frozen);

  const getHelperText = (value: string, currency: string, error?: string) => {
    if (error) return error;

    const price = getUSDPrice(currency, value, 0);
    return price ? `~ ${price}` : undefined;
  };

  const getAskBidPrice = (price: number, currency: string) => {
    const usdPrice = getUSDPrice(currency, price, 0);

    if (usdPrice) {
      return (
        <Typography fontWeight={600} fontSize={"inherit"}>{`(~\u00a0${usdPrice})`}</Typography>
      );
    }
  };

  return (
    <>
      <Alert severity="info" sx={{ "@media (max-width:400px)": { fontSize: ".8rem" } }}>
        <AlertTitle sx={{ "@media (max-width:400px)": { fontSize: ".9rem" } }}>
          {t("dcaBots.balances.limitBuyInfoTitle")}
        </AlertTitle>
        {t("dcaBots.balances.limitBuyInfoDesc")}
      </Alert>
      {sumsOfOpenInstantLimitWarning()}
      <Grid pt={2} container spacing={2}>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              background: getColorByMode(theme, theme.palette.grey[100], theme.palette.grey[900]),
              p: 2,
              borderRadius: "10px",
            }}>
            <Box pb={2}>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={{ xs: 0, sm: 0.5 }}>
                <Typography>{`${t("dcaBots.balances.available")}:`}</Typography>
                <MaskedModeWrapper color={theme.palette.text.primary} fontWeight={600}>
                  <SatsView
                    color={theme.palette.text.primary}
                    fontWeight={600}
                    value={new Big(balanceCounter.available).round(counterCurrScale, Big.roundDown).toNumber()}
                    currency={counterCurrency}
                    scale={counterCurrScale}
                    withZeros
                  />
                </MaskedModeWrapper>
              </Stack>
              {hasAnyFrozenBalance && (
                <Box sx={{ fontSize: "0.8em", color: theme.palette.grey[600], minHeight: "20px" }}>
                  {Boolean(balanceCounter.frozen) && (
                    <>
                      {`${t("dcaBots.balances.frozen")}: ${getMaskedValue(
                        isMasked,
                        renderNumber(
                          new Big(balanceCounter.frozen).round(counterCurrScale, Big.roundDown).toNumber(),
                          counterCurrScale,
                          true
                        )
                      )}\u00a0${counterCurrency}`}
                    </>
                  )}
                </Box>
              )}
            </Box>
            <PriceField
              autoComplete="off"
              allowZero
              precision={counterCurrScale}
              fullWidth={true}
              id="buyValue"
              name="buyValue"
              label={t("dcaBots.balances.amount")}
              value={formikBuy.values.buyValue ?? 0}
              onChange={formikBuy.handleChange}
              onBlur={formikBuy.handleBlur}
              error={Boolean(formikBuy.errors.buyValue)}
              helperText={formikBuy.errors.buyValue}
              disabled={isProcessingPayment}
              InputProps={{
                endAdornment: <InputAdornment position="start">{counterCurrency}</InputAdornment>,
              }}
            />
            <Box sx={{ pt: 1, pb: 2, px: 2 }}>
              <Slider
                key="sliderBuy"
                value={getSliderInputValue(formikBuy.values.buyValue)}
                min={0}
                step={getSliderStepSize(balanceCounter.available)}
                max={balanceCounter.available}
                onChange={onBuySliderChange}
                valueLabelDisplay="off"
                sx={{
                  color: "success.main",
                }}
              />
            </Box>
            <Stack
              sx={{ pb: 2, fontSize: "0.8em", color: theme.palette.grey[600], minHeight: "20px" }}
              direction={"row"}
              flexWrap={"wrap"}
              columnGap={0.5}>
              <Typography fontSize={"inherit"}>{`${t("dcaBots.askPrice")}:`}</Typography>
              <Stack direction={"row"} spacing={0.5}>
                <Typography fontWeight={600} fontSize={"inherit"}>
                  {`${renderNumber(askPrice, currencyPairPriceScale, true)}\u00a0${counterCurrency}`}
                </Typography>
                {getAskBidPrice(askPrice, counterCurrency)}
              </Stack>
            </Stack>
            <PriceField
              autoComplete="off"
              allowZero
              precision={currencyPairPriceScale}
              fullWidth={true}
              id="buyPrice"
              name="buyPrice"
              label={t("dcaBots.balances.limitPrice")}
              value={formikBuy.values.buyPrice ?? 0}
              onChange={formikBuy.handleChange}
              onBlur={formikBuy.handleBlur}
              error={Boolean(formikBuy.errors.buyPrice)}
              helperText={getHelperText(formikBuy.values.buyPrice, counterCurrency, formikBuy.errors.buyPrice)}
              disabled={isProcessingPayment}
              InputProps={{
                endAdornment: <InputAdornment position="start">{counterCurrency}</InputAdornment>,
              }}
            />
            <Box sx={{ pt: 1, pb: 2, px: 2 }}>
              <LogScale
                id={"sliderBuyLimitPrice"}
                value={formikBuy.values.buyPrice}
                defaultValue={getSliderDefaultValue(bidPrice, formikBuy.values.buyPrice)}
                min={1}
                step={1}
                max={90}
                valueLabelFormat={(n: number) => `-${n}%`}
                onChange={onBuyLimitSliderChange}
                valueLabelDisplay="auto"
                sx={{
                  color: "success.main",
                }}
              />
            </Box>
            <Stack direction={{ xs: "column", sm: "row" }} spacing={{ xs: 0, sm: 0.5 }}>
              <Typography>{t("dcaBots.balances.youWillGetApproximately")}</Typography>
              <SatsView
                color={theme.palette.text.primary}
                fontWeight={600}
                value={getApproximateLimitAmount(
                  `${formikBuy.values.buyValue}`,
                  `${formikBuy.values.buyPrice}`,
                  Side.BUY
                )}
                currency={baseCurrency}
                scale={baseCurrScale}
                withZeros
              />
            </Stack>
            {!isProcessingPayment && (
              <Box sx={{ display: "flex", justifyContent: "flex-end " }}>
                <ConditionalWrapper
                  condition={isFreeUser}
                  wrapper={(children) => <TooltipFreeUserUpsell>{children}</TooltipFreeUserUpsell>}>
                  <Button
                    disabled={hasMaxOpenInstantLimit()}
                    variant="contained"
                    
                    color="success"
                    endIcon={isFreeUser ? <Credit /> : undefined}
                    onClick={() => (!isFreeUser ? formikBuy.handleSubmit() : undefined)}
                    sx={{ mt: 2 }}>
                    {t("dcaBots.balances.confirmBuy")}
                  </Button>
                </ConditionalWrapper>
              </Box>
            )}
          </Box>
        </Grid>
        {/* SELL */}
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              background: getColorByMode(theme, theme.palette.grey[100], theme.palette.grey[900]),
              p: 2,
              borderRadius: "10px",
            }}>
            <Box pb={2}>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={{ xs: 0, sm: 0.5 }}>
                <Typography>{`${t("dcaBots.balances.available")}:`}</Typography>
                <MaskedModeWrapper color={theme.palette.text.primary} fontWeight={600}>
                  <SatsView
                    color={theme.palette.text.primary}
                    fontWeight={600}
                    value={new Big(balanceBase.available).round(baseCurrScale, Big.roundDown).toNumber()}
                    currency={baseCurrency}
                    scale={baseCurrScale}
                    withZeros
                  />
                </MaskedModeWrapper>
              </Stack>
              {hasAnyFrozenBalance && (
                <Box sx={{ fontSize: "0.8em", color: theme.palette.grey[600], minHeight: "20px" }}>
                  {Boolean(balanceBase.frozen) && (
                    <>
                      {`${t("dcaBots.balances.frozen")}: ${getMaskedValue(
                        isMasked,
                        renderNumber(
                          new Big(balanceBase.frozen).round(baseCurrScale, Big.roundDown).toNumber(),
                          baseCurrScale,
                          true
                        )
                      )}\u00a0${baseCurrency}`}
                    </>
                  )}
                </Box>
              )}
            </Box>
            <PriceField
              autoComplete="off"
              allowZero
              precision={currencyPairBaseScale}
              fullWidth={true}
              id="sellValue"
              name="sellValue"
              label={t("dcaBots.balances.quantity")}
              value={formikSell.values.sellValue ?? 0}
              onChange={formikSell.handleChange}
              onBlur={formikSell.handleBlur}
              error={Boolean(formikSell.errors.sellValue)}
              helperText={formikSell.errors.sellValue}
              disabled={isProcessingPayment}
              InputProps={{
                endAdornment: <InputAdornment position="start">{baseCurrency}</InputAdornment>,
              }}
            />
            <Box sx={{ pt: 1, pb: 2, px: 2 }}>
              <Slider
                key="sliderSell"
                value={getSliderInputValue(formikSell.values.sellValue)}
                min={0}
                step={getSliderStepSize(balanceBase.available)}
                max={balanceBase.available}
                onChange={onSellSliderChange}
                valueLabelDisplay="off"
                sx={{
                  color: "error.main",
                }}
              />
            </Box>
            <Stack
              sx={{ pb: 2, fontSize: "0.8em", color: theme.palette.grey[600], minHeight: "20px" }}
              direction={"row"}
              flexWrap={"wrap"}
              columnGap={0.5}>
              <Typography fontSize={"inherit"}>{`${t("dcaBots.bidPrice")}:`}</Typography>
              <Stack direction={"row"} spacing={0.5}>
                <Typography fontWeight={600} fontSize={"inherit"}>
                  {`${renderNumber(bidPrice, currencyPairPriceScale, true)}\u00a0${counterCurrency}`}
                </Typography>
                {getAskBidPrice(bidPrice, counterCurrency)}
              </Stack>
            </Stack>
            <PriceField
              autoComplete="off"
              allowZero
              precision={currencyPairPriceScale}
              fullWidth={true}
              id="sellPrice"
              name="sellPrice"
              label={t("dcaBots.balances.limitPrice")}
              value={formikSell.values.sellPrice ?? 0}
              onChange={formikSell.handleChange}
              onBlur={formikSell.handleBlur}
              error={Boolean(formikSell.errors.sellPrice)}
              helperText={getHelperText(formikSell.values.sellPrice, counterCurrency, formikSell.errors.sellPrice)}
              disabled={isProcessingPayment}
              InputProps={{
                endAdornment: <InputAdornment position="start">{counterCurrency}</InputAdornment>,
              }}
            />
            <Box sx={{ pt: 1, pb: 2, px: 2 }}>
              <LogScale
                id={"sliderSellLimitPrice"}
                value={formikSell.values.sellPrice}
                defaultValue={getSliderDefaultValue(askPrice, formikSell.values.sellPrice)}
                min={1}
                step={1}
                max={1000}
                valueLabelFormat={(n: number) => `+${n}%`}
                onChange={onSellLimitSliderChange}
                valueLabelDisplay="auto"
                sx={{
                  color: "error.main",
                }}
              />
            </Box>
            <Stack direction={{ xs: "column", sm: "row" }} spacing={{ xs: 0, sm: 0.5 }}>
              <Typography>{t("dcaBots.balances.youWillGetApproximately")}</Typography>
              <SatsView
                color={theme.palette.text.primary}
                fontWeight={600}
                value={getApproximateLimitAmount(
                  `${formikSell.values.sellValue}`,
                  `${formikSell.values.sellPrice}`,
                  Side.SELL
                )}
                currency={counterCurrency}
                scale={currencyPairPriceScale}
                withZeros
              />
            </Stack>
            {!isProcessingPayment && (
              <Box sx={{ display: "flex", justifyContent: "flex-end " }}>
                <ConditionalWrapper
                  condition={isFreeUser}
                  wrapper={(children) => <TooltipFreeUserUpsell>{children}</TooltipFreeUserUpsell>}>
                  <Button
                    disabled={hasMaxOpenInstantLimit()}
                    variant="contained"
                    color="error"
                    endIcon={isFreeUser ? <Credit /> : undefined}
                    onClick={() => (!isFreeUser ? formikSell.handleSubmit() : undefined)}
                    sx={{ mt: 2 }}>
                    {t("dcaBots.balances.confirmSell")}
                  </Button>
                </ConditionalWrapper>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default LimitBuy;
