import { useState } from "react";

import { Box, ToggleButton as MUIToggleButton, ToggleButtonGroup, useTheme } from "@mui/material";
import { ApexOptions } from "apexcharts";
import { getMaskedValue } from "components/elements/MaskedModeWrapper";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { useUserDataContext } from "context/UserDataContext";
import dayjs from "dayjs";
import isToday from "dayjs/plugin/isToday";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import styled, { createGlobalStyle } from "styled-components";
import { IInvestmentChart } from "types/types";
import { getColorByMode } from "utils";
import { renderPrice } from "utils/formatter";

dayjs.extend(isToday);

const GlobalStyle = createGlobalStyle`
 .chart .apexcharts-legend-series[rel="1"] {
    display: none !important;
  }
`;

const ToggleButton = styled(MUIToggleButton)`
  font-size: 0.8rem;
  padding: 4px 10px;
`;

interface Props {
  chartData: IInvestmentChart;
  isMobile: boolean;
  baseCurrency: string;
  counterCurrency: string;
  baseCurrDisplayedScale: number;
  counterCurrDisplayedScale: number;
  targetValuePrice?: number;
}

const Chart = ({
  chartData,
  isMobile,
  baseCurrency,
  counterCurrency,
  baseCurrDisplayedScale,
  counterCurrDisplayedScale,
  targetValuePrice,
}: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { isMasked } = useUserDataContext();
  const { colors } = useMUIThemeModeContext();
  const [logarithmic, setLogarithmic] = useState(false);

  const labels = chartData.points.map((item) =>
    Intl.DateTimeFormat("cs-CZ").format(new Date(dayjs(item.date).format()))
  );
  const min = chartData.minValue;

  const series = [
    {
      name: t("amountAccumulated"),
      data: chartData.points.map((item) => item.amount),
    },
    {
      name: t("invested"),
      data: chartData.points.map((item) => item.investedValue),
    },
    {
      name: t("value"),
      data: chartData.points.map((item) => item.value),
    },
  ];
  const strokeCurve: ApexStroke["curve"] = ["stepline", "stepline", "smooth"];
  const strokeWidth = [0, 4, 4];
  const chartColors = ["transparent", getColorByMode(theme, theme.palette.grey[300], theme.palette.grey[700]), theme.palette.primary.main];

  if (targetValuePrice) {
    series.push({
      name: t("targetValue"),
      data: chartData.points.map((item) => item.targetValue),
    });
    strokeCurve.push("smooth");
    strokeWidth.push(4);
    chartColors.push(theme.palette.info.main);
  }

  // chart config
  const options: ApexOptions = {
    theme: { mode: theme.palette.mode },
    tooltip: {
      followCursor: true,
      inverseOrder: true,
    },
    colors: chartColors,
    chart: {
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      background: "transparent",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: strokeCurve,
      width: strokeWidth,
    },
    grid: {
      row: {
        colors: [colors.legacyGray100, "transparent"],
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: labels,
      labels: {
        hideOverlappingLabels: true,
        rotate: -30,
        show: !isMobile,
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      show: !isMobile,
      labels: {
        formatter: (val: any, opts: any) => {
          // not possible to have a separate formatter for each data series, therefore this hack
          if (opts?.seriesIndex === 0) {
            return getMaskedValue(isMasked, renderPrice(val, baseCurrency, baseCurrDisplayedScale));
          } else {
            return getMaskedValue(isMasked, renderPrice(val, counterCurrency, counterCurrDisplayedScale));
          }
        },
      },
      logarithmic,
      min,
      forceNiceScale: logarithmic, // ignore min setting on logarithmic scale due to apexcharts issue
    },
  };

  return (
    <Box sx={{ pt: { xs: 2 }, pr: { xs: 0, md: 2 }, textAlign: "center" }}>
      <GlobalStyle />
      <ToggleButtonGroup color="primary" value={logarithmic} onChange={() => setLogarithmic(!logarithmic)} exclusive>
        <ToggleButton value={false}>{t("scale.linear")}</ToggleButton>
        <ToggleButton value={true}>{t("scale.log")}</ToggleButton>
      </ToggleButtonGroup>
      <ReactApexChart className="chart" options={options} series={series} type="line" width={"100%"} height={400} />
    </Box>
  );
};

export default Chart;
