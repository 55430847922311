import { Stack, StackProps, Typography } from "@mui/material";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import useExchangeRate from "hooks/useExchangeRate";
import { renderNumber } from "utils/formatter";

interface IProps extends StackProps {
  title?: string;
  currency: string;
  value: number;
  scale: number;
}

const LimitView = ({ title, currency, value, scale, ...props }: IProps) => {
  const { getUSDPrice } = useExchangeRate();
  const { colors } = useMUIThemeModeContext();

  return (
    <Stack {...props}>
      {title && (
        <Typography variant="caption" fontWeight={500}>
          {title}
        </Typography>
      )}
      <Typography variant={title ? "body2" : "caption"} fontWeight={600}>{`${renderNumber(
        value,
        scale,
        true
      )}\u00a0${currency}`}</Typography>
      <Typography variant="caption" fontWeight={500} color={colors.neutral500}>{`~ ${getUSDPrice(currency, value, scale)}`}</Typography>
    </Stack>
  );
};

export default LimitView;
