import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Stack, Typography, Box } from "@mui/material";
import Tile from "components/elements/Tile";
import Tooltip from "components/elements/Tooltip";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { FormikErrors } from "formik";
import { useTranslation } from "react-i18next";

import LimitInput from "./LimitInput";
import LimitView from "./LimitView";
import { DashedBox, Dot } from "./styles";

interface IProps {
  isLoading: boolean;
  maxPercentageUp: number;
  maxPercentageDown: number;
  closePrice?: number;
  lowerPrice: number;
  upperPrice: number;
  currency: string;
  scale: number;
  priceRangeTooltip: string;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<FormikErrors<any>> | Promise<void>;
  setFieldTouched: (
    field: string,
    touched?: boolean,
    shouldValidate?: boolean | undefined
  ) => Promise<FormikErrors<any>> | Promise<void>;
}

const PriceRangeInput = ({
  isLoading,
  maxPercentageDown,
  maxPercentageUp,
  closePrice,
  upperPrice,
  lowerPrice,
  currency,
  scale,
  priceRangeTooltip,
  setFieldValue,
  setFieldTouched,
}: IProps) => {
  const { t } = useTranslation<string>();
  const { colors } = useMUIThemeModeContext();

  return (
    <Tile
      sx={{ height: "100%" }}
      header={t("gridBot.create.limitSetting.header")}
      actions={
        <Tooltip arrow placement="top" title={t(priceRangeTooltip) ?? ""}>
          <HelpOutlineIcon sx={{ color: colors.slate500 }} />
        </Tooltip>
      }>
      <Stack pt={2} px={3} width={"100%"} justifyContent={"space-between"} direction={"row"}>
        <LimitView
          title={t("gridBot.create.limitSetting.gridLowerPrice")}
          currency={currency}
          value={lowerPrice}
          scale={scale}
        />
        <LimitView
          alignItems={"flex-end"}
          title={t("gridBot.create.limitSetting.gridUpperPrice")}
          currency={currency}
          value={upperPrice}
          scale={scale}
        />
      </Stack>
      <Box pt={1} justifyContent={"center"} display={"flex"} textAlign={"center"}>
        <LimitView currency={currency} value={closePrice ?? 0} scale={scale} />
      </Box>
      <Stack width={"100%"} px={3} direction={"row"} alignItems="flex-end">
        <LimitInput
          id="gridLowerPrice"
          value={lowerPrice}
          increase={0}
          closePrice={closePrice}
          precision={scale}
          sliderMax={maxPercentageDown}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          disabled={isLoading}
        />
        <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
          <DashedBox sx={{ height: "49px" }} />
          <Dot />
          <DashedBox sx={{ height: "29px" }} />
        </Box>
        <LimitInput
          id="gridUpperPrice"
          value={upperPrice}
          increase={1}
          closePrice={closePrice}
          precision={scale}
          sliderMax={maxPercentageUp}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          disabled={isLoading}
        />
      </Stack>
      <Box pb={3} pt={0.5} justifyContent={"center"} display={"flex"} textAlign={"center"}>
        <Typography variant="body2" fontWeight={500}>
          {t("gridBot.create.limitSetting.midPrice")}
        </Typography>
      </Box>
    </Tile>
  );
};

export default PriceRangeInput;
