import React from "react";

import Slider from "../Slider";
import IProps from "./types";

export const getSliderValueFromPosition = (pos: number, minP: number, maxP: number, minV: number, maxV: number) => {
  // input will be between min and max
  const minPosition = minP;
  const maxPosition = maxP;

  // result value should be between min and max
  const minValue = Math.log(minV);
  const maxValue = Math.log(maxV);

  // calculate adjustment factor
  const scale = (maxValue - minValue) / (maxPosition - minPosition);

  return Math.round(Math.exp(minValue + scale * (pos - minPosition)));
};

export const getSliderPositionFromValue = (val: number, minP: number, maxP: number, minV: number, maxV: number) => {
  // input will be between min and max
  const minPosition = minP;
  const maxPosition = maxP;

  // result value should be between min and max
  const minValue = Math.log(minV);
  const maxValue = Math.log(maxV);

  // calculate adjustment factor
  const scale = (maxValue - minValue) / (maxPosition - minPosition);

  return Math.round(minPosition + (Math.log(val) - minValue) / scale);
};

const LogScaleSlider = ({
  sx,
  min,
  max,
  step = 1,
  defaultValue,
  onChange,
  valueLabelDisplay,
  valueLabelFormat,
  disabled,
}: IProps) => {
  const handleSliderChange = (event: React.SyntheticEvent | Event, newValue: number | Array<number>) => {
    onChange(getSliderValueFromPosition(Number(newValue), min, max, min, max));
  };

  return (
    <Slider
      sx={sx}
      defaultValue={getSliderPositionFromValue(defaultValue, min, max, min, max)}
      min={min}
      step={step}
      max={max}
      valueLabelFormat={valueLabelFormat}
      scale={(x) => getSliderValueFromPosition(Number(x), min, max, min, max)}
      onChangeCommitted={handleSliderChange}
      valueLabelDisplay={valueLabelDisplay}
      disabled={disabled}
    />
  );
};

export default LogScaleSlider;
