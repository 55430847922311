import { useState, useEffect } from "react";

import { useAPIExchanges, useAPIExchangesMetadata } from "./useAPI";

function useAppInit() {
  const [isPossibleRender, setIsPossibleRender] = useState<boolean>(false);

  const { data, isValidating } = useAPIExchanges();
  const { data: dataMetadata, isValidating: isValidatingMetadata } = useAPIExchangesMetadata();

  useEffect(() => {
    if (data !== undefined && !isValidating && dataMetadata !== undefined && !isValidatingMetadata) {
      setIsPossibleRender(true);
    }
  }, [data, isValidating, dataMetadata, isValidatingMetadata]);

  return {
    isPossibleRender,
  };
}

export default useAppInit;
