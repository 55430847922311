import React, { useState, useEffect } from "react";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { IconButton, Stack, SxProps, Theme, Typography } from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";
import { useUserDataContext } from "context/UserDataContext";

interface IProps {
  color?: string;
  fontSize?: string;
  fontWeight?: number;
  variant?: Variant;
  children: React.ReactNode;
  withoutMask?: boolean;
  stackProps?: SxProps<Theme>;
  compact?: boolean;
}

const MASKED_DATA = "∗∗∗∗∗∗∗∗∗∗";
const MASKED_DATA_COMPACT = "∗∗∗∗∗∗";

export const getMaskedValue = (isMasked: boolean, value: string) => {
  return isMasked ? MASKED_DATA : value;
};

const MaskedModeWrapper = ({
  children,
  fontSize,
  fontWeight,
  color = "text.secondary",
  withoutMask,
  variant,
  stackProps,
  compact,
}: IProps) => {
  const { isMasked } = useUserDataContext();
  const [innerIsMasked, setInnerIsMasked] = useState<boolean>(isMasked);
  const [isHovered, setIsHovered] = useState<boolean>(false);

  useEffect(() => {
    setInnerIsMasked(isMasked);
  }, [isMasked]);

  useEffect(() => {
    setIsHovered(false);
  }, [innerIsMasked]);

  if (innerIsMasked && !withoutMask) {
    return (
      <div onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
        <Stack spacing={0.5} direction={"row"} alignItems={"center"} sx={{ ...stackProps }}>
          <Typography
            sx={{ cursor: "default" }}
            fontSize={fontSize}
            variant={fontSize ? undefined : variant ? variant : "caption"}
            fontWeight={fontWeight}
            color={color}>
            {compact ? MASKED_DATA_COMPACT : MASKED_DATA}
          </Typography>
          {isHovered && (
            <IconButton sx={{ padding: 0, fontSize }} size="small" onClick={() => setInnerIsMasked(false)}>
              <VisibilityIcon fontSize={fontSize ? "inherit" : "small"} />
            </IconButton>
          )}
        </Stack>
      </div>
    );
  }

  if (isMasked && !withoutMask) {
    return (
      <Stack spacing={0.5} direction={"row"} alignItems={"center"} sx={{ ...stackProps }}>
        {children}
        <IconButton sx={{ padding: 0, fontSize }} size="small" onClick={() => setInnerIsMasked(true)}>
          <VisibilityOffIcon fontSize={fontSize ? "inherit" : "small"} />
        </IconButton>
      </Stack>
    );
  }

  return <>{children}</>;
};

export default MaskedModeWrapper;
