import React from "react";

import { Box, Stack, Typography } from "@mui/material";
import { Dollar, QuestionMark } from "components/icons";
import { HeaderButton } from "components/layouts/Default/Header";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";

interface HomeStrategyTileProps {
  image: string;
  name: string;
  title: string;
  subtitle: string | JSX.Element;
  reverse?: boolean;
  info?: {
    button: string;
    link: string;
    title: string;
    description: string | JSX.Element;
  };
  backTest?: {
    button: string;
    link: string;
    title?: string;
    description?: string | JSX.Element;
    target?: React.HTMLAttributeAnchorTarget;
  };
}

const HomeStrategyTile: React.FC<HomeStrategyTileProps> = ({
  image,
  title,
  name,
  subtitle,
  info,
  backTest,
  reverse,
}) => {
  const { colors } = useMUIThemeModeContext();

  return (
    <Stack spacing={{ xs: 4, md: 16 }} direction={{ xs: "column-reverse", sm: reverse ? "row-reverse" : "row" }}>
      <Box display={"flex"} width={"100%"} alignItems={"center"}>
        <Box width={"100%"} borderRadius={"24px"} overflow={"hidden"} boxShadow={(theme) => theme.shadows[24]}>
          <img src={image} alt={title} style={{ width: "100%" }} />
        </Box>
      </Box>
      <Box width={"100%"} textAlign={"start"}>
        <Typography
          color={(theme) => theme.palette.tertiary.main}
          fontWeight={600}
          fontSize={{ xs: "0.9rem", sm: "1rem" }}>
          {name}
        </Typography>
        <Typography pt={1} color={colors["deepMidnightBlue"]} fontWeight={600} fontSize={{ xs: "1.5rem", sm: "2rem" }}>
          {title}
        </Typography>
        <Typography pt={2} color={colors["blueGray"]} fontWeight={400} fontSize={{ xs: "1", sm: "1.125rem" }}>
          {subtitle}
        </Typography>
        {info && (
          <Stack spacing={2} direction={"row"} pt={4}>
            <QuestionMark style={{ width: "32px", height: "32px" }} />
            <Stack spacing={1}>
              <Typography color={colors["deepMidnightBlue"]} fontWeight={600} fontSize={{ xs: "0.9rem", sm: "1rem" }}>
                {info.title}
              </Typography>
              <Typography color={colors["blueGray"]} fontWeight={400} fontSize={{ xs: "0.9rem", sm: "1rem" }}>
                {info.description}
              </Typography>
            </Stack>
          </Stack>
        )}
        {backTest?.title && backTest.description && (
          <Stack spacing={2} direction={"row"} pt={4}>
            <Dollar style={{ width: "32px", height: "32px", color: "black" }} />
            <Stack spacing={1}>
              <Typography color={colors["deepMidnightBlue"]} fontWeight={600} fontSize={{ xs: "0.9rem", sm: "1rem" }}>
                {backTest.title}
              </Typography>
              <Typography color={colors["blueGray"]} fontWeight={400} fontSize={{ xs: "0.9rem", sm: "1rem" }}>
                {backTest.description}
              </Typography>
            </Stack>
          </Stack>
        )}
        <Stack pt={4} spacing={2} direction={{ xs: "column", md: "row" }}>
          {info && (
            <HeaderButton variant={"contained"} to={info.link}>
              {info.button}
            </HeaderButton>
          )}
          {backTest && (
            <HeaderButton variant={"outlined"} to={backTest.link} target={backTest.target}>
              {backTest.button}
            </HeaderButton>
          )}
        </Stack>
      </Box>
    </Stack>
  );
};

export default HomeStrategyTile;
