import { useState } from "react";

import { Stack, Typography, Box } from "@mui/material";
import { FormikErrors } from "formik";
import { useTranslation } from "react-i18next";
import { iOS } from "utils";
import { renderNumber } from "utils/formatter";

import { CustomSlider, Dot } from "./styles";

interface IProps {
  maxPercentageUp: number;
  maxPercentageDown: number;
  minMultiplier: number;
  maxMultiplier: number;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<FormikErrors<any>> | Promise<void>;
}

const MultiplierInput = ({
  maxPercentageDown,
  maxPercentageUp,
  minMultiplier,
  maxMultiplier,
  setFieldValue,
}: IProps) => {
  const { t } = useTranslation<string>();
  const [minMultiplierDefault] = useState<number>(minMultiplier);
  const [maxMultiplierDefault] = useState<number>(maxMultiplier);

  const handleSliderChange = (id: string) => (_event: React.SyntheticEvent | Event, newValue: number | Array<number>) => {
    setFieldValue(id, newValue);
  }

  return (
    <>
      <Stack pt={3} px={{ xs: 0, sm: 3 }} width={"100%"} justifyContent={"space-between"} direction={"row"}>
        <Typography variant="subtitle2" fontWeight={500}>
          {t("dcaBots.create.dynamicStrategy.minMultiplier")}
        </Typography>
        <Typography variant="subtitle2" fontWeight={500} textAlign={"right"}>
          {t("dcaBots.create.dynamicStrategy.maxMultiplier")}
        </Typography>
      </Stack>
      <Stack width={"100%"} px={{ xs: 0, sm: 3 }} direction={"row"} alignItems="flex-end">
        <CustomSlider
          increase={0}
          onChangeCommitted={handleSliderChange("dynamicStrategySettings.minMultiplier")}
          defaultValue={minMultiplierDefault}
          min={maxPercentageDown}
          max={1}
          step={0.1}
          track={"inverted"}
          valueLabelDisplay={"on"}
          valueLabelFormat={(n: number) => `x${renderNumber(n, 1)}`}
          marks={[
            {
              value: maxPercentageDown,
              label: `x${renderNumber(maxPercentageDown, 1)}`,
            },
          ]}
        />
        <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
          <Box sx={{ height: "40px" }} />
          <Dot />
          <Typography variant="subtitle2" fontWeight={500}>
            {"x1"}
          </Typography>
        </Box>
        <CustomSlider
          increase={1}
          onChangeCommitted={handleSliderChange("dynamicStrategySettings.maxMultiplier")}
          defaultValue={maxMultiplierDefault}
          min={1}
          max={maxPercentageUp}
          step={0.1}
          valueLabelDisplay={"on"}
          valueLabelFormat={(n: number) => `x${renderNumber(n, 1)}`}
          marks={[
            {
              value: maxPercentageUp,
              label: `x${maxPercentageUp}`,
            },
          ]}
        />
      </Stack>
    </>
  );
};

export default MultiplierInput;
