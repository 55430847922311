import { SvgIcon } from "@mui/material";

export const Android = (props: any) => {
  return (
    <SvgIcon {...props}>
      <svg width="24" height="24" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M62.5 77.5H17.5C16.1739 77.5 14.9021 76.9733 13.9645 76.0357C13.0268 75.098 12.5 73.826 12.5 72.5V37.5H67.5V72.5C67.5 73.826 66.9733 75.098 66.0357 76.0357C65.098 76.9733 63.826 77.5 62.5 77.5Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M67.5 35C67.5 27.7065 64.6027 20.7118 59.4453 15.5546C54.2883 10.3973 47.2933 7.5 40 7.5C32.7065 7.5 25.7118 10.3973 20.5546 15.5546C15.3973 20.7118 12.5 27.7065 12.5 35V37.5H67.5V35Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M28.75 25.0103C28.0596 25.0103 27.5 24.4506 27.5 23.7603C27.5 23.0699 28.0596 22.5103 28.75 22.5103" stroke="currentColor" strokeWidth="1.5"/>
        <path d="M28.75 25.0103C29.4404 25.0103 30 24.4506 30 23.7603C30 23.0699 29.4404 22.5103 28.75 22.5103" stroke="currentColor" strokeWidth="1.5"/>
        <path d="M51.25 25.0103C50.5597 25.0103 50 24.4506 50 23.7603C50 23.0699 50.5597 22.5103 51.25 22.5103" stroke="currentColor" strokeWidth="1.5"/>
        <path d="M51.25 25.0103C51.9403 25.0103 52.5 24.4506 52.5 23.7603C52.5 23.0699 51.9403 22.5103 51.25 22.5103" stroke="currentColor" strokeWidth="1.5"/>
        <path d="M24.53 12.2736L17.5 2.51025" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M55.47 12.2736L62.5 2.51025" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12.5 37.5103C9.84783 37.5103 7.3043 38.5636 5.42893 40.4389C3.55357 42.3143 2.5 44.8579 2.5 47.5103V67.5103" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M67.5 37.5103C70.152 37.5103 72.6957 38.5636 74.571 40.4389C76.4463 42.3143 77.5 44.8579 77.5 47.5103V67.5103" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    </SvgIcon>
  );
};
