import { REFERRAL_COINMATE } from "constants/links";

import { Button, Grid, Stack, useMediaQuery, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import { ReactComponent as AnycoinLogo } from "assets/exchanges/anycoin-logo-original.svg";
import { ReactComponent as BinanceLogo } from "assets/exchanges/binance-logo-original.svg";
import { ReactComponent as BitstampLogo } from "assets/exchanges/bitstamp-logo-original.svg";
import { ReactComponent as CoinmateLogo } from "assets/exchanges/coinmate-logo-original.svg";
import Card2 from "components/elements/Card2";
import SectionCard from "components/elements/SectionCard";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { useUserDataContext } from "context/UserDataContext";
import { useTranslation, Trans } from "react-i18next";
import { ExchangeType } from "types/enums";

interface Props {
  onOpenExchangeModal: (exchangeType?: ExchangeType) => void;
  onRegisteringAnycoin: () => void;
}

const AFF_BY_CARD = ["anycoin", "coinmate"];

const Upsell = ({ onOpenExchangeModal, onRegisteringAnycoin }: Props) => {
  const { colors } = useMUIThemeModeContext();
  const { t } = useTranslation();
  const { affBy } = useUserDataContext();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));

  const clickableIcon = (exchangeType: ExchangeType, icon: JSX.Element) => (
    <a
      style={{ display: "flex" }}
      href="#"
      onClick={(e) => {
        e.preventDefault();
        onOpenExchangeModal(exchangeType);
      }}>
      {icon}
    </a>
  );

  const promoCard = (prefix: string, exchangeType: ExchangeType, icon: JSX.Element, referral?: string) => (
    <Card2 background={colors.gray50} height={"100%"} childrenBoxSx={{ height: "100%" }}>
      <Stack spacing={2} alignContent={"flex-start"} height={"100%"} justifyContent={"space-between"}>
        <Stack spacing={2}>
          {clickableIcon(exchangeType, icon)}
          <Typography color={colors.gray600} fontSize={"0.875rem"}>
            {t(`exchangeConnections.upsell.promoHeader.${prefix}`)}
          </Typography>
        </Stack>
        <Stack direction="column" spacing={1.5}>
          {referral ? (
            <Button
              sx={{ border: `1px solid ${colors.gray300}`, background: colors.white }}
              color="inherit"
              variant="outlined"
              href={referral}
              target="_blank">
              {t(`exchangeConnections.upsell.promoActionSignup.${prefix}`)}
            </Button>
          ) : (
            <Button
              sx={{ border: `1px solid ${colors.gray300}`, background: colors.white  }}
              color="inherit"
              variant="outlined"
              onClick={onRegisteringAnycoin}>
              {t(`exchangeConnections.upsell.promoActionSignup.${prefix}`)}
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={() => onOpenExchangeModal(exchangeType)}>
            {t(`exchangeConnections.upsell.promoAction.${prefix}`)}
          </Button>
        </Stack>
      </Stack>
    </Card2>
  );

  const canRenderCard = (key: string) => !affBy || (affBy && affBy === key) || !AFF_BY_CARD.includes(affBy);
  const canRenderLogo = (key: string) => affBy && affBy === key;
  const canRenderOthers = () => !affBy || !AFF_BY_CARD.includes(affBy);

  return (
    <>
      <Card2 mb={canRenderOthers() ? 7 : 0} subtitle={<Trans i18nKey={"exchangeConnections.recommended.subtitle"} />}>
        <Grid container spacing={2}>
          {canRenderCard("coinmate") && (
            <Grid item xs={12} lg={6} height={"auto"}>
              {promoCard(
                "coinmate",
                ExchangeType.COINMATE,
                <CoinmateLogo height={24} width={95} style={{ maxWidth: "100%" }} />,
                REFERRAL_COINMATE
              )}
            </Grid>
          )}
          {canRenderCard("anycoin") && (
            <Grid item xs={12} lg={6} height={"auto"}>
              {promoCard(
                "anycoin",
                ExchangeType.ANYCOIN,
                <AnycoinLogo height={24} width={95} color={colors.black} style={{ maxWidth: "100%" }} />
              )}
            </Grid>
          )}
          {affBy && AFF_BY_CARD.includes(affBy) && (
            <Grid item xs={12} lg={6}>
              <Card2 height={"100%"}>
                <Stack alignItems={"center"} spacing={3}>
                  <Typography fontSize={"1rem"} fontWeight={600}>
                    {t(`exchangeConnections.upsell.otherHeader`)}
                  </Typography>
                  {canRenderLogo("anycoin")
                    && clickableIcon(ExchangeType.COINMATE, <CoinmateLogo height={32} width={200} />)}
                  {canRenderLogo("coinmate")
                    && clickableIcon(ExchangeType.ANYCOIN, <AnycoinLogo height={32} width={200} color={colors.black} />)}
                  {clickableIcon(ExchangeType.BINANCE, <BinanceLogo height={20} width={200} />)}
                  {clickableIcon(ExchangeType.BITSTAMP, <BitstampLogo height={20} width={200} />)}
                  <Button
                    sx={{ border: `1px solid ${colors.gray300}`, background: colors.white }}
                    color="inherit"
                    fullWidth
                    variant="outlined"
                    onClick={() => onOpenExchangeModal()}>
                    {t(`exchangeConnections.upsell.otherAction`)}
                  </Button>
                </Stack>
              </Card2>
            </Grid>
          )}
        </Grid>
      </Card2>
      {canRenderOthers() && (
        <SectionCard title={t("exchangeConnections.recommended.others")}>
          <Card2 sx={{ p: 0 }}>
            <Grid container>
              <Grid
                item
                xs={12}
                sm={6}
                display={"flex"}
                justifyContent={"center"}
                borderRight={isXs ? undefined : `1px solid ${colors.gray200}`}
                borderBottom={isXs ? `1px solid ${colors.gray200}` : undefined}
                p={3}>
                {clickableIcon(ExchangeType.BINANCE, <BinanceLogo height={20} width={200} />)}
              </Grid>
              <Grid item xs={12} sm={6} display={"flex"} justifyContent={"center"} p={3}>
                {clickableIcon(ExchangeType.BITSTAMP, <BitstampLogo height={20} width={200} />)}
              </Grid>
            </Grid>
          </Card2>
        </SectionCard>
      )}
    </>
  );
};

export default Upsell;
