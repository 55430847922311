import validations from "constants/validations";

import { useEffect, useState } from "react";

import LoadingButton from "@mui/lab/LoadingButton";
import { Alert, Box, Button, Stack } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { getUserPasswordReset, userPasswordReset } from "API/calls";
import Panel from "components/elements/Panel";
import PasswordField from "components/elements/PasswordField";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { IUserPasswordReset } from "types/types";
import { StringParam, useQueryParams } from "use-query-params";
import * as yup from "yup";

const SetNewPasswordPage = () => {
  const [query, setQuery] = useQueryParams({ token: StringParam });
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [passwordUpdated, setPasswordUpdated] = useState(false);
  const [tokenError, setTokenError] = useState<number>();
  const [token] = useState<string>(query.token || "");

  const checkToken = async () => {
    try {
      setLoading(true);
      await getUserPasswordReset(token);
    } catch (err: any) {
      setTokenError(err?.response?.status);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // to remove token query parameter from URL before it gets collected to Google Analytics
    setQuery({ token: undefined });
    checkToken();
  }, []);

  const handleUserPasswordReset = async (payload: IUserPasswordReset) => {
    try {
      setLoading(true);
      await userPasswordReset(payload);
      setPasswordUpdated(true);
      enqueueSnackbar(t("setNewPasswordPage.passwordChanged"), { variant: "success" });
    } catch (err: any) {
      // enqueueSnackbar(err?.response?.data?.message ?? t("commonError"), { variant: "error" });
      setTokenError(err?.response?.status);
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      passwordNew: "",
      passwordNewCheck: "",
    },
    validationSchema: yup.object().shape({
      passwordNew: yup.string().required(t("fieldRequired")).matches(validations.password, t("fieldErrors.password")),
      passwordNewCheck: yup
        .string()
        .required(t("fieldRequired"))
        .matches(validations.password, t("fieldErrors.password"))
        .oneOf([yup.ref("passwordNew"), null], t("fieldErrors.passwordCheck")),
    }),
    onSubmit: (values) => {
      handleUserPasswordReset({
        password: values.passwordNewCheck,
        token,
      });
    },
  });

  return (
    <Grid container justifyContent="center" spacing={8}>
      <Grid item sx={{ width: "100%", maxWidth: "sm" }}>
        <Panel sx={{ p: 4, mb: 10 }}>
          <Typography variant="h5" component="h1" fontWeight={600} sx={{ pb: 3, color: "tertiary.main" }}>
            {t("setNewPasswordPage.title")}
          </Typography>
          <Stack spacing={2}>
            {tokenError ? (
              <>
                <Alert severity="error">
                  {tokenError === 403 && t("setNewPasswordPage.tokenExpired")}
                  {(tokenError === 400 || tokenError === 404) && t("setNewPasswordPage.tokenInvalid")}
                </Alert>
                <LoadingButton
                  component={Link}
                  to="/zapomenute-heslo"
                  fullWidth
                  variant="contained"
                  disableElevation
                  loading={loading}>
                  {t("setNewPasswordPage.requestAgain")}
                </LoadingButton>
              </>
            ) : (
              <>
                {passwordUpdated ? (
                  <>
                    <Alert severity="success">{t("setNewPasswordPage.passwordChanged")}</Alert>
                    <Button component={Link} to="/login" fullWidth variant="contained">
                      {t("setNewPasswordPage.continueToLogin")}
                    </Button>
                  </>
                ) : (
                  <>
                    <Box>{t("setNewPasswordPage.content")}</Box>
                    <PasswordField
                      fullWidth={true}
                      id={"passwordNew"}
                      name={"passwordNew"}
                      label={t("passwordNew")}
                      value={formik.values.passwordNew ?? ""}
                      error={Boolean(formik.errors.passwordNew)}
                      helperText={formik.errors.passwordNew}
                      onChange={formik.handleChange}
                      autoComplete={"off"}
                    />
                    <PasswordField
                      fullWidth={true}
                      id={"passwordNewCheck"}
                      name={"passwordNewCheck"}
                      label={t("passwordNewCheck")}
                      value={formik.values.passwordNewCheck}
                      error={Boolean(formik.errors.passwordNewCheck)}
                      helperText={formik.errors.passwordNewCheck}
                      onChange={formik.handleChange}
                      autoComplete={"off"}
                    />
                    <LoadingButton
                      fullWidth
                      variant="contained"
                      disableElevation
                      onClick={() => formik.handleSubmit()}
                      loading={loading}>
                      {t("setNewPasswordPage.submit")}
                    </LoadingButton>
                  </>
                )}
              </>
            )}
          </Stack>
        </Panel>
      </Grid>
    </Grid>
  );
};
export default SetNewPasswordPage;
