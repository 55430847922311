import { SvgIcon } from "@mui/material";

export const Dollar = (props: any) => {
  return (
    <SvgIcon {...props}>
      <svg width="24" height="24" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.23438 16.0001C2.23438 8.1208 8.62178 1.7334 16.501 1.7334C24.3803 1.7334 30.7677 8.1208 30.7677 16.0001C30.7677 23.8793 24.3803 30.2667 16.501 30.2667C8.62178 30.2667 2.23438 23.8793 2.23438 16.0001ZM16.501 3.60006C9.65271 3.60006 4.10104 9.15173 4.10104 16.0001C4.10104 22.8484 9.65271 28.4001 16.501 28.4001C23.3494 28.4001 28.901 22.8484 28.901 16.0001C28.901 9.15173 23.3494 3.60006 16.501 3.60006Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.5003 9.7334C17.0157 9.7334 17.4336 10.1513 17.4336 10.6667V21.3334C17.4336 21.8489 17.0157 22.2667 16.5003 22.2667C15.9848 22.2667 15.5669 21.8489 15.5669 21.3334V10.6667C15.5669 10.1513 15.9848 9.7334 16.5003 9.7334Z"
          fill="#FF9900"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.9307 12.0063C14.599 11.3798 15.5076 11.0664 16.5023 11.0664C17.4971 11.0664 18.4057 11.3798 19.074 12.0063C19.7478 12.638 20.1023 13.5188 20.1023 14.4997C20.1023 15.0152 19.6845 15.4331 19.169 15.4331C18.6535 15.4331 18.2357 15.0152 18.2357 14.4997C18.2357 13.9807 18.0569 13.6115 17.7973 13.3681C17.5323 13.1197 17.1076 12.9331 16.5023 12.9331C15.8971 12.9331 15.4724 13.1197 15.2074 13.3681C14.9478 13.6115 14.769 13.9807 14.769 14.4997C14.769 14.6341 14.8006 14.6839 14.8179 14.7076C14.8445 14.744 14.9099 14.8089 15.07 14.8851C15.4281 15.0554 15.9469 15.1483 16.6614 15.2718C16.6712 15.2735 16.681 15.2752 16.6908 15.2769C17.3164 15.385 18.121 15.5241 18.7627 15.8543C19.1077 16.0318 19.4536 16.2865 19.7108 16.6635C19.9742 17.0495 20.1023 17.5021 20.1023 17.9997C20.1023 19.1964 19.3663 19.9596 18.6266 20.3757C17.9101 20.7787 17.0809 20.9331 16.5023 20.9331C15.8451 20.9331 15.0082 20.6616 14.3382 20.2394C13.6904 19.8313 12.9023 19.0864 12.9023 17.9997C12.9023 17.4843 13.3202 17.0664 13.8357 17.0664C14.3511 17.0664 14.769 17.4843 14.769 17.9997C14.769 18.1131 14.8698 18.3681 15.3332 18.6601C15.7742 18.9379 16.2707 19.0664 16.5023 19.0664C16.8127 19.0664 17.3168 18.9708 17.7114 18.7488C18.0829 18.5398 18.2357 18.3031 18.2357 17.9997C18.2357 17.8336 18.1972 17.7571 18.1689 17.7155C18.1344 17.665 18.0637 17.5939 17.9086 17.5141C17.5605 17.335 17.047 17.2329 16.3433 17.1112C16.3079 17.1051 16.2718 17.0989 16.2352 17.0926C15.6318 16.9889 14.8732 16.8586 14.2681 16.5707C13.9282 16.409 13.5768 16.1724 13.3118 15.8104C13.0375 15.4357 12.9023 14.9904 12.9023 14.4997C12.9023 13.5188 13.2569 12.638 13.9307 12.0063Z"
          fill="#FF9900"
        />
      </svg>
    </SvgIcon>
  );
};
