import { SvgIcon } from "@mui/material";

export const QuestionMark = (props: any) => {
  return (
    <SvgIcon {...props}>
      <svg width="24" height="24" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.4518 0.732422H14.5464C16.9987 0.732416 18.9027 0.732412 20.4231 0.870206C21.966 1.01005 23.2052 1.29768 24.2991 1.92926C25.6571 2.71333 26.7849 3.84105 27.5689 5.19909C28.2005 6.29302 28.4881 7.53216 28.628 9.0751C28.7658 10.5955 28.7658 12.4995 28.7658 14.9519V15.0463C28.7658 17.4986 28.7658 19.4027 28.628 20.9231C28.4881 22.466 28.2005 23.7052 27.5689 24.7991C26.7849 26.1571 25.6571 27.2849 24.2991 28.0689C23.2052 28.7005 21.966 28.9881 20.4231 29.128C18.9027 29.2658 16.9986 29.2658 14.5463 29.2658H14.4519C11.9995 29.2658 10.0955 29.2658 8.5751 29.128C7.03216 28.9881 5.79302 28.7005 4.69909 28.0689C3.34105 27.2849 2.21333 26.1571 1.42926 24.7991C0.79768 23.7052 0.510048 22.466 0.370206 20.9231C0.232412 19.4027 0.232416 17.4987 0.232422 15.0464V14.9518C0.232416 12.4995 0.232412 10.5954 0.370206 9.0751C0.510048 7.53216 0.79768 6.29302 1.42926 5.19909C2.21333 3.84105 3.34105 2.71333 4.69909 1.92926C5.79302 1.29768 7.03216 1.01005 8.5751 0.870206C10.0954 0.732412 11.9995 0.732416 14.4518 0.732422ZM8.74359 2.72925C7.33012 2.85736 6.3949 3.10563 5.63242 3.54584C4.55815 4.16607 3.66607 5.05815 3.04584 6.13242C2.60563 6.8949 2.35736 7.83012 2.22925 9.24359C2.09998 10.6699 2.09909 12.4896 2.09909 14.9991C2.09909 17.5086 2.09998 19.3283 2.22925 20.7546C2.35736 22.1681 2.60563 23.1033 3.04584 23.8658C3.66607 24.94 4.55815 25.8321 5.63242 26.4523C6.3949 26.8925 7.33012 27.1408 8.74359 27.2689C10.1699 27.3982 11.9896 27.3991 14.4991 27.3991C17.0086 27.3991 18.8283 27.3982 20.2546 27.2689C21.6681 27.1408 22.6033 26.8925 23.3658 26.4523C24.44 25.8321 25.3321 24.94 25.9523 23.8658C26.3925 23.1033 26.6408 22.1681 26.7689 20.7546C26.8982 19.3283 26.8991 17.5086 26.8991 14.9991C26.8991 12.4896 26.8982 10.6699 26.7689 9.24359C26.6408 7.83012 26.3925 6.8949 25.9523 6.13242C25.3321 5.05815 24.44 4.16607 23.3658 3.54584C22.6033 3.10563 21.6681 2.85736 20.2546 2.72925C18.8283 2.59998 17.0086 2.59909 14.4991 2.59909C11.9896 2.59909 10.1699 2.59998 8.74359 2.72925Z"
          fill="#3D424E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.2324 12.6658C10.2324 10.4856 12.0208 8.73242 14.1853 8.73242C16.3283 8.73242 18.0991 10.4682 18.0991 12.627C18.0991 13.5114 17.7981 14.3694 17.2457 15.0599L15.2279 17.5821C14.9059 17.9846 14.3186 18.0499 13.916 17.7279C13.5135 17.4059 13.4483 16.8186 13.7703 16.416L15.788 13.8938C16.0757 13.5343 16.2324 13.0875 16.2324 12.627C16.2324 11.5149 15.3131 10.5991 14.1853 10.5991C13.036 10.5991 12.0991 11.5322 12.0991 12.6658V12.9991C12.0991 13.5146 11.6812 13.9324 11.1658 13.9324C10.6503 13.9324 10.2324 13.5146 10.2324 12.9991V12.6658Z"
          fill="#FF9900"
        />
        <path
          d="M13.4541 20.4731C13.4541 19.8963 13.9217 19.4287 14.4985 19.4287C15.0754 19.4287 15.543 19.8963 15.543 20.4731C15.543 21.05 15.0754 21.5176 14.4985 21.5176C13.9217 21.5176 13.4541 21.05 13.4541 20.4731Z"
          fill="#FF9900"
        />
      </svg>
    </SvgIcon>
  );
};
