import { TREZOR_AFFILIATE } from "constants/links";

import { Box, Button, Typography, alpha, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid";
import { ReactComponent as Devices } from "assets/images/trezor-devices-perspective.svg";
import Panel from "components/elements/Panel";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { useTranslation } from "react-i18next";

interface IProps {
  source?: string;
}

const TrezorAffiliateCard = ({ source = "homepage" }: IProps) => {
  const { t } = useTranslation();
  const { colors } = useMUIThemeModeContext();
  const { palette: { mode } } = useTheme();
  const trezorAffiliateLink = `${TREZOR_AFFILIATE}&source=${source}`;

  return (
    <Grid item xs={12} style={{ textAlign: "center" }} id="trezor">
      <Panel backdrop sx={{ p: 4, overflow: "hidden" }}>
        <>
          <Devices style={{ margin: "-100px 0 0 -150px", display: "block", position: "absolute" }} />

          <Panel backdrop sx={{ background: `${alpha(colors.white, 0.93)} !important;`, p: { xs: 2, sm: 3 } }}>
            <Typography
              variant="h4"
              component="h2"
              sx={{ mb: 2, textAlign: "center", "@media (max-width:400px)": { fontSize: "1.46rem" } }}
              color="secondary">
              {t("homepage.trezorAffiliateTitle")}
            </Typography>
            <Box>{t("homepage.trezorAffiliateText")}</Box>
            <Box sx={{ mt: 2 }}>
              <Button component={"a"} variant="contained" target={"_blank"} href={trezorAffiliateLink}>
                {t("homepage.trezorAffiliateBuy")}
              </Button>
            </Box>
          </Panel>
        </>
      </Panel>
    </Grid>
  );
};

export default TrezorAffiliateCard;
