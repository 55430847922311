import { FC, useEffect, useState } from "react";

import FullscreenIcon from "@mui/icons-material/Fullscreen";
import { Alert, Box, Divider, Stack, ToggleButton, ToggleButtonGroup, useMediaQuery, useTheme } from "@mui/material";
import Legend, { LegendProps } from "components/elements/CandleChart/Legend";
import Card from "components/elements/Card";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { useUserDataContext } from "context/UserDataContext";
import { useAPIOHLC } from "hooks/useAPI";
import useCurrencyPairs from "hooks/useCurrencyPairs";
import useExchangeRate from "hooks/useExchangeRate";
import { useTranslation } from "react-i18next";
import { BotStrategyType, DcaBotMode, DcaBotOrderStatus, DcaBotRunState, OHLCInterval, OrderTriggering, OrderType } from "types/enums";
import { ICurrencyPair, IDcaBotRun } from "types/types";
import { splitCurrencyPair } from "utils";

import ChartItem, { BROKERAGE_PRICE_LINE_COLOR } from "./Item";
import ChartModal from "./Modal";
import ChartSkeleton from "./Skeleton";

interface IProps {
  exchangeEnum: string;
  currencyPair: string;
  isBrokerage?: boolean;
  askPrice?: number;
  bidPrice?: number;
  dcaBotRuns?: IDcaBotRun[];
  mode: DcaBotMode;
  strategyType?: BotStrategyType;
  enabled: boolean;
}

const Chart: FC<IProps> = ({ exchangeEnum, currencyPair, isBrokerage, askPrice, bidPrice, dcaBotRuns, mode, strategyType, enabled }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { subscriptionType } = useUserDataContext();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { colors } = useMUIThemeModeContext();
  const { showInUSD } = useUserDataContext();
  const { getUSDOriginalPrice } = useExchangeRate();
  const { counterCurrency } = splitCurrencyPair(currencyPair);

  const [isAvailable, setIsAvailable] = useState<boolean>(false);
  const [interval, setInterval] = useState<OHLCInterval>(
    (localStorage.getItem("dcaStrategyChartCandles") as OHLCInterval) ?? OHLCInterval.ONE_DAY
  );
  const [fullScreen, setFullScreen] = useState(false);

  const { data: currencyPairs } = useCurrencyPairs();
  const { data: OHLCData, error } = useAPIOHLC(currencyPair, interval, exchangeEnum, undefined, isAvailable);

  useEffect(() => {
    if (currencyPairs) {
      const exCurrPairs = currencyPairs.find((pairs) => pairs?.id === exchangeEnum);
      const currPair = exCurrPairs?.data?.find((pair: ICurrencyPair) => pair?.key === currencyPair);

      if (currPair?.enabled && currPair.ohlc_data) setIsAvailable(true);
    }
  }, [currencyPairs, exchangeEnum, currencyPair]);

  if (!isAvailable) return null;

  const handleChange = (_event: React.MouseEvent<HTMLElement>, newInterval: string) => {
    if (!newInterval) return;

    localStorage.setItem("dcaStrategyChartCandles", newInterval);
    setInterval(newInterval as OHLCInterval);
  };

  const priceTransform = (price: number) => {
    const inUSD = getUSDOriginalPrice(counterCurrency, price);
    return inUSD ?? price;
  };

  const processOpenInstantLimitOrders = () => {
    const result: any[] = [];
    let hasBuy = false;
    let hasSell = false;

    if (subscriptionType !== "FREE") {
      const openLimitInstantRuns = dcaBotRuns?.filter(
        (run) =>
          run.state === DcaBotRunState.OPEN
          && run.type === OrderType.LIMIT
          && run.triggering === OrderTriggering.INSTANT
      );

      if (openLimitInstantRuns && openLimitInstantRuns.length > 0) {
        openLimitInstantRuns.forEach((run) => {
          const value = run.orders[0].originalPrice;
          const price = showInUSD ? priceTransform(value) : value;

          if (run.side === "BUY" && !hasBuy) hasBuy = true;
          if (run.side === "SELL" && !hasSell) hasSell = true;

          result.push({
            price,
            color: run.side === "BUY" ? colors.success : colors.error,
            lineWidth: 1.5,
            lineStyle: 0,
            axisLabelVisible: true,
          });
        });
      }
    }

    return { hasBuy, hasSell, orders: result };
  };

  const processOpenPeriodicLimitOrders = () => {
    const result: any[] = [];

    if (enabled && strategyType === BotStrategyType.LIMIT && (mode === DcaBotMode.DCA_BUY || mode === DcaBotMode.DCA_SELL)) {
      
      const openLimitPeriodicRun = dcaBotRuns?.find((run) => {
        return (
          run.state === DcaBotRunState.OPEN
          && run.type === OrderType.LIMIT
          && run.triggering === OrderTriggering.PERIODIC
        );
      });

      if (openLimitPeriodicRun) {
        const orders = openLimitPeriodicRun.orders.filter(
          (order) =>
            order.type === OrderType.LIMIT
            && order.triggering === OrderTriggering.PERIODIC
            && (
              order.status === DcaBotOrderStatus.OPEN
              || order.status === DcaBotOrderStatus.PARTIALLY_FILLED
              || order.status === DcaBotOrderStatus.FILLED
            )
        );

        if (orders.length > 0) {
          orders.forEach((order) => {
            const value = order.originalPrice;
            const price = showInUSD ? priceTransform(value) : value;

            result.push({
              price,
              color: "BLUE",
              lineWidth: 1.5,
              lineStyle: order.status === DcaBotOrderStatus.FILLED ? 4 : 0,
              axisLabelVisible: true,
            });
          });
        }
      }
    }

    return result;
  };

  const chartSection = (height?: number) => {
    if (!OHLCData || bidPrice === undefined || askPrice === undefined) return <ChartSkeleton />;

    if (OHLCData.ohlc.length === 0 || error) {
      return (
        <Box height={"100%"} alignItems={"center"} display={"flex"} justifyContent={"center"}>
          <Alert severity="error">{t(`dcaBots.runsList.chart.errorData`)}</Alert>
        </Box>
      );
    }

    const priceLevels: any[] = [];
    const legengs: LegendProps[] = [];

    if (isBrokerage) {
      legengs.push({ color: BROKERAGE_PRICE_LINE_COLOR.askPrice, title: t("dcaBots.runsList.chart.askPrice") });
      legengs.push({ color: BROKERAGE_PRICE_LINE_COLOR.bidPrice, title: t("dcaBots.runsList.chart.bidPrice") });
    } else {
      legengs.push({ color: theme.palette.tertiary.main, title: t("dcaBots.runsList.chart.lastPrice") });
    }

    const openPeriodicLimitOrders = processOpenPeriodicLimitOrders();
    if (openPeriodicLimitOrders.length > 0) {
      priceLevels.push(...openPeriodicLimitOrders);
      legengs.push({ color: "BLUE", title: t("dcaBots.runsList.chart.dcaLimit") });
    }

    const openInstantLimitOrders = processOpenInstantLimitOrders();
    if (openInstantLimitOrders.orders.length > 0) {
      priceLevels.push(...openInstantLimitOrders.orders);
      if (openInstantLimitOrders.hasBuy) legengs.push({ color: colors.success, title: t("dcaBots.runsList.chart.buyLimit") });
      if (openInstantLimitOrders.hasSell) legengs.push({ color: colors.error, title: t("dcaBots.runsList.chart.sellLimit") });
    }

    return (
      <Stack height={"100%"} spacing={1}>
        <Box sx={{ height: height ? `${height}px` : "100%" }}>
          <ChartItem
            currencyPair={currencyPair}
            data={{ bidPrice, askPrice, priceLevels }}
            OHLCData={OHLCData}
            exchangeEnum={exchangeEnum}
            isBrokerage={isBrokerage}
            interval={interval}
          />
        </Box>
        <Stack direction={"row"} columnGap={2} flexWrap={"wrap"}>
          {legengs.map((lineSerie, index) => (
            <Legend key={`legend-${index}`} color={lineSerie.color} title={lineSerie.title} />
          ))}
        </Stack>
      </Stack>
    );
  };

  return (
    <Box pb={3}>
      <Card
        sx={{ mb: 3 }}
        boxSx={{ py: 0, px: 0 }}
        shadow={false}
        header={t(`dcaBots.runsList.chart.title`)}
        headerProps={{ variant: "h6", sx: { color: colors["black"] } }}
        childrenBoxSx={{ mt: 0 }}
        divider={false}
        actions={
          <Stack spacing={2} direction={"row"}>
            {!isMobile && (
              <ToggleButton
                value="fullScreen"
                size="small"
                sx={{ textTransform: "none" }}
                selected={fullScreen}
                onChange={() => setFullScreen(!fullScreen)}>
                <FullscreenIcon />
              </ToggleButton>
            )}
            <ToggleButtonGroup color="primary" size="small" value={interval} exclusive onChange={handleChange}>
              <ToggleButton sx={{ textTransform: "none" }} value={OHLCInterval.ONE_DAY}>
                1D
              </ToggleButton>
              <ToggleButton sx={{ textTransform: "none" }} value={OHLCInterval.ONE_HOUR}>
                1h
              </ToggleButton>
            </ToggleButtonGroup>
          </Stack>
        }>
        {chartSection(300)}
        {fullScreen && (
          <ChartModal
            title={t(`dcaBots.runsList.chart.title`)}
            handleClose={() => setFullScreen(false)}
            interval={interval}
            onChangeInterval={handleChange}>
            {chartSection()}
          </ChartModal>
        )}
      </Card>
      <Divider />
    </Box>
  );
};

export default Chart;
