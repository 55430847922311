import { SvgIcon } from "@mui/material";

export const User3 = (props: any) => {
  return (
    <SvgIcon {...props}>
      <svg width="24" height="24" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.0976 19.9632C20.9338 19.9511 20.657 19.9701 19.8691 20.0329C18.5444 20.1384 17.0751 20.2226 15.749 20.2226C14.5577 20.2226 13.3048 20.1547 12.1628 20.0644C11.3571 20.0007 11.0699 19.9808 10.9135 19.9914C9.78178 20.068 8.97728 20.7599 8.73221 21.8674C8.69819 22.0212 8.67772 22.2697 8.62651 22.9866C8.57161 23.7552 8.5545 24.0334 8.57198 24.2487C8.687 25.6644 9.75917 26.8159 11.1631 27.0315C11.3766 27.0643 11.6553 27.067 12.4258 27.067H19.5368C20.3422 27.067 20.6335 27.064 20.8564 27.0282C22.2433 26.8054 23.3015 25.6689 23.4249 24.2697C23.4447 24.0448 23.427 23.754 23.3696 22.9507C23.3182 22.2306 23.2976 21.9834 23.2609 21.8225C23.0147 20.7443 22.2007 20.0446 21.0976 19.9632ZM19.8284 18.1636C20.4696 18.1123 20.9068 18.0774 21.235 18.1016C23.1555 18.2433 24.6521 19.5296 25.0807 21.4071C25.1539 21.7278 25.1829 22.1342 25.2247 22.7223C25.227 22.7536 25.2292 22.7854 25.2315 22.8177C25.2338 22.8488 25.236 22.8795 25.2381 22.9097C25.2867 23.5876 25.3191 24.0402 25.2844 24.4337C25.0859 26.6846 23.3835 28.5129 21.1525 28.8712C20.7625 28.9339 20.3087 28.9338 19.6291 28.9337C19.5988 28.9337 19.5681 28.9337 19.5368 28.9337H12.4258C12.3959 28.9337 12.3665 28.9337 12.3375 28.9337C11.6874 28.9338 11.2533 28.9339 10.8798 28.8765C8.62126 28.5297 6.89648 26.6773 6.71145 24.3998C6.68085 24.0232 6.71185 23.5902 6.75828 22.9417C6.76035 22.9128 6.76246 22.8834 6.76458 22.8536C6.76692 22.821 6.7692 22.7889 6.77145 22.7574C6.81307 22.1728 6.84166 21.7713 6.90963 21.4641C7.33371 19.5476 8.82909 18.2615 10.7875 18.129C11.1015 18.1078 11.5431 18.1428 12.1978 18.1947C12.2345 18.1976 12.2718 18.2005 12.3099 18.2035C13.4226 18.2915 14.6232 18.3559 15.749 18.3559C17.0053 18.3559 18.42 18.2758 19.7208 18.1721C19.7573 18.1692 19.7931 18.1664 19.8284 18.1636Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.4023 10.0004C10.4023 6.9076 12.9095 4.40039 16.0023 4.40039C19.0951 4.40039 21.6023 6.9076 21.6023 10.0004V10.6671C21.6023 13.3917 19.3936 15.6004 16.669 15.6004H15.3357C12.6111 15.6004 10.4023 13.3917 10.4023 10.6671V10.0004ZM16.0023 6.26706C13.9405 6.26706 12.269 7.93853 12.269 10.0004V10.6671C12.269 12.3607 13.642 13.7337 15.3357 13.7337H16.669C18.3627 13.7337 19.7357 12.3607 19.7357 10.6671V10.0004C19.7357 7.93853 18.0642 6.26706 16.0023 6.26706Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.55341 18.3718C5.39041 17.8828 4.86184 17.6185 4.37283 17.7815L4.66798 18.667C4.37283 17.7815 4.3723 17.7817 4.37177 17.7819L4.37066 17.7823L4.36829 17.7831L4.36299 17.7849L4.35012 17.7894C4.34056 17.7928 4.32903 17.797 4.31567 17.802C4.28897 17.8121 4.25485 17.8256 4.21438 17.843C4.13358 17.8776 4.02658 17.9278 3.90221 17.9969C3.65444 18.1346 3.33075 18.3509 3.00801 18.6737C2.34476 19.3369 1.73464 20.4033 1.73464 22.0003C1.73464 23.5974 2.34476 24.6637 3.00801 25.327C3.33075 25.6497 3.65444 25.8661 3.90221 26.0037C4.02658 26.0728 4.13358 26.123 4.21438 26.1577C4.25485 26.175 4.28897 26.1885 4.31567 26.1986C4.32903 26.2037 4.34056 26.2079 4.35012 26.2113L4.36299 26.2158L4.36829 26.2176L4.37066 26.2184L4.37177 26.2187C4.3723 26.2189 4.37283 26.2191 4.66798 25.3337L4.37283 26.2191C4.86184 26.3821 5.39041 26.1178 5.55341 25.6288C5.71513 25.1436 5.45628 24.6196 4.97466 24.4521C4.97139 24.4509 4.96284 24.4476 4.9497 24.4419C4.92112 24.4297 4.87187 24.407 4.80874 24.3719C4.68151 24.3013 4.5052 24.1843 4.32794 24.007C3.99119 23.6703 3.60131 23.0699 3.60131 22.0003C3.60131 20.9307 3.99119 20.3304 4.32794 19.9936C4.5052 19.8164 4.68151 19.6994 4.80874 19.6287C4.87187 19.5936 4.92112 19.571 4.9497 19.5587C4.96283 19.5531 4.97138 19.5497 4.97466 19.5485C5.45628 19.3811 5.71513 18.857 5.55341 18.3718Z"
          fill="#FF9900"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.4466 18.3718C26.6096 17.8828 27.1382 17.6185 27.6272 17.7815L27.332 18.667C27.6272 17.7815 27.6277 17.7817 27.6282 17.7819L27.6293 17.7823L27.6317 17.7831L27.637 17.7849L27.6499 17.7894C27.6594 17.7928 27.671 17.797 27.6843 17.802C27.711 17.8121 27.7451 17.8256 27.7856 17.843C27.8664 17.8776 27.9734 17.9278 28.0978 17.9969C28.3456 18.1346 28.6693 18.3509 28.992 18.6737C29.6552 19.3369 30.2654 20.4033 30.2654 22.0003C30.2654 23.5974 29.6552 24.6637 28.992 25.327C28.6693 25.6497 28.3456 25.8661 28.0978 26.0037C27.9734 26.0728 27.8664 26.123 27.7856 26.1577C27.7451 26.175 27.711 26.1885 27.6843 26.1986C27.671 26.2037 27.6594 26.2079 27.6499 26.2113L27.637 26.2158L27.6317 26.2176L27.6293 26.2184L27.6282 26.2187C27.6277 26.2189 27.6272 26.2191 27.332 25.3337L27.6272 26.2191C27.1382 26.3821 26.6096 26.1178 26.4466 25.6288C26.2849 25.1436 26.5437 24.6196 27.0253 24.4521C27.0286 24.4509 27.0372 24.4476 27.0503 24.4419C27.0789 24.4297 27.1281 24.407 27.1913 24.3719C27.3185 24.3013 27.4948 24.1843 27.6721 24.007C28.0088 23.6703 28.3987 23.0699 28.3987 22.0003C28.3987 20.9307 28.0088 20.3304 27.6721 19.9936C27.4948 19.8164 27.3185 19.6994 27.1913 19.6287C27.1281 19.5936 27.0789 19.571 27.0503 19.5587C27.0372 19.5531 27.0286 19.5497 27.0253 19.5485C26.5437 19.3811 26.2849 18.857 26.4466 18.3718Z"
          fill="#FF9900"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.086 5.8105C23.1906 5.30576 23.6846 4.9814 24.1894 5.08602L23.9999 5.99993C24.1894 5.08602 24.1898 5.08611 24.1903 5.08621L24.1913 5.08642L24.1934 5.08687L24.1985 5.08796L24.2117 5.09085L24.2494 5.09967C24.2795 5.10696 24.3194 5.11716 24.3676 5.13075C24.4639 5.15787 24.5948 5.19884 24.7489 5.25758C25.0547 5.37409 25.4663 5.56597 25.8829 5.86954C26.7396 6.49379 27.5999 7.58668 27.5999 9.33326C27.5999 11.0798 26.7396 12.1727 25.8829 12.797C25.4663 13.1005 25.0547 13.2924 24.7489 13.4089C24.5948 13.4677 24.4639 13.5086 24.3676 13.5358C24.3194 13.5494 24.2795 13.5596 24.2494 13.5668L24.2117 13.5757L24.1985 13.5786L24.1934 13.5796L24.1913 13.5801L24.1903 13.5803C24.1898 13.5804 24.1894 13.5805 23.9999 12.6666L24.1894 13.5805C23.6846 13.6851 23.1906 13.3608 23.086 12.856C22.9818 12.3535 23.303 11.8616 23.804 11.7541L23.8103 11.7526C23.8193 11.7504 23.8367 11.746 23.8614 11.7391C23.911 11.7251 23.9884 11.7012 24.0842 11.6646C24.2785 11.5906 24.5336 11.4705 24.7836 11.2883C25.2602 10.9411 25.7333 10.3673 25.7333 9.33326C25.7333 8.29924 25.2602 7.72546 24.7836 7.3782C24.5336 7.19601 24.2785 7.07592 24.0842 7.00189C23.9884 6.96537 23.911 6.94143 23.8614 6.92746C23.8367 6.92051 23.8193 6.91612 23.8103 6.91395L23.804 6.91246C23.303 6.80496 22.9818 6.31306 23.086 5.8105Z"
          fill="#FF9900"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.92068 5.84604C8.83572 5.33763 8.35469 4.99435 7.84627 5.07932L8.00011 5.99989C7.84627 5.07932 7.84579 5.0794 7.84529 5.07948L7.84425 5.07966L7.84197 5.08005L7.83666 5.08097L7.82305 5.08342L7.7843 5.09096C7.75352 5.09721 7.71292 5.10605 7.66389 5.11804C7.56606 5.14196 7.43327 5.17873 7.27701 5.23304C6.96712 5.34076 6.54974 5.52272 6.12718 5.82213C5.2512 6.44281 4.40011 7.54333 4.40011 9.33321C4.40011 11.1231 5.2512 12.2236 6.12718 12.8443C6.54973 13.1437 6.96712 13.3257 7.27701 13.4334C7.43327 13.4877 7.56605 13.5245 7.66389 13.5484C7.71292 13.5604 7.75352 13.5692 7.7843 13.5755L7.82305 13.583L7.83666 13.5855L7.84197 13.5864L7.84425 13.5868L7.84529 13.587C7.84579 13.587 7.84627 13.5871 8.00011 12.6666L7.84627 13.5871C8.35468 13.6721 8.83571 13.3288 8.92068 12.8204C9.00525 12.3143 8.66554 11.8354 8.16101 11.7472C8.161 11.7472 8.161 11.7472 8.16099 11.7472C8.16005 11.747 8.15839 11.7467 8.15604 11.7462C8.14775 11.7445 8.13106 11.741 8.10717 11.7351C8.05917 11.7234 7.98363 11.7028 7.88989 11.6702C7.69977 11.6041 7.45049 11.4942 7.20638 11.3212C6.74903 10.9972 6.26678 10.431 6.26678 9.33321C6.26678 8.23542 6.74903 7.66927 7.20638 7.34521C7.45049 7.17224 7.69977 7.06231 7.88989 6.99623C7.98363 6.96365 8.05917 6.94304 8.10717 6.93131C8.13105 6.92547 8.14775 6.92192 8.15603 6.92023M8.16099 6.91925C8.16099 6.91925 8.161 6.91925 8.161 6.91925C8.16171 6.91911 8.162 6.91906 8.16188 6.91908L8.1597 6.91947L8.15728 6.91989L8.15574 6.92015"
          fill="#FF9900"
        />
      </svg>
    </SvgIcon>
  );
};
