import "dayjs/locale/cs";
import "dayjs/locale/sk";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, DatePickerProps } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Dayjs } from "dayjs";
import { getBrowserLocale } from "utils";

const LocalizedDatePicker = ({ ...props }: Omit<DatePickerProps<Dayjs | null, Dayjs | null>, "renderInput">) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={getBrowserLocale()}>
      <DatePicker
        {...props}
        renderInput={(params) => <TextField {...params}  />}
        inputFormat={"D. M. YYYY"}
      />
    </LocalizationProvider>
  );
};

export default LocalizedDatePicker;
