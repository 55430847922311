import React from "react";

import { styled } from "@mui/material";
import Box, { BoxProps } from "@mui/material/Box";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";

export const StyledBox = styled(Box)<{ isMain?: boolean }>(({ isMain }) => {
  const { colors } = useMUIThemeModeContext();

  return {
    position: "relative",
    background: colors.white,
    borderRadius: "10px",
    overflow: "hidden",
    filter: isMain
      ? "drop-shadow(0px 0px 1px rgba(48, 49, 51, 0.05)) drop-shadow(0px 2px 4px rgba(48, 49, 51, 0.1))"
      : undefined,
    boxShadow:
      isMain === false || !isMain ? "0px 0px 1px rgba(48, 49, 51, 0.05), 0px 2px 4px rgba(48, 49, 51, 0.1)" : undefined,
  };
});

export const HeaderBox = styled(Box)<{ isMain?: boolean }>(({ isMain, theme }) => {
  const { colors } = useMUIThemeModeContext();

  return {
    color: colors.black,
    fontWeight: 600,
    borderBottom: `1px solid ${colors.legacyGray200}`,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: isMain ? "20px 24px" : "16px 24px",
    fontSize: isMain ? "18px" : "14px",
    lineHeight: isMain ? "27px" : "20px",
  };
});

export interface IProps extends BoxProps {
  isMain?: boolean;
  header?: string;
  children: React.ReactNode;
  actions?: React.ReactNode;
  footer?: React.ReactNode;
}

const Tile = ({ header, isMain, children, actions, footer, ...props }: IProps) => {
  const { colors } = useMUIThemeModeContext();

  return (
    <StyledBox isMain={isMain} {...props}>
      {header && (
        <HeaderBox isMain={isMain}>
          {header}
          {actions}
        </HeaderBox>
      )}
      {children}
      {footer && (
        <Box bgcolor={colors.purple100} color={colors.purple500} px={3} py={1.5}>
          {footer}
        </Box>
      )}
    </StyledBox>
  );
};

export default Tile;
