import { useState } from "react";

import { Box, Tab, Tabs as MuiTabs } from "@mui/material";

import TabPanel from "./TabPanel";

interface Tab {
  label: string;
  component: React.ReactNode;
}

interface Props {
  tabs: Tab[];
}

const Tabs = ({ tabs, ...props }: Props) => {
  const [value, setValue] = useState(0);

  const onChange = (event: React.SyntheticEvent, value: any) => {
    setValue(value);
  };

  return (
    <Box sx={{ width: "100%" }} {...props}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <MuiTabs value={value} onChange={onChange} variant="scrollable" scrollButtons="auto">
          {tabs.map(({ label }, i) => (
            <Tab label={label} key={i} id={`Tab-${i}`} />
          ))}
        </MuiTabs>
      </Box>
      {tabs.map(({ component }, i) => (
        <TabPanel value={value} index={i} key={i}>
          {component}
        </TabPanel>
      ))}
    </Box>
  );
};

export default Tabs;
