import React from "react";

import Box from "@mui/material/Box";
import CenterWrapper from "components/elements/CenterWrapper";
import PageTitle from "components/elements/PageTitle";
import GridBotDetail from "components/modules/GridBotDetail";
import Loading from "components/modules/Loading";
import { useParams } from "react-router-dom";

const GridBotDetailPage: React.FC = () => {
  const { id } = useParams();

  if (!id) {
    return (
      <CenterWrapper>
        <Loading />
      </CenterWrapper>
    );
  }

  return (
    <Box key={id} sx={{ display: "flex", width: "100%", justifyContent: "center" }}>
      <PageTitle i18nKey="gridBot.meta_title" />
      <GridBotDetail id={id} />
    </Box>
  );
};

export default GridBotDetailPage;
