import { Box, CircularProgress, Grid, Skeleton, Stack, alpha, useTheme } from "@mui/material";
import Card from "components/elements/Card";
import Panel from "components/elements/Panel";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { getAnyByMode } from "utils";

const BotDetailSkeleton = () => {
  const theme = useTheme();
  const { colors } = useMUIThemeModeContext();

  return (
    <Grid container spacing={4}>
      <Grid item lg={7} xs={12}>
        <Panel sx={{ overflow: "hidden" }}>
          <Box p={3} bgcolor={alpha(colors.black, getAnyByMode(theme, 0.03, 0.05))} borderBottom={`2px solid ${colors.legacyGray50}`}>
            <Stack spacing={2} direction="row">
              <Skeleton variant="circular" height={40} width={40} />
              <Skeleton variant="text" height={40} width="40%" />
            </Stack>
          </Box>
          <Box p={3} display="flex" justifyContent="center" height={450}>
            <CircularProgress sx={{ mt: 5 }} />
          </Box>
        </Panel>
      </Grid>
      <Grid item lg={5} xs={12}>
        <Card divider={false} sx={{ mb: 0 }}>
          <Skeleton variant="text" height={32} width="50%" />
        </Card>
      </Grid>
    </Grid>
  );
};

export default BotDetailSkeleton;
