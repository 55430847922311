import React from "react";

import { Stack, Step, StepLabel, Stepper as MUIStepper } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { StepperBox, StepperButton, StepperContent, StepperTitle, StepperChildren } from "./styles";
import IProps from "./types";

const Stepper: React.FC<IProps> = ({
  title,
  steps,
  activeStep,
  isFirstStep,
  isLastStep,
  onNext,
  onBack,
  onDone,
  children,
}) => {
  const { t } = useTranslation<string>();

  return (
    <>
      <StepperBox>
        <MUIStepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </MUIStepper>
      </StepperBox>
      <StepperContent>
        <StepperTitle variant="h6">{title}</StepperTitle>
        <StepperChildren>{children}</StepperChildren>
        <Stack direction="row" spacing={2}>
          {isFirstStep && (
            <StepperButton color="inherit" variant="contained" disableElevation to="/nastaveni" component={Link}>
              {t("twoFactorAuth.previousStep")}
            </StepperButton>
          )}
          {isLastStep && (
            <StepperButton color="primary" variant="contained" disableElevation onClick={onDone}>
              {t("twoFactorAuth.loginAgain")}
            </StepperButton>
          )}
          {!isFirstStep && !isLastStep && onBack && (
            <StepperButton color="inherit" variant="contained" disableElevation onClick={onBack}>
              {t("twoFactorAuth.previousStep")}
            </StepperButton>
          )}
          {!isLastStep && (
            <StepperButton color="primary" variant="contained" disableElevation onClick={onNext}>
              {t("twoFactorAuth.next")}
            </StepperButton>
          )}
        </Stack>
      </StepperContent>
    </>
  );
};

export default Stepper;
