import React from "react";

import { ChevronRight } from "@mui/icons-material";
import { Button, Grid, Stack, Typography, useTheme, Link as MUILink } from "@mui/material";
import Box from "@mui/material/Box";
import AspectRatioWrapper from "components/elements/AspectRatioWrapper";
import List from "components/elements/List";
import HomeBox from "components/modules/HomeBox";
import RegisterWithEmailOnly from "components/modules/RegisterWithEmailOnly";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { useIsLogin } from "hooks/useUser";
import { Trans, useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

const HowToStart: React.FC = () => {
  const { t } = useTranslation();
  const { colors } = useMUIThemeModeContext();
  const theme = useTheme();
  const navigate = useNavigate();
  const isLoggedIn = useIsLogin();

  const videoSection = (video: string, type: "educate" | "myself", url: string) => {
    return (
      <Grid container>
        <Grid item xs={12} sm={5} alignContent={"center"} order={{ xs: 2, sm: 1 }}>
          <AspectRatioWrapper aspectRatio={"16 / 9"}>
            <iframe
              width="100%"
              src={`https://www.youtube-nocookie.com/embed/${video}`}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
              style={{ display: "block", aspectRatio: "16 / 9", border: 0, borderRadius: "20.87px" }}
            />
          </AspectRatioWrapper>
        </Grid>
        <Grid item xs={12} sm={7} order={{ xs: 1, sm: 2 }}>
          <Box pt={{ xs: 0, sm: 4 }} pb={{ xs: 2, sm: 0 }} px={4}>
            <Typography color={theme.palette.tertiary.main} fontWeight={500} fontSize={{ xs: "0.9rem", sm: "1rem" }}>
              {t(`homepage.howToStart.${type}.name`)}
            </Typography>
            <Typography
              sx={{ pt: 1 }}
              color={colors["deepMidnightBlue"]}
              fontWeight={600}
              fontSize={{ xs: "1.25rem", sm: "1.5rem" }}>
              {t(`homepage.howToStart.${type}.title`)}
            </Typography>
            <Typography
              sx={{ py: 2 }}
              color={colors["blueGray"]}
              fontWeight={300}
              fontSize={{ xs: "0.9rem", sm: "1rem" }}>
              {t(`homepage.howToStart.${type}.subtitle`)}
            </Typography>
            <MUILink component={Link} to={`/proc-bitcoin/${url}`} fontSize={"0.875rem"} underline="none">
              <Stack direction={"row"} alignContent={"center"} alignItems={"center"} spacing={1}>
                {t("homepage.howToStart.nextVideos")}
                <ChevronRight fontSize="small" />
              </Stack>
            </MUILink>
          </Box>
        </Grid>
      </Grid>
    );
  };

  return (
    <HomeBox
      title={t("homepage.howToStart.title")}
      id={"jak-zacit"}
      subtitle={<Trans i18nKey="homepage.howToStart.subtitle" />}
      middle={
        !isLoggedIn ? (
          <Box pt={{ xs: 2, sm: 4 }} pb={{ xs: 3, sm: 0 }} width={"100%"} display={"flex"} justifyContent={"center"}>
            <Box minWidth={{ md: "600px" }}>
              <RegisterWithEmailOnly withBorder withBackground textAlign="center" />
            </Box>
          </Box>
        ) : undefined
      }>
      <Grid container sx={{ mt: { xs: 1, sm: 4 } }} spacing={4} textAlign={"start"}>
        <Grid item xs={12} lg={8}>
          <Stack spacing={4}>
            {videoSection("tVmymAj1cRA", "educate", "svet-potrebuje-kvalitni-penize")}
            {videoSection("uuMalJpmHnM", "myself", "jak-vam-stosuj-cz-pomuze")}
          </Stack>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Box px={4} pt={{ xs: 0, sm: 4 }} pb={4} bgcolor={colors["blueWhite"]} borderRadius={"24px"}>
            <Typography color={theme.palette.tertiary.main} fontWeight={500} fontSize={{ xs: "0.9rem", sm: "1rem" }}>
              {t("homepage.howToStart.consultation.name")}
            </Typography>
            <Typography
              sx={{ pt: 1 }}
              color={colors["deepMidnightBlue"]}
              fontWeight={600}
              fontSize={{ xs: "1.25rem", sm: "1.5rem" }}>
              {t("homepage.howToStart.consultation.title")}
            </Typography>
            <Typography
              sx={{ pt: 2 }}
              color={colors["blueGray"]}
              fontWeight={300}
              fontSize={{ xs: "0.9rem", sm: "1rem" }}>
              <Trans i18nKey="homepage.howToStart.consultation.subtitle" />
            </Typography>
            <Typography sx={{ pt: 4 }} fontWeight={600} fontSize={{ xs: "0.9rem", sm: "1rem" }} color={"black"}>
              {t("homepage.howToStart.consultation.individuallyTitle")}
            </Typography>
            <List
              data={[
                "homepage.howToStart.consultation.individually1",
                "homepage.howToStart.consultation.individually2",
                "homepage.howToStart.consultation.individually3",
                "homepage.howToStart.consultation.individually4",
                "homepage.howToStart.consultation.individually5",
              ]}
            />
            <Button
              sx={{ mt: 3 }}
              variant="contained"
              fullWidth
              endIcon={<ChevronRight />}
              onClick={() => navigate("#kontakt")}>
              {t("homepage.howToStart.consultation.individuallyButton")}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </HomeBox>
  );
};

export default HowToStart;
