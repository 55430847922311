import React from "react";

import { Box, Stack, Typography } from "@mui/material";

export interface LegendProps {
  title: string;
  color: string;
}


const Legend = ({ title, color }: LegendProps) => {
  return (
    <Stack direction="row" alignItems="center">
      <Box style={{ height: "3px", width: "12px", backgroundColor: color }} />
      <Typography variant="body2" sx={{ ml: 1 }}>
        {title}
      </Typography>
    </Stack>
  );
};

export default Legend;