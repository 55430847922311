import * as React from "react";
import {useEffect, useRef} from "react";

import DescriptionIcon from "@mui/icons-material/Description";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {grey} from "@mui/material/colors";
import Divider from "@mui/material/Divider";
import MuiLink from "@mui/material/Link";
import useTheme from "@mui/material/styles/useTheme";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import {getCreditsPaymentsTransactionInvoice} from "API/calls";
import Panel from "components/elements/Panel";
import {Credit} from "components/icons/components/Credit";
import {useUserDataContext} from "context/UserDataContext";
import {useAPICreditsTransactions} from "hooks/useAPI";
import {useSnackbar} from "notistack";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {CreditsTransactionType} from "types/enums";
import {renderDateTime} from "utils/formatter";

import ErrorBox from "../ErrorBox";
import Loading from "../Loading";

const Transactions: React.FC<{ limit?: number }> = ({ limit }) => {
  const { data, error, mutate } = useAPICreditsTransactions();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useTranslation();
  const { creditsBalance, refreshData } = useUserDataContext();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    mutate();
  }, [creditsBalance]);

  useEffect(() => {
    if(data?.creditsTransactions?.length) {
      refreshData?.();
    }
  }, [data?.creditsTransactions?.length]);

  useEffect(() => {
    if (ref.current && document.location.hash === "#transakce") {
      ref.current.scrollIntoView();
    }
  }, [ref, data]);

  const onInvoiceDownload = async (id: string) => {
    try {
      const invoice: string = (await getCreditsPaymentsTransactionInvoice(id)).data;
      const blob = new Blob([invoice], { type: "application/pdf" });
      const fileURL = window.URL.createObjectURL(blob);
      window.open(fileURL);
    } catch (err: any) {
      enqueueSnackbar(t("commonError"), { variant: "error" });
    }
  };

  const creditsTransactions = data?.creditsTransactions;
  const showMore = limit && creditsTransactions?.length && creditsTransactions.length > limit;

  if (error) return <ErrorBox>{error?.message}</ErrorBox>;
  if (!data) return <Loading />;
  return (
    <Panel sx={{ mb: 4, p: 3 }}>
      <Typography id={"transakce"} variant="h5" component="h1" fontWeight={600} sx={{ pb: 1, color: "tertiary.main" }} ref={ref}>
        {t("transactions.title")}
      </Typography>

      {isMobile
        && creditsTransactions?.map(
          (row, index) =>
            (!limit || index < limit) && (
              <Box key={index}>
                <Table sx={{ width: "100%", "td, th": { border: 0, py: 1, px: 0 } }}>
                  <TableBody>
                    <TableRow>
                      <TableCell>{t("date")}</TableCell>
                      <TableCell align="right">
                        <Box sx={{ color: grey[500] }}>{renderDateTime(row.transactedAt)}</Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{t("amount")}</TableCell>
                      <TableCell align="right">
                        {row.amount > 0 && (
                          <Box
                            sx={{
                              color: theme.palette.success.light,
                              display: "flex",
                              justifyContent: "end",
                              alignItems: "center",
                            }}>
                            +{row.amount}
                            <Credit sx={{ marginLeft: "4px" }} />
                          </Box>
                        )}
                        {row.amount <= 0 && (
                          <Box
                            sx={{
                              color: theme.palette.error.light,
                              display: "flex",
                              justifyContent: "end",
                              alignItems: "center",
                            }}>
                            {row.amount}
                            <Credit sx={{ marginLeft: "4px" }} />
                          </Box>
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{t("type")}</TableCell>
                      <TableCell align="right">
                        <Box sx={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
                          {t("creditsTransactionType." + row.type)}{" "}
                          {(row.type === CreditsTransactionType["CREDITS_BOUGHT"] || row.type === CreditsTransactionType["CREDITS_RECHARGED"]) && (
                            <MuiLink
                              onClick={() => onInvoiceDownload(row.id)}
                              sx={{ display: "block", margin: "5px 0 0 5px" }}
                              component="button">
                              <DescriptionIcon fontSize="medium" color="primary" />
                            </MuiLink>
                          )}
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{t("newBalance")}</TableCell>
                      <TableCell align="right">
                        <Box sx={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
                          {row.newBalance}
                          <Credit sx={{ marginLeft: "4px" }} />
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                {((limit && index < limit - 1) || (!limit && index < creditsTransactions.length - 1)) && (
                  <Divider sx={{ mb: 2, pb: 2 }} />
                )}
              </Box>
            )
        )}

      {!isMobile && (
        <TableContainer>
          <Table sx={{ width: "100%" }}>
            <TableHead>
              <TableRow>
                <TableCell>{t("date")}</TableCell>
                <TableCell>{t("amount")}</TableCell>
                <TableCell>{t("type")}</TableCell>
                <TableCell align="right">{t("newBalance")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {creditsTransactions?.map(
                (row, index) =>
                  (!limit || index < limit) && (
                    <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                      <TableCell component="th" scope="row">
                        <Box sx={{ color: grey[500] }}>{renderDateTime(row.transactedAt)}</Box>
                      </TableCell>
                      <TableCell>
                        <Box sx={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
                          {row.amount > 0 && <Box sx={{ color: theme.palette.success.light }}>+{row.amount}</Box>}
                          {row.amount <= 0 && <Box sx={{ color: theme.palette.error.light }}>{row.amount}</Box>}
                          <Credit sx={{ marginLeft: "4px" }} />
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box sx={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
                          {t("creditsTransactionType." + row.type)}{" "}
                          {(row.type === CreditsTransactionType["CREDITS_BOUGHT"] || row.type === CreditsTransactionType["CREDITS_RECHARGED"]) && (
                            <MuiLink
                              onClick={() => onInvoiceDownload(row.id)}
                              sx={{ display: "block", margin: "5px 0 0 5px" }}
                              component="button">
                              <DescriptionIcon fontSize="medium" color="primary" />
                            </MuiLink>
                          )}
                        </Box>
                      </TableCell>
                      <TableCell align="right">
                        <Box sx={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
                          {row.newBalance}
                          <Credit sx={{ marginLeft: "4px" }} />
                        </Box>
                      </TableCell>
                    </TableRow>
                  )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {!!showMore && (
        <>
          <Divider sx={{ mb: 2, pt: { xs: 2, md: 0 } }} />
          <Button component={Link} to="/stosaky/transakce">
            {t("showAllCreditsTransactions")}
          </Button>
        </>
      )}
    </Panel>
  );
};

export default Transactions;
