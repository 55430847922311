import { useEffect, useState } from "react";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Alert, Box, Collapse, Stack, Typography, darken, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import DynamicChart from "components/modules/DynamicChart";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { ErrorBoundary } from "react-error-boundary";
import { Trans, useTranslation } from "react-i18next";
import { DcaBotMode, DcaDynamicStrategyType } from "types/enums";
import { getColorByMode } from "utils";

import { DEFAULT_DYNAMIC_STRATEGY_VALUES } from "../module/useNewDcaBotData";
import MultiplierInput from "./MultiplierInput";
import { CustomSlider } from "./MultiplierInput/styles";
import { IProps } from "./types";

const MIN_AND_MAX_LIMITS = {
  [DcaDynamicStrategyType.BTC_NUPL]: {
    min: -40,
    max: 100,
  },
  [DcaDynamicStrategyType.BTC_FEAR_AND_GREED]: {
    min: 0,
    max: 100,
  },
  [DcaDynamicStrategyType.EMPTY]: {
    min: -40,
    max: 100,
  },
};

const getDefaultValues = (type: DcaDynamicStrategyType, mode: DcaBotMode) => {
  const defaultValuesByType = DEFAULT_DYNAMIC_STRATEGY_VALUES[type as keyof typeof DEFAULT_DYNAMIC_STRATEGY_VALUES];
  const defaultValuesByMode = defaultValuesByType[mode as keyof typeof defaultValuesByType];

  return defaultValuesByMode;
};

const DynamicStrategySection = ({
  setFieldValue,
  minMultiplier,
  maxMultiplier,
  bottom,
  center,
  top,
  dynamicStrategyChart,
  dynamicStrategyChartOriginal,
  children,
  disabled,
  mode,
  type,
}: IProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { colors } = useMUIThemeModeContext();
  const [isCollaped, setIsCollapsed] = useState<boolean>(false);
  const [limitsDefault] = useState<number[]>([bottom, center, top]);
  const defaultValues = getDefaultValues(type, mode);

  useEffect(() => {
    if (bottom < MIN_AND_MAX_LIMITS[type].min) {
      setFieldValue("dynamicStrategySettings.bottom", MIN_AND_MAX_LIMITS[type].min);
    }

    if (center < MIN_AND_MAX_LIMITS[type].min) {
      setFieldValue("dynamicStrategySettings.center", MIN_AND_MAX_LIMITS[type].min);
    }

    if (top < MIN_AND_MAX_LIMITS[type].min) {
      setFieldValue("dynamicStrategySettings.top", MIN_AND_MAX_LIMITS[type].min);
    }

    if (bottom > MIN_AND_MAX_LIMITS[type].max) {
      setFieldValue("dynamicStrategySettings.bottom", MIN_AND_MAX_LIMITS[type].max);
    }

    if (center > MIN_AND_MAX_LIMITS[type].max) {
      setFieldValue("dynamicStrategySettings.center", MIN_AND_MAX_LIMITS[type].max);
    }

    if (top > MIN_AND_MAX_LIMITS[type].max) {
      setFieldValue("dynamicStrategySettings.top", MIN_AND_MAX_LIMITS[type].max);
    }
  }, [type]);

  const handleChangeLimits = (_event: React.SyntheticEvent | Event, newValue: number | number[]) => {
    if (!Array.isArray(newValue)) return;

    setFieldValue("dynamicStrategySettings.bottom", newValue[0]);
    setFieldValue("dynamicStrategySettings.center", newValue[1]);
    setFieldValue("dynamicStrategySettings.top", newValue[2]);
  };

  const getCurrentValue = () => {
    const current = dynamicStrategyChart[dynamicStrategyChart.length - 1];

    return current?.i;
  };

  const getCurrentMultiplier = () => {
    const current = dynamicStrategyChart[dynamicStrategyChart.length - 1];

    return current?.m;
  };

  return (
    <Grid item xs={12}>
      <Stack direction={"column"} spacing={2}>
        <Typography color={"text.secondary"} variant="body2">
          <Trans i18nKey={`dcaBots.create.dynamicStrategy.intro.${type}`} />
        </Typography>
        <Typography color={"text.secondary"} variant="body2">
          <Trans i18nKey="dcaBots.create.dynamicStrategy.intro2" />
        </Typography>
        <Stack spacing={1}>
          <Grid container spacing={2}>{children}</Grid>
          <MultiplierInput
            maxPercentageUp={10}
            maxPercentageDown={0.1}
            minMultiplier={minMultiplier}
            maxMultiplier={maxMultiplier}
            setFieldValue={setFieldValue}
          />
          <ErrorBoundary
            FallbackComponent={() => (
              <Alert severity="error">
                {t("dcaBots.create.dynamicStrategy.chartError", { type: t(`dcaBots.create.dynamicStrategyLabel.typesShort.${type}`) })}
              </Alert>
            )}>
            <DynamicChart
              minMultiplier={minMultiplier}
              maxMultiplier={maxMultiplier}
              top={top}
              bottom={bottom}
              center={center}
              dynamicStrategyChart={dynamicStrategyChart}
              dynamicStrategyChartOriginal={dynamicStrategyChartOriginal}
              isCollaped={isCollaped}
              mode={mode}
              type={type}
            />
          </ErrorBoundary>
          <Box
            sx={{
              background: getColorByMode(theme, colors.legacyGray100, darken(colors.legacyGray100, 0.95)),
              borderRadius: "10px",
              p: 2,
              mb: 1,
            }}>
            <Stack direction={"row"} alignItems={"center"} spacing={1}>
              <Typography color={colors.gray900} fontWeight={400} fontSize={{ sx: "0.75rem" }}>
                {t("dcaBots.create.dynamicStrategy.advancedSettings", { type: t(`dcaBots.create.dynamicStrategyLabel.typesShort.${type}`) })}
              </Typography>
              <IconButton size="small" onClick={() => setIsCollapsed(!isCollaped)}>
                {isCollaped ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </Stack>
            <Collapse in={isCollaped} timeout="auto">
              <Box px={{ xs: 0.5, sm: 4 }} pt={4}>
                <CustomSlider
                  bgcolor="white"
                  basic={1}
                  increase={1}
                  onChangeCommitted={handleChangeLimits}
                  defaultValue={limitsDefault}
                  min={MIN_AND_MAX_LIMITS[type].min}
                  max={MIN_AND_MAX_LIMITS[type].max}
                  step={5}
                  disabled={disabled}
                  valueLabelDisplay={"auto"}
                  valueLabelFormat={(n: number) => `${n} %`}
                  marks={[
                    {
                      value: -40,
                      label: "-40 %",
                    },
                    {
                      value: 0,
                      label: "0 %",
                    },
                    {
                      value: 50,
                      label: "50 %",
                    },
                    {
                      value: 100,
                      label: "100 %",
                    },
                  ]}
                />
              </Box>
              <Stack spacing={1}>
                <Typography color={"text.secondary"} variant="body2" fontWeight={600} marginTop={1}>
                  {t("dcaBots.create.dynamicStrategy.currentValue", { value: `${getCurrentValue()}`.replace(".", ","), type: t(`dcaBots.create.dynamicStrategyLabel.typesShort.${type}`)})}
                  <br />
                  {t("dcaBots.create.dynamicStrategy.currentMultiplierValue", {
                    value: `${getCurrentMultiplier()}`.replace(".", ","),
                  })}
                </Typography>
                <Typography color={"text.secondary"} variant="body2">
                  <Trans
                    i18nKey={`dcaBots.create.dynamicStrategy.description1.${mode}`}
                    values={{
                      type: t(`dcaBots.create.dynamicStrategyLabel.typesShort.${type}`),
                      top: MIN_AND_MAX_LIMITS[type].max,
                      bottom: MIN_AND_MAX_LIMITS[type].min,
                    }}
                  />
                </Typography>
                <Typography color={"text.secondary"} variant="body2">
                  <Trans
                    i18nKey={`dcaBots.create.dynamicStrategy.description2.${mode}`}
                    values={{ center: defaultValues.center }}
                  />
                </Typography>
                <Typography color={"text.secondary"} variant="body2">
                  <Trans i18nKey={`dcaBots.create.dynamicStrategy.description3.${mode}`} />
                </Typography>
                <Typography color={"text.secondary"} variant="body2">
                  <Trans i18nKey="dcaBots.create.dynamicStrategy.description4" />
                </Typography>
              </Stack>
            </Collapse>
          </Box>
        </Stack>
      </Stack>
    </Grid>
  );
};

export default DynamicStrategySection;
