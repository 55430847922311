import { useState } from "react";

import { CircularProgress, Grid } from "@mui/material";
import CenterWrapper from "components/elements/CenterWrapper";
import { useTranslation } from "react-i18next";
import { isEmptyArray } from "utils";

import CreateConnectionModal from "../ExchangeConnectionsList/CreateModal";
import CreateModal from "./CreateModal";
import ListItem from "./ListItem";
import ListWrapper from "./ListWrapper";
import { IProps } from "./types";

const WithdrawalConnectionsList = ({ data, refreshData }: IProps) => {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [isOpenCreateConnectionModal, setIsOpenCreateConnectionModal] = useState<boolean>(false);
  const { t } = useTranslation();

  if (!data) {
    return (
      <CenterWrapper>
        <CircularProgress color="primary" />
      </CenterWrapper>
    );
  }

  const renderList = () => {
    if (isEmptyArray(data.withdrawalConnections)) return;

    return (
      <Grid container spacing={2}>
        {Array.isArray(data.withdrawalConnections)
          && data.withdrawalConnections.length > 0
          && data.withdrawalConnections?.map((withdrawalConnectionDetail, index) => (
            <ListItem
              key={`ExchangeConnectionsListItem-${index}`}
              {...withdrawalConnectionDetail}
              refreshData={refreshData}
            />
          ))}
      </Grid>
    );
  };

  return (
    <>
      <ListWrapper header={t("withdrawalConnections.title")} onAdd={() => setIsOpenModal(true)}>
        {renderList()}
      </ListWrapper>
      {isOpenModal && (
        <CreateModal
          isOpen={isOpenModal}
          handleClose={() => setIsOpenModal(false)}
          onRegisteringAnycoin={() => {
            setIsOpenCreateConnectionModal(true);
            setIsOpenModal(false);
          }}
        />
      )}
      {isOpenCreateConnectionModal && (
        <CreateConnectionModal
          isOpen={isOpenCreateConnectionModal}
          isRegisteringAnycoin={true}
          handleClose={() => setIsOpenCreateConnectionModal(false)}
          handleSetIsRegisteringAnycoin={() => null}
          hasExchangeConnections={true}
        />
      )}
    </>
  );
};

export default WithdrawalConnectionsList;
