/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useEffect, useState } from "react";

import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
  Stack,
  MenuItem,
  Typography,
  Box,
  Grid,
  SelectChangeEvent,
  FormControlLabel,
  Checkbox,
  InputAdornment,
  Link,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  useMediaQuery,
  Alert,
  CircularProgress,
  useTheme,
} from "@mui/material";
import { getGridBotBacktest } from "API/calls";
import Big from "big.js";
import CandleChart, { convertOHLCData } from "components/elements/CandleChart";
import { IPriceRange } from "components/elements/CandleChart/types";
import LocalizedDatePicker from "components/elements/LocalizedDatePicker";
import PageTitle from "components/elements/PageTitle";
import PriceField from "components/elements/PriceField";
import ResponsiveLabel from "components/elements/ResponsiveLabel";
import Select from "components/elements/Select";
import Tile from "components/elements/Tile";
import Tooltip from "components/elements/Tooltip";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import dayjs, { Dayjs } from "dayjs";
import { isEmptyArray, useFormik } from "formik";
import { useAPIOHLC } from "hooks/useAPI";
import useCurrencyPairs from "hooks/useCurrencyPairs";
import useExchangesMetadata from "hooks/useExchangesMetadata";
import { useIsLogin } from "hooks/useUser";
import { PriceLineOptions, LineWidth, SeriesMarker, Time, TimeRange } from "lightweight-charts";
import { useSnackbar } from "notistack";
import { Trans, useTranslation } from "react-i18next";
import { useCustomCompareEffect } from "react-use";
import { ExchangeType, OHLCInterval } from "types/enums";
import { getColorByMode, splitCurrencyPair } from "utils";
import { renderNumber } from "utils/formatter";
import * as yup from "yup";

import OrderValueInput from "../NewGridBot/OrderValueInput";
import PercentStepInput from "./PercentStepInput";

interface IBacktest {
  buyOrdersCount: number;
  end: {
    baseCurrencyAmount: number;
    baseCurrencyProfitAmount: number;
    baseCurrencyProfitValue: number;
    baseCurrencyTotalAmount: number;
    baseCurrencyTotalValue: number;
    baseCurrencyValue: number;
    counterCurrencyAmount: number;
    counterCurrencyTotalValue: number;
    price: number;
  };
  priceLevels: number[];
  sellOrdersCount: number;
  start: {
    baseCurrencyAmount: number;
    baseCurrencyValue: number;
    counterCurrencyAmount: number;
    counterCurrencyTotalValue: number;
    price: number;
  };
}

const DEFAULT_CURRENCY_PAIR = "BTC/CZK";
const DATE_FORMAT = "YYYY-MM-DD";

const initValues = {
  exchangeEnum: ExchangeType.COINMATE,
  currencyPair: DEFAULT_CURRENCY_PAIR,
  gridPercentStep: 2.5,
  gridOrderValue: 5000,
  exchangeFeeTier: "COINMATE_TIER_0",
  dateFrom: "2022-01-01",
  dateTo: dayjs().format(DATE_FORMAT),
  gridUpperPriceCheck: false,
  gridUpperPrice: 0,
  gridLowerPriceCheck: false,
  gridLowerPrice: 0,
};

const ORDER_VALUE = {
  CZK: 5000,
  EUR: 200,
};

export const COINMATE_FEE = [0.4, 0.2, 0.12, 0.09, 0.05, 0.03, 0.02, 0];
export const COINMATE_FEE_VOLUME = [
  "default",
  "10 000 EUR",
  "100 000 EUR",
  "250 000 EUR",
  "500 000 EUR",
  "1 000 000 EUR",
  "3 000 000 EUR",
  "15 000 000 EUR",
];

const depsEqual = (prevDeps: any[], nextDeps: any[]) => {
  const prevValues = prevDeps[0];
  const nextValues = nextDeps[0];

  if (prevValues?.exchangeEnum !== nextValues?.exchangeEnum) return false;
  if (prevValues?.exchangeFeeTier !== nextValues?.exchangeFeeTier) return false;
  if (prevValues?.currencyPair !== nextValues?.currencyPair) return false;
  if (prevValues?.gridPercentStep !== nextValues?.gridPercentStep) return false;
  if (prevValues?.gridOrderValue !== nextValues?.gridOrderValue) return false;
  if (prevValues?.dateFrom !== nextValues?.dateFrom) return false;
  if (prevValues?.dateTo !== nextValues?.dateTo) return false;

  if (prevValues?.gridUpperPriceCheck) {
    if (prevValues?.gridUpperPrice !== nextValues?.gridUpperPrice) return false;
  } else return false;

  if (prevValues?.gridLowerPriceCheck) {
    if (prevValues?.gridLowerPrice !== nextValues?.gridLowerPrice) return false;
  } else return false;

  return true;
};

const getNumber = (value: string | number) => {
  return Number(`${value}`.replace(",", "."))
}

const BacktestGridBot = () => {
  const { colors } = useMUIThemeModeContext();
  const theme = useTheme();
  const { t } = useTranslation<string>();
  const [isLoading] = useState<boolean>(false);
  const [backtestData, setBacktestData] = useState<IBacktest>();
  const { enqueueSnackbar } = useSnackbar();
  const { data: currencyPairs } = useCurrencyPairs();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isLoggedIn = useIsLogin();

  const [chartLoading, setChartLoading] = useState<boolean>(false);
  const [priceLines, setPriceLines] = useState<PriceLineOptions[]>([]);
  const [markers, setMarkers] = useState<SeriesMarker<Time>[]>([]);
  const [range, setRange] = useState<TimeRange>();
  const [priceRange, setPriceRange] = useState<IPriceRange>();

  const validationGridOrderValue = (value?: string, context?: yup.TestContext<any>) => {
    if (value && context) {
      const val = Number(value.replace(",", "."));
      const min = gridStrategyLimits?.min
        ? new Big(gridStrategyLimits?.min).round(counterCurrDisplayedScale, Big.roundDown).toNumber()
        : undefined;
      const max = gridStrategyLimits?.max
        ? new Big(gridStrategyLimits?.max).round(counterCurrDisplayedScale, Big.roundDown).toNumber()
        : undefined;

      if (max && val > max) {
        return context.createError({
          message: t("gridBot.create.maxGridOrderValueFallback", { value: max, currency: counterCurrency }),
        });
      }

      if (min && val < min) {
        return context.createError({
          message: t("gridBot.create.minGridOrderValueFallback", { value: min, currency: counterCurrency }),
        });
      }
    }

    return true;
  };

  const validationGridUpperPriceValue = (value?: string, context?: yup.TestContext<any>) => {
    if (value && context) {
      const val = Number(value.replace(",", "."));
      const min = backtestData?.start.price ?? 99999999999999;

      if (val < min) {
        return context.createError({
          message: t("backtestGridBot.inputs.minGridUpperPrice", { value: min }),
        });
      }
    }

    return true;
  };

  const validationGridLowerPrice = (value?: string, context?: yup.TestContext<any>) => {
    if (value && context) {
      const val = Number(value.replace(",", "."));
      const max = backtestData?.start.price ?? 0;

      if (val > max) {
        return context.createError({
          message: t("backtestGridBot.inputs.maxGridLowerPrice", { value: max }),
        });
      }
    }

    return true;
  };

  const defaultSchema = {
    exchangeEnum: yup.string().required(t("fieldRequired")),
    exchangeFeeTier: yup.string().when("exchangeEnum", {
      is: ExchangeType.COINMATE,
      then: yup.string().required(t("fieldRequired")),
    }),
    currencyPair: yup.string().required(t("fieldRequired")),
    gridUpperPriceCheck: yup.bool(),
    gridUpperPrice: yup.string().when("gridUpperPriceCheck", {
      is: true,
      then: yup
        .string()
        .required(t("fieldRequired"))
        .test("gridUpperPrice", "gridUpperPriceError", validationGridUpperPriceValue),
    }),
    gridLowerPriceCheck: yup.bool(),
    gridLowerPrice: yup.string().when("gridLowerPriceCheck", {
      is: true,
      then: yup
        .string()
        .required(t("fieldRequired"))
        .test("gridLowerPrice", "gridLowerPriceError", validationGridLowerPrice),
    }),
    gridPercentStep: yup.string().required(t("fieldRequired")),
    gridOrderValue: yup
      .string()
      .required(t("fieldRequired"))
      .test("gridOrderValue", "gridOrderValueError", validationGridOrderValue),
    dateFrom: yup.string().required(t("fieldRequired")),
    dateTo: yup.string().required(t("fieldRequired")),
  };
  const formik = useFormik({
    initialValues: initValues,
    validationSchema: yup.object(defaultSchema),
    onSubmit: () => undefined,
  });

  const { currencyPairPriceScale, counterCurrDisplayedScale, gridStrategyLimits, baseCurrDisplayedScale }
    = useExchangesMetadata(formik.values.exchangeEnum, formik.values.currencyPair);
  const { counterCurrency, baseCurrency } = splitCurrencyPair(formik.values.currencyPair);
  const { data: OHLCData } = useAPIOHLC(
    formik.values.currencyPair,
    OHLCInterval.ONE_DAY,
    formik.values.exchangeEnum,
    15000
  );

  useEffect(() => {
    if (formik.values.exchangeEnum && formik.values.exchangeEnum.length && formik.touched.exchangeEnum) {
      formik.setFieldValue("currencyPair", DEFAULT_CURRENCY_PAIR);
    }
  }, [formik.values.exchangeEnum]);

  useEffect(() => {
    if (formik.values.currencyPair) {
      setChartLoading(true);
    }
  }, [formik.values.currencyPair]);

  useEffect(() => {
    if (counterCurrency) {
      formik.setFieldValue("gridOrderValue", ORDER_VALUE[counterCurrency as keyof typeof ORDER_VALUE]);
    }
  }, [counterCurrency]);

  useEffect(() => {
    if (counterCurrency && baseCurrency) {
      formik.setFieldTouched("gridUpperPrice", false);
      formik.setFieldTouched("gridLowerPrice", false);
    }
  }, [counterCurrency, baseCurrency]);

  useCustomCompareEffect(() => {
    handleCall();
  }, [formik.values], depsEqual);

  useEffect(() => {
    if (backtestData && backtestData.priceLevels) {
      if (!formik.values.gridLowerPriceCheck) {
        const min = Math.min(...backtestData.priceLevels);
        formik.setFieldValue("gridLowerPrice", min);
      }
      if (!formik.values.gridUpperPriceCheck) {
        const max = Math.max(...backtestData.priceLevels);
        formik.setFieldValue("gridUpperPrice", max);
      }
    }
  }, [backtestData]);

  useEffect(() => {
    getPriceLines();
  }, [formik.values.gridUpperPrice, formik.values.gridLowerPrice, backtestData?.start?.price, backtestData?.priceLevels]);

  useEffect(() => {
    getPriceLines();
    getMarkers();
  }, [theme.palette.mode]);

  const isDateValid = (dateFrom: string, dateTo: string) => {
    const dayjsDateFrom = dayjs(dateFrom);
    const dayjsDateTo = dayjs(dateTo);

    if (!dayjsDateFrom.isValid() || !dayjsDateTo.isValid()) return false;
    if (!dayjsDateFrom.isBefore(dayjsDateTo)) {
      if(!dayjsDateFrom.isSame(dayjsDateTo)) return false;
    }

    return true;
  };

  const getMarkers = () => {
    if (!isDateValid(formik.values.dateFrom, formik.values.dateTo)) return;

    const newMarkers = [];
    const dayjsDateFrom = dayjs(formik.values.dateFrom);
    const dayjsDateTo = dayjs(formik.values.dateTo);

    //Markers
    newMarkers.push({
      time: { year: dayjsDateFrom.year(), month: dayjsDateFrom.month() + 1, day: dayjsDateFrom.date() - 1 },
      position: "belowBar" as const,
      color: getColorByMode(theme, "blue", "cornflowerblue"),
      shape: "arrowUp" as const,
      text: t("backtestGridBot.chart.startMarker"),
    });
    newMarkers.push({
      time: { year: dayjsDateTo.year(), month: dayjsDateTo.month() + 1, day: dayjsDateTo.date() - 1 },
      position: "belowBar" as const,
      color: getColorByMode(theme, "blue", "cornflowerblue"),
      shape: "arrowUp" as const,
      text: t("backtestGridBot.chart.endMarker"),
    });

    setMarkers(newMarkers);

    //range
    const dateFrom = dayjsDateFrom.subtract(10, "day");
    const dateTo = dayjsDateTo.add(10, "day");

    setRange({
      from: { year: dateFrom.year(), month: dateFrom.month() + 1, day: dateFrom.date() - 1 },
      to: { year: dateTo.year(), month: dateTo.month() + 1, day: dateTo.date() - 1 },
    });
  };

  const handleCall = async () => {
    const form = await formik.validateForm();

    if (Object.keys(form).length !== 0) return;

    try {
      const {
        dateFrom,
        dateTo,
        exchangeFeeTier,
        gridLowerPrice,
        gridLowerPriceCheck,
        gridUpperPrice,
        gridUpperPriceCheck,
        ...rest
      } = formik.values;

      if (!isDateValid(dateFrom, dateTo)) return;

      const data: IBacktest = (
        await getGridBotBacktest({
          ...rest,
          dateFrom,
          dateTo,
          exchangeFeeTier: formik.values.exchangeEnum === ExchangeType.COINMATE ? exchangeFeeTier : undefined,
          gridLowerPrice: gridLowerPriceCheck ? getNumber(gridLowerPrice) : undefined,
          gridUpperPrice: gridUpperPriceCheck ? getNumber(gridUpperPrice) : undefined,
        })
      ).data;

      if (data.priceLevels.length > 1) {
        setBacktestData(data);
        getMarkers();
        chartLoading && setChartLoading(false);
      } else {
        enqueueSnackbar(t("backtestGridBot.priceLevelWarning"), { variant: "warning" });
      }
    } catch (error) {
      enqueueSnackbar(t("commonError"), { variant: "error" });
    }
  };

  const getCurrencyPairsTicker = () => {
    const exEnum = formik.values.exchangeEnum;
    const exCurrPairs = currencyPairs.find((pairs) => pairs?.id === exEnum);

    const gridCurrPairs = exCurrPairs?.data?.filter(
      (pair: any) => pair?.enabled && pair.grid_strategy_subscription_type
    );

    return gridCurrPairs?.map((el: any) => (
      <MenuItem key={el?.key} value={el?.key}>
        {ResponsiveLabel(el?.key)}
      </MenuItem>
    ));
  };

  const handleChangeExchangeEnum = (event: SelectChangeEvent<any>) => {
    formik.setFieldTouched("exchangeEnum", true);
    formik.handleChange(event);
  };

  const isLowerUpperPriceValid = () => {
    const startPrice = backtestData?.start.price ?? 0;

    if (formik.values.gridUpperPriceCheck && getNumber(formik.values.gridUpperPrice) < startPrice) return false;
    if (formik.values.gridLowerPriceCheck && getNumber(formik.values.gridLowerPrice) > startPrice) return false;

    return true;
  };

  const getPriceLines = () => {
    if (isLowerUpperPriceValid()) {
      const result = [];
      if (formik.values.gridUpperPrice) {
        result.push({
          price: getNumber(formik.values.gridUpperPrice),
          color: getColorByMode(theme, colors.black, "#808080"),
          lineWidth: 3 as LineWidth,
          lineStyle: 0,
          axisLabelVisible: true,
          lineVisible: true,
          title: "",
        });
      }
      if (formik.values.gridLowerPrice) {
        result.push({
          price: getNumber(formik.values.gridLowerPrice),
          color: getColorByMode(theme, colors.black, "#808080"),
          lineWidth: 3 as LineWidth,
          lineStyle: 0,
          axisLabelVisible: true,
          lineVisible: true,
          title: "",
        });
      }
      if (backtestData?.priceLevels) {
        backtestData?.priceLevels.forEach((level) => {
          const isStartPriceLevel = backtestData?.start?.price === level;

          result.push({
            price: level,
            color: isStartPriceLevel ? getColorByMode(theme, "blue", "cornflowerblue") : "gray",
            lineWidth: isStartPriceLevel ? 2 : 1,
            lineStyle: isStartPriceLevel ? 0 : 3,
            axisLabelVisible: isStartPriceLevel,
            lineVisible: true,
            title: "",
          });
        });
      }

      setPriceLines(result);
      setPriceRange({
        minValue: getNumber(formik.values.gridLowerPrice),
        maxValue: getNumber(formik.values.gridUpperPrice),
      });
    }
  };

  const chartSection = (height?: number) => {
    if (!OHLCData || isEmptyArray(OHLCData.ohlc) || chartLoading) {
      return (
        <Box height={height ? `${height}px` : "100%"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
          <CircularProgress color="primary" />
        </Box>
      );
    }

    return (
      <Tile sx={{ py: 2, px: 1, height: height ? `${height}px` : "100%" }}>
        <CandleChart
          data={convertOHLCData(OHLCData)}
          priceLines={priceLines}
          markers={markers}
          pricePrecision={currencyPairPriceScale}
          priceRange={priceRange}
          priceMargins={0.1}
          dateWithTime={false}
          range={range}
        />
      </Tile>
    );
  };

  const handleChangeDate = (key: string) => (newValue: Dayjs | null) => {
    formik.setFieldValue(key, newValue ? newValue?.format(DATE_FORMAT) : newValue);
  };

  const getExchangeFeeLabel = (fee: number, index: number) => {
    const volume = COINMATE_FEE_VOLUME[index];

    return t("backtestGridBot.inputs.exchangeFeeValue", {
      fee: `${fee}`.replace(".", ","),
      description:
        volume === "default"
          ? t("backtestGridBot.inputs.exchangeFeeVolume.default")
          : t("backtestGridBot.inputs.exchangeFeeVolume.volume", { volume }),
    });
  };

  const getAlert = () => {
    switch (formik.values.gridPercentStep) {
      case 2:
        return "2";
      case 1.5:
        return "1-5";
      case 1:
        return "1";
      case 0.5:
        return "0-5";
      default:
        return "2-5";
    }
  };

  return (
    <Tile isMain={true} header={t("backtestGridBot.title")} m={{ xs: -2, md: 0 }}>
      <PageTitle i18nKey="backtestGridBot.meta_title" />
      <Box p={2}>
        <Stack spacing={2}>
          <Grid container spacing={2}>
            <Grid item lg={4} xs={12}>
              <Stack spacing={2}>
                <Select
                  id="exchangeEnum"
                  name="exchangeEnum"
                  label={ResponsiveLabel(t("backtestGridBot.inputs.exchange"))}
                  value={formik.values.exchangeEnum ?? ""}
                  onChange={handleChangeExchangeEnum}
                  disabled={isLoading}
                  error={formik.touched.exchangeEnum && formik.errors.exchangeEnum}>
                  <MenuItem value={ExchangeType.COINMATE}>{ResponsiveLabel("Coinmate")}</MenuItem>
                  <MenuItem value={ExchangeType.ANYCOIN}>{ResponsiveLabel("Anycoin")}</MenuItem>
                </Select>
                <Select
                  id="currencyPair"
                  name="currencyPair"
                  label={ResponsiveLabel(t("gridBot.create.currencyPair"))}
                  value={formik.values.currencyPair ?? ""}
                  onChange={formik.handleChange}
                  disabled={!currencyPairs || isLoading}
                  error={formik.touched.currencyPair && formik.errors.currencyPair}>
                  {getCurrencyPairsTicker()}
                </Select>
                <LocalizedDatePicker
                  label={t("backtestGridBot.inputs.dateFrom")}
                  value={dayjs(formik.values.dateFrom)}
                  onChange={handleChangeDate("dateFrom")}
                  disabled={isLoading}
                  minDate={dayjs("2019-01-01")}
                  maxDate={dayjs(formik.values.dateTo)}
                  disableMaskedInput
                />
                <LocalizedDatePicker
                  label={t("backtestGridBot.inputs.dateTo")}
                  value={dayjs(formik.values.dateTo)}
                  onChange={handleChangeDate("dateTo")}
                  disabled={isLoading}
                  minDate={dayjs(formik.values.dateFrom)}
                  maxDate={dayjs()}
                  disableMaskedInput
                />
                <Stack style={{ marginTop: "0.4em" }} spacing={0.4}>
                  <FormControlLabel
                    control={<Checkbox />}
                    id={"gridUpperPriceCheck"}
                    name={"gridUpperPriceCheck"}
                    sx={{
                      mr: 0,
                      "& .MuiFormControlLabel-label": {
                        width: "100%",
                      },
                    }}
                    label={
                      <Stack width={"100%"} direction={"row"} justifyContent={"space-between"}>
                        <Box>
                          {t("backtestGridBot.inputs.adjustRange", { input: t("gridBot.create.gridUpperPrice").toLowerCase() })}
                        </Box>
                        <Tooltip arrow placement="top" title={t("backtestGridBot.inputs.gridUpperPriceCheckTooltip") ?? ""}>
                          <HelpOutlineIcon sx={{ color: colors.slate500 }} />
                        </Tooltip>
                      </Stack>
                    }
                    checked={formik.values.gridUpperPriceCheck}
                    onChange={formik.handleChange}
                  />
                  <PriceField
                    autoComplete="off"
                    onlyPositive
                    precision={2}
                    fullWidth={true}
                    id="gridUpperPrice"
                    name="gridUpperPrice"
                    label={t("gridBot.create.gridUpperPrice")}
                    value={formik.values.gridUpperPrice}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={Boolean(formik.errors.gridUpperPrice)}
                    helperText={formik.errors.gridUpperPrice}
                    disabled={isLoading || !formik.values.gridUpperPriceCheck}
                    InputProps={{
                      endAdornment: <InputAdornment position="start">{counterCurrency}</InputAdornment>,
                    }}
                  />
                </Stack>
                <Stack style={{ marginTop: "0.4em" }} spacing={0.4}>
                  <FormControlLabel
                    control={<Checkbox />}
                    id={"gridLowerPriceCheck"}
                    name={"gridLowerPriceCheck"}
                    sx={{
                      mr: 0,
                      "& .MuiFormControlLabel-label": {
                        width: "100%",
                      },
                    }}
                    label={
                      <Stack width={"100%"} direction={"row"} justifyContent={"space-between"}>
                        <Box>
                          {t("backtestGridBot.inputs.adjustRange", { input: t("gridBot.create.gridLowerPrice").toLowerCase() })}
                        </Box>
                        <Tooltip arrow placement="top" title={t("backtestGridBot.inputs.gridLowerPriceCheckTooltip") ?? ""}>
                          <HelpOutlineIcon sx={{ color: colors.slate500 }} />
                        </Tooltip>
                      </Stack>
                    }
                    checked={formik.values.gridLowerPriceCheck}
                    onChange={formik.handleChange}
                  />
                  <PriceField
                    autoComplete="off"
                    onlyPositive
                    precision={2}
                    fullWidth={true}
                    id="gridLowerPrice"
                    name="gridLowerPrice"
                    label={t("gridBot.create.gridLowerPrice")}
                    value={formik.values.gridLowerPrice}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={Boolean(formik.errors.gridLowerPrice)}
                    helperText={formik.errors.gridLowerPrice}
                    disabled={isLoading || !formik.values.gridLowerPriceCheck}
                    InputProps={{
                      endAdornment: <InputAdornment position="start">{counterCurrency}</InputAdornment>,
                    }}
                  />
                </Stack>
                {formik.values.exchangeEnum === ExchangeType.COINMATE && (
                  <Stack>
                    <Typography variant="caption" pb={2}>
                      <Trans
                        i18nKey="backtestGridBot.inputs.exchangeFeeDescription"
                        components={{
                          a: (
                            <Link color={theme.palette.info.main} href="https://coinmate.io/cs/fees" target="_blank" />
                          ),
                        }}
                      />
                    </Typography>
                    <Select
                      id="exchangeFeeTier"
                      name="exchangeFeeTier"
                      label={ResponsiveLabel(t("backtestGridBot.inputs.exchangeFee"))}
                      value={formik.values.exchangeFeeTier ?? ""}
                      onChange={formik.handleChange}
                      error={formik.touched.exchangeFeeTier && formik.errors.exchangeFeeTier}>
                      {COINMATE_FEE.map((fee, index) => (
                        <MenuItem key={`exchangeFeeTier-${index}`} value={`COINMATE_TIER_${index}`}>
                          {ResponsiveLabel(getExchangeFeeLabel(fee, index))}
                        </MenuItem>
                      ))}
                    </Select>
                  </Stack>
                )}
              </Stack>
            </Grid>
            <Grid item lg={8} xs={12}>
              {chartSection(350)}
              <Stack pt={2} spacing={2} direction={{ lg: "row", xs: "column" }}>
                <Stack width={{ lg: "50%", xs: "100%" }} spacing={2} justifyContent={"space-between"} height={"auto"}>
                  <Tile
                    sx={{ height: "100%" }}
                    header={t("gridBot.create.gridSetting.orderDistance.title")}
                    actions={
                      <Tooltip
                        arrow
                        placement="top"
                        title={t("gridBot.create.gridSetting.orderDistance.tooltip") ?? ""}>
                        <HelpOutlineIcon sx={{ color: colors.slate500 }} />
                      </Tooltip>
                    }>
                    <Typography color={"#525252"} px={3} pt={2} variant="body2">
                      {t("gridBot.create.gridSetting.orderDistance.description")}
                    </Typography>
                    <PercentStepInput
                      id="gridPercentStep"
                      value={formik.values.gridPercentStep}
                      setFieldValue={formik.setFieldValue}
                      disabled={isLoading}
                    />
                  </Tile>
                </Stack>
                <Stack width={{ lg: "50%", xs: "100%" }} spacing={2} justifyContent={"space-between"} height={"100%"}>
                  <Tile
                    sx={{ height: "100%" }}
                    header={t("gridBot.create.gridSetting.orderValue.title")}
                    actions={
                      <Tooltip arrow placement="top" title={t("gridBot.create.gridSetting.orderValue.tooltip") ?? ""}>
                        <HelpOutlineIcon sx={{ color: colors.slate500 }} />
                      </Tooltip>
                    }>
                    <Typography color={"#525252"} px={3} pt={2} variant="body2">
                      {t("gridBot.create.gridSetting.orderValue.description")}
                    </Typography>
                    <OrderValueInput
                      precision={counterCurrDisplayedScale}
                      formik={formik}
                      disabled={isLoading}
                      counterCurrency={counterCurrency}
                      gridStrategyLimits={gridStrategyLimits}
                    />
                  </Tile>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
          <Alert severity="info" sx={{ "@media (max-width:400px)": { fontSize: ".8rem" } }}>
            <Trans
              i18nKey={`backtestGridBot.alert.${getAlert()}`}
              values={{
                coinmate:
                  formik.values.exchangeEnum === ExchangeType.COINMATE
                    ? t("backtestGridBot.alert.coinmate")
                    : undefined,
              }}
              components={{
                a: <Link href={isLoggedIn ? "/predplatne" : "/cenik"} color={theme.palette.info.main} />,
                a2: <Link href="https://coinmate.io/cs/fees" target="_blank" color={theme.palette.info.main} />,
              }}
            />
            <Box pt={1}>
              <Trans
                i18nKey={"backtestGridBot.alert.tutorial"}
                components={{
                  a: (
                    <Link
                      href={"https://stosuj.cz/proc-bitcoin/predstaveni-grid-strategie"}
                      target="_blank"
                      color={theme.palette.info.main}
                    />
                  ),
                }}
              />
            </Box>
          </Alert>
          <Stack direction={{ xs: "column", lg: "row" }} spacing={2}>
            <Box width={{ xs: "100%", lg: "50%" }}>
              <Tile header={t("backtestGridBot.performance")} height={"100%"}>
                <TableContainer sx={{ padding: "8px 8px 12px 8px" }}>
                  <Table size="small">
                    <TableBody>
                      <TableRow
                        sx={{
                          "& td, & th": {
                            border: 0,
                            fontSize: isMobile ? "12px" : undefined,
                            p: isMobile ? 0.5 : undefined,
                          },
                          verticalAlign: "top",
                        }}>
                        <TableCell padding={"normal"} component="th" scope="row">
                          {t("backtestGridBot.totalProfit")}
                        </TableCell>
                        <TableCell padding={"normal"} sx={{ fontFamily: "monospace", fontWeight: 600 }} align="right">
                          {`${renderNumber(
                            backtestData?.end.baseCurrencyProfitAmount ?? 0,
                            baseCurrDisplayedScale,
                            true
                          )}\u00a0${baseCurrency}`}
                        </TableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          "& td, & th": {
                            border: 0,
                            fontSize: isMobile ? "12px" : undefined,
                            p: isMobile ? 0.5 : undefined,
                          },
                          verticalAlign: "top",
                        }}>
                        <TableCell padding={"normal"} sx={{ fontStyle: "italic" }} component="th" scope="row">
                          <Trans
                            i18nKey={"backtestGridBot.baseCurrencyValueInCounterCurrency"}
                            values={{
                              baseCurrency: baseCurrency,
                              counterCurrency: counterCurrency,
                              price: renderNumber(
                                backtestData?.end.price ?? 0,
                                currencyPairPriceScale,
                                true
                              ),
                            }}
                          />
                        </TableCell>
                        <TableCell padding={"normal"} sx={{ fontStyle: "italic", fontFamily: "monospace" }} align="right">
                          {`~ ${renderNumber(
                            backtestData?.end.baseCurrencyProfitValue ?? 0,
                            counterCurrDisplayedScale,
                            true
                          )}\u00a0${counterCurrency}`}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Tile>
            </Box>
            <Box width={{ xs: "100%", lg: "50%" }}>
              <Tile header={t("backtestGridBot.ordersCount")} height={"100%"}>
                <TableContainer sx={{ padding: "8px 8px 12px 8px" }}>
                  <Table size="small">
                    <TableBody>
                      <TableRow
                        sx={{
                          "& td, & th": {
                            border: 0,
                            fontSize: isMobile ? "12px" : undefined,
                            p: isMobile ? 0.5 : undefined,
                          },
                          verticalAlign: "top",
                        }}>
                        <TableCell padding={"normal"} component="th" scope="row">
                          {t("backtestGridBot.buyOrdersCount")}
                        </TableCell>
                        <TableCell padding={"normal"} sx={{ fontFamily: "monospace", fontWeight: 600 }} align="right">
                          {backtestData?.buyOrdersCount}
                        </TableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          "& td, & th": {
                            border: 0,
                            fontSize: isMobile ? "12px" : undefined,
                            p: isMobile ? 0.5 : undefined,
                          },
                          verticalAlign: "top",
                        }}>
                        <TableCell padding={"normal"} component="th" scope="row">
                          {t("backtestGridBot.sellOrdersCount")}
                        </TableCell>
                        <TableCell padding={"normal"} sx={{ fontFamily: "monospace", fontWeight: 600 }} align="right">
                          {backtestData?.sellOrdersCount}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Tile>
            </Box>
          </Stack>
          <Stack direction={{ xs: "column", lg: "row" }} spacing={2}>
            <Box width={{ xs: "100%", lg: "50%" }}>
              <Tile
                header={t("backtestGridBot.startState", { date: dayjs(formik.values.dateFrom).format("D. M. YYYY") })}
                height={"100%"}>
                <TableContainer sx={{ padding: "8px 8px 12px 8px" }}>
                  <Table size="small">
                    <TableBody>
                      <TableRow
                        sx={{
                          "& td, & th": {
                            border: 0,
                            fontSize: isMobile ? "12px" : undefined,
                            p: isMobile ? 0.5 : undefined,
                          },
                          verticalAlign: "top",
                        }}>
                        <TableCell padding={"normal"} component="th" scope="row">
                          {t("backtestGridBot.startCounterCurrencyAmount")}
                        </TableCell>
                        <TableCell padding={"normal"} sx={{ fontFamily: "monospace", fontWeight: 600 }} align="right">
                          {`${renderNumber(
                            backtestData?.start.counterCurrencyAmount ?? 0,
                            counterCurrDisplayedScale,
                            true
                          )}\u00a0${counterCurrency}`}
                        </TableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          "& td, & th": {
                            border: 0,
                            fontSize: isMobile ? "12px" : undefined,
                            p: isMobile ? 0.5 : undefined,
                          },
                          verticalAlign: "top",
                        }}>
                        <TableCell padding={"normal"} component="th" scope="row">
                          {t("backtestGridBot.startBaseCurrencyAmount")}
                        </TableCell>
                        <TableCell padding={"normal"} sx={{ fontFamily: "monospace", fontWeight: 600 }} align="right">
                          {`${renderNumber(
                            backtestData?.start.baseCurrencyAmount ?? 0,
                            baseCurrDisplayedScale,
                            true
                          )}\u00a0${baseCurrency}`}
                        </TableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          "& td, & th": {
                            border: 0,
                            fontSize: isMobile ? "12px" : undefined,
                            p: isMobile ? 0.5 : undefined,
                          },
                          verticalAlign: "top",
                        }}>
                        <TableCell padding={"normal"} sx={{ fontStyle: "italic" }} component="th" scope="row">
                          <Trans
                            i18nKey={"backtestGridBot.baseCurrencyValueInCounterCurrency"}
                            values={{
                              baseCurrency: baseCurrency,
                              counterCurrency: counterCurrency,
                              price: renderNumber(backtestData?.start.price ?? 0, currencyPairPriceScale, true),
                            }}
                          />
                        </TableCell>
                        <TableCell padding={"normal"} sx={{ fontStyle: "italic", fontFamily: "monospace" }} align="right">
                          {`~ ${renderNumber(
                            backtestData?.start.baseCurrencyValue ?? 0,
                            counterCurrDisplayedScale,
                            true
                          )}\u00a0${counterCurrency}`}
                        </TableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          "& td, & th": {
                            border: 0,
                            fontSize: isMobile ? "12px" : undefined,
                            p: isMobile ? 0.5 : undefined,
                            pt: 3,
                          },
                          verticalAlign: "top",
                        }}>
                        <TableCell padding={"normal"} sx={{ fontWeight: 600 }} component="th" scope="row">
                          {t("backtestGridBot.startCounterCurrencyTotalValue")}
                        </TableCell>
                        <TableCell padding={"normal"} sx={{ fontFamily: "monospace", fontWeight: 600 }} align="right">
                          {`${renderNumber(
                            backtestData?.start.counterCurrencyTotalValue ?? 0,
                            counterCurrDisplayedScale,
                            true
                          )}\u00a0${counterCurrency}`}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Tile>
            </Box>
            <Box width={{ xs: "100%", lg: "50%" }}>
              <Tile
                header={t("backtestGridBot.endState", { date: dayjs(formik.values.dateTo).format("D. M. YYYY") })}
                height={"100%"}>
                <TableContainer sx={{ padding: "8px 8px 12px 8px" }}>
                  <Table size="small">
                    <TableBody>
                      <TableRow
                        sx={{
                          "& td, & th": {
                            border: 0,
                            fontSize: isMobile ? "12px" : undefined,
                            p: isMobile ? 0.5 : undefined,
                          },
                          verticalAlign: "top",
                        }}>
                        <TableCell padding={"normal"} component="th" scope="row">
                          {t("backtestGridBot.endCounterAndBaseCurrencyAmount")}
                        </TableCell>
                        <TableCell padding={"normal"} sx={{ fontFamily: "monospace", fontWeight: 600 }} align="right">
                          {`${renderNumber(
                            backtestData?.end.counterCurrencyAmount ?? 0,
                            counterCurrDisplayedScale,
                            true
                          )}\u00a0${counterCurrency}`}
                        </TableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          "& td, & th": {
                            border: 0,
                            fontSize: isMobile ? "12px" : undefined,
                            p: isMobile ? 0.5 : undefined,
                          },
                          verticalAlign: "top",
                        }}>
                        <TableCell padding={"normal"} component="th" scope="row">
                          {t("backtestGridBot.endCounterAndBaseCurrencyAmount")}
                        </TableCell>
                        <TableCell padding={"normal"} sx={{ fontFamily: "monospace", fontWeight: 600 }} align="right">
                          {`${renderNumber(
                            backtestData?.end.baseCurrencyTotalAmount ?? 0,
                            baseCurrDisplayedScale,
                            true
                          )}\u00a0${baseCurrency}`}
                        </TableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          "& td, & th": {
                            border: 0,
                            fontSize: isMobile ? "12px" : undefined,
                            p: isMobile ? 0.5 : undefined,
                          },
                          verticalAlign: "top",
                        }}>
                        <TableCell padding={"normal"} sx={{ fontStyle: "italic" }} component="th" scope="row">
                          <Trans
                            i18nKey={"backtestGridBot.baseCurrencyValueInCounterCurrency"}
                            values={{
                              baseCurrency: baseCurrency,
                              counterCurrency: counterCurrency,
                              price: renderNumber(
                                backtestData?.end.price ?? 0,
                                currencyPairPriceScale,
                                true
                              ),
                            }}
                          />
                        </TableCell>
                        <TableCell padding={"normal"} sx={{ fontStyle: "italic", fontFamily: "monospace" }} align="right">
                          {`~ ${renderNumber(
                            backtestData?.end.baseCurrencyTotalValue ?? 0,
                            counterCurrDisplayedScale,
                            true
                          )}\u00a0${counterCurrency}`}
                        </TableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          "& td, & th": {
                            border: 0,
                            fontSize: isMobile ? "12px" : undefined,
                            p: isMobile ? 0.5 : undefined,
                            pt: 3,
                          },
                          verticalAlign: "top",
                        }}>
                        <TableCell padding={"normal"} sx={{ fontWeight: 600 }} component="th" scope="row">
                          {t("backtestGridBot.endCounterCurrencyTotalValue")}
                        </TableCell>
                        <TableCell padding={"normal"} sx={{ fontFamily: "monospace", fontWeight: 600 }} align="right">
                          {`${renderNumber(
                            backtestData?.end.counterCurrencyTotalValue ?? 0,
                            counterCurrDisplayedScale,
                            true
                          )}\u00a0${counterCurrency}`}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Tile>
            </Box>
          </Stack>
        </Stack>
      </Box>
    </Tile>
  );
};

export default BacktestGridBot;
