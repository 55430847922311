import { Alert, Box, Divider, Typography, useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import useMediaQuery from "@mui/material/useMediaQuery";
import Card from "components/elements/Card";
import useExchangesMetadata from "hooks/useExchangesMetadata";
import { ErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import { IDcaBotRun, IInvestmentChart, IPriceChart, IRunsListOrder } from "types/types";

import Chart from "./chart";
import ErrorFallback from "./ErrorFallback";
import ErrorPriceChartFallback from "./ErrorPriceChartFallback";
import PriceChart from "./priceChart";

interface Props {
  chartData?: IInvestmentChart;
  priceChartData?: IPriceChart;
  dcaBotRuns: IDcaBotRun[];
  baseCurrency: string;
  counterCurrency: string;
  currencyPair: string;
  exchangeEnum?: string;
  targetValuePrice?: number;
  setTargetValueModalOpen: () => void;
}

const ChartHistory = ({
  chartData,
  priceChartData,
  dcaBotRuns,
  baseCurrency,
  counterCurrency,
  currencyPair,
  exchangeEnum,
  targetValuePrice,
  setTargetValueModalOpen,
}: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { baseCurrDisplayedScale, counterCurrDisplayedScale } = useExchangesMetadata(exchangeEnum ?? "", currencyPair);

  const orders: IRunsListOrder[] = [];
  dcaBotRuns.forEach((item: IDcaBotRun) => {
    const filledOrders = item.orders.filter((order) => order.status === "FILLED");
    filledOrders.forEach((filledOrder) => orders.push(filledOrder));
  });
  const ordersSorted = orders.sort((a, b) => Date.parse(a.updatedAt) - Date.parse(b.updatedAt));
  const dataAvailable = ordersSorted.length >= 2;

  return (
    <Card
      header={t("chartHistory.title")}
      sx={{ mb: 0 }}
      actions={
        dataAvailable ? (
          <Button color="info" onClick={setTargetValueModalOpen} variant="outlined" size="small">
            {t("chartHistory.targetValue.ctaEdit")}
          </Button>
        ) : undefined
      }>
      {dataAvailable && chartData ? (
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <Chart
                chartData={chartData}
                isMobile={isMobile}
                baseCurrency={baseCurrency}
                counterCurrency={counterCurrency}
                baseCurrDisplayedScale={baseCurrDisplayedScale}
                counterCurrDisplayedScale={counterCurrDisplayedScale}
                targetValuePrice={targetValuePrice}
              />
            </ErrorBoundary>
          </Grid>
        </Grid>
      ) : (
        <Alert severity="info">{t("chartHistory.notEnoughData")}</Alert>
      )}
      <Typography variant="h6" component="h1" fontWeight={600} sx={{ color: "tertiary.main", py: 3 }}>
        {t("priceChart.title")}
      </Typography>
      <Divider sx={{ mb: 1 }} />
      {priceChartData ? (
        <Box alignItems="center">
          <ErrorBoundary FallbackComponent={ErrorPriceChartFallback}>
            <PriceChart
              chartData={priceChartData}
              isMobile={isMobile}
              counterCurrency={counterCurrency}
              counterCurrDisplayedScale={counterCurrDisplayedScale}
            />
          </ErrorBoundary>
        </Box>
      ) : (
        <Alert severity="info" sx={{ mt: 3 }}>{t("priceChart.notEnoughData")}</Alert>
      )}
    </Card>
  );
};

export default ChartHistory;
