import React from "react";

import { Box, Divider, Grid, Stack, SxProps, Theme, Typography, alpha, useTheme, TypographyProps } from "@mui/material";
import Panel, { Props as PanelProps } from "components/elements/Panel";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { getAnyByMode } from "utils";

export interface CardProps extends PanelProps {
  header?: React.ReactFragment;
  subheader?: React.ReactFragment;
  actions?: React.ReactFragment;
  divider?: boolean;
  bottomSection?: React.ReactFragment;
  childrenBoxSx?: SxProps<Theme> | undefined;
  boxSx?: SxProps<Theme> | undefined;
  leftActions?: React.ReactFragment;
  headerProps?: TypographyProps;
}

const Card: React.FC<CardProps> = ({
  header,
  subheader,
  actions,
  divider = true,
  shadow,
  bottomSection,
  childrenBoxSx,
  boxSx,
  leftActions,
  headerProps,
  ...props
}) => {
  const { colors } = useMUIThemeModeContext();
  const theme = useTheme();

  return (
    <Panel shadow={shadow} {...props} sx={{ mb: 10, width: "100%", overflow: "hidden", ...props.sx }}>
      <Box sx={{ px: { xs: 2, sm: 4 }, py: { xs: 2, sm: bottomSection ? 3 : 4 }, ...boxSx }}>
        {header || actions ? (
          <>
            <Stack direction={"row"} alignItems={"start"} spacing={0.5}>
              <Grid
                container
                spacing={2}
                sx={{ pb: props.children ? 3 : 0, justifyContent: "space-between", alignItems: "center" }}>
                {header && (
                  <Grid item>
                    <Typography variant="h5" fontWeight={600} sx={{ color: "tertiary.main" }} {...headerProps} >
                      {header}
                    </Typography>
                    {subheader && (
                      <Typography component="h1" variant="body1" fontWeight={500} sx={{ color: "secondary.main" }}>
                        {subheader}
                      </Typography>
                    )}
                  </Grid>
                )}
                {actions && <Grid item>{actions}</Grid>}
              </Grid>
              {leftActions}
            </Stack>
            {divider && <Divider />}
            <Box sx={{ mt: props.children ? 3 : 0, ...childrenBoxSx }}>{props.children}</Box>
          </>
        ) : (
          props.children
        )}
      </Box>
      {bottomSection && (
        <Box
          sx={{
            display: "flex",
            borderTop: `2px solid ${colors.legacyGray50}`,
            justifyContent: "center",
            backgroundColor: alpha(colors.black, getAnyByMode(theme, 0.03, 0.05)),
            py: 2,
            px: { xs: 2 },
          }}>
          {bottomSection}
        </Box>
      )}
    </Panel>
  );
};

export default Card;
