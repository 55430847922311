import { styled } from "@mui/material/styles";

export const FormDiv = styled("div")({
  display: "flex",
  justifyContent: "center",
  width: "100%",
});

export const RegistrationInfo = styled("div")({
  fontSize: "1rem",
  fontWeight: 600,
  textAlign: "center"
});
