import React from "react";

import { Grid, Skeleton } from "@mui/material";

interface IProps {
  canRenderCashbackSats: boolean;
}

const SkeletonItem: React.FC<IProps> = ({ canRenderCashbackSats }) => {
  if (canRenderCashbackSats) {
    return (
      <Grid item xs={12} lg={6} alignItems={"center"} alignContent={"center"}>
        <Skeleton variant="text" height={50} width="100%" />
      </Grid>
    );
  }

  return (
    <>
      <Grid item xs={12} lg={6}>
        <Skeleton variant="text" height={50} width="100%" />
      </Grid>
      <Grid item xs={12} lg={6}>
        <Skeleton variant="text" height={50} width="100%" />
      </Grid>
    </>
  );
};

export default SkeletonItem;
