import { useState, useEffect } from "react";

import { Divider, IconButton, useMediaQuery, useTheme } from "@mui/material";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Card from "components/elements/Card";
import FormToggleButton from "components/elements/FormToggleButton";
import { getMaskedValue } from "components/elements/MaskedModeWrapper";
import { ArrowDown, ArrowUp } from "components/icons";
import { useUserDataContext } from "context/UserDataContext";
import useExchangesMetadata from "hooks/useExchangesMetadata";
import { useTranslation, Trans } from "react-i18next";
import { DcaBotMode, DcaBotRunState, LayoutPosition, OrderTriggering, OrderType } from "types/enums";
import { renderNumber } from "utils/formatter";

import Chart from "./Chart";
import OpenLimitRow from "./OpenLimitRow";
import OrderRow from "./OrderRow";
import IProps from "./types";

const RunsList = ({
  data,
  initialBaseCurrencyAmount,
  initialCounterCurrencyAmount,
  baseCurrency,
  counterCurrency,
  exchangeEnum,
  setInitialValuesModalOpen,
  dcaBotId,
  refreshData,
  position,
  onPositionToggle,
  selectedTriggering,
  onTriggeringChange,
  refreshDetail,
  mode,
  isBrokerage,
  askPrice,
  bidPrice,
  strategyType,
  enabled,
}: IProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobileLayout = useMediaQuery(theme.breakpoints.down("lg"));
  const disableRunsListInfoBox = localStorage.getItem("disableRunsListInfoBox");
  const [isShowAll, setIsShowAll] = useState<boolean>(true);
  const [isInit, setIsInit] = useState<boolean>(false);
  const [order, setOrder] = useState<"asc" | "desc">("desc");
  const [close, setClose] = useState(false);
  const { isMasked } = useUserDataContext();
  const { counterCurrDisplayedScale, currencyPairBaseScale } = useExchangesMetadata(
    exchangeEnum ?? "",
    `${baseCurrency}/${counterCurrency}`
  );

  useEffect(() => {
    if (data && !isInit) {
      setIsShowAll(!(data.dcaBotRuns.length > 5));
      setIsInit(true);
    }
  }, [data, initialBaseCurrencyAmount, initialCounterCurrencyAmount]);

  const getData = () => {
    if (!data) return [];

    const dataWithoutOpenLimitOrders = data.dcaBotRuns
      .filter((dcaBotRun) => {
        return !(
          dcaBotRun.state === DcaBotRunState.OPEN
          && dcaBotRun.triggering === OrderTriggering.INSTANT
          && dcaBotRun.type === OrderType.LIMIT
        );
      })
      .sort((a, b) => {
        const dateA = a.startedAt ?? a.scheduledAt;
        const dateB = b.startedAt ?? b.scheduledAt;

        const timestampA = dateA ? new Date(dateA).getTime() : 0;
        const timestampB = dateB ? new Date(dateB).getTime() : 0;

        if (order === "asc") {
          return timestampA - timestampB;
        }
        return timestampB - timestampA;
      });

    return isShowAll ? dataWithoutOpenLimitOrders : dataWithoutOpenLimitOrders.slice(0, 5);
  };

  const closeInfoBox = () => {
    localStorage.setItem("disableRunsListInfoBox", "true");
    setClose(true);
  };

  const renderOpenLimitOrders = () => {
    if (!data || data?.dcaBotRuns.length === 0) return;

    const openLimitOrders = data.dcaBotRuns
      .filter((dcaBotRun) => {
        return (
          dcaBotRun.state === DcaBotRunState.OPEN
          && dcaBotRun.triggering === OrderTriggering.INSTANT
          && dcaBotRun.type === OrderType.LIMIT
        );
      })
      .sort((a, b) => b?.orders?.[0]?.originalPrice - a?.orders?.[0]?.originalPrice);

    if (openLimitOrders.length === 0) return;

    return (
      <Stack spacing={2} pb={2} mx={{ xs: -1, sm: 0 }}>
        <Typography component="h1" variant="body1" fontWeight={500} sx={{ color: "secondary.main" }}>
          {t("dcaBots.runsList.openLimitTitle")}
        </Typography>
        {openLimitOrders.map((dcaBotRun, index) => (
          <OpenLimitRow
            key={`dcaBotOpenLimit-${dcaBotRun.startedAt}-${index}`}
            dcaBotRun={dcaBotRun}
            baseCurrency={baseCurrency}
            counterCurrency={counterCurrency}
            exchangeEnum={exchangeEnum}
            dcaBotId={dcaBotId}
            refreshData={refreshData}
          />
        ))}
        <Divider />
      </Stack>
    );
  };

  const renderTable = () => {
    if (!data) {
      return (
        <Grid container sx={{ justifyContent: "center", padding: "16px" }}>
          <CircularProgress color="primary" />
        </Grid>
      );
    }

    if (data?.dcaBotRuns.length === 0) {
      return (
        <Grid container sx={{ justifyContent: "center", padding: "16px" }}>
          <Typography variant="body1">{t("dcaBots.runsList.noData")}</Typography>
        </Grid>
      );
    }

    return (
      <Stack spacing={2} mx={{ xs: -1, sm: 0 }}>
        {getData().map((dcaBotRun, index) => (
          <OrderRow
            key={`dcaBotRun-${dcaBotRun.startedAt}-${index}`}
            dcaBotRun={dcaBotRun}
            baseCurrency={baseCurrency}
            counterCurrency={counterCurrency}
            defaultOpen={index === 0}
            exchangeEnum={exchangeEnum}
            refreshDetail={refreshDetail}
          />
        ))}
      </Stack>
    );
  };

  const handleSort = (sort: string | string[] | null) => {
    if (sort !== null) setOrder(sort as "asc" | "desc");
  };

  const filter = () => {
    return (
      <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 1, md: 0 }} justifyContent={"space-between"} pb={3}>
        <FormToggleButton
          value={order}
          onChange={(_event, newOrder) => handleSort(newOrder)}
          size="small"
          color="info"
          fullWidth={isMobileLayout}
          items={[
            { value: "desc", label: t("dcaBots.runsList.triggering.desc") },
            { value: "asc", label: t("dcaBots.runsList.triggering.asc") },
          ]}
        />
        <FormToggleButton
          value={selectedTriggering ?? []}
          onChange={(_event, data) => onTriggeringChange(data as OrderTriggering[])}
          exclusive={false}
          fullWidth={isMobileLayout}
          size="small"
          color="info"
          items={[
            { value: OrderTriggering.PERIODIC, label: t("dcaBots.runsList.triggering.PERIODIC") },
            { value: OrderTriggering.INSTANT, label: t("dcaBots.runsList.triggering.INSTANT") },
            { value: OrderTriggering.MANUAL, label: t("dcaBots.runsList.triggering.MANUAL") },
          ]}
        />
      </Stack>
    );
  };

  return (
    <Card
      sx={{ mb: 0 }}
      header={t("dcaBots.runsList.title")}
      actions={
        <Button color="info" onClick={setInitialValuesModalOpen} variant="outlined" size="small">
          {initialBaseCurrencyAmount !== 0 && initialCounterCurrencyAmount !== 0
            ? t("dcaBots.initialValues.ctaEdit")
            : t("dcaBots.initialValues.cta")}
        </Button>
      }
      leftActions={
        isMobileLayout && (
          <IconButton onClick={onPositionToggle} size="small">
            {position === LayoutPosition.BOTTOM ? <ArrowUp fontSize="inherit" /> : <ArrowDown fontSize="inherit" />}
          </IconButton>
        )
      }>
      {exchangeEnum ? (
        <Chart
          exchangeEnum={exchangeEnum}
          currencyPair={`${baseCurrency}/${counterCurrency}`}
          isBrokerage={isBrokerage}
          askPrice={askPrice}
          bidPrice={bidPrice}
          dcaBotRuns={data?.dcaBotRuns}
          mode={mode}
          strategyType={strategyType}
          enabled={enabled}
        />
      ) : null}
      {filter()}
      {renderOpenLimitOrders()}
      {!disableRunsListInfoBox && !close && mode !== DcaBotMode.MANUAL && (
        <Alert
          onClose={closeInfoBox}
          severity="info"
          sx={{ mb: 2, "@media (max-width:400px)": { fontSize: "0.75rem" } }}>
          <AlertTitle>
            <Trans i18nKey="dcaBots.runsList.infoBox.title" />
          </AlertTitle>
          <Trans i18nKey="dcaBots.runsList.infoBox.subtitle1" />
          <br />
          <br />
          <Trans i18nKey="dcaBots.runsList.infoBox.subtitle2" values={{ baseCurrency }} />
          <br />
          <br />
          <Trans i18nKey="dcaBots.runsList.infoBox.subtitle3" />
        </Alert>
      )}
      {renderTable()}
      {!isShowAll && (
        <Box mt={3} display={"flex"} justifyContent={"center"}>
          <Button sx={{ backgroundColor: "tertiary.main" }} onClick={() => setIsShowAll(true)} variant="contained">
            {t("dcaBots.runsList.loadAll")}
          </Button>
        </Box>
      )}
      {!!(initialBaseCurrencyAmount && initialCounterCurrencyAmount) && (
        <Box sx={{ textAlign: "center", mt: 4 }}>
          <Trans
            i18nKey="dcaBots.runsList.initialAmounts"
            values={{
              initialBaseCurrencyAmount: getMaskedValue(
                isMasked,
                `${renderNumber(initialBaseCurrencyAmount, currencyPairBaseScale)}\u00a0${baseCurrency}`
              ),
              initialCounterCurrencyAmount: getMaskedValue(
                isMasked,
                `${renderNumber(initialCounterCurrencyAmount, counterCurrDisplayedScale, true)}\u00a0${counterCurrency}`
              ),
            }}
          />
        </Box>
      )}
    </Card>
  );
};

export default RunsList;
