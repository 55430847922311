import { SvgIcon } from "@mui/material";

export const CoinsStacked = (props: any) => {
  return (
    <SvgIcon {...props}>
      <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="coins-stacked-03">
          <path
            id="Icon"
            d="M10.8337 4.16667C10.8337 5.08714 8.78163 5.83333 6.25033 5.83333C3.71902 5.83333 1.66699 5.08714 1.66699 4.16667M10.8337 4.16667C10.8337 3.24619 8.78163 2.5 6.25033 2.5C3.71902 2.5 1.66699 3.24619 1.66699 4.16667M10.8337 4.16667V5.41667M1.66699 4.16667V14.1667C1.66699 15.0871 3.71902 15.8333 6.25033 15.8333M6.25033 9.16667C6.10987 9.16667 5.97089 9.16437 5.83366 9.15987C3.49762 9.08332 1.66699 8.3694 1.66699 7.5M6.25033 12.5C3.71902 12.5 1.66699 11.7538 1.66699 10.8333M18.3337 9.58333C18.3337 10.5038 16.2816 11.25 13.7503 11.25C11.219 11.25 9.16699 10.5038 9.16699 9.58333M18.3337 9.58333C18.3337 8.66286 16.2816 7.91667 13.7503 7.91667C11.219 7.91667 9.16699 8.66286 9.16699 9.58333M18.3337 9.58333V15.8333C18.3337 16.7538 16.2816 17.5 13.7503 17.5C11.219 17.5 9.16699 16.7538 9.16699 15.8333V9.58333M18.3337 12.7083C18.3337 13.6288 16.2816 14.375 13.7503 14.375C11.219 14.375 9.16699 13.6288 9.16699 12.7083"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    </SvgIcon>
  );
};
