import { subscriptionCost } from "constants/subscriptions";

import { useState } from "react";

import { Typography, Box, Button, Stack, styled, alpha, Link as MUILink, AlertTitle, Alert } from "@mui/material";
import { cancelCreditsRecharge } from "API/calls";
import BackdropLoading from "components/elements/BackdropLoading";
import Card2 from "components/elements/Card2";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { useUserDataContext } from "context/UserDataContext";
import { useAPICreditsRecharge } from "hooks/useAPI";
import useConfirm from "hooks/useConfirm";
import { useSnackbar } from "notistack";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate, Link } from "react-router-dom";
import { RechargeState, RechargeType } from "types/enums";
import { renderDate, renderNumber } from "utils/formatter";

const StatusBadge = styled(Box)<{ active: boolean }>(({ active }) => {
  const { colors } = useMUIThemeModeContext();

  return {
    backgroundColor: active ? colors.success500 : colors.darkBlue,
    color: colors.white,
    borderRadius: "10px",
    padding: "2px 8px",
  };
});

const CardContent = ({
  id,
  price,
  type,
  action,
  subtitle,
  cost,
  date,
}: {
  id: string;
  price: number;
  type: RechargeType;
  action: React.ReactNode;
  subtitle?: React.ReactNode;
  cost?: number;
  date?: string;
}) => {
  const { t } = useTranslation();
  const { colors } = useMUIThemeModeContext();

  return (
    <Stack spacing={3} alignItems={"start"}>
      <StatusBadge active={id === RechargeState.ACTIVE}>
        <Typography fontWeight={500} fontSize={"0.75rem"}>
          {t(`recharge.${id}.badge`)}
        </Typography>
      </StatusBadge>
      <Stack>
        <Typography color={colors.gray950} fontWeight={600} fontSize={"1rem"}>
          {t(`recharge.${id}.title`, { name: t(`recharge.${type}`), cost })}
        </Typography>
        <Stack direction={"row"} alignItems={"end"} spacing={0.5}>
          <Trans
            i18nKey={`recharge.${id}.price`}
            values={{
              price: renderNumber(price, 2, !Number.isInteger(price)),
            }}
            components={{
              t: <Typography color={colors.gray900} fontWeight={600} fontSize={"1.5rem"} />,
              t1: <Typography pb={0.5} color={colors.gray400} fontWeight={600} fontSize={"0.875rem"} />,
            }}
          />
        </Stack>
        {date && (
          <Typography color={colors.gray600} fontSize={"0.875rem"}>
            {t("recharge.start", { date: date })}
          </Typography>
        )}
      </Stack>
      {subtitle}
      {action}
    </Stack>
  );
};

const Recharge = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { colors } = useMUIThemeModeContext();
  const { refreshCreditsRecharge } = useUserDataContext();
  const { confirm } = useConfirm();
  const { enqueueSnackbar } = useSnackbar();
  const { data, mutate, response } = useAPICreditsRecharge();
  const [isOpenBackdropLoading, setIsOpenBackdropLoading] = useState<boolean>(false);

  const handleCancelRecharge = async () => {
    try {
      await confirm({
        title: t("recharge.confirmCancel.title"),
        description: t("recharge.confirmCancel.description"),
        dialogProps: { maxWidth: "xs" },
      });

      setIsOpenBackdropLoading(true);
      try {
        await cancelCreditsRecharge();
        enqueueSnackbar(t("recharge.confirmCancel.cancelSuccess"), { variant: "success" });
        mutate();
        refreshCreditsRecharge?.();
      } catch (error: any) {
        if (error?.response?.status === 404) {
          enqueueSnackbar(t("recharge.confirmCancel.error404"), { variant: "error" });
        } else {
          enqueueSnackbar(t("recharge.confirmCancel.cancelError"), { variant: "error" });
        }
      }
      setIsOpenBackdropLoading(false);
    } catch (err: any) {
      // silent
    }
  };

  const getAction = (state: RechargeState) => {
    if (state === RechargeState.EXPIRED || state === RechargeState.INACTIVE) {
      return (
        <Button fullWidth onClick={() => navigate("/mesicni-platba")} variant="contained">
          {t("recharge.create.button")}
        </Button>
      );
    }

    return (
      <>
        <Stack direction={"row"} width={"100%"} spacing={1.5}>
          <Button fullWidth onClick={() => navigate("/mesicni-platba")} variant="contained">
            {t("recharge.changeButton")}
          </Button>
          <Button
            fullWidth
            onClick={handleCancelRecharge}
            variant="outlined"
            color="inherit"
            sx={{ backgroundColor: colors.white, border: `1px solid ${colors.gray300}` }}>
            {t("recharge.cancelButton")}
          </Button>
        </Stack>
        <Button component={Link} to="/stosaky#transakce">
          {t("recharge.showAllTransactions")}
        </Button>
      </>
    );
  };

  const getContent = () => {
    if (response?.status === 204 || data?.state === RechargeState.CANCELED) {
      return (
        <CardContent
          id={RechargeState.INACTIVE}
          price={150}
          type={RechargeType.RECHARGE_150}
          cost={subscriptionCost.PREMIUM}
          action={getAction(RechargeState.INACTIVE)}
          subtitle={
            <Typography color={colors.gray600} fontSize={"0.875rem"}>
              {t("recharge.INACTIVE.subtitle")}
            </Typography>
          }
        />
      );
    }

    if (!data) return null;

    return (
      <CardContent
        id={data.state}
        price={data.czkAmountWithTax}
        type={data.type}
        date={renderDate(data.createdAt)}
        action={getAction(data.state)}
        subtitle={
          data.state === RechargeState.EXPIRED ? (
            <Box p={1.5} borderRadius={"10px"} bgcolor={alpha(colors.error, 0.1)} color={colors.error}>
              <Typography fontSize={"0.875rem"}>{t(`recharge.${data.state}.subtitle`)}</Typography>
            </Box>
          ) : undefined
        }
      />
    );
  };

  return (
    <>
      <Typography variant="h5" component="h1" fontWeight={600} sx={{ pb: 1, color: "tertiary.main" }}>
        {t("creditsBuyTitle2")}
      </Typography>
      <Typography variant="subtitle2" fontWeight={400}>
        <Alert severity="error" sx={{ mt: 1, mb: 2 }}>
          <AlertTitle>{"POZOR - musíme řešit implementaci jiné platební brány pro měsíční platby!"}</AlertTitle>
          {"Omlouvám se za komplikace, zatím využij jednorázový nákup balíčku Štosáků níže, díky za pochopení."}
          <br/>
          {"Nejvyšší balíček Štosáků nyní "}<strong>{"do konce roku 2024 ve 33% slevě!"}</strong>
        </Alert>
        <Trans
          i18nKey="creditsBuySubtitle2"
          components={{
            a1: <MUILink href={"/proc-bitcoin/cashback-saty"} />,
            a2: <MUILink href={"/mesicni-platba"} />,
          }}
        />
      </Typography>
      <Box sx={{ pb: 4, pt: 3 }}>
        <Card2 background={colors.gray50} border={`1px solid ${colors.gray200}`} maxWidth={"300px"}>
          {getContent()}
        </Card2>
        <BackdropLoading open={isOpenBackdropLoading} />
      </Box>
    </>
  );
};

export default Recharge;
