import React, { forwardRef, useImperativeHandle, useState } from "react";

import FullscreenIcon from "@mui/icons-material/Fullscreen";
import { Alert, Box, CircularProgress, Stack, useMediaQuery, useTheme } from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import AnimatedCachedIcon from "components/elements/AnimatedCachedIcon";
import Card from "components/elements/Card";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { useAPIOHLC } from "hooks/useAPI";
import { Trans, useTranslation } from "react-i18next";
import { OHLCInterval } from "types/enums";

import ChartItem from "./ChartItem";
import ChartModal from "./ChartModal";
import IProps from "./types";

const Chart = forwardRef(({ data, exchangeEnum, currencyPair, isBrokerage, exchangeTitle }: IProps, ref) => {
  const { t } = useTranslation();
  const { colors } = useMUIThemeModeContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [fullScreen, setFullScreen] = useState(false);
  const showGridBotBrokerageInfo = localStorage.getItem("showGridBotBrokerageInfo");
  const [isShowGridBotBrokerageInfo, setIsShowGridBotBrokerageInfo] = useState<boolean>(
    showGridBotBrokerageInfo !== "false"
  );

  const [interval, setInterval] = useState<OHLCInterval>(localStorage.getItem("gridStrategyChartCandles") as OHLCInterval ?? OHLCInterval.ONE_HOUR);
  const {
    data: OHLCData,
    error,
    isValidating,
    mutate,
  } = useAPIOHLC(currencyPair, interval, exchangeEnum, data?.synced ? 15000 : 5000);

  useImperativeHandle(ref, () => ({
    refreshOHLC: () => mutate(),
  }));

  const handleChange = (_event: React.MouseEvent<HTMLElement>, newInterval: string) => {
    if (!newInterval) return;

    localStorage.setItem("gridStrategyChartCandles", newInterval);
    setInterval(newInterval as OHLCInterval);
  };

  const closeBrokerageInfoBox = (_event: React.SyntheticEvent<Element, Event>) => {
    setIsShowGridBotBrokerageInfo(false);
    localStorage.setItem("showGridBotBrokerageInfo", "false");
  };

  const chartSection = (height?: number) => {
    if (!OHLCData) {
      return (
        <Box justifyContent={"center"} display={"flex"}>
          <CircularProgress />
        </Box>
      );
    }

    if (OHLCData.ohlc.length === 0 || error) {
      return <Alert severity="error">{t(`gridBot.chart.errorData`)}</Alert>;
    }

    return (
      <ChartItem
        currencyPair={currencyPair}
        data={data}
        OHLCData={OHLCData}
        exchangeEnum={exchangeEnum}
        isBrokerage={isBrokerage}
        height={height}
        interval={interval}
      />
    );
  };

  return (
    <Card
      sx={{ mb: 0 }}
      shadow={false}
      header={
        <Stack direction={"row"} alignItems={"center"} spacing={2}>
          {t(`gridBot.chart.title`)}
          {!data?.synced || isValidating ? <AnimatedCachedIcon loading={"true"} sx={{ ml: 1 }} /> : null}
        </Stack>
      }
      actions={
        <Stack spacing={2} direction={"row"}>
          {!isMobile && (
            <ToggleButton
              value="fullScreen"
              sx={{ textTransform: "none" }}
              selected={fullScreen}
              onChange={() => setFullScreen(!fullScreen)}>
              <FullscreenIcon />
            </ToggleButton>
          )}
          <ToggleButtonGroup color="primary" value={interval} exclusive onChange={handleChange}>
            <ToggleButton sx={{ textTransform: "none" }} value={OHLCInterval.ONE_DAY}>
              1D
            </ToggleButton>
            <ToggleButton sx={{ textTransform: "none" }} value={OHLCInterval.ONE_HOUR}>
              1h
            </ToggleButton>
            <ToggleButton sx={{ textTransform: "none" }} value={OHLCInterval.ONE_MINUTE}>
              1m
            </ToggleButton>
          </ToggleButtonGroup>
        </Stack>
      }>
      <Box sx={{ height: "300px" }}>{chartSection()}</Box>
      {fullScreen && (
        <ChartModal
          title={t(`gridBot.chart.title`)}
          handleClose={() => setFullScreen(false)}
          interval={interval}
          onChangeInterval={handleChange}>
          {chartSection()}
        </ChartModal>
      )}
      {isShowGridBotBrokerageInfo && isBrokerage && (
        <Box pt={2}>
          <Alert
            onClose={closeBrokerageInfoBox}
            severity="info"
            sx={{ "@media (max-width:400px)": { fontSize: "0.75rem", p: 0.5 } }}>
            <Trans
              i18nKey="gridBot.chart.brokerageInfoBox"
              values={{ brokerage: exchangeTitle }}
              components={{
                t1: <span style={{ color: colors.success }} />,
                t2: <span style={{ color: colors.error }} />,
              }}
            />
          </Alert>
        </Box>
      )}
    </Card>
  );
});

export default Chart;
