import React from "react";

import { Box, Button } from "@mui/material";
import { grey } from "@mui/material/colors";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";

interface Props {
  section: string;
  value: string;
  description?: string;
  actionLabel: string;
  action?: () => void;
  to?: string;
}

const SettingsItem: React.FC<Props> = ({ section, value, description, actionLabel, action, to }) => (
  <Box>
    <Typography variant="h6" component="h2" fontWeight={600} sx={{ pb: 1, color: "secondary.main" }}>
      {section}
    </Typography>
    <Grid container justifyContent="center" sx={{ pb: 2 }}>
      <Grid item sx={{ py: 2, pr: 2, alignSelf: "center" }} xs={12} md={8}>
        <Typography color={grey[500]}>{value}</Typography>
        {description && <br/>}
        {description}
      </Grid>
      <Grid item xs={12} md={4} sx={{ alignSelf: "center", textAlign: "right" }}>
        <Button
          variant="outlined"
          {...(to && { to, component: Link })}
          {...(action && { onClick: action })}
          {...((!(action || to)) && { disabled: true })}>
          {actionLabel}
        </Button>
      </Grid>
    </Grid>
  </Box>
);

export default SettingsItem;
