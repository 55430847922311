import { SvgIcon } from '@mui/material';

export const CreditMenuDark = (props: any) => {
  return (
    <SvgIcon {...props}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M15.7088 6.33484L12.0829 8.07279L8.47311 6.34252C5.19546 7.7499 2.8999 11.0069 2.8999 14.8C2.8999 19.8811 7.01888 24 12.0999 24C17.1809 24 21.2999 19.8811 21.2999 14.8C21.2999 11 18.996 7.73803 15.7088 6.33484Z" fill="currentColor"/>
        <path d="M12.2549 21.181C11.3431 21.181 10.5251 21.0201 9.80097 20.6983C9.07686 20.363 8.50025 19.9138 8.07114 19.3506C7.65545 18.7874 7.42749 18.1639 7.38726 17.48H10.2234C10.277 17.9091 10.4849 18.2645 10.8469 18.5461C11.2224 18.8277 11.685 18.9685 12.2348 18.9685C12.7712 18.9685 13.1869 18.8612 13.4819 18.6466C13.7903 18.4321 13.9445 18.1572 13.9445 17.8219C13.9445 17.4599 13.7568 17.1917 13.3813 17.0174C13.0193 16.8296 12.4359 16.6285 11.6314 16.4139C10.8 16.2128 10.1161 16.005 9.57972 15.7904C9.05674 15.5758 8.60082 15.2473 8.21194 14.8048C7.83648 14.3623 7.64874 13.7656 7.64874 13.0146C7.64874 12.3978 7.82307 11.8346 8.17172 11.325C8.53377 10.8155 9.04333 10.4132 9.7004 10.1182C10.3709 9.82316 11.1553 9.67566 12.0538 9.67566C13.3813 9.67566 14.4407 10.0109 15.2318 10.6814C16.023 11.3384 16.4588 12.2302 16.5393 13.3566H13.8439C13.8037 12.9141 13.616 12.5654 13.2807 12.3106C12.9589 12.0424 12.5231 11.9083 11.9733 11.9083C11.4638 11.9083 11.0682 12.0022 10.7866 12.1899C10.5184 12.3777 10.3843 12.6392 10.3843 12.9744C10.3843 13.3499 10.572 13.6382 10.9475 13.8393C11.323 14.027 11.9063 14.2215 12.6974 14.4226C13.502 14.6238 14.1658 14.8316 14.6887 15.0462C15.2117 15.2607 15.6609 15.596 16.0364 16.0519C16.4253 16.4944 16.6264 17.0844 16.6398 17.8219C16.6398 18.4656 16.4588 19.0422 16.0967 19.5518C15.7481 20.0613 15.2385 20.4636 14.5681 20.7586C13.911 21.0402 13.1399 21.181 12.2549 21.181Z" fill="#000" />
        <path d="M12.0831 6.4L17.6733 3.8335V0L12.0831 2.8264L6.49292 0V3.8335L12.0831 6.4Z" fill="currentColor"/>
      </svg>
    </SvgIcon>
  );
}
