import React, { useState, useEffect } from "react";

import {
  useTheme,
  BottomNavigationAction,
  BottomNavigation as MUIBottomNavigation,
  Paper,
  useMediaQuery,
  Backdrop,
  Collapse,
  Typography,
  Box,
  Stack,
} from "@mui/material";
import {
  Home,
  BarChart,
  MessageQuestionSquare,
  Settings,
  Laptop,
  PlaySquare,
  GraduationHat,
  Calculator,
  Users,
  PauseCircle,
} from "components/icons";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { useTranslation } from "react-i18next";
import { useLocation, Link } from "react-router-dom";
import { getColorByMode, getLocalStorageBooleanValue } from "utils";

import AccordionItem from "../AccordionItem";
import CreditBadge from "../CreditBadge";
import MenuItem from "../MenuItem";

enum SubMenuType {
  INFO = "INFO",
  STRATEGIES = "STRATEGIES",
}
interface IProps {
  botsList: { enabledList: React.ReactNode[]; disabledList: React.ReactNode[] };
}

const TOOLBAR_HEIGHT = "56px";
const BOTTOM_NAVIGATION_HEIGHT = "86px";

const BottomNavigation = ({ botsList }: IProps) => {
  const theme = useTheme();
  const { colors } = useMUIThemeModeContext();
  const navigationBreakpoint = useMediaQuery(theme.breakpoints.down(400));
  const smallerTextBreakpoint = useMediaQuery(theme.breakpoints.down(321));
  const { pathname, hash } = useLocation();
  const { t } = useTranslation();
  const [subMenuType, setSubMenuType] = useState<SubMenuType>();
  const [isOpenSubMenu, setIsOpenSubMenu] = useState<boolean>(false);
  const [disabledListExpanded, setDisabledListExpanded] = useState<boolean>(getLocalStorageBooleanValue("menuDisabledListExpanded", false));

  useEffect(() => {
    closeSubMenu();
  }, [pathname, hash]);

  const openSubMenu = (type: SubMenuType) => () => {
    if (type === subMenuType && isOpenSubMenu) setIsOpenSubMenu(false);
    if (!isOpenSubMenu) setIsOpenSubMenu(true);

    setSubMenuType(type);
  };

  const closeSubMenu = () => setIsOpenSubMenu(false);

  const handleEndCollapse = () => {
    if (!isOpenSubMenu) setTimeout(() => setSubMenuType(undefined), 200);
  };

  const handleLinkClick = (_event: React.SyntheticEvent<Element, Event>, value: string) => {
    if (value.includes("/")) closeSubMenu();
  };

  const handleDisabledListExpanded = () => {
    localStorage.setItem("menuDisabledListExpanded", (!disabledListExpanded).toString());
    setDisabledListExpanded((prev) => !prev);
  }

  const navigationButton = (
    label: string,
    icon: React.ReactNode,
    to?: string,
    onClick?: () => void,
    value?: string
  ) => {
    let props = {};
    if (to) props = { to, component: Link };

    return (
      <BottomNavigationAction
        sx={{
          padding: 0,
          minWidth: 55,
          backgroundColor: getColorByMode(theme, colors.white, "#252525"),
          color: colors.gray700,
          "&.Mui-selected": {
            paddingTop: 0,
          },
          "& .MuiBottomNavigationAction-label": {
            fontWeight: 500,
            paddingTop: 1,
            fontSize: smallerTextBreakpoint ? "0.6rem" : "0.75rem",
            "&.Mui-selected": {
              fontSize: smallerTextBreakpoint ? "0.6rem" : "0.75rem",
            },
          },
        }}
        value={to ?? value}
        label={label}
        icon={icon}
        onClick={onClick}
        {...props}
      />
    );
  };

  const infoSection = () => (
    <Stack spacing={0.5} px={1}>
      <MenuItem icon={<Laptop color="inherit" sx={{ fontSize: 20 }} />} title={t("home")} to="/" withSelected />
      <MenuItem
        icon={<PlaySquare color="inherit" sx={{ fontSize: 20 }} />}
        title={t("guide")}
        to="/proc-bitcoin/jak-vam-stosuj-cz-pomuze"
        withSelected
      />
      <MenuItem
        icon={<GraduationHat color="inherit" sx={{ fontSize: 20 }} />}
        title={t("whyBitcoinSidebarLink")}
        to="/proc-bitcoin"
        withSelected
      />
      <MenuItem
        icon={<Calculator color="inherit" sx={{ fontSize: 20 }} />}
        title={t("dcaCalc")}
        to="/#dca-kalkulacka"
        withSelected
      />
      <MenuItem
        icon={<Users color="inherit" sx={{ fontSize: 20 }} />}
        title={t("communityAndContact")}
        to="/#kontakt"
        withSelected
      />
      <MenuItem
        icon={<PlaySquare color="inherit" sx={{ fontSize: 20 }} />}
        title={t("terms")}
        to="/podminky"
        withSelected
      />
    </Stack>
  );

  const strategiesSection = () => (
    <Stack px={1}>
      <Stack spacing={0.5}>{botsList.enabledList}</Stack>
      {botsList.disabledList.length ? (
        <AccordionItem
          sx={{ backgroundColor: "transparent" }}
          icon={<PauseCircle sx={{ color: colors.gray400, fontSize: 20, mr: 1.5 }} />}
          title={t("bottomNavigation.subMenu.STRATEGIES.disabled")}
          expanded={disabledListExpanded}
          onChange={handleDisabledListExpanded}
          isMobile>
          <Stack spacing={0.5}>{botsList.disabledList}</Stack>
        </AccordionItem>
      ) : undefined}
    </Stack>
  );

  const getSubMenu = () => {
    switch (subMenuType) {
      case SubMenuType.INFO:
        return infoSection();
      case SubMenuType.STRATEGIES:
        return strategiesSection();
      default:
        break;
    }
  };

  return (
    <>
      <Paper
        sx={{
          position: "fixed",
          bottom: 0,
          width: "100%",
          overflowY: navigationBreakpoint ? "hidden" : undefined,
          pt: isOpenSubMenu ? 2 : 1.5,
          pb: 2,
          borderRadius: isOpenSubMenu ? "10px 10px 0px 0px" : 0,
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        elevation={3}>
        <Collapse in={isOpenSubMenu} addEndListener={handleEndCollapse}>
          <Box
            mb={2}
            maxHeight={`calc(100vh - ${TOOLBAR_HEIGHT} - ${BOTTOM_NAVIGATION_HEIGHT} - 16px)`}
            overflow={"auto"}>
            <Box px={3} py={1}>
              <Typography color={colors.gray900} fontWeight={600} fontSize={"0.875rem"}>
                {t(`bottomNavigation.subMenu.${subMenuType}.title`)}
              </Typography>
            </Box>
            {getSubMenu()}
          </Box>
        </Collapse>
        <MUIBottomNavigation
          sx={{ justifyContent: navigationBreakpoint ? "start" : undefined, maxHeight: BOTTOM_NAVIGATION_HEIGHT }}
          showLabels
          onChange={handleLinkClick}
          value={subMenuType ? subMenuType : `${pathname}${hash}`}>
          {navigationButton(t("bottomNavigation.dashboard"), <Home />, "/dashboard")}
          {navigationButton(
            t("bottomNavigation.strategies"),
            <BarChart />,
            undefined,
            openSubMenu(SubMenuType.STRATEGIES),
            SubMenuType.STRATEGIES
          )}
          {navigationButton(t("bottomNavigation.credits"), <CreditBadge />, "/stosaky")}
          {navigationButton(
            t("bottomNavigation.info"),
            <MessageQuestionSquare />,
            undefined,
            openSubMenu(SubMenuType.INFO),
            SubMenuType.INFO
          )}
          {navigationButton(t("bottomNavigation.settings"), <Settings />, "/nastaveni")}
        </MUIBottomNavigation>
      </Paper>
      <Backdrop open={isOpenSubMenu} onClick={closeSubMenu} sx={{ zIndex: (theme) => theme.zIndex.drawer }} />
    </>
  );
};

export default BottomNavigation;
