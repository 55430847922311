import { SvgIcon } from "@mui/material";

export const StatisticsMonitor = (props: any) => {
  return (
    <SvgIcon {...props}>
      <svg width="24" height="24" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.2924 1.7334H18.7097C20.541 1.73339 21.9726 1.73339 23.1224 1.82733C24.2918 1.92287 25.2515 2.12022 26.118 2.56175C27.5481 3.29039 28.7107 4.45304 29.4394 5.88307C29.8809 6.74961 30.0782 7.70934 30.1738 8.87874C30.2677 10.0285 30.2677 11.4601 30.2677 13.2915V13.3753C30.2677 15.2066 30.2677 16.6383 30.1738 17.7881C30.0782 18.9575 29.8809 19.9172 29.4394 20.7837C28.7107 22.2138 27.5481 23.3764 26.118 24.105C25.2515 24.5466 24.2918 24.7439 23.1224 24.8395C21.9726 24.9334 20.541 24.9334 18.7096 24.9334H13.2925C11.4611 24.9334 10.0295 24.9334 8.87972 24.8395C7.71032 24.7439 6.75059 24.5466 5.88405 24.105C4.45402 23.3764 3.29136 22.2138 2.56273 20.7837C2.1212 19.9172 1.92385 18.9575 1.8283 17.7881C1.73437 16.6383 1.73437 15.2067 1.73438 13.3753V13.2914C1.73437 11.4601 1.73437 10.0285 1.8283 8.87874C1.92385 7.70934 2.1212 6.74961 2.56273 5.88307C3.29136 4.45304 4.45402 3.29039 5.88405 2.56175C6.75059 2.12022 7.71032 1.92287 8.87972 1.82733C10.0295 1.73339 11.4611 1.73339 13.2924 1.7334ZM9.03172 3.68779C7.97776 3.77391 7.29103 3.93986 6.7315 4.22496C5.6527 4.77464 4.77561 5.65172 4.22594 6.73052C3.94084 7.29006 3.77488 7.97679 3.68877 9.03075C3.60177 10.0956 3.60104 11.4512 3.60104 13.3334C3.60104 15.2156 3.60177 16.5712 3.68877 17.636C3.77488 18.69 3.94084 19.3767 4.22594 19.9363C4.77561 21.0151 5.6527 21.8922 6.7315 22.4418C7.29103 22.7269 7.97776 22.8929 9.03172 22.979C10.0966 23.066 11.4521 23.0667 13.3344 23.0667H18.6677C20.5499 23.0667 21.9055 23.066 22.9704 22.979C24.0243 22.8929 24.7111 22.7269 25.2706 22.4418C26.3494 21.8922 27.2265 21.0151 27.7761 19.9363C28.0612 19.3767 28.2272 18.69 28.3133 17.636C28.4003 16.5712 28.401 15.2156 28.401 13.3334C28.401 11.4512 28.4003 10.0956 28.3133 9.03075C28.2272 7.97679 28.0612 7.29006 27.7761 6.73052C27.2265 5.65172 26.3494 4.77464 25.2706 4.22496C24.7111 3.93986 24.0243 3.77391 22.9704 3.68779C21.9055 3.60079 20.5499 3.60007 18.6677 3.60007H13.3344C11.4521 3.60007 10.0966 3.60079 9.03172 3.68779Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.0011 23.0664C16.5166 23.0664 16.9345 23.4843 16.9345 23.9997V26.6863L21.6622 28.4592C22.0847 28.6176 22.3333 29.0556 22.2528 29.4996C22.1723 29.9436 21.7857 30.2664 21.3345 30.2664H10.6678C10.2166 30.2664 9.82995 29.9436 9.74944 29.4996C9.66893 29.0556 9.91758 28.6176 10.3401 28.4592L15.0678 26.6863V23.9997C15.0678 23.4843 15.4857 23.0664 16.0011 23.0664Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.3411 11.3398C13.6824 10.9984 14.2277 10.9737 14.5985 11.2827L17.9438 14.0704L20.6744 11.3398C21.0389 10.9753 21.6299 10.9753 21.9943 11.3398C22.3588 11.7043 22.3588 12.2952 21.9943 12.6597L18.661 15.993C18.3197 16.3344 17.7744 16.3591 17.4035 16.0501L14.0583 13.2624L11.3277 15.993C10.9632 16.3575 10.3722 16.3575 10.0077 15.993C9.64325 15.6286 9.64325 15.0376 10.0077 14.6731L13.3411 11.3398Z"
          fill="#FF9900"
        />
      </svg>
    </SvgIcon>
  );
};
