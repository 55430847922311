import { Stack, Typography } from "@mui/material";
import FormControlLabel, { FormControlLabelProps } from "@mui/material/FormControlLabel";
import MUIRadio from "@mui/material/Radio";
import Badge from "components/elements/Badge";
import { Diamond } from "components/icons";

const DynamicStrategyRadio = ({ ...props }: Omit<FormControlLabelProps, "control">) => {
  return (
    <Stack direction={"row"} alignItems={"center"}>
      <FormControlLabel
        control={<MUIRadio />}
        {...props}
        sx={{ marginRight: "10px" }}
      />
      <Badge color="purple">
        <Typography fontSize={"0.875rem"} mx={0.5}>
          {"PREMIUM"}
        </Typography>
        <Diamond sx={{ fontSize: 15 }} />
      </Badge>
    </Stack>
  );
};

export default DynamicStrategyRadio;
