import React, { useState } from "react";

import HelpIcon from "@mui/icons-material/Help";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import PauseOutlinedIcon from "@mui/icons-material/PauseOutlined";
import PlayArrowOutlinedIcon from "@mui/icons-material/PlayArrowOutlined";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  Box,
  Grid,
  Stack,
  IconButton,
  MenuItem,
  Menu,
  ListItemIcon,
  Divider,
  useTheme,
  alpha,
  Skeleton,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import MaskedModeWrapper from "components/elements/MaskedModeWrapper";
import Panel from "components/elements/Panel";
import Tooltip from "components/elements/Tooltip";
import UsdWrapper, { TypographyItem } from "components/elements/UsdWrapper";
import ProfitBox from "components/modules/ProfitBox";
import SatsView from "components/modules/SatsView";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import useExchangesMetadata from "hooks/useExchangesMetadata";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { splitCurrencyPair } from "utils";
import { renderDateTimeWithComma, renderNumber } from "utils/formatter";

import Header from "./Header";
import IProps from "./types";

const Detail = ({ id, data, onEnabled, onDelete, children, ordersList }: IProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { colors } = useMUIThemeModeContext();
  const theme = useTheme();
  const { counterCurrency, baseCurrency } = splitCurrencyPair(data.currencyPair);
  const { enabled } = data;
  const { totalBaseCurrencyProfit: totalProfit, totalBaseCurrencyProfitCurrentValue: counterProfit } = ordersList || {};
  const [anchorElControl, setAnchorElControl] = useState<null | HTMLElement>(null);
  const { counterCurrDisplayedScale, baseCurrDisplayedScale } = useExchangesMetadata(
    data.exchangeConnection.exchangeEnum,
    data.currencyPair
  );

  const handleOpenControlMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElControl(event.currentTarget);
  };

  const handleCloseControlMenu = () => {
    setAnchorElControl(null);
  };

  const handleEnable = () => {
    handleCloseControlMenu();
    onEnabled();
  };

  const handleDelete = () => {
    handleCloseControlMenu();
    onDelete();
  };

  const handleSettings = () => {
    handleCloseControlMenu();
    navigate(`/upravit-grid-strategii/${id}`);
  };

  const controlMenu = () => {
    return (
      <Box>
        <IconButton size="small" onClick={handleOpenControlMenu} color="inherit">
          <MoreHorizIcon />
        </IconButton>
        <Menu
          sx={{ mt: "45px" }}
          id="grid-menu-appbar-control"
          anchorEl={anchorElControl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorElControl)}
          onClose={handleCloseControlMenu}>
          <MenuItem onClick={handleEnable} disabled={!enabled && !data.exchangeConnection.functional}>
            <ListItemIcon>
              {enabled ? <PauseOutlinedIcon fontSize="small" /> : <PlayArrowOutlinedIcon fontSize="small" />}
            </ListItemIcon>
            <Typography>{enabled ? t("dcaBots.pause") : t("dcaBots.activate")}</Typography>
          </MenuItem>
          <MenuItem onClick={handleDelete}>
            <ListItemIcon>
              <HighlightOffIcon fontSize="small" />
            </ListItemIcon>
            <Typography>{t("delete")}</Typography>
          </MenuItem>
          <MenuItem onClick={handleSettings}>
            <ListItemIcon>
              <SettingsIcon fontSize="small" />
            </ListItemIcon>
            <Typography>{t("dcaBots.editSettings")}</Typography>
          </MenuItem>
        </Menu>
      </Box>
    );
  };

  return (
    <Panel sx={{ overflow: "hidden" }}>
      <Header
        withBorderBottom={true}
        currencyPair={data.currencyPair}
        enabled={enabled}
        label={data.label}
        rightSection={
          <Stack direction="row" spacing={1}>
            <Typography color={colors.legacyGray700} variant="body2">
              {data.enabled ? t("dcaBots.enabled") : t("dcaBots.disabled")}
            </Typography>
            <Typography color={colors.legacyGray700} variant="body2" fontWeight={600}>
              {renderDateTimeWithComma(data.enabledChangedAt)}
            </Typography>
          </Stack>
        }
        rightSectionSmallScreen={controlMenu()}
      />
      <Grid container>
        <Grid item xs={12}>
          {children}
        </Grid>
        <Grid item xs={12} px={2}>
          <Divider />
        </Grid>
        <Grid item xs={12} px={2} py={{ xs: 0.75, sm: 1, md: 2 }}>
          <Stack width={"100%"} direction="row" justifyContent="space-between">
            <Stack direction={"row"} spacing={0.5} alignItems={"center"}>
              <Typography color={"secondary"} variant="body1" fontWeight={600}>
                {t("gridBot.detail.profit")}
              </Typography>
              <Tooltip title={t("gridBot.detail.profitTooltip") ?? ""} placement="top" arrow>
                <HelpIcon sx={{ fontSize: "18px", color: alpha(colors.black, 0.54) }} />
              </Tooltip>
            </Stack>
            <Stack
              direction={{ xs: "column-reverse", sm: "row" }}
              spacing={{ xs: 0, sm: 1 }}
              justifyContent={"space-between"}
              alignItems={{ xs: "end", sm: "center" }}>
              {ordersList ? (
                <>
                  {counterProfit ? (
                    <ProfitBox
                      value={counterProfit}
                      currency={counterCurrency}
                      scale={counterCurrDisplayedScale}
                      bgColor={colors.gray400}
                    />
                  ) : undefined}
                  <MaskedModeWrapper color={theme.palette.secondary.main} fontWeight={600} variant="body1">
                    <SatsView
                      color={theme.palette.secondary.main}
                      variant="body1"
                      fontWeight={600}
                      value={totalProfit}
                      currency={baseCurrency}
                      scale={baseCurrDisplayedScale}
                      prefix={totalProfit && totalProfit > 0 ? "+" : undefined}
                      withZeros={!!totalProfit}
                    />
                  </MaskedModeWrapper>
                </>
              ) : (
                <>
                  <Skeleton variant="text" width={60} height={24} />
                  <Skeleton variant="text" width={150} height={24} />

                </>
              )}
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12} px={2}>
          <Divider />
        </Grid>
        <Grid item xs={12} px={2} py={{ xs: 0.75, sm: 1, md: 2 }} pt={{ xs: 2 }}>
          <Stack width={"100%"} direction="row" justifyContent="space-between">
            <Typography color={"secondary"} variant="body1" pr={1}>
              {t("gridBot.detail.gridUpperPrice")}
            </Typography>
            <UsdWrapper value={data.gridUpperPrice} counterCurrency={counterCurrency} baseCurrency={baseCurrency}>
              <TypographyItem
                sx={{ textAlign: "end" }}
                fontWeight={600}
                color={"secondary"}
                variant="body1"
                value={data.gridUpperPrice}
                scale={counterCurrDisplayedScale}
                currency={counterCurrency}
                withZeros
              />
            </UsdWrapper>
          </Stack>
        </Grid>
        <Grid item xs={12} px={2} py={{ xs: 0.75, sm: 1, md: 2 }} pt={{ xs: 2 }}>
          <Stack width={"100%"} direction="row" justifyContent="space-between">
            <Typography color={"secondary"} variant="body1" pr={1}>
              {t("gridBot.detail.gridLowerPrice")}
            </Typography>
            <UsdWrapper value={data.gridLowerPrice} counterCurrency={counterCurrency} baseCurrency={baseCurrency}>
              <TypographyItem
                sx={{ textAlign: "end" }}
                fontWeight={600}
                color={"secondary"}
                variant="body1"
                value={data.gridLowerPrice}
                scale={counterCurrDisplayedScale}
                currency={counterCurrency}
                withZeros
              />
            </UsdWrapper>
          </Stack>
        </Grid>
        <Grid item xs={12} px={2} py={{ xs: 0.75, sm: 1, md: 2 }} pt={{ xs: 2 }}>
          <Stack width={"100%"} direction="row" justifyContent="space-between">
            <Typography color={"secondary"} variant="body1" pr={1}>
              {t("gridBot.detail.gridPercentStep")}
            </Typography>
            <Typography sx={{ textAlign: "end" }} color={"secondary"} variant="body1" fontWeight={600}>
              {`${renderNumber(data.gridPercentStep, 2)}\u00a0%`}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} px={2} py={{ xs: 0.75, sm: 1, md: 2 }} pt={{ xs: 2 }}>
          <Stack width={"100%"} direction="row" justifyContent="space-between">
            <Typography color={"secondary"} variant="body1" pr={1}>
              {t("gridBot.detail.gridOrderValue")}
            </Typography>
            <MaskedModeWrapper color={"secondary"} fontWeight={600} variant="body1">
              <Typography sx={{ textAlign: "end" }} color={"secondary"} variant="body1" fontWeight={600}>
                {`${renderNumber(data.gridOrderValue, counterCurrDisplayedScale, true)}\u00a0${counterCurrency}`}
              </Typography>
            </MaskedModeWrapper>
          </Stack>
        </Grid>
      </Grid>
    </Panel>
  );
};

export default Detail;
