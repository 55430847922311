import { REFERRAL_COINMATE } from "constants/links";

import { Button, Grid, Stack, Typography } from "@mui/material";
import Card2 from "components/elements/Card2";
import ExchangeIcon from "components/elements/ExchangeIcon";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { Trans, useTranslation } from "react-i18next";
import { ExchangeType } from "types/enums";

const Banner = () => {
  const { colors } = useMUIThemeModeContext();
  const { t } = useTranslation();

  return (
    <Grid item xs={12} lg={6} height={"auto"}>
      <Card2
        border={`1px solid ${colors.gray200}`}
        background={colors.gray50}
        title={
          <Stack>
            <Typography color={colors.gray600} fontSize={"0.875rem"} lineHeight={"1.4rem"}>
              <Trans i18nKey="exchangePromoLine1" />
            </Typography>
            <Typography color={colors.gray600} fontSize={"0.875rem"} lineHeight={"1.4rem"}>
              <Trans i18nKey="exchangePromoLine2" />
            </Typography>
          </Stack>
        }>
        <Stack direction={"row"} spacing={1} justifyContent={"space-between"} alignItems={"center"}>
          <ExchangeIcon type={ExchangeType.COINMATE} />
          <Button
            variant="contained"
            color="info"
            size="small"
            href={REFERRAL_COINMATE}
            target="_blank">
            {t("exchangePromo")}
          </Button>
        </Stack>
      </Card2>
    </Grid>
  );
};

export default Banner;
