import { useEffect, useState } from "react";

import { Step, StepLabel, Stepper, stepIconClasses, stepLabelClasses } from "@mui/material";
import Card2 from "components/elements/Card2";
import SectionCard from "components/elements/SectionCard";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { t } from "i18next";
import { useTranslation, Trans } from "react-i18next";

const steps = [
  {
    label: t("howStart.steps.1"),
  },
  {
    label: t("howStart.steps.2"),
  },
  {
    label: t("howStart.steps.3"),
  },
  {
    label: t("howStart.steps.4"),
  },
];

interface IProps {
  hasExchangeConnection: boolean;
}

const HowStart = ({ hasExchangeConnection }: IProps) => {
  const { colors } = useMUIThemeModeContext();
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    switch (true) {
      case hasExchangeConnection:
        setActiveStep(1);
        break;
      default:
        setActiveStep(0);
        break;
    }
  }, [hasExchangeConnection]);

  return (
    <SectionCard title={t("howStart.title")}>
      <Card2 subtitle={<Trans i18nKey={"howStart.description"} />}>
        <Stepper activeStep={activeStep} orientation="vertical" connector={null}>
          {steps.map((step) => (
            <Step key={step.label}>
              <StepLabel
                sx={{
                  [`& .${stepLabelClasses.disabled}`]: {
                    color: colors.gray500,
                  },
                }}
                StepIconProps={{
                  sx: {
                    width: "28px",
                    height: "28px",
                    color: colors.gray200,
                    [`&.${stepIconClasses.active} .${stepIconClasses.text}`]: {
                      fill: colors.white,
                    },
                    [`& .${stepIconClasses.text}`]: {
                      fill: colors.gray400,
                    },
                    [`&.${stepIconClasses.completed}`]: {
                      color: colors.success500,
                    },
                  },
                }}>
                {step.label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Card2>
    </SectionCard>
  );
};

export default HowStart;
