import { useRef, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import ReplayIcon from "@mui/icons-material/Replay";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import DynamicStrategyChart from "components/modules/DynamicStrategyChart";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { Trans, useTranslation } from "react-i18next";
import { DcaBotMode, DcaDynamicStrategyType } from "types/enums";
import { IPoint } from "types/types";
import { renderNumber } from "utils/formatter";

interface IProps {
  minMultiplier: number;
  maxMultiplier: number;
  top: number;
  bottom: number;
  center: number;
  dynamicStrategyChart: IPoint[];
  dynamicStrategyChartOriginal?: IPoint[];
  isCollaped: boolean;
  loading?: boolean;
  labelFontWeight?: number;
  lineLabelFormatter?: (value: string, levelLabel: string, limit: number) => string;
  mode: DcaBotMode;
  type: DcaDynamicStrategyType;
}

interface LegendProps {
  labelKey: string;
  value?: JSX.Element;
  color: string;
  labelKeyValues?: object;
}

const Legend = ({ labelKey, value, color, labelKeyValues }: LegendProps) => {
  return (
    <Stack direction="row" alignItems="center">
      <Box style={{ height: "3px", width: "12px", backgroundColor: color }} />
      <Typography variant="body2" sx={{ ml: 1 }}>
        <Trans i18nKey={labelKey} values={labelKeyValues} />
        {value}
      </Typography>
    </Stack>
  );
};

const DynamicChart = ({
  top,
  center,
  bottom,
  dynamicStrategyChart,
  dynamicStrategyChartOriginal,
  maxMultiplier,
  minMultiplier,
  isCollaped,
  loading,
  labelFontWeight = 400,
  lineLabelFormatter,
  mode,
  type,
}: IProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [fullScreen, setFullScreen] = useState(false);
  const { colors } = useMUIThemeModeContext();

  const chartRef = useRef<{ refresh: () => void }>();
  const [isPriceScaleWidthChanged, setIsPriceScaleWidthChanged] = useState(false);

  const getLineLabel = (value: string, levelLabel: string, limit: number) => {
    if (lineLabelFormatter) return lineLabelFormatter(value, levelLabel, limit);

    if (isCollaped) return `${value} | ${levelLabel}: ${limit}%`;
    return value;
  };

  const getCurrentValue = () => {
    if (!isCollaped) return;

    const value = dynamicStrategyChart[dynamicStrategyChart.length - 1]?.i;

    return (
      <>
        {" ("}
        <Trans i18nKey={"dcaBots.create.dynamicStrategy.legend.value"} values={{ value: `${value}`.replace(".", ",") }} />
        {")"}
      </>
    );
  };

  const getLines = () => {
    const min = mode === DcaBotMode.DCA_BUY ? minMultiplier : maxMultiplier;
    const max = mode === DcaBotMode.DCA_BUY ? maxMultiplier : minMultiplier;

    return [
      {
        value: top,
        label: getLineLabel(
          `x${renderNumber(min, 2)}`,
          t("dcaBots.create.dynamicStrategy.topLineLabel"),
          top
        ),
      },
      {
        value: center,
        label: getLineLabel("x1", t("dcaBots.create.dynamicStrategy.centerLineLabel"), center),
      },
      {
        value: bottom,
        label: getLineLabel(
          `x${renderNumber(max, 2)}`,
          t("dcaBots.create.dynamicStrategy.bottomLineLabel"),
          bottom
        ),
      },
    ];
  };

  return (
    <Stack>
      <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={{ pt: 3 }}>
        <Typography variant="h6" component="h2" fontWeight={labelFontWeight} sx={{ color: "tertiary.main" }}>
          {t("dcaBots.dynamicStrategy.chart", { type: t(`dcaBots.create.dynamicStrategyLabel.typesShort.${type}`) })}
        </Typography>
        <Stack spacing={0.5} direction={"row"}>
          {isPriceScaleWidthChanged && (
            <IconButton size="small" onClick={() => chartRef.current?.refresh()}>
              <ReplayIcon fontSize="small" />
            </IconButton>
          )}
          {!isMobile && (
            <IconButton size="small" onClick={() => setFullScreen(true)}>
              <FullscreenIcon />
            </IconButton>
          )}
        </Stack>
      </Stack>
      <Typography color={"text.secondary"} variant="body2" sx={{ pt: 1 }}>
        <Trans i18nKey={`dcaBots.create.dynamicStrategy.chartIntro.${mode}`} values={{ type: t(`dcaBots.create.dynamicStrategyLabel.typesShort.${type}`) }} />
      </Typography>
      <Box height={"300px"} sx={{ pt: 2 }}>
        {loading ? (
          <Skeleton sx={{ height: "100%" }} animation="wave" variant="rectangular" />
        ) : (
          <DynamicStrategyChart
            ref={chartRef}
            onPriceScaleWidthChange={setIsPriceScaleWidthChanged}
            lines={getLines()}
            points={dynamicStrategyChart}
            type={type}
          />
        )}
      </Box>
      {!loading && (
        <Stack direction={"row"} spacing={2} pt={2} pb={1}>
          <Legend labelKey={"dcaBots.create.dynamicStrategy.legend.price"} color={colors.black} />
          <Legend
            labelKey={"dcaBots.create.dynamicStrategy.legend.strategy"}
            labelKeyValues={{ type: t(`dcaBots.create.dynamicStrategyLabel.typesShort.${type}`) }}
            value={getCurrentValue()}
            color={theme.palette.tertiary.main}
          />
        </Stack>
      )}
      <Dialog open={fullScreen} fullWidth={true} maxWidth={"xl"} onClose={() => setFullScreen(false)}>
        <DialogTitle sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          {t("dcaBots.dynamicStrategy.chart", { type: t(`dcaBots.create.dynamicStrategyLabel.typesShort.${type}`) })}
          <Stack spacing={0.5} direction={"row"}>
            {isPriceScaleWidthChanged && (
              <IconButton size="small" onClick={() => chartRef.current?.refresh()}>
                <ReplayIcon fontSize="small" />
              </IconButton>
            )}
            <IconButton edge="end" size="small" color="inherit" onClick={() => setFullScreen(false)}>
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent sx={{ height: `${(window.innerHeight / 4) * 3}px` }}>
          <DynamicStrategyChart
            onPriceScaleWidthChange={setIsPriceScaleWidthChanged}
            lines={getLines()}
            points={dynamicStrategyChartOriginal || dynamicStrategyChart}
            type={type}
          />
        </DialogContent>
        <DialogActions sx={{ justifyContent: "start", px: 2 }}>
          <Stack direction={"row"} spacing={2}>
            <Legend labelKey={"dcaBots.create.dynamicStrategy.legend.price"} color={colors.black} />
            <Legend
              labelKey={"dcaBots.create.dynamicStrategy.legend.strategy"}
              labelKeyValues={{ type: t(`dcaBots.create.dynamicStrategyLabel.typesShort.${type}`) }}
              value={getCurrentValue()}
              color={theme.palette.tertiary.main}
            />
          </Stack>
        </DialogActions>
      </Dialog>
    </Stack>
  );
};

export default DynamicChart;
