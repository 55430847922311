import React, { useState } from "react";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import StarIcon from "@mui/icons-material/Star";
import { Avatar, Box, Button, Grid, Stack, Typography, alpha, useTheme } from "@mui/material";
import HomeBox from "components/modules/HomeBox";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { useTranslation } from "react-i18next";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

import { mainReferences, references } from "./config";

interface IMainReference {
  text: string;
  author: {
    name: string;
    title: string;
    image: string;
  };
}

const References: React.FC = () => {
  const { t } = useTranslation();
  const { colors } = useMUIThemeModeContext();
  const theme = useTheme();
  const [expandAll, setExpandAll] = useState(false);

  const getMainReference = (reference: IMainReference, index: number) => {
    return (
      <Grid item xs={12} md={6} key={index}>
        <Box borderRadius={"24px"} p={{xs: 2, md: 4}} bgcolor={colors["blueWhite"]} color={colors["blueGray"]} textAlign={"start"}>
          <Stack spacing={3}>
            <Typography
              fontSize={{ xs: "1rem", md: "1.125rem" }}
              fontStyle={"italic"}
              lineHeight={"155%"}>{`„${reference.text}“`}</Typography>
            <Box display={"flex"} alignItems={"center"} mt={3} justifyContent={"end"}>
              <Avatar src={reference.author.image} alt={reference.author.name} sx={{ width: 48, height: 48 }} />
              <Box ml={3}>
                <Typography color={colors["deepMidnightBlue"]} fontWeight={600} fontSize={{ xs: "0.9rem", md: "1rem" }}>
                  {reference.author.name}
                </Typography>
                <Typography fontSize={{ xs: "0.9rem", md: "1rem" }}>{reference.author.title}</Typography>
              </Box>
            </Box>
          </Stack>
        </Box>
      </Grid>
    );
  };

  const getReferences = () => {
    if (expandAll) return references;

    return references.slice(0, 6);
  };

  return (
    <HomeBox name={t("homepage.references.name")} title={t("homepage.references.title")} id={"reference"}>
      <Grid container spacing={4} pt={6} pb={4}>
        {mainReferences.map((reference, index) => getMainReference(reference, index))}
      </Grid>
      <ResponsiveMasonry columnsCountBreakPoints={{ 600: 1, 900: 2, 1200: 3 }}>
        <Masonry gutter="32px">
          {getReferences().map((reference, i) => (
            <Box
              key={i}
              border={`1px solid ${alpha(colors["deepMidnightBlue"], 0.1)}`}
              color={colors["blueGray"]}
              textAlign={"start"}
              p={4}
              borderRadius={"24px"}>
              <Typography
                fontSize={{ xs: "0.9rem", md: "1rem" }}
                fontStyle={"italic"}
                lineHeight={"155%"}>{`„${reference.text}“`}</Typography>
              <Box display={"flex"} mt={2} justifyContent={"space-between"}>
                <Stack spacing={"6px"} direction={"row"}>
                  <StarIcon style={{ fontSize: "1.2rem", marginTop: "1px", color: theme.palette.primary.main }} />
                  <Typography fontSize={{ xs: "0.9rem", md: "1rem" }}>{`${reference.rating}/10`}</Typography>
                </Stack>
                <Typography fontWeight={300} fontSize={{ xs: "0.9rem", md: "1rem" }}>
                  {`- ${reference.name ? reference.name : t("homepage.references.anonymous")}`}
                </Typography>
              </Box>
            </Box>
          ))}
        </Masonry>
      </ResponsiveMasonry>
      <Button
        sx={{ mt: 6 }}
        onClick={() => setExpandAll(!expandAll)}
        endIcon={expandAll ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}>
        {expandAll ? t("homepage.references.showLess") : t("homepage.references.showMore")}
      </Button>
    </HomeBox>
  );
};

export default References;
