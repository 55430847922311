import Default from "components/layouts/Default";
import Main from "components/layouts/Main";
import ActivationPage from "components/pages/Activation";
import BacktestDcaStrategy from "components/pages/BacktestDcaStrategy";
import BacktestGridBot from "components/pages/BacktestGridBot";
import CompleteRegistration from "components/pages/CompleteRegistration";
import CourseDetail from "components/pages/CourseDetail";
import CreditsDashboardPage from "components/pages/Credits/CreditsDashboard";
import CreditsTransactionsPage from "components/pages/Credits/CreditsTransactions";
import SubscriptionsPage from "components/pages/Credits/Subscription";
import CurrencyPairs from "components/pages/CurrencyPairs";
import DashboardPage from "components/pages/Dashboard";
import DcaBotDetailPage from "components/pages/DcaBotDetail";
import EmailPreferences from "components/pages/EmailPreferences";
import EmailVerification from "components/pages/EmailVerification";
import FeaturePage from "components/pages/Feature";
import GridBotDetail from "components/pages/GridBotDetail";
import HomePage from "components/pages/Home";
import LoginPage from "components/pages/Login";
import LostPasswordPage from "components/pages/LostPassword";
import NewDcaBotPage from "components/pages/NewDcaBot";
import NewGridBot from "components/pages/NewGridBot";
import UpdateGridBot from "components/pages/NewGridBot/Update";
import NewRecharge from "components/pages/NewRecharge";
import CancelRecharge from "components/pages/NewRecharge/Cancel";
import Pricing from "components/pages/Pricing";
import RegisterPage from "components/pages/Register";
import SetNewPasswordPage from "components/pages/SetNewPassword";
import SettingsPage from "components/pages/Settings/Settings";
import TermsPage from "components/pages/Terms";
import TwoFactorAuthPage from "components/pages/TwoFactorAuth";
import WhyBitcoin from "components/pages/WhyBitcoin";
import coursesConfig from "components/pages/WhyBitcoin/config/courses";
import { Navigate, useRoutes } from "react-router-dom";
import { whyBitcoinRoute } from "utils";

const getCoursesUrls = () => {
  return coursesConfig?.courses?.flatMap((course) => {
    if (course?.chapters?.length) {
      return course.chapters.map((chapter) => {
        return {
          path: whyBitcoinRoute(chapter.url),
          element: <CourseDetail />,
        };
      });
    }

    return [];
  });
};

const AppRoutes = () => {
  const routes = [
    {
      element: <Default />,
      children: [
        {
          path: "/",
          element: <HomePage />,
        },
      ],
    },
    {
      element: <Main />,
      children: [
        {
          path: "/login",
          element: <LoginPage />,
        },
        {
          path: "/registrace",
          element: <RegisterPage />,
        },
        {
          path: "/dashboard",
          element: <DashboardPage />,
        },
        {
          path: "/strategie/:id",
          element: <DcaBotDetailPage />,
        },
        {
          path: "/nova-strategie",
          element: <NewDcaBotPage />,
        },
        {
          path: "/upravit-strategii/:id",
          element: <NewDcaBotPage />,
        },
        {
          path: "/nova-grid-strategie",
          element: <NewGridBot />,
        },
        {
          path: "/upravit-grid-strategii/:id",
          element: <UpdateGridBot />,
        },
        {
          path: "/grid-strategie/:id",
          element: <GridBotDetail />,
        },
        {
          exact: true,
          path: "/stosaky",
          element: <CreditsDashboardPage />,
        },
        {
          path: "/stosaky/transakce",
          element: <CreditsTransactionsPage />,
        },
        {
          path: "/nastaveni",
          element: <SettingsPage />,
        },
        {
          path: "/predplatne",
          element: <SubscriptionsPage />,
        },
        {
          path: "/overeni-emailu",
          element: <EmailVerification />,
        },
        {
          path: "/podminky",
          element: <TermsPage />,
        },
        {
          path: "/cenik",
          element: <Pricing />,
        },
        {
          path: "/menove-pary",
          element: <CurrencyPairs />,
        },
        {
          path: "/dvoufaktorove-overeni",
          element: <TwoFactorAuthPage />,
        },
        {
          path: "/zapomenute-heslo",
          element: <LostPasswordPage />,
        },
        {
          path: "/obnova-hesla",
          element: <SetNewPasswordPage />,
        },
        {
          path: "/aktivace",
          element: <ActivationPage />,
        },
        {
          path: "/e-mailove-preference",
          element: <EmailPreferences />,
        },
        {
          path: "/backtest-grid-strategie",
          element: <BacktestGridBot />,
        },
        {
          exact: true,
          path: "/proc-bitcoin",
          element: <WhyBitcoin />,
        },
        {
          path: "/mesicni-platba",
          element: <NewRecharge />,
        },
        {
          path: "/zrusit-mesicni-platbu",
          element: <CancelRecharge />,
        },
        {
          path: "/feature/:type",
          element: <FeaturePage />,
        },
        {
          path: "/backtest-dca-strategie",
          element: <BacktestDcaStrategy />,
        },
        {
          path: "/dokonceni-registrace",
          element: <CompleteRegistration />,
        },
        ...getCoursesUrls(),
        {
          path: "*",
          element: <Navigate to="/" replace />,
        },
      ],
    },
  ];

  return useRoutes(routes);
};

export default AppRoutes;
