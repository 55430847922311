import CloseIcon from "@mui/icons-material/Close";
import {
  DialogTitle,
  DialogActions,
  DialogContent,
  IconButton,
  Breakpoint,
  Stack,
  Button,
  DialogContentText,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import Dialog from "../Dialog";

interface IProps {
  open: boolean;
  size?: Breakpoint;
  title: string;
  description?: string;
  children?: React.ReactNode;
  customActions?: React.ReactNode;
  customConfirmButton?: React.ReactNode;
  cancelTitle?: string;
  confirmTitle?: string;
  onCancel: () => void;
  onConfirm?: () => void;
  closeOnConfirm?: boolean;
  withCloseButton?: boolean;
  disableCancelButton?: boolean;
  disableCloseOnClickOutside?: boolean;
}

const Modal = ({
  open,
  size = "sm",
  title,
  description,
  children,
  customActions,
  cancelTitle,
  confirmTitle,
  onCancel,
  onConfirm,
  closeOnConfirm,
  withCloseButton,
  customConfirmButton,
  disableCancelButton = false,
  disableCloseOnClickOutside = false,
}: IProps) => {
  const { t } = useTranslation<string>();

  const handleConfirm = () => {
    if (closeOnConfirm) onCancel?.();

    onConfirm?.();
  };

  return (
    <Dialog open={open} onClose={disableCloseOnClickOutside ? undefined : onCancel} fullWidth maxWidth={size} scroll="paper">
      <DialogTitle>
        <Stack width={"100%"} direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
          {title}
          {withCloseButton && (
            <IconButton edge="end" color="inherit" onClick={onCancel} size="small">
              <CloseIcon />
            </IconButton>
          )}
        </Stack>
      </DialogTitle>
      <DialogContent>
        {description && <DialogContentText>{description}</DialogContentText>}
        {children}
      </DialogContent>
      <DialogActions>
        {customActions}
        {!disableCancelButton && (
          <Button onClick={onCancel} color="error">
            {cancelTitle ?? t("cancel")}
          </Button>
        )}
        {onConfirm && (
          <Button onClick={handleConfirm} variant="contained">
            {confirmTitle ?? t("confirm")}
          </Button>
        )}
        {customConfirmButton}
      </DialogActions>
    </Dialog>
  );
};

export default Modal;
