import React from "react";

import { Box, Grid, Stack, Typography } from "@mui/material";
import { Dollar, Document, Wallet2, Mobile, StatisticsMonitor, Graph } from "components/icons";
import HomeBox from "components/modules/HomeBox";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { Trans, useTranslation } from "react-i18next";

const ITEMS = [
  {
    icon: <Dollar style={{ width: "28px", height: "28px", color: "white" }} />,
    title: "homepage.otherFuction.recalculation.title",
    description: "homepage.otherFuction.recalculation.description",
  },
  {
    icon: <Document />,
    title: "homepage.otherFuction.initialState.title",
    description: "homepage.otherFuction.initialState.description",
  },
  {
    icon: <Wallet2 />,
    title: "homepage.otherFuction.withdrawal.title",
    description: "homepage.otherFuction.withdrawal.description",
  },
  {
    icon: <Mobile />,
    title: "homepage.otherFuction.pwa.title",
    description: "homepage.otherFuction.pwa.description",
  },
  {
    icon: <StatisticsMonitor style={{ width: "28px", height: "28px", color: "white" }} />,
    title: "homepage.otherFuction.savingsGoals.title",
    description: "homepage.otherFuction.savingsGoals.description",
  },
  {
    icon: <Graph />,
    title: "homepage.otherFuction.summary.title",
    description: "homepage.otherFuction.summary.description",
  },
];

const OtherFunctions: React.FC = () => {
  const { colors } = useMUIThemeModeContext();
  const { t } = useTranslation();

  return (
    <HomeBox
      bgcolor={colors["navyBlack"]}
      titleColor={"white"}
      title={t("homepage.otherFuction.title")}
      px={{ xs: 2, md: 8 }}>
      <Grid container spacing={{ xs: 2, md: 4 }} pt={{ xs: 4, md: 8 }} textAlign={"start"}>
        {ITEMS.map((item, index) => (
          <Grid item xs={12} sm={6} lg={4} key={index}>
            <Box p={{ xs: 2, md: 4 }} borderRadius={"24px"} bgcolor={colors["deepMidnightBlue"]} height={"100%"}>
              <Stack direction={"column"} spacing={{ xs: 1, md: 2 }}>
                {item.icon}
                <Typography color="white" fontSize={{ xs: "1rem", md: "1.25rem" }} fontWeight={600}>
                  {t(item.title)}
                </Typography>
                <Typography color="white" fontSize={{ xs: "0.75rem", md: "0.875rem" }} fontWeight={300}>
                  <Trans i18nKey={item.description} />
                </Typography>
              </Stack>
            </Box>
          </Grid>
        ))}
      </Grid>
    </HomeBox>
  );
};

export default OtherFunctions;
