import { Link, Typography, Box, Stack } from "@mui/material";
import PageTitle from "components/elements/PageTitle";
import Subscription from "components/modules/Subscription";
import { useUserDataContext } from "context/UserDataContext";
import { useIsLogin } from "hooks/useUser";
import { Trans, useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

const SubscriptionsPage = () => {
  const { t } = useTranslation();
  const { creditsBalance } = useUserDataContext();
  const isLoggedIn = useIsLogin();

  const headerContent = () => {
    return (
      <>
        <Typography variant="h5" component="h1" fontWeight={600} sx={{ pb: 1, color: "tertiary.main" }}>
          {t("subscription")}
        </Typography>
        <Typography variant="subtitle2" fontWeight={400} sx={{ pb: 3 }}>
          <Stack spacing={1}>
            <Box>
              <Trans i18nKey="subscriptionSubtitle1" />
            </Box>
            <Box>
              <Trans i18nKey="subscriptionSubtitle2" />
            </Box>
            <Box>
              <Trans i18nKey="subscriptionSubtitle3" />
            </Box>
            <Box>
              <Trans i18nKey="subscriptionSubtitle4" />
            </Box>
            <Box>
              {isLoggedIn && creditsBalance && creditsBalance > 0 ? (
                <>
                  <Trans i18nKey="subscriptionOwnedCredits" values={{ amount: creditsBalance }} />
                  {" "}
                  <Link to="/stosaky" component={RouterLink}>
                    <Trans i18nKey="subscriptionMoreCreditsLink" />
                  </Link>
                </>
              ) : (
                <Link to="/stosaky" component={RouterLink}>
                  <Trans i18nKey="subscriptionCreditsLink" />
                </Link>
              )}
            </Box>
          </Stack>
        </Typography>
      </>
    );
  };

  return (
    <>
      <PageTitle i18nKey="subscriptionPage.meta_title" />
      <Subscription backgroundOnMobile={true}>{headerContent()}</Subscription>
    </>
  );
};

export default SubscriptionsPage;
