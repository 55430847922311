import { SvgIcon } from "@mui/material";

export const ChevronDown = (props: any) => {
  return (
    <SvgIcon {...props}>
      <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="chevron-down">
          <path
            id="Icon"
            d="M5 7.5L10 12.5L15 7.5"
            stroke="currentColor"
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    </SvgIcon>
  );
};
