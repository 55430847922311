import React from "react";

import { Button } from "@mui/material";
import SectionCard from "components/elements/SectionCard";
import { useTranslation } from "react-i18next";
import { ExchangeType } from "types/enums";

import Upsell from "../Upsell";

interface IProps {
  header: string;
  onOpenExchangeModal: (exchangeType?: ExchangeType) => void;
  onRegisteringAnycoin: () => void;
}

const ListWrapper: React.FC<IProps> = ({ header, onOpenExchangeModal, onRegisteringAnycoin, children }) => {
  const { t } = useTranslation();

  const actions = (
    <Button
      variant="contained"
      size="small"
      onClick={() => onOpenExchangeModal()}>
      {t("createConnection")}
    </Button>
  );

  return (
    <SectionCard
      title={children ? header : t("exchangeConnections.recommended.title")}
      actions={children ? actions : undefined}>
      {children ? (
        children
      ) : (
        <Upsell onOpenExchangeModal={onOpenExchangeModal} onRegisteringAnycoin={onRegisteringAnycoin} />
      )}
    </SectionCard>
  );
};

export default ListWrapper;
