import validations from "constants/validations";

import { useEffect, useState } from "react";

import { Alert, AlertTitle } from "@mui/material";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Stack from "@mui/material/Stack";
import useTheme from "@mui/material/styles/useTheme";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { login, loginMFA } from "API/calls";
import CodeField from "components/elements/CodeField";
import PageTitle from "components/elements/PageTitle";
import Panel from "components/elements/Panel";
import PasswordField from "components/elements/PasswordField";
import { useUserDataContext } from "context/UserDataContext";
import { useFormik } from "formik";
import useKeyPress from "hooks/useKeyPress";
import { useIsSmallScreen } from "hooks/useWindowSize";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useNavigate, Link } from "react-router-dom";
import * as yup from "yup";

import { FormDiv } from "./styles";

const LoginPage = () => {
  const theme = useTheme();
  const isSmallScreen = useIsSmallScreen();
  const { t } = useTranslation<string>();
  const navigate = useNavigate();
  const keyPressed = useKeyPress("Enter");
  const { enqueueSnackbar } = useSnackbar();
  const userData = useUserDataContext();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      rememberMe: true,
    },
    validationSchema: yup.object().shape({
      email: yup.string().required(t("fieldRequired")).matches(validations.email, t("fieldErrors.email")),
      password: yup.string().required(t("fieldRequired")).matches(validations.password, t("fieldErrors.password")),
      rememberMe: yup.bool().required(t("fieldRequired")),
    }),
    onSubmit: (values) => {
      handleLogin(values.email, values.password, values.rememberMe);
    },
  });
  const [mfaAuthRequired, setMfaAuthRequired] = useState<boolean>(false);
  const mfaFormik = useFormik({
    initialValues: { otp: "" },
    validationSchema: yup.object({
      otp: yup
        .string()
        .required(t("fieldRequired"))
        .max(6, t("fieldMaxLength", { max: 6 })),
    }),
    onSubmit: (values) => {
      const { otp } = values;
      otp && handleMFALogin(`${otp}`);
    },
  });

  useEffect(() => {
    if (keyPressed) {
      mfaAuthRequired ? mfaFormik.handleSubmit() : formik.handleSubmit();
    }
  }, [keyPressed]);

  const handleLogin = async (email: string, password: string, rememberMe: boolean) => {
    try {
      const { data } = await login(email, password, rememberMe);
      if (data?.mfaAuthRequired) setMfaAuthRequired(true);
      else handleLoginSuccess();
    } catch (error: any) {
      if (error?.response?.status === 401) {
        enqueueSnackbar(t("loginForm.errorMessage"), { variant: "error" });
      } else {
        enqueueSnackbar(t("commonError"), { variant: "error" });
      }
    }
  };

  const handleMFALogin = async (otp: string) => {
    try {
      await loginMFA(otp);
      handleLoginSuccess();
    } catch (err: any) {
      enqueueSnackbar(err?.response?.data?.message ?? t("commonError"), { variant: "error" });
    }
  };

  const handleLoginSuccess = () => {
    enqueueSnackbar(t("loginForm.successMessage"), { variant: "success" });
    navigate(`/dashboard`);
    userData.refreshData?.();
  };

  if (mfaAuthRequired) {
    return (
      <FormDiv>
        <PageTitle i18nKey="loginForm.meta_title" />
        <Panel sx={{ p: 4, width: isSmallScreen ? "100%" : "460px" }}>
          <Stack spacing={2}>
            <Typography
              variant="h4"
              component="h1"
              fontWeight={600}
              sx={{ color: "tertiary.main", textAlign: "center" }}>
              {t("loginForm.mfaAuthRequired")}
            </Typography>
            <CodeField
              sx={{pb: 1}}
              fullWidth={true}
              maxLength={6}
              id={"otp"}
              name={"otp"}
              label={t("twoFactorAuth.enableGoogleAuth.label")}
              value={mfaFormik.values.otp ?? ""}
              error={Boolean(mfaFormik.errors.otp)}
              helperText={mfaFormik.errors.otp ?? t("twoFactorAuth.enableGoogleAuth.helperText")}
              onChange={mfaFormik.handleChange}
              autoFocus
            />
            <Alert severity="warning">
              <AlertTitle>{t("loginForm.mfaWarningTitle")}</AlertTitle>
              <div>{t("loginForm.mfaWarning1")}</div>
              <div><strong>{t("loginForm.mfaWarning2")}</strong></div>
              <div>{t("loginForm.mfaWarning3")}</div>
            </Alert>
            <Button fullWidth variant="contained" onClick={() => mfaFormik.handleSubmit()}>
              {t("loginForm.send")}
            </Button>
          </Stack>
        </Panel>
      </FormDiv>
    );
  }

  return (
    <FormDiv>
      <PageTitle i18nKey="loginForm.meta_title" />
      <Panel sx={{ p: 4, width: isSmallScreen ? "100%" : "460px" }}>
        <Stack spacing={3}>
          <Typography variant="h4" component="h1" fontWeight={600} sx={{ color: "tertiary.main", textAlign: "center", pb: 2 }}>
            {t("loginForm.login")}
          </Typography>
          <TextField
            fullWidth={true}
            id={"email"}
            name={"email"}
            type={"email"}
            inputProps={{
              autoCapitalize: "none",
            }}
            label={t("loginForm.email")}
            value={formik.values.email ?? ""}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <PasswordField
            fullWidth={true}
            id={"password"}
            name={"password"}
            label={t("loginForm.password")}
            value={formik.values.password ?? ""}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            onChange={formik.handleChange}
          />
          <Stack
            direction={isSmallScreen ? "column" : "row"}
            spacing={2}
            justifyContent="space-between"
            alignItems="center">
            <FormControlLabel
              control={<Checkbox />}
              id={"rememberMe"}
              name={"rememberMe"}
              label={`${t("loginForm.rememberMe")}`}
              checked={formik.values.rememberMe}
              onChange={formik.handleChange}
            />
            <Link to="/zapomenute-heslo" style={{ color: theme.palette.primary.main }}>
              {t("loginForm.forgottenPassword")}
            </Link>
          </Stack>
          <Button fullWidth variant="contained" onClick={() => formik.handleSubmit()} autoFocus>
            {t("loginForm.loginButton")}
          </Button>
          <Stack direction={isSmallScreen ? "column" : "row"} spacing={1} alignItems="center" justifyContent="center">
            <div>{t("loginForm.noAccount")}</div>
            <Link to="/registrace" style={{ color: theme.palette.primary.main }}>
              {t("loginForm.registerHere")}
            </Link>
          </Stack>
        </Stack>
      </Panel>
    </FormDiv>
  );
};

export default LoginPage;
