import { REFERRAL_ANYCOIN, REFERRAL_COINMATE } from "constants/links";

import { Box, Link, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { ReactComponent as AnycoinLogo } from "assets/exchanges/anycoin-30.svg";
import { ReactComponent as BinanceLogo } from "assets/exchanges/binance-30.svg";
import { ReactComponent as BitstampLogo } from "assets/exchanges/bitstamp-30.svg";
import { ReactComponent as CoinmateLogo } from "assets/exchanges/coinmate-30.svg";
import ExchangeComparison from "components/modules/ExchangeComparison";
import HomeBox from "components/modules/HomeBox";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { Trans, useTranslation } from "react-i18next";

const Exchanges = () => {
  const { t } = useTranslation();
  const { colors } = useMUIThemeModeContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <HomeBox size="small">
      <Stack spacing={3} textAlign={"center"}>
        <Box>
          <Typography color={colors["deepMidnightBlue"]} fontWeight={600} fontSize={{ xs: "1.25rem", sm: "1.5rem" }}>
            {t("homepage.exchanges")}
          </Typography>
          <Typography
            sx={{ pt: 3 }}
            color={colors["blueGray"]}
            fontWeight={300}
            fontSize={{ xs: "0.9rem", sm: "1rem" }}>
            <Trans i18nKey="homepage.exchangesCzech" />
          </Typography>
        </Box>
        <Stack direction={"row"} justifyContent={"center"}>
          <Link href={REFERRAL_COINMATE} target="_blank">
            <CoinmateLogo height={80} width={300} style={{ maxWidth: "75%" }} />
          </Link>
          <Link href={REFERRAL_ANYCOIN} target="_blank">
            <AnycoinLogo height={80} width={300} color={colors.black} style={{ maxWidth: "75%" }} />
          </Link>
        </Stack>
        <Typography color={colors["blueGray"]} fontWeight={300} fontSize={{ xs: "0.9rem", sm: "1rem" }}>
          {t("homepage.exchangesOther")}
        </Typography>
        <Stack
          direction={isMobile ? "column" : "row"}
          justifyContent={"center"}
          alignItems={isMobile ? "center" : undefined}
          spacing={4}>
          <BinanceLogo />
          <BitstampLogo />
        </Stack>
        <Box sx={{ textAlign: "center", pt: 2 }}>
          <ExchangeComparison />
        </Box>
      </Stack>
    </HomeBox>
  );
};

export default Exchanges;
