import { ChevronRight } from "@mui/icons-material";
import { Button, Typography, Box, useTheme, useMediaQuery, Container } from "@mui/material";
import Grid from "@mui/material/Grid";
import RegisterWithEmailOnly from "components/modules/RegisterWithEmailOnly";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { useIsLogin } from "hooks/useUser";
import { Trans, useTranslation } from "react-i18next";
import { TypeAnimation } from "react-type-animation";

import Mobile from "./Mobile";
import MobileWithDashboard from "./MobileWithDashboard";

const Hero = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(1250));
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  const isXl = useMediaQuery(theme.breakpoints.up("xl"));
  const canRenderVideo = useMediaQuery("(min-width:900px)");

  const { colors } = useMUIThemeModeContext();
  const isLoggedIn = useIsLogin();

  const getTitleFontSize = () => {
    if (isMd) return "2rem";
    if (!isXl) return "2.2rem";
    if (isXl) return "3rem";
    return "3rem";
  };
  const typeAnimationDelay = 400;

  return (
    <Container>
      <Grid container pt={{ xs: 3, md: 7 }} pb={isMobile ? 0 : 10} spacing={2}>
        <Grid item xs={12} md={6} lg={5}>
          <Box pt={{ xs: 2, lg: 6 }}>
            <Typography
              component="h1"
              fontWeight={700}
              lineHeight={"120%"}
              sx={{
                color: colors["blueBlack"],
                fontSize: getTitleFontSize(),
              }}>
              {t("homepage.heroTitle")}
            </Typography>
            <TypeAnimation
              key={`type-fontsize-${getTitleFontSize()}`}
              sequence={[
                `${t("homepage.heroType.1")} `,
                typeAnimationDelay * 2,
                `${t("homepage.heroType.2")} `,
                typeAnimationDelay,
                `${t("homepage.heroType.3")} `,
                typeAnimationDelay,
                `${t("homepage.heroType.4")} `,
                typeAnimationDelay,
                `${t("homepage.heroType.5")} `,
                typeAnimationDelay,
                `${t("homepage.heroType.6")} `,
                typeAnimationDelay * 8,
              ]}
              speed={50}
              repeat={Infinity}
              style={{
                color: theme.palette.primary.main,
                fontSize: getTitleFontSize(),
                fontWeight: 700,
                lineHeight: "120%",
              }}
            />
            <Typography
              sx={{ pt: 3, maxWidth: "460px" }}
              color={colors["blueBlack"]}
              fontSize={{ xs: "0.9rem", lg: "1rem", xl: "1.125rem" }}>
              <Trans i18nKey="homepage.heroSubtitle" />
            </Typography>
            <Box sx={{ mt: 4, mb: 3 }}>
              {isLoggedIn && (
                <Button href={"/dashboard"} variant="contained" endIcon={<ChevronRight />} size="large">
                  {t("homepage.showDashboard")}
                </Button>
              )}
              {!isLoggedIn && (
                <RegisterWithEmailOnly
                  textAlign={!canRenderVideo ? "center" : "right"}
                  withBackground={!canRenderVideo}
                  withBorder={!canRenderVideo}
                />
              )}
            </Box>
          </Box>
        </Grid>
        {canRenderVideo && (
          <Grid item xs={12} md={6} lg={7} display={{ xs: "none", md: "block" }}>
            {isMobile ? <Mobile /> : <MobileWithDashboard />}
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default Hero;
