import { Box, useTheme } from "@mui/material";
import { ApexOptions } from "apexcharts";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { useUserDataContext } from "context/UserDataContext";
import dayjs from "dayjs";
import isToday from "dayjs/plugin/isToday";
import useExchangeRate from "hooks/useExchangeRate";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import { IPriceChart } from "types/types";
import { percentageChange } from "utils";
import { renderNumber, renderPrice } from "utils/formatter";

dayjs.extend(isToday);

interface Props {
  chartData: IPriceChart;
  isMobile: boolean;
  counterCurrency: string;
  counterCurrDisplayedScale: number;
}

const PriceChart = ({ chartData, isMobile, counterCurrency, counterCurrDisplayedScale }: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { showInUSD } = useUserDataContext();
  const { getUSDPrice } = useExchangeRate();
  const { colors } = useMUIThemeModeContext();

  const labels = chartData.points.map((item) =>
    Intl.DateTimeFormat("cs-CZ").format(new Date(dayjs(item.date).format()))
  );
  const min = chartData.minValue;

  const series = [
    {
      name: t("price"),
      data: chartData.points.map((item) => item.price),
    },
    {
      name: t("averagePrice"),
      data: chartData.points.map((item) => item.averagePrice),
    },
  ];
  const strokeCurve: ApexStroke["curve"] = ["smooth", "stepline"];
  const strokeWidth = [4, 3];
  const chartColors = [theme.palette.primary.main, theme.palette.success.main];

  // chart config
  const options: ApexOptions = {
    theme: { mode: theme.palette.mode },
    tooltip: {
      followCursor: true,
      inverseOrder: true,
      y: {
        formatter: (val: number, { seriesIndex, dataPointIndex, series }) => {
          // not possible to have a separate formatter for each data series, therefore this hack
          if (seriesIndex === 0) {
            const percentage = percentageChange(series?.[1]?.[dataPointIndex], val);
            const price = showInUSD
              ? getUSDPrice(counterCurrency, val, counterCurrDisplayedScale)
              : renderPrice(val, counterCurrency, counterCurrDisplayedScale);
            const result = `${price} (${percentage > 0 ? "+" : ""}${renderNumber(percentage, 2)} %)`;
            return result;
          } else {
            const value = showInUSD ? getUSDPrice(counterCurrency, val, counterCurrDisplayedScale) : renderPrice(val, counterCurrency, counterCurrDisplayedScale);
            return value ?? "";
          }
        },
      },
    },
    colors: chartColors,
    chart: {
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      background: "transparent",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: strokeCurve,
      width: strokeWidth,
    },
    grid: {
      row: {
        colors: [colors.legacyGray100, "transparent"],
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: labels,
      labels: {
        hideOverlappingLabels: true,
        rotate: -30,
        show: !isMobile,
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      show: !isMobile,
      labels: {
        formatter: (val: any) => {
          const value = showInUSD ? getUSDPrice(counterCurrency, val, counterCurrDisplayedScale) : renderPrice(val, counterCurrency, counterCurrDisplayedScale);
          return value ?? ""
        },
      },
      min,
    },
  };

  return (
    <Box sx={{ pt: { xs: 2 }, pr: { xs: 0, md: 2 }, textAlign: "center" }}>
      <ReactApexChart options={options} series={series} type="line" width={"100%"} height={400} />
    </Box>
  );
};

export default PriceChart;
