import axios from "axios";

const APIS_WITHOUT_REDIRECT = ["api/credits-recharge"];

const redirectToLogin = (loginUrl = "/login") => {
  const { pathname } = window.location;

  if (pathname !== loginUrl) window.location.href = loginUrl;
};

export const axiosApiInstance = axios.create({
  withCredentials: true,
});

axiosApiInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response.status === 401 && !APIS_WITHOUT_REDIRECT.some((api) => error?.config?.url?.includes(api))) {
      redirectToLogin();
    }

    return Promise.reject(error);
  }
);
