import { useState, useEffect, useRef } from "react";

import LogScaleSlider from "components/elements/LogScaleSlider";
import IProps from "components/elements/LogScaleSlider/types";

const LogScale = ({ id, onChange, value, ...props }: IProps & { value: number | string }) => {
  const [key, setKey] = useState<number>(0);
  const [useSlider, setUseSlider] = useState<boolean>(false);
  const timeoutRef = useRef<any>();

  useEffect(() => {
    if(!useSlider) setKey(key + 1);
  }, [value])

  const handleChange = (value: number) => {
    setUseSlider(true);
    onChange(value);

    clearTimeout(timeoutRef.current);
    const newTimer = setTimeout(() => setUseSlider(false), 500);
    timeoutRef.current = newTimer;
  };
  

  return <LogScaleSlider key={`${id}-${key}`} onChange={handleChange} {...props} />;
};

export default LogScale;
