import React from "react";

import { FormControl, FormHelperText, FormLabel, RadioGroup as MUIRadioGroup } from "@mui/material";

import IProps from "./types";

const RadioGroup: React.FC<IProps> = ({ label, children, disabled, error, ...radioGroupProps }: IProps) => {
  return (
    <FormControl sx={{ width: "100%" }} disabled={disabled} component="fieldset">
      {label && <FormLabel component="legend">{label}</FormLabel>}
      <MUIRadioGroup {...radioGroupProps}>{children}</MUIRadioGroup>
      {error && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
  );
};

export default RadioGroup;
