import { Stack } from "@mui/material";
import Alert from "@mui/material/Alert";
import Modal from "components/elements/Modal";
import { useTranslation } from "react-i18next";

import IProps from "./types";

const ModalDowngradingFail = ({ open, onClose, data }: IProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={t("subscriptionPage.modalDowngradingFail.title")}
      open={open}
      onCancel={onClose}
      withCloseButton
      disableCancelButton
      onConfirm={onClose}
      confirmTitle={t("subscriptionPage.modalDowngradingFail.understand")}>
      <Alert severity="warning" sx={{ mb: 2 }}>
        {t("subscriptionPage.modalDowngradingFail.subtitle")}
      </Alert>
      <Stack spacing={2}>
        <ul style={{ display: "inline-block", textAlign: "left" }}>
          {data?.havingTooManyGridBots && (
            <li>
              {t("subscriptionPage.modalDowngradingFail.havingTooManyGridBots", {
                subType: data?.subscriptionType,
              })}
            </li>
          )}
          {data?.havingUnsupportedGridBotCurrencyPair && (
            <li>
              {t("subscriptionPage.modalDowngradingFail.havingUnsupportedGridBotCurrencyPair", {
                subType: data?.subscriptionType,
              })}
            </li>
          )}
          {data?.havingSmallerGridBotPercentStep && (
            <li>
              {t("subscriptionPage.modalDowngradingFail.havingSmallerGridBotPercentStep", {
                subType: data?.subscriptionType,
              })}
            </li>
          )}
          {data?.havingTooManyDcaBots && (
            <li>
              {t("subscriptionPage.modalDowngradingFail.havingTooManyDcaBots", { subType: data?.subscriptionType })}
            </li>
          )}
          {data?.havingDcaBotDynamicStrategy && (
            <li>
              {t("subscriptionPage.modalDowngradingFail.havingDcaBotDynamicStrategy", { subType: data?.subscriptionType })}
            </li>
          )}
          {data?.havingTooManyDcaBotLimitOrders && (
            <li>
              {t("subscriptionPage.modalDowngradingFail.havingTooManyDcaBotLimitOrders", {
                subType: data?.subscriptionType,
              })}
            </li>
          )}
          {data?.havingTooManyDcaBotInstantLimitOrders && (
            <li>
              {t("subscriptionPage.modalDowngradingFail.havingTooManyDcaBotInstantLimitOrders", {
                subType: data?.subscriptionType,
              })}
            </li>
          )}
          {data?.havingTooFrequentDcaBotFrequency && (
            <li>
              {t("subscriptionPage.modalDowngradingFail.havingTooFrequentDcaBotFrequency", {
                subType: data?.subscriptionType,
              })}
            </li>
          )}
          {data?.havingUnsupportedDcaBotCurrencyPair && (
            <li>
              {t("subscriptionPage.modalDowngradingFail.havingUnsupportedDcaBotCurrencyPair", {
                subType: data?.subscriptionType,
              })}
            </li>
          )}
          {data?.havingDcaBotTargetValuePrice && (
            <li>
              {t("subscriptionPage.modalDowngradingFail.havingDcaBotTargetValuePrice", {
                subType: data?.subscriptionType,
              })}
            </li>
          )}
          {data?.havingDcaBotLimitOrderNotExecutingAsMarket && (
            <li>
              {t("subscriptionPage.modalDowngradingFail.havingDcaBotLimitOrderNotExecutingAsMarket", {
                subType: data?.subscriptionType,
              })}
            </li>
          )}
          {data?.havingDcaBotSellMode && (
            <li>
              {t("subscriptionPage.modalDowngradingFail.havingDcaBotSellMode", {
                subType: data?.subscriptionType,
              })}
            </li>
          )}
          {data?.havingWithdrawalConnection && (
            <li>
              {t("subscriptionPage.modalDowngradingFail.havingWithdrawalConnection", {
                subType: data?.subscriptionType,
              })}
            </li>
          )}
        </ul>
      </Stack>
    </Modal>
  );
};

export default ModalDowngradingFail;
