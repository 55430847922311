/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { VIP_USERS } from "constants/affiliate";
import { DISCORD, GRID_STRATEGY_BACKTEST, GRID_STRATEGY_INTRODUCTION } from "constants/links";
import { subscriptionBots, subscriptionDefaultGridPercentStep } from "constants/subscriptions";

import { useEffect, useState } from "react";

import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
  Stack,
  MenuItem,
  TextField,
  Typography,
  FormControl,
  Checkbox,
  FormControlLabel,
  Box,
  Grid,
  useMediaQuery,
  Link,
  Alert,
  Button,
  SelectChangeEvent,
  useTheme,
} from "@mui/material";
import { createGridBot, previewGridBot } from "API/calls";
import gridExample from "assets/images/grid-example.gif";
import Big from "big.js";
import CandleChart, { convertOHLCData } from "components/elements/CandleChart";
import PageTitle from "components/elements/PageTitle";
import ResponsiveLabel from "components/elements/ResponsiveLabel";
import Select from "components/elements/Select";
import Tile from "components/elements/Tile";
import Tooltip from "components/elements/Tooltip";
import { AccountingCoinsStack } from "components/icons";
import SubscriptionLimitMsg from "components/modules/BotsList/SubscriptionLimitMsg";
import { useDefaultLayoutContext } from "context/DefaultLayoutContext";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { useUserDataContext } from "context/UserDataContext";
import { useFormik } from "formik";
import {
  useAPIExchangeConnectionsList,
  useAPIGridBotsList,
  useAPIOHLC,
  useAPIExchangeConnectionTradingFees,
} from "hooks/useAPI";
import useCurrencyPairs from "hooks/useCurrencyPairs";
import useExchangeRate from "hooks/useExchangeRate";
import useExchangesMetadata from "hooks/useExchangesMetadata";
import useStep from "hooks/useStep";
import { LineWidth } from "lightweight-charts";
import { useSnackbar } from "notistack";
import { Trans, useTranslation } from "react-i18next";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { ExchangeType, OHLCInterval, Side, SubscriptionType } from "types/enums";
import { IExchangeConnectionsDetail, IPreviewGridBot, IPriceLevel } from "types/types";
import { splitCurrencyPair, increaseByPercentage, decreaseByPercentage, isAtTierOrHigher, getColorByMode } from "utils";
import { getExchangeConnectionLabel } from "utils/formatter";
import * as yup from "yup";

import BalanceView from "./BalanceView";
import CoinmateTradingFeesAlert, { hasCoinmateTradingFeesAlert } from "./CoinmateTradingFeesAlert";
import GridStepper from "./GridStepper";
import LevelsModal from "./LevelsModal";
import OrderValueInput from "./OrderValueInput";
import PercentStepInput from "./PercentStepInput";
import PriceRangeInput from "./PriceRangeInput";

const DEFAULT_GRID_LIMIT_PERCENTAGE = 20;
const MAX_GRID_LIMIT_PERCENTAGE_UP = 150;
const MAX_GRID_LIMIT_PERCENTAGE_DOWN = -50;
const DEFAULT_CURRENCY_PAIR = "BTC/CZK";

const initValues = {
  exchangeConnectionId: "",
  label: "",
  currencyPair: DEFAULT_CURRENCY_PAIR,
  gridUpperPrice: 0,
  gridLowerPrice: 0,
  gridPercentStep: 2.5,
  gridOrderValue: 5000,
};

const ORDER_VALUE = {
  CZK: 5000,
  EUR: 200,
};

const getExchangeEnum = (exchangeConnections: IExchangeConnectionsDetail[], exchangeConnectionId: string) => {
  const selectedExchange = exchangeConnections.find((ex) => ex.id === exchangeConnectionId);

  return selectedExchange?.exchangeEnum ?? "";
};

const getExchangeTitle = (exchangeConnections: IExchangeConnectionsDetail[], exchangeConnectionId: string) => {
  const selectedExchange = exchangeConnections.find((ex) => ex.id === exchangeConnectionId);

  return selectedExchange?.exchangeTitle ?? "";
};

const NewGridBot = () => {
  const theme = useTheme();
  const { t } = useTranslation<string>();
  const navigate = useNavigate();
  const isLg = useMediaQuery(theme.breakpoints.up("lg"));
  const { colors } = useMUIThemeModeContext();
  const { subscriptionType, aff, showInUSD } = useUserDataContext();
  const { refreshMenu } = useDefaultLayoutContext();
  const [previewGridBotData, setPreviewGridBotData] = useState<IPreviewGridBot>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showLevelsModal, setShowLevelsModal] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const { getUSDOriginalPrice } = useExchangeRate();
  const isVipUser = VIP_USERS.includes(aff);
  const { steps, activeStep, nextStep, backStep } = useStep([
    t("gridBot.create.howDoesItWork.title"),
    t("gridBot.create.beta.title"),
    t("gridBot.create.basicSetting.title"),
    t("gridBot.create.limitSetting.title"),
    t("gridBot.create.gridSetting.title"),
  ]);

  const { data: botsData } = useAPIGridBotsList();
  const { data: currencyPairs } = useCurrencyPairs();
  const { data: userExchangeConnections } = useAPIExchangeConnectionsList(false);
  const filteredArray = userExchangeConnections?.exchangeConnections.filter(
    (item) => item.exchangeEnum === ExchangeType.ANYCOIN || item.exchangeEnum === ExchangeType.COINMATE
  );
  const remainingArray = userExchangeConnections?.exchangeConnections.filter((item) => !filteredArray?.includes(item));
  const exchangeConnections = (filteredArray || []).concat(remainingArray || []);

  const validationGridPrice = (value?: string, context?: yup.TestContext<any>) => {
    if (value && context && OHLCData && lastClosePrice) {
      const val = Number(value.replace(",", "."));
      const closePrice = new Big(lastClosePrice).round(currencyPairPriceScale, Big.roundDown).toNumber();

      if (context.path === "gridLowerPrice") {
        if (val >= closePrice) {
          return context.createError({
            message: t("gridBot.create.maxLowerPriceFallback", { value: closePrice, currency: counterCurrency }),
          });
        }
      } else {
        if (val <= closePrice) {
          return context.createError({
            message: t("gridBot.create.minUpperPriceFallback", { value: closePrice, currency: counterCurrency }),
          });
        }
      }
    }

    return true;
  };

  const validationGridOrderValue = (value?: string, context?: yup.TestContext<any>) => {
    if (value && context) {
      const val = Number(value.replace(",", "."));
      const min = gridStrategyLimits?.min
        ? new Big(gridStrategyLimits?.min).round(counterCurrDisplayedScale, Big.roundDown).toNumber()
        : undefined;
      const max = gridStrategyLimits?.max
        ? new Big(gridStrategyLimits?.max).round(counterCurrDisplayedScale, Big.roundDown).toNumber()
        : undefined;

      if (max && val > max) {
        return context.createError({
          message: t("gridBot.create.maxGridOrderValueFallback", { value: max, currency: counterCurrency }),
        });
      }

      if (min && val < min) {
        return context.createError({
          message: t("gridBot.create.minGridOrderValueFallback", { value: min, currency: counterCurrency }),
        });
      }
    }

    return true;
  };

  const [betaAgree, setBetaAgree] = useState<boolean>(false);
  const defaultSchema = {
    exchangeConnectionId: yup.string().required(t("fieldRequired")),
    currencyPair: yup.string().required(t("fieldRequired")),
    label: yup.string().max(50, t("fieldMaxLength", { max: 50 })),
    gridUpperPrice: yup
      .string()
      .required(t("fieldRequired"))
      .test("minGridUpperPrice", "gridUpperPriceError", validationGridPrice),
    gridLowerPrice: yup
      .string()
      .required(t("fieldRequired"))
      .test("maxGridLowerPrice", "gridLowerPriceError", validationGridPrice),
    gridPercentStep: yup.string().required(t("fieldRequired")),
    gridOrderValue: yup
      .string()
      .required(t("fieldRequired"))
      .test("gridOrderValue", "gridOrderValueError", validationGridOrderValue),
  };
  const formik = useFormik({
    initialValues: initValues,
    validationSchema: yup.object(defaultSchema),
    onSubmit: (values) => {
      const { gridUpperPrice, gridLowerPrice, gridOrderValue, gridPercentStep, ...rest } = values;

      handleCreate({
        ...rest,
        gridUpperPrice: `${gridUpperPrice}`.replace(",", "."),
        gridLowerPrice: `${gridLowerPrice}`.replace(",", "."),
        gridOrderValue: `${gridOrderValue}`.replace(",", "."),
        gridPercentStep: `${gridPercentStep}`.replace(",", "."),
      });
    },
  });

  const { currencyPairPriceScale, counterCurrDisplayedScale, baseCurrDisplayedScale, gridStrategyLimits }
    = useExchangesMetadata(
      getExchangeEnum(exchangeConnections, formik.values.exchangeConnectionId),
      formik.values.currencyPair
    );
  const { counterCurrency, baseCurrency } = splitCurrencyPair(formik.values.currencyPair);

  const { data: OHLCData } = useAPIOHLC(
    formik.values.currencyPair,
    OHLCInterval.ONE_DAY,
    getExchangeEnum(exchangeConnections, formik.values.exchangeConnectionId),
    5000
  );
  const lastClosePrice = OHLCData && OHLCData.ohlc[OHLCData.ohlc.length - 1].c;

  const isCoinmate = getExchangeEnum(exchangeConnections, formik.values.exchangeConnectionId) === ExchangeType.COINMATE;
  const { data: tradingFees } = useAPIExchangeConnectionTradingFees(
    formik.values.exchangeConnectionId,
    formik.values.currencyPair.replace("/", "-"),
    isCoinmate
  );

  useEffect(() => {
    if (subscriptionType) {
      formik.setFieldValue(
        "gridPercentStep",
        subscriptionDefaultGridPercentStep[subscriptionType as keyof typeof subscriptionDefaultGridPercentStep]
      );
    }
  }, [subscriptionType]);

  useEffect(() => {
    if (OHLCData && lastClosePrice) {
      if (!formik.touched.gridUpperPrice) {
        const value = increaseByPercentage(lastClosePrice, DEFAULT_GRID_LIMIT_PERCENTAGE);
        formik.setFieldValue("gridUpperPrice", new Big(value).round(currencyPairPriceScale, Big.roundDown).toNumber());
      }

      if (!formik.touched.gridLowerPrice) {
        const value = decreaseByPercentage(lastClosePrice, DEFAULT_GRID_LIMIT_PERCENTAGE);
        formik.setFieldValue("gridLowerPrice", new Big(value).round(currencyPairPriceScale, Big.roundDown).toNumber());
      }
    }
  }, [OHLCData]);

  useEffect(() => {
    if (activeStep === steps.length - 1 && formik.values.gridOrderValue) {
      (async () => {
        const min = gridStrategyLimits?.min
          ? new Big(gridStrategyLimits.min).round(counterCurrDisplayedScale, Big.roundDown).toNumber()
          : undefined;
        const max = gridStrategyLimits?.max
          ? new Big(gridStrategyLimits.max).round(counterCurrDisplayedScale, Big.roundDown).toNumber()
          : undefined;
        if ((min && formik.values.gridOrderValue < min) || (max && formik.values.gridOrderValue > max)) return;

        try {
          const response = await previewGridBot({ ...formik.values });
          setPreviewGridBotData(response.data);
        } catch (e: any) {}
      })();
    }
  }, [activeStep, formik.values.gridOrderValue, formik.values.gridPercentStep, lastClosePrice]);

  useEffect(() => {
    if (hasExchangeConnections() && !formik.values.exchangeConnectionId) {
      const result = exchangeConnections.find(
        ({ exchangeEnum, functional }) => (exchangeEnum === ExchangeType.ANYCOIN || exchangeEnum === ExchangeType.COINMATE) && functional
      );
      if (result) formik.setFieldValue("exchangeConnectionId", result.id);
    }
  }, [exchangeConnections]);

  useEffect(() => {
    if (activeStep > 0) {
      setTimeout(() => navigate(`/nova-grid-strategie#${activeStep + 1}`), 500);
    }
  }, [activeStep]);

  useEffect(() => {
    if (
      formik.values.exchangeConnectionId
      && formik.values.exchangeConnectionId.length
      && formik.touched.exchangeConnectionId
    ) {
      formik.setFieldValue("currencyPair", DEFAULT_CURRENCY_PAIR);
    }
  }, [formik.values.exchangeConnectionId]);

  useEffect(() => {
    if (counterCurrency) {
      formik.setFieldValue("gridOrderValue", ORDER_VALUE[counterCurrency as keyof typeof ORDER_VALUE]);
    }
  }, [counterCurrency]);

  useEffect(() => {
    if (counterCurrency && baseCurrency) {
      formik.setFieldTouched("gridUpperPrice", false);
      formik.setFieldTouched("gridLowerPrice", false);
    }
  }, [counterCurrency, baseCurrency]);

  const hasExchangeConnections = () => {
    if (userExchangeConnections && userExchangeConnections.exchangeConnections) {
      if (userExchangeConnections.exchangeConnections.length > 0) return true;
    }

    return false;
  };

  const handleCreate = async (body: object) => {
    setIsLoading(true);

    try {
      const data = (await createGridBot(body)).data;
      enqueueSnackbar(t("gridBot.create.successMessage"), { variant: "success" });
      refreshMenu?.();
      navigate(`/grid-strategie/${data.id}`);
    } catch (error: any) {
      if (error?.response?.status === 409) {
        enqueueSnackbar(t("gridBot.create.errorMessage409"), { variant: "error" });
      } else {
        enqueueSnackbar(t("gridBot.create.errorMessage"), { variant: "error" });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getContent = (index: number) => {
    switch (index) {
      case 0:
        return howDoesItWorkSection();
      case 1:
        return betaSection();
      case 2:
        return basicSettingSection();
      case 3:
        return limitSettingSection();
      case 4:
        return gridSettingSection();
      default:
        return null;
    }
  };

  const canAddNewBot = () => {
    if(isVipUser) return true;

    return !(
      subscriptionType
      && botsData
      && subscriptionBots[subscriptionType] <= botsData?.bots.filter((el) => el.enabled).length
    );
  };

  const howDoesItWorkSection = () => (
    <Tile>
      <Stack spacing={1} p={3}>
        {!canAddNewBot() && (
          <Grid item xs={12}>
            <Grid container justifyContent="center">
              <Alert severity="warning" sx={{ mb: 4 }}>
                <SubscriptionLimitMsg id={"bots"} subscriptionType={subscriptionType!} />
              </Alert>
            </Grid>
          </Grid>
        )}
        <Stack>
          <Typography variant="subtitle2" fontWeight={400}>
            <Trans i18nKey="gridBot.create.howDoesItWork.text1" />
          </Typography>
        </Stack>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <img src={gridExample} alt="Ukázka grid strategie" style={{ maxHeight: '300px', maxWidth: '100%' }} />
        </div>
        <Stack>
          <Typography variant="subtitle2" fontWeight={600} sx={{ mt: 2 }}>
            {t("gridBot.create.howDoesItWork.title2")}
          </Typography>
          <Typography variant="subtitle2" fontWeight={400}>
            <Trans i18nKey="gridBot.create.howDoesItWork.text2" />
          </Typography>
        </Stack>
      </Stack>
    </Tile>
  );

  const betaSection = () => (
    <Tile>
      <Stack spacing={1} p={3}>
        <Stack>
          <Typography variant="subtitle2" fontWeight={600}>
            {t("gridBot.create.beta.title1")}
          </Typography>
          <Typography pt={1} variant="subtitle2" fontWeight={400}>
            <Trans i18nKey="gridBot.create.beta.text1" />
          </Typography>
          <Typography pt={1} variant="subtitle2" fontWeight={400}>
            <Trans
              i18nKey="gridBot.create.beta.text1b"
              components={{
                a: <Link href={DISCORD} target="_blank" sx={{ fontWeight: "bold" }} />,
              }}
            />
          </Typography>
          <Typography pt={3} variant="subtitle2" fontWeight={600}>
            {t("gridBot.create.beta.title2")}
          </Typography>
          <Typography pt={1} variant="subtitle2" fontWeight={400}>
            <Trans
              i18nKey="gridBot.create.beta.text2"
              components={{
                a: <Link href={GRID_STRATEGY_INTRODUCTION} target="_blank" sx={{ fontWeight: "bold" }} />,
              }}
            />
          </Typography>
          <Typography pt={3} variant="subtitle2" fontWeight={600}>
            {t("gridBot.create.beta.title3")}
          </Typography>
          <Typography pt={1} variant="subtitle2" fontWeight={400}>
            <Trans
              i18nKey="gridBot.create.beta.text3"
              components={{
                a: <Link href={GRID_STRATEGY_BACKTEST} target="_blank" sx={{ fontWeight: "bold" }} />,
              }}
            />
          </Typography>
        </Stack>
        <FormControl variant="standard">
          <FormControlLabel
            control={<Checkbox />}
            label={ResponsiveLabel(t("gridBot.create.beta.agree"))}
            checked={betaAgree}
            onChange={(_e: any, checked: boolean) => setBetaAgree(checked)}
          />
        </FormControl>
      </Stack>
    </Tile>
  );

  const getCurrencyPairsTicker = () => {
    if (!subscriptionType) return;

    const exEnum = getExchangeEnum(exchangeConnections, formik.values.exchangeConnectionId);
    const exCurrPairs = currencyPairs.find((pairs) => pairs?.id === exEnum);

    const gridCurrPairs = exCurrPairs?.data?.filter(
      (pair: any) => pair?.enabled && pair.grid_strategy_subscription_type
    );

    return gridCurrPairs?.map((el: any) => (
      <MenuItem
        key={el?.key}
        value={el?.key}
        disabled={!isAtTierOrHigher(subscriptionType, el?.grid_strategy_subscription_type)}>
        {ResponsiveLabel(el?.key)}
      </MenuItem>
    ));
  };

  const handleChangeExchangeConnection = (event: SelectChangeEvent<any>) => {
    formik.setFieldTouched("exchangeConnectionId", true);
    formik.handleChange(event);
  };

  const basicSettingSection = () => (
    <Tile>
      <Stack spacing={2} p={3}>
        <Typography variant="caption" fontWeight={400}>
          {t("gridBot.create.basicSetting.exchangeConnectionsInfo")}
        </Typography>
        <Select
          id="exchangeConnectionId"
          name="exchangeConnectionId"
          label={ResponsiveLabel(t("gridBot.create.exchangeConnectionId"))}
          value={formik.values.exchangeConnectionId ?? ""}
          onChange={handleChangeExchangeConnection}
          disabled={!hasExchangeConnections() || isLoading}
          error={formik.touched.exchangeConnectionId && formik.errors.exchangeConnectionId}>
          {exchangeConnections.map((ex, index) => (
            <MenuItem
              key={`user-ex-${index}`}
              value={ex.id}
              disabled={(ex.exchangeEnum !== ExchangeType.ANYCOIN && ex.exchangeEnum !== ExchangeType.COINMATE) || !ex.functional}>
              {ResponsiveLabel(getExchangeConnectionLabel(ex.exchangeTitle, ex.functional, ex.label))}
            </MenuItem>
          ))}
        </Select>
        <Typography variant="caption" fontWeight={400}>
          {t("gridBot.create.basicSetting.currencyPairInfo")}
        </Typography>
        <Select
          id="currencyPair"
          name="currencyPair"
          label={ResponsiveLabel(t("gridBot.create.currencyPair"))}
          value={formik.values.currencyPair ?? ""}
          onChange={formik.handleChange}
          disabled={!currencyPairs}
          error={formik.touched.currencyPair && formik.errors.currencyPair}>
          {getCurrencyPairsTicker()}
        </Select>
        {subscriptionType === "FREE" && (
          <Grid item xs={12}>
            <Alert severity="info">
              <Trans
                i18nKey="bots.create.alert.free"
                components={{
                  a: <RouterLink color={theme.palette.info.main} to="/predplatne" />,
                }}
              />
            </Alert>
          </Grid>
        )}
        <Typography variant="caption" fontWeight={400}>
          {t("gridBot.create.basicSetting.labelInfo")}
        </Typography>
        <TextField
          fullWidth
          autoComplete="off"
          id="label"
          name="label"
          label={ResponsiveLabel(t("gridBot.create.label"))}
          disabled={isLoading}
          value={formik.values.label}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.label)}
          helperText={formik.errors.label}
        />
      </Stack>
    </Tile>
  );

  const priceTransform = (price: number) => {
    const inUSD = getUSDOriginalPrice(counterCurrency, price);
    return inUSD ?? price;
  };

  const getPriceRange = () => {
    const minValue = showInUSD ? priceTransform(formik.values.gridLowerPrice) : formik.values.gridLowerPrice;
    const maxValue = showInUSD ? priceTransform(formik.values.gridUpperPrice) : formik.values.gridUpperPrice;

    return { minValue, maxValue };
  };

  const chartSection = (priceLevels?: IPriceLevel[], height?: number) => {
    if (!OHLCData) return;

    const result = [];

    if (formik.values.gridUpperPrice) {
      const price = showInUSD ? priceTransform(formik.values.gridUpperPrice) : formik.values.gridUpperPrice;

      result.push({
        price,
        color: getColorByMode(theme, colors.black, "#808080"),
        lineWidth: 3 as LineWidth,
        lineStyle: 0,
        axisLabelVisible: true,
        lineVisible: true,
        title: "",
      });
    }
    if (formik.values.gridLowerPrice) {
      const price = showInUSD ? priceTransform(formik.values.gridLowerPrice) : formik.values.gridLowerPrice;

      result.push({
        price,
        color: getColorByMode(theme, colors.black, "#808080"),
        lineWidth: 3 as LineWidth,
        lineStyle: 0,
        axisLabelVisible: true,
        lineVisible: true,
        title: "",
      });
    }

    if (priceLevels) {
      priceLevels.forEach((level) => {
        if (level.orderSide) {
          const hasEnough
            = !!level.orderSide && (level.priceLevelStatus === "OPEN" || level.priceLevelStatus === "ENOUGH_BALANCE");
          const price = showInUSD ? priceTransform(level.value) : level.value;

          result.push({
            price,
            color: hasEnough ? (level.orderSide === "BUY" ? colors.success : colors.error) : "gray",
            lineWidth: 1,
            lineStyle: hasEnough ? 0 : 3,
            axisLabelVisible: false,
            lineVisible: true,
            title: "",
          });
        }
      });
    }

    return (
      <Tile sx={{ py: 2, px: 1, height: height ? `${height}px` : "100%" }}>
        <CandleChart
          data={convertOHLCData(OHLCData, showInUSD ? priceTransform : undefined)}
          priceLines={result}
          pricePrecision={currencyPairPriceScale}
          priceRange={getPriceRange()}
          dateWithTime={false}
        />
      </Tile>
    );
  };

  const limitSettingSection = () => (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
        <Stack direction={"column"} spacing={{ lg: 0, xs: 1 }} sx={{ height: "100%" }}>
          <PriceRangeInput
            closePrice={lastClosePrice}
            lowerPrice={formik.values.gridLowerPrice}
            upperPrice={formik.values.gridUpperPrice}
            currency={counterCurrency}
            scale={currencyPairPriceScale}
            isLoading={isLoading}
            maxPercentageDown={MAX_GRID_LIMIT_PERCENTAGE_DOWN}
            maxPercentageUp={MAX_GRID_LIMIT_PERCENTAGE_UP}
            priceRangeTooltip={"gridBot.create.limitSetting.tooltip"}
            setFieldValue={formik.setFieldValue}
            setFieldTouched={formik.setFieldTouched}
          />
          <Box display={{ lg: "none" }}>{chartSection(undefined, 300)}</Box>
        </Stack>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={7} xl={7} sx={{ height: "auto" }}>
        <Box sx={{ height: "100%" }} display={{ xs: "none", md: "none", lg: "block" }}>
          {chartSection()}
        </Box>
      </Grid>
    </Grid>
  );

  const gridSettingSection = () => (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
        <Stack direction={"column"} spacing={3}>
          {!isLg && <Box>{chartSection(previewGridBotData?.priceLevels, 300)}</Box>}
          <Tile
            sx={{ height: "100%" }}
            header={t("gridBot.create.gridSetting.orderDistance.title")}
            footer={
              subscriptionType !== SubscriptionType.PREMIUM && (
                <Stack direction={"row"} spacing={1}>
                  <AccountingCoinsStack sx={{ fontSize: 16 }} />
                  <Typography variant="caption">
                    <Trans
                      i18nKey={`gridBot.create.gridSetting.orderDistance.alert.${subscriptionType}`}
                      components={{
                        a: (
                          <Link
                            to={"/predplatne"}
                            target={"_blank"}
                            color={"inherit"}
                            fontWeight={600}
                            component={RouterLink}
                          />
                        ),
                      }}
                    />
                  </Typography>
                </Stack>
              )
            }
            actions={
              <Tooltip arrow placement="top" title={t("gridBot.create.gridSetting.orderDistance.tooltip") ?? ""}>
                <HelpOutlineIcon sx={{ color: colors.slate500 }} />
              </Tooltip>
            }>
            <Typography color={"#525252"} px={3} pt={2} variant="body2">
              {t("gridBot.create.gridSetting.orderDistance.description")}
            </Typography>
            <PercentStepInput
              id="gridPercentStep"
              value={formik.values.gridPercentStep}
              setFieldValue={formik.setFieldValue}
              disabled={isLoading}
              subscriptionType={subscriptionType}
              min={aff && isVipUser ? 0.5 : undefined}
              defaultMinValue={aff && isVipUser ? 0.5 : undefined}
            />
          </Tile>
          {!isLg && (
            <CoinmateTradingFeesAlert
              makerFee={tradingFees?.makerFee}
              isCoinmate={isCoinmate}
              gridPercentStep={formik.values.gridPercentStep}
            />
          )}
          <Tile
            sx={{ height: "100%" }}
            header={t("gridBot.create.gridSetting.orderValue.title")}
            actions={
              <Tooltip arrow placement="top" title={t("gridBot.create.gridSetting.orderValue.tooltip") ?? ""}>
                <HelpOutlineIcon sx={{ color: colors.slate500 }} />
              </Tooltip>
            }>
            <Typography color={"#525252"} px={3} pt={2} variant="body2">
              {t("gridBot.create.gridSetting.orderValue.description")}
            </Typography>
            <OrderValueInput
              precision={counterCurrDisplayedScale}
              formik={formik}
              disabled={isLoading}
              counterCurrency={counterCurrency}
              gridStrategyLimits={gridStrategyLimits}
            />
          </Tile>
        </Stack>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={7} xl={7} height={"auto"}>
        <Box height={"100%"} display={{ xs: "none", md: "none", lg: "block" }}>
          {chartSection(previewGridBotData?.priceLevels)}
        </Box>
      </Grid>
      {isLg && (
        <Grid item xs={12}>
          <CoinmateTradingFeesAlert
            makerFee={tradingFees?.makerFee}
            isCoinmate={isCoinmate}
            gridPercentStep={formik.values.gridPercentStep}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        {previewSection()}
      </Grid>
    </Grid>
  );

  const previewSection = () => {
    if (!previewGridBotData) return;

    const {
      availableBaseCurrencyBalance,
      availableCounterCurrencyBalance,
      missingBaseCurrencyBalance,
      missingCounterCurrencyBalance,
      priceLevels,
      totalRequiredBaseCurrencyBalance,
      totalRequiredCounterCurrencyBalance,
      ticker,
    } = previewGridBotData;
    const exchangeTitle = getExchangeTitle(exchangeConnections, formik.values.exchangeConnectionId);

    return (
      <Stack spacing={3}>
        <Stack spacing={2} direction={{ xs: "column", lg: "row" }}>
          <BalanceView
            title={t("gridBot.create.gridSetting.buyOrders")}
            subtitle={t(`gridBot.create.gridSetting.buyTotal`, { title: exchangeTitle })}
            scale={counterCurrDisplayedScale}
            currency={counterCurrency}
            totalRequiredBalance={totalRequiredCounterCurrencyBalance}
            availableBalance={availableCounterCurrencyBalance}
            missingBalance={missingCounterCurrencyBalance}
            exchangeTitle={exchangeTitle}
            priceLevels={priceLevels.filter((level) => level.orderSide === Side.BUY)}
          />
          <BalanceView
            title={t("gridBot.create.gridSetting.sellOrders")}
            subtitle={t(`gridBot.create.gridSetting.sellTotal`, { title: exchangeTitle })}
            scale={baseCurrDisplayedScale}
            currency={baseCurrency}
            totalRequiredBalance={totalRequiredBaseCurrencyBalance}
            availableBalance={availableBaseCurrencyBalance}
            missingBalance={missingBaseCurrencyBalance}
            exchangeTitle={exchangeTitle}
            priceLevels={priceLevels.filter((level) => level.orderSide === Side.SELL)}
          />
        </Stack>
        <Tile p={3}>
          <Typography variant="subtitle2" fontWeight={600}>
            {t("gridBot.create.about.title")}
          </Typography>
          <Typography py={2} variant="subtitle2" fontWeight={400}>
            <Trans i18nKey="gridBot.create.about.description" />
          </Typography>
          <Button sx={{ p: 0 }} onClick={() => setShowLevelsModal(true)}>
            {t("gridBot.create.about.button")}
          </Button>
          {showLevelsModal && (
            <LevelsModal
              data={priceLevels}
              ticker={ticker}
              exchangeEnum={getExchangeEnum(exchangeConnections, formik.values.exchangeConnectionId)}
              currencyPair={formik.values.currencyPair}
              handleClose={() => setShowLevelsModal(false)}
            />
          )}
        </Tile>
      </Stack>
    );
  };

  const disableNextStep = (index: number) => {
    if (index === 1 && !betaAgree) return true;
    if (index === 2 && !formik.values.exchangeConnectionId) return true;
    if (
      index === steps.length - 1
      && (!canAddNewBot() || hasCoinmateTradingFeesAlert(formik.values.gridPercentStep, isCoinmate, tradingFees?.makerFee))
    )
      return true;
    return false;
  };

  return (
    <Tile isMain={true} header={t("gridBot.create.header.create")} m={{ xs: -3, md: 0 }}>
      <PageTitle i18nKey="gridBot.create.meta_title.create" />
      <GridStepper
        steps={steps}
        activeStep={activeStep}
        backStep={backStep}
        nextStep={nextStep}
        getContent={getContent}
        submit={() => formik.handleSubmit()}
        disableNextStep={disableNextStep}
      />
    </Tile>
  );
};

export default NewGridBot;
