import { useState } from "react";

import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { Stack, alpha, useMediaQuery, useTheme } from "@mui/material";
import SatsView from "components/modules/SatsView";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";

import MaskedModeWrapper from "../MaskedModeWrapper";
import Tooltip from "../Tooltip";
import { CustomIconButton, TypographyWithTooltip } from "./styles";
import IProps from "./types";

const BalanceItem = ({ label, value, tooltip, isFirstChild, currency, decimalPlaces, withZeros, amount }: IProps) => {
  const theme = useTheme();
  const { colors } = useMUIThemeModeContext();
  const [tooltipIsOpen, setTooltipIsOpen] = useState<boolean>(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Stack spacing={2} pt={isFirstChild ? 0 : 2} alignItems={"center"} justifyContent={"space-between"} direction={"row"} width={"100%"}>
      <TypographyWithTooltip variant={isMobile ? "body2" : "body1"} fontWeight={400} color={"secondary.main"}>
        {label}
        {tooltip && (
          <Tooltip
            open={tooltipIsOpen}
            onOpen={() => setTooltipIsOpen(true)}
            onClose={() => setTooltipIsOpen(false)}
            title={tooltip ?? ""}
            placement="bottom">
            <CustomIconButton sx={{backgroundColor: alpha(colors.black, 0.54)}} disableRipple onClick={() => setTooltipIsOpen(!tooltipIsOpen)} size="small">
              <QuestionMarkIcon sx={{ color: colors.white }} />
            </CustomIconButton>
          </Tooltip>
        )}
      </TypographyWithTooltip>
      <Stack alignItems={"end"}>
        <MaskedModeWrapper color={theme.palette.secondary.main} fontWeight={600} variant={isMobile ? "body2" : "body1"}>
          <SatsView
            color={theme.palette.secondary.main}
            variant={isMobile ? "body2" : "body1"}
            fontWeight={600}
            value={value}
            currency={currency}
            scale={decimalPlaces}
            withZeros={withZeros}
          />
        </MaskedModeWrapper>
        {amount && (
          <MaskedModeWrapper
            color={theme.palette.secondary.main}
            fontWeight={600}
            variant={"caption"}>
            <SatsView
              color={theme.palette.secondary.main}
              variant={"caption"}
              prefix="~ "
              fontWeight={600}
              value={amount.value}
              currency={amount.currency}
              scale={amount.scale}
            />{" "}
          </MaskedModeWrapper>
        )}
      </Stack>
    </Stack>
  );
};

export default BalanceItem;
