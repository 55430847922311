import React, { useState } from "react";

import { Grid, IconButton, ListItemIcon, Menu, MenuItem, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import { enabledWithdrawalConnection, deleteWithdrawalConnection } from "API/calls";
import Card2 from "components/elements/Card2";
import ExchangeIcon from "components/elements/ExchangeIcon";
import { getMaskedValue } from "components/elements/MaskedModeWrapper";
import { Edit, Trash, Menu as MenuIcon, MoreHorizontal, Pause, Play } from "components/icons";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { useUserDataContext } from "context/UserDataContext";
import useConfirm from "hooks/useConfirm";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { ExchangeType } from "types/enums";
import { renderNumber } from "utils/formatter";

import EditModal from "./EditModal";
import IProps from "./types";
import WithdrawalsListModal from "./WithdrawalsListModal";

const ListItem = ({ exchangeEnum, label, id, btcThreshold, maxFee, enabled, btcAddress, refreshData }: IProps) => {
  const { colors } = useMUIThemeModeContext();
  const { isMasked } = useUserDataContext();
  const { t } = useTranslation();
  const { confirm } = useConfirm();
  const { enqueueSnackbar } = useSnackbar();
  const [anchorElControl, setAnchorElControl] = useState<null | HTMLElement>(null);
  const [isOpenEditModal, setIsOpenEditModal] = useState<boolean>(false);
  const [isOpenWithdrawalsListModal, setIsOpenWithdrawalsListModal] = useState<boolean>(false);

  const handleOpenControlMenu = (event: React.MouseEvent<HTMLElement>) => setAnchorElControl(event.currentTarget);
  const handleCloseControlMenu = () => setAnchorElControl(null);

  const handleEnable = async () => {
    handleCloseControlMenu();

    try {
      await enabledWithdrawalConnection(id, !enabled);
      refreshData();

      if (enabled) {
        enqueueSnackbar(t("withdrawalConnections.successMessageDisable"), { variant: "success" });
      } else {
        enqueueSnackbar(t("withdrawalConnections.successMessageEnable"), { variant: "success" });
      }
    } catch (err: any) {
      if (enabled) {
        enqueueSnackbar(t("withdrawalConnections.errorMessageDisable"), { variant: "error" });
      } else {
        enqueueSnackbar(t("withdrawalConnections.errorMessageEnable"), { variant: "error" });
      }
    }
  };

  const handleDelete = async () => {
    try {
      await confirm({
        title: t("withdrawalConnections.deleteModal.title", { label: label ? label : t("withoutLabel") }),
        description: t("withdrawalConnections.deleteModal.description"),
        dialogProps: { maxWidth: "xs" },
      });

      handleCloseControlMenu();

      try {
        await deleteWithdrawalConnection(id);
        enqueueSnackbar(t("withdrawalConnections.deleteSuccessMessage"), { variant: "success" });
        refreshData();
      } catch (error: any) {
        enqueueSnackbar(t("withdrawalConnections.deleteErrorMessage"), { variant: "error" });
      }
    } catch (err: any) {
      // silent
    }
  };

  const handleEdit = () => {
    handleCloseControlMenu();
    setIsOpenEditModal(true);
  };

  const handleShowWithdrawalsList = () => {
    handleCloseControlMenu();
    setIsOpenWithdrawalsListModal(true);
  };

  const menu = () => (
    <>
      <IconButton size="small" onClick={handleOpenControlMenu} color="inherit">
        <MoreHorizontal sx={{ fontSize: 20, color: colors.black }} />
      </IconButton>
      <Menu
        sx={{ mt: "45px" }}
        anchorEl={anchorElControl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElControl)}
        onClose={handleCloseControlMenu}>
        <MenuItem onClick={handleShowWithdrawalsList}>
          <ListItemIcon>
            <MenuIcon sx={{ fontSize: 20, color: colors.gray700 }} />
          </ListItemIcon>
          <Typography>{t("withdrawalConnections.showWithdrawalsList")}</Typography>
        </MenuItem>
        <MenuItem onClick={handleEdit}>
          <ListItemIcon>
            <Edit sx={{ fontSize: 20, color: colors.gray700 }} />
          </ListItemIcon>
          <Typography>{t("edit")}</Typography>
        </MenuItem>
        <MenuItem onClick={handleEnable}>
          <ListItemIcon>
            {enabled ? <Pause sx={{ fontSize: 20, color: colors.gray700 }} /> : <Play sx={{ fontSize: 20, color: colors.gray700 }} />}
          </ListItemIcon>
          <Typography>{enabled ? t("pause") : t("activate")}</Typography>
        </MenuItem>
        <MenuItem onClick={handleDelete}>
          <ListItemIcon>
            <Trash sx={{ fontSize: 20, color: colors.gray700 }} />
          </ListItemIcon>
          <Typography>{t("delete")}</Typography>
        </MenuItem>
      </Menu>
    </>
  );

  const getTitle = () => {
    if (label) return label;

    return (
      <Typography color={colors.gray400} fontSize={"1rem"} fontWeight={500}>
        {t("withoutLabel")}
      </Typography>
    );
  };

  return (
    <Grid item xs={12} lg={6} height={"auto"}>
      <Card2
        sx={{ height: "100%" }}
        title={getTitle()}
        childrenBoxSx={{
          display: "flex",
          flex: 1,
          alignItems: "flex-end",
        }}
        action={menu()}>
        <Stack
          width={"100%"}
          direction={{ xs: "column", sm: "row" }}
          alignItems={{ xs: "flex-start", sm: "center" }}
          justifyContent={"space-between"}
          spacing={1}>
          <ExchangeIcon type={exchangeEnum as ExchangeType} />
          <Typography color={colors.gray600} fontSize={"0.875rem"}>
            {t("withdrawalConnections.btcThresholdWithdrawal", { value: getMaskedValue(isMasked, renderNumber(btcThreshold, 8)) })}
          </Typography>
          <Typography color={enabled ? colors.success : colors.error} fontSize={"0.875rem"} fontWeight={500}>
            {t(`withdrawalConnections.${enabled ? "enabled" : "disabled"}`)}
          </Typography>
        </Stack>
      </Card2>
      {isOpenEditModal && (
        <EditModal
          id={id}
          label={label}
          btcAddress={btcAddress}
          btcThreshold={btcThreshold}
          maxFee={maxFee}
          exchangeEnum={exchangeEnum}
          isOpen={isOpenEditModal}
          handleClose={() => setIsOpenEditModal(false)}
        />
      )}
      {isOpenWithdrawalsListModal && (
        <WithdrawalsListModal
          id={id}
          open={isOpenWithdrawalsListModal}
          exchangeEnum={exchangeEnum}
          maxFee={maxFee}
          handleClose={() => setIsOpenWithdrawalsListModal(false)}
        />
      )}
    </Grid>
  );
};

export default ListItem;
