import React from "react";

import AddIcon from "@mui/icons-material/Add";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import RemoveIcon from "@mui/icons-material/Remove";
import { IconButton as MUIIconButton, Stack, Typography, alpha, styled, useMediaQuery, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import NumberField from "components/elements/NumberField";
import { useTranslation } from "react-i18next";

import { IProps } from "./types";

export const IconButton = styled(MUIIconButton)(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.primary.main,
  width: "30px",
  height: "30px",

  "&.Mui-disabled": {
    color: theme.palette.common.white,
    backgroundColor: "#C4C4C4",
  },
  "&:hover:not(.Mui-disabled)": {
    backgroundColor: alpha(theme.palette.primary.main, 0.5),
  },
}));

const PercentageInput = ({ value, onChange, min, max, error, index, handleRemoveOrder, mode }: IProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (typeof event.target.value === "number" && Number(event.target.value) < min) event.target.value = `${min}`;
    onChange(event.target.value === "" ? undefined : Number(event.target.value));
  };

  const handleBlur = () => {
    if (value === undefined) return;

    if (value < min) onChange(min);
    else if (value > max) onChange(max);
  };

  const handleAdd = () => onChange(value ? value + 1 : 1);
  const handleRemove = () => onChange(value ? value - 1 : 1);

  const isDisabledRemove = value ? value <= 1 : true;
  const isDisabledAdd = value ? value >= 90 : false;

  return (
    <Box sx={{ pb: { xs: 0, sm: 1 } }}>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        alignItems={{ xs: "start", sm: "center" }}
        spacing={{ xs: 1, sm: 2 }}>
        <Stack direction={"row"} justifyContent={"space-between"} width={isXs ? "100%" : undefined}>
          <Typography>{t(`dcaBots.create.limitOrder.label.${mode}`)}</Typography>
          <MUIIconButton
            sx={{ p: 0, display: isXs && index !== 0 ? undefined : "none" }}
            onClick={() => handleRemoveOrder(index)}>
            <HighlightOffIcon />
          </MUIIconButton>
        </Stack>
        <Stack direction={"row"} justifyContent={"space-between"} flex={1} alignItems={"center"} spacing={2} pb={{xs: 1, sm: 0}} >
          <Stack direction={"row"} alignItems={"center"} spacing={2} justifyContent={"center"} alignContent={"center"}>
            <NumberField
              value={value}
              size="small"
              autoComplete="off"
              onlyInteger
              onlyPositive
              onChange={handleInputChange}
              onBlur={handleBlur}
              inputProps={{
                step: 1,
                min: min,
                max: max,
                type: "number",
              }}
              error={!!error}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              sx={{ maxWidth: "100px", minWidth: "70px" }}
            />
            <Stack direction={"row"} spacing={1}>
              <IconButton onClick={handleRemove} disabled={isDisabledRemove}>
                <RemoveIcon fontSize="small" />
              </IconButton>
              <IconButton onClick={handleAdd} disabled={isDisabledAdd}>
                <AddIcon fontSize="small" />
              </IconButton>
            </Stack>
          </Stack>
          <MUIIconButton
            sx={{ display: !isXs && index !== 0 ? undefined : "none" }}
            onClick={() => handleRemoveOrder(index)}>
            <HighlightOffIcon />
          </MUIIconButton>
        </Stack>
      </Stack>
    </Box>
  );
};

export default PercentageInput;
