import CachedIcon from "@mui/icons-material/Cached";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

import { CustomCachedIconProps } from "./types";

export const ItemBox = styled(Box)(({ theme }) => ({
  backgroundColor: "rgba(33, 158, 188, .1)",
  borderRadius: "10px",
  padding: theme.spacing(2),
  width: "100%",
  height: "100%",
  color: theme.palette.tertiary.main,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const CustomCachedIcon = styled(CachedIcon)<CustomCachedIconProps>(({ disabled }) => ({
  "@keyframes spin": {
    "0%": {
      transform: "rotate(360deg)",
    },
    "100%": {
      transform: "rotate(0deg)",
    },
  },
  animation: disabled ? "spin 1s infinite ease" : undefined,
}));
