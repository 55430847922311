import { SvgIcon } from "@mui/material";

export const Award03 = (props: any) => {
  return (
    <SvgIcon {...props}>
      <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="award-03">
          <path
            id="Icon"
            d="M6.55722 12.8834L5.83333 18.3334L9.657 16.0392C9.78171 15.9644 9.84406 15.927 9.91063 15.9124C9.96951 15.8994 10.0305 15.8994 10.0894 15.9124C10.1559 15.927 10.2183 15.9644 10.343 16.0392L14.1667 18.3334L13.4433 12.881M13.6882 3.54081C13.8169 3.85208 14.064 4.0995 14.375 4.22867L15.4658 4.68048C15.7771 4.80942 16.0244 5.05674 16.1533 5.36803C16.2822 5.67933 16.2822 6.02909 16.1533 6.34038L15.7018 7.43038C15.5728 7.7418 15.5726 8.09192 15.7022 8.4032L16.1529 9.49287C16.2168 9.64705 16.2498 9.81231 16.2498 9.97922C16.2498 10.1461 16.2169 10.3114 16.1531 10.4656C16.0892 10.6198 15.9956 10.7599 15.8775 10.8779C15.7595 10.9959 15.6194 11.0895 15.4652 11.1533L14.3752 11.6048C14.064 11.7335 13.8166 11.9805 13.6874 12.2916L13.2356 13.3824C13.1067 13.6937 12.8593 13.941 12.5481 14.0699C12.2368 14.1989 11.887 14.1989 11.5758 14.0699L10.4858 13.6184C10.1745 13.4898 9.82489 13.4901 9.5138 13.6192L8.42306 14.0703C8.11195 14.199 7.76251 14.1989 7.45148 14.07C7.14045 13.9412 6.89328 13.6942 6.76426 13.3832L6.31232 12.2921C6.18363 11.9809 5.93659 11.7334 5.62553 11.6043L4.53479 11.1524C4.22365 11.0236 3.97641 10.7764 3.84743 10.4653C3.71844 10.1542 3.71827 9.80458 3.84694 9.49334L4.29841 8.40334C4.42703 8.09204 4.42677 7.74241 4.29768 7.4313L3.84686 6.33974C3.78294 6.18556 3.75003 6.0203 3.75 5.85339C3.74997 5.68649 3.78283 5.52122 3.8467 5.36702C3.91057 5.21282 4.0042 5.07272 4.12223 4.95472C4.24026 4.83672 4.38039 4.74313 4.5346 4.67931L5.62456 4.22782C5.93554 4.09923 6.1828 3.8525 6.31206 3.54178L6.76386 2.451C6.8928 2.13971 7.14011 1.89239 7.45139 1.76345C7.76267 1.63451 8.11242 1.63451 8.4237 1.76345L9.51365 2.21495C9.82494 2.34357 10.1746 2.34331 10.4857 2.21421L11.5769 1.76415C11.8881 1.63529 12.2378 1.63531 12.549 1.76423C12.8602 1.89314 13.1075 2.14039 13.2364 2.4516L13.6884 3.5427L13.6882 3.54081Z"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    </SvgIcon>
  );
};
