import React, { useState, useEffect } from "react";

import {
  Typography,
  Box,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
  useTheme,
  darken,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import useMediaQuery from "@mui/material/useMediaQuery";
import NumberField from "components/elements/NumberField";
import HomeBox from "components/modules/HomeBox";
import { getThemeOptions } from "config/Theme/options";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { useAPIPriceHistory } from "hooks/useAPI";
import { Trans, useTranslation } from "react-i18next";

import {
  getFiatValue,
  getFrequencyCountByValue,
  getHistoryWithPrices,
  getInvested,
  getInvestedCrypto,
} from "./calculations";
import Chart from "./chart";
import { Frequency, InputState, OutputState } from "./types";

const BlockWrapper: React.FC<{ block: boolean }> = ({ block, children }) => (
  <span style={{ display: block ? "block" : "inline-block", color: "black" }}>{children}</span>
);

const DcaCalculator = () => {
  const themeConfig = createTheme(getThemeOptions("light"));
  const { t } = useTranslation();
  const theme = useTheme();
  const { data: priceHistory } = useAPIPriceHistory("BTC/CZK");
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isLarge = useMediaQuery(theme.breakpoints.up("lg"));
  const { colors } = useMUIThemeModeContext();

  const durations = [...Array(8)].map((_, key) => {
    const value = key + 1;
    return {
      value,
      label: `${value} ${t("dcaCalculator.year", { count: value })}`,
    };
  });

  const frequencies: Frequency[] = [
    {
      value: 1,
      count: 1,
      label: `1 ${t("dcaCalculator.day", { count: 1 })}`,
    },
    {
      value: 2,
      count: 2,
      label: `2 ${t("dcaCalculator.day", { count: 2 })}`,
    },
    {
      value: 3,
      count: 3,
      label: `3 ${t("dcaCalculator.day", { count: 3 })}`,
    },
    {
      value: 5,
      count: 5,
      label: `5 ${t("dcaCalculator.day", { count: 5 })}`,
    },
    {
      value: 7,
      count: 1,
      label: `1 ${t("dcaCalculator.week", { count: 1 })}`,
    },
    {
      value: 14,
      count: 2,
      label: `2 ${t("dcaCalculator.week", { count: 2 })}`,
    },
    {
      value: 30,
      count: 1,
      label: `1 ${t("dcaCalculator.month", { count: 1 })}`,
    },
  ];

  const [inputState, setInputState] = useState<InputState>({
    duration: durations[3].value,
    amount: 2000,
    frequency: frequencies[4].value,
  });

  const [outputState, setOutputState] = useState<OutputState>({
    invested: 0,
    investedCrypto: 0,
    currentValue: 0,
    chartConfig: undefined,
  });

  useEffect(() => {
    const invested = getInvested({
      duration: inputState.duration,
      frequency: inputState.frequency,
      amount: Number(inputState.amount),
    });
    const investedCrypto = getInvestedCrypto({
      amount: Number(inputState.amount),
      priceHistory: getHistoryWithPrices({
        duration: inputState.duration,
        frequency: inputState.frequency,
        priceHistory,
      }),
    });
    const chartConfig = {
      history: getHistoryWithPrices({
        duration: inputState.duration,
        frequency: inputState.frequency,
        priceHistory,
      }).reverse(),
      amount: Number(inputState.amount),
      isMobile,
    };
    setOutputState((prevState) => ({
      ...prevState,
      invested,
      investedCrypto,
      currentValue: getFiatValue(investedCrypto, priceHistory),
      chartConfig,
    }));
  }, [priceHistory, inputState.duration, inputState.frequency, inputState.amount, isMobile]);

  const onChangeDuration = (event: SelectChangeEvent) => {
    setInputState((prevState) => ({
      ...prevState,
      duration: Number(event.target.value),
    }));
  };

  const onAmountChange = (event: any) => {
    const val = event.target.value;
    setInputState((prevState) => ({
      ...prevState,
      amount: val,
    }));
  };

  const onFrequencyChange = (event: SelectChangeEvent) => {
    setInputState((prevState) => ({
      ...prevState,
      frequency: Number(event.target.value),
    }));
  };

  return (
    <ThemeProvider theme={themeConfig}>
      <HomeBox id={"dca-kalkulacka"}>
        {!priceHistory ? (
          <Box sx={{ py: 10 }}>
            <CircularProgress color="primary" />
          </Box>
        ) : (
          <>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} lg={5}>
                <Box textAlign={"start"} pb={2}>
                  <Typography
                    color={(theme) => theme.palette.tertiary.main}
                    fontWeight={600}
                    fontSize={{ xs: "0.9rem", sm: "1rem" }}>
                    {t("dcaCalcName")}
                  </Typography>
                  <Typography
                    pt={1}
                    color={colors["deepMidnightBlue"]}
                    fontWeight={600}
                    fontSize={{ xs: "1.5rem", sm: "2rem" }}>
                    {t("dcaCalc")}
                  </Typography>
                </Box>
                <Box sx={{ lineHeight: "40px", pb: { xs: 2, lg: 4 } }}>
                  <BlockWrapper block={isMobile}>
                    <Trans
                      i18nKey="dcaCalculator.savingToBitcoin"
                      components={{
                        strong: <strong />,
                      }}
                    />
                  </BlockWrapper>{" "}
                  <BlockWrapper block={isMobile}>
                    {t("dcaCalculator.inLastYear", { count: inputState.duration })}
                    <FormControl
                      sx={{
                        mx: 1,
                        minWidth: 70,
                        display: "inline-block",
                        top: "-6px",
                        marginTop: "5px",
                        marginBottom: "5px",
                      }}
                      size="small">
                      <Select value={String(inputState.duration)} onChange={onChangeDuration} autoWidth>
                        {durations.map((item) => {
                          return (
                            <MenuItem value={item.value} key={item.value}>
                              {item.label}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </BlockWrapper>
                  <BlockWrapper block={isLarge}>
                    <BlockWrapper block={isMobile}>
                      <span>
                        {t("dcaCalculator.every", {
                          count: getFrequencyCountByValue({ value: inputState.frequency, frequencies }),
                        })}
                      </span>
                      <FormControl
                        sx={{
                          mx: 1,
                          minWidth: 70,
                          display: "inline-block",
                          top: "-6px",
                          marginTop: "5px",
                          marginBottom: "5px",
                        }}
                        size="small">
                        <Select value={String(inputState.frequency)} onChange={onFrequencyChange} autoWidth>
                          {frequencies.map((item) => {
                            return (
                              <MenuItem value={item.value} key={item.value}>
                                {item.label}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </BlockWrapper>
                    <BlockWrapper block={isMobile}>
                      <span>{t("dcaCalculator.amount")}</span>
                      <FormControl
                        sx={{
                          mx: 1,
                          width: "135px",
                          display: "inline-block",
                          top: "-6px",
                          marginTop: "5px",
                          marginBottom: "5px",
                          textAlign: "center",
                        }}>
                        <NumberField
                          autoComplete="off"
                          onlyPositive
                          allowZero
                          onlyInteger
                          value={inputState.amount}
                          onChange={onAmountChange}
                          size="small"
                          maxLength={7}
                          InputProps={{
                            endAdornment: <InputAdornment position="start">{t("currencySymbol.CZK")}</InputAdornment>,
                          }}
                        />
                      </FormControl>
                    </BlockWrapper>
                  </BlockWrapper>
                  {!isMobile && !isLarge && <br />}
                  {inputState.amount !== "" && (
                    <>
                      <BlockWrapper block={isMobile}>
                        {t("dcaCalculator.yourSavedAmount")}{" "}
                        <strong>
                          {new Intl.NumberFormat("cs-CZ", {
                            style: "currency",
                            currency: "CZK",
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          }).format(outputState.invested)}
                        </strong>{" "}
                        {t("in")}
                        {"\u00a0"}
                        <strong>
                          {new Intl.NumberFormat("cs-CZ", {
                            style: "currency",
                            currency: "BTC",
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 4,
                          }).format(outputState.investedCrypto)}
                        </strong>
                      </BlockWrapper>{" "}
                      {!isMobile && <br />}
                      <BlockWrapper block={isMobile}>
                        {t("dcaCalculator.currentValue")}{" "}
                        <strong
                          style={{
                            color:
                              outputState.currentValue > outputState.invested
                                ? theme.palette.success.main
                                : theme.palette.error.main,
                            fontSize: "1.5em",
                            position: "relative",
                            top: "3px",
                            display: "inline-block",
                            marginLeft: "2px",
                          }}>
                          {new Intl.NumberFormat("cs-CZ", {
                            style: "currency",
                            currency: "CZK",
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          }).format(outputState.currentValue)}
                        </strong>
                      </BlockWrapper>
                      {outputState.currentValue && outputState.currentValue <= outputState.invested ? (
                        <Box sx={{ color: theme.palette.primary.main, fontSize: "1.2em" }}>
                          <strong>{t("dcaCalculator.stackSats")}</strong>
                          {"\u00A0"}
                          <span style={{ position: "relative", top: "-3px" }}>🤞</span>
                        </Box>
                      ) : (
                        outputState.currentValue > 0 && (
                          <Box>
                            <Box
                              sx={{
                                color: theme.palette.success.main,
                                fontSize: "1.1em",
                                display: "inline-block",
                                background: "#a8ebac7d",
                                borderRadius: "10px",
                                px: 3,
                                mx: 1,
                                mt: 1,
                                fontWeight: "bold",
                              }}>
                              <Box sx={{ fontSize: "0.8em", padding: "10px 0 0 0", lineHeight: 1.5 }}>
                                {t("dcaCalculator.youGained")}:
                              </Box>
                              +{"\u00A0"}
                              {new Intl.NumberFormat("cs-CZ", {
                                style: "currency",
                                currency: "CZK",
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                              }).format(outputState.currentValue - outputState.invested)}{" "}
                              <span style={{ fontSize: "0.8em", fontWeight: "normal" }}>
                                (+{"\u00A0"}
                                {new Intl.NumberFormat("cs-CZ", {
                                  style: "percent",
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 0,
                                }).format((outputState.currentValue - outputState.invested) / outputState.invested)}
                                )
                              </span>
                            </Box>
                          </Box>
                        )
                      )}
                    </>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} lg={7}>
                <Box width={"100%"} borderRadius={"24px"} overflow={"hidden"} boxShadow={theme.shadows[24]}>
                  {outputState.chartConfig ? (
                    <Chart config={outputState.chartConfig} />
                  ) : (
                    <Box sx={{ py: 10 }}>
                      <CircularProgress color="primary" />
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
          </>
        )}
      </HomeBox>
    </ThemeProvider>
  );
};

export default DcaCalculator;
