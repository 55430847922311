import React from "react";

import { Dialog as MuiDialog, DialogProps, useMediaQuery, useTheme } from "@mui/material";

type Props = {};

const Dialog: React.FC<DialogProps & Props> = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <MuiDialog
      sx={{
        ".MuiDialog-paper": {
          margin: { xs: 1, sm: 4 },
          ...(Boolean(isMobile && props.fullWidth) && { width: "auto" })
        },
        ".MuiDialogContent-root": {
          px: { xs: 2, sm: 3 },
          pt: "8px !important",
          pb: 0,
        },
        ".MuiDialogTitle-root": {
          px: { xs: 2, sm: 3 },
          py: { xs: 2, sm: 2 },
          fontSize: { xs: "1.1rem", sm: "1.25rem" },
        },
        ".MuiDialogActions-root": {
          px: { xs: 2, sm: 3 },
          py: { xs: 2, sm: 3 },
        },
      }}
      {...props}>
      {props.children}
    </MuiDialog>
  );
};

export default Dialog;
