import validations from "constants/validations";

import { useState, useEffect } from "react";

import { Link, Alert, Typography, Button, Stack } from "@mui/material";
import { completeRegistrationWithDcaBot } from "API/calls";
import PageTitle from "components/elements/PageTitle";
import Panel from "components/elements/Panel";
import PasswordField from "components/elements/PasswordField";
import Radio from "components/elements/Radio";
import RadioGroup from "components/elements/RadioGroup";
import LanguageSelect from "components/modules/LanguageSelect";
import { useFormik } from "formik";
import useKeyPress from "hooks/useKeyPress";
import { useIsSmallScreen } from "hooks/useWindowSize";
import { useSnackbar } from "notistack";
import { useTranslation, Trans } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { IActivation } from "types/types";
import { StringParam, useQueryParams } from "use-query-params";
import * as yup from "yup";

import { FormDiv } from "./styles";

export enum ExchangeToRegisterType {
  ANYCOIN = "ANYCOIN",
  STOSUJ = "STOSUJ",
}

const ActivationPage = () => {
  const navigate = useNavigate();
  const [query, setQuery] = useQueryParams({ token: StringParam });
  const { t } = useTranslation<string>();
  const isSmallScreen = useIsSmallScreen();
  const keyPressed = useKeyPress("Enter");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [token] = useState<string>(query.token || "");
  const { enqueueSnackbar } = useSnackbar();

  const formik = useFormik({
    initialValues: {
      password: "",
      language: "CS",
      exchangeToRegister: ExchangeToRegisterType.ANYCOIN,
      dcaBotFrequency: "W",
    },
    validationSchema: yup.object().shape({
      password: yup.string().required(t("fieldRequired")).matches(validations.password, t("fieldErrors.password")),
      language: yup.string().required(t("fieldRequired")),
      exchangeToRegister: yup.string().required(t("fieldRequired")),
      dcaBotFrequency: yup.string().required(t("fieldRequired")),
    }),
    onSubmit: ({ exchangeToRegister, ...formValues }) => {
      handleActivation({
        ...formValues,
        token,
        exchangeToRegister: exchangeToRegister === ExchangeToRegisterType.STOSUJ ? null : exchangeToRegister,
      });
    },
  });

  useEffect(() => {
    // to remove token query parameter from URL before it gets collected to Google Analytics
    setQuery({ token: undefined });
  }, []);

  useEffect(() => {
    if (!token) navigate("/");
  }, [token]);

  useEffect(() => {
    if (keyPressed) formik.handleSubmit();
  }, [keyPressed]);

  const handleActivation = async (payload: IActivation) => {
    setIsLoading(true);
    try {
      await completeRegistrationWithDcaBot(payload);
      enqueueSnackbar(t("activation.successMessage"), { variant: "success" });
      navigate("/dashboard");
    } catch (error: any) {
      enqueueSnackbar(t("activation.errorMessage"), { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  const changeWithValidate = (e: any) => {
    formik.setFieldTouched(e.target.id);
    formik.handleChange(e);
  };

  return (
    <FormDiv>
      <PageTitle i18nKey="activation.meta_title" />
      <Panel sx={{ p: 4, width: isSmallScreen ? "100%" : "460px" }}>
        <Stack spacing={3}>
          <Typography variant="h4" component="h1" fontWeight={600} sx={{ color: "tertiary.main", textAlign: "center", pb: 2 }}>
            {t("activation.title")}
          </Typography>
          <PasswordField
            fullWidth={true}
            id={"password"}
            name={"password"}
            label={t("activation.password")}
            value={formik.values.password ?? ""}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            onChange={changeWithValidate}
            disabled={isLoading}
          />
          <LanguageSelect
            value={formik.values.language ?? ""}
            error={formik.errors.language}
            onChange={(e) => formik.setFieldValue("language", e.target.value)}
            disabled={isLoading}
          />
          <RadioGroup
            id={"exchangeToRegister"}
            name={"exchangeToRegister"}
            onChange={formik.handleChange}
            disabled={isLoading}
            value={formik.values.exchangeToRegister}>
            <Radio
              componentsProps={{typography: { variant: "body2" }}}
              value={ExchangeToRegisterType.STOSUJ}
              label={`${t("activation.createStosuj")}`}
            />
            <Radio
              componentsProps={{typography: { variant: "body2" }}}
              value={ExchangeToRegisterType.ANYCOIN}
              label={`${t("activation.createAnycoin.title")}`}
            />
            {formik.values.exchangeToRegister === ExchangeToRegisterType.ANYCOIN && (
              <Alert severity="info" sx={{ marginY: "8px", fontSize: "0.8rem" }}>
                <Trans
                  i18nKey="activation.createAnycoin.description"
                  components={{
                    a: <Link href={"https://www.anycoin.cz/terms"} target="_blank" sx={{ fontWeight: "bold" }} />,
                  }}
                />
              </Alert>
            )}
          </RadioGroup>
          <Button
            disabled={isLoading || !token}
            fullWidth
            variant="contained"
            onClick={() => formik.handleSubmit()}
            autoFocus>
            {t("activation.button")}
          </Button>
        </Stack>
      </Panel>
    </FormDiv>
  );
};

export default ActivationPage;
